import { BehaviorSubject } from "rxjs";
import { getOperatorContractedVessels } from "./api-server/vessels";
import { api } from "../contexts/AuthContext";
import store from "../state/store";

const apiUrl = `/vesselmgt`;
let currentRig: BehaviorSubject<any> = new BehaviorSubject(null);

const RigInfo: any[] = [
	{
		name: "drillmax",
		longname: "STENA DRILLMAX",
		on_hire: false,
		imo: 9364942,
		mmsi: 235060864,
		icon: "https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/blue/Stena-Drillmax-Blue-150x150.png",
		icon2:
			"https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/grey/Stena-Drillmax-Gray-150x150.png",
		ais_location: { lat: 8.187658, lng: -57.07386 },
		ais_speed: 0.2,
		ais_course: 53,
		ais_datetime: "2021-12-10T00:32:00Z",
		_ais: "https://www.marinetraffic.com/en/ais/details/ships/shipid:193011/mmsi:235060864/imo:9364942/vessel:STENA_DRILLMAX",
	},
	{
		name: "carron",
		longname: "STENA CARRON",
		on_hire: false,
		imo: 9364954,
		mmsi: 235066948,
		icon: "https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/blue/Stena-Carron-Blue-150x150.png",
		icon2:
			"https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/grey/Stena-Carron-Gray-150x150.png",
		ais_location: { lat: 8.051653, lng: -56.74779 },
		ais_speed: 0.1,
		ais_course: 219,
		ais_datetime: "2021-12-10T00:32:00Z",
		_ais: "https://www.marinetraffic.com/en/ais/details/ships/shipid:193981/mmsi:235066948/imo:9364954/vessel:STENA_CARRON",
	},
	{
		name: "don",
		longname: "STENA DON",
		on_hire: false,
		imo: 8764418,
		mmsi: 232020225,
		icon: "https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/blue/Stena-Don-Blue-150x150.png",
		icon2:
			"https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/grey/Stena-Don-Gray-150x150.png",
		ais_location: { lat: 37.66799, lng: -9.822545 },
		ais_speed: 4.1,
		ais_course: 165,
		ais_datetime: "2021-12-10T00:32:00Z",
		_ais: "https://www.marinetraffic.com/en/ais/details/ships/shipid:711741/mmsi:232020225/imo:8764418/vessel:STENA_DON",
	},
	{
		name: "forth",
		longname: "STENA FORTH",
		on_hire: false,
		imo: 9428932,
		mmsi: 235074573,
		icon: "https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/blue/Stena-Forth-Blue-150x150.png",
		icon2:
			"https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/grey/Stena-Forth-Gray-150x150.png",
		ais_location: { lat: 34.67167, lng: 33.096 },
		ais_speed: 0,
		ais_course: 224,
		ais_datetime: "2021-12-10T01:04Z",
		_ais: "https://www.marinetraffic.com/en/ais/details/ships/shipid:195238/mmsi:235074573/imo:9428932/vessel:STENA_FORTH",
	},
	{
		name: "icemax",
		longname: "STENA ICEMAX",
		on_hire: false,
		imo: 9517575,
		mmsi: 235092459,
		icon: "https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/blue/Stena-IceMax-Blue_V2-150x150.png",
		icon2:
			"https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/grey/Stena-IceMax-Gray_V2-150x150.png",
		ais_location: { lat: 13.589, lng: -17.56983 },
		ais_speed: 0,
		ais_course: 144,
		ais_datetime: "2021-12-10T01:05Z",
		_ais: "https://www.marinetraffic.com/en/ais/details/ships/shipid:199104/mmsi:235092459/imo:9517575/vessel:STENA_ICEMAX",
	},
	{
		name: "spey",
		longname: "STENA SPEY",
		on_hire: false,
		imo: 9517575,
		mmsi: 235092459,
		icon: "https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/blue/Stena-Spey-Blue-150x150.png",
		icon2:
			"https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/grey/Stena-Spey-Gray-150x150.png",
		ais_location: { lat: 58.8993, lng: -2.980708 },
		ais_speed: 0,
		ais_course: 327,
		ais_datetime: "2021-12-10T01:05Z",
		_ais: "https://www.marinetraffic.com/en/ais/details/ships/shipid:184272/mmsi:232662000/imo:8751681/vessel:STENA_SPEY",
	},
];

const getImageLink = (rig: string) => {
	return new Promise((resolve: any, reject) => {
		const data = store.getState().vessels.find((rec: any) => {
			return rec.name === rig;
		});
		if (data?.on_hire === true) {
			if (data?.icon) {
				resolve(data["icon"]);
			} else {
				resolve(
					"https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/invalid/invalid.png"
				);
			}
		} else {
			if (data?.icon2) {
				resolve(data["icon2"]);
			} else {
				resolve(
					"https://dtpwebfiles.blob.core.windows.net/$web/DARWIN_repo/rig_silhouette/invalid/invalid.png"
				);
			}
		}
	});
};

const getRigObject = (rigName: string) => {
	//console.log(store.getState('vessels'))
	const mstore: any = store.getState();
	const vessels: any = mstore.vessels;
	const allvessels: any = vessels.allDetailVessels;
	//console.log('>>',vessels)
	const data = allvessels?.find((rec: any) => {
		return rec.name === rigName;
	});
	try {
		if (data !== undefined && data !== null) {
			if (data?.name) {
				return data;
			} else {
				return undefined;
			}
		}
	} catch (e) {
		// console.error(e);
	}
};

const getRigObjectbyCode = (code: string) => {
	//console.log(store.getState('vessels'))
	const mstore: any = store.getState();
	const vessels: any = mstore.vessels;
	const allvessels: any = vessels.allDetailVessels;
	//console.log('>>',vessels)
	const data = allvessels?.find((rec: any) => {
		console.log(rec);
		return rec.code === parseInt(code);
	});
	try {
		if (data !== undefined && data !== null) {
			if (data) {
				return data;
			} else {
				return undefined;
			}
		}
	} catch (e) {
		// console.error(e);
	}
};

export const getRigs = () => {
	return new Promise((resolve, reject) => {
		//console.log('STORE',store.getState())
		let all_hired_vessels: any = [];
		RigInfo.map((rec: any) => {
			if (rec?.on_hire === true) all_hired_vessels.push(rec);
			return null;
		});
		let all_others: any = [];
		RigInfo.map((rec: any) => {
			if (rec?.on_hire !== true) all_others.push(rec);
			return null;
		});

		resolve([].concat(all_hired_vessels as any, all_others as any));
	});
};

export const loadVessels = () => {
	let AllVessels: any = [];
	let files: any = store.getState().vessels?.vesselFiles || {};
	api.get(`${apiUrl}`)
		.then((_data: any) => {
			//console.log(_data?.data)
			// console.log(_data)
			if (_data?.data && _data.data.length && _data.data.length > 0) {
				// console.log(_data);
				getOperatorContractedVessels(
					`${process.env.REACT_APP_CLIENT_TENANT_GROUP_UID}`
				).then((_contracted: any) => {
					const contractedVessels: any[] = [];
					if (
						process.env.REACT_APP_CLIENT_TENANT_GROUP_UID === "STENA-DRILLING"
					) {
						const allVessels = [100, 200, 300, 400, 700, 800, 900];
						store.dispatch({ type: "CLEAR_CONTRACTED_VESSELS" });
						allVessels.map((_v: any) => {
							contractedVessels.push(_v);
							store.dispatch({ type: "ADD_CONTRACTED_VESSEL", payload: _v });
							return null;
						});
					} else {
						const _contractedVessels = _contracted?.data;
						store.dispatch({ type: "CLEAR_CONTRACTED_VESSELS" });
						if (_contractedVessels?.length) {
							_contractedVessels.map((_v: any) => {
								contractedVessels.push(_v.vessel_code);
								store.dispatch({
									type: "ADD_CONTRACTED_VESSEL",
									payload: _v.vessel_code,
								});
								return null;
							});
						}
					}

					_data.data
						.sort((a: any, b: any) => a.code - b.code)
						.map((vessel: any) => {
							let vessel_image = {
								name: vessel.name,
								icon: vessel.icon_active,
								icon2: vessel.icon_disabled,
								icon3: vessel.icon,
								on_hire: contractedVessels.indexOf(vessel.code) >= 0,
							};

							let _vessel = vessel;
							_vessel.on_hire = vessel_image.on_hire;
							AllVessels.push(_vessel);
							store.dispatch({
								type: "SET_VESSEL_IMAGE",
								payload: vessel_image,
							});
							return null;
						});
					store.dispatch({ type: "SET_VESSEL_FILETAGS", payload: files });
					store.dispatch({ type: "SET_VESSELS_DETAIL", payload: AllVessels });
				});
			} else {
				if (_data.data) {
					RigInfo.map((vesseldata: any) => {
						api.post(`${apiUrl}`, vesseldata)
							.then((_data: any) => {
								//console.log('saved',vesseldata.name)
							})
							.catch((e: any) => {
								// console.error("unable to save", vesseldata.name);
							});
						return null;
					});
				}
			}
		})
		.catch((e: any) => {
			// console.error(e);
		});
};

export { RigInfo, getImageLink, currentRig, getRigObject, getRigObjectbyCode };

//
