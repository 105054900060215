import { Table, TableProps } from "antd";
import { useMemo } from "react";
import CustomParagraph from "./CustomParagraph";
import { duckQuery } from "../services/api-server/deltashare";
import { useMainContext } from "../contexts/MainContext";
import { useDataExplorer } from "../contexts/DataExplorerContext";
import { useComponent } from "../contexts/ComponentContext";

interface DataSourceTableProps extends TableProps<any> {
	onDataset?: (dataset: any) => void;
}

const { Column } = Table;

const DataSourceTable = ({
	onDataset = () => {},
	...restProps
}: DataSourceTableProps) => {
	const { state, dispatch } = useDataExplorer();

	const { metadatas: tables } = useMainContext();
	const { data_authorized } = useComponent();

	const dataSource = useMemo(() => {
		if (tables.length === 0) return [];
		// Filter by schema
		let filteredTables = tables.filter((tbl: any) => {
			return (
				tbl.schema === state.schema && data_authorized?.includes(tbl?.name)
			);
		});

		// When there is a filter
		if (state.searchVal && state.searchVal !== "") {
			filteredTables = filteredTables.filter((table: any) =>
				table.name.includes(state.searchVal)
			);
		}

		const cleanedTable = filteredTables.map((tbl: any) => {
			const {
				metadata: { description = "" },
				name = "",
				id,
			} = tbl;
			return { key: id, id, name, description };
		});
		return cleanedTable;
	}, [tables, data_authorized, state.searchVal, state.schema]);

	const renderColumns = useMemo(() => {
		if (dataSource.length === 0) return [];
		const sampleRow = dataSource[0];
		const cols: any = [];

		for (const field in sampleRow) {
			if (field === "name")
				cols.push(<Column dataIndex={field} key={field} title={"Dataset"} />);
			if (field === "description") {
				const colRender = (value: any) => {
					return <CustomParagraph>{value}</CustomParagraph>;
				};
				cols.push(
					<Column
						dataIndex={field}
						key={field}
						title={"Description"}
						render={colRender}
					/>
				);
			}
		}

		return cols;
	}, [dataSource]);

	const onRow = (record: any) => {
		return {
			onClick: (e: any) => {
				if (e?.target?.tagName?.toLowerCase() !== "a") {
					const found = tables.find((tbl) => tbl.metadata.id === record.id);

					duckQuery(`SELECT * FROM "${record.name}" LIMIT 100`, data_authorized)
						.then((response: any) => {
							dispatch({
								type: "TOP_HUNDRED_DATA",
								payload: response?.response,
							});
						})
						.catch((error) => {
							console.log(error);
						});
					dispatch({ type: "DATASET", payload: found });
					onDataset(found);
				}
			},
			style: { cursor: "pointer" },
		};
	};

	return (
		<Table
			{...restProps}
			size="small"
			rootClassName="data-source-table"
			dataSource={dataSource}
			pagination={false}
			style={{ maxHeight: 400 }}
			bordered
			scroll={{ y: 319 }}
			onRow={onRow}
			title={() => "Datasets"}
		>
			{renderColumns}
		</Table>
	);
};

export default DataSourceTable;
