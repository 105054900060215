import { createContext, useContext, useEffect, useState } from "react";
import { getAllMetaData } from "../utils/utils";
import { useSelector } from "react-redux";
import { listVesselTags } from "../services/api-server/kognifai";
import { Location } from "react-router";

type ToggleFullScreen = () => void;

export type MainContextProps = {
	kognifaiTags?: {};
	setKognifaiTags?: (tags: any) => void;
	location?: Location;
	metadatas: Array<any>;
	metadatasLoading: boolean;
	fullScreen: boolean;
	toggleFullScreen: ToggleFullScreen;
};

const MainContext = createContext<MainContextProps>({
	kognifaiTags: {},
	setKognifaiTags: () => {},
	location: undefined,
	metadatas: [],
	metadatasLoading: false,
	fullScreen: false,
	toggleFullScreen: () => {},
});

type MainProviderProps = {
	location?: Location;
	children?: React.ReactNode;
};

const MainProvider = ({ location, children }: MainProviderProps) => {
	const [fullScreen, setFullScreen] = useState(false);
	const [metadatas, setMetadas] = useState<any[]>([]);
	const [metadatasLoading, setMetadatasLoading] = useState(false);
	const [kognifaiTags, setKognifaiTags] = useState<any>([]);
	const vessels = useSelector((state: any) => state.vessels.allVessels);

	const toggleFullScreen = () => {
		if (!document.fullscreenElement && !fullScreen) {
			document.documentElement.requestFullscreen().catch((err) => {
				console.log(
					`Error attempting to enable full-screen mode: ${err.message} (${err.name})`
				);
			});
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			}
		}
		setFullScreen((prev) => !prev);
	};

	const handleFullScreenChange = () => {
		if (!document.fullscreenElement) {
			setFullScreen(false);
		}
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === "F11") {
			e.preventDefault();
			toggleFullScreen();
		}
	};

	useEffect(() => {
		document.addEventListener("fullscreenchange", handleFullScreenChange);
		window.addEventListener("keydown", handleKeyDown);

		setMetadatasLoading(true);

		getAllMetaData()
			.then((data: any) => {
				if (data && data?.length !== 0) setMetadas(data);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setMetadatasLoading(false);
			});

		return () => {
			document.removeEventListener("fullscreenchange", handleFullScreenChange);
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	// Ignore kognifai fetch vessel tags data for now
	// useEffect(() => {
	// 	const getVesselTags = async () => {
	// 		let tagList: any = {};

	// 		const promises = vessels?.map((vessel: any) =>
	// 			listVesselTags(vessel)
	// 				.then((response: any) => {
	// 					const data: any[] = response?.data;
	// 					if (Array.isArray(data)) {
	// 						const cleanedData = data?.map((d: any) => ({
	// 							label: d?.Name,
	// 							value: d?.Name,
	// 						}));
	// 						return { [vessel]: cleanedData };
	// 					}
	// 				})
	// 				.catch((err) => {
	// 					console.log({
	// 						status: err?.response.status,
	// 						message: err?.response.statusText,
	// 					});
	// 				})
	// 		);

	// 		try {
	// 			const vesselTagsArr = await Promise.all(promises);

	// 			vesselTagsArr?.forEach((vesselTags: any) => {
	// 				tagList = { ...tagList, ...vesselTags };
	// 			});
	// 		} catch (error) {
	// 			console.log(error);
	// 		}

	// 		setKognifaiTags(tagList);
	// 	};

	// 	if (vessels?.length && vessels?.length > 0) {
	// 		getVesselTags();
	// 	}
	// }, [vessels]);

	return (
		<MainContext.Provider
			value={{
				location,
				fullScreen,
				toggleFullScreen,
				metadatasLoading,
				metadatas,
				kognifaiTags,
			}}
		>
			{children}
		</MainContext.Provider>
	);
};

const useMainContext = () => {
	const context = useContext(MainContext);

	if (!context)
		throw new Error("useMainContext must be used within an MainProvider");

	return context;
};

export { MainProvider, useMainContext, MainContext };
