import { Form, FormInstance, Select, Typography } from "antd";

const { Text } = Typography

type DigitalTwinInputsProps = {
    formRef?: FormInstance | null
}

const DigitalTwinInputs = ({ formRef }: DigitalTwinInputsProps) => {
    const handleChange = (value: any, option: any) => {
        formRef?.setFieldValue('dt_data', option)
    }

    return (
        <>
            <Form.Item>
                <Text style={{ fontSize: 20 }}>Digital Twin Settings</Text>
            </Form.Item>
            <Form.Item
                label="Rig"
                name={"dt_data"}
                rules={[{ required: true, message: "Please select a rig" }]}
            >
                <Select
                    labelInValue
                    className={"input"}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    options={[
                        // { key: 'stena_carron', label: "Stena Carron", value: 'dead4.glb', code: '900' },
                        { key: 'stena_carron_2', label: "Stena Carron", value: 'stena_carron.glb', code: '900' },
                        // { key: 'stena_icemax', label: "Stena IceMax", value: 'icemax-new-raw-dead-delete3.glb', code: '100' },
                        { key: 'stena_forth', label: " Stena Forth", value: 'stena_forth.glb', code: '800' },
                        { key: 'stena_drillmax', label: "Stena DrillMAX", value: 'stena_drillmax.glb', code: '400' },
                    ]}
                    onChange={handleChange}
                />
            </Form.Item>
        </>
    )
}

export default DigitalTwinInputs