import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	ModalProps,
	Row,
	Space,
	Switch,
	Table,
	TableProps,
	Typography,
} from "antd";
import { Dispatch, useEffect, useMemo, useState } from "react";
import DataExplorer from "./DataExplorer";
import { useForm } from "antd/es/form/Form";
import {} from "../services/api-server/parameters";
import { transformRecord } from "../utils/utils";
import _ from "lodash";
import { useComponent } from "../contexts/ComponentContext";
import { EditableCell, EditableRow } from "./EditableCell";
import { v4 as uuid } from "uuid";

const { Text, Link } = Typography;

type ParameterFormProps = ModalProps & {
	setFormValues?: Dispatch<any>;
	formValues?: any;
	allParameters?: Parameter[];
	currParameter?: Partial<Parameter>;
	isNew?: boolean;
	handleSaveParameter?: (parameter: Partial<Parameter>) => Promise<void>;
};

interface DataType {
	key: React.Key;
	value: string;
	displayName: string;
	include: boolean;
	fieldValues?: Array<Record<string, any>>;
}

type ColumnTypes = Exclude<TableProps<DataType>["columns"], undefined>;

const ParameterForm = ({
	allParameters = [],
	currParameter,
	isNew,
	handleSaveParameter = async () => {},
	...restProps
}: ParameterFormProps) => {
	const [searchVal, setSearchVal] = useState("");
	const [saveLoading, setSaveLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [fieldValues, setFieldValues] = useState<any[]>([]);
	const [formValues, setFormValues] = useState<any>({});
	const [currentPage, setCurrentPage] = useState(1);
	const [errorModal, setErrorModal] = useState(false);
	const [errorMesages, setErrorMessages] = useState<
		{ name: string; message: string }[]
	>([]);

	const [form] = useForm();

	const values = useMemo(() => {
		const fValues =
			formValues?.fieldValues?.length > 0
				? formValues.fieldValues
				: fieldValues;

		const filteredValues = searchVal
			? fValues?.filter(
					(field: any) =>
						field?.value?.toLowerCase()?.includes(searchVal?.toLowerCase()) ||
						field?.displayName
							?.toLowerCase()
							?.includes(searchVal?.toLowerCase())
			  )
			: fValues;
		return filteredValues?.map((val: any) => ({ ...val }));
	}, [searchVal, formValues?.fieldValues, fieldValues]);

	const columns: ColumnTypes[number] &
		{
			editable?: boolean;
			dataIndex: string;
		}[] = useMemo(() => {
		let defaultColumns: (ColumnTypes[number] & {
			editable?: boolean;
			dataIndex: string;
		})[] = [
			{
				key: "value",
				editable: true,
				dataIndex: "value",
				title: "Value",
			},
			{
				key: "displayName",
				editable: true,
				dataIndex: "displayName",
				title: "Display name",
			},
			{
				key: "include",
				dataIndex: "include",
				title: "Include",
				width: 80,
				render: (value, record, index) => {
					return (
						<Form.Item noStyle>
							<Switch
								checked={value}
								onChange={(checked) => {
									handleFieldValuesChange(
										formValues?.fieldValues?.map((_record: any) => {
											if (_record.key === record.key) {
												return { ...record, include: checked };
											}
											return _record;
										})
									);
								}}
							/>
						</Form.Item>
					);
				},
				align: "center",
			},
		];

		// Render differently when parameter is a custom parameter
		defaultColumns =
			currParameter?.source === "custom"
				? [
						...defaultColumns,
						{
							dataIndex: "action",
							title: "Action",
							render: (value, record) => (
								<Link
									onClick={() => {
										handleFieldValuesChange(
											formValues?.fieldValues?.filter(
												(_record: any) => _record.key !== record.key
											)
										);
									}}
								>
									Delete
								</Link>
							),
							width: 70,
							align: "center",
						},
				  ]
				: defaultColumns;

		return defaultColumns?.map((col) => {
			if (!col.editable) {
				return col;
			}
			return {
				...col,
				onCell: (record: DataType) => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title,
					handleSave: (row: DataType) => {
						handleFieldValuesChange(
							formValues?.fieldValues?.map((_record: any) => {
								if (_record?.key === row?.key) {
									return { ..._record, ...row };
								}
								return _record;
							})
						);
					},
				}),
			};
		});
	}, [currParameter, formValues]);

	const handleFieldValuesChange = (value: any[]) => {
		setFormValues((prev: any) => ({ ...prev, fieldValues: value }));
	};

	const validateFieldValues = () => {
		let errMsgs: { name: string; message: string }[] = [];
		// Checking if at least one field was included
		if (!formValues?.fieldValues?.some((field: any) => field.include)) {
			errMsgs.push({
				message: "Please include at least 1 of the values",
				name: "minimum_include",
			});
		}

		const errorFound = formValues?.fieldValues?.some(
			(field: any) => !field?.value || !field?.displayName
		);

		if (errorFound) {
			errMsgs.push({
				message: "Empty values are not allowed",
				name: "empty_values",
			});
		}

		setErrorMessages(errMsgs);
		if (errMsgs.length !== 0) {
			setErrorModal(true);
			return false;
		}

		return true;
	};

	const handleOnFinish = async () => {
		// Validate fields manually
		if (validateFieldValues()) {
			setSaveLoading(true);
			try {
				const values = await form.validateFields();
				await handleSaveParameter({ ...values, ...formValues });

				form.resetFields();

				setFieldValues([]);
				setFormValues({});
			} catch (error) {
				console.log(error);
			} finally {
				setSaveLoading(false);
			}
		}
	};

	useEffect(() => {
		if (currParameter) {
			form.setFieldsValue(currParameter);

			setFormValues({
				...currParameter,
				fieldValues: transformRecord(currParameter?.fieldValues),
			});
		}
	}, [currParameter]);

	return (
		<Modal
			styles={{ body: { padding: 0 } }}
			style={{ top: 20 }}
			okText={"Save"}
			onOk={handleOnFinish}
			okButtonProps={{
				htmlType: "submit",
				loading: saveLoading,
			}}
			maskClosable={false}
			destroyOnClose
			width={formValues?.source === "custom" ? "40%" : "70%"}
			afterOpenChange={(open) => {
				if (!open) {
					setFormValues({});
					setFieldValues([]);
					form.resetFields();
				}
			}}
			modalRender={(dom) => (
				<Form
					onKeyDown={(e) => {
						// Prevent saving parameter when "Enter" key was pressed
						if (e.key === "Enter") e.preventDefault();
					}}
					form={form}
					layout="vertical"
					onValuesChange={(changedValue, values) => {
						setFormValues((prev: any) => ({ ...prev, ...changedValue }));
					}}
					requiredMark={"optional"}
				>
					{dom}
				</Form>
			)}
			{...restProps}
		>
			<Row>
				<Col
					span={formValues?.source === "custom" ? 24 : 12}
					style={{ padding: 12 }}
				>
					<Form.Item
						name={"name"}
						label={"Name"}
						getValueFromEvent={(e) => e.target.value?.trim()}
						rules={[
							{ required: true, message: "Please input parameter name" },
							{
								validator: (_, value) => {
									const existingParamNames = allParameters
										?.filter((param) => param.id !== currParameter?.id)
										?.map((param) => param?.name?.toLowerCase());
									if (existingParamNames.includes(value?.toLowerCase())) {
										return Promise.reject(
											new Error("Param name must be unique")
										);
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Input addonBefore="@" placeholder="name" />
					</Form.Item>
					<Form.Item
						name={"displayName"}
						label={"Display name"}
						extra="Shown to users on the Dashboard page"
						rules={[{ required: true, message: "Please input display name" }]}
					>
						<Input placeholder="Display name" />
					</Form.Item>
					{/* Commenting this one out for further scope */}
					{/* <Form.Item
						colon={false}
						layout="horizontal"
						name={"mandatory"}
						valuePropName="checked"
					>
						<Checkbox>
							<span>Mandatory</span>
						</Checkbox>
					</Form.Item> */}
					<Form.Item label={"Select Field Values"}>
						<Row gutter={[8, 8]} justify={"space-between"}>
							<Col span={9}>
								{/* To be implemented */}
								<Input
									placeholder="Search values..."
									onChange={(e) => setSearchVal(e.target.value)}
								/>
							</Col>
							<Col>
								<Space>
									<Button
										onClick={() =>
											handleFieldValuesChange(
												formValues?.fieldValues?.map((record: any) => ({
													...record,
													include: false,
												}))
											)
										}
									>
										Exclude all
									</Button>
									<Button
										onClick={() =>
											handleFieldValuesChange(
												formValues?.fieldValues?.map((record: any) => ({
													...record,
													include: true,
												}))
											)
										}
									>
										Include all
									</Button>
									{/* Only for custom parameter */}
									{currParameter?.source === "custom" ? (
										<Button
											type="primary"
											onClick={() => {
												const id = uuid();

												setFormValues((prev: any) => ({
													...prev,
													fieldValues: [
														...prev?.fieldValues,
														{
															value: `value${prev?.fieldValues?.length + 1}`,
															displayName: `value${
																prev?.fieldValues?.length + 1
															}`,
															key: id,
															id,
														},
													],
												}));
											}}
										>
											Add value
										</Button>
									) : null}
								</Space>
							</Col>
							<Col span={24}>
								<Table
									size="small"
									tableLayout="fixed"
									bordered
									columns={columns as ColumnTypes}
									dataSource={values}
									loading={loading}
									components={{
										body: { cell: EditableCell, row: EditableRow },
									}}
									rowClassName={() => "editable-row"}
									pagination={{
										current: currentPage,
										onChange: (page) => {
											setCurrentPage(page);
										},
									}}
								/>
							</Col>
						</Row>
					</Form.Item>
				</Col>
				{formValues?.source === "custom" ? null : (
					<Col span={12} style={{ background: "#141414", padding: 12 }}>
						<DataExplorer
							onField={(values, dataset, fieldName, query) => {
								setCurrentPage(1);
								setFieldValues(values);
								setFormValues((prev: any) => ({
									...prev,
									metadata: {
										name: dataset?.name,
										schema: dataset?.schema,
										field: fieldName,
									},
									fieldValues: values,
									query,
								}));
							}}
						/>
					</Col>
				)}
			</Row>
			<Modal
				cancelButtonProps={{ style: { display: "none" } }}
				title="Error Occurred"
				open={errorModal}
				onCancel={() => setErrorModal(false)}
				onOk={() => setErrorModal(false)}
			>
				{errorMesages?.map((err) => {
					return (
						<Text type="danger" style={{ display: "block" }}>
							{err.message}
						</Text>
					);
				})}
			</Modal>
		</Modal>
	);
};

export default ParameterForm;
