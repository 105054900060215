import axios from "axios";
import { api } from "../../contexts/AuthContext";
import store from "../../state/store";
export const getMaximoData = (
	assetNum: String,
	code: String,
	useTag: Boolean = false
) => {
	let params = {};

	if (useTag) {
		params = { "oslc.where": `assettag="${assetNum}" AND location=${code}` };
	} else {
		params = { "oslc.where": `assetnum="${assetNum}" AND location=${code}` };
	}

	return new Promise((resolve: any, reject: any) => {
		api.get(`/maximo/getMaximoData`, { params: params })
			.then((response: any) => {
				resolve(response.data);
			})
			.catch((err: any) => {
				reject(err);
			});
	});
};

export const getMaximoXMLData = (
	pureQuery: any = null,
	assetNum: String = "",
	assetNumList: any = null,
	code: String = "",
	type: any = null,
	specialQueryString: any | null = null,
	maxItems: any | null = null,
	signal: any = null
) => {
	// let queryString = pureQuery || `ASSETNUM='${assetNum}' and siteid='${code}'`;
	let queryString = pureQuery;
	if (!pureQuery) {
		queryString = `ASSETNUM IN (${assetNumList}) and siteid='${code}'`;
	}

	if (!pureQuery) {
		if (specialQueryString) {
			queryString += specialQueryString;
		} else {
			if (type === "STN_PORTAL_WO") {
				queryString += " and status != 'CLOSE'  order by WONUM asc";
				maxItems = "25";
			}
		}
	}

	return new Promise((resolve: any, reject: any) => {
		api.post(
			`/maximo/getMaximoXMLData`,
			{
				query: queryString,
				type: type,
				maxItems: maxItems,
			},
			{ signal: signal }
		)
			.then((response: any) => {
				resolve(response.data);
			})
			.catch((err: any) => {
				if (axios.isCancel(err)) {
					reject("Canceled");
				}
				reject(err);
			});
	});
};

export const getMaximoList = (assetNum: String) => {
	return new Promise((resolve: any, reject: any) => {
		api.get(`/assets/hierarchy/maximo-list-${assetNum}.json`, {})
			.then((response: any) => {
				resolve(response.data);
			})
			.catch((err: any) => {
				reject(err);
			});
	});
};
