import { io } from "socket.io-client";
import { Tenant } from "../services/api-server/_exports";
const socketServer: string = process.env.REACT_APP_SOCKET_SERVER as string;
const socketIDToken: any = localStorage.getItem(`${Tenant}:idToken`);
let sessionDate: any = new Date().getTime();
sessionDate = (sessionDate - (sessionDate % 1000)) / 1000;

const path =
	process.env.NODE_ENV == "development" ? "/socket.io" : `/${Tenant}/socket.io`;



export const socket = io(socketServer, {
	path: path,
	query: { token: socketIDToken, sessiondt: sessionDate },
	withCredentials: true,
});
