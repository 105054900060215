import React from "react";
import { colorRange } from "../../utils/dataTools";
import * as D3 from "d3";

//To prevent re-rendering of chart
let divFound = false;
const areEqual = (prevProps: any, nextProps: any) => {
	if (prevProps.divFound != nextProps.divFound && divFound == false) {
		return false;
	}
	if (prevProps.rig === nextProps.rig) {
		return true; // donot re-render
	} else {
		return false; // will re-render
	}
};

const Component = (props: any) => {
	const divRef: any = React.useRef();
	const tooltipRef: any = React.useRef();
	const data: any = props.data ? props.data : [];

	const partition: any = (d: any) => {
		const _root = D3.hierarchy(d)
			.sum((d: any) => {
				return d.value;
			})
			.sort((a: any, b: any) => {
				return b.value - a.value;
			});
		return D3.partition().size([2 * Math.PI, _root.height + 1])(_root);
	};

	let parent: any = null;
	let dimension: any = {};
	let x: any = null;
	let y: any = null;
	const updateDimensions = () => {
		dimension = {
			width: () => {
				return (
					(divRef.current ? divRef.current.clientWidth : window.innerWidth) -
					dimension.padding
				);
			},
			height: () => {
				return (
					(divRef.current ? divRef.current.clientHeight : window.innerHeight) -
					dimension.padding
				);
			},
			scaleX: () => {
				return D3.scaleLinear([-100, 100], [0, dimension.width()]);
			},
			scaleY: () => {
				return D3.scaleLinear([-100, 100], [0, dimension.height()]);
			},
			margin: () => {
				return Math.min(dimension.width(), dimension.height()) * 0.05;
			},
			center: () => {
				return [dimension.width() / 2, dimension.height() / 2];
			},
			padding: props.padding ? props.padding : 10,
		};
		x = D3.scaleLinear().rangeRound([0, dimension.width()]);
		y = D3.scaleLinear().rangeRound([0, dimension.height()]);
	};

	updateDimensions();
	// const color:any = D3.scaleOrdinal(D3.quantize(D3.interpolateRainbow, props.data.children.length + 1))
	const color: any = D3.scaleOrdinal().range(colorRange);
	const format: any = D3.format(",d");
	const svgRef: any = React.useRef();
	const radius: any = () => {
		// calculate radius
		return dimension.height() / 6 - dimension.padding;
		return (
			Math.min(dimension.height(), dimension.width()) / 6 - dimension.padding
		);
	};
	let svg: any = D3.selectAll(svgRef.current)
		// .attr('viewBox',[0,0,dimension.width(), dimension.width()])
		.attr("viewBox", 0)
		.style("font", "10px sans-serif");

	let g: any = undefined;

	const getLabel: any = (data: any) => {
		if (props.formatLabel && typeof props.formatLabel === "function") {
			return props.formatLabel(data);
		} else {
			return data.data.label
				? data.data.label.length < 5
					? data.data.label
					: ""
				: "";
		}
	};
	const setStage: any = () => {
		const el: any = D3.select(svgRef.current);
		el.attr("id", "sunburst")
			.attr("viewBox", [0, 0, dimension.width(), dimension.width()])
			.style("font", "10px sans-serif");

		D3.selectAll("#sunburst").selectAll("#chart-canvas").remove();
		D3.selectAll("#sunburst").append("g").attr("id", "chart-canvas");
		g = D3.select("#chart-canvas");

		g.attr(
			"transform",
			`translate(${dimension.width() / 2},${dimension.width() / 2})`
		);

		// console.log('g',dimension.width(),g)
	};
	const arcVisible = (d: any) => {
		return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
	};

	const labelVisible = (d: any) => {
		return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
	};
	const _radius: any = radius();
	const labelTransform = (d: any) => {
		const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
		const y = ((d.y0 + d.y1) / 2) * _radius;
		return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
	};

	const arc: any = D3.arc()
		.startAngle((d: any) => {
			return d.x0;
		})
		.endAngle((d: any) => {
			return d.x1;
		})
		.padAngle((d: any) => {
			return Math.min((d.x1 - d.x0) / 2, 0.005);
		})
		.innerRadius((d: any) => {
			return d.y0 * _radius;
		})
		.outerRadius((d: any) => {
			return Math.max(d.y0 * _radius, d.y1 * _radius - 1);
		});

	const getParentNodeAtDepth: any = (node: any, targetDepth: number) => {
		if (node.depth === targetDepth) {
			return node;
		} else if (node.parent) {
			return getParentNodeAtDepth(node.parent, targetDepth);
		}
		return null;
	};

	/*
    prepare the data and transform it to a partitioned tree
    */
	const root: any = partition(props.data);
	root.each((d: any) => {
		d.current = d;
	});

	/*
    initialise the chart
    */
	const init: any = () => {
		setStage();
		const el: any = D3.select(svgRef.current);
		D3.select(svgRef.current)
			.select("#chart-canvas")
			.append("g")
			.attr("id", "test");
		const g: any = D3.select(svgRef.current).select("#chart-canvas");
		const path: any = g
			.append("g")
			.selectAll("path")
			.data(root.descendants().slice(1))
			.join("path")
			.attr("fill", (d: any) => {
				const node = getParentNodeAtDepth(d, 1);
				if (node.data.id == "DT") {
					return "#791A1F";
				} else if (node.data.id === "NPT") {
					return "#AA7714";
				} else {
					return node.current.data.color
						? node.current.data.color
						: color(node.data.label);
				}
			})
			.attr("fill-opacity", (d: any) => {
				return arcVisible(d.current) ? 1 : 0;
			})
			.attr("pointer-events", (d: any) => {
				return arcVisible(d.current) ? "auto" : "none";
			})
			.attr("d", (d: any) => {
				return arc(d.current);
			})
			.attr("data-level", (d: any) => (d.children ? "parent" : "children"))
			.on("mouseover", (ev: any, d: any) => {
				if (svgRef.current) {
					updateTooltip(ev, d.data.name);
				}
			})
			.on("mouseout", (ev: any, d: any) => {
				updateTooltip(ev, "");
			});

		/*
        prepare the labels
        */
		const label: any = g
			.append("g")
			.attr("pointer-events", "none")
			.attr("text-anchor", "middle")
			.style("user-select", "none")
			.selectAll("text")
			.data(root.descendants().slice(1))
			.join("text")
			.attr("id", "label")
			.attr("class", "segment-label")
			.attr("dy", (d: any) => {
				return `0.85em`;
			})
			.attr("fill-opacity", (d: any) => {
				return +labelVisible(d.current);
			})
			.attr("transform", (d: any) => {
				return labelTransform(d.current);
			})
			.text((d: any) => {
				return getLabel(d);
			});

		const parent = g
			.append("circle")
			.datum(root)
			.attr("id", "root")
			.attr("r", _radius - 3)
			.attr("fill", (d: any) => {
				return "white";
			})
			.attr("class", "parent")
			.attr("pointer-events", "all")
			.attr("cursor", "zoom-out");

		D3.select(svgRef.current).select("#parent-label").remove();
		const parentLabel = g
			.append("g")
			.attr("id", "parent-label")
			.append("text")
			.datum(root)
			.attr("id", "parent-label-text")
			.attr("font-size", "1.8em")
			.attr("fill", "red")
			.attr("text-anchor", "middle")
			.attr("x", 0) //()=>{return dimension.width()/2})
			.attr("y", 0) //()=>{return dimension.height()/2})
			.text("");

		const clickEvent: any = (event: any, node: any) => {
			// const g:any = D3.selectAll(svgRef).select('#chart-canvas')

			const dataLevel = event.target.getAttribute("data-level");
			switch (dataLevel) {
				case "parent":
					props.openDetailsModal(node?.data);
					return;
			}

			parent.datum(node.parent || root);
			root.each((d: any) => {
				return (d.target = {
					x0:
						Math.max(0, Math.min(1, (d.x0 - node.x0) / (node.x1 - node.x0))) *
						2 *
						Math.PI,
					x1:
						Math.max(0, Math.min(1, (d.x1 - node.x0) / (node.x1 - node.x0))) *
						2 *
						Math.PI,
					y0: Math.max(0, d.y0 - node.depth),
					y1: Math.max(0, d.y1 - node.depth),
				});
			});

			D3.select(svgRef.current)
				.select("#root")
				.attr("fill", () => {
					const parentColor: any = node.data
						? node.data.color
							? node.data.color
							: "#fff"
						: "#fff";

					return parentColor;
				});

			D3.select(svgRef.current).select("#parent-label").remove();

			// to-do - add a tspan to provide multi-line text
			const parentLabel = g
				.append("g")
				.attr("id", "parent-label")
				.append("text")
				.attr("id", "parent-label-text")
				.attr("font-size", "1.8em")
				.attr("font-weight", "bold")
				.attr("fill", "black")
				.attr("text-anchor", "middle")
				.attr("x", 0)
				.attr("y", 0)
				.text(() => {
					return getLabel(node);
				});

			const t: any = g.transition().duration(750);

			path
				.transition(t)
				.tween("data", (d: any) => {
					const i: any = D3.interpolate(d.current, d.target);
					return (t: any) => {
						return (d.current = i(t));
					};
				})
				.filter((d: any, i: any, x: any) => {
					return +x[i].getAttribute("fill-opacity") || arcVisible(d.target);
				})
				.attr("fill-opacity", (d: any) => {
					// return arcVisible(d.target)? 1/(d.depth/2):0
					return arcVisible(d.target) ? 1 : 0;
				})
				.attr("pointer-events", (d: any) => {
					return arcVisible(d.target) ? "auto" : "none";
				})
				.attrTween("d", (d: any) => {
					return () => arc(d.current);
				});

			label
				.filter((d: any, i: any, x: any) => {
					return +x[i].getAttribute("fill-opacity") || labelVisible(d.target);
				})
				.transition(t)
				.attr("fill-opacity", (d: any) => {
					return +labelVisible(d.target);
				})
				.attrTween("transform", (d: any) => {
					return () => labelTransform(d.current);
				});

			const _x: any = D3.selectAll("g#parent-label").attr(
				"pointer-events",
				"bounding-box"
			);
			_x.attr("cursor", "default");
			D3.selectAll("g#parent-label").on("click", (ev: any, el: any) => {
				//console.log(node);
				clickEvent(ev, node.parent ? node.parent : node);
			});
		};

		D3.selectAll("text.segment-label").on("click", clickEvent);
		parent.on("click", clickEvent);
		path
			// .filter((d: any) => {
			// 	return d.children;
			// })
			.style("cursor", "pointer")
			.on("click", clickEvent);

		// path.append("title").text((d: any) => {
		// 	return `${d
		// 		.ancestors()
		// 		.map((d: any) => {
		// 			return d.data.name;
		// 		})
		// 		.reverse()
		// 		.join("/")}\n${format(d.value)}`;
		// });
	};
	React.useEffect(() => {
		updateDimensions();
		init();
	});

	React.useEffect(() => {
		updateDimensions();
		init();
		props.divHasFound();
	}, [divRef]);

	updateDimensions();
	init();

	const formatLabel: any = (data: any) => {
		if (props.formatLabel && typeof props.formatLabel === "function") {
			return props.formatLabel(data);
		} else {
			return data;
		}
	};

	const updateTooltip = (ev: any, data: any, _default: string = "") => {
		// the mouse event will provide the X and Y coordinates
		// Using Layer event value will use the current device viewport position

		const clientX: number = ev.layerX;
		const clientY: number = ev.layerY;
		const tt = D3.select(tooltipRef.current);
		let _text = formatLabel(data);
		let text = _text ? _text : _default;
		tt.style("left", `${clientX - 40}px`);
		tt.style("top", `${clientY - 35}px`);
		D3.select(tooltipRef.current).text(text);
		if (!text) {
			tt.style("display", "none");
		} else if (text.length === 0) {
			// if empty text, hide the tooltip
			tt.style("display", "none");
		} else {
			tt.style("display", "block");
			tt.style("color", "white");
		}
	};

	return (
		<>
			<div
				ref={divRef}
				style={{
					height: "100%",
					width: "100%",
				}}
			>
				<svg ref={svgRef} key="x"></svg>
				<div
					onMouseOverCapture={() => {
						return null;
					}}
					className="tooltip"
					ref={tooltipRef}
				></div>
			</div>
		</>
	);
};

export default React.memo(Component, areEqual);
