const ACTIONTYPES = {
	VIEW_SCHEMAS: "VIEW_SCHEMAS",
	VIEW_DATASETS: "VIEW_DATASETS",
	VIEW_FIELDS: "VIEW_FIELDS",
	ONSEARCH: "ONSEARCH",
	TAB: "TAB",
};

const MODE_TYPE = {
	FIELD: "FIELD",
	DATASET: "DATASET",
	SCHEMA: "SCHEMA",
};

export type DataExplorerState = {
	schema: string;
	dataset: { name: string; fields: any };
	field: string | undefined;
	mode: string;
	history: { mode: string; title: string }[];
	topHundredData: Array<any>;
	activeTab: string;
	searchVal: undefined | string;
};
export type DataExplorerAction =
	| {
			type: "SCHEMA";
			payload: string;
	  }
	| {
			type: "DATASET";
			payload: { name: string; fields: any };
	  }
	| {
			type: "FIELD";
			payload: string;
	  }
	| {
			type: "TOP_HUNDRED_DATA";
			payload: Array<any>;
	  }
	| {
			type: "VIEW_SCHEMAS";
	  }
	| {
			type: "VIEW_DATASETS";
	  }
	| {
			type: "VIEW_FIELDS";
	  }
	| {
			type: "ONSEARCH";
			payload: string;
	  }
	| {
			type: "TAB";
			payload: string;
	  };

const INIITAL_STATE: DataExplorerState = {
	schema: "",
	dataset: { name: "", fields: undefined },
	mode: MODE_TYPE.SCHEMA,
	history: [{ mode: MODE_TYPE.SCHEMA, title: "Schema" }],
	topHundredData: [],
	field: undefined,
	activeTab: "",
	searchVal: undefined,
};

const reducer = (
	state: DataExplorerState,
	action: DataExplorerAction
): DataExplorerState => {
	switch (action.type) {
		case "TAB":
			return { ...state, activeTab: action.payload };

		case "VIEW_SCHEMAS":
			return {
				...state,
				mode: MODE_TYPE.SCHEMA,
				topHundredData: INIITAL_STATE.topHundredData,
				schema: INIITAL_STATE.schema,
				dataset: INIITAL_STATE.dataset,
				field: INIITAL_STATE.field,
				history: INIITAL_STATE.history,
				searchVal: INIITAL_STATE.searchVal,
				activeTab: INIITAL_STATE.activeTab,
			};

		case "VIEW_DATASETS":
			const index1 = state.history.findIndex(
				(h: any) => h.mode === MODE_TYPE.DATASET
			);

			return {
				...state,
				mode: MODE_TYPE.DATASET,
				history: state.history.slice(0, index1),
				searchVal: INIITAL_STATE.searchVal,
				topHundredData: INIITAL_STATE.topHundredData,
				dataset: INIITAL_STATE.dataset,
				field: INIITAL_STATE.field,
				activeTab: INIITAL_STATE.activeTab,
			};

		case "VIEW_FIELDS":
			const index2 = state.history.findIndex(
				(h: any) => h.mode === MODE_TYPE.FIELD
			);

			return {
				...state,
				mode: MODE_TYPE.FIELD,
				history: state.history.slice(0, index2),
				field: INIITAL_STATE.field,
				searchVal: INIITAL_STATE.searchVal,
				activeTab: INIITAL_STATE.activeTab,
			};

		case "SCHEMA":
			return {
				...state,
				schema: action.payload,
				mode: MODE_TYPE.DATASET,
				searchVal: INIITAL_STATE.searchVal,
				history: state.history?.map((h: any) => {
					if (h.mode === MODE_TYPE.SCHEMA) {
						return { ...h, title: action.payload };
					}
					return h;
				}),
				activeTab: INIITAL_STATE.activeTab,
			};

		case "DATASET":
			return {
				...state,
				dataset: action.payload,
				mode: MODE_TYPE.FIELD,
				searchVal: INIITAL_STATE.searchVal,
				history: [
					...state.history.filter((h: any) => h.mode),
					{ mode: MODE_TYPE.DATASET, title: action.payload.name },
				],
				activeTab: INIITAL_STATE.activeTab,
			};

		case "FIELD":
			return {
				...state,
				field: action.payload,
				mode: MODE_TYPE.FIELD,
				activeTab: INIITAL_STATE.activeTab,
				history: state.history.find(
					(h: any) => h.mode === MODE_TYPE.FIELD
				)
					? state.history
							.filter((h: any) => h.mode)
							.map((h: any) => {
								if (h.mode === MODE_TYPE.FIELD) {
									return {
										mode: MODE_TYPE.FIELD,
										title: action.payload,
									};
								}
								return h;
							})
					: [
							...state.history.filter((h: any) => h.mode),
							{ mode: MODE_TYPE.FIELD, title: action.payload },
					  ],
			};

		case "TOP_HUNDRED_DATA":
			return { ...state, topHundredData: action.payload };

		case "ONSEARCH":
			return { ...state, searchVal: action.payload };

		default:
			return { ...state };
	}
};

export { INIITAL_STATE, reducer, ACTIONTYPES, MODE_TYPE };
