import { Popconfirm, Typography } from "antd";
import { useCallback, useContext } from "react";
import { CustomDashboardContext } from "../contexts/context";
import useDashboards from "../hooks/useDashboards";
import { deleteVisual } from "../services/api-server/visuals";
import { VisualType } from "../types/Visual";
import { Layout } from "react-grid-layout";
import { CustomLayout } from "../types/CustomLayout";
import _ from "lodash";
import { updateVisualGroup } from "../services/api-server/visualsGroups";
import Emitter from "../services/EventEmitter";
import { useComponent } from "../contexts/ComponentContext";

interface VisualDeleteHandlerProps {
	visual: VisualType;
	layoutId?: string;
}

const { Link, Paragraph } = Typography;

const VisualDeleteHandler = ({
	visual,
	layoutId,
}: VisualDeleteHandlerProps) => {
	const { dispatch: customDashboardDispatch, visual_groups } = useContext(
		CustomDashboardContext
	);

	const { menuItem } = useComponent();

	const { dashboards, saveDashboards } = useDashboards();

	const getDescription = () => {
		const count = visual?.layoutIds?.length;
		const noun = visual?.layoutIds?.length > 1 ? "items" : "item";
		const conjunction = visual?.layoutIds?.length > 1 ? "items" : "item";
		const message = `There ${conjunction} ${count} dashboard ${noun} using this, delete this visual will delete relevant visuals on other dashboards`;

		return count !== 0 ? <Paragraph>{message}</Paragraph> : null;
	};

	const handleRemoveLayout = useCallback(() => {
		customDashboardDispatch({
			type: "REMOVE_FROM_DASHBOARD",
			payload: layoutId,
		});
	}, [layoutId]);

	const handleDeleteVisual = async () => {
		// group layouts
		const layoutsToRemove: string[] = [];

		//  Handling dashboards
		const updatedDashboards = dashboards?.map((dashboard: any) => {
			const { dashboard_layout = [] } = dashboard;

			const updatedLayout = dashboard_layout
				?.map((el: any) => {
					const { children = [] } = el;

					const updatedChildrenLayout = children?.filter(
						(child: Layout) => !visual?.layoutIds.includes(child.i)
					);

					return { ...el, children: updatedChildrenLayout };
				})
				.filter((el: CustomLayout) => {
					const toRemove = el.children?.length === 0;

					if (toRemove && el.isGroup) {
						layoutsToRemove.push(el.i);
					}

					return !toRemove;
				});

			return { ...dashboard, dashboard_layout: updatedLayout };
		});

		// Handling visuals
		try {
			const response: any = await deleteVisual(visual.id, menuItem.key);
			await saveDashboards(updatedDashboards, menuItem.key);

			for (const _group of visual_groups) {
				const found = _group?.layoutIds?.find((_layoutId: string) =>
					layoutsToRemove?.includes(_layoutId)
				);
				if (found) {
					const updatedGroup = {
						..._group,
						layoutIds: _group?.layoutIds?.filter(
							(_layoutId: string) => !layoutsToRemove?.includes(_layoutId)
						),
					};
					await updateVisualGroup(updatedGroup);
				}
			}

			Emitter.emit("alert", {
				type: "success",
				message: "Visual deleted successfully",
				description: "You have successfully deleted a visual",
				timeout: 5000,
			});

			console.log("Visual deleted:" + response.data);
		} catch (e) {
			Emitter.emit("alert", {
				type: "error",
				message: "Unsuccessful visual deletion",
				description: "There was an error while deleting visual",
				timeout: 5000,
			});
			console.log(e);
		}
	};

	if (layoutId) {
		return <Link onClick={handleRemoveLayout}>Delete</Link>;
	}

	return (
		<Popconfirm
			title="Are you sure you want to delete this visual?"
			description={getDescription}
			onConfirm={handleDeleteVisual}
		>
			<Link>Delete</Link>
		</Popconfirm>
	);
};

export default VisualDeleteHandler;
