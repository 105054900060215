import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useLocation } from "react-router-dom";
import { getReleaseNotes } from "../services/api-server/documents";
import { connect } from "react-redux";
import Search from "antd/lib/input/Search";
import { handleSearch } from "../utils/search";

class Release extends React.Component<any> {
	state: any = {
		markdown: null,
		contenttable: null,
		elementid: null,
		hash: null,
	};

	componentDidMount() {
		getReleaseNotes().then((res: any) => {
			let textString: string = "";
			let tableofcontents: any = [];
			const textdata = res.data.data;
			// console.log(textdata);

			Object.keys(textdata)
				.sort()
				.map((type: any) => {
					const text_type = textdata[type];
					if (type === "releasenotes") {
						textString = textString + `# **RELEASE NOTES**`;
					}
					if (type === "projectinfo") {
						textString = textString + `# **PROJECT INFORMATION**`;
					}

					Object.keys(text_type)
						.sort(
							(a: any, b: any) =>
								Date.parse(text_type[b].date) - Date.parse(text_type[a].date)
						)
						.map((payload: any) => {
							let text_payload = text_type[payload];
							let obj: any = {};
							if (type !== "projectinfo") {
								textString =
									textString +
									"<br/> \r\n" +
									`## ${text_payload.title} <a id=${text_payload.version} name=${text_payload.version}></a>`;
								obj["title"] = text_payload.title;
								obj["version"] = text_payload.version;
								tableofcontents.push(obj);
							}
							text_payload.notes.map((notes: any) => {
								textString = textString + "<br/> \r\n" + notes;
								return true;
							});
							return true;
						});
					textString = textString + "<br/> \r\n";
					return true;
				});
			this.setState({ markdown: textString, contenttable: tableofcontents });
		});
	}

	render() {
		// const fetchfile = () => {
		//   fetch(file)
		//     .then((res) => res.text())
		//     .then((text) => this.setState({ markdown: text }));
		// }
		const WithRouter = () => {
			const hash = useLocation().hash;
			if ((!this.state.hash && hash !== "") || this.state.hash !== hash) {
				const id = hash.replace("#", "");
				this.setState({ elementid: id, hash: hash });
			}
			if (this.state.elementid) {
				const element = document.getElementById(this.state.elementid);
				if (element && !this.state.initialScroll) {
					element.scrollIntoView(true);
					this.setState({ initialScroll: true });
				}
			}
			return null;
		};
		return (
			<>
				{!this.props.ismobile ? (
					<div className="markdown-container">
						<div className="markdown-top-container">
							<div className="markdown-title-container">
								<div className="markdown-title">Release Notes</div>
								<p style={{ width: "90%", fontSize: "16px" }}>
									You will be notified before a new version is released. Upon
									release, you will receive a notification that a new version
									has been released. Release Notes can be found below
								</p>
							</div>
							<div className="markdown-contact-container">
								<span>Contact</span>
								<div
									className="divider"
									style={{
										borderBottom: "1px solid rgba(0,0,0,0.1)",
										marginLeft: "-10px",
									}}
								></div>
								<span style={{ fontWeight: "bold" }}>Need help? </span>
								<br />
								<span>Please contact the support team:</span>
								<br />
								<span
									style={{ fontWeight: "bold", textDecoration: "underline" }}
									data-tip="Contact Support"
								>
									<a
										className="md-link"
										href="mailto:portal-support@stena-evolve.com?subject=Portal Support"
									>
										portal-support@stena-evolve.com
									</a>
								</span>
							</div>
						</div>
						<div className="markdown-bottom-container">
							<>
								{this.state.markdown ? (
									<div className="markdown-notes">
										<ReactMarkdown
											children={this.state.markdown}
											rehypePlugins={[rehypeRaw]}
										/>
										<WithRouter></WithRouter>
									</div>
								) : null}
							</>
							<div className="markdown-table-of-contents">
								{this.state.contenttable ? (
									<>
										<span style={{ fontSize: "1.5em", fontWeight: "bold" }}>
											Version Log
										</span>{" "}
										<br />
										<span style={{ textDecoration: "underline" }}>
											{this.state.contenttable.map((element: any) => {
												return (
													<>
														<a className="md-link" href={`#${element.version}`}>
															{element.title}
														</a>{" "}
														<br />
													</>
												);
											})}
										</span>
									</>
								) : null}
							</div>
						</div>
					</div>
				) : (
					<div className="markdown-container-mobile">
						<div style={{ padding: "5%" }}>
							<Search
								allowClear
								placeholder="Search..."
								onSearch={handleSearch}
							/>
							<div className="markdown-title-container">
								<div className="markdown-title" style={{ marginTop: "5%" }}>
									Release Notes
								</div>
								<p style={{ fontSize: "16px" }}>
									You will be notified before a new version is released. Upon
									release, you will receive a notification that a new version
									has been released. Release Notes can be found below
								</p>
							</div>
							<div
								className="markdown-content-mobile"
								style={{ padding: "5% 0px" }}
							>
								<span>Need help? Please contact the support team:</span>
								<br />
								<span
									style={{ fontWeight: "bold", textDecoration: "underline" }}
									data-tip="Contact Support"
								>
									<a
										style={{ color: "rgb(131, 195, 255)" }}
										href="mailto:portal-support@stena-evolve.com?subject=Portal Support"
									>
										portal-support@stena-evolve.com
									</a>
								</span>
							</div>
						</div>
						<div className="markdown-bottom-container-mobile">
							<div className="markdown-table-of-contents">
								{this.state.contenttable ? (
									<>
										<span style={{ fontSize: "2em", fontWeight: "bold" }}>
											Version Log
										</span>{" "}
										<br />
										<span style={{ textDecoration: "underline" }}>
											{this.state.contenttable.map((element: any) => {
												return (
													<>
														<a className="md-link" href={`#${element.version}`}>
															{element.title}
														</a>{" "}
														<br />
													</>
												);
											})}
										</span>
									</>
								) : null}
							</div>
							<>
								{this.state.markdown ? (
									<div className="markdown-notes" style={{ margin: "10% 0%" }}>
										<ReactMarkdown
											children={this.state.markdown}
											rehypePlugins={[rehypeRaw]}
										/>
										<WithRouter></WithRouter>
									</div>
								) : null}
							</>
						</div>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(Release);
