import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { User } from "./Security";
import { ReactComponent as Sun } from "../assets/img/day-mode.svg";
import { ReactComponent as Moon } from "../assets/img/night-mode.svg";
import Emitter from "./EventEmitter";
import { connect } from "react-redux";
import store from "../state/store";
import Email from "./Email";
import { v4 } from "uuid";
import { loadMenu } from "./api-server/menu";
import { Switch } from "antd";
import { GetComponentIcon } from "../utils/svg_icons";
import SideMenu from "./SideMenu";

const EventEmitter = require("events");

const { SubMenu } = Menu;

export interface IMenuItem {
	sequence: number;
	menuid: string;
	component: string;
	is_divider: boolean;
	roles?: string[];
	clickable?: boolean;
	requireAdmin?: boolean | undefined;
	label: string;
	route?: { link: string; params: {} };
	link?: string;
	icon?: any;
}
export interface IMenuSection {
	sequence: number;
	sectionid?: string;
	label?: string | null | undefined;
	items: IMenuItem[];
	icon?: any;
}
export interface IMenuProduct {
	sequence: number;
	productid: string;
	label: string;
	sections?: IMenuSection[];
	icon?: any;
}
export interface IMenu {
	products: any | any[];
}

export class CMainMenu extends React.Component<any> {
	props: any = {
		collapsed: false,
		onCollapse: null,
		role: null,
	};

	state: any = {
		secondary_color: "white",
		href: window.location.href.split("/").pop(),
		available_menu_items: ["Something New"],
		menudata: [],
		role: null,
		openParent: [],
		loaded: false,
		drawer: false,
		mode: document.body.classList.contains("dark-mode"),
	};

	eventemitter = new EventEmitter();

	_user: any = new User();

	constructor(props: any) {
		super(props);
		this.props = props;
		this.onCollapse = this.onCollapse.bind(this);
		this._user.init();
	}

	componentDidMount() {
		Emitter.on("onpage", (href: any) => {
			this.setState({
				href: href,
			});
		});

		Emitter.on("MenuSaved", () => {
			loadMenu().then((d: any) => {
				if (d === undefined || d === null) {
				} else {
					store.dispatch({
						type: "SET_ALL_MODULES",
						payload: { ...this.props.allModules, mainMenu: d?.menu },
					});
				}
				this.setState({ loaded: true });
			});
		});
		window.addEventListener("popstate", this.windowpop);

		Emitter.on("MenuReload", (payload: any) => {
			if (payload === undefined || payload === null) {
			} else {
				store.dispatch({
					type: "SET_ALL_MODULES",
					payload: { ...this.props.allModules, mainMenu: payload?.menu },
				});
			}
			this.setState({ loaded: true });
		});
		loadMenu().then((d: any) => {
			if (d === undefined || d === null) {
			} else {
				store.dispatch({
					type: "SET_ALL_MODULES",
					payload: { ...this.props.allModules, mainMenu: d?.menu },
				});
			}

			let defaultMenu: any = d?.menu?.find((menuItem: any) => {
				return menuItem?.route?.link === this.state.href;
			});

			if (defaultMenu) {
				this.setState({ selectedKey: defaultMenu?.key });
			}

			this.setState({ loaded: true });
		});
	}

	componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	): void {
		if (
			this.state.available_menu_items.length > 0 &&
			this.state.available_menu_items !== prevState.available_menu_items
		)
			this.props.dispatch({
				type: "SET_AVAILABLE_COMPONENTS",
				payload: this.state.available_menu_items,
			});
	}

	componentWillUnmount() {
		Emitter.off("onpage", null);
		window.removeEventListener("popstate", this.windowpop);
	}

	windowpop = () => {
		//console.log("this happens");

		this.setState({ href: window.location.href.split("/").pop() });
	};

	sethref = (isAdmin: boolean) => {
		this.setState({
			href: window.location.href.split("/").pop(),
		});
	};

	selectMenu = (ev: any, parentkey: string | null = null, component: any) => {
		if (ev.key) {
			//console.log('parentkey=',parentkey)
			this.props.dispatch({ type: "SET_MODULE", payload: ev.key });
			this.props.dispatch({ type: "SET_COMPONENT", payload: component });
			if (parentkey !== null) {
				let parents: any = [];
				let parentLength = ev.keyPath.length;
				for (let i = 0; i < parentLength - 1; i++) {
					parents[i] = ev.keyPath[parentLength - 1 - i];
				}
				// console.log(parents)
				this.setState({ openParent: parents });
			} else {
				this.setState({ openParent: null });
			}
		} else {
			this.props.dispatch({ type: "SET_MODULE", payload: null });
		}
		return false;
	};

	getVisibility = (mItem: any) => {
		let visible: boolean = true;

		if (!this.getAccess(mItem)) {
			visible = false;
		} else {
			if (mItem.visible !== undefined) {
				if (!mItem.visible) {
					visible = false;
				} else if (mItem?.route?.link === "" || mItem?.title === "") {
					visible = false;
				} else if (!mItem?.component) {
					visible = false;
				}
			}
		}

		return visible;
	};

	getAccess = (mItem: any) => {
		let access: boolean = false;

		if (this.props.role.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
			access = true;
		} else if (mItem.roles?.length === 0 || !mItem.roles) {
			access = true;
		} else {
			mItem.roles.forEach((role: any) => {
				if (this.props.role.includes(role)) {
					access = true;
				}
			});
		}

		return access;
	};

	onMenuSelected = ({ key, type }: any) => {
		this.setState({ selectedKey: key });
		if (type === "sidebar") {
			this.setState({ openKeys: [] });
		}
		this.sethref(false);
	};

	render_menu_item = (
		menuitem: any,
		level: number = 0,
		parentkey: string | null = null,
		parentroute: string | null = null,
		route: string | null = null
	) => {
		// set this change menu options

		let finalroute: string = "";
		let access: any = false;
		if (route) {
			finalroute = `${route}/${menuitem.route.link}`;
		} else {
			finalroute = `${menuitem.route.link}`;
		}
		const uuid = v4();

		if (this.props.role.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
			access = true;
		} else if (menuitem.roles?.length === 0 || !menuitem.roles) {
			access = true;
		} else {
			menuitem.roles.map((element: any) => {
				if (this.props.role.includes(element)) {
					access = true;
				}
				return true;
			});
		}

		const renderParams = (mitem: any) => {
			let paramObj: any = {};
			let route_params: any = mitem.route?.params;
			paramObj["component"] = mitem.component;
			if (mitem.roles) {
				paramObj["roles"] = mitem.roles;
			}

			if (route_params !== undefined && route_params !== null) {
				route_params?.map((p: any) => {
					//console.log('p',p)
					paramObj[`${p.key}`] = p.value;
					return true;
				});
				// console.log('paramObj',paramObj)
			}

			if (paramObj) {
				return { params: paramObj };
			} else {
				return { params: null };
			}
		};

		if (menuitem?.is_divider) {
			return <Menu.Divider key={uuid}></Menu.Divider>;
		}

		if (!menuitem.menuid) {
			menuitem.menuid = uuid;
		}

		if (menuitem?.title) {
			if (menuitem.route !== undefined && access) {
				let available_menu_items = [...this.state.available_menu_items];
				if (
					!available_menu_items.includes(menuitem.component) &&
					menuitem.component
				) {
					available_menu_items.push(menuitem.component);
					this.setState({ available_menu_items: available_menu_items });
				}

				if (level >= 2) {
					return (
						<Menu.Item
							key={menuitem.route.link}
							className={
								menuitem?.title.length > 20 && !this.props.ismobile
									? "wrap_menu_title"
									: ""
							}
							onClick={(ev: any) => {
								this.selectMenu(ev, parentkey, menuitem.component);
								this.sethref(false);
							}}
						>
							<Link to={`${finalroute}`} state={renderParams(menuitem)} />
							<div className="dropdown_title">{menuitem.title}</div>
						</Menu.Item>
					);
				} else {
					return (
						<Menu.Item
							key={menuitem.route.link}
							className={
								menuitem?.title.length > 20 && !this.props.ismobile
									? "wrap_menu_title"
									: ""
							}
							onClick={(ev: any) => {
								this.selectMenu(ev, parentkey, menuitem.component);
								this.sethref(false);
							}}
							icon={
								this.props.ismobile
									? null
									: GetComponentIcon({ componentName: menuitem.component })
							}
						>
							<Link to={`${finalroute}`} state={renderParams(menuitem)}>
								{menuitem.title}
							</Link>
						</Menu.Item>
					);
				}
			}
		}
	};

	render_menu_item_icon = (menuitem: any) => {
		const uuid = v4();
		const AdminCheck = () => {
			const roles = this.props.role;
			for (var i = 0; i < roles.length; i++) {
				if (roles[i].toLowerCase().includes("admin")) {
					return true;
				}
			}
		};

		if (menuitem?.is_divider) {
			return <Menu.Divider key={uuid}></Menu.Divider>;
		}
		if (menuitem.menuid === undefined) {
			if (menuitem.productid !== undefined) {
				menuitem.menuid = menuitem.productid;
			}
		}
		if (menuitem.icon === undefined) {
			menuitem.icon = "bulb";
		}
		if (menuitem.state !== undefined) {
			if (menuitem.state === "Email") {
				menuitem.label = (
					<div className="username">
						<Email />
					</div>
				);
			} else if (menuitem.state === "theme-switch") {
				menuitem.label = (
					<div
						className="theme-switch"
						style={{ display: "flex", alignItems: "center" }}
						onClick={(e: any) => {
							e.stopPropagation();
							let ev: any = this.state.mode || false;
							this.setState({ mode: !ev });
							Emitter.emit("mode-toggle", !ev);
							document.body.classList.toggle("dark-mode", !ev);
							if (ev === false) {
								localStorage.setItem("theme", "dark");
							} else {
								localStorage.setItem("theme", "light");
							}
						}}
					>
						<Switch
							size="small"
							checked={!this.state.mode}
							checkedChildren={
								<Sun
									width={12}
									height={12}
									style={{
										marginTop: "2px",
										filter: "brightness(0) invert(1)",
									}}
								/>
							}
							unCheckedChildren={
								<Moon
									width={12}
									height={12}
									style={{
										marginTop: "3px",
										filter: "brightness(0) invert(1)",
									}}
								/>
							}
							onChange={(ev) => {
								this.setState({ mode: !ev });
								Emitter.emit("mode-toggle", !ev);
								document.body.classList.toggle("dark-mode", !ev);
								if (ev === false) {
									localStorage.setItem("theme", "dark");
								} else {
									localStorage.setItem("theme", "light");
								}
							}}
						/>{" "}
						<span style={{ marginLeft: "5px" }}>
							{!this.state.mode ? "Light" : "Dark"} Mode
						</span>
					</div>
				);
			} else {
				menuitem.label = this.state[menuitem.state];
			}
		}
		if (!menuitem.menuid) {
			menuitem.menuid = uuid;
		}
		if (menuitem.requireAdmin === true && AdminCheck() !== true) {
			return null;
		}

		return (
			<Menu.Item
				className={
					menuitem.clickable === false ? "user-menu unclickable" : "user-menu"
				}
				key={menuitem?.route?.link}
				onClick={(ev) => {
					this.selectMenu(ev, null, null);
					this.closeDrawer();
				}}
			>
				{menuitem.route?.link ? (
					<Link to={menuitem?.route?.link} onClick={() => { }} />
				) : null}
				{menuitem.label}
			</Menu.Item>
		);
	};

	render_menu = () => {
		const render_item_and_sections: any = (
			menuitem: any,
			level: number = 0,
			parentkey: any | null = null,
			parentroute: any | null = null,
			route: string | null = null
		) => {
			const pattern = /^[a-zA-Z0-9_-]+$/;

			// defining the current route
			let currentroute: any = route;
			if (parentroute === "") {
				parentroute = null;
			}
			if (parentroute && currentroute !== null) {
				currentroute = `${currentroute}/${parentroute}`;
			} else if (parentroute && currentroute == null) {
				currentroute = `${parentroute}`;
			} else if (currentroute !== null && parentroute !== null) {
				currentroute = `${currentroute}/`;
			}

			if (menuitem.visible !== undefined) if (!menuitem.visible) return;

			if (menuitem.route.link !== "" && pattern.test(menuitem.route.link)) {
				// if menu has children
				if (menuitem?.children) {
					return (
						<SubMenu
							className={
								menuitem?.title.length > 15 && !this.props.ismobile
									? "wrap_menu_title"
									: ""
							}
							key={menuitem.key}
							icon={
								this.props.ismobile || level !== 1
									? null
									: GetComponentIcon({
										componentName: menuitem.component,
									})
							}
							title={menuitem.title}
						>
							{menuitem.children.map((child_menu: any) => {
								return render_item_and_sections(
									child_menu,
									level + 1,
									menuitem.key,
									menuitem.route?.link,
									currentroute
								);
							})}
						</SubMenu>
					);
				} else {
					return this.render_menu_item(
						menuitem,
						level,
						parentkey,
						parentroute,
						currentroute
					);
				}
			}
		};

		return <SideMenu items={this.props.mainMenu} />;
	};

	render_icon_menu = (toggle: any = () => { }) => {
		return (
			<Menu style={{ minWidth: "200px", top: "14px" }}>
				{this.props.mainMenu?.map((d: any) => {
					if (d) {
						const uuid = v4();
						return (
							<div
								key={uuid}
								onClick={() => {
									console.log("toggling");
									toggle();
								}}
							>
								{" "}
								{this.render_menu_item_icon(d)}
							</div>
						);
					}
					return null;
				})}
			</Menu>
		);
	};

	render_icon_menu_mobile = () => {
		return (
			<div className="mobile-menu" style={{ color: "black !important" }}>
				{this.props.adminMenu?.map((d: any) => {
					if (d) {
						const uuid = v4();
						return (
							<div className="admin-menu-item" key={uuid}>
								{" "}
								{this.render_menu_item_icon(d)}{" "}
							</div>
						);
					}
					return null;
				})}
			</div>
		);
	};

	onCollapse() {
		let collapsed_state = !this.props.collapsed;
		this.props.onCollapse(collapsed_state);
		this.setState({ collapsed: collapsed_state });
		this.props.dispatch({
			type: "SET_COLLAPSE",
			payload: collapsed_state,
		});
	}

	showDrawer = () => {
		this.setState({ drawer: true });
	};

	closeDrawer = () => {
		this.setState({ drawer: false });
	};

	render() {
		return (
			<>
				{this.props.ismobile
					? this.state.loaded
						? this.render_menu()
						: null
					: this.state.loaded
						? this.render_menu()
						: null}
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		allModules: state.allModules,
		mainMenu: state.allModules.mainMenu,
		adminMenu: state.allModules.adminMenu,
		currentModule: state.currentModule,
		role: state.role,
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(CMainMenu);
