import { Flex, Typography } from "antd";
import React from "react";

const { Text } = Typography;

type LabelVisualProps = {
	label?: React.ReactNode;
	value?: React.ReactNode;
};

const LabelVisual = ({ label, value }: LabelVisualProps) => {
	return (
		<Flex justify="space-between" style={{ width: "100%" }}>
			<Text>{label}</Text>
			<Text>{value}</Text>
		</Flex>
	);
};

export default LabelVisual;
