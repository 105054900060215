import { Button, Layout } from "antd";
import { GetComponentIcon } from "../utils/svg_icons";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Tenant } from "./api-server/_exports";
import { useMenuContext } from "../contexts/MenuContext";
import NewMenuItemHandler from "../components/NewMenuItemHandler";
import { useSelector } from "react-redux";
import { filterMenu, getNodes } from "../utils/utils";

type MenuItemProps = { item: any };

const MenuItem = ({ item }: MenuItemProps) => {
	const { toggle, selectedKey } = useMenuContext();

	const [active, setActive] = useState(false);
	const menuPath = `/${Tenant}/${item?.path?.join("/")}`;
	const navigate = useNavigate();
	useEffect(() => {
		setActive(selectedKey === item?.key);
	}, [selectedKey, item?.key]);
	return (
		<Button
			key={item.key}
			className={`mega-menu-v2-item mega-menu-v2-item${active ? "-active" : ""
				}`}
			icon={GetComponentIcon({ componentName: item?.component, width: 14 })}
			onClick={(e) => {
				navigate(menuPath);
				toggle();
			}}
		>
			<NewMenuItemHandler
				key={item.key}
				newDuration={item.new_duration}
				title={item.title}
			/>
		</Button>
	);
};

const MegaMenuV2 = () => {
	const menuItems = useSelector((state: any) => state?.allModules?.mainMenu);
	const currentUser = useSelector((state: any) => state?.user);
	const userRoles = useSelector((state: any) => state?.role);
	const { currentSubKey } = useMenuContext();

	const allNodesWithChildren: any[] = useMemo(() => {
		const items = userRoles?.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)
			? menuItems
			: filterMenu(menuItems, userRoles, currentUser);
		return getNodes(items, false).filter(
			(node: any) => node?.children && node?.children?.length !== 0
		);
	}, [menuItems, userRoles, currentUser]);

	const currentItems = useMemo(() => {
		return allNodesWithChildren?.find(
			(node: any) => node?.key === currentSubKey
		)?.children;
	}, [allNodesWithChildren, currentSubKey]);

	return currentItems?.length > 0 ? (
		<Layout
			style={{
				height: "100vh",
				width: "100%",
				background: "#141414",
				display: "grid",
				gridTemplateColumns: "repeat(3, 1fr)",
				gridTemplateRows: "min-content",
				gridAutoRows: "max-content",
				padding: 24,
				columnGap: 42,
				rowGap: 12,
			}}
		>
			{currentItems?.map((item: any) => (
				<MenuItem item={item} />
			))}
		</Layout>
	) : null;
};

export default MegaMenuV2;
