import React, { useCallback, useContext, useEffect, useState } from "react";
import { saveMenu } from "../services/api-server/menu";
import Emitter from "../services/EventEmitter";
import { CustomDashboardContext } from "../contexts/context";

const useDashboards = () => {
	const [dashboards, setDashboards] = useState<Array<any>>([]);
	const [loading, setLoading] = useState(false);
	const { menu } = useContext(CustomDashboardContext);

	const getDashboards = () => {
		let _dashboards: Array<any> = [];

		const traverse = (mItem: any) => {
			const { component = "", children = [] } = mItem;
			if (component === "Custom Dashboard") {
				_dashboards = [..._dashboards, mItem];
			}

			if (children !== null && children?.length !== 0) {
				children?.forEach((child: any) => {
					traverse(child);
				});
			}
		};

		menu?.forEach((mItem: any) => {
			traverse(mItem);
		});

		setDashboards(_dashboards);
	};

	const saveDashboards = useCallback(
		(dashboards: Array<any>, menuKey?: string) => {
			return new Promise((resolve, reject) => {
				const traverse = (menuItem: any) => {
					let updated = { ...menuItem };

					const found = dashboards.find(
						(dashboard: any) => dashboard?.key === menuItem?.key
					);
					if (found) return found;

					if (menuItem?.children && menuItem?.children?.length !== 0) {
						updated.children = menuItem?.children?.map(traverse);
					}

					return updated;
				};

				const updatedMenu = menu.map(traverse);
				// console.log(updatedMenu);
				setLoading(true);
				saveMenu(updatedMenu, menuKey)
					.then((response) => {
						Emitter.emit("MenuSaved", response);
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					})
					.finally(() => {
						setLoading(false);
					});
			});
		},
		[menu]
	);

	useEffect(() => {
		if (menu?.length !== 0) {
			getDashboards();
		}
	}, [menu]);

	return { loading, dashboards, saveDashboards };
};

export default useDashboards;
