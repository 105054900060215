import {
	Badge,
	Button,
	Col,
	Image,
	Popover,
	Row,
	Space,
	Spin,
	Tooltip,
	Typography,
} from "antd";
import React from "react";
import { getRigObject } from "./../services/RigInfo";
import PI_Care_Grey from "./../assets/img/product_icons/CareIcon_Outline.png";
import PI_Innovation_Grey from "./../assets/img/product_icons/i icon outline.png";
import PI_Performance_Grey from "./../assets/img/product_icons/PerformanceIcon_Outline.png";
import Portal_Icon_Logo from "../assets/img/Portal_hexagon_RGB.png";
import "../assets/css/Vessel.css";
import { connect } from "react-redux";
import Emitter from "../services/EventEmitter";
import { getDocumentbyTags } from "../services/api-server/documents";
import { GetAntIcon } from "../utils/ant_icons";
import PieChart from "./charts/PieChart";
import SunBurst from "./charts/SunBurst";
import TreeMap from "./charts/TreeMap";
import { v4 } from "uuid";
import { HomeContext } from "../containers/Home";
import CustomModal from "./CustomModal";
import { formatDateTime } from "../utils/dataTools";
import { duckQuery } from "../services/api-server/deltashare";

const { Text } = Typography;

const smart_contracts_keys = ["Pending", "Executed", "Rejected", "Expired"];
const smart_contracts = [
	{
		key: "Executed",
		color: "#59B24C",
		data: [
			{ id: v4(), percent: 100 },
			{ id: v4(), percent: 100 },
			{ id: v4(), percent: 100 },
		],
	},
	{
		key: "Pending",
		color: "#F7941D",
		data: [
			{ id: v4(), percent: Math.floor(Math.random() * 101) },
			{ id: v4(), percent: Math.floor(Math.random() * 101) },
			{ id: v4(), percent: Math.floor(Math.random() * 101) },
		],
	},
	{
		key: "Rejected",
		color: "#E32118",
		data: [
			{ id: v4(), percent: null },
			{ id: v4(), percent: null },
			{ id: v4(), percent: null },
		],
	},
	{
		key: "Expired",
		color: "#b2b2b2",
		data: [
			{ id: v4(), percent: null },
			{ id: v4(), percent: null },
			{ id: v4(), percent: null },
		],
	},
];

const object: any = {
	care: "Care",
	performance: "DPR",
	observation: "DOR",
};

const bronze_tnp_object: any = {
	Well: "well_name",
	Wellbore: "wellbore_name",
	Operation: "operation_name",
	Day_no: "day_number",
};

const silver_tnp_object: any = {
	Country: "country",
	Position: "latlong",
	Well: "well_name",
	Wellbore: "wellbore_name",
	Operation: "operation_name",
	Current_Day_No: "day_number",
	PoB: "pob_count",
	Last_State_Detected: "rig_state",
};

const silver_tnp_object_less: any = {
	Country: "country",
	Position: "latlong",
};

const document_object: any = {
	DOR: "observation",
	DPR: "performance",
	Care: "care",
};

const Hse_object: any = {
	Lost_Time_Incidents: "lti",
	Recordable_Work_Case: "hwc",
	Medical_Treatment_Only: "mto",
	First_Aid_Case: "faci",
	Dropped_Object: "do",
	Near_Miss: "nm",
	Environmental_Event: "ees",
	Property_Damage: "Unknown",
	Equipment_Damage: "Unknown",
	For_Record_Only: "Unknown",
};

const NPT_object: any = {
	P: "Programmed",
	NPT: "NPT",
	DT: "Downtime",
};

const sunBurst_data = {
	id: "A",
	name: "NPT_Code",
	label: "NPT",
	children: [
		{
			id: "a",
			name: "A",
			label: `A`,
			// value: this.state.data.hse_summary.perfect_days,
			children: [
				{
					id: "a",
					name: "A1",
					label: `A1`,
					children: [
						{
							id: "a",
							name: "A1-1",
							label: `A1-1`,
							value: Math.random() * (20 - 1) + 1,
						},
						{
							id: "a",
							name: "A1-2",
							label: `A1-2`,
							value: Math.random() * (20 - 1) + 1,
						},
					],
				},
				{
					id: "a",
					name: "A2",
					label: `A2`,
					children: [
						{
							id: "a",
							name: "A2-1",
							label: `A2-1`,
							value: Math.random() * (20 - 1) + 1,
						},
						{
							id: "a",
							name: "A2-2",
							label: `A2-2`,
							value: Math.random() * (20 - 1) + 1,
						},
					],
				},
			],
		},
		{
			id: "b",
			name: "B",
			label: `B`,
			children: [
				{
					id: "b",
					name: "B1",
					label: `B1`,
					children: [
						{
							id: "b",
							name: "B1-1",
							label: `B1-1`,
							value: Math.random() * (20 - 1) + 1,
						},
						{
							id: "b",
							name: "B1-2",
							label: `B1-2`,
							value: Math.random() * (20 - 1) + 1,
						},
						{
							id: "b",
							name: "B1-3",
							label: `B1-3`,
							value: Math.random() * (20 - 1) + 1,
						},
					],
				},
			],
			// value: this.state.data.hse_summary.non_perfect_days
		},
		{
			id: "c",
			name: "C",
			label: `C`,
			children: [],
			// value: this.state.data.hse_summary.non_perfect_days
		},
	],
};

class VesselStatus extends React.Component<any> {
	static contextType?: React.Context<any> | undefined = HomeContext;

	props: any = {
		rig: null,
	};

	state: any = {
		data: null,
		searchloading: false,
		hse_summary_data: {},
		report_container: "report",
		documents: null,
	};
	updateLoop: any;
	constructor(props: any) {
		super(props);
		this.props = props;
	}

	componentDidMount() {
		if (this.props.vesselImgMap) {
			this.setState({
				imageObj: this.props.vesselImgMap[`${this.props.rig}`],
			});
		}
		if (this.state.data?.name !== this.props.rig) {
			let data: any = getRigObject(this.props.rig);
			if (data.hse_summary) {
				let hse_array: any = [];
				Object.keys(data.hse_summary).map((element: any) => {
					let object: any = {
						id: "",
						value: 0,
					};
					if (typeof data.hse_summary[element] === "number") {
						if (element != "non_perfect_days") {
							object.id = element;
							object.value = data.hse_summary[element];
							hse_array.push(object);
						}
					}
				});
				this.setState({
					hse_summary_data: hse_array,
				});
				this.generateTreeMap_PD(
					1,
					data.hse.slice(-30),
					data.hse_summary,
					{}
				).then((data: any) => {
					this.setState({ treemap: data });
				});
			}
			if (data.time_codes) {
				let hours_summary: any = [];

				Object.keys(data.time_codes.hours_summary).map(
					(element: any, index: any) => {
						let object: any = {
							id: "",
							value: 0,
						};
						if (typeof data.time_codes.hours_summary[element] === "number") {
							object.id = NPT_object[element];
							object.value = (
								(data.time_codes.hours_summary[element] / (30 * 24)) *
								100
							).toFixed(2);
							hours_summary.push(object);
						}
					}
				);
				if (
					data.time_codes.hours_summary.NPT ||
					data.time_codes.hours_summary.DT
				) {
					this.generateSunBurst_NPT(
						1,
						data.time_codes,
						data.time_codes.npt_summary,
						{}
					).then((data: any) => {
						this.setState({ sunburst_data: data });
					});
				} else {
					this.setState({ sunburst_data: null });
				}

				this.setState({ npt_summary_data: hours_summary });
			}
			if (data.last_updated_ids) {
				const latestdate = new Date(data.last_updated_ids);
				const date = latestdate.toLocaleDateString("en-GB").replace(/\//g, "-");
				const time = latestdate.toLocaleTimeString();
				const lastUpdated = `${date} - ${time}`;
				this.setState({ last_updated: lastUpdated });
			}

			//Calculate POB count data (remove IDS total_pax usage)
			let pob_count = 0;
			duckQuery(
				`select count(*) as pob_count From pob Where pob.vessel_id = ${data.code} and pob.pob_date = (select max(pob.pob_date) From pob Where pob.vessel_id = ${data.code})`,
				[],
				true
			)
				.then((data: any) => {
					pob_count = data.response[0]?.pob_count || 0;
				})
				.finally(() => {
					if (this.state.data?.name !== this.props.rig)
						this.setState({
							data: { ...getRigObject(this.props.rig), pob_count },
						});

					if (this.props.vesselImgMap) {
						this.setState({
							imageObj: this.props.vesselImgMap[`${this.props.rig}`],
						});
					}
				});
			this.setState({ documents: data.latest_documents || null });
		}

		Emitter.on("updatelocation", this.updateData);
	}

	componentWillUnmount() {
		// if (this.updateLoop) {
		// 	clearInterval(this.updateLoop);
		// 	this.updateLoop = undefined;
		// }

		Emitter.off("updatelocation", this.updateData);
	}

	updateData = () => {
		this.setState({ data: getRigObject(this.props.rig) });
	};

	generateTreeMap_PD = (
		loop_count: any,
		hse_data: any,
		hse_data_summary: any,
		treedata: any,
		bool: any | null = null
	) => {
		return new Promise((resolve, reject) => {
			if (loop_count < 4) {
				if (loop_count === 1) {
					treedata = {
						name: "parent",
						label: "parent",
						children: [],
					};
					this.generateTreeMap_PD(
						2,
						hse_data,
						hse_data_summary,
						treedata.children
					).then((res: any) => {
						treedata.children = res;
						resolve(treedata);
					});
				}
				if (loop_count === 2) {
					treedata.push({
						id: "pd",
						name: "Perfect Days",
						label: `PD ${hse_data_summary?.perfect_days}`,
						children: [],
					});
					treedata.push({
						id: "npd",
						name: "Non Perfect Days",
						label: `NPD ${hse_data_summary?.non_perfect_days}`,
						children: [],
					});
					this.generateTreeMap_PD(
						3,
						hse_data,
						hse_data_summary,
						treedata[0].children,
						true
					).then((res: any) => {
						treedata[0].children = res;
						this.generateTreeMap_PD(
							3,
							hse_data,
							hse_data_summary,
							treedata[1].children,
							false
						).then((res: any) => {
							treedata[1].children = res;
							resolve(treedata);
						});
					});
				}
				if (loop_count === 3) {
					Promise.all(
						hse_data.map((element: any) => {
							let tempdata: any = {
								name: element.report_date,
								label: element.report_date,
								children: [],
							};
							if (
								(!Object.values(element).includes(true) && bool === true) ||
								(Object.values(element).includes(true) && bool === false)
							) {
								tempdata.children.push({
									name: `${element.report_date} Detailed`,
									label: `${JSON.stringify(element).split(",").join(" ")}`,
									value: 1,
								});
								treedata.push(tempdata);
								return true;
							}
							return false;
						})
					).then((res: any) => {
						resolve(treedata);
					});
				}
			}
		});
	};

	generateSunBurst_NPT = (
		loop_count: any,
		npt_data: any,
		npt_data_summary: any,
		treedata: any,
		npt_key: any = null
	) => {
		return new Promise((resolve: any, reject: any) => {
			if (loop_count < 5) {
				if (loop_count === 1) {
					treedata = {
						name: "",
						label: "",
						children: [],
					};
					this.generateSunBurst_NPT(
						2,
						npt_data,
						npt_data_summary,
						treedata.children
					).then((res: any) => {
						treedata.children = res;
						resolve(treedata);
					});
				}
				if (loop_count === 2) {
					let treedata: any = [];
					Promise.all(
						Object.keys(npt_data_summary).map((element: any, index: any) => {
							let obj: any = {
								id: `${element}`,
								name: `${element}, ${npt_data.hours_summary[element]}hr`,
								label: `${element}`,
								excludedKeys: ["name", "label", "children", "excludedKeys"],
								children: [],
							};

							this.generateSunBurst_NPT(
								3,
								npt_data,
								npt_data_summary[element],
								obj.children,
								element
							).then((res: any) => {
								obj.children = res;
								treedata.push(obj);
								return true;
							});
						})
					).then(() => {
						resolve(treedata);
					});
				}
				if (loop_count === 3) {
					let temparray: any = [];

					Promise.all(
						// this one is either "NPT" or "DT"
						npt_data_summary.map((element: any, index: number) => {
							temparray.push({
								label: element.responsible_party,
								name: `${element.responsible_party}, ${element.activity_duration}hr`,
								responsible_party: element.responsible_party,
								duration: element.activity_duration,
								excludedKeys: [
									"name",
									"children",
									"excludedKeys",
									"day_uid",
									"label",
									"value",
									"day_uid",
									"report_date",
									"report_number",
									"rig_name",
									"well_name",
									"wellbore_name",
									"operation_name",
									"activity_time_end",
									"activity_type",
									"class_code",
									"primary_code",
									"hole_depth",
									"string_depth_from",
									"string_depth_to",
									"sub_time_code_1",
									"sub_time_code_2",
									"operation_mode",
									"contract_rate_code",
									"activity_desc",
									"trip_number",
									"downtime_event_id",
									"failure_cause",
									"responsible_party_co",
									"responsible_party_dept",
								],
							});

							this.generateSunBurst_NPT(
								4,
								npt_data,
								npt_data_summary[index],
								treedata
							).then((data) => {
								temparray[index].children = data;
							});

							return true;
						})
					).then(() => {
						resolve(temparray);
					});
				}

				if (loop_count === 4) {
					let temparray: any = [];

					Promise.all(
						// this is is all of the responsible parties
						npt_data_summary.event.map((_event: any) => {
							temparray.push({
								name: formatDateTime(_event.activity_time_start),
								label: formatDateTime(_event.activity_time_start),
								value: _event.activity_duration,
								excludedKeys: [
									"name",
									"value",
									"label",
									"class_code",
									"day_uid",
									"children",
									"excludedKeys",
								],
								..._event,
							});
							return true;
						})
					).then(() => {
						resolve(temparray);
					});
				}
			}
		});
	};

	getImageLink = () => {
		if (this.props.vesselImgMap) {
			const vesselObj = this.props.vesselImgMap[`${this.props.rig}`];
			return vesselObj.icon3 || vesselObj.icon;
		} else {
			// return not-available
		}
	};

	onChangePage = (value: any) => {
		Emitter.emit("onpage", value);
	};

	getDocument = async (value: any) => {
		// refers to the function that is passed to the context provider from Home.tsx
		let context: any = this.context;
		let handleOpenDocument: any = context?.handleOpenDocument;

		this.setState({ searchloading: true, message: "Loading Document..." });
		let TagString = value;
		handleOpenDocument({
			open: true,
			pdfViewer: true, // Open the drawer immediately
			fileObject: null, // Keep it null or show some loading indication inside the drawer
			loading: true, // Maintain loading state
		});
		await getDocumentbyTags(TagString)
			.then((data: any) => {
				handleOpenDocument({
					open: true,
					pdfViewer: true,
					fileObject: {
						record: data.record,
						key: data.filename,
						children: data.url,
						label: data.filename,
					},
					loading: false,
				});

				this.setState({ searchloading: false, message: "" });
			})
			.catch((error: any) => {
				this.setState({ searchloading: false, message: "" });
				Emitter.emit("alert", {
					type: "error",
					message: "File does not exist",
					description: `The File for ${this.state.data.vessel_name} does not exist`,
					timeout: 5000,
				});
			});
	};

	onClose = () => {
		this.props.dispatch({ type: "SELECT_VESSEL", payload: null });
	};

	detailInfo = (infoName: any) => {
		if (this.state.data?.[infoName] || infoName === "latlong") {
			if (infoName === "latlong") {
				let aislocation = this.state.data?.ais_data[0]?.ais_location || null;
				if (aislocation) {
					return `${aislocation.lat.toFixed(4)}, ${aislocation.lng.toFixed(4)}`;
				} else {
					return "Unavailable";
				}
			} else if (infoName === "latest_rig_state_timestamp") {
				return `Last Data (Local Rig Time): ${this.state.data[infoName]}`;
			} else return this.state.data[infoName];
		} else {
			return "Unavailable";
		}
	};

	render() {
		const renderBronzeTNP = (tnp_key: any) => {};

		const renderSilverTNP = (tnp_key: any) => {
			if (tnp_key == "Last_State_Detected") {
				return (
					<Row className="rowMaster_drawer">
						<Col className="colMaster_drawer" span={12}>
							{tnp_key.split("_").join(" ")}
						</Col>
						<Col className="colChild_drawer" span={12}>
							<Tooltip
								placement="left"
								overlayStyle={{ maxWidth: "max-content" }}
								title={this.detailInfo("latest_rig_state_timestamp")}
							>
								{this.detailInfo(silver_tnp_object?.[tnp_key])}
							</Tooltip>
						</Col>
					</Row>
				);
			} else {
				return (
					<Row className="rowMaster_drawer">
						<Col className="colMaster_drawer" span={12}>
							{tnp_key.split("_").join(" ")}
						</Col>
						<Col className="colChild_drawer" span={12}>
							{this.detailInfo(silver_tnp_object[tnp_key])}
						</Col>
					</Row>
				);
			}
		};

		const renderBronzeDocumentButtons = (doc_key: any) => {
			const doc_img: any = {
				care: PI_Care_Grey,
				DPR: PI_Performance_Grey,
				DOR: PI_Innovation_Grey,
			};
			return (
				<Col className="fullCol">
					<Button
						disabled={
							!this.state.documents || this.state.documents[doc_key] === null
						}
						onClick={() => this.getDocument(this.state.documents[doc_key])}
						className="vesselStatusBtn"
					>
						<img
							className="vessel-button-img"
							alt={`grey-${doc_key}`}
							src={doc_img[doc_key]}
						/>
					</Button>
				</Col>
			);
		};

		const renderDocumentButtons = (doc_key: any, index: any) => {
			if (!this.state.documents || this.state.documents[doc_key] === null) {
				return (
					<Tooltip placement="top" title="File does not exist">
						<span style={{ cursor: "disabled" }}>
							<Button
								key={doc_key}
								icon={GetAntIcon("pdf")}
								disabled={true}
								style={{ pointerEvents: "none" }}
								className="vessel_drawer_report_button vessel-drawer-button"
								onClick={() => this.getDocument(this.state.documents[doc_key])}
							>
								{doc_key.toUpperCase()}
							</Button>
						</span>
					</Tooltip>
				);
			} else {
				return (
					<Button
						key={doc_key}
						icon={GetAntIcon("pdf")}
						className="vessel_drawer_report_button vessel-drawer-button"
						onClick={() => this.getDocument(this.state.documents[doc_key])}
					>
						{doc_key.toUpperCase()}
					</Button>
				);
			}
		};

		const renderSmartContracts = (contract_data: any) => {
			return (
				<>
					<Button
						style={{
							display: "flex",
							color: `${contract_data.color}`,
							fontWeight: "bold",
						}}
						className="stopFocus-contracts"
						onClick={() => {
							if (this.state.smart_contracts_key == contract_data.key) {
								this.setState({ smart_contracts_key: undefined });
							} else {
								this.setState({ smart_contracts_key: contract_data.key });
							}
						}}
					>
						<span style={{ position: "absolute", left: "30px" }}>
							{contract_data.key} ({contract_data.data.length})
						</span>
						<span style={{ position: "absolute", right: "30px" }}>
							{this.state.smart_contracts_key == contract_data.key
								? GetAntIcon("downarrow")
								: GetAntIcon("rightarrow")}
						</span>
					</Button>
					{this.state.smart_contracts_key == contract_data.key ? (
						<div
							style={{
								display: "flex",
								alignItems: "flex-end",
								flexDirection: "column",
							}}
						>
							{contract_data.data.map((element: any) => (
								<Button
									style={{
										width: "400px",
										display: "flex",
										color: `${contract_data.color}`,
									}}
									className="stopFocus-contracts"
								>
									<span>{element.id}</span>
									<span style={{ position: "absolute", right: "30px" }}>
										{element.percent ? `${element.percent}%` : null}
									</span>
								</Button>
							))}
						</div>
					) : null}
				</>
			);
		};

		const renderHSEContent = (hse_key: any) => {
			return (
				<>
					<div>{hse_key.split("_").join(" ")}</div>
					<div style={{ justifySelf: "center" }}>
						{this.state.data?.hse_summary?.dailyhse?.[Hse_object[hse_key]] >
						0 ? (
							<Popover
								content={
									this.state.data?.hse_summary?.dailyhse?.[Hse_object[hse_key]]
								}
							>
								<Badge status="error"></Badge>
							</Popover>
						) : (
							<Badge status="success"></Badge>
						)}
					</div>
					<div style={{ justifySelf: "center" }}>
						{this.state.data?.hse_summary?.dailyhse?.[Hse_object[hse_key]]
							? this.state.data?.hse_summary?.dailyhse?.[Hse_object[hse_key]]
							: 0}
					</div>
				</>
			);
		};

		const handleOpenDetailsModal = (data: any, excludedKeys: string[]) => {
			this.setState({
				vesselData: data,
				openDetailsModal: true,
			});
		};

		const handleCloseDetailsModal = () => {
			this.setState({ openDetailsModal: false });
		};

		const renderSilverCards = () => {
			return (
				<div
					className="vessel_drawer_grid"
					style={
						this.props.ismobile ? { gridTemplateColumns: "0.4fr 1.6fr" } : {}
					}
				>
					<div className="vessel_drawer_image_container">
						<div className="vessel_3D_image_silver_drawer">
							<Spin
								wrapperClassName={"generic-loading-wrapper"}
								spinning={!this.state.data?.vessel_image}
							>
								<Image
									style={{ height: "100%" }}
									src={this.state.data?.vessel_image}
									alt={`STENA ${this.props.rig.toUpperCase()}`}
									preview={{
										className: "vessel_3d_image_preview",
										destroyOnClose: true,
									}}
								></Image>
							</Spin>
						</div>
						<a
							href={this.state.data?.spec_link}
							target="_blank"
							style={{
								pointerEvents: this.state.data?.spec_link ? "auto" : "none",
								textDecoration: "underline",
								alignSelf: "center",
								marginRight: "70px",
								marginTop: "5px",
							}}
						>
							Vessel details
						</a>
					</div>
					{this.state.data?.on_hire === true ? (
						<>
							{" "}
							<div className="vessel_drawer_tnp_container">
								<Spin
									wrapperClassName={"generic-loading-wrapper"}
									spinning={!this.state.data || !this.state.data?.pob_count}
								>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<span className="silver-tnp-title">Vessel Information</span>
										<Tooltip
											style={{ maxWidth: "300px" }}
											overlayStyle={{ maxWidth: "300px" }}
											className={"vesselstatus-tooltip"}
											placement="left"
											title={`Last Update: ${this.state?.last_updated}`}
										>
											<Button
												id="refreshButton"
												onClick={() => {
													document
														.getElementById("refreshButton")
														?.classList.toggle("refresh-button", true);
													setTimeout(() => {
														document
															.getElementById("refreshButton")
															?.classList.toggle("refresh-button", false);
													}, 2000);
												}}
												className="vessel-drawer-button stopFocus"
												icon={GetAntIcon("refresh")}
											></Button>
										</Tooltip>
									</div>
									<div className="silver-tnp" style={{ rowGap: "10px" }}>
										{Object.keys(silver_tnp_object).map((tnp_key: any) => {
											return renderSilverTNP(tnp_key);
										})}
									</div>

									<a
										style={{
											textDecoration: "underline",
										}}
									>
										Daily Information Screen
									</a>
								</Spin>
							</div>
							<div className="vessel_drawer_report_container">
								<Spin
									wrapperClassName={"generic-loading-wrapper"}
									spinning={!this.state.documents}
								>
									<div
										style={{
											fontSize: "18px",
											fontWeight: "bold",
											lineHeight: "2",
											display: "flex",
										}}
									>
										<div className={"vessel-drawer-content-title"}>
											Latest Reports
										</div>
										{/* <span style={{ margin: "0 20px" }}>|</span>
									<div
										style={{
											cursor: "pointer",
											textDecoration: `${
												this.state.report_container == "contracts"
													? "underline"
													: "none"
											}`,
											color: `${
												this.state.report_container == "contracts"
													? "#42bb91"
													: "black"
											}`,
										}}
										onClick={() => {
											this.setState({ report_container: "contracts" });
										}}
									>
										Smart Contracts
									</div> */}
									</div>
									{this.state.report_container == "report" ? (
										<div className="vessel_drawer_report_buttons_container">
											{Object.keys(document_object).map(
												(document_key: any, index: any) => {
													return renderDocumentButtons(document_key, index);
												}
											)}
										</div>
									) : null}
									{this.state.report_container == "contracts" ? (
										<div style={{ marginTop: "10px" }}>
											<div
												style={{
													width: "100%",
													justifyContent: "center",
												}}
											>
												<div
													style={{
														width: "100%",
														display: "flex",
														flexDirection: "column",
													}}
												>
													{smart_contracts.map((element: any) =>
														renderSmartContracts(element)
													)}
												</div>
											</div>
										</div>
									) : null}
								</Spin>
							</div>
							{this.state.report_container == "report" ? (
								<>
									{" "}
									<div className="vessel_drawer_hse_container">
										<Spin
											wrapperClassName={"generic-loading-wrapper"}
											spinning={!this.state.data?.hse_summary?.dailyhse}
										>
											<div className={"vessel-drawer-content-title"}>
												Operational Performance
											</div>
											<div className="vessel_drawer_hse_content">
												{Object.keys(Hse_object)?.map((hse_key: any) => {
													return renderHSEContent(hse_key);
												})}
											</div>
										</Spin>
									</div>
									<div className="vessel_drawer_chart_container">
										<div className={"vessel-drawer-content-title"}>
											Last 30 days Performance
										</div>
										<div style={{ height: "200px", marginBottom: "75px" }}>
											<span className={"vessel-drawer-content-title"}>
												Perfect Days
											</span>
											{this.state.treemap ? (
												<TreeMap
													rig={this.state.data?.name}
													data={this.state.treemap}
												></TreeMap>
											) : (
												<div
													className={"vessel-drawer-content-title"}
													style={{
														paddingBottom: "50px",
														fontSize: "30px",
														fontWeight: "bold",
														height: "100%",
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}
												>
													NO DATA
												</div>
											)}
										</div>
										<div
											style={{
												display: "grid",
												gridTemplateColumns: "0.5fr 0.5fr",
												maxWidth: "100%",
												overflow: "hidden",
											}}
										>
											<Space direction="vertical">
												{/* <span className={"vessel-drawer-content-title"}>
													NPT
												</span> */}
												<Text
												// className={"vessel-drawer-content-title"}
												>
													NPT
												</Text>
												{this.state.npt_summary_data ? (
													<PieChart
														rig={this.state.data?.name}
														thickness={1}
														valueField="value"
														data={this.state.npt_summary_data}
														degreeStart={0}
													/>
												) : (
													<div
														className={"vessel-drawer-content-title"}
														style={{
															paddingBottom: "50px",
															fontSize: "30px",
															fontWeight: "bold",
															height: "100%",
															display: "flex",
															alignItems: "center",
															justifyContent: "center",
														}}
													>
														NO DATA
													</div>
												)}
											</Space>

											<Space direction="vertical">
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
													}}
												>
													<Text
													// className={"vessel-drawer-content-title"}
													>
														NPT Responsible Party
													</Text>
													{/* <Button icon={GetAntIcon("bar")} size="small" /> */}
												</div>

												{this.state.sunburst_data ? (
													<>
														<SunBurst
															rig={this.state.data?.name}
															divFound={this.state.divref}
															divHasFound={() =>
																this.setState({ divref: true })
															}
															data={this.state.sunburst_data}
															padding={8}
															openDetailsModal={handleOpenDetailsModal}
														/>
														<CustomModal
															open={this.state.openDetailsModal}
															onCancel={handleCloseDetailsModal}
															data={this.state.vesselData}
														/>
													</>
												) : (
													<div
														className={"vessel-drawer-content-title"}
														style={{
															paddingBottom: "50px",
															fontSize: "30px",
															fontWeight: "bold",
															height: "100%",
															display: "flex",
															alignItems: "center",
															justifyContent: "center",
														}}
													>
														NO DATA
													</div>
												)}
											</Space>
										</div>
									</div>
								</>
							) : null}
						</>
					) : (
						<>
							{" "}
							<div className="vessel_drawer_tnp_container">
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<span className="silver-tnp-title">Vessel Information</span>
									<Button
										id="refreshButton"
										onClick={() => {
											document
												.getElementById("refreshButton")
												?.classList.toggle("refresh-button", true);
											setInterval(() => {
												document
													.getElementById("refreshButton")
													?.classList.toggle("refresh-button", false);
											}, 2000);
										}}
										className="vessel-drawer-button stopFocus"
										icon={GetAntIcon("refresh")}
									></Button>
								</div>
								<div className="silver-tnp" style={{ rowGap: "10px" }}>
									{Object.keys(silver_tnp_object_less).map((tnp_key: any) => {
										return renderSilverTNP(tnp_key);
									})}
								</div>
							</div>
						</>
					)}
				</div>
			);
		};

		return (
			<div
				style={
					this.props.ismobile && this.props.level === "bronze"
						? {
								position: "absolute",
								zIndex: "10000000",
								width: "100%",
								left: "0px",
								bottom: "0px",
								background: "white",
						  }
						: {}
				}
			>
				<Spin
					// style={{ color: "rgb(77, 77, 77)" }}
					size="large"
					tip={"Loading Document..."}
					indicator={<img src={Portal_Icon_Logo} />}
					className={"antd-spin-loading-top antd-spin-blink"}
					wrapperClassName={"loading-wrapper"}
					spinning={this.state.searchloading}
				>
					{this.props.level === "bronze" ? (
						<div
							className={
								this.state.data?.on_hire === true ? "vesselStatus" : "null"
							}
							style={
								this.props.ismobile
									? {
											position: "absolute",
											zIndex: "10000000",
											width: "100%",
											left: "0px",
											bottom: "0px",
											background: "white",
									  }
									: {}
							}
						>
							{/* Close button for mobile version*/}
							{this.props.ismobile && (
								<Button
									style={{
										position: "absolute",
										zIndex: "9999",
										right: "0",
										fontSize: "30px",
										border: "none",
										background: "rgba(0,0,0,0)",
									}}
									onClick={() => {
										this.onClose();
									}}
								>
									{GetAntIcon("close")}
								</Button>
							)}

							<div style={{ display: "flex", placeContent: "center" }}>
								<Image
									className={
										this.props.vesselImgMap[`${this.props.rig}`]?.on_hire ===
										true
											? "vessel-onhire vesselImage nodrag"
											: "vessel-notonhire vesselImage nodrag"
									}
									style={{ width: "100%" }}
									src={this.getImageLink()}
									preview={false}
								/>
							</div>

							{/* View documents link for mobile version */}
							{this.props.ismobile ? (
								<div
									style={{
										marginBottom: "15px",
										textAlign: "center",
									}}
								>
									{this.state.data?.on_hire === true ? (
										<a href="#" style={{ textDecoration: "underline" }}>
											View documents
										</a>
									) : null}
								</div>
							) : null}

							{this.state.data !== null && this.state.data.on_hire === true ? (
								<div>
									<Row
										style={{ borderTop: "1px solid #dcdcdc" }}
										className="rowMaster"
									>
										<Col className="colMaster" span={12}>
											Well
										</Col>
										<Col className="colChild" span={12}>
											{this.detailInfo("well_name")}
										</Col>
									</Row>
									<Row className="rowMaster">
										<Col className="colMaster" span={12}>
											Wellbore
										</Col>
										<Col className="colChild" span={12}>
											{this.detailInfo("wellbore_name")}
										</Col>
									</Row>
									<Row className="rowMaster">
										<Col className="colMaster" span={12}>
											Operation
										</Col>
										<Col className="colChild" span={12}>
											{this.detailInfo("operation_name")}
										</Col>
									</Row>
									<Row className="rowMaster">
										<Col className="colMaster" span={12}>
											Day no.
										</Col>
										<Col className="colChild" span={12}>
											{this.detailInfo("day_number")}
										</Col>
									</Row>
									<Row>
										<>
											{Object.keys(document_object).map(
												(document_key: any, index: any) => {
													return renderBronzeDocumentButtons(document_key);
												}
											)}
										</>
									</Row>
								</div>
							) : null}
						</div>
					) : null}
					{this.props.level === "silver" && this.state.data !== null
						? renderSilverCards()
						: null}
					{this.props.level === "gold" && this.state.data !== null
						? // <>
						  // 	<div>HI THIS IS GOLD LEVEL</div>
						  // </>
						  renderSilverCards()
						: null}
				</Spin>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		vessels: state.vessels.allVessels,
		selectedVessel: state.vessels.selectedVessel,
		vesselImgMap: state.vesselImgMap,
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(VesselStatus);
