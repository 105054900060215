import { useMemo, useState } from "react";
import CodeMirror, { ReactCodeMirrorProps } from "@uiw/react-codemirror";
import { langs } from "@uiw/codemirror-extensions-langs";
import { autocompletion } from "@codemirror/autocomplete";
import { getTheme } from "../utils/codemirrrortheme";
import useParameters from "../hooks/useParameters";
import { useComponent } from "../contexts/ComponentContext";

interface CustomEditorProps extends ReactCodeMirrorProps {
	completions?: Array<any>;
}

const CustomEditor = ({
	completions,
	value = "",
	...restProps
}: CustomEditorProps) => {
	const [theme, setTheme] = useState("vscodeDark");
	const { menuItem } = useComponent();
	const { parameters } = useParameters(menuItem.key);

	const mentions = parameters?.map((param) => ({
		label: `@${param.name}`,
		type: "variable",
	}));

	const options = useMemo(() => {
		return [...mentions, ...(completions || [])];
	}, [completions]);

	const myCompletions = (context: any) => {
		// let word = context.matchBefore(/\w*/);
		let word = context.matchBefore(/[\@\w]*/);
		if (word.from == word.to && !context.explicit) return null;
		return {
			from: word.from,
			options: options,
		};
	};

	const extensions = [
		langs.sql(),
		autocompletion({ override: [myCompletions] }),
	];

	return (
		<CodeMirror
			{...restProps}
			theme={getTheme(theme)}
			style={{ width: "100%" }}
			extensions={extensions}
			basicSetup={{
				tabSize: 4,
				allowMultipleSelections: true,
			}}
			value={value}
		/>
	);
};

export default CustomEditor;
