import { Button, Input, Layout, Popconfirm, Typography } from "antd";
import { useContext, useState } from "react";
import MeasureList from "./MeasureList";
import { GetAntIcon } from "../utils/ant_icons";
import { CustomDashboardContext } from "../contexts/context";
import { useComponent } from "../contexts/ComponentContext";

const { Header } = Layout;
const { Text } = Typography;

interface MeasureContentProps {
	menuitem: any;
}

const MeasureTab = ({ menuitem }: MeasureContentProps) => {
	const [searchVal, setSearchVal] = useState<string | undefined>("");

	const {
		isOwner,
		state: customDashboardState,
		measureDispatch,
		measureState,
	} = useContext(CustomDashboardContext);
	const { measures, menuItem } = useComponent();
	const actionable = !customDashboardState.editMode;

	const handleEditMeasure = (measure: MeasureType) => {
		measureDispatch({ type: "EDIT_MEASURE", payload: measure });
	};

	const handleCreateMeasure = () => {
		localStorage.removeItem(`menu.${menuItem.key}`);
		measureDispatch({ type: "CREATE_MEASURE" });
	};

	const handleRestoreMeasure = (measure: Partial<MeasureType>) => {
		measureDispatch({ type: "RESTORE_MEASURE", payload: measure });
	};

	const renderwMeasureButton = () => {
		if (!isOwner || menuitem?.component != "Custom Dashboard") return null;

		const hasPrevious = localStorage?.getItem(`menu.${menuItem.key}`);

		if (hasPrevious) {
			const measure = JSON.parse(hasPrevious);
			return (
				<Popconfirm
					title="Unsaved measure"
					description="You have unsaved changes. Would you like to restore them or create a new measure?"
					okText="Restore"
					okButtonProps={{ type: "primary" }}
					cancelText="Create New"
					onCancel={handleCreateMeasure}
					disabled={!actionable}
					onConfirm={() => handleRestoreMeasure(measure)}
				>
					<Button icon={GetAntIcon("plus")}>New measure</Button>
				</Popconfirm>
			);
		}
		return (
			<Button onClick={handleCreateMeasure} icon={GetAntIcon("plus")}>
				New measure
			</Button>
		);
	};

	const getMeasures = () => {
		return searchVal
			? measures?.filter((_measure) => {
					return (
						_measure?.name?.toLowerCase()?.includes(searchVal?.toLowerCase()) ||
						_measure?.description
							?.toLowerCase()
							?.includes(searchVal?.toLowerCase())
					);
			  })
			: measures;
	};

	return (
		<>
			<Layout style={{ background: "transparent", height: "inherit" }}>
				<Header
					style={{
						padding: 0,
						background: "transparent",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Text style={{ fontSize: 24 }}>Measures</Text>
					{renderwMeasureButton()}
				</Header>
				<Input
					placeholder="Search..."
					onChange={(e) => setSearchVal(e.target.value)}
					value={searchVal}
					style={{ borderRadius: 2, marginBottom: 16 }}
					allowClear
				/>
				<MeasureList
					onEdit={handleEditMeasure}
					measures={getMeasures()}
					state={measureState}
					dispatch={measureDispatch}
				/>
			</Layout>
		</>
	);
};

export default MeasureTab;
