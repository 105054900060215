import {
	Button,
	Divider,
	Flex,
	Input,
	InputRef,
	Modal,
	ModalProps,
	Select,
	Space,
	Spin,
	Table,
	Typography,
} from "antd";
import {
	useCallback,
	useState,
	useMemo,
	ChangeEvent,
	useRef,
	useEffect,
} from "react";
import { getMaximoXMLData } from "../../services/api-server/maximo";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { GetAntIcon } from "../../utils/ant_icons";

const { TextArea, Search } = Input;

const statusArray1 = [
	{ label: "Status", key: "STATUS" },
	{ label: "Asset Number", key: "ASSETNUM" },
	{ label: "Work group", key: "PERSONGROUP" },
	{ label: "Estimated duration", key: "ESTDUR", type: "duration" },
	{ label: "Maintenance criticality", key: "STN_MNT_CRITICALITY" },
	{ label: "Class", key: "WOCLASS" },
	{ label: "Job plan", key: "JPNUM" },
];

const statusArray2 = [
	{ label: "Target start", key: "TARGSTARTDATE", type: "date" },
	{ label: "Target finish", key: "TARGCOMPDATE", type: "date" },
];
const statusArray3 = [
	{ label: "Actual start", key: "ACTSTART", type: "date" },
	{ label: "Actual finish", key: "ACTFINISH", type: "date" },
];

const tableColumns = [
	{
		title: "Log",
		dataIndex: "WORKLOGID",
		key: "Log",
	},
	{
		title: "Date",
		dataIndex: "CREATEDATE",
		key: "Date",
		render: (text: any) => {
			var date = new Date(text);
			return date.toLocaleDateString("en-GB", {
				year: "numeric",
				month: "short",
				day: "numeric",
			});
		},
	},
	{
		title: "Type",
		dataIndex: "LOGTYPE",
		key: "Type",
	},
	{
		title: "Class",
		dataIndex: "CLASS",
		key: "Class",
	},
	{
		title: "Summary",
		dataIndex: "DESCRIPTION",
		key: "Summary",
	},
	{
		title: "Created by",
		dataIndex: "CREATEBY",
		key: "Created by",
	},
];

type MaximoModalProps = ModalProps & {
	maximoRecord: any;
	handleMaximoModalClose: any;
	type: string;
	dataKey: string;
};

const MaximoModal = ({
	maximoRecord = null,
	handleMaximoModalClose = null,
	type = "",
	dataKey = "",
	...rest
}: MaximoModalProps) => {
	const [tableHeight, setTableHeight] = useState<any>(null);
	const [maximoWOLogs, setMaximoWOLogs] = useState<any>([]);
	const [isLoading, setLoading] = useState<any>(true);
	const [logRecord, setLogRecord] = useState<any>(null);
	const [filteredMaximoWOLogs, setFilteredMaximoWOLogs] = useState<any>(null);
	const renderDataDescription = (arrayList: any) => {
		const renderValue = (record: any, type: any) => {
			if (record === "" || !record) {
				return "-";
			}
			switch (type) {
				case "date":
					var date = new Date(record);
					return date.toLocaleDateString("en-GB", {
						year: "numeric",
						month: "short",
						day: "numeric",
					});
				case "duration":
					var hours = Math.floor(record);

					// Get the decimal part (minutes)
					var decimalPart = record - hours;
					var minutes = Math.round(decimalPart * 60);

					// Format the string
					var result = hours + " h " + minutes + " min";
					return result;

				default:
					return record || "-";
			}
		};

		return (
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "0.5fr 0.5fr",
					minWidth: "50%",
					rowGap: "4px",
				}}
			>
				{arrayList?.map((element: any) => {
					return (
						<>
							<span>{element.label}</span>
							<span>
								{renderValue(maximoRecord?.[element.key]?.[0], element?.type)}
							</span>
						</>
					);
				})}
			</div>
		);
	};

	const RenderableDiv = (htmlString: any) => {
		// console.log(htmlString);

		// Forced replace all text colors to white
		let parsedString = htmlString?.replaceAll(
			/color:\s*(.*?);/g,
			"color: rgb(255,255,255);"
		);

		// console.log(parsedString);
		return (
			<div
				style={{
					overflow: "auto",
					maxHeight: "150px",
					padding: "8px",
					color: "white",
				}}
				dangerouslySetInnerHTML={{ __html: parsedString }}
			/>
		);
	};

	const calculateTableHeight = () => {
		const table: any = document.getElementById("table-container");

		if (table) {
			setTableHeight(table?.clientHeight - 55);
		}
	};

	useEffect(() => {
		calculateTableHeight();
		setLoading(true);
		if (maximoRecord) {
			getMaximoXMLData(
				`RECORDKEY='${maximoRecord?.["WONUM"]?.[0]}' and siteid='${maximoRecord?.["LOCATION"]?.[0]}' order by CREATEDATE desc`,
				undefined,
				undefined,
				"STN_PORTAL_WORKLOGS"
			)
				.then((element: any) => {
					// console.log(element);
					setMaximoWOLogs(element.WORKLOG || []);
				})
				.catch((error: any) => {
					// console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [maximoRecord]);

	return (
		<Modal
			{...rest}
			width={"80%"}
			okText={"Save"}
			okButtonProps={{ disabled: true }}
			open={maximoRecord}
			destroyOnClose
			onCancel={() => {
				setMaximoWOLogs(null);
				setLogRecord(null);
				handleMaximoModalClose();
				setFilteredMaximoWOLogs(null);
			}}
			footer={
				<div>
					<Button
						onClick={() => {
							handleMaximoModalClose();
							setMaximoWOLogs(null);
							setLogRecord(null);
							setFilteredMaximoWOLogs(null);
						}}
						type={"primary"}
					>
						Close
					</Button>
				</div>
			}
		>
			{type === "workOrder" ? (
				<div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
					<div
						style={{ display: "flex", flexDirection: "column", gap: "16px" }}
					>
						<span style={{ fontSize: "16px" }}>Details</span>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								gap: "24px",
								width: "100%",
							}}
						>
							<div
								style={{ display: "flex", flexDirection: "row", width: "60%" }}
							>
								<div style={{ width: "100%" }}>
									{renderDataDescription(statusArray1)}
								</div>
								<Divider type="vertical" style={{ height: "100%" }} />
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "16px",
										width: "100%",
									}}
								>
									<div>{renderDataDescription(statusArray2)}</div>
									<div>{renderDataDescription(statusArray3)}</div>
								</div>
								<Divider type="vertical" style={{ height: "100%" }} />
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									flex: "1",
									gap: "16px",
								}}
							>
								<span style={{ fontSize: "16px" }}>Description</span>
								{/* <ReactQuill
								style={{ overflow: "auto", maxHeight: "150px" }}
								modules={{
									toolbar: false,
								}}
								readOnly
								theme="snow"
								value={maximoWORecord?.DESCRIPTION_LONGDESCRIPTION?.[0] || ""}
							/> */}
								<div
									id="log-details-frame"
									style={{
										minHeight: "180px",
										height: "100%",
										background: "#262626",
										padding: "8px",
										gap: "24px",
										overflow: "hidden",
									}}
								>
									{RenderableDiv(
										maximoRecord?.DESCRIPTION_LONGDESCRIPTION?.[0]
									)}
								</div>
							</div>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "16px",
							// minHeight: "400px",
						}}
					>
						<span style={{ fontSize: "16px" }}>Log Entries</span>
						<Spin
							className="maximo-antd-spin"
							wrapperClassName="flex--table"
							spinning={isLoading}
							style={{
								display: "flex",
								flexDirection: "row",
								gap: "24px",
								width: "100%",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									gap: "24px",
									width: "100%",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "8px",
										width: "60%",
									}}
								>
									<Search
										placeholder="Search entry"
										key={"maximoLogSearch"}
										onSearch={(value: any) => {
											if (value === "") {
												setFilteredMaximoWOLogs(null);
											} else {
												const filtered = maximoWOLogs.filter((wo: any) =>
													wo.WORKLOGID[0].includes(value)
												);
												setFilteredMaximoWOLogs(filtered);
											}
										}}
									/>
									<div
										id="table-container"
										className="kognifai-asset-table"
										style={{
											flex: 1,
											maxHeight: "100%",
											overflow: "hidden",
										}}
									>
										<Table
											bordered
											key={"maximo-record-table"}
											rootClassName="digitaltwin-table"
											rowKey={"Name"}
											virtual
											rowClassName={(record: any) => {
												return record?.WORKLOGID?.[0] ===
													logRecord?.WORKLOGID?.[0]
													? "log-record-row-active"
													: "";
											}}
											pagination={false}
											columns={tableColumns}
											dataSource={filteredMaximoWOLogs || maximoWOLogs || []}
											scroll={tableHeight && { y: tableHeight, x: "500px" }}
											onRow={(data: any) => {
												return {
													onClick: () => {
														if (
															logRecord?.WORKLOGID?.[0] === data?.WORKLOGID?.[0]
														) {
															setLogRecord(null);
														} else {
															setLogRecord(data);
														}
													},
												};
											}}
										></Table>
									</div>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										flex: 1,
										gap: "16px",
										width: "35%",
									}}
								>
									<span style={{ fontSize: "16px" }}>Log Details</span>
									<div
										id="log-details-frame"
										style={{
											minHeight: "180px",
											height: "100%",
											background: "#262626",
											gap: "24px",
											overflow: "hidden",
										}}
									>
										{logRecord ? (
											<>
												{RenderableDiv(
													logRecord?.DESCRIPTION_LONGDESCRIPTION?.[0]
												)}
											</>
										) : (
											<div
												style={{
													height: "100%",
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												{" "}
												<ExclamationCircleFilled
													style={{ fontSize: "42px", color: "#177DDC" }}
												/>
												<span>
													Please select a log entry to view the description
												</span>
											</div>
										)}
									</div>
								</div>
							</div>
						</Spin>
					</div>
				</div>
			) : (
				<div
					style={{
						height: "60vh",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						gap: "24px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							gap: "8px",
						}}
					>
						<div
							style={{
								fontSize: "86px",
								color: " #FFFFFF73",
								display: "flex",
								width: "86px",
								height: "86px",
							}}
						>
							{GetAntIcon("coffee")}
						</div>{" "}
						<span style={{ fontSize: "30px", color: "#FFFFFF73" }}>
							Nothing here, yet...
						</span>
					</div>

					<span
						style={{
							fontSize: "14px",
							lineHeight: "22px",
							color: "#FFFFFF73",
							textAlign: "center",
						}}
					>
						Stay tune for more valuable <br /> information coming here soon.
					</span>
				</div>
			)}
		</Modal>
	);
};

export default MaximoModal;
