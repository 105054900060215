import { api } from "../../contexts/AuthContext";
import { Tenant } from "./_exports";

export const getRoles = () => {
	return new Promise((resolve, reject) => {
		api.get(`/roles`, {
			params: { tenantid: Tenant },
		})
			.then((response) => {
				const data = response?.data[0];
				if (Array.isArray(data?.roles)) {
					resolve(data?.roles);
				} else {
					resolve([]);
				}
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};

export const addRole = (role: any) => {
	const endpoint = `/roles/addRole`;

	return new Promise((resolve, reject) => {
		api.post(endpoint, role)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => reject(err));
	});
};

export const deleteRolesByMenuKey = (menuKey: any) => {
	const endpoint = `/roles/deleteRole`;

	return new Promise((resolve, reject) => {
		api.post(endpoint, { menuKey })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => reject(err));
	});
};
