import { InputNumber, Select, Space, Row, Col } from "antd";
import { useContext, useMemo, useState } from "react";
import SlicerRangePicker from "./SlicerRangePicker";
import dayjs from "dayjs";
import { CustomDashboardContext } from "../contexts/context";
import { getQuarter } from "../utils/utils";
import ParamSelectDropdownV2 from "./ParamSelectDropdownV2";
import useParameters from "../hooks/useParameters";
import { useSelector } from "react-redux";

type SlicerInputsProps = {
	menuItem?: any;
	onChange?: (values: any) => void;
};

const SlicerInputs = ({ menuItem, onChange = () => {} }: SlicerInputsProps) => {
	const ismobile = useSelector((state: any) => state.ismobile);
	const { state: customDashboardState } = useContext(CustomDashboardContext);
	const { key = "" } = menuItem;
	const { parameters, paramValues } = useParameters(key);
	const [values, setValues] = useState({
		...customDashboardState.sliceValues,
		...paramValues,
	});

	const handleChange = (key: string, value: any) => {
		let newValues = { ...values, [key]: value };

		// handle to reflect the prefix date range
		const { start_date, end_date } = calculateDateRange(newValues);
		newValues = { ...newValues, start_date, end_date, useTarget: true };
		setValues(newValues);
		onChange(newValues);
	};

	const handleSlicerChange = (value: any) => {
		const { occurrences, unit, mode, ...others } = values;
		const newValues = { ...others, ...value, useTarget: false };

		setValues(newValues);
		onChange(newValues);
	};

	// doing this to prevent re-rendering when user is halfway through select dates
	// before metadata fetch is complete
	const slicerDatePicker = useMemo(() => {
		return (
			<SlicerRangePicker
				key={`${key}-slicer-range-picker`}
				paramRef={["start_date", "end_date"]}
				style={{ borderRadius: 2, width: "100%" }}
				onChange={handleSlicerChange}
				value={[
					values?.start_date ? dayjs(values?.start_date) : null,
					values?.end_date ? dayjs(values?.end_date) : null,
				]}
			/>
		);
	}, [values, key]);

	return ismobile ? (
		<Space style={{ width: "100%" }}>
			<Row gutter={[8, 16]} style={{ lineHeight: 0 }}>
				{/* Filtering current parameters to exclude single value selection params */}
				{parameters
					?.filter((param) => param.selection === "multi")
					?.map((param, i) => (
						<Col xs={24} md={6}>
							<ParamSelectDropdownV2
								key={i}
								overlayStyle={{ width: "100%" }}
								parameter={param}
								onChange={(value, options) => {
									const paramValue = options?.map(
										(option: any) => option.value
									);
									handleChange(param?.name, paramValue);
								}}
								value={values?.[param?.name]}
								useDisplayName
							/>
						</Col>
					))}

				<Col xs={8} md={4}>
					<Select
						style={{ width: "100%" }}
						options={[
							{ label: "Current", value: "current" },
							{ label: "Previous", value: "previous" },
						]}
						// placeholder="Current"
						onChange={(value) => handleChange("mode", value)}
						value={values?.mode}
					/>
				</Col>

				<Col xs={4} md={2}>
					<InputNumber
						// placeholder="1"
						style={{ borderRadius: 2, width: "100%" }}
						min={1}
						max={99}
						onChange={(value) => handleChange("occurrences", value)}
						value={values?.occurrences}
						disabled={values?.mode === "current"}
					/>
				</Col>

				<Col xs={12} md={4}>
					<Select
						style={{ width: "100%" }}
						options={[
							{ label: "Year", value: "year" },
							{ label: "Quarter", value: "quarter" },
							{ label: "Month", value: "month" },
							{ label: "Week", value: "week" },
							{ label: "Day", value: "day" },
						]}
						// placeholder="Month"
						onChange={(value) => handleChange("unit", value)}
						value={values?.unit}
					/>
				</Col>

				<Col xs={24} md={8}>
					{slicerDatePicker}
				</Col>
			</Row>
		</Space>
	) : (
		<Space>
			{/* Filtering current parameters to exclude single value selection params */}
			{parameters
				?.filter((param) => param.selection === "multi")
				?.map((param, i) => (
					<ParamSelectDropdownV2
						key={i}
						overlayStyle={{ width: 200 }}
						parameter={param}
						onChange={(value, options) => {
							const paramValue = options?.map((option: any) => option.value);
							handleChange(param?.name, paramValue);
						}}
						value={values?.[param?.name]}
						useDisplayName
					/>
				))}

			<Select
				style={{ width: 100 }}
				options={[
					{ label: "Current", value: "current" },
					{ label: "Previous", value: "previous" },
				]}
				// placeholder="Current"
				onChange={(value) => handleChange("mode", value)}
				value={values?.mode}
			/>
			<InputNumber
				// placeholder="1"
				style={{ borderRadius: 2, width: 60 }}
				min={1}
				max={99}
				onChange={(value) => handleChange("occurrences", value)}
				value={values?.occurrences}
				disabled={values?.mode === "current"}
			/>
			<Select
				style={{ width: 100 }}
				options={[
					{ label: "Year", value: "year" },
					{ label: "Quarter", value: "quarter" },
					{ label: "Month", value: "month" },
					{ label: "Week", value: "week" },
					{ label: "Day", value: "day" },
				]}
				// placeholder="Month"
				onChange={(value) => handleChange("unit", value)}
				value={values?.unit}
			/>
			{slicerDatePicker}
		</Space>
	);
};

const calculateDateRange = (values: any) => {
	const { mode = "current", occurrences = 1, unit = "month" } = values;
	let startDate: dayjs.Dayjs = dayjs();
	let endDate: dayjs.Dayjs = dayjs();

	if (mode === "current") {
		if (unit === "quarter") {
			const quarterDates = getQuarter();
			startDate = quarterDates.startDate;
		} else {
			startDate = dayjs().startOf(unit);
		}
	} else {
		if (unit === "quarter") {
			const quarterDates = getQuarter(
				dayjs().subtract(occurrences * 3, "month")
			);

			startDate = quarterDates.startDate;
			endDate = quarterDates.endDate;
		} else {
			startDate = dayjs().subtract(occurrences, unit).startOf(unit);
			endDate = dayjs(startDate).endOf(unit);
		}
	}

	return {
		start_date: startDate.format("YYYY-MM-DD"),
		end_date: endDate.format("YYYY-MM-DD"),
	};
};

export default SlicerInputs;
