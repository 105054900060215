import { CMainMenu } from "./Menu";
import { Dropdown } from "antd";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

class AdminMenu extends CMainMenu {
	constructor(props: any) {
		super(props);
	}
	componentDidMount() {
		// console.log(this.props.username);
		// //this.setState({username:this.props.user?.preferred_username})
		// this.setState({ username: this.props.username });
	}

	render() {
		return (
			<Dropdown dropdownRender={(() => this.render_icon_menu(() => this.props.toggle()))} >
				<div
					className="hexagon-button site-button"
					style={{ cursor: "pointer" }}
				>
					<FontAwesomeIcon className="site-button-icon" icon={faUser} />
				</div>
			</Dropdown>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		mainMenu: state.allModules.adminMenu,
		role: state.role,
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(AdminMenu);
