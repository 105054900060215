import React, { useCallback, useContext, useMemo, useState } from "react";
import Plot from "react-plotly.js";
import {
	Button,
	Card,
	CardProps,
	Dropdown,
	Empty,
	Flex,
	MenuProps,
	Spin,
	Tooltip,
	Typography,
} from "antd";
import useSize2 from "../hooks/useSize2";
import { CustomDashboardContext, VisualContext } from "../contexts/context";
import { EditOutlined, LockOutlined } from "@ant-design/icons";
import VisualDeleteHandler from "./VisualDeleteHandler";
import CustomStatistic, { getTarget } from "./CustomStatistic";
import LabelVisual from "./LabelVisual";
import { VisualType } from "../types/Visual";
import useMenu from "../hooks/useMenu";
import RequestAccessModal from "./modal/RequestAccessModal";
import { getColors } from "../utils/utils";
import useMeasures from "../hooks/useMeasures";

const { Link } = Typography;

type VisualProps = CardProps & {
	visual: VisualType;
	plotStyle?: React.CSSProperties;
	layoutId?: string;
	// showTarget?: boolean;
	showControls?: boolean;
	data?: any;
	enableDrilldown?: boolean;
};

// * Visual is only responsible for displaying data
const Visual = ({
	visual,
	plotStyle,
	layoutId,
	showControls,
	data = null,
	enableDrilldown,
	loading,
	...restProps
}: VisualProps) => {
	const { getMenuByKey } = useMenu();
	const { getMeasureById } = useMeasures();
	const [showModal, setShowModal] = useState(false);
	const { height } = useSize2(`el-${layoutId}`);
	const {
		state: dashboardState,
		dispatch: dashboardDispatch,
		isOwner,
		loaded,
	} = useContext(CustomDashboardContext);
	const actionable = !dashboardState.editMode;

	const { dispatch: visualDispatch } = useContext(VisualContext);

	const measure = getMeasureById(visual.measure);

	// CALLBACKS
	const handleEdit = useCallback(() => {
		visualDispatch({ type: "EDIT_VISUAL", payload: visual });
	}, [visual]);

	const dropdownMenu: MenuProps["items"] = useMemo(() => {
		if (showControls) {
			return [
				{
					label: <Link onClick={handleEdit}>Edit</Link>,
					type: "group",
				},
				{
					label: <VisualDeleteHandler visual={visual} layoutId={layoutId} />,
					type: "group",
				},
			] as MenuProps["items"];
		}

		return [];
	}, [layoutId, visual, showControls]);

	// RENDER
	const renderBody = () => {
		if (!data) return null;
		if (!visual?.data?.value) return null;

		// if (!hasPermission) {
		// 	return (
		// 		<Flex
		// 			vertical
		// 			align="center"
		// 			justify="center"
		// 			style={{ height: "100%", width: "100%" }}
		// 			gap={12}
		// 		>
		// 			<LockOutlined style={{ fontSize: 32, opacity: 0.75 }} />
		// 			Sorry, you don't have access <Button>Request access</Button>
		// 		</Flex>
		// 	);
		// }

		if ((data && data?.length === 0) || !visual?.data?.type) {
			return (
				<Flex
					justify="center"
					align="center"
					style={{ height: "100%", width: "100%", position: "relative" }}
				>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</Flex>
			);
		}

		if (visual?.data?.type === "card") {
			const unitTargets = visual?.targets?.[dashboardState?.sliceValues?.unit];
			// to handle situations when user uses the predefined date ranges. (e.g day, month, week, quarter, year)
			return (
				<CustomStatistic
					style={{ filter: "blur(10px)" }}
					visual={visual}
					valueObj={data?.[0]?.[visual?.data?.value]}
					targets={unitTargets}
					indicator_value={data?.[0]?.[visual?.data?.indicator_value]}
					target={getTarget(
						unitTargets?.target,
						visual?.target_multiplier || "",
						visual?.target_based_on || "",
						dashboardState?.sliceValues
					)}
				/>
			);
		} else if (visual?.data?.type === "label") {
			// to handle situations when user uses the predefined date ranges. (e.g day, month, week, quarter, year)

			return (
				<LabelVisual
					label={visual?.data?.value}
					value={data[0][visual?.data?.value]}
				/>
			);
		} else if (visual?.data?.type === "image") {
			// to handle situations when user uses the predefined date ranges. (e.g day, month, week, quarter, year)

			return (
				<Flex style={{ height: "100%", width: "100%" }}>
					<img
						style={{ width: "100%", objectFit: "contain" }}
						src={data[0][visual?.data?.value]}
					/>
				</Flex>
			);
		} else {
			return (
				<Plot
					style={{
						width: "100%",
						// height: height - 48,
						...plotStyle,
					}}
					data={[
						{
							type: visual?.data?.type,
							x: data?.map((d: any) => d[visual?.data?.x]),
							y: data?.map((d: any) => d[visual?.data?.y]),
							values: data?.map((d: any) => d[visual?.data?.values]),
							labels: data?.map((d: any) => d[visual?.data?.labels]),
							hole: 0.7,
							mode: "gauge+number",
							textinfo: "none",
							value: data?.[0]?.[visual?.data?.value],
						},
					]}
					layout={{
						autosize: true,
						showlegend: false,
						plot_bgcolor: "rgb(17, 29, 44)",
						paper_bgcolor: "rgb(17, 29, 44)",
						// margin: staticPlot ? { l: 0, r: 0, t: 0, b: 0 } : {},
						margin: { l: 0, r: 0, t: 0, b: 0 },
						// legend: {
						// 	x: 0.5,
						// 	y: -0.3,
						// 	orientation: "h",
						// 	xanchor: "center",
						// 	yanchor: "bottom",
						// },
					}}
					config={{ staticPlot: true, displayModeBar: false }}
					useResizeHandler
				/>
				// <>Work In Progress</>
			);
		}
	};

	const getExtra = () => {
		if (!isOwner) return null;

		if (showControls)
			return (
				<Dropdown disabled={!actionable} menu={{ items: dropdownMenu }}>
					<Link>
						<EditOutlined />
					</Link>
				</Dropdown>
			);
		return null;
	};

	if (!loaded) return null;

	return (
		<Spin spinning={loading} wrapperClassName={"generic-loading-wrapper"}>
			<Card
				{...restProps}
				title={
					<Tooltip placement="right" title={visual?.subtitle}>
						{visual?.title}
					</Tooltip>
				}
				extra={restProps.extra ?? getExtra()}
				style={{
					borderRadius: 2,
					width: "100%",
					height: "100%",
					background: getColors(undefined).backgroundColor,
					borderColor: getColors(undefined).borderColor,
					...restProps.style,
				}}
				styles={{
					body: {
						height: "calc(100% - 59px)", // Subtracting 59 for the header
						display: "flex",
						position: "relative",
					},
					header: {
						padding: "16px 24px",
						background: getColors(undefined).headerColor,
						borderColor: getColors(undefined).borderColor,
						...restProps.styles?.header,
					},
				}}
				className="visual-card"
				classNames={{
					body:
						!enableDrilldown || !visual?.drilldown || data === "Unauthorized"
							? ""
							: "viz-body-clickable",
				}}
			>
				{data === "Unauthorized" ? (
					<Flex
						vertical
						align="center"
						justify="center"
						style={{
							height: "100%",
							width: "100%",
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: `translate(-50%, -50%)`,
							zIndex: 2,
							backdropFilter: "blur(10px)",
						}}
						gap={12}
					>
						<LockOutlined style={{ fontSize: 32, opacity: 0.75 }} />
						Sorry, you don't have access{" "}
						<Button onClick={() => setShowModal(true)}>Request access</Button>
					</Flex>
				) : null}
				<div
					style={{ height: "100%" }}
					onClick={() => {
						if (visual.drilldown && enableDrilldown)
							dashboardDispatch({
								type: "SHOW_DRILLDOWN",
								payload: visual,
							});
					}}
				>
					{renderBody()}
				</div>
			</Card>
			{measure ? (
				<RequestAccessModal
					open={showModal}
					menuItem={getMenuByKey(measure.menuKey || "")}
					onCancel={() => setShowModal(false)}
				/>
			) : null}
		</Spin>
	);
};

export default Visual;
