import { Table, TableProps } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { capitalize } from "../utils/dataTools";
import { useDataExplorer } from "../contexts/DataExplorerContext";

interface FieldTableProps extends TableProps<any> { }

const FieldTable = ({ ...restProps }: FieldTableProps) => {
	const { state, dispatch } = useDataExplorer()

	const dataSource = useMemo(() => {
		let filteredFields = state.dataset?.fields;
		if (!filteredFields) return [];
		if (state.searchVal && state.searchVal !== "") {
			filteredFields = filteredFields.filter((field: any) =>
				field.name.includes(state.searchVal)
			);
		}
		return generateDataSource(filteredFields);
	}, [state.searchVal, state.dataset?.fields]);

	const columns = useMemo(() => {
		if (!dataSource) return [];
		return generateColumns(dataSource);
	}, [dataSource]);

	const onRow = (record: any) => {
		return {
			onClick: (ev: any) => {
				dispatch({ type: 'FIELD', payload: record.key })

			},
			className:
				state.field === record?.key ? "selected-field" : "",
			style: { cursor: "pointer" },
		};
	}

	const scrollTo = (position: number) => {
		const table = document.querySelector(".field-table div.ant-table-body");
		if (table) table.scrollTop = position;
	}

	useEffect(() => {
		const row = document.querySelector(".field-table-row");
		if (row && state.field) {
			const index = state?.dataset?.fields?.findIndex(
				(field: any) => field.name === state.field
			);
			const scrollTop = row.clientHeight * index;
			scrollTo(scrollTop);
		} else {
			scrollTo(0);
		}
	}, [
		state?.dataset?.fields,
		state.field,
		state.searchVal,
	]);

	if (!state?.dataset?.fields) return null;

	return (
		<Table
			dataSource={dataSource}
			columns={columns}
			size="small"
			pagination={false}
			rowClassName={"field-table-row"}
			tableLayout="auto"
			bordered
			scroll={{ y: 212 }}
			onRow={onRow}
			title={() => "Fields"}
			rootClassName="field-table"
			style={{ maxHeight: 300 }}
			{...restProps}
		/>
	);
};

const generateDataSource = (data: Array<any>) => {
	const date = dayjs().toISOString();
	return data?.map((d, i) => {
		const { nullable, metadata, ...restData } = d;
		return { key: d.name, ...restData, latest_value: date };
	});
};

const generateColumns = (data: Array<any>) => {
	if (data?.length === 0) return;
	const { key, ...restData } = data?.[0];
	const columns: any = [];
	for (const field in restData) {
		switch (field) {
			case "name":
				columns.push({ dataIndex: field, key: field, title: "Field" });
				break;
			case "type":
				columns.push({ dataIndex: field, key: field, title: "Data Type" });
				break;

			default:
				columns.push({
					dataIndex: field,
					key: field,
					title: capitalize(field),
				});
				break;
		}
	}
	return columns;
};

export default FieldTable;
