import { useEffect, useState } from "react";
import { Layout, Spin } from "antd";
import { connect } from "react-redux";
import NoAccess from "../NoAccess";

const { Content } = Layout;

const UptimeKuma = (props: any) => {
	const [viewableUrl, setViewableUrl] = useState<any>(null);
	const [token, setToken] = useState<any>({});
	const [loading, setLoading] = useState(false);

	const checkRoles = () => {
		if (props.role.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
			return true;
		} else if (props.params?.roles?.length === 0 || !props.params?.roles) {
			return true;
		} else {
			for (var i = 0; i < props.params?.roles?.length; i++) {
				if (props.params?.roles[i]?.toLowerCase().includes("admin")) {
					return true;
				}
			}
		}
	};

	return (
		<>
			<div
				style={{
					height: "100%",
					width: "100%",
					display: "flex",
				}}
			>
				{checkRoles() ? (
					<Layout
						style={{
							height: "100%",
							background: "#fafafb",
							// padding: "32px 32px 0",
							gap: 24,
						}}
					>
						{/* {!loading && viewableUrl ? ( */}
						<iframe
							title={"Hello world"}
							id="iframe"
							src={"https://uptime.flash.proxy.brixmind.com/status/test"}
							style={{ width: "100%", height: "100%" }}
						/>
						{/* ) : (
							<></>
						)} */}
					</Layout>
				) : (
					<NoAccess />
				)}
			</div>
		</>
	);
};

export default UptimeKuma;
