import {
	Select,
	Button,
	Input,
	Table,
	Tree,
	Space,
	Tag,
	Typography,
	Popconfirm,
	TablePaginationConfig,
} from "antd";
import Column from "antd/lib/table/Column";
import { v4 as uuidv4 } from "uuid";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React from "react";
import { connect } from "react-redux";
import { getAppRoles } from "../../services/api-server/admin";
import { GetAntIcon } from "../../utils/ant_icons";

let initial = { name: "", dir_struct: "", tags: [], roles: [] };
const operatorList = ["=", ">", ">=", "<", "<="];
const { DirectoryTree } = Tree;
const { Option } = Select;
var timer: any;

class ModalDirectoryManager extends React.Component<any> {
	props: any = {
		open: () => {},
	};

	state: any = {
		page: 1,
		selectedUser: null,
		dir: { ...initial },
		editIndex: -1,
		editting: false,
		clean: true,
		dirdata: [],
		expandedKeys: [],
		inputVisible: {},
	};

	constructor(props: any) {
		super(props);
		this.props = { ...props };
		this.state.folderData = JSON.parse(JSON.stringify(this.props.folderData));
		this.state.handleclean = this.props.handleclean;
		this.state.handledata = this.props.handledata;
	}

	componentDidMount() {
		this.getRoles();
		if (!this.state.folderData?.dir) {
			this.setState({ dirdata: [] });
		} else {
			this.setState({ dirdata: [...this.state.folderData?.dir] });
		}
	}

	componentWillUnmount() {
		this.setState({ dirdata: [] });
	}

	componentDidUpdate() {}

	getRoles = () => {
		getAppRoles().then((data: any) => {
			if (data) {
				this.setState({ roles: data });
			}
		});
	};

	render() {
		const changePage = (value: any) => {
			this.setState({ page: value });
		};

		const saveDirStructure = (index: any) => {
			if (!this.state.dir.dir_struct) {
				alert("Input all values");
			} else {
				let folderData = { ...this.state.folderData };
				let dir = folderData.dir;
				const dirParams = { ...this.state.dir };
				if (dir === undefined) {
					dir = [];
				}
				index >= 0 ? (dir[index] = dirParams) : dir.push(dirParams);
				folderData.dir = [...dir];

				initial.tags = [];
				initial.roles = [];

				this.setState({
					folderData: folderData,
					dir: initial,
					dirdata: dir,
					treedata: null,
					editIndex: -1,
					inputVisible: {},
					temp: null,
					editting: false,
				});
				this.state.handleclean();
				this.state.handledata(JSON.parse(JSON.stringify(folderData)));
				clearTimeout(timer);
			}
		};

		const updateDirParams = (key: string, value: string) => {
			let data: any = { ...this.state.dir };
			data[`${key}`] = value;
			this.setState({ dir: data });
		};

		const editDirParams = (record: any, index: number) => {
			this.setState({ dir: record, editIndex: index, inputVisible: {} });
		};

		const addTag = (type: any, value: any) => {
			let iv = { ...this.state.inputVisible };
			if (type === "tags") value = value.toUpperCase();
			iv[type] = false;
			if (value !== "") {
				let data = { ...this.state.dir };
				if (data[type]) {
					if (!data[type].includes(value)) {
						data[type].push(value);
					}
				} else {
					data[type] = [value];
				}
				this.setState({ dir: data, inputVisible: iv, clean: false });
			} else {
				this.setState({ inputVisible: iv });
			}
		};

		const deleteTag = (type: any, index: any) => {
			let data = JSON.parse(JSON.stringify(this.state.dir));
			data[type].splice(index, 1);
			this.setState({ dir: data, clean: false });
		};

		const deleteDirParams = (index: number) => {
			let data: any = JSON.parse(JSON.stringify(this.state.folderData));
			let dirParams: any[] = data?.dir;
			dirParams.splice(index, 1);
			data.dir = dirParams;
			if (data["dir"].length === 0) {
				delete data["dir"];
			}
			initial.tags = [];
			initial.roles = [];
			this.setState({
				folderData: data,
				dir: initial,
				dirdata: dirParams,
				treedata: null,
				editIndex: -1,
				editting: false,
				inputVisible: {},
				temp: null,
				popvisible: false,
			});
			this.state.handleclean();
			this.state.handledata(data);
			clearTimeout(timer);
		};

		const renderTag = (dir: any, type: any) => {
			return (
				<>
					{dir[type] ? (
						dir[type].map((tag: any, index: any) => {
							return (
								<Tag
									className="role-tags"
									key={tag + index}
									closable={true}
									onClose={() => deleteTag(type, index)}
									style={
										!this.props.ismobile
											? { fontSize: "14px" }
											: { fontSize: "14px", margin: "8px 8px 0px 0px" }
									}
								>
									{tag}
								</Tag>
							);
						})
					) : (
						<></>
					)}
					{!this.state.inputVisible[type] && (
						<Tag
							className="site-tag-plus"
							style={this.props.ismobile ? { margin: "8px 8px 0px 0px" } : {}}
							onClick={() => {
								let iv = { ...this.state.inputVisible };
								iv[type] = true;
								this.setState({ inputVisible: iv });
							}}
						>
							<PlusOutlined /> Add {`${type.substring(0, type.length - 1)}`}
						</Tag>
					)}
					{this.state.inputVisible.length !== 0 &&
						this.state.inputVisible[type] && (
							<>
								{type === "roles" ? (
									<Select
										getPopupContainer={(trigger) => trigger.parentNode}
										className="tag-select"
										value=""
										style={
											this.props.ismobile ? { margin: "8px 8px 0px 0px" } : {}
										}
										onChange={(ev: any) => addTag(type, ev)}
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) => {
											return (option!.children as unknown as string)
												.toLowerCase()
												.includes(input.toLowerCase());
										}}
									>
										{this.state.roles?.map((element: any) => {
											return (
												<Option key={element} value={element}>
													{element}
												</Option>
											);
										})}
									</Select>
								) : (
									<Input
										type="text"
										style={{ width: "30%" }}
										className={"input"}
										key={"tag"}
										onKeyUp={(e: any) => {
											clearTimeout(timer);
											timer = setTimeout(function () {
												addTag(type, e.target.value);
											}, 1000);
											if (e.key === "Enter") {
												addTag(type, e.target.value);
												clearTimeout(timer);
											}
										}}
									/>
								)}
							</>
						)}
				</>
			);
		};

		const addNewTagParameters = () => {
			const add = () => {
				let data = { ...this.state.dir };
				let tags = data.tags;
				if (tags === undefined) {
					tags = [];
				}
				tags.push({ key: "", operator: "=", value: "" });
				data.tags = tags;
				this.setState({ dir: data, clean: false, disabled: true });
			};
			return (
				<button
					key={"addnewtable"}
					className={"admin-menu-button"}
					onClick={add}
				>
					+ Add tag
				</button>
			);
		};

		const updateTagParams = (index: number, key: string, value: string) => {
			let data: any = JSON.parse(JSON.stringify(this.state.dir));
			let tagParams: any[] = data?.tags;
			tagParams[index][`${key}`] = value;
			data.tags = tagParams;
			this.setState({ dir: { ...data }, clean: false });
		};

		const deleteTagParams = (index: number) => {
			let data: any = JSON.parse(JSON.stringify(this.state.dir));
			let tagParams: any[] = data?.tags;
			tagParams.splice(index, 1);
			data.tags = tagParams;
			if (data["tags"].length === 0) {
				delete data["tags"];
			}
			this.setState({ dir: { ...data }, clean: false });
		};
		const TagParameters = () => {
			const data = { ...this.state.dir };
			let tagParams = data?.tags;
			if (tagParams !== undefined && tagParams !== null && tagParams.length) {
				return (
					<>
						<div
							style={!this.props.ismobile ? { width: "70%" } : {}}
							className="folder-tag-layout-properties"
						>
							{tagParams.map((obj: any, index: any) => {
								return (
									<>
										<div>
											<Input
												type="text"
												className={"input"}
												key={index}
												value={obj.key}
												placeholder="Key"
												onChange={(v: any) =>
													updateTagParams(index, "key", v.target.value)
												}
											/>
										</div>
										<div>
											<Select
												showSearch
												showArrow={false}
												style={{ width: "100%", textAlign: "center" }}
												optionFilterProp="children"
												getPopupContainer={(trigger) => trigger.parentNode}
												onChange={(ev: any) =>
													updateTagParams(index, "operator", ev)
												}
												defaultValue={obj.operator}
												filterOption={(input, option) => {
													return (option!.children as unknown as string)
														.toLowerCase()
														.includes(input.toLowerCase());
												}}
											>
												{operatorList?.map((element: any) => {
													return (
														<Option key={element} value={element}>
															{element}
														</Option>
													);
												})}
											</Select>
										</div>
										<div>
											<Input
												type="text"
												className={"input"}
												key={index}
												value={obj.value}
												placeholder="Value"
												onChange={(v: any) =>
													updateTagParams(
														index,
														"value",
														v.target.value.toUpperCase()
													)
												}
											/>
										</div>
										<Button
											className="button-general"
											type="primary"
											style={{ width: "80px" }}
											onClick={() => deleteTagParams(index)}
										>
											{" "}
											Delete{" "}
										</Button>
									</>
								);
							})}
						</div>
						<div style={{ marginTop: "3px" }}>{addNewTagParameters()}</div>
					</>
				);
			} else {
				return <>{addNewTagParameters()}</>;
			}
		};

		const renderDirTree = () => {
			const treesort = (tree: any, taglist: any, counter: any) => {
				if (tree === undefined) {
					tree = [];
				}
				let temp = {
					title: taglist[counter],
					type: "folder",
					key: uuidv4(),
					children: [],
				};

				let index: any = 0;
				if (counter < taglist.length) {
					if (
						tree?.find((element: any) => element.title === taglist[counter])
					) {
						index = tree.findIndex(
							(element: any) => element.title === taglist[counter]
						);
					} else {
						tree.push(temp);
						index = tree.length - 1;
					}
					tree[index].children = treesort(
						tree[index].children,
						taglist,
						counter + 1
					);
					if (tree[index].children.length < 1) {
						delete tree[index].children;
					}
					return tree;
				} else {
					return tree;
				}
			};

			let treedata: any = [];
			if (this.state.dirdata) {
				Promise.all(
					this.state.dirdata.map((element: any) => {
						let folders = element.dir_struct
							?.replaceAll(" ", "")
							.split("/")
							.filter(Boolean);
						treedata = treesort(treedata, folders, 0);
						return null;
					})
				).then(() => {
					if (treedata.length !== 0 && !this.state.treedata) {
						this.setState({ treedata: treedata });
					}
				});
				return (
					<>
						{this.state.treedata ? (
							<DirectoryTree
								expandAction={"doubleClick"}
								defaultExpandAll
								treeData={this.state.treedata}
							></DirectoryTree>
						) : null}
					</>
				);
			} else {
				return <></>;
			}
		};

		const renderDirParameters = () => {
			const dirParams = { ...this.state.dir };
			const checkDir = () => {
				if (!dirParams.dir_struct) {
					return true;
				} else {
					if (dirParams.tags?.length > 0) {
						return !dirParams.tags.every(
							(tag: any) => tag.key != "" && tag.value != ""
						);
					} else {
						return false;
					}
				}
			};
			return (
				<>
					{!this.props.ismobile ? (
						<div className="modal-dirparameter-container">
							<div className="modal-dirparameter-content">
								{/* <div>
                            <div>
                                Directory Name
                            </div>
                            <Input
                                type="text"
                                style={{ width: "95%" }}
                                className={'input'}
                                key={"name"}
                                value={dirParams.name}
                                placeholder="Name"
                                onChange={(v: any) => updateDirParams('name', v.target.value)}
                            />
                        </div> */}
								<Space direction="vertical" size={8}>
									<div>Directory Structure</div>
									<Input
										type="text"
										style={{ width: "95%" }}
										className={"input"}
										key={"directoryStructure"}
										value={dirParams.dir_struct}
										placeholder="Eg. Well/DON/DDR"
										onChange={(v: any) => {
											updateDirParams("dir_struct", v.target.value);
											this.setState({ clean: false });
										}}
									/>
								</Space>
								<Space direction="vertical" size={8}>
									<div>Roles</div>
									{renderTag(dirParams, "roles")}
								</Space>
								<Space
									direction="vertical"
									size={8}
									style={{
										gridArea: "c",
										display: "flex",
										flexDirection: "column",
									}}
								>
									<Space direction="horizontal">
										<div>Tags</div>
										<div>
											( Logical Operator:{" "}
											<Select
												onChange={(value: any) => {
													updateDirParams("logical_op", value);
													this.setState({ clean: false });
												}}
												dropdownStyle={{ zIndex: "30005" }}
												style={{ width: "80px" }}
												value={dirParams.logical_op || "OR"}
												defaultValue={"OR"}
												options={[
													{ label: "AND", value: "AND" },
													{ label: "OR", value: "OR" },
												]}
											></Select>
											{"   "})
										</div>
									</Space>

									{/* {renderTag(dirParams, 'tags')} */}
									{TagParameters()}
								</Space>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{this.state.editIndex >= 0 ? (
									<div>
										<Button
											type="primary"
											key={"Cancel"}
											className={"button-general"}
											style={{ width: "inherit" }}
											onClick={() => {
												initial.tags = [];
												initial.roles = [];
												clearTimeout(timer);
												this.setState({
													dir: initial,
													editIndex: -1,
													inputVisible: {},
													dirdata: this.state.temp,
													editting: false,
													clean: true,
												});
											}}
										>
											Cancel
										</Button>
										<Button
											disabled={this.state.clean || checkDir()}
											type="primary"
											key={"Save"}
											className={"button-general"}
											style={{ width: "inherit" }}
											onClick={() => {
												saveDirStructure(this.state.editIndex);
												this.setState({ clean: true });
											}}
										>
											Save Directory
										</Button>
									</div>
								) : (
									<Button
										disabled={checkDir()}
										type="primary"
										key={"Add"}
										onClick={() => saveDirStructure(-1)}
										className={"button-general"}
										style={{ width: "inherit" }}
									>
										+ New directory
									</Button>
								)}
							</div>
						</div>
					) : (
						<div>
							<div>
								<div>
									<div>Directory Structure</div>
									<Input
										type="text"
										className={"input"}
										key={"directoryStructure"}
										value={dirParams.dir_struct}
										placeholder="Eg. Well/DON/DDR"
										onChange={(v: any) =>
											updateDirParams("dir_struct", v.target.value)
										}
									/>
								</div>
								<div>
									<div>Roles</div>
									<div>{renderTag(dirParams, "roles")}</div>
								</div>
								<div style={{ margin: "10px 0px" }}>
									<div>Tags</div>
									{/* {renderTag(dirParams, 'tags')} */}
									{TagParameters()}
								</div>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{this.state.editIndex >= 0 ? (
									<div>
										<Button
											type="primary"
											key={"Cancel"}
											style={{ marginRight: "5px" }}
											onClick={() => {
												initial.tags = [];
												initial.roles = [];
												clearTimeout(timer);
												this.setState({
													dir: initial,
													editIndex: -1,
													inputVisible: {},
													dirdata: this.state.temp,
													editting: false,
												});
											}}
										>
											Cancel
										</Button>
										<Button
											disabled={this.state.clean && this.state.disabled}
											type="primary"
											key={"Save"}
											onClick={() => saveDirStructure(this.state.editIndex)}
										>
											Save Directory
										</Button>
									</div>
								) : (
									<Button
										disabled={checkDir()}
										type="primary"
										key={"Add"}
										onClick={() => saveDirStructure(-1)}
										className={"button-general"}
									>
										+ New directory
									</Button>
								)}
							</div>
						</div>
					)}
				</>
			);
		};

		const FolderArrange = () => {
			return this.state.dirdata.map((element: any, index: any) => {
				return (
					<div className="folder" style={{ marginBottom: "5%" }}>
						<div
							className="folder-header"
							style={{
								display: "grid",
								gridTemplateColumns: "repeat(2, auto)",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<span style={{ fontWeight: "bolder" }}>{element.dir_struct}</span>
						</div>

						<div
							style={
								element.tags.length !== 0 || element.roles.length !== 0
									? { lineHeight: "2" }
									: { display: "none" }
							}
							className="folder-body"
						>
							<div>{renderTableTags(element.tags)}</div>
							<div>{renderTableTags(element.roles)}</div>
						</div>

						<div
							className="folder-footer"
							style={{ gridTemplateColumns: "repeat(2, auto)" }}
						>
							<Button
								className="folder-buttons"
								key="edit-folder"
								icon={<EditOutlined />}
								onClick={(e: any) => {
									e.stopPropagation();
									let temp = JSON.parse(JSON.stringify(this.state.dirdata));
									this.setState({ temp: temp, editting: true });
									editDirParams(element, index);
								}}
							/>
							<Popconfirm
								title="Are you sure?"
								icon={GetAntIcon("question2")}
								onConfirm={() => deleteDirParams(index)}
								okText="Yes"
								cancelText="No"
								zIndex={30003}
							>
								<Button
									style={{ width: "100%" }}
									className="folder-buttons"
									key="delete-folder"
									icon={<DeleteOutlined />}
									disabled={this.state.editting}
									// onConfirm={() => deleteDirParams(index)}
								/>
							</Popconfirm>
						</div>
					</div>
				);
			});
		};

		const renderTableTags = (tagArray: any) => {
			if (tagArray === undefined) {
				tagArray = [];
			}
			return (
				<>
					{tagArray.map((tag: any) => {
						if (tag && tag.value) {
							tag = tag.value;
						}
						let color = tag.length > 5 ? "geekblue" : "green";
						if (tag === "loser") {
							color = "volcano";
						}
						if (typeof tag === "number") {
							color = "yellow";
						}
						return (
							<>
								{tag.length > 0 ? (
									<Tag color={color} key={tag}>
										{tag.toUpperCase()}
									</Tag>
								) : null}
							</>
						);
					})}
				</>
			);
		};

		return (
			<>
				{!this.props.ismobile ? (
					<div style={{ display: "grid", gridTemplateColumns: "0.2fr 0.8fr" }}>
						<div
							style={{ minHeight: "80vh", maxHeight: "80vh", overflow: "auto" }}
						>
							{renderDirTree()}
						</div>
						<div style={{ margin: "20px 20px 0 20px" }}>
							{renderDirParameters()}
							<div style={{ margin: "20px 0" }} />
							<Table
								pagination={
									{
										position: ["bottomLeft"],
										pageSize: 5,
										onChange(pageNo: any) {
											changePage(pageNo);
										},
									} as TablePaginationConfig
								}
								dataSource={[...this.state.dirdata]}
							>
								{/* <Column title="Name" dataIndex={"name"}></Column> */}
								<Column
									title="Directory Structure"
									dataIndex={"dir_struct"}
								></Column>
								<Column
									title="File Tags"
									render={(_: any, row: any) => renderTableTags(row.tags)}
								></Column>
								<Column
									title="Security Roles"
									render={(_: any, row: any) => renderTableTags(row.roles)}
								></Column>
								<Column
									title="Action"
									render={(item, record, index) => {
										index = (this.state.page - 1) * 5 + index;
										return (
											<Space>
												<Typography.Link
													disabled={this.state.editting}
													onClick={(e) => {
														e.stopPropagation();
														let temp = JSON.parse(
															JSON.stringify(this.state.dirdata)
														);
														this.setState({ temp: temp, editting: true });
														editDirParams(record, index);
													}}
												>
													Edit
												</Typography.Link>
												<Popconfirm
													title="Are you sure?"
													icon={GetAntIcon("question2")}
													onConfirm={() => deleteDirParams(index)}
													okText="Yes"
													cancelText="No"
													zIndex={30003}
												>
													<Typography.Link disabled={this.state.editting}>
														Delete
													</Typography.Link>
												</Popconfirm>
											</Space>
										);
									}}
								></Column>
							</Table>
						</div>
					</div>
				) : (
					<div>
						<div>{renderDirTree()}</div>
						<div style={{ margin: "20px 20px" }}>
							{renderDirParameters()}
							<div style={{ margin: "20px 0" }} />
							<div style={{ paddingBottom: "5%" }}>{FolderArrange()}</div>
						</div>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(ModalDirectoryManager);
