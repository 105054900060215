import {
	Col,
	Divider,
	Flex,
	Form,
	Input,
	InputNumber,
	Modal,
	ModalProps,
	Radio,
	Row,
	Select,
	SelectProps,
	Space,
	Tabs,
	Typography,
} from "antd";
import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { addVisual, updateVisual } from "../../services/api-server/visuals";
import Emitter from "../../services/EventEmitter";
import { CustomDashboardContext, VisualContext } from "../../contexts/context";
import { TargetProps } from "../TargetsConfig";
import PlotInputs from "../PlotInputs";
import Visual from "../Visual";
import PreviewTable from "../PreviewTable";
import TargetsConfigTab, { MultiplierButton } from "../TargetsConfigTab";
import useParameters from "../../hooks/useParameters";
import { useComponent } from "../../contexts/ComponentContext";
import CustomEditor from "../CustomEditor";
import useMenu from "../../hooks/useMenu";
import { getParameters } from "../../services/api-server/parameters";
import useMeasures from "../../hooks/useMeasures";
import { useSelector } from "react-redux";

interface VisualModalProps extends ModalProps {}

const { Text } = Typography;

const VisualModal = ({ ...restProps }: VisualModalProps) => {
	const [form] = Form.useForm();
	const [formValues, setFormValues] = useState<any>({});
	const [currentMeasure, setCurrentMeasure] = useState<any>(null);
	const [params, setParams] = useState<Record<string, any>>({});

	// Watching measure to handle parameters
	const measureValue = Form.useWatch(["measure"], form);

	const { state: visualState, dispatch: visualDispatch } =
		useContext(VisualContext);
	const { customDashboardModules, getMenuByKey } = useMenu();

	// FROM CONTENT TAB
	const { measures, data_authorized, menuItem } = useComponent();
	const { parameters, paramValues } = useParameters(menuItem.key);
	const { getMeasureById, fetchMeasureData } = useMeasures();
	const { isOwner, state: customDashboardState } = useContext(
		CustomDashboardContext
	);

	const currentUser = useSelector((state: any) => state.user);

	// ! To prepare used parameters options
	useEffect(() => {
		const handleParams = async (measure: MeasureType) => {
			// * 1. Get parameters from custom dashboard where the measure was mastered from
			if (measure?.menuKey) {
				let masteredParams = await getParameters(measure?.menuKey);

				// * 2. Get parameters that was used in the measure
				let paramsUsed: string[] = [];
				if (measure.queryStatement) {
					const pattern = /@\w+/g;
					// Find all matches in the given text
					const matches = measure.queryStatement.match(pattern) || [];
					paramsUsed = matches.map((match: string) => match.slice(1));
				}

				let filteredParams = masteredParams.filter((param: any) =>
					paramsUsed.includes(param.name)
				);

				if (filteredParams) {
					const params: Record<string, any> = {};

					filteredParams.forEach((param) => {
						let values = param.fieldValues
							.filter((field) => field.include)
							.map((field) => field.value);

						params[param.name] = values;
					});
					setParams(params);
				}
			}
		};

		//Check for params of the mastered menu key
		//Check for params used in the measure query
		if (measureValue) {
			let foundMeasure = getMeasureById(measureValue);
			if (foundMeasure) {
				setCurrentMeasure(foundMeasure);
				handleParams(foundMeasure);
			}
		}
	}, [measureValue, getMeasureById]);

	const handleClose = useCallback(() => {
		visualDispatch({ type: "RESET_VISUAL_MODAL" });
		setCurrentMeasure(null);
		form.resetFields();
	}, []);

	const handleSaveVisual = () => {
		const values = form.getFieldsValue(true);
		const cleanedTargets = cleanTargets(formValues?.targets || {});
		const visual = {
			...values,
			targets: cleanedTargets,
		};

		if (visualState.new) {
			// Handling saving new visual
			addVisual(visual, menuItem.key)
				.then((response) => {
					Emitter.emit("alert", {
						type: "success",
						message: "Visual created successfully",
						description: "You have successfully created a visual",
						timeout: 5000,
					});
					console.log(response);
				})
				.catch((error) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Unsuccessful saving visual",
						description: "There was an error while saving visual",
						timeout: 5000,
					});
					console.error(error);
				})
				.finally(() => {
					visualDispatch({ type: "RESET_VISUAL_MODAL" });
					setCurrentMeasure(null);
					form.resetFields();
				});
		} else {
			// Handling saving existing visual
			updateVisual(visual, menuItem.key)
				.then((response) => {
					Emitter.emit("alert", {
						type: "success",
						message: "Your visual had been updated",
						description: "You have successfully updated a visual",
						timeout: 5000,
					});
				})
				.catch((error) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Unsuccessful saving visual",
						description: "There was an error while saving visual",
						timeout: 5000,
					});
					console.error(error);
				})
				.finally(() => {
					visualDispatch({ type: "RESET_VISUAL_MODAL" });
					setCurrentMeasure(null);
					form.resetFields();
				});
		}
	};

	const isSavable = useCallback(() => {
		const { title } = formValues;

		let savable = false;
		if (title && title !== "") savable = true;

		return savable;
	}, [formValues]);

	// MEMOS
	const getMeasureOptions = useMemo(() => {
		return measures.map((measure) => {
			return {
				label: measure.name,
				value: measure.id,
				menuKey: measure.menuKey,
			};
		}) as SelectProps["options"];
	}, [measures]);

	const queryMeasure = useCallback(
		(measure: MeasureType) => {
			visualDispatch({ type: "LOAD_VISUAL_START" });
			setVisualDataLoading(true);

			fetchMeasureData(
				measure,
				currentUser,
				menuItem,
				isOwner,
				customDashboardState?.sliceValues,
				formValues?.parameters
			)
				.then((data) => {
					if (data?.response) {
						setVisualData(data?.response);
						visualDispatch({ type: "DATA", payload: data?.response });
					} else {
						visualDispatch({ type: "DATA", payload: [] });
					}
				})
				.catch((error) => {
					console.log(error);
					visualDispatch({ type: "DATA", payload: [] });
				})
				.finally(() => {
					visualDispatch({ type: "LOADING", payload: false });
					setVisualDataLoading(false);
				});
		},
		[currentUser, menuItem, isOwner, fetchMeasureData, formValues?.parameters]
	);

	const selectedMeasure = getMeasureById(formValues?.measure);

	useEffect(() => {
		setFormValues({ ...visualState.visual, date_type: "dashboard" });
		form.setFieldsValue({ ...visualState.visual, date_type: "dashboard" });
	}, [visualState.visual]);

	// Keeping track of the measure to handle query db when measure changed
	const trackMeasureId = Form.useWatch("measure", form);

	useEffect(() => {
		const measure = getMeasureById(trackMeasureId);

		if (measure) {
			queryMeasure(measure);
			setCurrentMeasure(measure);
		}
	}, [trackMeasureId, getMeasureById, queryMeasure]);

	const [visualData, setVisualData] = useState<Record<string, any>>({});
	const [visualDataLoading, setVisualDataLoading] = useState(false);

	return (
		<Modal
			{...restProps}
			onCancel={handleClose}
			okText={"Save"}
			okButtonProps={{ disabled: !isSavable(), htmlType: "submit" }}
			width={"70%"}
			title={visualState.new ? "New Visual" : "Edit Visual"}
			maskClosable={false}
			closeIcon={false}
			destroyOnClose
			zIndex={30001}
			modalRender={(dom) => (
				// Main Form
				<Form
					form={form}
					layout="vertical"
					onFinish={() => {
						handleSaveVisual();
					}}
					onValuesChange={(changedValues, values) => {
						setFormValues((prevValues: any) => ({
							...prevValues,
							...values,
						}));
					}}
				>
					{dom}
				</Form>
			)}
		>
			{/* <Spin spinning={visualState.loading}> */}
			<Row gutter={24}>
				<Col flex={1}>
					<PlotInputs formValues={formValues} />

					<Form.Item name={"id"} hidden />
					<Form.Item label="Title" name={"title"}>
						<Input placeholder="Title" />
					</Form.Item>

					<Form.Item
						label={
							<Space>
								Subtitle
								<Text className="custom-optional-text">(optional)</Text>
							</Space>
						}
						name={"subtitle"}
					>
						<Input placeholder="Subtitle" />
					</Form.Item>
				</Col>
				<Divider type="vertical" style={{ height: "auto" }} />
				<Col span={12}>
					<Form.Item>
						<Visual
							loading={visualDataLoading}
							data={visualData}
							styles={{ body: { height: 300, width: "auto" } }}
							plotStyle={{ height: 300 }}
							visual={formValues}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Tabs
				items={[
					{
						key: "measure",
						label: "Measure",
						children: (
							<Row gutter={24}>
								<Col flex={1}>
									<Form.Item layout="vertical" label="Measure" name={"measure"}>
										<Select
											optionFilterProp="label"
											showSearch
											placeholder="Measure"
											options={getMeasureOptions}
											optionRender={(oriOption) => {
												let menu: any = customDashboardModules.find(
													(module: any) =>
														module.key === oriOption.data?.menuKey
												);

												return (
													<Space>
														{oriOption.label}
														{menu ? (
															<span style={{ opacity: "0.6" }}>
																({menu.title})
															</span>
														) : null}
													</Space>
												);
											}}
										/>
									</Form.Item>
									<Form.Item
										layout="vertical"
										label="Value"
										name={["data", "value"]}
									>
										<Select
											dropdownStyle={{ zIndex: 30003 }}
											options={visualState.fields.map((field: any) => ({
												key: field,
												label: field,
												value: field,
											}))}
											placeholder={"Value"}
										/>
									</Form.Item>
									<Form.Item
										layout="vertical"
										label="Measure Indicator Field"
										name={["data", "indicator_value"]}
									>
										<Select
											dropdownStyle={{ zIndex: 30003 }}
											options={visualState.fields.map((field: any) => ({
												key: field,
												label: field,
												value: field,
											}))}
											placeholder={"Indicator field"}
										/>
									</Form.Item>
								</Col>
								<Col flex={1}>
									{visualState?.data?.length ? (
										<Form.Item layout="vertical" label={"Data preview"}>
											<PreviewTable data={visualState?.data} />
										</Form.Item>
									) : null}
								</Col>
								{currentMeasure && (
									<CustomEditor
										readOnly={true}
										value={currentMeasure?.queryStatement || ""}
									/>
								)}
							</Row>
						),
					},
					{
						key: "targets",
						label: "Targets",
						children: (
							<TargetsConfigTab
								formValues={formValues}
								setFormValues={setFormValues}
							/>
						),
					},
					{
						disabled: !measureValue,
						key: "parameters",
						label: "Parameters",
						children: (
							<>
								<Text style={{ marginBottom: 16, display: "block" }}>
									Parameters
								</Text>
								<Form.Item label="Dates" name="date_type">
									<Radio.Group style={{ width: "100%" }}>
										<MultiplierButton value={"custom"} disabled>
											Select date range
										</MultiplierButton>
										<MultiplierButton value={"dashboard"}>
											Use dashboard dates
										</MultiplierButton>
									</Radio.Group>
								</Form.Item>
								{formValues?.date_type === "custom" ? (
									<Form.Item label="Date range">
										<Flex gap={12}>
											<Form.Item name={"date_mode"} noStyle>
												<Select
													style={{ width: 100 }}
													options={[
														{ label: "Current", value: "current" },
														{ label: "Previous", value: "previous" },
													]}
												/>
											</Form.Item>
											<Form.Item name={"date_occurrences"} noStyle>
												<InputNumber style={{ width: 48 }} min={1} />
											</Form.Item>
											<Form.Item name={"date_unit"} noStyle>
												<Select
													style={{ width: 100 }}
													options={[
														{ label: "Year", value: "year" },
														{ label: "Quarter", value: "quarter" },
														{ label: "Month", value: "month" },
														{ label: "Week", value: "week" },
														{ label: "Day", value: "day" },
													]}
												/>
											</Form.Item>
										</Flex>
									</Form.Item>
								) : null}
								{Object.entries(params)
									.map(([k, v]: any) => ({
										[k]: v,
										displayName:
											parameters?.find((p) => p.name === k)?.displayName || k,
										options: v?.map((o: any) => ({
											label: o,
											value: o,
										})),
										key: k,
									}))
									?.map((d) => (
										<Form.Item
											layout="vertical"
											key={d?.key}
											label={d?.displayName}
											name={["parameters", d?.key]}
										>
											<Select
												mode="multiple"
												options={d?.options}
												placeholder={d?.displayName}
											/>
										</Form.Item>
									))}
							</>
						),
					},
					{
						key: "drilldown",
						label: "Drilldown",
						children: (
							<Row>
								<Col flex={1}>
									<Form.Item
										layout="vertical"
										label={
											<Space>
												Drill Down Measure
												<Text className="custom-optional-text">(optional)</Text>
											</Space>
										}
										name={"drilldown"}
									>
										<Select
											// searchValue="label"
											optionFilterProp="label"
											showSearch
											placeholder="Drill down"
											options={getMeasureOptions}
											allowClear
											optionRender={(oriOption) => {
												let menu: any = customDashboardModules.find(
													(module: any) =>
														module.key === oriOption.data?.menuKey
												);

												return (
													<Space>
														{oriOption.label}
														{menu ? (
															<span style={{ opacity: "0.6" }}>
																({menu.title})
															</span>
														) : null}
													</Space>
												);
											}}
										/>
									</Form.Item>
								</Col>
								<Col flex={1}></Col>
							</Row>
						),
					},
				]}
			/>
			{/* </Spin> */}
		</Modal>
	);
};

const cleanTargets = (targets: TargetProps) => {
	const targetsEntries = Object.entries(targets).map(([k, v]: any) => {
		const targetEntries = Object.entries(v);
		const cleanedTarget = targetEntries?.map(([_k, _v]) => {
			// excluded type casting the _id to number
			if (_k === "_id") {
				return [_k, _v];
			}
			return [_k, Number(_v) ? Number(_v) : 0];
		});

		const cleanedTargetFromEntries = Object.fromEntries(cleanedTarget);

		return [k, cleanedTargetFromEntries];
	});

	const targetsFromEntries = Object.fromEntries(targetsEntries);

	return targetsFromEntries;
};
export default VisualModal;
