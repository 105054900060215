import React from "react";
import StaggeredItem from "./StaggeredItem";

interface StaggeredGridProps {
	items: React.ReactNode[];
}

const StaggeredGrid = ({ items }: StaggeredGridProps) => {
	return (
		<div className="staggered-grid" style={{ overflowY: "auto", flexGrow: 1 }}>
			{items.map((item, index) => (
				<StaggeredItem key={index}>{item}</StaggeredItem>
			))}
		</div>
	);
};

export default StaggeredGrid;
