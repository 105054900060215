import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
	Modal,
	Popconfirm,
	Space,
	Spin,
	Typography,
	TypographyProps,
} from "antd";
import { TextProps } from "antd/es/typography/Text";
import React, { useCallback, useState } from "react";

const { Text, Link } = Typography;

interface StatusHandlerProps extends TextProps {
	errorMessage: string;
	dirty?: boolean;
	loading?: boolean;
	successText?: string;
	successIcon?: React.ReactNode;
	errorIcon?: React.ReactNode;
}

const StatusHandler = ({
	errorMessage,
	dirty = false,
	loading = false,
	successText = "Success!",
	successIcon = (
		<CheckCircleOutlined
			style={{
				color: "#52C41A",
				fontSize: 17.5,
				display: "flex",
				alignItems: "center",
			}}
		/>
	),
	errorIcon = (
		<CloseCircleOutlined
			style={{
				color: "#E84749",
				fontSize: 17.5,
				display: "flex",
				alignItems: "center",
			}}
		/>
	),
	...restProps
}: StatusHandlerProps) => {
	const [open, setOpen] = useState(false);

	const handleMoreInfo = useCallback(() => {
		setOpen(true);
	}, []);

	const handleCloseMoreInfo = useCallback(() => {
		setOpen(false);
	}, []);

	if (loading) return <Spin size="small" />;

	if (!dirty) return null;

	if (errorMessage === "") {
		return (
			<Space align="center">
				{successIcon}
				<Text {...restProps}>{successText}</Text>
			</Space>
		);
	}

	return (
		<Space align="center">
			{errorIcon}
			There is an error running your query.
			<Popconfirm
				icon={
					<ExclamationCircleOutlined style={{ color: "rgb(232, 71, 73)" }} />
				}
				title={"Error while running query"}
				description={
					<Text>
						<pre>
							{errorMessage}
						</pre>
					</Text>
				}
				overlayStyle={{ zIndex: 30003 }}
				cancelButtonProps={{ style: { display: "none" } }}
			>
				<Link onClick={handleMoreInfo}>More info</Link>
			</Popconfirm>
		</Space>
	);
};

export default StatusHandler;
