// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------dev-sharedUiComponents-dist-components-Fonts__fontRegular {
    font-family: "acumin-pro-condensed";
    font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/components/Fonts.scss"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,eAAe;AACnB","sourcesContent":[":local(.fontRegular) {\r\n    font-family: \"acumin-pro-condensed\";\r\n    font-size: 14px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontRegular": `---------dev-sharedUiComponents-dist-components-Fonts__fontRegular`
};
export default ___CSS_LOADER_EXPORT___;
