import { api } from "../../contexts/AuthContext";

export const getEngineeringAsset = (code: any) => {
	return new Promise((resolve, reject) => {
		api
			.get(`/assets/models/engineering`, {
				params: { code: code },
				responseType: "blob",
			})
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				// console.error("error", e);
				reject(e);
			});
	});
};

export const getVesselModel = (modelName: any) => {
	return new Promise((resolve, reject) => {
		api
			.get(`/assets/models/${modelName}`, {
				responseType: "blob",
			})
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				// console.error("error", e);
				reject(e);
			});
	});
};
