const initialState = {
    allNotifications:[]
}

const initialState_RN = {
    releaseNotes: []
}

export const Notifications = (state: any = initialState, action: any) => {
    //console.log('allVessels',state,action)
    switch (action.type) {
        case 'SET_NOTIFICATIONS':
            return {
                ...state,
                allNotifications: action.payload
            }
        case 'ADD_NOTIFICATION':
            return {
                ...state,
                allNotifications:[...state.allNotifications,action.payload]
            }
        default:
            return state
    }
}

export const ReleaseNotes = (state: any = initialState_RN, action: any) => {
    switch (action.type) {
        case 'SET_RELEASENOTES':
            return action.payload
        case 'ADD_RELEASENOTES':
            return {
                ...state,
                releaseNotes: action.payload
            }
        default:
            return state
    }
}