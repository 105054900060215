export const Mode = (state: any = null, action: any) => {
	switch (action.type) {
		case "TOGGLE_MODE":
			return state === "testing" ? "production" : "testing";
		default:
			return state;
	}
};

export const TestRecipients = (state: any = null, action: any) => {
	switch (action.type) {
		case "SET_TEST_RECIPIENTS":
			return action.payload;
		default:
			return state;
	}
};
