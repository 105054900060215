import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import NoAccess from "./NoAccess";
import {
	getDocument,
	getDocumentList,
	solrSearchDocs,
	updateFolder,
} from "../services/api-server/documents";
import {
	Button,
	PaginationProps,
	Breadcrumb,
	Spin,
	Table,
	Input,
	Select,
	Space,
	Tag,
	Modal,
	TablePaginationConfig,
	Divider,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Emitter from "../services/EventEmitter";
import { Tenant } from "../App";
import { GetAntIcon, GetFileType } from "../utils/ant_icons";
import ModalDirectoryManager from "../components/modal/Modal_DirectoryManager";
import Portal_Logo from "../assets/img/Portal_logo_RGB.png";
import Portal_Icon_Logo from "../assets/img/Portal_hexagon_RGB.png";
import { HomeContext } from "./Home";
import { ComponentHook } from "../utils/components";

const { Column } = Table;
const { Search } = Input;

const DocumentTable = (props: any) => {
	const [acceptedroles, setacceptedroles]: any = useState(null);
	const [history, sethistory]: any = useState(null);
	const [folder, setFolder]: any = useState(null);
	const [folderIndex, setFolderIndex]: any = useState(null);
	const [clean, setClean]: any = useState(true);
	const [high, setHigh]: any = useState(false);
	const [breadcrumbs, setBreadcrumbs]: any = useState([]);
	const [tempFolder, setTempFolder]: any = useState(null);
	const [mode, setMode]: any = useState(
		document.body.classList.contains("dark-mode")
	);
	const [loading, setLoading]: any = useState(true);
	const [tip, setTip]: any = useState(null);

	const [treeData, setTreeData]: any = useState(null);
	const [files, setFiles]: any = useState(null);
	const [allBlobs, setAllBlobs]: any = useState(null);
	const [documents, setDocuments]: any = useState(null);
	const []: any = useState(null);
	const []: any = useState(null);

	const [searchLoading, setSearchLoading]: any = useState(false);
	const [searchValue, setSearchValue]: any = useState(null);
	const [searchedData, setSearchData]: any = useState(null);
	const [filterData, setFilterData]: any = useState(null);
	const [prevFiles, setPrevFiles]: any = useState(null);
	// const prevFiles: any = useRef(null);

	const [visible_searchModal, setVisible_searchModal]: any = useState(null);
	const [visible_editModal, setVisible_editModal]: any = useState(null);

	useEffect(() => {
		sethistory(null);
		setacceptedroles(null);
		checkRole();
	}, []);

	useEffect(() => {
		if (props.documents && !folder) {
			if (props.folder) {
				setFolder(props.folder[0]);
				setFolderIndex(props.folder[1]);
				getDocuments(props.folder[0]);
			} else {
				if (props.params?.mitem?.folder) {
					let foldername = props.params?.mitem?.folder;
					let doc = props.documents?.folders.find(
						(element: any) => element.name === foldername
					);
					setFolder(doc);

					setFolderIndex(1);
					getDocuments(doc);
				}
			}
		}
	}, [props.documents]);

	useEffect(() => {
		if (treeData) {
			let tree = treeData;
			let initDirectory = props.params?.mitem?.directory;
			let bread = breadcrumbs || [];
			if (initDirectory) {
				let directory_structure = initDirectory.split("/");
				directory_structure.every((element: String, index: any) => {
					let treeObject: any = tree.find(
						(treeElement: any) => treeElement.s_name === element
					);

					if (treeObject) {
						let breadObject: any = {
							name: treeObject.s_name,
							tree: treeObject.children,
						};
						bread.push(breadObject);
						tree = treeObject.children;
						if (index == directory_structure.length - 1) {
							setBreadcrumbs(bread);
							setFiles(treeObject.children);
							setAllBlobs(treeObject.children);
						}
						return true;
					} else {
						return false;
					}
				});
			}
		}
	}, [treeData]);

	const checkRole = () => {
		if (acceptedroles === null) {
			if (props.role.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
				setacceptedroles(true);
				setHigh(true);
			} else if (props.params?.roles?.length === 0 || !props.params?.roles) {
				setacceptedroles(true);
			} else {
				props.params.roles.map((element: any) => {
					if (props.role.includes(element)) {
						setacceptedroles(true);
					}
					return true;
				});
			}
		}
		return null;
	};

	const WithRouter = () => {
		let Params: any = useLocation();
		let pathname: string = Params.pathname;
		sethistory(pathname);
		checkRole();
		return null;
	};

	const searchDocs = (value: any) => {
		if (value.length > 0) {
			setSearchLoading(true);
			solrSearchDocs(value)
				.then((data: any) => {
					// setVisible_searchModal(true);
					setSearchData(data);
					setSearchLoading(false);
					setFilterData(null);
					filterFiles(data);
					// prevFiles.current = files;
				})
				.catch((error: any) => {
					setSearchLoading(false);
				});
		} else {
			setFilterData(null);
		}
	};

	const getDocuments = (folder: any) => {
		if (folder) {
			let container = folder?.containers;
			let tags = folder?.tags;
			let dir = folder?.dir;
			if (tags === undefined) {
				tags = [];
			}
			if (dir === undefined) {
				dir = [];
			}
			getDocumentList(container, tags, props.role, folder)
				.then((res: any) => {
					setLoading(false);
					if (res.data && res.data.length !== 0) {
						setTreeData(res.data.treedata);
						setFiles(res.data.treedata);
						setPrevFiles(res.data.treedata);
						setAllBlobs(res.data.allblobs);
					} else if (res.data.length === 0) {
						Emitter.emit("alert", {
							type: "error",
							message: "No Blobs Found",
							description: `The container is unable to find the relevant blobs`,
							timeout: 5000,
						});
					} else {
						Emitter.emit("alert", {
							type: "error",
							message: "An Error has Occured",
							description: `The container is unable to retrieve the blobs`,
							timeout: 5000,
						});
					}
				})
				.catch(() => {
					Emitter.emit("alert", {
						type: "error",
						message: "Error: Blob Retrieval Failure",
						description:
							"There is an error when retrieving the blob files, check the folder configurations to see if everything is configured correctly",
						timeout: 5000,
					});
					setLoading(true);
				});
		}
	};

	const saveDirectory = () => {
		if (tempFolder) {
			let currentfolder: any = { ...tempFolder };
			let docs: any = props.documents;
			docs.folders[folderIndex] = currentfolder;

			updateFolder(docs).then((res: any) => {
				setLoading(true);
				setDocuments(res.data);
				setFolder(currentfolder);
				setTempFolder(null);
				setVisible_editModal(false);
				setClean(true);
				setBreadcrumbs([]);
				Emitter.emit("newdocs", null);
				getDocuments(currentfolder);
			});
		}
	};

	const itemRender: PaginationProps["itemRender"] = (
		_: any,
		type: any,
		originalElement: any
	) => {
		if (type === "prev") {
			return <a>Previous</a>;
		}
		if (type === "next") {
			return <a>Next</a>;
		}
		return originalElement;
	};

	const renderModalDirectoryManager = () => {
		return (
			<Modal
				key="Edit_Directory"
				title={`Directory Manager ( ${folder ? folder.name : null} )`}
				open={visible_editModal}
				centered
				destroyOnClose={true}
				onCancel={() => {
					setVisible_editModal(false);
					setTempFolder(null);
					setClean(true);
				}}
				onOk={() => {
					saveDirectory();
				}}
				okText={"Save"}
				okButtonProps={{ disabled: clean }}
				width={"90%"}
				styles={{ body: { minHeight: "75vh", padding: 0 } }}
				zIndex={30000}
			>
				<ModalDirectoryManager
					folderData={folder}
					clean={clean}
					handleclean={() => setClean(false)}
					handledata={(data: any) => setTempFolder(data)}
				></ModalDirectoryManager>
			</Modal>
		);
	};

	const filterFiles = async (data: any) => {
		// allBlobs;
		const allblobs = [...allBlobs];
		const searchedblobs = [...data];

		const resultkey = allblobs
			.filter((element1: any) => {
				return searchedblobs.some((element2: any) => {
					return element2.id.split("/").pop() === element1.s_name;
				});
			})
			.sort((a: any, b: any) => {
				return (
					new Date(b.lastmodified).getTime() -
					new Date(a.lastmodified).getTime()
				);
			});
		setFilterData(resultkey);
		setFiles(resultkey);
		setSearchLoading(false);
	};

	const renderSearchModal = () => {
		return (
			<Modal
				key="ModalSearch"
				title={`Search Results: ${searchValue}`}
				centered
				open={visible_searchModal}
				destroyOnClose={true}
				onCancel={() => {
					setVisible_searchModal(false);
				}}
				width={props.ismobile ? "95%" : "90%"}
				style={props.ismobile ? { padding: "5% 0" } : {}}
				styles={{
					body: {
						margin: "20px 20px 0px",
						minHeight: "80vh",
						width: props.ismobile ? "100%" : "auto",
						top: "0 !important",
						padding: props.ismobile ? "16px" : "auto",
					},
				}}
				footer={null}
				zIndex={30000}
			>
				<Spin
					// style={{ color: "rgb(77, 77, 77)" }}
					size="large"
					tip={"Searching for files..."}
					indicator={<img src={Portal_Logo} />}
					className={"antd-spin-blink"}
					wrapperClassName={"loading-wrapper"}
					spinning={searchLoading}
				>
					{filterData ? (
						<div>
							<HomeContext.Consumer>
								{({ handleOpenDocument }: any) => (
									<Table
										pagination={{
											position: ["bottomLeft"],
											style: { float: "left" },
											// pageSizeOptions: ["10"],
											// pageSize: 10,
										}}
										style={{ textAlign: "center" }}
										rowClassName="document-table-row"
										onRow={(record, rowIndex) => {
											return {
												onClick: () => {
													setLoading(true);
													setTip("Retrieving File...");
													handleOpenDocument({
														open: true,
														pdfViewer: true, // Open the drawer immediately
														fileObject: null, // Keep it null or show some loading indication inside the drawer
														loading: true, // Maintain loading state
													});
													getDocument(
														record.name,
														record.container,
														record.type
													)
														.then((res: any) => {
															handleOpenDocument({
																open: true,
																pdfViewer: true,
																fileObject: {
																	record: record,
																	key: record.name,
																	children: res,
																	label: record.name,
																},
																loading: false,
															});

															setLoading(false);
														})
														.catch((error: any) => {
															setLoading(false);

															Emitter.emit("alert", {
																type: "error",
																message: "Failed to Retrive File",
																description:
																	"Failed to retrieve file, there may be an issue with the server",
																timeout: 5000,
															});
														});
												},
											};
										}}
										dataSource={filterData}
									>
										<Column
											align="center"
											title="Type"
											key="type"
											render={(a: any) => {
												const classname = GetFileType(a.s_name);
												return (
													<div className={`${classname} file-icons`}></div>
												);
											}}
										/>
										<Column
											align="left"
											title="Name"
											dataIndex="s_name"
											key="name"
											width="20%%"
										/>
										{!props.ismobile && (
											<Column
												width={"10%"}
												align="left"
												title="Container"
												dataIndex="container"
												key="container"
											/>
										)}

										<Column
											width={"10%"}
											align="center"
											title="Last Modified"
											key="lmd"
											render={(item: any, record: any) => {
												if (item.lastmodified)
													return (
														<>
															{new Date(item.lastmodified).toLocaleDateString(
																"en-GB"
															)}
														</>
													);
												else return null;
											}}
										/>
										{!props.ismobile && (
											<Column
												align="left"
												title="Tags"
												key="tags"
												render={(_: any, row: any) => {
													let tags: any = {};
													if (row.tags || row.metadata) {
														Object.assign(tags, row["tags"], row["metadata"]);
													} else {
														return null;
													}
													return (
														<>
															{Object.values(tags).map((tag: any) => {
																let color =
																	tag.length > 5 ? "geekblue" : "green";
																if (tag === "loser") {
																	color = "volcano";
																}
																if (typeof tag === "number") {
																	color = "yellow";
																}
																return (
																	<Tag color={color} key={tag}>
																		{tag.toUpperCase()}
																	</Tag>
																);
															})}
														</>
													);
												}}
											/>
										)}
									</Table>
								)}
							</HomeContext.Consumer>
						</div>
					) : (
						<>{searchedData ? filterFiles(searchedData) : null}</>
					)}
				</Spin>
			</Modal>
		);
	};

	const FolderArrange = () => {
		return files?.map((element: any) => {
			return (
				<div className="folder" style={{ marginBottom: "5%" }}>
					<div className="folder-header">
						<span className="folder-title">{element.s_name}</span>

						<HomeContext.Consumer>
							{({ handleOpenDocument }: any) => (
								<Button
									type="link"
									style={{
										cursor: "pointer",
										float: "right",
										color: "#1890ff",
										fontWeight: "300",
										fontSize: "14px",
									}}
									onClick={(e: any) => {
										if (element.type !== "folder" && element.type !== "back") {
											setLoading(true);
											handleOpenDocument({
												open: true,
												pdfViewer: true, // Open the drawer immediately
												fileObject: null, // Keep it null or show some loading indication inside the drawer
												loading: true, // Maintain loading state
											});
											getDocument(element.name, element.container, element.type)
												.then((res: any) => {
													handleOpenDocument({
														open: true,
														pdfViewer: true,
														fileObject: {
															record: element,
															key: element.s_name,
															children: res,
															label: element.s_name,
														},
														loading: false,
													});

													setLoading(false);
												})
												.catch((error: any) => {
													setLoading(false);
													Emitter.emit("alert", {
														type: "error",
														message: "Failed to Retrieve File",
														description:
															"Failed to retrieve file, there may be an issue with the server",
														timeout: 5000,
													});
												});
										} else if (element.type === "back") {
											let bread = breadcrumbs;
											let tree = [];
											bread.splice(bread.length - 1, 1);
											if (bread.length === 0) {
												tree = treeData;
											} else {
												tree = bread[bread.length - 1].tree;
											}
											setFiles(tree);
											// setAllBlobs(tree);
											setBreadcrumbs(bread);
										} else {
											let bread = breadcrumbs;
											let breadObject = {
												name: element.s_name,
												tree: element.children,
											};
											bread.push(breadObject);
											setFiles(element.children);
											// setAllBlobs(element.children);
											setBreadcrumbs(bread);
										}
									}}
								>
									{element.type === "back" ? "Back" : "Open"}
								</Button>
							)}
						</HomeContext.Consumer>
					</div>
					<div
						style={{
							fontSize: "14px",
							// lineHeight: "2"
						}}
						className="folder-body"
					>
						<div>
							{breadcrumbs.length === 0
								? folder.name
								: breadcrumbs[breadcrumbs.length - 1].name}
						</div>
						<div className="document-last-modified-text-mobile">
							{element.lastmodified
								? `Last modified: ${new Date(
										element.lastmodified
								  ).toLocaleDateString("en-GB")}`
								: null}{" "}
						</div>
						<div>
							{element.tags
								? Object.keys(element.tags).map((_element: any) => {
										let value: any = element.tags[_element].toUpperCase();
										let color: string = value.length > 5 ? "geekblue" : "green";

										return <Tag color={color}>{value}</Tag>;
								  })
								: null}
						</div>
					</div>
					{/* {high ? (
						!(element.type === "folder" || element.type === "back") ? (
							<div
								className="folder-footer"
								style={{ gridTemplateColumns: "repeat(2, auto)" }}
							>
								<Button
									className="folder-buttons"
									key="edit-folder"
									icon={<EditOutlined />}
									onClick={(e: any) => {
										e.stopPropagation();
										alert("Work in Progress");
									}}
								/>
								<Button
									className="folder-buttons"
									key="delete-folder"
									icon={<DeleteOutlined />}
									onClick={(e: any) => {
										e.stopPropagation();
										alert("Work in Progress");
									}}
								/>
							</div>
						) : null
					) : null} */}
				</div>
			);
		});
	};

	const renderTable = () => {
		const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			setSearchValue(e.target.value);
			if (e.target.value === "") handleClear();
		};

		const handleClear = () => {
			setFiles(prevFiles);
		};

		return (
			<div className="document-table">
				<div className="document-table-header">
					<span style={{ fontSize: "1.5em" }}>Files</span>
					<Select
						style={{ width: "200px", marginLeft: "auto" }}
						placeholder="Filter by folder..."
						disabled
					/>
					<Search
						className="document-search"
						style={{ width: "200px", marginLeft: "20px" }}
						placeholder="Search..."
						onSearch={(value: any) => searchDocs(value)}
						loading={searchLoading}
						value={searchValue}
						// allowClear
						onChange={(ev: any) => onSearchChange(ev)}
					/>
					{high ? (
						<>
							<Button
								className="button-general"
								style={{
									width: "auto",
									padding: "0 20px",
									marginLeft: "20px",
								}}
								onClick={() => {
									setVisible_editModal(true);
								}}
							>
								Directory Manager
							</Button>
							<Button
								style={{ width: "150px", marginLeft: "20px" }}
								className="button-general-disabled"
								disabled
							>
								New File
							</Button>
						</>
					) : null}
				</div>
				<HomeContext.Consumer>
					{({ handleOpenDocument }: any) => (
						<Table
							className="document-table-main"
							bordered={mode}
							expandable={{ childrenColumnName: "null" }}
							pagination={
								{
									position: ["bottomLeft"],
									style: { float: "left" },
									itemRender: itemRender,
									// defaultPageSize: 8,
									// pageSizeOptions: ["8"],
								} as TablePaginationConfig
							}
							style={{ textAlign: "center" }}
							rowClassName="document-table-row"
							onRow={(record, rowIndex) => {
								return {
									onClick: () => {
										let bread = breadcrumbs;

										if (record.type !== "folder" && record.type !== "back") {
											setLoading(true);
											handleOpenDocument({
												open: true,
												pdfViewer: true, // Open the drawer immediately
												fileObject: null, // Keep it null or show some loading indication inside the drawer
												loading: true, // Maintain loading state
											});
											getDocument(record.name, record.container, record.type)
												.then((res: any) => {
													handleOpenDocument({
														pdfViewer: true,
														fileObject: {
															record: record,
															key: record.name,
															children: res,
															label: record.name,
														},
														loading: false,
														open: true,
													});
													setLoading(false);
												})
												.catch((error: any) => {
													setLoading(false);
													Emitter.emit("alert", {
														type: "error",
														message: "Failed to Retrive File",
														description:
															"Failed to retrieve file, there may be an issue with the server",
														timeout: 5000,
													});
												});
										} else if (record.type === "back") {
											let tree = [];
											bread.splice(bread.length - 1, 1);
											if (bread.length === 0) {
												tree = treeData;
											} else {
												tree = bread[bread.length - 1].tree;
											}
											setFiles(tree);
											setPrevFiles(tree);
											// setAllBlobs(tree);
											setBreadcrumbs(bread);
										} else {
											let breadObject = {
												name: record.s_name,
												tree: record.children,
											};
											bread.push(breadObject);
											setFiles(record.children);
											setPrevFiles(record.children);
											// setAllBlobs(record.children);
											setBreadcrumbs(bread);
										}
									},
								};
							}}
							dataSource={files}
							// dataSource={files || prevFiles.current}
						>
							<Column
								width={"3%"}
								align="center"
								title="Type"
								key="type"
								render={(a, r, index) => {
									if (a.type === "folder" || a.type === "back") {
										return GetAntIcon(a.type);
									} else {
										let classname = GetFileType(a.s_name);
										return <div className={`${classname} file-icons`}></div>;
									}
								}}
							></Column>
							<Column
								align="left"
								title="Title"
								dataIndex="s_name"
								key="name"
							></Column>
							<Column
								width={"10%"}
								align="left"
								title="Container"
								dataIndex="container"
								key="container"
							></Column>
							<Column
								width={"10%"}
								align="center"
								title="Last Modified"
								key="lmd"
								render={(item: any, record: any) => {
									if (item.lastmodified)
										return (
											<>
												{new Date(item.lastmodified).toLocaleDateString(
													"en-GB"
												)}
											</>
										);
									else return null;
								}}
							></Column>
							<Column
								align="left"
								title="Tags"
								key="tags"
								render={(_: any, row: any) => {
									let tags: any = {};
									if (row.tags || row.metadata) {
										Object.assign(tags, row["tags"], row["metadata"]);
									} else {
										return null;
									}
									return (
										<>
											{Object.values(tags).map((tag: any) => {
												let color = tag.length > 5 ? "geekblue" : "green";
												if (tag === "loser") {
													color = "volcano";
												}
												if (typeof tag === "number") {
													color = "yellow";
												}
												return (
													<Tag color={color} key={tag}>
														{tag.toUpperCase()}
													</Tag>
												);
											})}
										</>
									);
								}}
							></Column>
							{high ? (
								<Column
									width={"7%"}
									align="left"
									title="Action"
									key="action"
									render={(item: any) => {
										if (item.type !== "folder" && item.type !== "back") {
											return (
												<Space className="table-actions">
													<span
														onClick={(e) => {
															e.stopPropagation();
															alert("Work in Progress");
														}}
													>
														Edit
													</span>
													<span
														onClick={(e) => {
															e.stopPropagation();
															alert("Work in Progress");
														}}
													>
														Delete
													</span>
												</Space>
											);
										}
									}}
								></Column>
							) : null}
						</Table>
					)}
				</HomeContext.Consumer>
			</div>
		);
	};

	const renderBreadCrumb = () => {
		return (
			<Breadcrumb style={{ marginTop: "10px" }} separator=">">
				{/* <Breadcrumb.Item>
							<Link to={`/${Tenant}`}>Home</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<Link to={`/${Tenant}/documents`}>Documents</Link>
						</Breadcrumb.Item> */}
				<Breadcrumb.Item>
					<span
						className="link"
						onClick={() => {
							setFiles(treeData);
							// setAllBlobs(treeData);
							setBreadcrumbs([]);
						}}
						style={{ cursor: "pointer" }}
					>
						{folder?.name}
					</span>
				</Breadcrumb.Item>
				{breadcrumbs
					? breadcrumbs.map((element: any, index: any) => {
							return (
								<Breadcrumb.Item>
									<span
										className="link"
										onClick={() => {
											if (index !== breadcrumbs.length - 1) {
												let bread = breadcrumbs;
												bread.splice(index + 1, bread.length);
												setFiles(element.tree);
												// setAllBlobs(element.tree);
												setBreadcrumbs(bread);
											}
										}}
										style={{ cursor: "pointer" }}
									>
										{element.name}
									</span>
								</Breadcrumb.Item>
							);
					  })
					: null}
			</Breadcrumb>
		);
	};

	const renderContent = () => {
		return (
			<>
				{!props.ismobile ? (
					//DESKTOP MODE
					<Spin
						// style={{ color: "rgb(77, 77, 77)" }}
						size="large"
						tip={"Retrieving File..."}
						indicator={<img src={mode ? Portal_Icon_Logo : Portal_Logo} />}
						className={"antd-spin-blink"}
						wrapperClassName={"loading-wrapper"}
						spinning={loading}
					>
						{mode ? (
							//Dark Mode
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									height: "100%",
								}}
							>
								<div className="document-content-header">
									{renderBreadCrumb()}
									<span className="document-content-header-title">
										{folder.name}
									</span>
								</div>
								<div
									style={{
										display: "flex",
										flex: "1",
										padding: "20px",
										overflow: "auto",
										maxHeight: "100%",
									}}
								>
									{renderTable()}
								</div>
							</div>
						) : (
							//Light mode
							<div className="document-container">
								{renderBreadCrumb()}
								<div
									style={{
										fontSize: "3em",
										fontWeight: "bolder",
										lineHeight: "2",
									}}
								>
									{folder.name}
								</div>
								{renderTable()}
							</div>
						)}
					</Spin>
				) : (
					//MOBILE MODE
					<Spin
						// style={{ color: "rgb(77, 77, 77)" }}
						size="large"
						tip={"Retrieving Files..."}
						indicator={<img alt="Portal logo" src={Portal_Icon_Logo} />}
						className={"antd-spin-loading-mobile antd-spin-blink "}
						wrapperClassName={"loading-wrapper"}
						spinning={loading}
					>
						<div
							className="document-container-mobile"
							style={{ padding: "5%" }}
						>
							<Breadcrumb separator=">">
								{/* <Breadcrumb.Item>
									<Link to={`/${Tenant}`}>Home</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>
									<Link to={`/${Tenant}/documents`}>Documents</Link>
								</Breadcrumb.Item> */}
								<Breadcrumb.Item>
									<span
										onClick={() => {
											setFiles(treeData);
											// setAllBlobs(treeData);
											setBreadcrumbs([]);
										}}
										style={{ cursor: "pointer" }}
									>
										{folder?.name}
									</span>
								</Breadcrumb.Item>
								{breadcrumbs
									? breadcrumbs.map((element: any, index: any) => {
											return (
												<Breadcrumb.Item>
													<span
														onClick={() => {
															if (index !== breadcrumbs.length - 1) {
																let bread = breadcrumbs;
																bread.splice(index + 1, bread.length);
																setFiles(element.tree);
																// setAllBlobs(element.tree);
																setBreadcrumbs(bread);
															}
														}}
														style={{ cursor: "pointer" }}
													>
														{element.name}
													</span>
												</Breadcrumb.Item>
											);
									  })
									: null}
							</Breadcrumb>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<div
									className="document-content-header-title-mobile"
									style={{
										fontSize: "3em",
										fontWeight: "bolder",
										lineHeight: "2",
									}}
								>
									{folder.name}
								</div>
								{high ? (
									<Button
										style={{ width: "150px", marginLeft: "20px" }}
										className="button-general-disabled"
										disabled
									>
										New File
									</Button>
								) : null}
							</div>

							{/* Filter Select */}
							<Select
								style={{ width: "100%", marginBottom: "3%" }}
								placeholder="Filter by folder..."
								disabled
							></Select>

							{/* search */}
							<Search
								className="document-search"
								style={{ width: "100%", marginBottom: "3%" }}
								placeholder="Search..."
								onSearch={(value: any) => searchDocs(value)}
								allowClear
								loading={searchLoading}
							/>

							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									marginBottom: "3%",
								}}
							>
								<div
									className="document-sub-title"
									style={{ fontSize: "1.5em" }}
								>
									Files
								</div>
								{/* {high ? (
									<>
										<Button
											className="button-general"
											style={{
												width: "auto",
												padding: "0 20px",
												marginLeft: "20px",
											}}
											onClick={() => {
												setVisible_editModal(true);
											}}
										>
											Directory Manager
										</Button>
									</>
								) : null} */}
							</div>

							<div style={{ paddingBottom: "5%" }}>{FolderArrange()}</div>
						</div>
					</Spin>
				)}
			</>
		);
	};

	return (
		<>
			{!history ? <WithRouter /> : null}
			{folder && (
				<>
					<ComponentHook menuProps={props.params.mitem} />
					{renderContent()}
					{renderSearchModal()}
					{renderModalDirectoryManager()}
				</>
			)}
			{/* {acceptedroles && folder ? (
				<>
					<ComponentHook menuProps={props.params.mitem} />
					{renderContent()}
					{renderSearchModal()}
					{renderModalDirectoryManager()}
				</>
			) : (
				<NoAccess />
			)} */}
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		role: state.role,
		documents: state.documents,
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(DocumentTable);
