import { group_uid } from "./_exports";
import { api } from "../../contexts/AuthContext";

export const SendUserSubmissionMessage = (Message: any) => {
	return new Promise((resolve: any, reject: any) => {
		if (Message) {
			Message.tenant = group_uid;
			api.post(`/sendusersubmission`, Message)
				.then((res: any) => {
					resolve(res);
				})
				.catch((error: any) => {
					reject(error);
				});
		}
	});
};

export const updateUser = (id: string, data: any) => {
	const endpoint = `/usermgt/users/${id}`;
	return new Promise((resolve, reject) => {
		api.put(endpoint, data)
			.then((response: any) => {
				resolve(response);
			})
			.catch((err: any) => {
				reject(err);
			});
	});
};

export const getUser = async (id: string): Promise<any> => {
	try {
		const endpoint = `/usermgt/users/${id}`;
		const response = await api.get(endpoint);
		// console.log(response);
		return response.data;
	} catch (error) {
		// console.log(error);
		return null;
	}
};

export const getAllUsers = () => {
	return new Promise((resolve, reject) => {
		const endpoint = `/usermgt/getAllUsers`;
		api.get(endpoint)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => reject(err));
	});
};
