export const handleSearch = (value: string) => {
	if (value.length > 0) {
		let sections: any = document.querySelectorAll("h2");

		if (sections.length > 0) {
			sections.forEach((section: any) => {
				section.style.transition = "all ease .5s";
				section.style.display = "inline";
				let title: string = section.innerText.toLowerCase().trim();
				let position: number = title.search(value);

				if (position !== -1) {
					section.classList.add("highlight");

					setTimeout(() => {
						section.classList.remove("highlight");
					}, 1500);

					section?.scrollIntoView({ behavior: "smooth", block: "start" });
				}
			});
		}
	}
};
