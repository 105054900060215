import { useEffect } from "react";
import { saveToLS } from "../utils/utils";

const useLayoutChange = (layout: any[], editMode: boolean, dirty: boolean) => {
	useEffect(() => {
		if (editMode && dirty) {
			saveToLS("layout", layout);
		}
	}, [editMode, dirty, layout]);

	return null;
};

export default useLayoutChange;
