import { api } from "../../contexts/AuthContext";

export const getOperatorVessels = (operatorname: string) => {
	return new Promise((resolve, reject) => {
		api.get(`/vesselcontract/${operatorname}`)
			.then((_data: any) => {
				resolve(_data?.data);
			})
			.catch((e: any) => {
				// console.error("error", e);
				reject(e);
			});
	});
};

export const getOperatorContractedVessels = (operatorname: string) => {
	return new Promise((resolve, reject) => {
		const dt = new Date().toISOString().split("T")[0];
		// console.log(dt)
		// console.log(operatorname)
		api.get(`/vesselcontract/date/${operatorname}/${dt}`)
			.then((_data: any) => {
				// console.log("contractedrigs", _data);
				resolve(_data?.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const saveContract = (contractData: any) => {
	return new Promise((resolve, reject) => {
		api.post(`/vesselcontract`, contractData)
			.then((_data: any) => {
				// console.log('saved contract', _data)
				resolve(_data);
			})
			.catch((e: any) => {
				// console.error("error", e);
				reject(e);
			});
	});
};
export const updateContract = (id: string, contractData: any) => {
	return new Promise((resolve, reject) => {
		api.put(`/vesselcontract/${id}`, contractData)
			.then((_data: any) => {
				// console.log('updated contract', _data)
				resolve(_data);
			})
			.catch((e: any) => {
				// console.error("error", e);
				reject(e);
			});
	});
};

export const deleteContract = (contractid: string) => {
	return new Promise((resolve, reject) => {
		api.delete(`/vesselcontract/${contractid}`)
			.then((_data: any) => {
				// console.log('deleted contract', _data)
				resolve(_data);
			})
			.catch((e: any) => {
				// console.error("error", e);
				reject(e);
			});
	});
};

export const getVessel = (vesselName: String) => {
	return new Promise((resolve, reject) => {
		api.get(`/vesselmgt`, {
			params: { name: vesselName.toLowerCase() },
		})
			.then((_data: any) => {
				resolve(_data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getVesselCode = (vesselName: String) => {
	return new Promise((resolve, reject) => {
		api.get(`/vesselmgt/vesselcode/${vesselName}`, {})
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const saveVessel = (vesselData: any) => {
	return new Promise((resolve, reject) => {
		api.post(`/vesselmgt`, vesselData)
			.then((_data: any) => {
				// console.log('saved', _data)
				resolve(_data);
			})
			.catch((e: any) => {
				// console.error("unable to save", vesselData);
				reject(e);
			});
	});
};

export const updateVessel = (vesselData: any) => {
	return new Promise((resolve, reject) => {
		const id = vesselData._id;
		api.put(`/vesselmgt/${id}`, vesselData)
			.then((_data: any) => {
				// console.log('updated', _data)
				resolve(_data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const deleteVessel = (vesselId: any) => {
	return new Promise((resolve, reject) => {
		api.delete(`/vesselmgt/${vesselId}`)
			.then((_data: any) => {
				resolve(_data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
