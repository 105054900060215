import { useCallback, useEffect, useState } from "react";
import Emitter from "../services/EventEmitter";
import { getMeasures } from "../services/api-server/measures";
import { getParameters } from "../services/api-server/parameters";
import { findMeasureById, magicFunction } from "../utils/utils";
import { replaceWithParams } from "../utils/queryBuilder";
import { duckQuery } from "../services/api-server/deltashare";
import useMenu from "./useMenu";

const useMeasures = () => {
	const [measures, setMeasures] = useState<Array<any>>([]);
	const [loading, setLoading] = useState(false);
	const { getMenuByKey } = useMenu();

	const updateMeasures = () => {
		setLoading(true);

		getMeasures()
			.then((data: any) => {
				setMeasures(data || []);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchMeasureData = useCallback(
		async (
			measure: MeasureType,
			currentUser: Record<string, any>,
			currentMenuItem: Record<string, any>,
			isOwner: boolean,
			slicerParams?: Record<string, any>,
			visualParams?: Record<string, any>
		): Promise<any> => {
			const { start_date, end_date } = slicerParams!;

			if (measure) {
				let parameters = await getParameters(measure.menuKey || "");
				const masteredMenuItem = getMenuByKey(measure.menuKey || "");

				if (
					// * Checking for superadmin and current menu item's owner
					isOwner ||
					masteredMenuItem?.viewers?.includes(currentUser.oid) ||
					masteredMenuItem?.owners?.includes(currentUser.oid)
				) {
					const masteredParams: Record<string, any> = {};

					parameters.forEach((param) => {
						let values = param.fieldValues
							.filter((field) => field.include)
							.map((field) => field.value);

						masteredParams[param.name] = values;
					});

					const obey =
						currentMenuItem?.key === masteredMenuItem?.key
							? magicFunction(
									{ ...masteredParams, ...slicerParams },
									visualParams
							  )
							: magicFunction(
									{ ...masteredParams, start_date, end_date },
									visualParams
							  );

					try {
						const query = replaceWithParams(measure.queryStatement, {
							...obey,
						});

						const response: any = await duckQuery(
							query,
							masteredMenuItem?.data_authorized || [],
							false
						);
						return response;
					} catch (error) {
						console.error(
							`Error loading data for measure ${measure.id}`,
							error
						);
						return error;
					}
				} else {
					return "Unauthorized";
				}
			} else {
				return null;
			}
		},
		[getMenuByKey]
	);

	const getMeasureById = useCallback(
		(id: string) => findMeasureById(measures, id),
		[measures]
	);

	useEffect(() => {
		// updates the measures during the first render
		updateMeasures();

		// attaching a listenerto update visuals
		Emitter.on("MEASURES_UPDATED", updateMeasures);

		// clean up
		return () => {
			Emitter.off("MEASURES_UPDATED", updateMeasures);
		};
	}, []);

	return { measures, loading, fetchMeasureData, getMeasureById };
};

export default useMeasures;
