import { Form, Select, Typography } from "antd"
import { useEffect, useState } from "react"
import { getAssets } from "../../services/api-server/as-teg"
import { isArray } from "lodash"
import { getRigObject } from "../../services/RigInfo"

const { Text } = Typography

const ScanMaxInputs = () => {
    const [assetsOptions, setAssetsOptions] = useState<any[]>([])

    useEffect(() => {
        const fetchAssets = async () => {
            try {
                const response = await getAssets()
                if (isArray(response) && response.length !== 0) {
                    const filteredAssets = response?.filter((asset: any) => {
                        const assetNameLower = asset?.name?.toLowerCase()
                        const rigInfo = getRigObject(assetNameLower);
                        return rigInfo?.on_hire
                    }).map((asset: any) => ({ label: asset?.name, value: asset?.name }))


                    setAssetsOptions(filteredAssets)
                }

            } catch (error) {

            }
        }

        fetchAssets()
    }, [])

    return (
        <>
            <Form.Item>
                <Text style={{ fontSize: 20, marginBottom: 8 }}>
                    ScanMAX Settings
                </Text>
            </Form.Item>
            <Form.Item
                label="Asset"
                name={"asset"}
                rules={[{ required: true, message: "Please select an asset" }]}
            >
                <Select
                    className="input"
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    options={assetsOptions}
                />
            </Form.Item>
        </>
    )
}

export default ScanMaxInputs