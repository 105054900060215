import { Col, Row, Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea'

const { Paragraph } = Typography

type SettingsOverviewProps = {
    title?: string,
    description?: string,
    readonly?: boolean
    onChange?: (changedValue: any) => void
}

const OverviewTab = ({ title = '', readonly, description, onChange = () => { } }: SettingsOverviewProps) => {
    return (
        <Row style={{ padding: '0 16px' }} gutter={[0, 12]}>
            <Col span={24}>
                <div>
                    Title
                </div>
                <div>
                    {title}
                </div>
            </Col>
            <Col xs={24} sm={6}>
                <div style={{ marginBottom: 4 }}>Description</div>
                {!readonly ? (
                    <TextArea
                        value={description}
                        onChange={(e) => onChange({ description: e.target.value })}
                        placeholder="This is a description of the dashboard..."
                    />
                ) : (
                    <Paragraph>{description}</Paragraph>
                )}

            </Col>
        </Row>
    )
}

export default OverviewTab