import React from "react";
import CustomDashboardSVG from "../assets/img/Custom Dashboard.svg";
import DocumentSVG from "../assets/img/Documents.svg";
import MapSVG from "../assets/img/Map.svg";
import PowerBISVG from "../assets/img/Power BI.svg";
import RealTimeSVG from "../assets/img/Real-time.svg";
import DigitalTwinSVG from "../assets/img/DigitalTwin.svg";
import {
	DeploymentUnitOutlined as SensorMappingIcon,
	FolderOpenOutlined as DocumentIcon,
	ScanOutlined as ScanMAXIcon,
	FieldTimeOutlined as RealTimeIcon,
	LineChartOutlined as PowerBIIcon,
	GlobalOutlined as MapIcon,
	DashboardOutlined as CustomDashboardIcon,
} from "@ant-design/icons";
// import { ReactComponent as ScanMAXSVG } from "../assets/img/ScanMAX.svg";

interface GetSVGIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	componentName: string;
	disabled?: boolean;
}

export const componentIcons: any = {
	"Custom Dashboard": CustomDashboardIcon,
	Documents: DocumentIcon,
	"Digital Twin": DigitalTwinSVG,
	Map: MapIcon,
	PowerBI: PowerBIIcon,
	"Real-time Analytics": RealTimeIcon,
	ScanMAX: ScanMAXIcon,
	"Sensor Mapping": SensorMappingIcon,
};

export const GetComponentIcon = ({
	componentName,
	disabled = false,
	width,
	...restprops
}: GetSVGIconProps) => {
	const IconComponent = componentIcons[componentName];

	if (!IconComponent) return null;

	if (typeof IconComponent === "object")
		return (
			<IconComponent
				style={
					disabled ? { filter: "contrast(0)", opacity: 0.5, width } : { width }
				}
			/>
		);

	return (
		<img
			{...restprops}
			// className="menu-img"
			alt={componentName}
			src={componentIcons[componentName]}
			style={
				disabled ? { filter: "contrast(0)", opacity: 0.5, width } : { width }
			}
		/>
	);
};
