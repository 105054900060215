import { api } from "../../contexts/AuthContext";
import store from "../../state/store";
import { Tenant } from "./_exports";
import jwtDecode from "jwt-decode";

const APP_ID = process.env.REACT_APP_CLIENT_ID as string;

export const loadUsers = () => {
	api.get(`/clients`)
		.then((_data: any) => {
			if (_data?.data && _data.data.length) {
				store.dispatch({ type: "SET_ALL_USERS", payload: _data.data });
			}
		})
		.catch((e: any) => {
			// console.error(e);
		});
};

export const getAppData = () => {
	return new Promise((resolve, reject) => {
		api.get(`/getappdata`)
			.then((res: any) => {
				resolve(res);
			})
			.catch((error: any) => {
				reject(error);
			});
	});
};

export const getAppRoles = (): Promise<any[]> => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getAppRoles`)
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getAppRoles2 = () => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getAppRoles2`)
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getAppUsers = () => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getAppUsers`)
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getAppUser = (id: string): Promise<any> => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getAppUser`, {
			params: { id },
		})
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getApplicationGroups2 = () => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getApplicationGroups2`)
			.then((repsonse) => {
				if (Array.isArray(repsonse?.data)) {
					resolve(repsonse?.data);
				} else {
					resolve([]);
				}
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getApplicationUsers2 = () => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getApplicationUsers2`)
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getApplication = () => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getApplication`, {
			params: { appId: APP_ID },
		})
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getUserAppRoleAssignments = (id: string) => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getUserAppRoleAssignments`, {
			params: { id },
		})
			.then((res: any) => {
				if (Array.isArray(res?.data)) {
					resolve(res?.data);
				} else {
					resolve([]);
				}
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const getGroupAppRoleAssignments = (id: string) => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getGroupAppRoleAssignments`, {
			params: { id },
		})
			.then((res: any) => {
				if (Array.isArray(res?.data)) {
					resolve(res?.data);
				} else {
					resolve([]);
				}
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getAppGroup = (id: string) => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getAppGroup`, {
			params: { id },
		})
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getAppGroupMembers = (id: string) => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getAppGroupMembers`, {
			params: { id },
		})
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getMemberOf = (id: string) => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getMemberOf`, {
			params: { id },
		})
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getGeneralUsers = (): Promise<any[]> => {
	return new Promise((resolve, reject) => {
		api.get(`/graphql/getAllGeneralUsers`)
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
