import { api } from "../../contexts/AuthContext";
import { Tenant } from "./_exports";
type ACTION_TYPE =
	| "OWNERSHIP_GRANTED"
	| "OWNERSHIP_REVOKED"
	| "VIEWERSHIP_GRANTED"
	| "VIEWERSHIP_REVOKED";

export type Action = {
	menuKey: string;
	actionType: ACTION_TYPE;
	approvedDate: string;
	user_id: string;
	approvedBy: string;
};

export const registerAction = (action: Action, menuKey: string) => {
	return new Promise((resolve, reject) => {
		api
			.post(`/actions/registerAction`, { action, tenantid: Tenant, menuKey })
			.then((response) => {
				resolve(response);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};

export const updateAction = (action: any) => {
	return new Promise((resolve, reject) => {
		api
			.put(`/actions/updateAction`, action)
			.then((response) => {
				resolve(response);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};

export const getActions = (menuKey: string): Promise<any[]> => {
	return new Promise((resolve, reject) => {
		api
			.get(`/actions/getActions`, {
				params: { tenantid: Tenant },
			})
			.then((response) => {
				if (Array.isArray(response?.data?.actions)) {
					const filtered = response.data.actions?.filter(
						(action: any) => action.menuKey === menuKey
					);
					resolve(filtered);
				} else {
					resolve([]);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};
