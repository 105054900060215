import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
	Button,
	Drawer,
	Dropdown,
	Layout,
	Popconfirm,
	Popover,
	Progress,
	Space,
	Spin,
	Tabs,
} from "antd";
import { capitalize } from "../utils/dataTools";
import {
	DRAWER_KEYS,
	initialState,
	reducer,
} from "../reducers/customDashboardReducer";
import { CheckCircleOutlined, DownOutlined } from "@ant-design/icons";
import MeasureTab from "../components/MeasureTab";
import VisualTab from "../components/VisualTab";
import { Responsive, WidthProvider } from "react-grid-layout";
import { CustomDashboardContext } from "../contexts/context";
import { saveMenuItem } from "../services/api-server/menu";
import Emitter from "../services/EventEmitter";
import useCustomDashboard from "../hooks/useCustomDashboard";
import SlicerInputs from "../components/SlicerInputs";
import { initParameters } from "../utils/queryBuilder";
import VisualGroupsTab from "../components/VisualGroupsTab";
import { CustomLayout } from "../types/CustomLayout";
import DashboardItem from "../components/DashboardItem";
import { v4 as uuid } from "uuid";
import { getVisuals, updateVisual } from "../services/api-server/visuals";
import { updateVisualGroup } from "../services/api-server/visualsGroups";
import { socket } from "../utils/socket";
import { ComponentHook } from "../utils/components";
import { useMainContext } from "../contexts/MainContext";
import DrilldownModal from "../components/modal/DrilldownModal";
import { loadDashboardData, removeFromLS } from "../utils/utils";
import useLayoutChange from "../hooks/useLayoutChange";
import { useComponent } from "../contexts/ComponentContext";
import {
	ACTIONTYPES,
	INITIALSTATE,
	reducer as measureReducer,
} from "../reducers/measureReducer";
import MeasureModal from "../components/modal/MeasureModal";
import "../assets/css/custom_dashboard.css";
import {
	createCustomDashboard,
	getCustomDashboard,
	updateCustomDashboard,
} from "../services/api-server/custom_dashboards";
import { useSelector } from "react-redux";
import useMeasures from "../hooks/useMeasures";
const { Header } = Layout;

const ResponsiveGridLayout = WidthProvider(Responsive);
const CustomDashboard = (props: any) => {
	const ismobile = useSelector((state: any) => state.ismobile);
	const [outerRendered, setOuterRendered] = useState(false);

	const { isOwner, isViewer, isAdmin } = props;
	const { mitem: menuitem, roles } = props.params;
	const { key = "", layoutid = null, component = "" } = menuitem;
	const [state, dispatch] = useReducer(reducer, initialState);
	const [measureState, measureDispatch] = useReducer(
		measureReducer,
		INITIALSTATE
	);

	// handling saving to local storage when layout changes
	useLayoutChange(state.layout, state.editMode, state.dirty);

	const {
		loading,
		progress,
		visualGroups,
		paramValues,
		fetchMeasureData,
		getMenuByKey,
	} = useCustomDashboard({
		menuitem,
		metadata: state.metadata,
	});

	const { measures, visuals, menuItem } = useComponent();

	const { metadatasLoading: metadataLoading, metadatas: tables } =
		useMainContext();

	const { getMeasureById } = useMeasures();

	// FUNCTIONS
	const closeMeasureModal = useCallback(() => {
		dispatch({ type: "MEASUREMODAL", payload: false });
	}, []);

	const handleButtonClick = useCallback((drawer: string) => {
		return () => {
			dispatch({ type: "DRAWER", payload: drawer });
		};
	}, []);

	const handleEditDashboard = useCallback(() => {
		dispatch({ type: "EDIT_DASHBOARD", payload: !state.editMode });
	}, [state.editMode]);

	const handleDrawerChange = useCallback((activeKey: string) => {
		dispatch({ type: "DRAWER", payload: activeKey });
	}, []);

	const onDrop = (
		layout: ReactGridLayout.Layout[],
		item: ReactGridLayout.Layout,
		e: any
	) => {
		// check if the dropped item is a visual
		const visualId = e.dataTransfer.getData("visual_id");

		// TODO: handle updating visual's layoutIds
		// need to generate the layout id here and pass to ondrop
		const newLayoutId = uuid();
		const visual = state.visuals.find(
			(_visual: any) => _visual.id === visualId
		);
		if (visual) {
			const updatedVisual = {
				...visual,
				layoutIds: [...visual?.layoutIds, newLayoutId],
			};

			dispatch({ type: "UPDATE_VISUAL", payload: updatedVisual });
		}

		// TODO: handle dropping only visuals
		if (state.visuals?.find((_visual: any) => _visual.id === visualId)) {
			dispatch({
				type: "ON_DROP",
				payload: { layout, item, visualId, newLayoutId },
			});
		}
	};

	const handleSave = async () => {
		// filter "Add visual" tile before saving layout
		const filteredLayout = state.layout.filter((el: any) => !el?.add);

		if (typeof props.onSave === "function") {
			// handle saving logic from non-custom dashboard components
			try {
				let metadata_response = await props.onSave(
					filteredLayout,
					state.metadata,
					state
				);

				Emitter.emit("alert", {
					type: "success",
					message: "Dashboard saved successfully",
					description: "You have successfully saved this dashboard",
					timeout: 5000,
				});

				// Update visuals that has changes
				for (const visual of state.visuals) {
					if (state.updatedVisualIds.includes(visual.id))
						await updateVisual(visual, key);
				}

				// Update visual groups that has changes
				for (const group of state.groups) {
					if (state.updatedVisualGroupIds.includes(group.id))
						await updateVisualGroup(group);
				}

				dispatch({
					type: "INIT_DASHBOARD",
					payload: {
						initialLayout: filteredLayout,
						initialLayouts: {
							...state.layouts,
							[state.breakpoint]: filteredLayout,
						},
						layoutid: metadata_response.layoutid,
						metadata: metadata_response,
					},
				});

				dispatch({ type: "DASHBOARD_SAVE_END", payload: false });
			} catch {}
		} else {
			//Handle saving for custom dashboard menu item
			const { key = "", layoutid = null } = props?.params?.mitem;
			try {
				if (layoutid) {
					const response = await updateCustomDashboard(layoutid, {
						layout: filteredLayout,
						layouts: {
							...state.layouts,
							[state.breakpoint]: filteredLayout,
						},
					});
				} else {
					const response: any = await createCustomDashboard({
						layout: filteredLayout,
						layouts: {
							...state.layouts,
							[state.breakpoint]: filteredLayout,
						},
					});

					const updatedMenu = {
						...props?.params?.mitem,
						layoutid: response._id || null,
					};

					const response_menu = await saveMenuItem(updatedMenu);
				}
				for (const visual of state.visuals) {
					if (state.updatedVisualIds.includes(visual.id))
						await updateVisual(visual, key);
				}

				for (const group of state.groups) {
					if (state.updatedVisualGroupIds.includes(group.id))
						await updateVisualGroup(group);
				}

				Emitter.emit("alert", {
					type: "success",
					message: "Dashboard saved successfully",
					description: "You have successfully saved this dashboard",
					timeout: 5000,
				});

				socket.emit("UPDATE_MENU", key);

				dispatch({ type: "DASHBOARD_SAVE_END", payload: false });
			} catch (error) {
				console.error(error);
			}
		}
	};

	const handleDiscard = useCallback(
		() => {
			dispatch({
				type: "DISCARD_CHANGES",
				payload: {
					prevLayout: state.initialLayout || [],
					prevLayouts: state.initialLayouts || {},
					preVisuals: visuals,
					prevVisualGroups: visualGroups,
				},
			});
		},
		//  [dashboard_layout, dashboard_layouts, visuals, visualGroups]
		[state.initialLayout, state.initialLayouts, visuals, visualGroups]
	);

	const handleReturn = useCallback(() => {
		dispatch({ type: "EDIT_MODE", payload: false });
	}, []);

	const onLayoutChange = (
		currentLayout: ReactGridLayout.Layout[],
		allLayouts: ReactGridLayout.Layouts
	) => {
		dispatch({
			type: "LAYOUT_CHANGE",
			payload: { layout: currentLayout, allLayouts },
		});
	};

	const onBreakpointChange = useCallback((newBreakpoint: string) => {
		dispatch({ type: "ON_BREAKPOINT_CHANGE", payload: newBreakpoint });
	}, []);

	const sliceData = () => {
		dispatch({ type: "SLICE_DATA" });
	};

	/**
	 *
	 * @param item layout item
	 * @returns visual component
	 *
	 * finds the visual using with visual_id from the list of visuals
	 */
	const generateElement = (item: CustomLayout) => {
		return (
			<div
				key={item.i}
				style={{ display: "flex", height: "100%" }}
				className={`main-layout-${item.i}}`}
			>
				{outerRendered ? (
					<DashboardItem
						loading={visualDataLoading}
						data={visualData}
						item={item}
						onLayoutChange={() => {}}
					/>
				) : null}
			</div>
		);
	};

	const renderSaveEdit = () => {
		if (!isOwner) return null;

		if (!state.editMode)
			return (
				<Space>
					{/* Only component owners can see this setting button */}
					<Popover
						placement="left"
						overlayClassName="edit-mode-disabled-popover"
						content={
							state.breakpoint !== "lg"
								? "Dashboard edits can only be performed from a desktop application. Please resize your window."
								: ""
						}
					>
						<Button
							// disabled={state.breakpoint !== "lg"}
							className="edit-button"
							onClick={handleEditDashboard}
						>
							Edit dashboard
						</Button>
					</Popover>
				</Space>
			);

		return (
			<Space>
				<Button
					loading={state.loading}
					disabled={!state.dirty}
					onClick={handleSave}
					type="primary"
					icon={<CheckCircleOutlined />}
				>
					Save
				</Button>
				{state.dirty ? (
					<Popconfirm
						title="Unsave changes"
						description="You're about to discard your changes. Are you sure you want to continue?"
						onConfirm={handleDiscard}
					>
						<Button>Cancel</Button>
					</Popconfirm>
				) : (
					<Button onClick={handleReturn}>Cancel</Button>
				)}
			</Space>
		);
	};

	// MEMO
	const tabItems = useMemo(() => {
		// render the content depending on the drawer key selected
		const getTabContent = (key: string) => {
			switch (DRAWER_KEYS[key]) {
				case DRAWER_KEYS.DASHBOARD:
					return DRAWER_KEYS.DASHBOARD;
				case DRAWER_KEYS.VISUALS:
					return <VisualTab />;
				case DRAWER_KEYS.MEASURES:
					return <MeasureTab menuitem={menuitem} />;
				case DRAWER_KEYS.VISUAL_GROUPS:
					return <VisualGroupsTab />;
				default:
					break;
			}
		};

		const items: Array<any> = [];
		for (const key in DRAWER_KEYS) {
			items.push({
				key: DRAWER_KEYS[key],
				label: capitalize(DRAWER_KEYS[key]),
				children: getTabContent(key),
			});
		}
		return items;
	}, [DRAWER_KEYS, state.drawerKey]);

	const [visualData, setVisualData] = useState<Record<string, any>>({});
	const [visualDataLoading, setVisualDataLoading] = useState(false);
	const [drilldownData, setDrilldownData] = useState<
		Array<Record<string, any>>
	>([]);
	const [drilldownDataLoading, setDrilldownDataLoading] = useState(false);

	/**
	 * Things that need to be loaded before rendering dashboard
	 * * menu from useMenu
	 * * visuals from useVisuals
	 * * measures from useMeasures
	 * * paramValues from useParameters
	 */

	//first load
	useEffect(() => {
		const fetchData = async () => {
			try {
				const visuals = await getVisuals(menuItem.key);

				//Custom Dashboard component
				if (component == "Custom Dashboard") {
					let dashboard: any = {};

					if (layoutid) {
						dashboard = await getCustomDashboard({
							_id: layoutid,
						});
					}

					dispatch({
						type: "INIT_DASHBOARD",
						payload: {
							initialLayout: dashboard?.layout || [],
							initialLayouts: dashboard?.layouts || { lg: [] },
							layoutid: dashboard._id || null,
						},
					});
				}
				// Load sub component metadata and get dashboard
				else {
					// console.log("non-custom dashboard effect");

					let metadata = await props.fetchMetaData();
					const { vesselid } = metadata;

					let dashboard: any = {};

					// Check if the current sub_component has a layout id
					if (metadata.layoutid) {
						dashboard = await getCustomDashboard({
							_id: metadata.layoutid,
						});
					}

					dispatch({
						type: "INIT_DASHBOARD",
						payload: {
							metadata: metadata,
							initialLayout: dashboard.layout || [],
							initialLayouts: dashboard.layouts || { lg: [] },
							layoutid: dashboard._id || null,
						},
					});
				}

				dispatch({ type: "SET_VISUAL_GROUPS", payload: visualGroups });
				dispatch({ type: "SET_VISUALS", payload: visuals });
			} catch (error: any) {
				console.log(error);
			}
		};

		if (!loading) fetchData();
	}, [
		visuals,
		measures,
		visualGroups,
		menuItem.key,
		loading,
		// props.paramValues,
	]);

	useEffect(() => {
		//Either use paramValues from other components or itself
		let _paramValues = props.paramValues || paramValues;
		// console.log(state.metadata);

		dispatch({
			type: "LOAD_DASHBOARD",
			payload: {
				defaultSlicerParams: {
					...initParameters,
					..._paramValues,
				},
			},
		});
	}, [paramValues, props.paramValues, initParameters]);

	const currentUser = useSelector((state: any) => state?.user);

	useEffect(() => {
		const refreshData = async () => {
			setVisualDataLoading(true);
			if (component === "Custom Dashboard") {
				// Load dashboard by checking user permission
				const data = await loadDashboardData(
					state?.layout || [],
					state.visuals,
					measures,
					Object.entries(state?.sliceValues).length !== 0
						? { ...state?.sliceValues }
						: {},
					currentUser,
					isOwner,
					menuItem,
					getMenuByKey
				);

				setVisualData(data);
			} else {
				// * Commented for now, tbc after solid measure inheritance flow
				// TODO: Handle for other non-custom dashboard components
				// if (state.metadata) {
				// 			const { vesselid } = state.metadata;
				// 			const data = await loadDashboardData(
				// 				state?.layout || [],
				// 				visuals,
				// 				measures,
				// 				Object.entries(state?.sliceValues).length !== 0
				// 					? { ...state?.sliceValues, vessel_id: vesselid }
				// 					: {}
				// 			);
				// 			setVisualData(data);
				// 		}
			}

			setVisualDataLoading(false);
		};

		if (!loading) refreshData();
	}, [
		state.sliceValues,
		state?.layout,
		state.visuals,
		measures,
		component,
		currentUser,
		menuItem,
		isOwner,
		loading,
	]);

	/**
	 * Handling drilldown data here first for the time being.
	 * Better approaches are welcomed
	 */
	useEffect(() => {
		const fetchDrilldownData = async () => {
			setDrilldownDataLoading(true);
			try {
				const measure = getMeasureById(state.currentDrilldown);
				if (measure) {
					const data = await fetchMeasureData(
						measure,
						currentUser,
						menuItem,
						isOwner,
						state.sliceValues,
						state.currentVisual.parameters
					);
					setDrilldownData(data.response);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setDrilldownDataLoading(false);
			}
		};
		if (state.drilldownModal && state.currentDrilldown) {
			fetchDrilldownData();
		} else {
			setDrilldownData([]);
		}
	}, [state.drilldownModal, getMeasureById]);

	useEffect(() => {
		// clear any local storage layout
		removeFromLS("layout");

		const timeout = setTimeout(() => {
			setOuterRendered(true);
		}, 2000);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	return (
		<CustomDashboardContext.Provider
			value={{
				state,
				dispatch,
				loaded: !loading,
				metadataLoading,
				tables,
				isAdmin,
				isOwner,
				isViewer,
				visual_groups: visualGroups,
				measureState,
				measureDispatch,
			}}
		>
			<ComponentHook menuProps={props.params.mitem} />
			{loading ? (
				<Space
					style={{
						width: "100%",
						height: "100%",
						justifyContent: "center",
					}}
				>
					{progress !== 100 ? (
						<Progress type="dashboard" percent={progress} />
					) : (
						<Spin />
					)}
				</Space>
			) : (
				<Layout
					style={{ background: "transparent" }}
					key={key}
					className="custom_dashboard_layout"
				>
					<Header
						className={
							ismobile
								? "custom_dashboard_layout_header_mobile"
								: "custom_dashboard_layout_header"
						}
					>
						<Space>
							{/* params that were set in the measure
						won't be used in the dashboard */}
							<SlicerInputs
								onChange={(values) => {
									dispatch({
										type: "SLICER_VAL_CHANGE",
										payload: values,
									});
								}}
								menuItem={props.params.mitem}
							/>
						</Space>
						<Space>
							<Button
								rootClassName={`slicer-run-button ${
									!state.slicerModified ? "success" : ""
								}`}
								onClick={sliceData}
								type={"primary"}
								disabled={Object.keys(state.tempSliceValues).length === 0}
							>
								Run
							</Button>
						</Space>
						<Space style={{ marginLeft: "auto" }}>
							<Space>{renderSaveEdit()}</Space>
							<Dropdown
								disabled={metadataLoading}
								trigger={["click"]}
								menu={{
									items: [
										{
											key: "measures",
											label: "Measures",
											onClick: handleButtonClick(DRAWER_KEYS.MEASURES),
										},
										{
											key: "visuals",
											label: "Visuals",
											onClick: handleButtonClick(DRAWER_KEYS.VISUALS),
										},
										{
											key: "groups",
											label: "Groups",
											onClick: handleButtonClick(DRAWER_KEYS.VISUAL_GROUPS),
										},
									],
								}}
							>
								<Button loading={metadataLoading}>
									View elements <DownOutlined />
								</Button>
							</Dropdown>
						</Space>
					</Header>
					<DrilldownModal data={drilldownData} loading={drilldownDataLoading} />
					<MeasureModal
						open={measureState.measureModal}
						closeModal={() => measureDispatch({ type: ACTIONTYPES.RESET })}
						measure={measureState.measure}
					/>
					<Drawer
						rootClassName="custom-dashboard-drawer"
						open={state.drawer}
						width={"50%"}
						onClose={() => dispatch({ type: "DRAWER", payload: null })}
						mask={false}
						styles={{
							footer: { display: "flex", justifyContent: "end" },
							body: { height: "inherit" },
						}}
					>
						<Spin
							spinning={loading}
							style={{ height: "100%", position: "initial" }}
						>
							<Tabs
								style={{ height: "100%" }}
								items={tabItems}
								activeKey={state.drawerKey}
								onChange={handleDrawerChange}
							/>
						</Spin>
					</Drawer>
					{!loading ? (
						<ResponsiveGridLayout
							className="layout"
							isDraggable={state.editMode}
							isDroppable={state.editMode}
							isResizable={state.editMode}
							style={{ height: "100%", overflowY: "scroll" }}
							onDrop={onDrop}
							onLayoutChange={onLayoutChange}
							layouts={{ lg: state.layouts.lg }} // always supply
							onBreakpointChange={onBreakpointChange}
							rowHeight={props.rowHeight || 80}
							breakpoints={props.breakpoints || { lg: 1200, md: 768, sm: 576 }}
							cols={props.cols || { lg: 6, md: 3, sm: 1 }}
							onResizeStart={() =>
								dispatch({ type: "IS_RESIZE", payload: true })
							}
							onResizeStop={() =>
								dispatch({ type: "IS_RESIZE", payload: false })
							}
							onDragStart={() => dispatch({ type: "IS_DRAG", payload: true })}
							onDragStop={() => dispatch({ type: "IS_DRAG", payload: false })}
							draggableCancel=".draggableCancel"
							droppingItem={{ i: "__dropping-elem__", h: 3, w: 1 }}
						>
							{state?.layout?.map(generateElement)}
						</ResponsiveGridLayout>
					) : null}
				</Layout>
			)}
		</CustomDashboardContext.Provider>
	);
};

// returns the updated menu
const updateMenuItemByKey = (
	menu: any,
	key: string,
	updatedProperties: any
) => {
	return menu.map((menuItem: any) => {
		if (menuItem.key === key) {
			return { ...menuItem, ...updatedProperties };
		} else if (menuItem.children) {
			return {
				...menuItem,
				children: updateMenuItemByKey(
					menuItem.children,
					key,
					updatedProperties
				),
			};
		}
		return menuItem;
	});
};

export default CustomDashboard;
