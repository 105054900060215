import {
	Col,
	DatePicker,
	DatePickerProps,
	Flex,
	Form,
	FormInstance,
	Input,
	Row,
	Typography,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect } from "react";

import useVessels from "../hooks/useVessels";
import ParamSelectDropdownV2 from "./ParamSelectDropdownV2";

const { Text } = Typography;

interface QueryParametersProps {
	parameters?: Array<Parameter>;
	paramValues?: any;
	onChange?: (params?: any) => void;
}

const datePickerProps: DatePickerProps = {
	style: { width: 216, borderRadius: 2 },
	popupStyle: { zIndex: 30003 },
};

const QueryParameters = ({
	parameters,
	paramValues,
	onChange = () => {},
}: QueryParametersProps) => {
	const handleChange = useCallback(
		(key: string, value: any) => {
			const params = { ...paramValues, [key]: value };
			onChange(params);
		},
		[paramValues]
	);

	return (
		<Row gutter={16}>
			<Col span={12}>
				<Flex vertical gap={8}>
					{parameters?.slice(0, 3)?.map((param) => {
						switch (param.dataType) {
							case "date":
								return (
									<Row>
										<Col span={8}>
											<Text className="query-params-label">
												@{param?.name}:{" "}
											</Text>
										</Col>
										<Col flex={"auto"}>
											<DatePicker
												{...datePickerProps}
												onChange={(value, dateString) =>
													handleChange(param?.name, dateString)
												}
												value={
													!paramValues?.[param?.name]
														? null
														: dayjs(paramValues?.[param?.name])
												}
											/>
										</Col>
									</Row>
								);

							default:
								return (
									<Row>
										<Col span={8}>
											<Text className="query-params-label">
												@{param?.name}:{" "}
											</Text>
										</Col>
										<Col flex={"auto"}>
											{param.selection === "single" ? (
												<Input />
											) : (
												<ParamSelectDropdownV2
													value={paramValues?.[param?.name]}
													parameter={param}
													onChange={(value, options) => {
														const paramValue = options?.map(
															(option: any) => option.value
														);

														handleChange(param?.name, paramValue);
													}}
												/>
											)}
										</Col>
									</Row>
								);
						}
					})}
				</Flex>
			</Col>
			<Col span={12}>
				<Flex vertical gap={8}>
					{parameters?.slice(3, 6)?.map((param) => {
						switch (param.dataType) {
							case "date":
								return (
									<Row>
										<Col span={8}>
											<Text className="query-params-label">
												@{param?.name}:{" "}
											</Text>
										</Col>
										<Col flex={"auto"}>
											<DatePicker
												{...datePickerProps}
												onChange={(value, dateString) =>
													handleChange(param?.name, dateString)
												}
												value={
													!paramValues?.[param?.name]
														? null
														: dayjs(paramValues?.[param?.name])
												}
											/>
										</Col>
									</Row>
								);

							default:
								return (
									<Row>
										<Col span={8}>
											<Text className="query-params-label">
												@{param?.name}:{" "}
											</Text>
										</Col>
										<Col flex={"auto"}>
											{param.selection === "single" ? (
												<Input />
											) : (
												<ParamSelectDropdownV2
													value={paramValues?.[param?.name]}
													parameter={param}
													onChange={(value, options) => {
														const paramValue = options?.map(
															(option: any) => option.value
														);

														handleChange(param?.name, paramValue);
													}}
												/>
											)}
										</Col>
									</Row>
								);
						}
					})}
				</Flex>
			</Col>
		</Row>
	);
};

export default QueryParameters;
