import { Form, Typography } from "antd";
import DataAccess from "./DataAccess";

const { Text } = Typography;

type CustomDashboardInputsProps = {
	data_authorized?: any[];
	schema_authorized?: any[];
	onDataAccessChange?: (
		authorizedDataset: Array<string>,
		authorizedSchemas: Array<string>
	) => void;
};

const CustomDashboardInputs = ({
	data_authorized = [],
	schema_authorized = [],
	onDataAccessChange = () => {},
}: CustomDashboardInputsProps) => {
	return (
		<>
			<Form.Item>
				<Text style={{ fontSize: 20, marginBottom: 8 }}>
					Dashboard Access Settings
				</Text>
			</Form.Item>
			<DataAccess
				onChange={onDataAccessChange}
				authorized_dataset={data_authorized}
				authorized_schema={schema_authorized}
			/>
		</>
	);
};

export default CustomDashboardInputs;
