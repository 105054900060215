import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import file from "../Help.md";
import rehypeRaw from "rehype-raw";
import { connect } from "react-redux";
import Search from "antd/lib/input/Search";
import { handleSearch } from "../utils/search";

class Help extends React.Component<any> {
	state = {
		markdown: null,
		// visible: false,
	};
	helpCenterTopRef: any = undefined;

	constructor(props: any) {
		super(props);
		this.helpCenterTopRef = React.createRef();
	}

	componentDidMount() {}

	render() {
		const fetchfile = () => {
			fetch(file)
				.then((res) => res.text())
				.then((text) => this.setState({ markdown: text }));
		};

		// const handleChange = (e: any) => {
		// 	this.setState({ searchString: e.target.value });
		// };

		return (
			<>
				{!this.props.ismobile ? (
					<div className="markdown-container">
						<div className="markdown-top-container">
							<div className="markdown-title-container">
								<div className="markdown-title">Help Center</div>
								<p style={{ width: "90%", fontSize: "16px" }}>
									Welcome to the Stena Evolve 'Portal' help center!
								</p>
							</div>
							<div className="markdown-contact-container">
								<span>Contact</span>
								<div
									className="divider"
									style={{
										borderBottom: "1px solid rgba(0,0,0,0.1)",
										marginLeft: "-10px",
									}}
								></div>
								<span style={{ fontWeight: "bold" }}>Need help? </span>
								<br />
								<span>Please contact the support team:</span>
								<br />
								<span
									style={{ fontWeight: "bold", textDecoration: "underline" }}
									data-tip="Contact Support"
								>
									<a
										className="md-link"
										href="mailto:portal-support@stena-evolve.com?subject=Portal Support"
									>
										portal-support@stena-evolve.com
									</a>
								</span>
							</div>
						</div>
						<div className="markdown-bottom-container">
							<>
								{this.state.markdown ? (
									<div className="markdown-notes">
										<ReactMarkdown
											children={this.state.markdown}
											components={{ h1: "h2" }}
											rehypePlugins={[rehypeRaw]}
										/>
									</div>
								) : (
									fetchfile()
								)}
							</>
							<div className="markdown-table-of-contents">
								<span style={{ fontSize: "1.5em", fontWeight: "bold" }}>
									Table of Contents
								</span>{" "}
								<br />
								<span style={{ textDecoration: "underline" }}>
									<a className="md-link" href="#introduction">
										Introduction
									</a>{" "}
									<br />
									<a className="md-link" href="#general_use">
										General Use
									</a>{" "}
									<br />
									<a className="md-link" href="#navigation">
										Navigation
									</a>{" "}
									<br />
									<a className="md-link" href="#anything_else">
										Anything Else
									</a>{" "}
									<br />
								</span>
							</div>
						</div>
					</div>
				) : (
					<div className="markdown-container-mobile">
						<div style={{ padding: "5%" }}>
							<Search
								allowClear
								placeholder="Search..."
								onSearch={handleSearch}
								// for autocomplete feature
								// not implemented yet
								// onChange={handleChange}
							/>
							<div className="markdown-title-container">
								<div
									ref={this.helpCenterTopRef}
									id="top"
									className="markdown-title"
									style={{ marginTop: "5%" }}
								>
									Help Center
								</div>
								<p style={{ width: "90%", fontSize: "16px" }}>
									Welcome to the Stena Evolve 'Portal' help center!
								</p>
							</div>
						</div>
						<div className="markdown-bottom-container-mobile">
							<div className="markdown-content-mobile">
								<span style={{ fontSize: "1.5em", fontWeight: "bold" }}>
									Table of Contents
								</span>{" "}
								<br />
								<span style={{ textDecoration: "underline" }}>
									<a className="md-link" href="#introduction">
										Introduction
									</a>{" "}
									<br />
									<a className="md-link" href="#general_use">
										General Use
									</a>{" "}
									<br />
									<a className="md-link" href="#navigation">
										Navigation
									</a>{" "}
									<br />
									<a className="md-link" href="#anything_else">
										Anything Else
									</a>{" "}
									<br />
								</span>
							</div>
							<div
								className="markdown-content-mobile"
								style={{ lineHeight: "2.5" }}
							>
								<span style={{ fontSize: "1.5em", fontWeight: "bold" }}>
									Contact
								</span>{" "}
								<br />
								<span>Need help? Please contact the support team:</span>
								<br />
								<span
									style={{ fontWeight: "bold", textDecoration: "underline" }}
									data-tip="Contact Support"
								>
									<a
										style={{ color: "rgb(131, 195, 255)" }}
										href="mailto:portal-support@stena-evolve.com?subject=Portal Support"
									>
										portal-support@stena-evolve.com
									</a>
								</span>
							</div>
							<>
								{this.state.markdown ? (
									<div
										className="markdown-notes"
										style={{ marginBottom: "10%" }}
									>
										<ReactMarkdown
											children={this.state.markdown}
											components={{ h1: "h2" }}
											rehypePlugins={[rehypeRaw]}
										/>
									</div>
								) : (
									fetchfile()
								)}
							</>
						</div>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(Help);
