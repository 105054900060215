import {
	Card,
	Col,
	ColorPicker,
	Divider,
	Form,
	Input,
	Modal,
	ModalProps,
	Row,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { saveVisualGroup } from "../../services/api-server/visualsGroups";
import Emitter from "../../services/EventEmitter";
import { VisualGroupType } from "../../types/VisualGroup";
import { getColors } from "../../utils/utils";
import { CustomDashboardContext } from "../../contexts/context";
import { RuleObject } from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import { Color } from "antd/es/color-picker";

type VisualGroupsConfigurationProps = ModalProps & {
	visualGroup?: Partial<VisualGroupType>; // visual group object will be passed here regardless of new or existing visual group
	onSave?: () => void;
	onChange?: (visualGroup: Partial<VisualGroupType>) => void;
};

const VisualGroupConfiguration = ({
	visualGroup,
	onSave = () => {},
	onChange = () => {},
	onCancel = () => {},
	...restProps
}: VisualGroupsConfigurationProps) => {
	const [form] = useForm();
	const [loading, setLoading] = useState(false);
	const { visual_groups } = useContext(CustomDashboardContext);
	const [colour, setColour] = useState(""); // handling colour separately as antd form item returning ColorFactory object instead of hex
	const [isOk, setIsOk] = useState(true);

	const handleSave = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		try {
			const values = await form?.validateFields();

			const updatedVisualGroup = { ...visualGroup, ...values, colour };

			setLoading(true);
			const response = await saveVisualGroup(updatedVisualGroup);

			Emitter.emit("alert", {
				type: "success",
				message: "Visual group saved",
				description: "You have successfully saved this visual group",
				timeout: 5000,
			});
			Emitter.emit("VISUAL_GROUPS_UPDATED", response);
			onSave();
			form?.resetFields();
		} catch (e) {
			Emitter.emit("alert", {
				type: "error",
				message: "Error saving visual group",
				description: "An error occurred when saving this visual group",
				timeout: 5000,
			});
		} finally {
			onCancel(e);
			setLoading(false);
		}
	};

	const handleClose = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		form.resetFields();
		onCancel(ev);
	};

	const { backgroundColor, headerColor } = getColors(colour || "#fff");

	const checkUnique = async (rule: RuleObject, value: any) => {
		if (!value) {
			setIsOk(false);
			return Promise.resolve();
		}

		const isUnique = !visual_groups
			?.filter((_group: any) => _group?.id !== visualGroup?.id)
			.find(
				(group: any) =>
					group?.name?.toLowerCase() === value?.toLowerCase()?.trim()
			);

		if (isUnique) {
			setIsOk(true);
			return Promise.resolve();
		} else {
			setIsOk(false);
			return Promise.reject("Group name must be unique");
		}
	};

	const name = Form.useWatch("name", form);

	const handleColorChange = (value: Color) => {
		setColour(value.toHexString());
		form?.setFieldValue("colour", value.toHexString());
	};

	useEffect(() => {
		form.setFieldsValue(visualGroup);
		setColour(visualGroup?.colour || "#fff");

		return () => {
			setIsOk(true);
		};
	}, [visualGroup]);

	return (
		<Modal
			{...restProps}
			title={"Group"}
			onOk={handleSave}
			okText="Save"
			okButtonProps={{
				loading,
				disabled:
					!form?.isFieldsTouched() ||
					!isOk ||
					!form?.getFieldValue("name")?.trim(),
			}}
			onCancel={handleClose}
			width={"80%"}
			destroyOnClose
		>
			<Form layout="vertical" form={form} requiredMark={"optional"}>
				<Row justify={"center"} gutter={16}>
					<Col span={11}>
						<Form.Item
							name={"name"}
							label="Group Name"
							rules={[
								{
									required: true,
									message: "Please input a group name",
									validator: (_, value) => {
										if (value?.trim()) {
											return Promise.resolve();
										} else {
											return Promise.reject();
										}
									},
								},
								{
									validator: checkUnique,
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item name={"colour"} label="Banner Color" required={true}>
							<ColorPicker onChange={handleColorChange} />
						</Form.Item>
					</Col>
					<Divider type="vertical" style={{ height: "auto" }} />
					<Col span={11}>
						<Form.Item label="Preview" required={true}>
							<Card
								style={{
									border: `solid 1px ${colour}`,
									borderRadius: 2,
									backgroundColor,
								}}
								styles={{
									body: { height: 250 },
									header: {
										borderColor: colour,
										background: headerColor,
									},
								}}
								title={name}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default VisualGroupConfiguration;
