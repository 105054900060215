import { Badge, BadgeProps } from "antd";
import { useAccessRequestContext } from "../contexts/AccessRequestContext";

type RequestsBadgeProps = BadgeProps & {
	show?: boolean;
};

const RequestsBadge = ({
	show,
	children,
	...restProps
}: RequestsBadgeProps) => {
	const { accessRequests } = useAccessRequestContext();

	return (
		<Badge
			{...restProps}
			dot
			size="small"
			style={{
				display:
					show &&
					accessRequests.filter((_request) => _request.status === "Requested")
						.length !== 0
						? "inherit"
						: "none",
			}}
		>
			{children}
		</Badge>
	);
};

export default RequestsBadge;
