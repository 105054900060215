import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useMenu from "../hooks/useMenu";
import { Empty, Flex, Input, Select, Space, Spin } from "antd";
import { useSelector } from "react-redux";
import { filterMenu, getNodes } from "../utils/utils";
import { GetComponentIcon } from "../utils/svg_icons";
import { debounce } from "lodash";
import { useNavigate } from "react-router";
import { Tenant } from "../services/api-server/_exports";
import { GetAntIcon } from "../utils/ant_icons";
import { CloseCircleOutlined } from "@ant-design/icons";

const debounceTimeout = 1000;
const GlobalSearch = (props: any) => {
	const { menu } = useMenu();
	const navigate = useNavigate();
	const userRoles = useSelector((state: any) => state?.role);
	const currentUser = useSelector((state: any) => state?.user);

	const items = useMemo(() => {
		return userRoles?.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)
			? menu
			: filterMenu(menu, userRoles, currentUser);
	}, [userRoles, menu, currentUser]);

	const [fetching, setFetching] = useState(false);
	const [Searched, setSearched] = useState(false);
	const [options, setOptions] = useState<any[]>([]);
	const fetchRef = useRef(0);

	const allNodes = useMemo(() => {
		return getNodes(items)?.map((node) => ({
			label: node?.title,
			value: node?.key,
			path: node?.path,
			description: node?.description || null,
			title: node?.description || null,
			component: node?.component,
		}));
	}, [items]);

	useEffect(() => {
		setOptions(allNodes);
	}, [items]);

	const fetchOptions = async (value: string) =>
		!value
			? allNodes
			: allNodes?.filter((node) =>
					node.label?.toLowerCase()?.includes(value.toLowerCase())
			  );

	const loadOptions = (value: string) => {
		// if (value.length != 0) {
		fetchRef.current += 1;
		const fetchId = fetchRef.current;
		setOptions([]);
		setFetching(true);
		fetchOptions(value).then((newOptions) => {
			if (fetchId !== fetchRef.current) {
				// for fetch callback order
				return;
			}

			setOptions(newOptions);
			setFetching(false);
			setSearched(true);
		});
		// } else {
		// 	setOptions([]);
		// 	setFetching(false);
		// 	setSearched(false);
		// }
	};

	// Memoize the debounced function
	const debouncedLoadOptions = useCallback(
		debounce((value) => {
			loadOptions(value);
		}, debounceTimeout),
		[items]
	);

	const handleResultSelect = (info: any, option: any) => {
		//Currently setting for menu type only
		const focusedElement = document.activeElement;

		// Check if the focused element is an HTMLElement (or a more specific type)
		if (focusedElement instanceof HTMLElement) {
			// Call blur() method on the HTMLElement
			focusedElement.blur();
		} else {
			// Handle case where the focusedElement is not an HTMLElement
			console.error("The currently focused element is not an HTMLElement.");
		}
		navigate(`/${Tenant}/${option.path?.join("/")}`);

		// toggle();
	};

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
			}}
		>
			<Select
				style={{ width: "100%" }}
				placeholder={"Search..."}
				labelInValue
				showSearch
				suffixIcon={GetAntIcon("search")}
				allowClear
				filterOption={false}
				onSearch={(value: any) => {
					setFetching(true);
					debouncedLoadOptions(value);
				}}
				onClear={() => {
					setTimeout(() => {
						setOptions(allNodes);
					}, 1);
				}}
				notFoundContent={
					fetching ? <Spin size="small" /> : Searched && <Empty />
				}
				{...props}
				autoClearSearchValue
				options={options}
				onSelect={handleResultSelect}
				dropdownStyle={{ background: "#141414" }}
				optionRender={(item: any) => {
					return (
						<Space
							size={16}
							style={{ width: "100%" }}
							styles={{
								item: { overflow: "hidden" },
							}}
						>
							{GetComponentIcon({
								componentName: item?.data?.component,
								width: 20,
							})}
							<Flex vertical>
								<div>{item.label}</div>
								<div
									style={{
										textOverflow: "ellipsis",
										overflow: "hidden",
										color: "#FFFFFF73",
									}}
								>
									{item?.data?.description}
								</div>
							</Flex>
						</Space>
					);
					// return (
					// 	<div
					// 		style={{
					// 			height: "30px",
					// 			display: "flex",
					// 			flexDirection: "row",
					// 			alignItems: "center",
					// 			gap: "5px",
					// 		}}
					// 	>
					// 		<span style={{ display: "flex" }}>
					// 			{GetComponentIcon({
					// 				componentName: item?.data?.component,
					// 				width: 20,
					// 			})}
					// 		</span>
					// 		<span>{item.label}</span>
					// 	</div>
					// );
				}}
			/>
		</div>
	);
};

export default GlobalSearch;
