import { Tenant } from "./_exports";
import Emitter from "../EventEmitter";
import { api } from "../../contexts/AuthContext";

export const getMeasures = () => {
	return new Promise((resolve, reject) => {
		api
			.get(`/measures_v2/getMeasures`, {})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addMeasure = (measure: MeasureType, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api
			.post(`/measures_v2/addMeasure`, { ...measure, menuKey })
			.then((response) => {
				Emitter.emit("MEASURES_UPDATED", response);
				resolve(response);
			})
			.catch((e: any) => {
				console.log("err", e);
				reject(e);
			});
	});
};

export const updateMeasure = (measure: MeasureType, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api
			.put(`/measures_v2/updateMeasure`, measure)
			.then((response) => {
				Emitter.emit("MEASURES_UPDATED", response);
				resolve(response);
			})
			.catch((e: any) => {
				console.log("err", e);
				reject(e);
			});
	});
};

export const deleteMeasure = (measureId: string, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api
			.delete(`/measures_v2/deleteMeasure`, {
				data: { id: measureId, tenantid: Tenant, menuKey },
			})
			.then((_data) => {
				Emitter.emit("MEASURES_UPDATED", _data);
				resolve(_data);
			})
			.catch((e: any) => {
				console.log("err", e);
				reject(e);
			});
	});
};
