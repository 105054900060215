import { ColumnProps } from "antd/lib/table/Column";
import { v4 as uuidv4 } from "uuid";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
const subLabels: any = {
	Environment: ["ENV-1", "ENV-2", "ENV-3", "ENV-4"],
	Emission: ["E-1", "E-2", "E-3"],
	Safety: ["Safety-A", "S-B", "S-C", "S-D"],
	Health: ["H-1", "H-2", "H-3", "H-4", "H-5"],
	Mechanical: ["M-1", "M-2", "M-3", "M-4"],
};
export const stenaColors = {
	grey: "#B2B2B2",
	purple: "#522D6E",
	lilac: "#AA6CAD",
	teal: "#42BB91",
};
export const colorRange = [
	stenaColors.purple,
	stenaColors.lilac,
	stenaColors.teal,
	stenaColors.grey,
];
export const generateData: any = (
	n: number = Math.floor(Math.random() * 200)
) => {
	let myData: any = [];
	const categories = [
		"Environment",
		"Emission",
		"Safety",
		"Health",
		"Mechanical",
	];
	for (let i = 0; i < n; i++) {
		const id = uuidv4();
		const cat = categories[Math.floor(Math.random() * categories.length)];
		let object: any = {
			_id: id,
			id,
			label: `mylabel ${i}`,
			category: cat,
			subCategory:
				subLabels[cat][Math.floor(Math.random() * subLabels[cat].length)],
			lineA: Math.random() * 100,
			lineB: Math.random() * 100,
			lineC: Math.random() * 150,
			lineD: Math.random() * 100,
			value: 10, //Math.random()*100,
			myValue: Math.random() * 10,
		};
		myData.push(object);
	}
	return myData;
};

export const capitalize = (value: string) => {
	const lowerCase = value.toLowerCase();
	const capitalized = `${lowerCase[0].toUpperCase()}${lowerCase.slice(1)}`;
	const cleaned = capitalized.replace(/\_/g, " ");

	return cleaned;
};

export const isDateTime = (name: string) => {
	const dateNames = ["activity_time_start", "activity_time_end"];
	return dateNames.includes(name);
};

export const isDate = (name: string) => {
	const dateNames = ["report_date"];
	return dateNames.includes(name);
};

export const processValueByKey = (key: string, value: any): string => {
	let proccessedValue = value;
	if (isDate(key)) {
		proccessedValue = formatDate(value);
	} else if (isDateTime(key)) {
		proccessedValue = formatDateTime(value);
	} else if (key === "activity_duration" || key === "duration") {
		proccessedValue = `${proccessedValue} hour${value > 1 ? "s" : ""}`;
	}
	return proccessedValue;
};

export const formatDateTime = (value: string): string => {
	return dayjs(value).format("DD-MMM-YYYY hh:mm:ss A");
};

export const formatDate = (value: string): string => {
	return dayjs(value).format("DD-MMM-YYYY");
};
