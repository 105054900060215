import React, { useEffect, useState } from "react";
import { loadAdminmenu } from "../services/api-server/menu";
import { initialState } from "../state/reducers/module";

const useAdminMenu = () => {
	const [adminMenu, setAdminMenu] = useState<object[]>([]);

	useEffect(() => {
		loadAdminmenu()
			.then((data: any) => {
				// if (data !== undefined) setAdminMenu(data.adminmenu);

				setAdminMenu(initialState.adminModules);
			})
			.catch((error: any) => {
				// console.error(error.message);
			});
	}, []);

	return adminMenu;
};

export default useAdminMenu;
