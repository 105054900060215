import axios from "axios";
import { AuthHeader, Tenant, url } from "./_exports";
import Emitter from "../EventEmitter";

export const getParameters = (menuKey: string): Promise<Parameter[]> => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/parameters/getParameters`, {
				params: {
					menuKey,
				},
				...AuthHeader,
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addParameter = (
	parameter: Partial<Parameter>,
	menuKey?: string
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/parameters/addParameter`,
				{ ...parameter, menuKey },
				AuthHeader
			)
			.then((response) => {
				Emitter.emit("PARAMETERS_UPDATED", response);
				resolve(response);
			})
			.catch((e: any) => {
				console.log("err", e);
				reject(e);
			});
	});
};

export const updateParameter = (
	parameter: Partial<Parameter>,
	menuKey?: string
) => {
	return new Promise((resolve, reject) => {
		axios
			.put(`${url}/parameters/updateParameter`, parameter, AuthHeader)
			.then((response) => {
				Emitter.emit("PARAMETERS_UPDATED", response);
				resolve(response);
			})
			.catch((e: any) => {
				console.log("err", e);
				reject(e);
			});
	});
};

export const deleteParameter = (id: string, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${url}/parameters/deleteParameter`, {
				data: { id },
				...AuthHeader,
			})
			.then((_data) => {
				Emitter.emit("PARAMETERS_UPDATED", _data);
				resolve(_data);
			})
			.catch((e: any) => {
				console.log("err", e);
				reject(e);
			});
	});
};
