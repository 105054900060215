export const AdminUsers = (state: any = null, action: any) => {
	switch (action.type) {
		case "POPULATE_USERS":
			return action.payload;
		default:
			return state;
	}
};

export const Role = (state: any = null, action: any) => {
	switch (action.type) {
		case "ROLE":
			return action.payload;
		default:
			return state;
	}
};

const allUsersInitialState: any = {
	list: [],
	user: {},
};
export const AllUsers = (state: any = allUsersInitialState, action: any) => {
	let _data: any = null;
	switch (action.type) {
		case "SAVE_USER":
			_data = action.payload;
			return { ...state, user: _data };
		case "SET_ALL_USERS":
			// console.log('allusers',action.payload)
			_data = action.payload;
			return { ...state, list: _data };
		case "REPLACE_ALL_USERS":
			// console.log("REPLACE_ALL_USERS CALLED", action.payload);
			_data = action.payload;
			return { ...state, list: _data };
		default:
			return state;
	}
};

export const Client = (state: any = null, action: any) => {
	switch (action.type) {
		case "CLIENT":
			return action.payload;
		default:
			return state;
	}
};

export const SelectedUser = (state: any = null, action: any) => {
	switch (action.type) {
		case "UPDATE_SELECTED_USER":
			// console.log("UPDATE_SELECTED_USER");
			return action.payload;
		case "SELECT_USER":
			return action.payload;
		default:
			return state;
	}
};

export const SelectedMenu = (state: any = null, action: any) => {
	switch (action.type) {
		case "UPDATE_SELECTED_MENU":
			// console.log("Updated selected menu");
			return action.payload;
		case "SELECT_MENU":
			return action.payload;
		default:
			return state;
	}
};
