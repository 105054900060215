import { DashboardOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { VisualType } from "../types/Visual";
import { useContext } from "react";
import { CustomDashboardContext } from "../contexts/context";
import useMeasures from "../hooks/useMeasures";
import { useComponent } from "../contexts/ComponentContext";

type VisualActionsDropdownType = {
	visual: VisualType;
};

const VisualActionsDropdown = ({ visual }: VisualActionsDropdownType) => {
	const { measureDispatch } = useContext(CustomDashboardContext);
	const { menuItem: masteredMenuItem } = useComponent();
	const { getMeasureById } = useMeasures();

	const handleShowMeasure = () => {
		// Find where the measure was mastered from
		const measure = getMeasureById(visual.measure);

		if (measure?.menuKey === masteredMenuItem.key) {
			measureDispatch({ type: "EDIT_MEASURE", payload: measure });
		} else {
			measureDispatch({ type: "VIEW_MEASURE", payload: measure });
		}
	};

	const items: MenuProps["items"] = [
		{
			key: "measure",
			label: "View measure",
			icon: <DashboardOutlined style={{ fontSize: 16 }} />,
			onClick: handleShowMeasure,
		},
	];
	return (
		<Dropdown menu={{ items }} trigger={["click"]}>
			<MoreOutlined />
		</Dropdown>
	);
};

export default VisualActionsDropdown;
