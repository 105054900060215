import { api } from "../../contexts/AuthContext";

export type SubmissionEmailProps = {
	component_title: string;
	component: string;
	description: string;
	redirect_url: string;
	receivers: string[];
};

export type RejectSubmissionEmailProps = {
	component: string;
	component_title: string;
	reason: string;
	redirect_url: string;
	requestor: string[];
};

export type ApproveSubmissionEmailProps = {
	component: string;
	component_title: string;
	redirect_url: string;
	requestor: string[];
};

export type AnnouncementEmailProps = {
	component: string;
	component_title: string;
	title: string;
	redirect_url: string;
	message: string;
	receivers: string[];
};

export const sendSubmissionEmail = (data: SubmissionEmailProps) => {
	// Send Grid email template for access request
	const template_id = "d-4cace8612cea48bb9b5422ea5fa2ee4f";

	return new Promise((resolve, reject) => {
		api
			.post(`/sendgrid/sendMail`, {
				...data,
				template_id,
				subject: "Portal - Component Access Request",
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const sendSubmissionRejectEmail = (data: RejectSubmissionEmailProps) => {
	// Send Grid email template for access request reject
	const template_id = "d-b8012d9864ae4916929bc832c0e1b573";

	return new Promise((resolve, reject) => {
		api
			.post("/sendgrid/sendMail", {
				...data,
				template_id,
				subject: "Portal - Component Access Request",
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const sendSubmissionApproveEmail = (
	data: ApproveSubmissionEmailProps
) => {
	// Send Grid email template for access request approve
	const template_id = "d-863e37440a1b49a89f91d04a2adb5151";

	return new Promise((resolve, reject) => {
		api
			.post("/sendgrid/sendMail", {
				...data,
				template_id,
				subject: "Portal - Component Access Request",
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const sendAnnouncementEmail = (data: AnnouncementEmailProps) => {
	// Send Grid email template for access request approve
	const template_id = "d-c218ba2522fd489183fc9a86269f1898";

	return new Promise((resolve, reject) => {
		api
			.post("/sendgrid/sendMail", {
				...data,
				template_id,
				subject: "Component Announcement",
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
