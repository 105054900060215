import React from "react";
import { connect } from "react-redux";

class NoAccess extends React.Component<any> {
	props: any = {};

	constructor(props: any) {
		super(props);
		this.props = props;
	}

	render() {
		return (
			<div
				style={{
					height: "100%",
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<div className="coming-soon-background">
					{" "}
					<div className="coming-soon-center-container">
						<div className="hexlock-container">
							<div className="hexlock"></div>
						</div>
						<span
							style={
								!this.props.ismobile
									? { fontSize: "3em", lineHeight: "2", textAlign: "center" }
									: {
											fontSize: "1.8em",
											lineHeight: "2",
											textAlign: "center",
									  }
							}
						>
							{this.props.text
								? this.props.text
								: "You do not have access to this page"}
						</span>
						{this.props.subtext ? (
							<span
								style={
									!this.props.ismobile
										? {
												fontSize: "1.4em",
												lineHeight: "1",
												textAlign: "center",
										  }
										: {
												fontSize: "1.2em",
												lineHeight: "1",
												textAlign: "center",
										  }
								}
							>
								{this.props.subtext}
							</span>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(NoAccess);
