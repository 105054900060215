import { useEffect, useState } from "react";
import { getParameters } from "../services/api-server/parameters";
import Emitter from "../services/EventEmitter";
import { duckQuery } from "../services/api-server/deltashare";
import { recordToArray } from "../utils/utils";

// Default mandatory parameters
// Setting the menuKey field to be 'all' to indicate the parameters is defaulted
export const mandatoryParameters: Parameter[] = [
	{
		id: "start_date",
		dataType: "date",
		displayName: "Start date",
		mandatory: true,
		selection: "single",
		fieldValues: [],
		name: "start_date",
		source: "query",
		default: true,
		menuKey: "all",
	},
	{
		id: "end_date",
		dataType: "date",
		displayName: "End date",
		mandatory: true,
		selection: "single",
		fieldValues: [],
		name: "end_date",
		source: "query",
		default: true,
		menuKey: "all",
	},
];

const useParameters = (menuKey: string) => {
	const [parameters, setParameters] = useState<Parameter[]>([]);
	const [paramValues, setParamValues] = useState<any>({});
	const [loading, setLoading] = useState(false); // Setting this true to ensure it loads initially

	useEffect(() => {
		const fetchParameters = async () => {
			setLoading(true);
			try {
				const response = await getParameters(menuKey);
				const allParams = [
					...mandatoryParameters,
					...(response as Parameter[]),
				];

				const promises = allParams
					?.filter((param) => param?.selection === "multi") // Only params that has multi values pass for this
					?.map(async (param) => {
						if (param.source === "query") {
							if (param?.query) {
								const data: any = await duckQuery(param?.query, [], true);
								const latestValues = recordToArray(data?.response);

								// Filtering to exclude the latest value from the parameter values, configure what values to be shown from the parameter configuration
								const filteredValues = latestValues?.filter((value) => {
									if (param?.fieldValues?.length > 0) {
										return param?.fieldValues?.find(
											(field) => field?.value == value && field?.include
										);
									}
									return true;
								});

								return { [param.name]: filteredValues };
							} else {
								return { [param.name]: [] };
							}
						} else {
							return {
								[param.name]: param?.fieldValues
									?.filter((field) => field?.include)
									?.map((field) => field?.value),
							};
						}
					});
				const responses = await Promise.all(promises);

				setParameters(allParams);

				setParamValues(
					responses?.reduce((acc, curr) => {
						return { ...acc, ...curr };
					}, {})
				);
				// setLoading(false);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		fetchParameters();

		Emitter.on("PARAMETERS_UPDATED", fetchParameters);

		return () => {
			Emitter.off("PARAMETERS_UPDATED", fetchParameters);
		};
	}, [menuKey]);

	return { parameters, paramValues, loading };
};

export default useParameters;
