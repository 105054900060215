import { isLoggedIn, loginRegister, Token, User } from "./isLoggedIn";
import { ContractedVessels, VesselImgMap, Vessels } from "./vessels";
import { combineReducers } from "redux";
import {
	AdminModules,
	AllComponents,
	AllModules,
	CurrentComponent,
	CurrentModule,
	Documents,
	SiderCollapse,
} from "./module";
import { AdminUsers, AllUsers, Client, Role, SelectedUser } from "./admin";
import { isMobile } from "./device";

import { Notifications, ReleaseNotes } from "./notifications";
import { strictEqual } from "assert";
import { Mode, TestRecipients } from "./mode";
export const noop = async (state: any = null, action: any) => {
	switch (action.type) {
		case "NO-OP":
			// console.log("waiting");
			await setTimeout(() => {
				// console.log("waited");
			}, 1000);
			return state;
		case "PAUSE":
			alert("paused");
			return state;
		case "GO-HOME":
			// window.location.href = `/${process.env.REACT_APP_CLIENT_TENANT_ID}`;
			return state;
		case "PAGE-RELOAD":
			window.location.reload();
			return state;
		default:
			await setTimeout(() => {}, 1000);
			return state;
	}
};

export const allReducers = combineReducers({
	isLoggedIn: isLoggedIn,
	user: User,
	client: Client,
	role: Role,
	ismobile: isMobile,
	documents: Documents,
	adminUsers: AdminUsers,
	noop: noop,
	vessels: Vessels,
	loginRegister: loginRegister,
	userToken: Token,
	allModules: AllModules,
	allComponents: AllComponents,
	siderCollapse: SiderCollapse,
	adminModules: AdminModules,
	currentModule: CurrentModule,
	currentComponent: CurrentComponent,
	allUsers: AllUsers,
	vesselImgMap: VesselImgMap,
	selectedUser: SelectedUser,
	contractedVessels: ContractedVessels,
	notifications: Notifications,
	releasenotes: ReleaseNotes,
	mode: Mode,
	testRecipients: TestRecipients,
});
