import { Form, Radio, Typography } from 'antd';

const { Text } = Typography

const MapInputs = () => {
    return (
        <>
            <Form.Item>
                <Text style={{ fontSize: 20, marginBottom: 8 }}>
                    Map Settings
                </Text>
            </Form.Item>
            <Form.Item name="level" label="Level">
                <Radio.Group
                    options={[{
                        label: 'Bronze', value: 'bronze'
                    },
                    { label: 'Silver', value: 'silver' },
                    { label: 'Gold', value: 'gold' },
                    ]}
                />
            </Form.Item>
        </>
    );
}

export default MapInputs