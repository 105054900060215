import { Empty, Image } from "antd";
import React from "react";
import { connect } from "react-redux";
import "../assets/css/Vessel.css";

class VesselButton extends React.Component<any> {
	props: any = {
		vesselname: "",
		onhire: false,
	};

	state: any = {
		vesselSelected: null,
		dynClass: "vessel-button",
		imageObj: null,
		updateLoop: undefined,
	};

	rig: any;

	ref: any;

	updateLoop: any;

	constructor(props: any) {
		super(props);
		this.props = props;
	}

	componentDidMount() {
		if (this.state.updateLoop === undefined) {
			this.updateLoop = setInterval(() => {
				if (this.props.vesselImgMap) {
					this.setState({
						imageObj: this.props.vesselImgMap[`${this.props.vesselname}`],
					});
				}
			}, 500);
		}
	}

	componentDidUpdate() {
		if (this.props.vesselImgMap && this.state.imageObj == null) {
			this.setState({
				imageObj: this.props.vesselImgMap[`${this.props.vesselname}`],
			});
		}
	}
	componentWillUnmount() {
		if (this.updateLoop) {
			clearInterval(this.updateLoop);
		}
	}

	getImageLink = () => {
		return this.state.imageObj?.icon3 || this.state.imageObj?.icon || undefined;
	};

	dynClass = () => {
		let result = `${
			this.props.selectedVessel === this.props.vesselname
				? "vessel-button-selected"
				: "vessel-button"
		} `;
		if (this.props.selectedVessel === this.props.vesselname) {
			if (this.ref?.scrollIntoView) {
				this.ref.scrollIntoView({
					behavior: "smooth",
					block: "center",
					inline: "nearest",
				});
			}
		}
		return result;
	};

	selectVessel = () => {
		if (this.props.selectedVessel === this.props.vesselname) {
			this.setState({ vesselSelected: null });
			this.props.dispatch({ type: "SELECT_VESSEL", payload: null });
		} else {
			this.setState({ vesselSelected: this.props.vesselname });
			this.props.dispatch({
				type: "SELECT_VESSEL",
				payload: null,
			});

			setTimeout(() => {
				this.props.dispatch({
					type: "SELECT_VESSEL",
					payload: this.props.vesselname,
				});
			}, 500);
		}
	};

	render() {
		return (
			<>
				<button
					ref={(ref: any) => {
						this.ref = ref;
					}}
					className={this.dynClass()}
					onClick={this.selectVessel}
				>
					{this.getImageLink() ? (
						<Image
							className={
								this.state.imageObj?.on_hire === true
									? "vessel-onhire vesselImage nodrag"
									: "vessel-notonhire vesselImage nodrag"
							}
							width={180}
							src={this.getImageLink()}
							preview={false}
							alt={this.getImageLink()}
						/>
					) : (
						<Empty />
					)}
				</button>
			</>
		);
	}
}
const mapStateToProps = (state: any) => {
	return {
		vessels: state.vessels.allVessels,
		selectedVessel: state.vessels.selectedVessel,
		vesselImgMap: state.vesselImgMap,
	};
};

export default connect(mapStateToProps)(VesselButton);
