import React, { useEffect, useRef, useState } from "react";

interface StaggeredItemProps {
	style?: React.CSSProperties;
	children?: React.ReactNode;
}

const StaggeredItem = ({ style, children }: StaggeredItemProps) => {
	const childRef = useRef<HTMLDivElement>(null);
	const [gridRowEnd, setGridRowEnd] = useState("span 10");

	useEffect(() => {
		if (childRef.current) {
			const height = Math.round(childRef.current.clientHeight / 10);
			setGridRowEnd(`span ${height}`);
			// console.log(height);
		}
	}, [childRef.current]);

	return (
		<div
			className="staggered-item"
			ref={childRef}
			style={{
				// width: "100%",
				gridRowEnd,
				height: "max-content",
			}}
		>
			{children}
		</div>
	);
};

export default StaggeredItem;
