import { api } from "../../contexts/AuthContext";
import { Tenant } from "./_exports";

export const getEmbedURL = (groupID: any, contentID: any, type: any) => {
	const data = {
		groupID: groupID,
		contentID: contentID,
		type: type,
	};
	return new Promise((resolve, reject) => {
		api.post(`/powerbi/getPBIEmbedURL`, data)
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err.response.data);
			});
	});
};

export const getEmbedToken = (
	user: any,
	groupID: any,
	contentID: any,
	type: any,
	datasetid: any,
	e_identity: any = false,
	paginated: any = false,
	roles: any = null
) => {
	const data: any = {
		role: `${Tenant}`,
		groupID: groupID,
		contentID: contentID,
		type: type,
		e_identity: e_identity,
		paginated: paginated,
		datasetid: datasetid,
		roles: roles,
	};

	try {
		data.email = user?.preferred_username;
	} catch (error: any) {
		console.log("Fail to decode access token");
	}
	return new Promise((resolve, reject) => {
		api.post(`/powerbi/getPBIEmbedToken`, data)
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err.response.data);
			});
	});
};

export const refreshData = () => {
	return new Promise((resolve, reject) => {
		api.get(`/refreshdata`)
			.then((res: any) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const refreshData2 = (datasetid: any, tablename: any) => {
	refreshData();
	const data = {
		datasetid: datasetid,
		tablename: tablename,
	};
	return new Promise((resolve, reject) => {
		api.post(`/powerbi/refreshdata`, data)
			.then((res: any) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getMasterdataKeys = (datasetid: any, tablename: any) => {
	const data = {
		datasetid: datasetid,
		tablename: tablename,
	};
	return new Promise((resolve, reject) => {
		api.post(`/powerbi/getpbikeys`, data)
			.then((res: any) => {
				resolve(res);
			})
			.catch((error: any) => {
				reject(error);
			});
	});
};

export const savePbiData = (datasetid: any, tablename: any) => {
	const data = {
		datasetid: datasetid,
		tablename: tablename,
	};
	return new Promise((resolve, reject) => {
		api.post(`/powerbi/addPbiData`, data)
			.then((res: any) => {
				resolve(res);
			})
			.catch((error: any) => {
				reject(error);
			});
	});
};

export const getPbiData = (datasetid: any, tablename: any) => {
	const data = {
		datasetid: datasetid,
		tablename: tablename,
	};
	return new Promise((resolve, reject) => {
		api.post(`/powerbi/getPbidata`, data)
			.then((res: any) => {
				resolve(res);
			})
			.catch((error: any) => {
				reject(error);
			});
	});
};
