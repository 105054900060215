import { api } from "../../contexts/AuthContext";

export const getViewableUrl = (vessel: string) => {
	return new Promise((resolve, reject) => {
		api.get(`/as-teg`, {
			params: {
				vessel: vessel,
			},
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getAssets = () => {
	return new Promise((resolve, reject) => {
		api.get(`/as-teg/assets`)
			.then((response) => {
				resolve(response.data.assets);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const generateToken = () => {
	return new Promise((resolve, reject) => {
		api.post(`/token`)
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
