import { Table, TableProps } from "antd";
import React, { useMemo } from "react";
import { capitalize } from "../utils/dataTools";

interface PreviewTableProps extends TableProps<any> {
	data: Array<any>;
	style?: React.CSSProperties;
}

const PreviewTable = ({ data, style, ...restProps }: PreviewTableProps) => {
	const datasource = useMemo(() => {
		return data.map((d: any, i: number) => {
			const { key, ...restProps } = d;
			let _d = {};
			for (const field in d) {
				if (typeof d[field] === "boolean") {
					_d = { ..._d, key: i, [field]: d[field] ? "true" : "false" };
				} else if (field === "key") {
					_d = { ..._d, key: i, "field-key": d[field] };
				} else {
					_d = { ..._d, key: i, [field]: d[field] };
				}
			}
			return _d;
		});
	}, [data]);

	const columns = useMemo(() => {
		if (data) {
			return generateColumns(data);
		}
		return;
	}, [data]);

	if (!data) return null;

	return (
		<Table
			dataSource={datasource}
			columns={columns}
			size="small"
			pagination={{
				pageSize: 8,
				showSizeChanger: false,
				style: { paddingRight: 16 },
			}}
			style={{ ...style }}
			rowClassName={"preview-table-row"}
			className="preview-table"
			tableLayout="fixed"
			scroll={{ x: "max-content" }}
			bordered
			{...restProps}
		/>
	);
};

const generateColumns = (data: Array<any>) => {
	if (data?.length === 0) return;
	const sampleData = data[0];
	const columns: any = [];

	for (const field in sampleData) {
		if (field === "key") {
			columns.push({
				dataIndex: `field-${field}`,
				key: `field-${field}`,
				title: `field-${field}`,
			});
		} else {
			columns.push({
				dataIndex: field,
				key: field,
				title: field,
			});
		}
	}

	return columns;
};

export default PreviewTable;
