import { Form, Radio, Select } from "antd";
import { useContext, useMemo } from "react";
import TargetsConfig from "./TargetsConfig";
import { RadioButtonProps } from "antd/es/radio/radioButton";
import { CustomDashboardContext } from "../contexts/context";
import useParameters from "../hooks/useParameters";
import { useComponent } from "../contexts/ComponentContext";

type TargetsConfigTabProps = {
	formValues?: any;
	setFormValues?: React.Dispatch<any>;
};

const TargetsConfigTab = ({
	formValues,
	setFormValues = () => {},
}: TargetsConfigTabProps) => {
	const { menuItem } = useComponent();

	const { state: customDashboardState } = useContext(CustomDashboardContext);

	const { parameters } = useParameters(menuItem.key);

	// using slicer params that are a type of array as options
	const getMultiplierOptions = useMemo(() => {
		const optionsArray = Object.entries(
			customDashboardState?.sliceValues
		)?.reduce((acc: any, [k, v]: any) => {
			if (Array.isArray(v)) {
				acc.push(k);
			}
			return acc;
		}, []);

		const prevParameters = optionsArray?.map((param: any) => ({
			label: param,
			value: param,
		}));

		return [...prevParameters];
	}, [customDashboardState?.sliceValues, parameters]);

	const getTargetUnitOptions = useMemo(() => {
		return [
			{
				label: "Time (hh:mm:ss)",
				value: "time",
			},
			{
				label: "Currency ($)",
				value: "dollar",
			},
			{
				label: <>Currency (&pound;)</>,
				value: "pound",
			},
			{
				label: "Percentage (%)",
				value: "percentage",
			},
		];
	}, []);

	return (
		<>
			<Form.Item>
				<TargetsConfig
					onChange={(targets) => {
						setFormValues((prevValues: any) => ({ ...prevValues, targets }));
					}}
					value={formValues?.targets}
					positiveIndicator={formValues?.indicator}
				/>
			</Form.Item>
			<Form.Item
				label="Target unit"
				labelCol={{ span: 4 }}
				labelAlign="left"
				name={"target_unit_type"}
			>
				<Radio.Group
					defaultValue={"default"}
					style={{ width: "100%" }}
					name="target_unit_type"
				>
					<MultiplierButton value={"default"}>Default</MultiplierButton>
					<MultiplierButton value={"custom"}>Custom</MultiplierButton>
				</Radio.Group>
			</Form.Item>
			{formValues?.target_unit_type === "custom" ? (
				<>
					<Form.Item
						label="Unit"
						labelCol={{ span: 4 }}
						labelAlign="left"
						wrapperCol={{ span: 6 }}
						name={"target_unit"}
					>
						<Select
							allowClear
							options={getTargetUnitOptions}
							placeholder={"Unit"}
						/>
					</Form.Item>
				</>
			) : null}
			<Form.Item
				label="Target multiplier"
				labelCol={{ span: 4 }}
				labelAlign="left"
				name={"target_multiplier"}
			>
				<Radio.Group style={{ width: "100%" }}>
					<MultiplierButton value={"none"}>None</MultiplierButton>
					<MultiplierButton value={"multiply"}>Multiply</MultiplierButton>
				</Radio.Group>
			</Form.Item>
			{formValues?.target_multiplier === "multiply" ? (
				<Form.Item
					label="Multiplier based on"
					labelCol={{ span: 4 }}
					labelAlign="left"
					wrapperCol={{ span: 6 }}
					name={"target_based_on"}
					dependencies={[]}
				>
					<Select
						allowClear
						options={getMultiplierOptions}
						placeholder="Multiplier"
					/>
				</Form.Item>
			) : null}
			<Form.Item
				label="Positive result indicator"
				labelCol={{ span: 4 }}
				labelAlign="left"
				name={"indicator"}
			>
				<Radio.Group>
					<Radio.Button style={{ borderRadius: "2px 0 0 2px" }} value={"above"}>
						Above
					</Radio.Button>
					<Radio.Button style={{ borderRadius: "0 2px 2px 0" }} value={"below"}>
						Below
					</Radio.Button>
				</Radio.Group>
			</Form.Item>
		</>
	);
};

export const MultiplierButton = ({
	children,
	...restProps
}: RadioButtonProps) => {
	return (
		<Radio.Button
			{...restProps}
			style={{
				borderRadius: "2px 0 0 2px",
				textAlign: "center",
			}}
		>
			{children}
		</Radio.Button>
	);
};

export default TargetsConfigTab;
