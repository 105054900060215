import React from "react";
import {
	searchVesselSVG,
	accessVesselSVG,
	navigateSVG,
} from "../../components/Svg";
import { Button, Checkbox, Divider, Modal, Space } from "antd";

const TutorialModal = ({
	tutorial,
	noShow,
	setNoShow,
	setTutorialModal,
	menuKeyRef,
	setMenuKey,
	OpenCloseLeftDrawer,
	canvasRef,
	setTutorial,
	tutorialModal,
}: any) => {
	const setTutorialContent = () => {
		switch (tutorial) {
			case 1:
				return (
					<div style={{ paddingTop: "24px" }}>
						<div style={{ display: "grid", gap: "36px" }}>
							<div
								style={{
									fontWeight: "400",
									fontSize: "30px",
									lineHeight: "40px",
								}}
							>
								Digital Twin - Stena Carron
							</div>
							<div
								style={{
									fontWeight: "400",
									fontSize: "22px",
									lineHeight: "1.25",
								}}
							>
								Welcome! To maximise your experience navigating Digital Twin, we
								have prepared a quick-start guide to get you up and running
							</div>
							<div
								style={{
									fontWeight: "400",
									fontSize: "16px",
									lineHeight: "1.25",
								}}
							>
								Please note that the Digital Twin service enables you to access
								data from various isolated systems in the context of real-time
								operations. It is important to understand that you are not
								granted the ability to control Stena Carron or any of the
								connected systems from within this service
							</div>
						</div>
						<Divider />
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Checkbox
								checked={noShow}
								onChange={(e: any) => {
									setNoShow(e.target.checked);
								}}
							>
								Do not show this again
							</Checkbox>
							<Space style={{ marginLeft: "auto" }}>
								{/* <Button
									onClick={() => {
										setTutorialModal(false);
										if (noShow) {
											localStorage.setItem("skipTutorial", "true");
										}
									}}
								>
									Skip
								</Button> */}
								<Button
									onClick={() => {
										// setTutorial(2);
										setTutorialModal(false);
										menuKeyRef.current = "Help";
										setMenuKey("Help");
										OpenCloseLeftDrawer(true);

										if (noShow) {
											localStorage.setItem("skipTutorial", "true");
										}
									}}
								>
									Continue
								</Button>
							</Space>
						</div>
					</div>
				);
			case 2:
				return (
					<div>
						<div
							style={{
								fontWeight: "400",
								fontSize: "30px",
							}}
						>
							Navigating the 3D Scene
						</div>

						<Divider />
						<Space style={{ display: "flex", justifyContent: "flex-end" }}>
							{/* <Button
								onClick={() => {
									setTutorialModal(false);
								}}
							>
								Skip
							</Button> */}
							<Button
								onClick={() => {
									// setTutorial(3);
									// setMenuKey(null);
									// setLeftModal(false);
									// setTimeout(() => {
									// 	setMenuKey("Asset");
									// 	setLeftModal(true);
									// }, 1);
									setTutorialModal(false);
									canvasRef.current.focus();
								}}
							>
								Finish
							</Button>
						</Space>
					</div>
				);
			case 3:
				return (
					<>
						<div>
							<div
								style={{
									fontWeight: "400",
									fontSize: "30px",
								}}
							>
								Discover the Vessel Assets and Properties
							</div>

							<Divider />
							<Space style={{ display: "flex", justifyContent: "flex-end" }}>
								<Button
									onClick={() => {
										setTutorialModal(false);
									}}
								>
									Skip
								</Button>
								<Button
									onClick={() => {
										setTutorial(4);
										setMenuKey(null);
										menuKeyRef.current = "";
										OpenCloseLeftDrawer(false);
									}}
								>
									Continue
								</Button>
							</Space>
						</div>
						<div style={{ position: "fixed", top: 0, left: 0 }}>
							<div style={{ position: "fixed", top: "300px", left: "500px" }}>
								{navigateSVG()}
							</div>
							<div
								style={{
									position: "fixed",
									top: "70px",
									left: "50%",
									transform: "translateX(-415px)",
								}}
							>
								{searchVesselSVG()}
							</div>
							<div style={{ position: "fixed", top: "170px", left: "20px" }}>
								{accessVesselSVG()}
							</div>
							{/* <div>{discoverVesselPropertiesSVG()}</div> */}
						</div>
					</>
				);
			case 4:
				return (
					<div style={{ paddingTop: "24px" }}>
						<div style={{ display: "grid", gap: "36px" }}>
							<div
								style={{
									fontWeight: "400",
									fontSize: "30px",
									lineHeight: "40px",
								}}
							>
								Tutorial Completed!
							</div>
							<div
								style={{
									fontWeight: "400",
									fontSize: "16px",
									lineHeight: "22px",
								}}
							>
								Congratulations! You have successfully completed the tutorial
								and are now prepared to fully utilise the Digital Twin
							</div>
							<div
								style={{
									fontWeight: "400",
									fontSize: "16px",
									lineHeight: "22px",
								}}
							>
								If you ever need a refresher, you can always access the tutorial
								from the "Actions" menu in the upper right corner. Happy
								exploring!
							</div>
						</div>
						<Divider />
						<Space style={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								onClick={() => {
									setTutorialModal(false);
								}}
							>
								Got it!
							</Button>
						</Space>
					</div>
				);
			default:
				return <></>;
		}
	};

	return (
		<Modal
			// destroyOnClose
			width={800}
			onCancel={() => {
				setTutorialModal(false);
			}}
			style={
				tutorial === 2 || tutorial === 3
					? { opacity: "0.90", left: "300px" }
					: { opacity: "0.90" }
			}
			maskClosable={false}
			open={tutorialModal}
			centered
			closable={false}
			footer={null}
		>
			{setTutorialContent()}
		</Modal>
	);
};

export default TutorialModal;
