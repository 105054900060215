//Login Logos
import Exxon_loginlogo from "./../assets/img/xom.png";
import Chevron_loginlogo from "./../assets/img/Chevron.png";
import StenaDrilling_loginlogo from "./../assets/img/Stena_Drilling_Logo_Dark.png";
import StenaEvolve_loginlogo from "./../assets/img/StenaEvolve_Flag_RGB.png";
import Repsol_loginlogo from "./../assets/img/rsr.png";
import Shell_loginlogo from "./../assets/img/Shell_logo.png";

//Home Logos
import Exxon_logo from "./../assets/img/xom.png";
import Chevron_logo from "./../assets/img/Chevron-home.png";
import StenaDrilling_logo from "./../assets/img/Stena-Drilling.png";
import Repsol_logo from "./../assets/img/rsr-white.png";
import StenaEvolve_logo from "./../assets/img/StenaEvolve_horizontal_white.png";
import Shell_logo from "./../assets/img/Shell_logo.png";
import { Tenant } from "../services/api-server/_exports";

export const setLoginLogo = () => {
	switch (Tenant) {
		case "exxonmobil":
			return (
				<img
					className="company-logo"
					style={{ width: "150px" }}
					src={Exxon_loginlogo}
					alt="Logo"
				/>
			);
		case "chevron":
			return (
				<img
					className="company-logo"
					style={{ width: "100px" }}
					src={Chevron_loginlogo}
					alt="Logo"
				/>
			);
		case "stenadrilling":
			return (
				<img
					className="company-logo"
					style={{ width: "200px", position: "relative", left: "-10px" }}
					src={StenaDrilling_loginlogo}
					alt="Logo"
				/>
			);
		case "repsolsinopec":
			return (
				<img
					className="company-logo"
					style={{ width: "150px" }}
					src={Repsol_loginlogo}
					alt="Logo"
				/>
			);
		case "shell":
			return (
				<img
					className="company-logo"
					style={{ width: "100px" }}
					src={Shell_loginlogo}
					alt="Logo"
				/>
			);
		case "preproduction":
		case "staging":
			return (
				<img
					className="company-logo"
					style={{ width: "200px", position: "relative", left: "-10px" }}
					src={StenaEvolve_loginlogo}
					alt="Logo"
				/>
			);
		default:
			return null;
	}
};

export const setHomeLogo = () => {
	switch (Tenant) {
		case "exxonmobil":
			return (
				<img
					className="home-logo"
					style={{ width: "200px" }}
					src={Exxon_logo}
					alt="Home_logo"
				/>
			);
		case "chevron":
			return (
				<img
					className="home-logo"
					style={{ width: "200px" }}
					src={Chevron_logo}
					alt="Home_logo"
				/>
			);
		case "stenadrilling":
			return (
				<img
					className="home-logo"
					style={{ width: "225px" }}
					src={StenaDrilling_logo}
					alt="Home_logo"
				/>
			);
		case "repsolsinopec":
			return (
				<img
					className="home-logo"
					style={{ width: "175px" }}
					src={Repsol_logo}
					alt="Home_logo"
				/>
			);
		case "shell":
			return (
				<img
					className="home-logo"
					style={{ width: "50px" }}
					src={Shell_logo}
					alt="Logo"
				/>
			);
		case "preproduction":
		case "staging":
			return (
				<img
					className="home-logo"
					style={{ width: "150px" }}
					src={StenaEvolve_logo}
					alt="Logo"
				/>
			);

		default:
			return <></>;
	}
};

export const setDrawerlogo = (logo_link: any) => {
	if (logo_link) {
		return (
			<img
				className="drawer-logo"
				style={{ width: "90px", marginTop: "5px" }}
				src={logo_link}
				alt="Drawer_logo"
			/>
		);
	} else {
		return <></>;
	}
};
