import {
	Badge,
	ConfigProvider,
	Modal,
	ModalProps,
	Space,
	Spin,
	Tabs,
	TabsProps,
	theme,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import {
	getAppGroupMembers,
	getApplicationGroups2,
	getAppRoles2,
	getAppUser,
	getUserAppRoleAssignments,
} from "../../services/api-server/admin";
import _ from "lodash";
import { PERMISSION } from "../../reducers/customDashboardReducer";
import { saveMenu } from "../../services/api-server/menu";
import Emitter from "../../services/EventEmitter";
import AccessRequestTab from "../AccessRequestTab";
import { Action, getActions } from "../../services/api-server/actions";
import {
	findParent,
	getComponentRoles,
	removeComponentViewer,
	setComponentViewer,
} from "../../utils/utils";
import useMenu from "../../hooks/useMenu";
import AddViewerModal from "./AddViewerModal";
import { updateAccessRequest } from "../../services/api-server/access_requests";
import { socket } from "../../utils/socket";
import { useAccessRequestContext } from "../../contexts/AccessRequestContext";
import UserPermissions from "../UserPermissions";
import OverviewTab from "./OverviewTab";
import ParametersTab from "../ParametersTab";
import { useSelector } from "react-redux";
import useUrlQuery from "../../hooks/useUrlQuery";

interface ConfigurationManagerProps extends ModalProps {
	menuItem?: any;
	readonly?: boolean;
	onSave?: () => void;
	accessRequests?: Array<Partial<AccessRequest>>;
	parameters?: Array<Parameter>;
}

const ComponentManager = ({
	menuItem,
	readonly = true,
	onCancel = () => {},
	onSave = () => {},
	parameters,
	...restProps
}: ConfigurationManagerProps) => {
	const { title = "", key: menuKey = "" } = menuItem;
	const {
		accessRequests,
		loading: requestsLoading,
		setOwners,
		setRoleViewers,
		setViewers,
	} = useAccessRequestContext();

	const [compUsers, setCompUsers] = useState<Array<any>>([]);
	const [groupModal, setGroupModal] = useState(false);
	const [description, setDescription] = useState(menuItem?.description);

	const { menu } = useMenu();

	const [saveLoading, setSaveLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [dirty, setDirty] = useState(false);
	const query = useUrlQuery();

	const [firstActiveTab, setfirstActiveTab] = useState(
		query.get("am") === "true" ? "permission" : "overview"
	);
	const [secondActiveTab, setSecondActiveTab] = useState(
		query.get("am") === "true" ? "request" : "general"
	);

	const [searchVal, setSearchVal] = useState("");
	const user = useSelector((state: any) => state?.user);

	const handleAddViewers = useCallback((viewers: any) => {
		setDirty(true);
		setGroupModal(false);
		const viewersWithPermission = viewers.map((viewer: any) => ({
			...viewer,
			permissions: PERMISSION.VIEWER,
			removable: true,
		}));

		setCompUsers((prev: any) => [...prev, ...viewersWithPermission]);
	}, []);

	const handleRemoveViewer = async (id: string) => {
		setDirty(true);

		setCompUsers((prev: Array<any>) => prev.filter((user) => user.id !== id));
	};

	/**
	 * Owners here is refering to the users that was assigned to be component owner
	 * from site admin
	 */
	const getOwners = useCallback(async (owners: any = []) => {
		const ownerPromises = owners?.map(async (o: string) => {
			try {
				const user = await getAppUser(o);
				return {
					...user,
					permissions: PERMISSION.OWNER,
					removable: false,
				};
			} catch (error) {
				return;
			}
		});

		// Filtering deleted user from results
		return (await Promise.all(ownerPromises)).filter((o) => o);
	}, []);

	const getViewers = async (viewers: any = []) => {
		const viewerPromises = viewers.map(async (v: string) => {
			try {
				const user = await getAppUser(v);
				return {
					...user,
					permissions: PERMISSION.VIEWER,
					removable: true,
				};
			} catch (error) {
				return;
			}
		});

		// Filtering deleted user from results
		return (await Promise.all(viewerPromises)).filter((v) => v);
	};

	const handleSave = async () => {
		setLoading(true);

		const root = findParent(menu, menuKey);

		// finding viewers that were manually added or removed by component owner
		const cViewers = _.uniq(
			compUsers
				?.filter(
					(user: any) => user.permissions === PERMISSION.VIEWER && !user.team
				)
				.map((user: any) => user?.id)
		);

		const traverse = (mItem: any) => {
			if (mItem?.key === menuKey) {
				// * Filter user from menu owner if owner
				const cOwners = mItem.owners.filter(
					(user: string) => !cViewers.includes(user)
				);

				return { ...mItem, viewers: cViewers, owners: cOwners, description };
			}
			if (mItem?.children && mItem?.children?.length !== 0) {
				const updatedChildren = mItem?.children?.map((child: any) =>
					traverse(child)
				);
				return { ...mItem, children: updatedChildren };
			}

			return mItem;
		};

		if (!root) return;
		let updatedRoot = { ...root };

		if (root.key === menuKey) {
			// * Filter user from menu owner if owner
			const cOwners = root.owners.filter(
				(user: string) => !cViewers.includes(user)
			);
			updatedRoot = {
				...root,
				viewers: cViewers,
				owners: cOwners,
				description,
			};
		}

		if (root?.children && root?.children?.length !== 0) {
			updatedRoot = { ...root, children: root?.children?.map(traverse) };
		}

		const updatedMenu = menu?.map((mItem: any) => {
			const { key } = mItem;
			const { key: rootKey } = updatedRoot;

			if (key === rootKey) return updatedRoot;
			return mItem;
		});

		try {
			const roles: any = await getComponentRoles(menuItem);
			const viewerRole = roles?.find((role: any) =>
				role.name.includes("viewer")
			);
			const prevViewers: Array<any> = menuItem?.viewers;
			const currViewers: Array<any> = cViewers;

			const grantedViewers = currViewers?.filter(
				(el) => !prevViewers?.includes(el)
			);

			const revokedViewers = prevViewers?.filter(
				(el) => !currViewers?.includes(el)
			);

			if (grantedViewers?.length > 0) {
				const promises = grantedViewers?.map((viewer: any) =>
					setComponentViewer(
						viewer,
						viewerRole,
						user?.name,
						roles,
						menuItem.key
					)
				);

				await Promise.all(promises);

				for (const viewer of grantedViewers) {
					const request = accessRequests.find(
						(_request) =>
							_request.requesterId === viewer &&
							_request.status === "Requested" &&
							_request.menuKey === menuKey
					);

					if (request) {
						await updateAccessRequest({ ...request, status: "Approved" });
						socket.emit("ACCESS_REQUEST_STATUS_CHANGED");
					}
				}
			}

			if (revokedViewers?.length > 0) {
				const promises = revokedViewers?.map((viewer: any) =>
					removeComponentViewer(viewer, viewerRole, user?.name, menuKey)
				);

				await Promise.all(promises);
			}

			await saveMenu(updatedMenu, menuKey);

			await dataFetching();

			setSaveLoading(false);
			setDirty(false);

			Emitter.emit("alert", {
				type: "success",
				message: "Dashboard configuration updated",
				description: "You have successfully updated dashboard configuration",
				timeout: 5000,
			});
			socket.emit("UPDATE_MENU", menuKey);

			// Emitter.emit("MenuSaved", true);
			// socket.emit("CONF_SAVE", "howdy");
			// setGroupModal(false);
			onSave();
		} catch (err) {
			console.error(err);
		}
	};

	const handleDescriptionChange = (changedValue: any) => {
		setDirty(true);
		setDescription(changedValue["description"]);
	};

	const handleApprove = async () => {
		await dataFetching();
		setSaveLoading(false);
		setDirty(false);
	};

	const renderTab = (tab: string) => {
		switch (tab) {
			case "general":
				return (
					<UserPermissions
						key={"user_permissions"}
						menuItem={menuItem}
						openAddViewersModal={() => setGroupModal(true)}
						readonly={readonly}
						users={compUsers}
						onRemoveViewer={handleRemoveViewer}
					/>
				);
			case "accessRequests":
				return (
					<AccessRequestTab
						key={"access_request"}
						readonly={readonly}
						menuItem={menuItem}
						onApprove={handleApprove}
						users={compUsers}
					/>
				);
			default:
		}
	};

	const handleTabChange = (tab: any) => {
		setfirstActiveTab(tab);
	};

	const defaultTabItems: TabsProps["items"] = [
		{
			key: "general",
			label: "User permissions",
			children: renderTab("general"),
		},
	];

	const permissionTabItems = readonly
		? defaultTabItems
		: [
				...defaultTabItems,
				{
					key: "request",
					label: (
						<Space>
							Requests
							<Badge
								styles={{
									indicator: {
										background: "#111D2C",
										color: "#177DDC",
										boxShadow: "none",
									},
								}}
								style={{
									display:
										!readonly &&
										accessRequests.filter(
											(_request) => _request.status === "Requested"
										).length !== 0
											? "inherit"
											: "none",
								}}
								count={
									accessRequests?.filter(
										(request) => request.status === "Requested"
									).length
								}
							/>
						</Space>
					),
					children: renderTab("accessRequests"),
				},
		  ];

	const tabItems = [
		{
			key: "overview",
			label: "Overview",
			children: (
				<OverviewTab
					title={title}
					readonly={readonly}
					description={description}
					onChange={handleDescriptionChange}
				/>
			),
		},
		{
			key: "permission",
			label: (
				<Space>
					Permissions
					<Badge
						styles={{
							indicator: {
								background: "#111D2C",
								color: "#177DDC",
								boxShadow: "none",
							},
						}}
						style={{
							display:
								!readonly &&
								accessRequests.filter(
									(_request) => _request.status === "Requested"
								).length !== 0
									? "inherit"
									: "none",
						}}
						count={
							accessRequests.filter(
								(_request) => _request.status === "Requested"
							).length
						}
					/>
				</Space>
			),
			children: (
				<Tabs
					style={{ paddingRight: 16 }}
					tabPosition="left"
					activeKey={secondActiveTab}
					items={permissionTabItems}
					onChange={setSecondActiveTab}
				/>
			),
		},
	];

	// handling only app roles that was assigned to this components
	const fetchRoleViewers = async () => {
		try {
			// all application roles object
			const appRoles: any = await getAppRoles2();

			// Filtering all app roles to only include the app roles that was assigned to the component in menu manager
			const filteredRoles: any = appRoles.filter((role: any) =>
				menuItem?.roles?.includes(role?.value)
			);

			const adminRole = appRoles?.find(
				(role: any) => role?.value === process.env.REACT_APP_SUPER_ADMIN_TAG
			);

			const groups: any = await getApplicationGroups2();

			// only take groups that are assigned to selected roles by portal admin
			const filteredGroups: any = groups.filter((group: any) => {
				return filteredRoles.find((role: any) => role.id === group.appRoleId);
			});

			const componentUsers: Array<any> = [];

			for (const group of filteredGroups) {
				const members: any = await getAppGroupMembers(group.principalId);
				const roleAssigned = appRoles?.find(
					(role: any) => role.id === group.appRoleId
				);

				const promises = members?.map(async (member: any) => {
					const assignments: any = await getUserAppRoleAssignments(member.id);
					const isAdmin = assignments?.find(
						(assignment: any) => assignment?.appRoleId === adminRole.id
					);
					const isOwner = menuItem?.owners?.includes(member.id);

					return !isAdmin && !isOwner
						? {
								...member,
								groupId: group.principalId,
								groupDisplayName: group.principalDisplayName,
								team: roleAssigned?.displayName,
								permissions: PERMISSION.VIEWER,
								removable: false,
						  }
						: null;
				});

				const filteredMembers = (await Promise.all(promises)).filter(Boolean);
				componentUsers.push(...filteredMembers);
			}
			return componentUsers;
		} catch (err) {
			throw err;
		}
	};

	const dataFetching = async () => {
		try {
			setLoading(true);

			let [owners, roleViewers, viewers, actions] = await Promise.all([
				getOwners(menuItem?.owners),
				fetchRoleViewers(),
				getViewers(menuItem?.viewers),
				getActions(menuItem?.key),
			]);

			setDescription(description || "");

			// owner takes first priority
			owners = owners?.map((user: any) => {
				const acs = actions.filter(
					(action: any) => action?.user_id === user?.id
				);
				const latestAction = getLatest(acs, "Owner");
				if (latestAction) {
					const { approvedBy, approvedDate } = latestAction;
					return { ...user, approvedBy, approvedDate };
				}

				return { ...user };
			});

			// lowest priority, only display user as viewers if they are not owners or role viewers
			viewers = viewers
				?.filter((user: any) => {
					const foundOwner = owners?.find(
						(owner: any) => owner?.id === user?.id
					);
					return foundOwner === undefined;
				})
				?.map((user: any) => {
					const acs = actions.filter(
						(action: any) => action?.user_id === user?.id
					);
					const latestAction = getLatest(acs, "Viewer");
					if (latestAction) {
						const { approvedBy, approvedDate } = latestAction;
						return { ...user, approvedBy, approvedDate };
					}
					return { ...user };
				});

			// filter those viewers if they are component owners
			// second priority
			roleViewers = roleViewers?.map((user: any) => {
				const acs = actions.filter(
					(action: any) => action.user_id === user.team
				);
				const latestAction = getLatest(acs, "Viewer");
				if (latestAction) {
					const { approvedBy, approvedDate } = latestAction;
					return { ...user, approvedBy, approvedDate };
				}
				return { ...user };
			});

			let allUsers: any = [...owners, ...viewers, ...roleViewers];

			setOwners(owners);
			setViewers(viewers);
			setRoleViewers(roleViewers);
			setCompUsers(allUsers);
			setLoading(false);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		dataFetching();
	}, [menuItem]);

	return menuItem ? (
		<ConfigProvider theme={{ algorithm: theme.darkAlgorithm, inherit: true }}>
			<Modal
				{...restProps}
				width={"85%"}
				title="Settings"
				okText="Save"
				maskClosable={false}
				destroyOnClose
				onOk={handleSave}
				okButtonProps={{
					disabled: !dirty,
					loading: saveLoading || requestsLoading,
				}}
				footer={
					// !readonly ? (activeTab === "request" ? false : undefined) : false
					readonly ? false : undefined
				}
				onCancel={(e) => {
					onCancel(e);
					setfirstActiveTab("overview");
				}}
				styles={{
					header: {
						padding: "16px 24px",
					},
					body: {
						padding: "0 0 16px 0",
					},
					footer: {
						padding: !readonly ? undefined : 0,
					},
				}}
			>
				<Spin spinning={loading || requestsLoading}>
					<Tabs
						activeKey={firstActiveTab}
						onChange={setfirstActiveTab}
						tabBarStyle={{ padding: "0 16px" }}
						items={
							!readonly
								? [
										...tabItems,
										{
											key: "parameters_tab",
											label: "Parameters",
											children: (
												<ParametersTab
													readonly={readonly}
													parameters={parameters}
													menuKey={menuItem?.key}
												/>
											),
										},
								  ]
								: tabItems
						}
					/>
				</Spin>
			</Modal>
			<AddViewerModal
				open={groupModal}
				onAddViewers={handleAddViewers}
				defaultUsers={compUsers}
				onCancel={() => setGroupModal(false)}
			/>
		</ConfigProvider>
	) : null;
};

const getLatest = (actions: Action[], permission: "Viewer" | "Owner") => {
	return actions.reduce((maxDateItem: Action | null, currentItem) => {
		const currentDate = new Date(currentItem.approvedDate);
		const maxDate = maxDateItem ? new Date(maxDateItem.approvedDate) : null;

		if (
			!maxDate ||
			(currentDate > maxDate &&
				(permission === "Owner"
					? currentItem.actionType === "OWNERSHIP_GRANTED"
					: currentItem.actionType === "VIEWERSHIP_GRANTED"))
		) {
			return currentItem;
		} else {
			return maxDateItem;
		}
	}, null);
};

export default ComponentManager;
