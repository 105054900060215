import { useState, useEffect } from "react";

const useSize2 = (id: any) => {
	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);

	let layout: any = document.getElementById(id);
	let card = layout?.getElementsByClassName("visual-card")[0];
	let children = card?.children;
	let header = children?.item(0);
	// let body = children?.item(1);
	const { height: layoutHeight, width: layoutWidth } =
		layout?.getBoundingClientRect() || { height, width };
	const { height: headerHeight, width: headerWidth } =
		header?.getBoundingClientRect() || { height, width };

	let _width = layoutWidth - headerWidth;
	let _height = layoutHeight - headerHeight;

	useEffect(() => {
		setWidth(_width);
		setHeight(_height);
	}, [_width, _height]);

	return { height, width };
};

export default useSize2;
