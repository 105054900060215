import { Tenant } from "./_exports";
import dayjs from "dayjs";
import { api } from "../../contexts/AuthContext";
import store from "../../state/store";

export const submitRequest = (request: Partial<AccessRequest>) => {
	const endpoint = `/access_requests/createRequest`;
	const { menuKey } = request;

	return new Promise((resolve, reject) => {
		api.post(endpoint, { request, menuKey, tenantid: Tenant })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => reject(err));
	});
};

export const getAccessRequests = (): Promise<Partial<AccessRequest>[]> => {
	const endpoint = `/access_requests/getAccessRequests`;

	return new Promise((resolve, reject) => {
		api.get(endpoint)
			.then((response) => {
				if (response.data && Array.isArray(response.data)) {
					const sorted = response.data.sort((a, b) =>
						dayjs(b.date).diff(dayjs(a.date))
					);
					resolve(sorted);
				} else {
					resolve([]);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const updateAccessRequest = (request: Partial<AccessRequest>) => {
	const { menuKey } = request;
	const { user } = store.getState();

	return new Promise((resolve, reject) => {
		const endpoint = `/access_requests/updateRequest`;

		request.modifiedBy = user?.name || "";
		api.put(endpoint, {
			request,
			menuKey,
			tenantid: Tenant,
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
