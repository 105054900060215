import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import store from "./state/store";
import { ErrorBoundary } from "react-error-boundary";
import { sendErrorNotification } from "./utils/utils";
import { Image } from "antd";
import { AuthProvider } from "./contexts/AuthContext";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const handleErrorFallback = (props: any) => {
	return (
		<div
			style={{
				height: "100vh",
				width: "100vw",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			className="site-whole"
		>
			<div style={{ background: "none" }} className="error-background"></div>
			<div className="error-center-container">
				<div className="hexlock-container">
					<div className="hexlock"></div>
				</div>
				<span
					style={{
						fontSize: "4svh",
						lineHeight: "2",
						textAlign: "center",
						color: "white",
					}}
				>
					An unexpected error has occurred, please try again later
				</span>
				<span
					style={{ fontSize: "2svh", lineHeight: "1", textAlign: "center" }}
				>
					Contact support:{" "}
					<a href="mailto: portal-support@stena-evolve.com">
						portal-support@stena-evolve.com
					</a>
				</span>
			</div>
		</div>
	);
};

const logError = (error: any, info: any) => {
	if (process.env.NODE_ENV === "production") {
		error.location = window.location.pathname;
		error.level = "root";
		let JSONerror = JSON.stringify(error, Object.getOwnPropertyNames(error));
		sendErrorNotification(JSONerror);
	}
};

root.render(
	<ErrorBoundary fallbackRender={handleErrorFallback} onError={logError}>
		<Provider store={store}>
			<AuthProvider>
				<App />
			</AuthProvider>
		</Provider>
	</ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
