import React from "react";
import Portal_logo from "../assets/img/Portal_logo_RGB_whitetext.png";
import StenaEvolve_logo from "../assets/img/StenaEvolve_horizontal_white.png";
import ReactTooltip from "react-tooltip";
import "../assets/css/LoginPage.css";
import { Layout, Button, Input, ConfigProvider, theme } from "antd";
import { checkMobile } from "../utils/utils";
import { setLoginLogo } from "../utils/company_logo";
import { connect } from "react-redux";
import { getClientLogo } from "../services/api-server/clients";
import { url, Tenant } from "../services/api-server/_exports";

const { Content } = Layout;

export class LoginPage extends React.Component<any> {
	state: any = {
		hideNav: null,
		Mobileview: null,
		email: "",
		password: "",
		tenant: Tenant?.toUpperCase(),
		logo: null,
	};

	componentDidMount() {
		// if (
		// 	localStorage.getItem(`${Tenant}:idToken`) &&
		// 	localStorage.getItem(`${Tenant}:idToken`) !== undefined
		// ) {
		// 	window.location.href = `/${Tenant}`;
		// }
		if (!this.state.logo) {
			getClientLogo().then((data: any) => {
				this.setState({ logo: data.data });
			});
		}

		window.addEventListener("resize", this.resize);
		this.resize();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
		//this.user.unsubscribe()
	}

	setLoginLogoPBI = () => {
		return (
			<img
				className="company-logo"
				style={{ width: "100px" }}
				src={this.state.logo}
				alt="Logo"
			/>
		);
	};

	resize = () => {
		// console.log(checkMobile())
		// console.log('inner width', window.innerWidth)
		// console.log(window.screen.orientation)
		let hideNav = window.innerWidth <= 640 || window.innerHeight <= 400;
		this.setState({ hideNav: hideNav });
		//console.log('hideNav',hideNav)
		if (checkMobile()) {
			this.Mobileview(true);
		} else {
			this.Mobileview(false);
		}
	};

	Mobileview(mobileview: any) {
		this.setState({ Mobileview: mobileview });
	}

	render() {
		const AzureLogin = () => {
			window.location.href = `${url}/login`;
		};

		const NormalLogin = () => {
			if (this.state.email === "" || this.state.password === "") {
				alert("email and password must not be empty");
			} else if (
				this.state.email === process.env.REACT_APP_USER &&
				this.state.password === process.env.REACT_APP_USER_PW
			) {
				localStorage.setItem(`${Tenant}:idToken`, "1");
				window.location.href = `/${Tenant}/home/dashboard`;
			} else if (
				this.state.email === process.env.REACT_APP_ADMIN &&
				this.state.password === process.env.REACT_APP_ADMIN_PW
			) {
				localStorage.setItem(`${Tenant}:idToken`, "2");
				window.location.href = `/${Tenant}/home/dashboard`;
			} else {
				alert("Invalid credentials");
			}
		};
		return (
			<>
				{!this.props.ismobile ? (
					<Layout className="loginBody">
						<Content>
							<div className="container">
								<div className="left-container">
									<div className="container-center">
										<div className="display-container">
											<img
												className="portal-login-logo"
												src={Portal_logo}
												alt="Logo"
											/>
											<span className="slogan">One Team, One Platform</span>
										</div>
									</div>
									<div className="footer-login">
										<span className="footer-text">
											Copyright <span style={{ fontSize: "18px" }}>©</span>{" "}
											{new Date().getFullYear()}.
										</span>
										<img
											className="footer-logo"
											src={StenaEvolve_logo}
											alt="Company Logo"
										/>
									</div>
								</div>
								<div className="login">
									<div className="logincontainer">
										{setLoginLogo() ? setLoginLogo() : this.setLoginLogoPBI()}

										<div
											style={{
												padding: "0 0",
												marginBottom: "12px",
												fontSize: "32px",
												textAlign: "left",
												color: "#000000",
											}}
										>
											Login to your account{" "}
										</div>
										{/* <form
											style={{
												display: "table",
												textAlign: "left",
												width: "100%",
												borderSpacing: "0px 10px",
											}}
										>
											<p style={{ display: "table-row" }}>
												<span>E-mail</span>
											</p>
											<p style={{ display: "table-row" }}>
												<Input
													id="a"
													style={{ display: "table-cell" }}
													type="text"
													name="name"
													onChange={(event) =>
														this.setState({ email: event.target.value })
													}
												/>
											</p>
											<p style={{ display: "table-row" }}>
												<span>Password</span>
											</p>
											<p style={{ display: "table-row" }}>
												<Input
													style={{ display: "table-cell" }}
													type="password"
													name="password"
													onChange={(event) =>
														this.setState({ password: event.target.value })
													}
												/>
											</p>
										</form> */}
										<form
											style={{
												display: "table",
												width: "100%",
												borderSpacing: "0px 10px",
											}}
										>
											{/* <p style={{ marginTop: "1%" }}>
												<Button
													className="button-login-normal"
													onClick={NormalLogin}
												>
													{" "}
													Sign In{" "}
												</Button>
											</p>
											<p
												style={{
													width: "100%",
													height: "20px",
													marginBottom: "10%",
													borderBottom: "1px solid rgba(0,0,0,0.3)",
													textAlign: "center",
												}}
											>
												<span
													style={{
														fontSize: "20px",
														backgroundColor: "#ffffff",
														padding: "10px 20px",
													}}
												>
													or
												</span>
											</p> */}
											<p>
												<ConfigProvider
													theme={{ algorithm: theme.defaultAlgorithm }}
												>
													<Button
														className="button-login-normal"
														onClick={AzureLogin}
													>
														Login via Stena AD
													</Button>
												</ConfigProvider>
											</p>
										</form>
										<span
											style={{
												display: "flex",
												justifyContent: "left",
												fontWeight: "bold",
												textDecoration: "underline",
												maxWidth: "fit-content",
											}}
											data-tip="Contact Support"
										>
											<a href="mailto:portal-support@stena-evolve.com?subject=Portal Assistance">
												Contact Us
											</a>
										</span>
										<ReactTooltip />
									</div>
								</div>
							</div>
						</Content>
					</Layout>
				) : (
					<div className="potraitmode">
						<Layout className="mLoginBody">
							<Content>
								<div className="mcontainer">
									<div className="mcontainer-center">
										<div className="mdisplay-container">
											<img
												className="mportal-login-logo"
												src={Portal_logo}
												alt="Logo"
											/>
											<span className="slogan-mobile">
												One Team, One Platform
											</span>
										</div>
										<div className="mlogin">
											<div className="mlogincontainer">
												<div
													style={{ display: "flex", justifyContent: "center" }}
												>
													{setLoginLogo()
														? setLoginLogo()
														: this.setLoginLogoPBI()}
												</div>
												<div
													style={{
														padding: "0 0",
														marginBottom: "12px",
														fontSize: "6vw",
														textAlign: "left",
													}}
												>
													Login to your account
												</div>
												<form
													style={{
														display: "table",
														textAlign: "left",
														width: "100%",
														borderSpacing: "0px 10px",
													}}
												>
													<p style={{ display: "table-row" }}>
														<span>E-mail</span>
													</p>
													<p style={{ display: "table-row" }}>
														<Input
															id="a"
															style={{ display: "table-cell" }}
															type="text"
															name="name"
															onChange={(event) =>
																this.setState({ email: event.target.value })
															}
														/>
													</p>
													<p style={{ display: "table-row" }}>
														<span>Password</span>
													</p>
													<p style={{ display: "table-row" }}>
														<Input
															style={{ display: "table-cell" }}
															type="password"
															name="password"
															onChange={(event) =>
																this.setState({ password: event.target.value })
															}
														/>
													</p>
												</form>
												<form
													style={{
														display: "table",
														width: "100%",
														borderSpacing: "0px 10px",
													}}
												>
													<p style={{ marginTop: "1%" }}>
														<Button
															className="button-login-normal"
															onClick={NormalLogin}
														>
															{" "}
															Sign In{" "}
														</Button>
													</p>
													<p
														style={{
															width: "100%",
															height: "20px",
															marginBottom: "10%",
															borderBottom: "1px solid rgba(0,0,0,0.3)",
															textAlign: "center",
														}}
													>
														<span
															style={{
																fontSize: "20px",
																backgroundColor: "#ffffff",
																padding: "10px 20px",
															}}
														>
															or
														</span>
													</p>
													<p>
														<Button
															className="button-login"
															onClick={AzureLogin}
														>
															Continue with Stena AD
														</Button>
													</p>
												</form>
												<span
													style={{
														fontWeight: "bold",
														textDecoration: "underline",
													}}
													data-tip="Contact Support"
												>
													<a href="mailto:portal-support@stena-evolve.com?subject=Portal Assistance">
														Contact Us
													</a>
												</span>
												<ReactTooltip />
											</div>
										</div>
									</div>
								</div>
								<div className="mfooter-login">
									<span
										className="footer-text"
										style={{
											fontSize: "11px",
											marginTop: "auto",
											color: "#D3D3D3",
										}}
									>
										Powered by Stena Evolve {"  "}© Copyright{" "}
										{new Date().getFullYear()}
									</span>
									<img
										className="footer-logo"
										style={{
											marginRight: "0",
											width: "80px",
										}}
										src={StenaEvolve_logo}
										alt="Company Logo"
									/>
								</div>
							</Content>
						</Layout>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(LoginPage);
