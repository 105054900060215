import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import NoAccess from "./NoAccess";
import { ComponentHook } from "../utils/components";

function KognifaiDashboard(props: any) {
	const [title, setTitle] = useState(props.params.mitem?.title);
	const [link, setLink] = useState(props.params.mitem?.kognifai_shared_link);
	const [history, sethistory]: any = useState(null);

	useEffect(() => {
		sethistory(null);
		setTitle(props.params.mitem?.title);
		setLink(props.params.mitem?.kognifai_shared_link);
	}, [props.params.mitem?.title, props.params.mitem?.kognifai_shared_link]);

	const WithRouter = () => {
		let Params: any = useLocation();
		let pathname: string = Params.pathname;
		sethistory(pathname);
		return null;
	};

	return (
		<>
			{!history ? <WithRouter /> : null}
			{link ? (
				<>
					<ComponentHook menuProps={props.params.mitem} />
					<div style={{ flexGrow: "1", display: "flex" }}>
						<iframe
							title="real-time-analysis"
							style={{ flexGrow: "1" }}
							src={link ? link : ""}
						></iframe>
					</div>
				</>
			) : (
				<NoAccess text={"Shared link not specified"} />
			)}
		</>
	);
}

const mapStateToProps = (state: any) => {
	return {
		role: state.role,
	};
};

export default connect(mapStateToProps)(KognifaiDashboard);
