import { Button, Input, Space, Switch } from "antd";
import { useContext, useEffect, useState } from "react";
import { updateTenantModule } from "../../services/api-server/menu";

const { Group } = Button;

type TenantDetailsProps = {
	tenant: any;
	onUpdate: (updatedTenant: any) => void;
};

const TenantDetails = ({ tenant, onUpdate }: TenantDetailsProps) => {
	const [dirty, setDirty] = useState(false);
	const [updatedTenant, setUpdatedTenant] = useState<any>(null);

	const onChange = (checked: boolean) => {
		setDirty(true);
		setUpdatedTenant({ ...tenant, menu_option: checked ? "mega" : "default" });
	};

	const onConfirm = () => {
		updateTenantModule(tenant.tenantid, updatedTenant).then(() => {
			onUpdate(updatedTenant);
		});
		setDirty(false);
	};

	const onCancel = () => {
		// console.log(tenant);

		setUpdatedTenant(tenant);
		setDirty(false);
	};

	useEffect(() => {
		setUpdatedTenant(tenant);
		setDirty(false);
	}, [tenant]);

	return (
		<div style={{ display: "grid", gap: 20 }}>
			<Space direction="vertical">
				Tenant ID
				<Input readOnly value={tenant?.tenantid} />
			</Space>
			<Space direction="vertical">
				ID
				<Input readOnly value={tenant?._id} />
			</Space>
			<Space direction="vertical">
				Mega Menu
				<Switch
					defaultChecked={false}
					checked={updatedTenant?.menu_option === "mega"}
					checkedChildren="Enabled"
					unCheckedChildren="Disabled"
					onChange={onChange}
				/>
			</Space>

			{dirty && (
				<Space>
					<Button onClick={onConfirm} className="confirm">
						Confirm
					</Button>
					<Button onClick={onCancel} className="cancel">
						Cancel
					</Button>
				</Space>
			)}
		</div>
	);
};

export default TenantDetails;
