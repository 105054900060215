import {
	Button,
	Col,
	Dropdown,
	Flex,
	Input,
	Popconfirm,
	Row,
	Space,
	Table,
	TableColumnsType,
	Typography,
} from "antd";
import { GetAntIcon } from "../utils/ant_icons";
import { useMemo, useState } from "react";
import ParameterForm from "./ParameterForm";
import {
	addParameter,
	deleteParameter,
	updateParameter,
} from "../services/api-server/parameters";
import { v4 as uuid } from "uuid";
import Emitter from "../services/EventEmitter";
import _ from "lodash";

const { Text, Link } = Typography;

type ParametersTabProps = {
	menuKey?: string;
	parameters?: Array<Parameter>;
	readonly?: boolean;
};

const defaultColumns: TableColumnsType<Parameter> = [
	{
		key: "name",
		dataIndex: "name",
		title: "Name",
		render: (value) => `@${value}`,
	},
	{ key: "displayName", dataIndex: "displayName", title: "Display Name" },
	{ key: "dataType", dataIndex: "dataType", title: "Data type" },
	{
		key: "mandatory",
		dataIndex: "mandatory",
		title: "Mandatory",
		render: (value) => (value ? "Mandatory" : "Optional"),
	},
	{
		key: "selection",
		dataIndex: "selection",
		title: "Selection",
		render: (value) => (value === "single" ? "Single value" : "Multi-select"),
	},
];

const ParametersTab = ({
	menuKey = "",
	parameters = [],
	readonly,
}: ParametersTabProps) => {
	const [searchVal, setSearchVal] = useState("");
	const [isNew, setIsNew] = useState(true);
	const [currParameter, setCurrParameter] = useState<
		undefined | Partial<Parameter>
	>(undefined);

	const [openModal, setOpenModal] = useState(false);

	const filteredParameters = useMemo(() => {
		return searchVal
			? parameters?.filter(
					(param) =>
						param?.name?.toLowerCase()?.includes(searchVal?.toLowerCase()) ||
						param?.displayName
							?.toLowerCase()
							?.includes(searchVal?.toLowerCase())
			  )
			: parameters;
	}, [searchVal, parameters]);

	const columns: TableColumnsType<Parameter> = !readonly
		? [
				...defaultColumns,
				{
					key: "action",
					dataIndex: "action",
					title: "Actions",
					width: 160,
					render: (value, record) => (
						<Space>
							<Link
								disabled={record.mandatory}
								onClick={handleEditParameter(record)}
							>
								Edit
							</Link>
							<Popconfirm
								title="Are you sure you want to remove this parameter?"
								description={`You're about to remove this @${record.displayName}. Are you sure you want to continue?`}
								onConfirm={handleDeleteParameter(record.id)}
							>
								<Link disabled={record.mandatory}>Remove</Link>
							</Popconfirm>
						</Space>
					),
				},
		  ]
		: defaultColumns;

	const handleCreateParameter = (source: string) => {
		return () => {
			if (source === "custom") {
				setCurrParameter({
					id: uuid(),
					dataType: "string",
					selection: "multi",
					source: "custom",
					fieldValues: [
						{
							value: "value1",
							key: uuid(),
							displayName: "value1",
						},
					],
					menuKey,
				});
			} else {
				setCurrParameter({
					id: uuid(),
					dataType: "string",
					selection: "multi",
					source: "query",
					fieldValues: [],
					menuKey,
				});
			}
			setIsNew(true);
			setOpenModal(true);
		};
	};

	const handleEditParameter = (parameter: Partial<Parameter>) => {
		return () => {
			setCurrParameter(parameter);
			setIsNew(false);
			setOpenModal(true);
		};
	};

	// DB
	const handleDeleteParameter = (id: string) => {
		return async () => {
			try {
				await deleteParameter(id);
				Emitter.emit("alert", {
					type: "success",
					message: "Parameter deleted successfully",
					description: "You have successfully deleted a parameter",
					timeout: 5000,
				});
			} catch (error) {
				Emitter.emit("alert", {
					type: "error",
					message: "Unsuccessful parameter deletion",
					description: "There was an error while deleting parameter",
					timeout: 5000,
				});
			}
		};
	};

	const handleAddParameter = async (parameter: Partial<Parameter>) => {
		try {
			await addParameter(parameter, menuKey);
			Emitter.emit("alert", {
				type: "success",
				message: "Parameter added successfully",
				description: "You have successfully added a parameter",
				timeout: 5000,
			});
		} catch (error) {
			Emitter.emit("alert", {
				type: "error",
				message: "Unsuccessful adding parameter",
				description: "There was an error while adding parameter",
				timeout: 5000,
			});

			throw error;
		}
	};

	const handleUpdateParameter = async (parameter: Partial<Parameter>) => {
		try {
			await updateParameter(parameter, menuKey);
			Emitter.emit("alert", {
				type: "success",
				message: "Parameter updated successfully",
				description: "You have successfully updated a parameter",
				timeout: 5000,
			});
		} catch (error) {
			Emitter.emit("alert", {
				type: "error",
				message: "Unsuccessful updating parameter",
				description: "There was an error while updating parameter",
				timeout: 5000,
			});

			throw error;
		}
	};

	const handleSaveParameter = async (parameter: Partial<Parameter>) => {
		try {
			isNew
				? await handleAddParameter(parameter)
				: await handleUpdateParameter(parameter);
			setOpenModal(false);
		} catch (error) {
			throw error;
		}
	};

	return (
		<>
			<Row gutter={[0, 16]} style={{ padding: "0 16px" }}>
				<Col span={24}>
					<Flex justify="space-between">
						<Text style={{ fontSize: 16 }}>Parameters</Text>
						{!readonly ? (
							<Dropdown
								disabled={parameters?.length >= 6}
								trigger={["click"]}
								menu={{
									items: [
										{
											label: "Custom",
											key: "custom",
											onClick: handleCreateParameter("custom"),
										},
										{
											label: "From query",
											key: "query",
											onClick: handleCreateParameter("query"),
										},
									],
								}}
							>
								<Button icon={GetAntIcon("plus")}>Add parameters</Button>
							</Dropdown>
						) : null}
					</Flex>
				</Col>
				<Col span={6}>
					<Input
						placeholder={"Search parameters name..."}
						onChange={(e) => setSearchVal(e.target.value)}
					/>
				</Col>
				<Col span={24}>
					<Table
						size="small"
						bordered
						dataSource={filteredParameters}
						columns={columns}
					/>
				</Col>
			</Row>

			<ParameterForm
				title={`${isNew ? "New" : "Edit"} ${_.capitalize(
					currParameter?.source
				)} Parameter`}
				open={openModal}
				currParameter={currParameter}
				onCancel={() => {
					setCurrParameter(undefined);
					setIsNew(true);
					setOpenModal(false);
				}} // Reset the parameter
				isNew={isNew}
				allParameters={filteredParameters}
				handleSaveParameter={handleSaveParameter}
			/>
		</>
	);
};

export default ParametersTab;
