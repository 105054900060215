import { api } from "../../contexts/AuthContext";
import { Tenant } from "./_exports";

export const saveMenu = (menu: any, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api.put(`/modulemgt/`, {
			filter: { tenantid: Tenant },
			data: { menu: menu },
			options: { upsert: true, new: true },
			tenantid: Tenant,
			menuKey,
		})
			.then((_data) => {
				resolve(_data);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};

export const saveMenuItem = (menuItem: any) => {
	const { key } = menuItem;
	return new Promise((resolve, reject) => {
		api.put(`/modulemgt/updateMenu`, {
			menuItem,
			tenantid: Tenant,
			menuKey: key,
		})
			.then((_data) => {
				resolve(_data);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};

export const deleteMenuItem = (menuItem: any) => {
	return new Promise((resolve, reject) => {
		api.put(`/modulemgt/deleteMenuItem`, {
			menuItemKey: menuItem.key,
			tenantid: Tenant,
		})
			.then((_data) => {
				resolve(_data);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};

export const loadMenu = () => {
	return new Promise((resolve, reject) => {
		api.get(`/modulemgt/`, {
			params: { tenantid: Tenant },
		})
			.then((_data) => {
				resolve(_data?.data[0]);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const updateTenantModule = (tenantid: string, data: any) => {
	return new Promise((resolve, reject) => {
		api.put(`/modulemgt`, {
			filter: { tenantid: tenantid },
			data: data,
		})
			.then((_data) => {
				resolve(_data);
			})
			.catch((err) => {
				console.error(err?.message);
				reject(err);
			});
	});
};

export const getAllTenantModules = () => {
	return new Promise((resolve, reject) => [
		api
			.get(`/modulemgt/allTenants`)
			.then((_data) => {
				resolve(_data);
			})
			.catch((err) => {
				console.error(err?.message);
				reject(err);
			}),
	]);
};

export const saveAdminMenu = (adminmenu: any) => {
	return new Promise((resolve, reject) => {
		api.put(`/adminmodulemgt/`, {
			filter: { tenantid: Tenant },
			data: { tenantid: Tenant, adminmenu: adminmenu },
			options: { upsert: true, new: true },
		})
			.then((_data) => {
				resolve(_data);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};

export const loadAdminmenu = () => {
	return new Promise((resolve, reject) => {
		api.get(`/adminmodulemgt/`, {
			params: { tenantid: Tenant },
		})
			.then((_data) => {
				// console.log(_data);
				resolve(_data?.data[0]);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getComponents = () => {
	return new Promise((resolve, reject) => {
		api.get(`/getcomponents`)
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((error) => {
				console.log("Fetched error");
				reject(error);
			});
	});
};
