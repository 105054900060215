import { AutoComplete, Flex, Menu } from "antd";
import { useSelector } from "react-redux";
import { filterMenu, getNodes } from "../utils/utils";
import { useMemo, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { Link, useNavigate } from "react-router-dom";
import { Tenant } from "./api-server/_exports";
import { GetComponentIcon } from "../utils/svg_icons";
import { useMenuContext } from "../contexts/MenuContext";
import NewMenuItemHandler from "../components/NewMenuItemHandler";
import _ from "lodash";
import useMenu from "../hooks/useMenu";

type SideMenuProps = {
	items: any[];
	onSelect?: (info: any) => void;
};

interface LevelKeysProps {
	key?: string;
	children?: LevelKeysProps[];
}

const getLevelKeys = (items1: LevelKeysProps[]) => {
	const key: Record<string, number> = {};
	const func = (items2: LevelKeysProps[], level = 1) => {
		items2.forEach((item) => {
			if (item.key) {
				key[item.key] = level;
			}
			if (item.children) {
				func(item.children, level + 1);
			}
		});
	};
	func(items1);
	return key;
};

const SideMenu = ({ items: menuItems }: SideMenuProps) => {
	const { menu } = useMenu();
	const userRoles = useSelector((state: any) => state?.role);
	const currentUser = useSelector((state: any) => state?.user);

	const items = useMemo(() => {
		return userRoles?.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)
			? menu
			: filterMenu(menu, userRoles, currentUser)
	}, [userRoles, menu, currentUser])

	const searchRef = useRef<any>(null);
	const navigate = useNavigate();
	const { ismobile } = useSelector((state: any) => state);
	const allNodes = getNodes(items)?.map((node) => ({
		label: node?.title,
		value: node?.key,
		path: node?.path,
	}));
	const [options, setOptions] = useState<any>([]);
	const [value] = useDebounce(options, 1000);
	const [searchVal, setSearchVal] = useState("");
	const {
		toggle,
		selectedKey,
		setOpenKeys,
		openKeys,
		setSubmenuKey = () => { },
	} = useMenuContext();
	const levelKeys = useMemo(() => {
		return getLevelKeys(items as LevelKeysProps[]);
	}, [items]);

	const getPanelValue = (value: string) =>
		!value
			? []
			: allNodes?.filter((node) =>
				node.label?.toLowerCase()?.includes(value.toLowerCase())
			);

	const onOpenChange = (keys: string[]) => {
		const currentOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
		// open
		if (currentOpenKey !== undefined) {
			const repeatIndex = keys
				.filter((key) => key !== currentOpenKey)
				.findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

			setOpenKeys(
				keys
					// remove repeat key
					.filter((_, index) => index !== repeatIndex)
					// remove current level all child
					.filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
			);
		} else {
			// close
			setOpenKeys(keys);
		}
	};

	const renderMobileMenu = (level = 1) => {
		const traverse = (item: any, currentLevel: number) => {
			const newLevel = currentLevel + 1;
			const hasIcon = GetComponentIcon({ componentName: item?.component });

			if (item?.children && item?.children?.length > 0) {
				return (
					<Menu.SubMenu
						className="v2-menu-submenu"
						key={item.key}
						title={
							<span
								style={{
									paddingLeft: 46,
									//  color: "rgba(255, 255, 255, 0.65)"
								}}
							>
								{item?.title}
							</span>
						}
					>
						{item?.children?.map((child: any) => traverse(child, newLevel))}
					</Menu.SubMenu>
				);
			}

			return (
				<Menu.Item
					key={item?.key}
					style={{
						paddingLeft: newLevel === 4 ? 76 : !hasIcon ? 46 : 16,
					}}
					icon={GetComponentIcon({ componentName: item?.component, width: 20 })}
					onClick={() => toggle()}
				>
					<Link
						// style={{ color: "rgba(255, 255, 255, 0.65)" }}
						to={`/${Tenant}/${item?.path?.join("/")}`}
					>
						<NewMenuItemHandler
							key={item.key}
							newDuration={item?.new_duration}
							title={item?.title}
						/>
					</Link>
				</Menu.Item>
			);
		};

		return items?.map((item: any) => traverse(item, level));
	};
	const renderDesktopMenu = (level = 0) => {
		const traverse = (item: any, currentLevel: number) => {
			const newLevel = currentLevel + 1;

			if (newLevel === 2) {
				return item?.children?.length > 0 ? (
					<Menu.Item
						key={item.key}
						className={
							openKeys?.includes(item.key) ? "ant-menu-item-selected" : ""
						}
						title={
							<span
								style={{
									paddingLeft: 46,
								}}
							>
								2:{item?.title}
							</span>
						}
						onClick={() => {
							setSubmenuKey(item.key);
						}}
					>
						{item?.children?.length > 0 ? (
							<span
								style={{
									paddingLeft: 46,
								}}
							>
								{item?.title}
								{item?.children?.length > 0 ? (
									<i
										style={{ transform: "rotate(-90deg)" }}
										className="ant-menu-submenu-arrow"
									/>
								) : null}
							</span>
						) : (
							<Link to={`/${Tenant}/${item?.path?.join("/")}`}>
								{GetComponentIcon({
									componentName: item?.component,
									width: 20,
								})}
								{item?.title}
							</Link>
						)}
					</Menu.Item>
				) : (
					<Menu.Item
						key={item.key}
						style={{
							paddingLeft: !GetComponentIcon({
								componentName: item?.component,
								width: 20,
							})
								? 46
								: 16,
						}}
						icon={GetComponentIcon({
							componentName: item?.component,
							width: 20,
						})}
						onClick={() => toggle()}
					>
						<Link to={`/${Tenant}/${item?.path?.join("/")}`}>
							{/* {item?.title} */}
							<NewMenuItemHandler
								key={item.key}
								newDuration={item?.new_duration}
								title={item?.title}
							/>
						</Link>
					</Menu.Item>
				);
			}

			if (item?.children && item?.children?.length > 0) {
				return (
					<Menu.SubMenu
						className="v2-menu-submenu"
						key={item.key}
						title={
							<span
								style={{
									paddingLeft: 42,
								}}
							>
								{item?.title}
							</span>
						}
					>
						{item?.children?.map((child: any) => traverse(child, newLevel))}
					</Menu.SubMenu>
				);
			}

			return (
				<Menu.Item
					key={item?.key}
					style={{
						paddingLeft: GetComponentIcon({ componentName: item?.component })
							? 16
							: 46,
					}}
					icon={GetComponentIcon({ componentName: item?.component, width: 20 })}
					onClick={() => toggle()}
				>
					<Link to={`/${Tenant}/${item?.path?.join("/")}`}>
						<NewMenuItemHandler
							key={item.key}
							newDuration={item?.new_duration}
							title={item?.title}
						/>
					</Link>
				</Menu.Item>
			);
		};

		return items?.map((item: any) => traverse(item, level));
	};

	return (
		<Flex vertical>
			<AutoComplete
				ref={searchRef}
				placeholder={"Search..."}
				options={value}
				optionFilterProp="label"
				bordered={false}
				style={{
					width: "100%",
					background: "rgb(20, 20, 20)",
					borderTop: "2px solid rgb(37, 37, 37)",
				}}
				optionRender={(ad, info) => {
					return (
						// <Link to={`/${Tenant}/${ad.data.path?.join("/")}`}>{ad.label}</Link>
						<a>{ad.label}</a>
					);
				}}
				onSearch={(value) => setOptions(getPanelValue(value))}
				allowClear
				onChange={(_, option: any) => {
					setSearchVal(option?.label);
				}}
				onSelect={(info, option) => {
					const focusedElement = document.activeElement;

					// Check if the focused element is an HTMLElement (or a more specific type)
					if (focusedElement instanceof HTMLElement) {
						// Call blur() method on the HTMLElement
						focusedElement.blur();
					} else {
						// Handle case where the focusedElement is not an HTMLElement
						// console.error(
						//	"The currently focused element is not an HTMLElement."
						//);
					}
					navigate(`/${Tenant}/${option.path?.join("/")}`);

					toggle();
				}}
				value={searchVal}
			/>

			<Menu
				theme="dark"
				mode={"inline"}
				inlineIndent={0}
				rootClassName={ismobile ? "v2-menu-mobile" : "v2-menu"}
				selectedKeys={[selectedKey]}
				onOpenChange={onOpenChange}
				openKeys={openKeys}
			>
				{ismobile ? renderMobileMenu() : renderDesktopMenu()}
			</Menu>
		</Flex>
	);
};

export default SideMenu;
