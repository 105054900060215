const initialState = {
	allVessels: [],
	allDetailVessels: [],
	selectedVessel: null,
};
export const VesselImgMap = (state: any = {}, action: any) => {
	switch (action.type) {
		case "SET_VESSEL_IMAGE":
			let new_state = state;
			if (action.payload.name) {
				new_state[`${action.payload?.name}`] = {
					icon: action.payload?.icon,
					icon2: action.payload?.icon2,
					icon3: action.payload?.icon3,
					on_hire: action.payload?.on_hire,
				};
				return new_state;
			} else {
				return state;
			}

		default:
			return state;
	}
};
export const Vessels = (state: any = initialState, action: any) => {
	//console.log('allVessels',state,action)
	switch (action.type) {
		case "SET_VESSELS":
			return {
				...state,
				allVessels: action.payload,
			};
		case "SET_VESSELS_DETAIL":
			// expected payload: array of vessel information
			// lets organise it to "hired" and "not hired"
			const VesselInfo = action.payload;
			let all_hired_vessels: any = [];
			VesselInfo.map((rec: any) => {
				if (rec?.on_hire === true) all_hired_vessels.push(rec);
				return null;
			});
			let all_others: any = [];
			VesselInfo.map((rec: any) => {
				if (rec?.on_hire !== true) all_others.push(rec);
				return null;
			});

			const result = [].concat(all_hired_vessels as any, all_others as any);
			const rignames = result.map((d: any) => {
				return d.name;
			});
			return {
				...state,
				allVessels: rignames,
				allDetailVessels: result,
			};

		// console.log('vessels.set',action.payload)
		// return action.payload
		case "SET_VESSEL_GEOLOCATION":
			const VesselGeo = action.payload;
			//console.log(VesselGeo);
			return {
				...state,
				vessellocation: action.payload,
			};
		case "SELECT_VESSEL":
		case "SELECT_VESSELS":
			localStorage.setItem("selectedVessel", action.payload);
			return {
				...state,
				selectedVessel: action.payload,
			};
		case "SET_VESSEL_FILETAGS":
			return {
				...state,
				vesselFiles: action.payload,
			};
		default:
			return state;
	}
};

export const ContractedVessels = (state: any = [], action: any) => {
	switch (action.type) {
		case "CLEAR_CONTRACTED_VESSELS":
			return [];
		case "ADD_CONTRACTED_VESSEL":
			return [...state, action.payload];
		default:
			return state;
	}
};
