import { useMemo } from "react"
import { useLocation } from "react-router"

const useUrlQuery = () => {
    // Query Params for handling open configuration manager depending on query url
    const { search } = useLocation()
    return useMemo(() => {
        return new URLSearchParams(search)
    }, [search])
}

export default useUrlQuery