import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import NoAccess from "./NoAccess";

class Comingsoon extends React.Component<any> {
	state: any = {
		acceptedroles: null,
		history: null,
	};

	props: any = {};

	constructor(props: any) {
		super(props);
		this.props = props;
	}

	componentDidMount() {
		this.checkRole();
	}

	componentDidUpdate() {
		if (this.state.history !== window.location.pathname && this.state.history) {
			this.setState({ history: null, acceptedroles: null });
		}
	}

	checkRole() {
		if (this.state.acceptedroles === null) {
			if (this.props.role.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
				this.setState({ acceptedroles: true });
			} else if (
				this.props.params?.roles?.length === 0 ||
				!this.props.params?.roles
			) {
				this.setState({ acceptedroles: true });
			} else {
				this.props.params.roles.map((element: any) => {
					if (this.props.role.includes(element)) {
						this.setState({ acceptedroles: true });
					}
					return true;
				});
			}
		}
	}

	render() {
		const WithRouter = () => {
			let Params: any = useLocation();
			let pathname: string = Params.pathname;
			this.setState({ history: pathname });
			this.checkRole();
			return null;
		};
		return (
			<>
				{!this.state.history ? (
					<>
						<WithRouter />
					</>
				) : null}
				{this.state.acceptedroles ? (
					<div
						style={{
							height: "100%",
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<div className="coming-soon-background"></div>
						<div
							className="coming-soon-center-container"
							style={{ padding: "5%" }}
						>
							<div className="hexlock-container">
								<div className="hexlock"></div>
							</div>
							<span
								style={
									!this.props.ismobile
										? { fontSize: "3em", lineHeight: "2" }
										: {
												fontSize: "1.8em",
												lineHeight: "2",
												textAlign: "center",
										  }
								}
							>
								Looks like this page isn't quite ready
							</span>
							<span
								className="subtext"
								style={{ width: "65%", fontSize: "1.2em", textAlign: "center" }}
							>
								We haven't yet setup this page. Give us a little more time and
								we'll have it ready for you
							</span>
						</div>
					</div>
				) : (
					<NoAccess />
				)}
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		role: state.role,
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(Comingsoon);
