export const isMobile = (state: any = null, action: any) => {
	switch (action.type) {
		case "IS_MOBILE":
			return action.payload;
		default:
			return state;
	}
};

export const Mode = (state: any = null, action: any) => {
	switch (action.type) {
		case "LIGHT_MODE":
			return "light_mode";
		case "DARK_MODE":
			return "dark_mode";
		default:
			return state;
	}
};
