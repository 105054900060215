import { api } from "../../contexts/AuthContext";

export const getAllVesselsKognifai = () => {
	return new Promise((resolve: any, reject: any) => {
		api.get(`/kognifai/getAllVessels`)
			.then((data: any) => {
				resolve(data.data);
			})
			.catch((err: any) => {
				reject(err);
			});
	});
};

export const listVesselTags = (vessel: string) => {
	return new Promise((resolve: any, reject: any) => {
		api.get(`/kognifai/listVesselTags/${vessel}`)
			.then((data: any) => {
				resolve(data);
			})
			.catch((err: any) => {
				reject(err);
			});
	});
};

export const getVesselTag = (
	vessel: string,
	tag: string,
	from: string,
	to: string,
	interval: string
) => {
	return new Promise((resolve: any, reject: any) => {
		//console.log(vessel, tag, from, to, interval);
		const _url = `/kognifai/getVesselTag/${vessel}/${tag}`;
		api.get(
			`${_url}?start_date=${from}&end_date=${to}&interval=${interval}`
		)
			.then((data: any) => {
				resolve(data);
			})
			.catch((err: any) => {
				reject(err);
			});
	});
};
