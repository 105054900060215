import React from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
	addFolder,
	getContainers,
	getDocument,
	getDocumentList,
	solrSearchDocs,
	updateFolder,
} from "../services/api-server/documents";
import {
	Spin,
	Table,
	Button,
	Input,
	Modal,
	Select,
	Space,
	Tooltip,
	Tag,
} from "antd";
import {
	DeleteOutlined,
	EditOutlined,
	UpSquareOutlined,
	DownSquareOutlined,
	WarningOutlined,
} from "@ant-design/icons";
import Emitter from "../services/EventEmitter";
import TextArea from "antd/lib/input/TextArea";
import { GetFileType } from "../utils/ant_icons";
import ModalDirectoryManager from "../components/modal/Modal_DirectoryManager";
import Portal_Logo from "../assets/img/Portal_logo_RGB.png";
import Portal_Icon_Logo from "../assets/img/Portal_hexagon_RGB.png";
import { HomeContext, HomeContextProps } from "./Home";
import { Tenant } from "../services/api-server/_exports";
import { ComponentHook } from "../utils/components";
import { getLatest } from "../utils/utils";
const controller = new AbortController();
const { signal } = controller;

const { Option } = Select;
const { Column } = Table;
const { Search } = Input;
const operatorList = ["=", ">", ">=", "<", "<="];

class Documents extends React.Component<any> {
	static contextType: React.Context<HomeContextProps | undefined> = HomeContext;

	state: any = {
		clean: true,
		history: null,
		blobdata: null,
		folderdata: null,
		searchedblobs: null,
		allblobs: null,
		containerdata: null,
		loading: true,
		super: false,
		documents: "clean",
		tip: "Retrieving folders...",
		newFolder: null,
		ModalData: null,
		mode: document.body.classList.contains("dark-mode"),
	};
	props: any = {};

	constructor(props: any) {
		super(props);
		this.props = props;
	}

	componentDidMount() {
		if (this.props.role.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
			this.setState({ super: true });
		}
		this.GetDocuments();
		getContainers().then((res: any) => {
			this.setState({ containerdata: res.data });
		});

		Emitter.on("mode-toggle", (ev: any) => {
			this.setState({ mode: ev });
		});
	}

	componentWillUnmount(): void {
		Emitter.remove("mode-toggle");
	}

	async GetDocuments() {
		const documentQuery = async (blobdata: any) => {
			const fetchDocument = (targetDoc: any) => {
				const fetch = async () => {
					try {
						const { handleOpenDocument } = this.context as HomeContextProps;
						const fileUrl = await getDocument(
							targetDoc.name,
							targetDoc.container
						);
						if (this.context) {
							handleOpenDocument({
								open: true,
								pdfViewer: true,
								fileObject: {
									record: targetDoc,
									key: targetDoc.name,
									children: fileUrl,
									label: targetDoc.name,
								},
								loading: false,
							});
							this.setState({ loading: false });
						}
					} catch {
						Emitter.emit("alert", {
							type: "error",
							message: "Document not found",
							description: "The requested document could not be located",
							timeout: 5000,
						});
						this.setState({ loading: false });
					}
				};
				const handleVisibilityChange = () => {
					if (document.visibilityState === "visible") {
						fetch();
						controller.abort();
					}
				};

				if (document.visibilityState === "visible") {
					fetch();
				} else {
					document.addEventListener(
						"visibilitychange",
						handleVisibilityChange,
						{ signal }
					);
				}
			};

			// Check for URL params to open file
			const queryParams = new URLSearchParams(window.location.search);
			const rig = queryParams.get("rig");
			const cat = queryParams.get("cat");
			const date = queryParams.get("date");

			const rigDocs = blobdata?.filter(
				(d: any) =>
					d?.container?.toLowerCase() === rig?.toLowerCase() &&
					d?.tags?.category?.toLowerCase() === cat?.toLowerCase() &&
					d?.tags?.reportDate === date
			);
			const targetDoc = getLatest(rigDocs, "lastmodified");

			if (rig && cat) {
				if (targetDoc) {
					fetchDocument(targetDoc);
				} else {
					this.setState({ loading: false });
					Emitter.emit("alert", {
						type: "error",
						message: "Document not found",
						description: "The requested document could not be located",
						timeout: 5000,
					});
				}
			} else {
				this.setState({ loading: false });
			}
		};

		getDocumentList("whole", [], this.props.role, null)
			.then(async (res: any) => {
				if (res.data) {
					let blobdata = res.data.uniqueblobs;
					let folderdata = res.data.folderdata;

					blobdata = blobdata.sort(
						(a: any, b: any) =>
							Date.parse(b.lastmodified) - Date.parse(a.lastmodified)
					);

					let sliceddata = blobdata.slice(0, 30);
					this.setState({
						blobdata: sliceddata,
						allblobs: blobdata,
						folderdata: folderdata,
					});

					documentQuery(blobdata);
				} else {
					this.setState({ loading: false });
				}
			})
			.catch(() => {
				Emitter.emit("alert", {
					type: "error",
					message: "Error: Blob Retrieval Failure",
					description:
						"There is an error when retrieving the blob files, check the folder configurations to see if everything is configured correctly",
					timeout: 5000,
				});
				this.setState({ loading: false });
			});
	}

	searchDocs(value: any) {
		if (value.length > 0) {
			this.setState({ searchLoading: true });
			solrSearchDocs(value)
				.then((data: any) => {
					this.setState({
						searchValue: value,
						visible_searchModal: true,
						searchedData: data,
						searchLoading: false,
						filterdata: null,
					});
				})
				.catch((error: any) => {
					this.setState({ searchLoading: false });
				});
		}
	}

	componentDidUpdate() {
		if (this.state.history !== window.location.pathname && this.state.history) {
			this.setState({
				history: null
			});
		}
	}

	changeOrder(direction: any, index: any) {
		let wholedoc = { ...this.state.documents };
		let currentdoc = wholedoc.folders[index];
		let otherdoc = [];
		//console.log(direction, wholedoc.folders, index)
		if (direction === "up" && wholedoc.folders[index - 1]) {
			otherdoc = wholedoc.folders[index - 1];
			wholedoc.folders[index] = otherdoc;
			wholedoc.folders[index - 1] = currentdoc;
			this.UpdateFolder(wholedoc);
		} else if (direction === "down" && wholedoc.folders[index + 1]) {
			//console.log(wholedoc.folders[index + 1], index, index + 1)
			otherdoc = wholedoc.folders[index + 1];
			wholedoc.folders[index] = otherdoc;
			wholedoc.folders[index + 1] = currentdoc;
			this.UpdateFolder(wholedoc);
		}
	}

	editFolder() {
		let tagError = false;
		let reason = "";
		if (!this.state.newFolder.name) {
			alert("Unable to save, please enter a name for this folder");
		} else {
			if (this.state.newFolder.tags) {
				this.state.newFolder.tags.map((element: any) => {
					if (isNaN(parseInt(element.key.charAt(0))) === false) {
						tagError = true;
						reason = "The tag key cannot start with a number";
					}
					return null;
				});
			}
			if (tagError === false) {
				//console.log("This is trying to do something?")
				let wholedoc = this.state.documents;
				let index = this.state.ModalData[1];
				let newData = this.state.newFolder;
				//console.log(newData)
				wholedoc.folders[index] = newData;

				this.UpdateFolder(wholedoc);
				this.setState({ EditModal: false });
			} else {
				alert(`Unable to save - ${reason}`);
			}
		}
	}

	deleteFolder() {
		let wholedoc = this.state.documents;
		let index = this.state.ModalData[1];
		wholedoc.folders.splice(index, 1);
		this.UpdateFolder(wholedoc);
		this.setState({ DeleteModal: false, loading: true });
		this.GetDocuments();
	}

	UpdateFolder(document: any) {
		updateFolder(document).then((res: any) => {
			this.setState({
				documents: res.data,
				newFolder: null,
				loading: true,
			});
			this.props.dispatch({ type: "DOCUMENTS", payload: res.data });
			Emitter.emit("newdocs", null);
			this.GetDocuments();
		});
	}

	saveFolder() {
		let tagError = false;
		let reason = "";
		//console.log("is it trying to save?")
		if (!this.state.newFolder.name) {
			alert("Unable to save, please enter a name for this folder");
		} else {
			if (this.state.newFolder.tags) {
				this.state.newFolder.tags.map((element: any) => {
					if (isNaN(parseInt(element.key.charAt(0))) === false) {
						tagError = true;
						reason = "The tag key cannot start with a number";
					}
					return null;
				});
			}
			if (tagError === false) {
				if (this.state.documents !== "clean") {
					if (this.state.newFolder) {
						let wholedoc = this.state.documents;
						let newdoc = this.state.newFolder;
						wholedoc.folders.push(newdoc);
						this.UpdateFolder(wholedoc);
						this.setState({
							newFolderModal: false,
							DeleteModal: false,
							EditModal: false,
						});
					}
				} else {
					const newDocs = {
						tenantid: Tenant,
						folders: [this.state.newFolder],
					};
					addFolder(newDocs)
						.then((res: any) => {
							Emitter.emit("alert", {
								type: "success",
								message: "Folder Added Successfully",
								description: "A new folder has successfully added",
								timeout: 5000,
							});
							this.setState({
								documents: newDocs,
								newFolder: null,
								loading: true,
							});
							this.props.dispatch({
								type: "DOCUMENTS",
								payload: newDocs,
							});
							Emitter.emit("newdocs", null);
							this.GetDocuments();
						})
						.catch((error: any) => {
							Emitter.emit("alert", {
								type: "error",
								message: "An Error has Occured",
								description: "An Error has occured in adding the folder",
								timeout: 5000,
							});
						});
					this.setState({
						newFolderModal: false,
						DeleteModal: false,
						EditModal: false,
					});
				}
			} else {
				alert(`Unable to save - ${reason}`);
			}
		}
	}

	render() {
		// refers to the function that is passed to the context provider from Home.tsx
		let context: any = this.context;
		let handleOpenDocument: any = context?.handleOpenDocument;

		const WithRouter = () => {
			let Params: any = useLocation();
			let pathname: string = Params.pathname;
			this.setState({ history: pathname });
			return null;
		};
		const ModalDataValue = (type: any) => {
			return this.state.ModalData ? this.state.ModalData[0][`${type}`] : null;
		};
		const NewdataChanged = (l: any, v: any) => {
			this.setState({ clean: false });
			let data: any = { ...this.state.newFolder };
			data[`${l}`] = v;
			if (data[`${l}`].length === 0) {
				delete data[`${l}`];
			}
			this.setState({ newFolder: data });
		};

		const RenderFolders = () => {
			if (this.state.documents === "clean") {
				this.setState({ documents: this.props.documents });
			}
			return null;
		};
		const addNewTagParameters = () => {
			const add = () => {
				let data = { ...this.state.newFolder };
				let tags = data.tags;
				if (tags === undefined) {
					tags = [];
				}
				tags.push({ key: "", operator: "=", value: "" });
				data.tags = tags;
				this.setState({ newFolder: data });
			};
			return (
				<button
					key={"addnewtable"}
					className={"admin-menu-button"}
					onClick={add}
				>
					Add New Tags
				</button>
			);
		};

		const updateTagParams = (index: number, key: string, value: string) => {
			let data: any = { ...this.state.newFolder };
			let tagParams: any[] = data?.tags;
			tagParams[index][`${key}`] = value;
			data.tags = tagParams;

			this.setState({ newFolder: data, clean: false });
		};

		const deleteTagParams = (index: number) => {
			let data: any = { ...this.state.newFolder };
			let tagParams: any[] = data?.tags;
			tagParams.splice(index, 1);
			data.tags = tagParams;
			if (data["tags"].length === 0) {
				delete data["tags"];
			}
			this.setState({ newFolder: data, clean: false });
		};
		const TagParameters = () => {
			const data = { ...this.state.newFolder };
			let tagParams = data?.tags;
			if (tagParams !== undefined && tagParams !== null && tagParams.length) {
				return (
					<div className="folder-tag-layout-properties">
						{tagParams.map((obj: any, index: any) => {
							return (
								<>
									<div>
										<Input
											type="text"
											className={"input"}
											key={index}
											value={obj.key}
											placeholder="Key"
											onChange={(v: any) =>
												updateTagParams(index, "key", v.target.value)
											}
										/>
									</div>
									<div>
										<Select
											showArrow={false}
											style={{
												width: "100%",
												textAlign: "center",
											}}
											optionFilterProp="children"
											getPopupContainer={(trigger) => trigger.parentNode}
											onChange={(ev: any) =>
												updateTagParams(index, "operator", ev)
											}
											defaultValue={obj.operator}
											filterOption={(input, option) => {
												return (option!.children as unknown as string)
													.toLowerCase()
													.includes(input.toLowerCase());
											}}
										>
											{operatorList?.map((element: any) => {
												return (
													<Option key={element} value={element}>
														{element}
													</Option>
												);
											})}
										</Select>
									</div>
									<div>
										<Input
											type="text"
											className={"input"}
											key={index}
											value={obj.value}
											placeholder="Value"
											onChange={(v: any) =>
												updateTagParams(index, "value", v.target.value)
											}
										/>
									</div>
									<Button
										style={{ width: "70px" }}
										className={"button-general"}
										onClick={() => deleteTagParams(index)}
									>
										{" "}
										Delete{" "}
									</Button>
								</>
							);
						})}
						<div>{addNewTagParameters()}</div>
					</div>
				);
			} else {
				return <>{addNewTagParameters()}</>;
			}
		};
		const FolderArrange = () => {
			let blanktags = false;
			const checkBlankTags = (element: any) => {
				Object.keys(element).some((key) => {
					if (element[key] === "") blanktags = true;
					return null;
				});
			};

			if (this.state.documents !== "clean") {
				return this.state.documents.folders.map((element: any, index: any) => {
					let currentfolder = JSON.parse(JSON.stringify(element));
					return (
						<div
							className="folder"
							style={this.props.ismobile ? { marginBottom: "5%" } : {}}
						>
							<div className="folder-header">
								<div>
									<span className="folder-title">{element.name}</span>
									{element.tags
										? element.tags.map((_element: any) => {
												checkBlankTags(_element);
												return null;
										  })
										: null}
									{(!element.containers && !element.tags) ||
									blanktags === true ? (
										<Tooltip title="The folder is not configured properly">
											<Space
												style={{
													marginLeft: "10px",
													color: "red",
													fontSize: "14px",
												}}
											>
												<WarningOutlined alt="Folder Error" />
											</Space>
										</Tooltip>
									) : null}
								</div>

								<span
									style={{
										cursor: "pointer",
										float: "right",
										color: "#1890ff",
										fontWeight: "400",
										fontSize: "14px",
									}}
								>
									<Link to={`${element.name.split(" ").join("_")}`}>Open</Link>
								</span>
							</div>
							<div
								style={{
									fontSize: "14px",
									display: "flex",
								}}
								className="folder-body"
							>
								{this.state.folderdata
									? element.description?.length > 0
										? element.description
										: `${
												this.state.folderdata[element.name]
													? this.state.folderdata[element.name]
													: `0`
										  } file(s) exist in this folder`
									: null}
							</div>
							{this.state.super && (
								<div className="folder-footer">
									<Button
										className="folder-buttons"
										key="move-up"
										icon={<UpSquareOutlined />}
										onClick={() => this.changeOrder("up", index)}
									/>
									<Button
										className="folder-buttons"
										key="move-down"
										icon={<DownSquareOutlined />}
										onClick={() => this.changeOrder("down", index)}
									/>
									<Button
										className="folder-buttons"
										key="edit-folder"
										icon={<EditOutlined />}
										onClick={() =>
											this.setState({
												EditModal: true,
												ModalData: [currentfolder, index],
												newFolder: currentfolder,
											})
										}
									/>
									<Button
										className="folder-buttons"
										key="delete-folder"
										icon={<DeleteOutlined />}
										onClick={() =>
											this.setState({
												DeleteModal: true,
												ModalData: [currentfolder, index],
											})
										}
									/>
								</div>
							)}
						</div>
					);
				});
			}
		};
		const renderNewFModal = () => {
			return (
				<>
					<Modal
						key={"NewFolder"}
						title={"Add New Folder"}
						styles={{ body: { padding: 0 } }}
						open={this.state.newFolderModal}
						centered={true}
						destroyOnClose={true}
						onCancel={() => {
							this.setState({
								newFolderModal: false,
								clean: true,
								newFolder: null,
							});
						}}
						okButtonProps={{ disabled: this.state.clean }}
						footer={[
							!this.props.ismobile && (
								<Button
									type="primary"
									key="directoryManager"
									style={{ float: "left", width: "inherit" }}
									className={"button-general"}
									onClick={() => {
										this.setState({ Visible_DirectoryModal: true });
									}}
								>
									Directory Manager
								</Button>
							),
							<Button
								key="cancel"
								onClick={() => {
									this.setState({
										newFolderModal: false,
										clean: true,
										newFolder: null,
									});
								}}
							>
								Cancel
							</Button>,
							<Button
								className="save-button"
								type="primary"
								key="save"
								disabled={this.state.clean}
								onClick={() => {
									this.saveFolder();
								}}
							>
								Save
							</Button>,
						]}
					>
						<div style={{ display: "grid", padding: "5%", gap: "20px" }}>
							<div>
								<div>Folder Name</div>
								<Input
									onChange={(ev: any) =>
										NewdataChanged("name", ev.target.value)
									}
								></Input>
							</div>
							<div>
								<div>Description</div>
								<TextArea
									rootClassName="documents-textarea"
									onChange={(ev: any) =>
										NewdataChanged("description", ev.target.value)
									}
								></TextArea>
							</div>
							<div>
								<div>Containers</div>
								<Select
									showSearch
									optionFilterProp="children"
									getPopupContainer={(trigger) => trigger.parentNode}
									className={"para-select"}
									onChange={(ev: any) => NewdataChanged("containers", ev)}
									filterOption={(input, option) => {
										return (option!.children as unknown as string)
											.toLowerCase()
											.includes(input.toLowerCase());
									}}
								>
									<Option key="empty" value={""}>
										None
									</Option>
									{this.state.containerdata?.map((element: any) => {
										return (
											<Option key={element} value={element}>
												{element}
											</Option>
										);
									})}
								</Select>
							</div>
							<div>
								<div>Tags</div>
								{TagParameters()}
							</div>
						</div>
					</Modal>
					{renderDirectoryModal()}
				</>
			);
		};

		const renderEditModal = () => {
			return (
				<Modal
					key={"EditFolder"}
					title={"Edit Folder"}
					open={this.state.EditModal}
					styles={{ body: { padding: "0px" } }}
					centered={true}
					destroyOnClose={true}
					onCancel={() => {
						this.setState({
							EditModal: false,
							ModalData: null,
							clean: true,
							newFolder: null,
						});
					}}
					footer={[
						!this.props.ismobile && (
							<Button
								type="primary"
								style={{ float: "left" }}
								onClick={() => {
									this.setState({ Visible_DirectoryModal: true });
								}}
								className={"button-general"}
							>
								Directory Manager
							</Button>
						),
						<Button
							key="cancel"
							onClick={() => {
								this.setState({
									EditModal: false,
									ModalData: null,
									clean: true,
									newFolder: null,
								});
							}}
						>
							Cancel
						</Button>,
						<Button
							type="primary"
							key="save"
							disabled={this.state.clean}
							onClick={() => {
								this.editFolder();
							}}
						>
							Save
						</Button>,
					]}
				>
					<div style={{ display: "grid", padding: "5%", gap: "20px" }}>
						<div>
							<div>Folder Name</div>
							<Input
								onChange={(ev: any) => NewdataChanged("name", ev.target.value)}
								defaultValue={ModalDataValue("name")}
							></Input>
						</div>
						<div>
							<div>Description</div>
							<TextArea
								rootClassName="documents-textarea"
								onChange={(ev: any) =>
									NewdataChanged("description", ev.target.value)
								}
								defaultValue={ModalDataValue("description")}
							></TextArea>
						</div>
						<div>
							<div>Containers</div>
							<Select
								showSearch
								optionFilterProp="children"
								getPopupContainer={(trigger) => trigger.parentNode}
								className={"para-select"}
								defaultValue={ModalDataValue("containers")}
								onChange={(ev: any) => NewdataChanged("containers", ev)}
								filterOption={(input, option) => {
									return (option!.children as unknown as string)
										.toLowerCase()
										.includes(input.toLowerCase());
								}}
							>
								<Option key="empty" value={""}>
									None
								</Option>
								{this.state.containerdata?.map((element: any) => {
									return (
										<Option key={element} value={element}>
											{element}
										</Option>
									);
								})}
							</Select>
						</div>
						<div>
							<div>Tags</div>
							{TagParameters()}
						</div>
					</div>
					{renderDirectoryModal()}
				</Modal>
			);
		};

		const renderDeleteModal = () => {
			return (
				<Modal
					key={"DeleteFolder"}
					title={"Delete Folder"}
					open={this.state.DeleteModal}
					styles={{ body: { padding: "0px" } }}
					centered={true}
					destroyOnClose={true}
					okText={"Delete"}
					onCancel={() => {
						this.setState({ DeleteModal: false, ModalData: null });
					}}
					onOk={() => {
						this.deleteFolder();
					}}
				>
					<div style={{ padding: "5%", gap: "20px" }}>
						<div>Do you wish to permanently delete this folder?</div>
						<div>This will not be recoverable</div>
					</div>
				</Modal>
			);
		};

		const renderDirectoryModal = () => {
			return (
				<Modal
					key="ModalDirectory"
					title={`Directory Manager ${
						this.state.newFolder?.name ? `(${this.state.newFolder.name})` : ""
					} `}
					centered
					open={this.state.Visible_DirectoryModal}
					destroyOnClose={true}
					onCancel={() => {
						this.setState({ Visible_DirectoryModal: false });
					}}
					width={"90%"}
					styles={{
						body: {
							minHeight: "80vh",
							top: "0 !important",
							padding: "0",
						},
					}}
					footer={null}
				>
					<ModalDirectoryManager
						folderData={this.state.newFolder}
						clean={this.state.clean}
						handleclean={() => this.setState({ clean: false })}
						handledata={(data: any) => {
							this.setState({ newFolder: data });
						}}
					></ModalDirectoryManager>
				</Modal>
			);
		};

		const renderSearchModal = () => {
			const filterFiles = async () => {
				const allblobs = [...this.state.allblobs];
				const searchedblobs = [...this.state.searchedData];

				const resultkey = allblobs.filter((element1: any) => {
					return searchedblobs.some((element2: any) => {
						return element2.id.split("/").pop() === element1.s_name;
					});
				});
				this.setState({ filterdata: resultkey, searchloading: false });
			};

			return (
				<Modal
					key="ModalSearch"
					title={`Search Results: ${this.state.searchValue}`}
					centered={true}
					open={this.state.visible_searchModal}
					destroyOnClose={true}
					onCancel={() => {
						this.setState({ visible_searchModal: false });
					}}
					width={this.props.ismobile ? "95%" : "90%"}
					// style={this.props.ismobile ? { padding: "5%" } : {}}
					styles={{
						body: {
							height: "80vh",
							width: this.props.ismobile ? "100%" : "auto",
							top: "0 !important",
							padding: this.props.ismobile ? "16px" : "auto",
							overflowY: "auto",
						},
					}}
					footer={null}
					zIndex={30000}
				>
					<Spin
						// style={{ color: "rgb(77, 77, 77)" }}
						size="large"
						tip={"Searching for files..."}
						indicator={<img alt="Portal logo" src={Portal_Logo} />}
						className={"antd-spin-blink"}
						wrapperClassName={"other-loading-wrapper"}
						spinning={this.state.searchloading}
					>
						{this.state.filterdata ? (
							<Table
								className="searched-table"
								pagination={{
									position: ["bottomLeft"],
									// pageSizeOptions: [8],
									// pageSize: 8,
								}}
								style={{
									textAlign: "center",
									backgroundColor: "white",
								}}
								rowClassName="document-table-row"
								onRow={(record, rowIndex) => {
									return {
										onClick: () => {
											this.setState({
												loading: true,
												tip: "Retrieving File...",
											});
											handleOpenDocument({
												open: true,
												pdfViewer: true, // Open the drawer immediately
												fileObject: null, // Keep it null or show some loading indication inside the drawer
												loading: true, // Maintain loading state
											});
											getDocument(record.name, record.container, record.type)
												.then((res: any) => {
													handleOpenDocument({
														pdfViewer: true,
														fileObject: {
															record: record,
															key: record.name,
															children: res,
															label: record.name,
														},
														loading: false,
														open: true,
													});

													this.setState({ loading: false });
												})
												.catch((error: any) => {
													this.setState({ loading: false });
													Emitter.emit("alert", {
														type: "error",
														message: "Failed to Retrive File",
														description:
															"Failed to retrieve file, there may be an issue with the server",
														timeout: 5000,
													});
												});
										},
									};
								}}
								dataSource={this.state.filterdata}
							>
								<Column
									align="center"
									title="Type"
									key="type"
									render={(a: any) => {
										const classname = GetFileType(a.s_name);
										return <div className={`${classname} file-icons`}></div>;
									}}
								/>
								<Column
									align="left"
									title="Name"
									dataIndex="s_name"
									key="name"
									width="20%%"
								/>
								{!this.props.ismobile && (
									<Column
										width={"10%"}
										align="left"
										title="Container"
										dataIndex="container"
										key="container"
									/>
								)}

								<Column
									width={"10%"}
									align="center"
									title="Last Modified"
									key="lmd"
									render={(item: any, record: any) => {
										if (item.lastmodified)
											return (
												<>
													{new Date(item.lastmodified).toLocaleDateString(
														"en-GB"
													)}
												</>
											);
										else return null;
									}}
								/>
								{!this.props.ismobile && (
									<Column
										align="left"
										title="Tags"
										key="tags"
										render={(_: any, row: any) => {
											let tags: any = {};
											if (row.tags || row.metadata) {
												Object.assign(tags, row["tags"], row["metadata"]);
											} else {
												return null;
											}
											return (
												<>
													{Object.values(tags).map((tag: any) => {
														let color = tag.length > 5 ? "geekblue" : "green";
														if (tag === "loser") {
															color = "volcano";
														}
														if (typeof tag === "number") {
															color = "yellow";
														}
														return (
															<Tag color={color} key={tag}>
																{tag.toUpperCase()}
															</Tag>
														);
													})}
												</>
											);
										}}
									/>
								)}
							</Table>
						) : (
							<>{this.state.searchedData ? filterFiles() : null}</>
						)}
					</Spin>
				</Modal>
			);
		};

		const renderContent = () => {
			return (
				<>
					{" "}
					{!this.props.ismobile ? (
						<Spin
							style={{ color: "rgb(77, 77, 77)", overflow: "auto" }}
							size="large"
							tip={this.state.tip}
							indicator={
								<img
									alt="Portal logo"
									src={this.state.mode ? Portal_Icon_Logo : Portal_Logo}
								/>
							}
							className={"antd-spin-blink"}
							wrapperClassName={"loading-wrapper"}
							spinning={this.state.loading}
						>
							<>
								{this.state.mode ? (
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											width: "100%",
											height: "100%",
											overflow: "hidden",
										}}
									>
										<div
											style={{
												display: "flex",
												flex: "1",
												padding: "24px",
												gap: "24px",
												overflow: "auto",
											}}
										>
											{/* <div> */}
											<div
												style={{
													width: "60%",
													display: "flex",
													flexDirection: "column",
												}}
											>
												<div
													style={{
														height: "56px",
														width: "100%",
														minHeight: "56px",
														background: "#141414",
														display: "flex",
														alignItems: "center",
													}}
												>
													<span
														style={{
															fontSize: "16px",
															fontWeight: "600",
															fontFamily: "Titillium Web",
															paddingLeft: "24px",
															color: "rgba(255,255,255,0.85)",
														}}
													>
														Folders
													</span>
													<Search
														className="document-search"
														style={{
															width: "200px",
															marginLeft: "auto",
														}}
														placeholder="Search..."
														onSearch={(value: any) => this.searchDocs(value)}
														allowClear
														loading={this.state.searchLoading}
													/>
													{this.state.super ? (
														<Button
															style={{
																width: "70px",
																marginLeft: "16px",
																marginRight: "28px",
																background: "#141414",
															}}
															className="button-general"
															onClick={() =>
																this.setState({
																	newFolderModal: true,
																	newFolder: {},
																})
															}
														>
															+ New
														</Button>
													) : null}
												</div>
												<div
													className="main-folder-body"
													style={{
														flex: "1",
														background: "rgba(31, 31, 31, 0.85)",
														padding: "10px",
													}}
												>
													{" "}
													<div
														style={{
															padding: "5px 5px",
														}}
														className="folder-bottom-container"
													>
														{this.state.documents !== "clean" ? (
															<>{FolderArrange()}</>
														) : null}
													</div>
												</div>
											</div>
											<div
												style={{
													flex: "1",
													display: "flex",
													flexDirection: "column",
												}}
											>
												<div
													style={{
														height: "56px",
														minHeight: "56px",
														width: "100%",
														background: "#141414",
														display: "flex",
														alignItems: "center",
													}}
												>
													<span
														style={{
															fontSize: "16px",
															fontWeight: "600",
															fontFamily: "Titillium Web",
															paddingLeft: "24px",
															color: "rgba(255,255,255,0.85)",
														}}
													>
														Recently Modified
													</span>
												</div>
												<div
													className="recently-modified-folder"
													style={{
														flex: "1",
														background: "rgba(31, 31, 31, 0.85)",
														padding: "10px",
													}}
												>
													<Table
														bordered
														pagination={{
															position: ["bottomLeft"],
															pageSize: 8,
														}}
														style={{
															textAlign: "center",
														}}
														rowClassName="document-table-row"
														onRow={(record, rowIndex) => {
															return {
																onClick: () => {
																	this.setState({
																		loading: true,
																		tip: "Retrieving File...",
																	});
																	handleOpenDocument({
																		open: true,
																		pdfViewer: true, // Open the drawer immediately
																		fileObject: null, // Keep it null or show some loading indication inside the drawer
																		loading: true, // Maintain loading state
																	});
																	getDocument(
																		record.name,
																		record.container,
																		record.type
																	)
																		.then((res: any) => {
																			handleOpenDocument({
																				open: true,
																				pdfViewer: true,
																				fileObject: {
																					record: record,
																					key: record.name,
																					children: res,
																					label: record.name,
																				},
																				loading: false,
																			});

																			this.setState({ loading: false });
																		})
																		.catch((error: any) => {
																			this.setState({
																				loading: false,
																			});
																			Emitter.emit("alert", {
																				type: "error",
																				message: "Failed to Retrive File",
																				description:
																					"Failed to retrieve file, there may be an issue with the server",
																				timeout: 5000,
																			});
																		});
																},
															};
														}}
														dataSource={this.state.blobdata}
													>
														<Column
															align="center"
															title="Type"
															key="type"
															width="10%"
															render={(a: any) => {
																const classname = GetFileType(a.s_name);
																return (
																	<div
																		className={`${classname} file-icons`}
																	></div>
																);
															}}
														></Column>
														<Column
															align="left"
															title="Name"
															dataIndex="s_name"
															key="name"
														></Column>
														<Column
															align="right"
															title="Modified"
															key="lmd"
															width="10%"
															render={(item: any, record: any) => {
																return (
																	<>
																		{new Date(
																			item.lastmodified
																		).toLocaleDateString("en-GB")}
																	</>
																);
															}}
														></Column>
													</Table>
												</div>
											</div>
											{/* </div> */}
										</div>
									</div>
								) : (
									<div className="document-container">
										{/* <Breadcrumb separator=">">
											<Breadcrumb.Item>
												{" "}
												<Link to={`/${Tenant}`}>Home</Link>
											</Breadcrumb.Item>
											<Breadcrumb.Item>Documents</Breadcrumb.Item>
										</Breadcrumb> */}
										<div
											style={{
												fontSize: "3em",
												fontWeight: "bolder",
												lineHeight: "2",
											}}
										>
											Documents
										</div>
										<div className="document-main-container">
											<div
												style={{ padding: "2% 5%" }}
												className="folder-top-container"
											>
												<div className="document-sub-title">Folders</div>
												<Search
													className="document-search"
													style={{
														width: "200px",
														marginLeft: "auto",
													}}
													placeholder="Search..."
													onSearch={(value: any) => this.searchDocs(value)}
													allowClear
													loading={this.state.searchLoading}
												/>
												{this.state.super ? (
													<Button
														style={{
															width: "150px",
															marginLeft: "20px",
														}}
														className="button-general"
														onClick={() =>
															this.setState({
																newFolderModal: true,
																newFolder: {},
															})
														}
													>
														New folder
													</Button>
												) : null}
											</div>
											<div
												style={{
													display: "flex",
													alignItems: "center",
													padding: "2% 5%",
												}}
												className="recently-modified-top-container"
											>
												<div className="document-sub-title">
													Recently modified
												</div>
											</div>
											<div
												style={{ padding: "0 5%" }}
												className="folder-bottom-container"
											>
												{this.state.documents !== "clean" ? (
													<>{FolderArrange()}</>
												) : null}
											</div>
											<div
												style={{
													padding: "0% 10% 0% 5%",
												}}
												className="recently-modified-bottom-container"
											>
												<Table
													pagination={{
														position: ["bottomLeft"],
														pageSize: 8,
													}}
													rowClassName="document-table-row"
													onRow={(record, rowIndex) => {
														return {
															onClick: () => {
																this.setState({
																	loading: true,
																	tip: "Retrieving File...",
																});
																handleOpenDocument({
																	open: true,
																	pdfViewer: true, // Open the drawer immediately
																	fileObject: null, // Keep it null or show some loading indication inside the drawer
																	loading: true, // Maintain loading state
																});
																getDocument(
																	record.name,
																	record.container,
																	record.type
																)
																	.then((res: any) => {
																		handleOpenDocument({
																			pdfViewer: true,
																			fileObject: {
																				record,
																				key: record.name,
																				children: res,
																				label: record.name,
																			},
																			loading: false,
																		});
																		this.setState({ loading: false });
																	})
																	.catch((error: any) => {
																		this.setState({
																			loading: false,
																		});
																		Emitter.emit("alert", {
																			type: "error",
																			message: "Failed to Retrive File",
																			description:
																				"Failed to retrieve file, there may be an issue with the server",
																			timeout: 5000,
																		});
																	});
															},
														};
													}}
													dataSource={this.state.blobdata}
												>
													<Column
														align="center"
														title="Type"
														key="type"
														width="10%"
														render={(a: any) => {
															const classname = GetFileType(a.s_name);
															return (
																<div
																	className={`${classname} file-icons`}
																></div>
															);
														}}
													></Column>
													<Column
														align="left"
														title="Name"
														dataIndex="s_name"
														key="name"
														width="50%"
													></Column>
													<Column
														align="center"
														title="Modified"
														key="lmd"
														width="10%"
														render={(item: any, record: any) => {
															return (
																<>
																	{new Date(
																		item.lastmodified
																	).toLocaleDateString("en-GB")}
																</>
															);
														}}
													></Column>
												</Table>
											</div>
										</div>
									</div>
								)}
							</>
						</Spin>
					) : (
						<Spin
							// style={{ color: "rgb(77, 77, 77)" }}
							size="large"
							tip={this.state.tip}
							// indicator={<img alt="Portal logo" src={Portal_Logo} />}
							indicator={<img alt="Portal logo" src={Portal_Icon_Logo} />}
							className={"antd-spin-loading-mobile antd-spin-blink"}
							wrapperClassName={"loading-wrapper"}
							spinning={this.state.loading}
						>
							<div>
								<div className="document-container-mobile">
									<div
										className="document-content-header-mobile"
										style={{ padding: "20px" }}
									>
										{/* <Breadcrumb separator=">">
											<Breadcrumb.Item>
												<Link to={`/${Tenant}`}>Home</Link>
											</Breadcrumb.Item>
											<Breadcrumb.Item>Documents</Breadcrumb.Item>
										</Breadcrumb> */}
										<div
											className="document-content-header-title-mobile"
											style={{
												fontSize: "3em",
												fontWeight: "bolder",
												lineHeight: "2",
											}}
										>
											Documents
										</div>
									</div>
									<div className="document-main-container-mobile">
										<div
											style={{
												padding: "2% 5%",
												justifyContent: "space-between",
											}}
											className="folder-top-container"
										>
											<div className="document-sub-title">Folders</div>

											{this.state.super ? (
												<Button
													style={{
														width: "150px",
														marginLeft: "20px",
													}}
													className="button-general"
													onClick={() =>
														this.setState({
															newFolderModal: true,
															newFolder: {},
														})
													}
												>
													New folder
												</Button>
											) : null}
										</div>
										<div
											style={{
												padding: "2% 5%",
											}}
											className="recently-modified-top-container"
										>
											<Search
												className="document-search"
												style={{
													width: "100%",
													margin: "3% 0px",
												}}
												placeholder="Search..."
												onSearch={(value: any) => this.searchDocs(value)}
												allowClear
												loading={this.state.searchLoading}
											/>
										</div>
										<div style={{ padding: "0px 5%" }}>
											{this.state.documents !== "clean" ? (
												<>{FolderArrange()}</>
											) : null}
										</div>
										<div
											style={{ padding: "5%" }}
											className="recently-modified-bottom-container"
										>
											<div
												className="document-sub-title"
												style={{ margin: "3% 0px" }}
											>
												Recently modified
											</div>
											<Table
												pagination={{
													position: ["bottomLeft"],
													pageSize: 8,
												}}
												style={{
													textAlign: "center",
													backgroundColor: "white",
												}}
												rowClassName="document-table-row"
												onRow={(record, rowIndex) => {
													return {
														onClick: () => {
															this.setState({
																loading: true,
																tip: "Retrieving File...",
															});
															handleOpenDocument({
																open: true,
																pdfViewer: true, // Open the drawer immediately
																fileObject: null, // Keep it null or show some loading indication inside the drawer
																loading: true, // Maintain loading state
															});
															getDocument(
																record.name,
																record.container,
																record.type
															)
																.then((res: any) => {
																	handleOpenDocument({
																		open: true,
																		pdfViewer: true,
																		fileObject: {
																			record,
																			key: record.name,
																			children: res,
																			label: record.name,
																		},
																		loading: false,
																	});
																	this.setState({ loading: false });
																})
																.catch((error: any) => {
																	this.setState({
																		loading: false,
																	});
																	Emitter.emit("alert", {
																		type: "error",
																		message: "Failed to Retrieve File",
																		description:
																			"Failed to retrieve file, there may be an issue with the server",
																		timeout: 5000,
																	});
																});
														},
													};
												}}
												dataSource={this.state.blobdata}
											>
												<Column
													align="center"
													title="Type"
													key="type"
													render={(a: any) => {
														const classname = GetFileType(a.s_name);
														return (
															<div className={`${classname} file-icons`}></div>
														);
													}}
												></Column>
												<Column
													align="left"
													title="Name"
													dataIndex="s_name"
													key="name"
													width="50%"
												></Column>
												<Column
													align="center"
													title="Last Modified"
													key="lmd"
													width="50%"
													render={(item: any, record: any) => {
														return (
															<>
																{new Date(item.lastmodified).toLocaleDateString(
																	"en-GB"
																)}
															</>
														);
													}}
												></Column>
											</Table>
										</div>
									</div>
								</div>
							</div>
						</Spin>
					)}
				</>
			);
		};

		return (
			<>
				{this.props.documents ? <RenderFolders /> : null}
				{!this.state.history ? <WithRouter /> : null}
				<>
					<ComponentHook menuProps={this.props.params.mitem} />
					{renderContent()}
					{renderNewFModal()}
					{renderEditModal()}
					{renderDeleteModal()}
					{renderSearchModal()}
				</>
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		role: state.role,
		documents: state.documents,
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(Documents);
