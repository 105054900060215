import React from "react";
import "../../assets/css/Admin.css";
import { connect } from "react-redux";
import {
	Select,
	Button,
	Input,
	Radio,
	Popconfirm,
	Typography,
	Space,
	Form,
	FormInstance,
	Layout,
	Flex,
	Empty,
	ConfigProvider,
	theme,
	DatePicker,
	Switch,
	Drawer,
	Row,
	Col,
} from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Tree } from "antd";
import {
	deleteMenuItem,
	loadMenu,
	saveMenu,
	saveMenuItem,
} from "../../services/api-server/menu";
import { v4 as uuidv4 } from "uuid";
import { getComponents } from "../../services/api-server/menu";
import {
	getAppGroupMembers,
	getAppRoles,
	getAppRoles2,
	getApplicationGroups2,
	getGeneralUsers,
} from "../../services/api-server/admin";
import Emitter from "../../services/EventEmitter";
import NoAccess from "../NoAccess";
import { getNonDuplicateLabel } from "./AdminMenuManager";
import {
	addPathToMenu,
	areArraysEqual,
	createComponentRoles,
	getComponentRoles,
	removeComponentOwner,
	removeRolesFromUsers,
	setComponentOwner,
} from "../../utils/utils";
import { compareSelectOptions } from "../../utils/queryBuilder";
import { deleteRolesByMenuKey } from "../../services/api-server/roles";
import { Action, registerAction } from "../../services/api-server/actions";
import dayjs from "dayjs";
import {
	getAccessRequests,
	updateAccessRequest,
} from "../../services/api-server/access_requests";
import { socket } from "../../utils/socket";
import MapInputs from "./MapInputs";
import PowerBiInputs from "./PowerBiInputs";
import RealTimeAnalyticsInputs from "./RealTimeAnalyticsInputs";
import ScanMaxInputs from "./ScanMaxInputs";
import CustomDashboardInputs from "./CustomDashboardInputs";
import DigitalTwinInputs from "./DigitalTwinInputs";

const { Text, Title } = Typography;
const { Content, Sider, Header } = Layout;
const { RangePicker } = DatePicker;

class MenuManager extends React.Component<any> {
	state: any = {
		componentList: [],
		clean: true,
		allMenu: [],
		menu: [],
		roles: [],
		savedMenu: {},
		selectedMenu: null,
		selectedMenuInitial: null,
		mainMenu: {},
		visibleModal: false,
		inputVisible: false,
		inputValue: "",
		editInputIndex: -1,
		editInputValue: "",
		folders: [],
		allowDuplicate: true,
		collapsed: true,
	};

	formRef = React.createRef<FormInstance>();

	componentDidMount() {
		this.loadData();
		this.getRoles();
		this.getUsers();
		getComponents()
			.then((data: any) => {
				this.setState({ componentList: data });
			})
			.catch((error: any) => {});

		if (this.props.documents?.folders) {
			let Allfolders = this.props.documents.folders;
			let folders: any[] = [];
			Allfolders.forEach((element: any) => {
				folders.push({
					label: element.name,
					value: element.name,
				});
			});
			this.setState({ folders: folders });
		}

		Emitter.on("MenuReload", (payload: any) => {
			if (payload === undefined) {
				payload = { menu: this.props.allModules.mainMenu };
			} else {
				let routes: Array<string> = this.getAllRoutes(payload.menu);
				this.setState({ allRoutes: routes });
			}
			this.setState({ menu: payload.menu });
		});
	}

	toggleSidebar = () => {
		this.setState((prevState: any) => ({
			collapsed: !prevState.collapsed,
		}));
	};

	loadData = () => {
		loadMenu().then((data: any) => {
			if (data === undefined) {
				data = { menu: this.props.allModules.mainMenu };
				this.setState({ menu: data.menu });
			} else {
				let routes: Array<string> = this.getAllRoutes(data.menu);
				this.setState({ allRoutes: routes });
				this.setState({ menu: data.menu });
			}
		});
	};

	getRoles = () => {
		getAppRoles().then((data: any) => {
			if (data) {
				this.setState({ roles: data });
			}
		});
	};

	getUsers = () => {
		getGeneralUsers()
			.then((users: any) => {
				this.setState({ users });
			})
			.catch((err) => {
				console.error(err);
			});
	};

	// recursive function to get routes of menu
	getRoutes = (menuItems: any, parent: string = "", all?: boolean) => {
		let routes: Array<string> = [];

		menuItems.forEach((menuItem: any) => {
			let routeObject: any = { key: "", route: "", title: "" };

			const route = menuItem?.route?.link;
			const itemKey = menuItem?.key;
			const title = menuItem?.title;

			if (route && (!menuItem?.children || all)) {
				const fullPath = `${parent}/${route}`;

				routes.push({ ...routeObject, key: itemKey, route: fullPath, title });
			}

			if (menuItem?.children) {
				const childRoutes = this.getRoutes(
					menuItem.children,
					`${parent}/${route}`,
					all
				);
				routes.push(...childRoutes);
			}
		});

		return routes;
	};

	/**
	 *
	 * @param menuItems array of menuItems
	 * @param all to include the parents as a route, false to only get all non parent route
	 * @returns
	 */
	getAllRoutes = (menuItems: any, all: boolean = true) => {
		let allRoutes: Array<any> = [];

		allRoutes = this.getRoutes(menuItems, "", all);
		// console.log("get all routes: ", allRoutes.length);

		return allRoutes;
	};

	getPath = (key: any) => {
		const treeData = this.state.menu;

		const findNode = (data: any, nodeKey: any, path: any) => {
			for (let i = 0; i < data.length; i++) {
				const node = data[i];
				if (node.key === nodeKey) {
					// path.push(node.title);
					path.push(node.route.link);
					return true;
				} else if (node.children) {
					// path.push(node.title);
					path.push(node.route.link);
					if (findNode(node.children, nodeKey, path)) {
						return true;
					} else {
						path.pop();
					}
				}
			}
			return false;
		};

		const path: any = [];
		findNode(treeData, key, path);
		return `/${path.join("/")}`;
	};

	cancelChanges = () => {
		this.setState({
			selectedMenu: null,
			selectedMenuInitial: null,
			new: false,
			clean: true,
			titleError: false,
		});
		this.loadData();
		this.formRef.current?.resetFields();
	};

	// recursive function to update the route by key
	updateRouteByKey = (menuItems: any, key: string, newRoute: string) => {
		let updatedMenu: any;

		updatedMenu = menuItems.map((menuItem: any) => {
			if (menuItem.key === key) {
				return { ...menuItem, route: { link: newRoute } };
			} else if (menuItem.children) {
				const updatedChildren = this.updateRouteByKey(
					menuItem.children,
					key,
					newRoute
				);

				return { ...menuItem, children: updatedChildren };
			} else {
				return menuItem;
			}
		});

		if (updatedMenu !== undefined) return updatedMenu;
	};

	saveChanges = async (values: any) => {
		// console.log({ state: this.state.selectedMenu });

		let payload: any = {};

		const updatedViewers = this.state.selectedMenu?.viewers?.filter(
			(user: string) => !values?.owners?.includes(user)
		);

		const _menu = {
			...this.state.selectedMenu,
			...values,
			viewers: updatedViewers || [],
			createdAt: dayjs().toISOString(),
			path: this.state.selectedMenu?.path || [
				this.state.selectedMenu?.route?.link,
			],
		};

		// this is temporary to track menu roles was updated
		// console.log(this.state.selectedMenu);
		// console.log(values);
		// let wholemenu: any[] | null = this.state.menu;

		// const find_replace: any = (arr: any[] | null, key: string, obj: any) => {
		// 	if (arr?.length) {
		// 		return arr.map((o: any, index: number) => {
		// 			if (o.children && o.children.length && o.children.length > 0) {
		// 				if (o.key === key) {
		// 					return {
		// 						...o,
		// 						title: obj.title,
		// 						component: obj.component,
		// 						children: obj.children,
		// 						e_identity: obj.e_identity,
		// 						paginated: obj.paginated,
		// 						powerbi_roles: obj.powerbi_roles,
		// 						level: obj.level,
		// 						dashboard_preset: obj.dashboard_preset,
		// 						roles: obj.roles,
		// 						key: obj.key,
		// 						route: obj.route,
		// 						dt_rig: obj.dt_rig,
		// 						dt_data: obj.dt_data,
		// 						kognifai_shared_link: obj.kognifai_shared_link,
		// 						folder: obj.folder,
		// 						directory: obj.directory,
		// 						asset: obj.asset,
		// 						workspaceid: obj.workspaceid,
		// 						reportid: obj.reportid,
		// 						datasetid: obj.datasetid,
		// 						enable_custom_roles: obj.enable_custom_roles,
		// 						dashboard_name: obj.dashboard_name,
		// 						dashboard_layout: obj.dashboard_layout,
		// 						dashboard_layouts: obj.dashboard_layouts,
		// 						owners: obj.owners,
		// 						viewers: obj.viewers,
		// 						data_unauthorized: obj.data_unauthorized,
		// 						schema_unauthorized: obj.schema_unauthorized,
		// 						component_access: obj.component_access,
		// 						description: obj.description,
		// 						createdAt: obj.createdAt,
		// 						new_duration: obj.new_duration,
		// 					};
		// 				} else {
		// 					let newmenuitem: any = {
		// 						title: o.title,
		// 						key: o.key,
		// 						component: o.component,
		// 						e_identity: o.e_identity,
		// 						paginated: o.paginated,
		// 						powerbi_roles: o.powerbi_roles,
		// 						level: o.level,
		// 						dashboard_preset: o.dashboard_preset,
		// 						roles: o.roles,
		// 						route: o.route,
		// 						dt_rig: o.dt_rig,
		// 						dt_data: o.dt_data,
		// 						kognifai_shared_link: o.kognifai_shared_link,
		// 						children: find_replace(o.children, key, obj),
		// 						folder: o.folder,
		// 						directory: o.directory,
		// 						asset: o.asset,
		// 						workspaceid: o.workspaceid,
		// 						reportid: o.reportid,
		// 						datasetid: o.datasetid,
		// 						enable_custom_roles: o.enable_custom_roles,
		// 						dashboard_name: o.dashboard_name,
		// 						dashboard_layout: o.dashboard_layout,
		// 						dashboard_layouts: o.dashboard_layouts,
		// 						owners: o.owners,
		// 						viewers: o.viewers,
		// 						data_unauthorized: o.data_unauthorized,
		// 						schema_unauthorized: o.schema_unauthorized,
		// 						component_access: o.component_access,
		// 						description: o.description,
		// 						createdAt: o.createdAt,
		// 						new_duration: o.new_duration,
		// 					};
		// 					return newmenuitem;
		// 				}

		// 				// return find_replace(o.children,key,obj)
		// 			} else {
		// 				if (o.key === key) {
		// 					return {
		// 						title: obj.title,
		// 						key: obj.key,
		// 						level: obj.level,
		// 						component: obj.component,
		// 						dashboard_preset: obj.dashboard_preset,
		// 						e_identity: obj.e_identity,
		// 						paginated: obj.paginated,
		// 						powerbi_roles: obj.powerbi_roles,
		// 						roles: obj.roles,
		// 						route: obj.route,
		// 						dt_rig: obj.dt_rig,
		// 						dt_data: obj.dt_data,
		// 						kognifai_shared_link: obj.kognifai_shared_link,
		// 						folder: obj.folder,
		// 						directory: obj.directory,
		// 						asset: obj.asset,
		// 						workspaceid: obj.workspaceid,
		// 						reportid: obj.reportid,
		// 						datasetid: obj.datasetid,
		// 						enable_custom_roles: obj.enable_custom_roles,
		// 						dashboard_name: obj.dashboard_name,
		// 						dashboard_layout: obj.dashboard_layout,
		// 						dashboard_layouts: obj.dashboard_layouts,
		// 						owners: obj.owners,
		// 						viewers: obj.viewers,
		// 						data_unauthorized: obj.data_unauthorized,
		// 						schema_unauthorized: obj.schema_unauthorized,
		// 						component_access: obj.component_access,
		// 						new_duration: obj.new_duration,
		// 					};
		// 				} else {
		// 					return {
		// 						title: o.title,
		// 						key: o.key,
		// 						level: o.level,
		// 						dashboard_preset: o.dashboard_preset,
		// 						component: o.component,
		// 						e_identity: o.e_identity,
		// 						paginated: o.paginated,
		// 						powerbi_roles: o.powerbi_roles,
		// 						roles: o.roles,
		// 						route: o.route,
		// 						dt_rig: o.dt_rig,
		// 						dt_data: o.dt_data,
		// 						kognifai_shared_link: o.kognifai_shared_link,
		// 						folder: o.folder,
		// 						directory: o.directory,
		// 						asset: o.asset,
		// 						workspaceid: o.workspaceid,
		// 						reportid: o.reportid,
		// 						datasetid: o.datasetid,
		// 						enable_custom_roles: o.enable_custom_roles,
		// 						dashboard_name: o.dashboard_name,
		// 						dashboard_layout: o.dashboard_layout,
		// 						dashboard_layouts: o.dashboard_layouts,
		// 						owners: o.owners,
		// 						viewers: o.viewers,
		// 						data_unauthorized: o.data_unauthorized,
		// 						schema_unauthorized: o.schema_unauthorized,
		// 						component_access: o.component_access,
		// 						new_duration: o.new_duration,
		// 					};
		// 				}
		// 			}
		// 		});
		// 	} else {
		// 		return null;
		// 	}
		// };

		// if (_menu !== undefined && _menu !== null) {
		// 	if (
		// 		this.state.new === true &&
		// 		wholemenu?.findIndex((element: any) => element.key === _menu.key) === -1
		// 	) {
		// 		wholemenu.push(_menu);
		// 	} else {
		// 		wholemenu = find_replace(wholemenu, _menu.key, _menu);
		// 	}
		// }

		// addPathToMenu(wholemenu);

		try {
			// await saveMenu(wholemenu);
			await saveMenuItem(_menu);

			const requests = await getAccessRequests();

			const { owners: currOwners = [] } = values;

			if (this.state.new) {
				// console.log("new record");
				const roles: Array<any> = await createComponentRoles(_menu);
				const ownerRole = roles?.find((role) => role.name.includes("owner"));

				const promises = currOwners?.map((owner: any) =>
					setComponentOwner(owner, ownerRole, roles, _menu?.key)
				);

				await Promise.all(promises);
			} else {
				// console.log("existing record");
				const roles: Array<any> = await getComponentRoles(_menu);
				const ownerRole = roles?.find((role: any) =>
					role.name.includes("owner")
				);

				const { owners: prevOwners = [] } = this.state.selectedMenuInitial;
				const { owners: currOwners = [] } = values;

				if (!areArraysEqual(prevOwners, currOwners)) {
					const grantedOwners = currOwners.filter(
						(owner: any) => !prevOwners?.includes(owner)
					);

					const revokedOwners = prevOwners.filter(
						(owner: any) => !currOwners?.includes(owner)
					);

					if (grantedOwners?.length > 0) {
						const promises = grantedOwners?.map((owner: any) =>
							setComponentOwner(owner, ownerRole, roles, _menu?.key)
						);

						for (const owner of grantedOwners) {
							const request = requests.find(
								(_request) =>
									_request.requesterId === owner &&
									_request.status === "Requested" &&
									_request.menuKey === _menu?.key
							);

							if (request) {
								await updateAccessRequest({ ...request, status: "Approved" });
								socket.emit("ACCESS_REQUEST_STATUS_CHANGED");
							}
						}

						await Promise.all(promises);
					}
					if (revokedOwners?.length > 0) {
						const promises = revokedOwners?.map((owner: any) =>
							removeComponentOwner(
								owner,
								ownerRole,
								this.props.user?.name,
								_menu?.key
							)
						);
						await Promise.all(promises);
					}
				}
			}

			// handle menu roles
			const { roles: prevRoles = [] } = this.state.selectedMenuInitial;
			const { roles: currRoles = [] } = values;

			if (!areArraysEqual(prevRoles, currRoles)) {
				// determine which are granted and revoked
				const granted = currRoles?.filter(
					(role: any) => !prevRoles?.includes(role)
				);
				const revoked = prevRoles?.filter(
					(role: any) => !currRoles?.includes(role)
				);

				// console.log({ granted, revoked });

				if (granted?.length > 0) {
					const promises = granted?.map((role: string) => {
						const action: Action = {
							actionType: "VIEWERSHIP_GRANTED",
							approvedBy: this.props.user?.name,
							approvedDate: dayjs().toISOString(),
							menuKey: _menu?.key,
							user_id: role,
						};
						return registerAction(action, _menu?.key);
					});

					await Promise.all(promises);

					const currentMenuPendingRequests = requests?.filter(
						(_request: any) =>
							_request?.menuKey === _menu?.key &&
							_request?.status === "Requested"
					);

					const requestsToBeUpdated: any = [];

					if (currentMenuPendingRequests.length > 0) {
						const appRoles: any = await getAppRoles2();
						const groups: any = await getApplicationGroups2();
						for (const gRole of granted) {
							const foundRole = appRoles?.find(
								(_roleObj: any) => _roleObj?.displayName === gRole
							);

							// getting all groups that has this role
							const groupsWithRole = groups?.filter(
								(_group: any) => _group?.appRoleId === foundRole?.id
							);

							for (const group of groupsWithRole) {
								// get users of the group
								const groupUsers: any = await getAppGroupMembers(
									group?.principalId
								);
								// console.log(groupUsers);

								// only run when there are other pending requests
								if (
									requestsToBeUpdated.length !==
									currentMenuPendingRequests.length
								) {
									currentMenuPendingRequests?.forEach((_request: any) => {
										// to ensure no pushing the same requests to be further processed
										if (
											!requestsToBeUpdated?.find(
												(_requestToBeUpdated: any) =>
													_requestToBeUpdated?.id === _request?.id
											)
										) {
											requestsToBeUpdated.push(_request);
										}
									});
								}
							}
						}

						if (requestsToBeUpdated?.length > 0) {
							for (const _request of requestsToBeUpdated) {
								await updateAccessRequest({ ..._request, status: "Approved" });
							}
							socket.emit("ACCESS_REQUEST_STATUS_CHANGED");
						}
					}
				}

				if (revoked?.length > 0) {
					const promises = revoked?.map((role: string) => {
						const action: Action = {
							actionType: "VIEWERSHIP_REVOKED",
							approvedBy: this.props.user?.name,
							approvedDate: dayjs().toISOString(),
							menuKey: _menu?.key,
							user_id: role,
						};
						return registerAction(action, _menu?.key);
					});

					await Promise.all(promises);
				}
			}

			this.setState({ clean: true, selectedMenu: null, new: false });
			this.loadData();

			payload.type = "success";
			payload.message = "Menu Saved Successfully";
			payload.description =
				"The menu has been saved, please refresh to view your changes";
			payload.timeout = 5000;
			Emitter.emit("alert", payload);

			// get the updated routes
			const data: any = await loadMenu();
			let routes: Array<string> = this.getAllRoutes(data.menu);
			this.setState({ allRoutes: routes, selectedMenu: null });
			Emitter.emit("MenuSaved", true);
			this.formRef.current?.resetFields();
		} catch (err) {
			console.error(err);
		}
	};

	setSelectedModule = (key: any, node: any) => {
		const menuItem = {
			...node,
			component_access: node?.component_access ?? "restricted_hidden",
			new_duration: node?.new_duration
				? [dayjs(node.new_duration?.[0]), dayjs(node.new_duration?.[1])]
				: null,
		};

		this.setState({ selectedMenu: menuItem, selectedMenuInitial: menuItem });

		if (this.formRef?.current) {
			this.formRef.current.resetFields();
			this.formRef.current.setFieldsValue(menuItem);
		}

		if (this.props.documents?.folders) {
			let Allfolders = this.props.documents.folders;
			if (node?.folder && node?.component === "Document Folder") {
				let found: any = Allfolders.find(
					(element: any) => node?.folder === element.name
				);
				let directories: any[] = [];
				if (found.dir) {
					found.dir.forEach((element: any) => {
						directories.push({
							label: element.dir_struct,
							value: element.dir_struct,
						});
					});
				} else {
					delete this.state.selectedMenu?.directory;
					this.formRef.current?.setFieldValue("directory", null);
				}
				this.setState({ directories: directories });
			}
		}
	};

	addNewMenuItem = () => {
		const uuid: string = uuidv4();
		const menu = [...this.state.menu];
		const menuTitle = getNonDuplicateLabel(uuid, menu);

		const newItem = {
			title: menuTitle,
			key: uuid,
			route: { link: "" },
			component_access: "restricted_hidden",
		};
		menu.push(newItem);
		this.formRef.current?.resetFields();
		this.formRef.current?.setFieldsValue({
			title: newItem.title,
			component_access: newItem.component_access,
		});
		this.setState({
			menu: menu,
			clean: false,
			selectedMenu: newItem,
			selectedMenuInitial: newItem,
			selectedMenuPath: "/",
			new: true,
		});
	};

	deleteMenuItem = () => {
		let payload: any = {};
		let menu = this.state.menu;
		let selected_menuitem = this.state.selectedMenu;

		const remove_menu = (menuitems: any, key: string) => {
			if (!menuitems) return;

			return menuitems.reduce((acc: any, child: any) => {
				if (child?.key === key) {
					const removedChild = remove_menu(child.children, key);
					acc.children = removedChild;
				} else {
					child.children = remove_menu(child.children, key);
					acc.push(child);
				}
				return acc;
			}, []);
		};

		menu = remove_menu(menu, selected_menuitem.key);
		this.setState({ menu: menu });

		// saveMenu(menu)
		deleteMenuItem(selected_menuitem).then((d: any) => {
			this.setState({
				clean: true,
				selectedMenu: null,
				selectedMenuInitial: null,
				new: false,
			});
			this.loadData();

			removeRolesFromUsers(selected_menuitem.key).then(() => {
				// delete roles
				deleteRolesByMenuKey(selected_menuitem.key)
					.then((response: any) => {
						// console.log(response);
						payload.type = "success";
						payload.message = "Menu Deleted Successfully";
						payload.description = "The selected menu item is deleted";
						payload.timeout = 5000;
						Emitter.emit("alert", payload);
						Emitter.emit("MenuSaved", true);
						this.formRef.current?.resetFields();
					})
					.catch((err) => {
						console.log(err);
					});
			});
		});
	};

	onItemDrop = (info: any) => {
		const dropKey: any = info.node.key;
		const dragKey: any = info.dragNode.key;
		const dropPos: any = info.node.pos.split("-");
		const dropLevel: any = dropPos.length - 2;
		const dropToGap: any = info.dropToGap;
		const dropPosition =
			info.dropPosition - Number(dropPos[dropPos.length - 1]);

		// prevent drop if target node is greater than 3
		if (dropLevel >= 2 && dropToGap === false) {
			return false;
		}

		const loop = (data: any, key: any, callback: any) => {
			for (let i: number = 0; i < data.length; i++) {
				if (data[i].key === key) {
					return callback(data[i], i, data);
				}
				if (data[i].children && data[i].children.length) {
					loop(data[i].children, key, callback);
				}
			}
		};
		let data = [...this.state.menu];

		let dragObj: any;
		loop(data, dragKey, (item: any, index: any, arr: any) => {
			arr.splice(index, 1);
			dragObj = item;
		});

		if (!info.dropToGap) {
			loop(data, dropKey, (item: any) => {
				item.children = item.children || [];
				item.children.unshift(dragObj);
			});
		} else if (
			(info.node.props.children || []).length > 0 &&
			info.node.props.expanded &&
			dropPosition === 1
		) {
			loop(data, dropKey, (item: any) => {
				item.children = item.children || [];
				item.children.unshift(dragObj);
			});
		} else {
			let ar: any;
			let i: any;
			loop(data, dropKey, (item: any, index: any, arr: any) => {
				ar = arr;
				i = index;
			});
			if (dropPosition === -1) {
				ar.splice(i, 0, dragObj);
			} else {
				ar.splice(i + 1, 0, dragObj);
			}
		}

		let routes: Array<any> = this.getAllRoutes(data);
		let draggedItem = this.state.draggedItem.node;
		let draggedItemKey = draggedItem.key;
		let draggedItemPath: string = this.getPath(draggedItemKey);

		let filteredRoutes: Array<any> = routes.filter(
			(routeObject: any) => routeObject.key !== draggedItemKey
		);

		// if there are duplicates
		if (
			filteredRoutes.find((routeObject: any) =>
				Object.values(routeObject).includes(draggedItemPath)
			)
		) {
			// console.log({ filteredRoutes, draggedItemPath });

			let renamedRoute = this.getNonDuplicateRoutes(
				filteredRoutes,
				draggedItemPath
			);
			data = this.updateRouteByKey(data, draggedItemKey, renamedRoute);
		}

		addPathToMenu(data);

		saveMenu(data).then((_d: any) => {
			loadMenu().then((_d: any) => {
				Emitter.emit("MenuSaved", true);
				let allRoutes: Array<string> = this.getAllRoutes(_d.menu);
				this.setState({ allRoutes: allRoutes });
			});
		});

		this.setState({ menu: data });
	};

	onItemDragStart = (info: any) => {
		this.setState({ draggedItem: info });
	};

	getMenuLabels = (menu: any) => {
		let labels: Array<any> = [];

		menu.forEach((menuItem: any) => {
			if (menuItem?.title?.length)
				labels.push({ key: menuItem.key, title: menuItem.title });

			if (menuItem?.children?.length) {
				let childLabels: any = this.getMenuLabels(menuItem.children);
				labels.push(...childLabels);
			}
		});

		return labels;
	};

	getNonDuplicateRoutes = (routes: Array<any>, route: string) => {
		let count = 1;
		let result = route;
		while (
			routes.find((routeObject: any) =>
				Object.values(routeObject).includes(result)
			)
		) {
			result = `${route}-${count}`;
			count++;
		}

		let routeArray: Array<string> = result.split("/");
		return routeArray[routeArray.length - 1];
	};

	render = () => {
		const {} = this.state;

		const menu: any = this.state.menu;

		const usersOptions = this.state.users?.map((user: any) => {
			return {
				label: user?.userPrincipalName || user?.principalDisplayName,
				value: user?.principalId || user?.id,
			};
		});

		const dataChanged = (l: string, v: any) => {
			this.setState({ clean: false });
			let data = this.state.selectedMenu;

			if (l === "folder") {
				if (this.props.documents?.folders) {
					let Allfolders = this.props.documents.folders;
					let found: any = Allfolders.find(
						(element: any) => v === element.name
					);
					let directories: any[] = [];
					if (found.dir) {
						found.dir.forEach((element: any) => {
							directories.push({
								label: element.dir_struct,
								value: element.dir_struct,
							});
						});
					}
					delete this.state.selectedMenu?.directory;
					this.formRef.current?.setFieldValue("directory", null);

					this.setState({ directories: directories });
				}
			} else if (l === "component" && v === "PowerBI") {
				if (!this.state.selectedMenu?.powerbi_role) {
					const powerbi_roles: any = [];
					const powerbi_role: any = { role_key: "", users: [], roles: [] };
					powerbi_roles.push(powerbi_role);
					data["powerbi_roles"] = powerbi_roles;
					this.setState({ selectedMenu: data });
				}
			}

			data[`${l}`] = v;

			// this.setState({ selectedMenu: data });
		};

		const dataChanged2 = (changedValues: any) => {
			this.setState({
				selectedMenu: { ...this.state.selectedMenu, ...changedValues },
				clean: false,
			});
		};

		const onDataAccessChange = (
			authorizedDataset: Array<string>,
			authorizedSchemas: Array<string>
		) => {
			this.setState({ clean: false });
			let data = { ...this.state.selectedMenu };
			data["data_authorized"] = authorizedDataset;
			data["schema_authorized"] = authorizedSchemas;
			this.setState({ selectedMenu: data });
		};

		const confirmCancel = () => {
			/*
			when cancelled the changes to the selectedMenu should be discarded
			*/
			if (this.state.clean === false)
				return (
					<Form.Item style={{ marginTop: 16 }}>
						<Space>
							<Button
								key={"confirmbutton"}
								className="confirm"
								htmlType="submit"
							>
								Confirm
							</Button>
							<Button
								key={"cancelbutton"}
								className="cancel"
								onClick={this.cancelChanges}
							>
								Cancel
							</Button>
						</Space>
					</Form.Item>
				);

			return null;
		};

		const renderSelectedMenu2 = () => {
			switch (this.state.selectedMenu?.component) {
				case "Custom Dashboard":
					return (
						<CustomDashboardInputs
							data_authorized={this.state.selectedMenu?.data_authorized}
							schema_authorized={this.state.selectedMenu?.schema_authorized}
							onDataAccessChange={onDataAccessChange}
						/>
					);
				case "Map":
					return <MapInputs />;
				case "ScanMAX":
					return <ScanMaxInputs />;
				case "Real-time Analytics":
					return <RealTimeAnalyticsInputs />;
				case "PowerBI":
					return (
						<PowerBiInputs
							e_identity={this?.state?.selectedMenu?.e_identity}
							paginated={this?.state?.selectedMenu?.paginated}
							enable_custom_roles={
								this?.state?.selectedMenu?.enable_custom_roles
							}
						/>
					);
				case "Dashboard":
					return (
						<>
							<Form.Item>
								<Text style={{ fontSize: 20 }}>Dashboard Settings</Text>
							</Form.Item>
							<Form.Item label="Dashboard Preset" name={"dashboard_preset"}>
								<Select
									className={"input"}
									getPopupContainer={(trigger: any) => trigger.parentNode}
									onChange={(ev: any) => {
										dataChanged("dashboard_preset", ev);
									}}
									options={[
										{ label: "Plotly dashboard", value: "dashboard1" },
										{
											label: "Figma Perfect Days Leaderboard demo",
											value: "dashboard2",
										},
										{
											label: "PD leaderboard",
											value: "dashboard3",
										},
									]}
								/>
							</Form.Item>
						</>
					);
				case "Document Folder":
					return (
						<>
							<Form.Item>
								<Text style={{ fontSize: 20 }}>Document Folder Settings</Text>
							</Form.Item>
							<Form.Item
								label="Folder"
								name={"folder"}
								rules={[
									{
										required: true,
										message: "Please select a folder",
									},
								]}
							>
								<Select
									className={"input"}
									getPopupContainer={(trigger: any) => trigger.parentNode}
									onChange={(ev: any) => {
										dataChanged("folder", ev);
									}}
									options={this.state?.folders}
								/>
							</Form.Item>
							<Form.Item label="Directory Selector" name={"directory"}>
								<Select
									className={"input"}
									getPopupContainer={(trigger: any) => trigger.parentNode}
									onChange={(ev: any) => {
										dataChanged("directory", ev);
									}}
									options={this.state?.directories}
								/>
							</Form.Item>
						</>
					);
				case "Digital Twin":
				case "Loading Trial":
					return <DigitalTwinInputs formRef={this.formRef.current} />;

				default:
					return (
						<Flex
							justify="center"
							align="center"
							style={{
								height: "100%",
								background: "#fafafa",
								border: "1px solid #D9D9D9",
							}}
						>
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description={
									<Space direction="vertical">
										<Text style={{ fontSize: 20 }}>Component Settings</Text>
										<Text>
											Select a component type to view associated settings
										</Text>
										<Form.Item name={"component"}>
											<Select
												style={{ width: 200, textAlign: "start" }}
												placeholder="Select component"
												options={this.state.componentList
													?.filter((element: any) => !element.disabled)
													.map((element: any) => ({
														label: element.key,
														value: element.key,
													}))}
												onChange={(ev: any) => dataChanged("component", ev)}
											/>
										</Form.Item>
									</Space>
								}
							/>
						</Flex>
					);
			}
		};
		const checkRoles = () => {
			if (this.props.role.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
				return true;
			} else if (
				this.props.params?.roles?.length === 0 ||
				!this.props.params?.roles
			) {
				return true;
			} else {
				for (var i = 0; i < this.props.params?.roles?.length; i++) {
					if (this.props.params?.roles[i]?.toLowerCase().includes("admin")) {
						return true;
					}
				}
			}
		};

		const { collapsed } = this.state;
		return (
			<ConfigProvider
				theme={{
					algorithm: theme.defaultAlgorithm,
					inherit: false,
				}}
			>
				{checkRoles() ? (
					<Layout
						style={{
							height: "100%",
							background: "#fafafb",
							padding: "32px 32px 0",
							gap: 24,
						}}
					>
						<Header style={{ height: "auto", background: "transparent" }}>
							<Title style={{ fontWeight: 400, margin: 0 }}>Menu Manager</Title>
						</Header>

						<Layout
							style={{ background: "transparent", gap: 8, overflow: "hidden" }}
						>
							<Button
								icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
								type="text"
								onClick={this.toggleSidebar}
							/>

							<Sider
								style={{ background: "transparent" }}
								collapsible
								collapsed={collapsed}
								collapsedWidth={0}
								trigger={null}
							>
								<Tree
									style={{ background: "#fafafb" }}
									disabled={this.state.clean === false}
									draggable={true}
									showLine
									treeData={menu}
									onDragStart={this.onItemDragStart}
									onDrop={this.onItemDrop}
									onClick={(ev: any) => {
										this.setState({ inputVisible: false });
									}}
									onSelect={(selected: any, info: any) => {
										if (info.selected) {
											this.setSelectedModule(
												selected[0],
												info?.selectedNodes?.[0] || {}
											);
										} else {
											this.setState({
												selectedMenu: null,
												selectedMenuInitial: null,
											});
											this?.formRef?.current?.resetFields();
										}
									}}
									selectedKeys={[this.state.selectedMenu?.key]}
								/>
								<Button
									style={{ width: "200px", marginTop: "5%" }}
									onClick={this.addNewMenuItem}
									disabled={!this.state.clean}
								>
									Add New Menu Item
								</Button>

								<Popconfirm
									title={"Confirm deletion?"}
									placement={"bottom"}
									okText="Yes"
									cancelText="No"
									disabled={!this.state.selectedMenu ? true : false}
									onConfirm={() => this.deleteMenuItem()}
									rootClassName="menu-manager-popconfirm"
								>
									<Button
										style={{ width: "200px" }}
										disabled={!this.state.selectedMenu ? true : false}
									>
										Delete Menu Item
									</Button>
								</Popconfirm>
							</Sider>

							<Content
								style={{
									background: "white",
									boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
									padding: 16,
									overflow: "auto",
								}}
							>
								<Form
									ref={this.formRef}
									style={{ height: "100%" }}
									layout="vertical"
									disabled={this.state.selectedMenu === null}
									onFinish={this.saveChanges}
									requiredMark={"optional"}
									onValuesChange={(changedValues, values) => {
										dataChanged2(changedValues);
									}}
								>
									<Row gutter={[16, 16]} style={{ height: "100%" }}>
										<Col xs={24} md={8}>
											<Form.Item style={{ marginBottom: 8 }}>
												<Text style={{ fontSize: 20 }}>General</Text>
											</Form.Item>

											<Form.Item
												label="Title"
												name={"title"}
												rules={[
													{
														required: true,
														message: "Please enter a menu label",
													},
													{
														message: "Title must be unique",
														validator: (rule, value, callback) => {
															const filteredRoutes =
																this.state.allRoutes?.filter(
																	(routeObj: any) =>
																		routeObj?.key !==
																		this.state.selectedMenu?.key
																);
															const found = filteredRoutes?.find(
																(routeObj: any) =>
																	routeObj?.title?.toLowerCase() ===
																	value?.toLowerCase()?.trim()
															);
															if (found) {
																return Promise.reject();
															} else {
																return Promise.resolve();
															}
														},
													},
												]}
											>
												<Input
													count={{
														show: true,
														max: 20,
													}}
													style={{ borderRadius: 2 }}
												/>
											</Form.Item>
											<Form.Item
												label="Route"
												name={["route", "link"]}
												rules={[
													{
														required: true,
														message: "Please enter a menu route",
													},
													{
														message: "Route must be unique",
														validator: (rule, value, callback) => {
															const filteredRoutes =
																this.state.allRoutes?.filter(
																	(routeObj: any) =>
																		routeObj?.key !==
																		this.state.selectedMenu?.key
																);
															const found = filteredRoutes?.find(
																(routeObj: any) =>
																	routeObj?.route?.toLowerCase() ===
																	`/${value?.toLowerCase()?.trim()}`
															);
															if (found) {
																return Promise.reject();
															} else {
																return Promise.resolve();
															}
														},
													},
												]}
												normalize={(value: string) => {
													let cleanedRoute: string = value
														.replace(" ", "-")
														.replace(/\-+/g, "-");
													// checking for the first character of the route
													if (cleanedRoute.length === 1) {
														cleanedRoute = cleanedRoute.replace(
															/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
															""
														);
													}

													return cleanedRoute;
												}}
											>
												<Input
													disabled={!this.state.new}
													// onChange={(ev: any) => {
													// 	dataRouteChanged("link", ev.target.value);
													// }}
												/>
											</Form.Item>
											<Form.Item
												label="Component"
												name={"component"}
												rules={[
													{
														required: true,
														message: "Please select a component",
													},
												]}
											>
												<Select
													options={this.state.componentList
														?.filter((element: any) => !element.disabled)
														.map((element: any) => ({
															label: element.key,
															value: element.key,
														}))}
													// onChange={(ev: any) => dataChanged("component", ev)}
													showSearch
													optionFilterProp="label"
												/>
											</Form.Item>
											<Form.Item
												label="Component owners"
												name={"owners"}
												rules={[
													{
														required: true,
														message: "Please assign component owners",
													},
												]}
											>
												<Select
													options={usersOptions}
													// onChange={ownersChange}
													mode="multiple"
													filterSort={compareSelectOptions}
													optionFilterProp="label"
												/>
											</Form.Item>
											<Form.Item
												label="Access type"
												name={"component_access"}
												rules={[
													{
														required: true,
														message: "Please ",
													},
												]}
											>
												<Radio.Group
													// onChange={(e) =>
													// 	dataChanged("component_access", e.target.value)
													// }
													options={[
														{
															label: "Managed Access Hidden",
															value: "restricted_hidden",
														},
														{ label: "Managed Access", value: "restricted" },
														{
															label: "General Visibility (Read only)",
															value: "public",
														},
													]}
													style={{
														display: "flex",
														flexDirection: "column",
														gap: 8,
													}}
												/>
											</Form.Item>
											{!this.state.selectedMenu?.component_access ||
											this.state.selectedMenu.component_access !== "public" ? (
												<Form.Item label="App Roles" name={"roles"}>
													<Select
														mode="multiple"
														options={this.state.roles?.map((role: any) => ({
															label: role,
															value: role,
														}))}
													/>
												</Form.Item>
											) : null}
											<Form.Item style={{ marginBottom: 4 }}>
												<Space>
													New duration
													<Switch
														checked={this.state.selectedMenu?.new_duration}
														onChange={(checked) => {
															if (!checked) {
																this.formRef.current?.setFieldValue(
																	"new_duration",
																	null
																);
																// dataChanged("new_duration", null);
																dataChanged2({ new_duration: null });
															} else {
																this.formRef.current?.setFieldValue(
																	"new_duration",
																	[dayjs(), dayjs().add(1, "w")]
																);
																dataChanged2({
																	new_duration: [dayjs(), dayjs().add(1, "w")],
																});

																// dataChanged("new_duration", );
															}
														}}
													/>
												</Space>
											</Form.Item>
											{this.state.selectedMenu?.new_duration ? (
												<Form.Item name={"new_duration"}>
													<RangePicker
														style={{ borderRadius: 2 }}
														allowClear={false}
														allowEmpty={[true, false]}
														// value={[
														// 	dayjs(this.state.selectedMenu?.new_duration?.[0]) ??
														// 	null,
														// 	dayjs(this.state.selectedMenu?.new_duration?.[1]) ??
														// 	null,
														// ]}
														// defaultValue={[null, null]}
														// onChange={(date, dateString) => {
														// 	dataChanged("new_duration", dateString);
														// }}
													/>
												</Form.Item>
											) : null}

											{confirmCancel()}
										</Col>

										<Col xs={24} md={16}>
											{renderSelectedMenu2()}
										</Col>
									</Row>
								</Form>
							</Content>
						</Layout>
					</Layout>
				) : (
					<NoAccess />
				)}
			</ConfigProvider>
		);
	};
}

const mapStateToProps = (state: any) => {
	return {
		role: state.role,
		allUsers: state.allUsers,
		allModules: state.allModules,
		documents: state.documents,
		vessels: state.vessels.allVessels,
		user: state.user,
	};
};

export default connect(mapStateToProps)(MenuManager);
