import {
	darcula,
	material,
	abcdef,
	androidstudio,
	atomone,
	aura,
	bbedit,
	bespin,
	dracula,
	duotoneDark,
	duotoneLight,
	eclipse,
	githubDark,
	githubLight,
	gruvboxDark,
	gruvboxLight,
	materialDark,
	materialLight,
	noctisLilac,
	nord,
	okaidia,
	solarizedDark,
	solarizedLight,
	sublime,
	tokyoNight,
	tokyoNightDay,
	tokyoNightStorm,
	vscodeDark,
	xcodeDark,
	xcodeLight,
} from "@uiw/codemirror-themes-all";

export const themes = [
	"vscodeDark",
	"darcula",
	"material",
	"abcdef",
	"androidstudio",
	"atomone",
	"aura",
	"bbedit",
	"bespin",
	"dracula",
	"duotoneDark",
	"duotoneLight",
	"eclipse",
	"githubDark",
	"githubLight",
	"gruvboxDark",
	"gruvboxLight",
	"materialDark",
	"materialLight",
	"noctisLilac",
	"nord",
	"okaidia",
	"solarizedDark",
	"solarizedLight",
	"sublime",
	"tokyoNight",
	"tokyoNightDay",
	"tokyoNightStorm",
	"xcodeDark",
	"xcodeLight",
];

export const getTheme = (theme: string) => {
	switch (theme) {
		case "vscodeDark":
			return vscodeDark;
		case "darcula":
			return darcula;
		case "material":
			return material;
		case "abcdef":
			return abcdef;
		case "androidstudio":
			return androidstudio;
		case "atomone":
			return atomone;
		case "aura":
			return aura;
		case "bbedit":
			return bbedit;
		case "bespin":
			return bespin;
		case "dracula":
			return dracula;
		case "duotoneDark":
			return duotoneDark;
		case "duotoneLight":
			return duotoneLight;
		case "eclipse":
			return eclipse;
		case "githubDark":
			return githubDark;
		case "githubLight":
			return githubLight;
		case "gruvboxDark":
			return gruvboxDark;
		case "gruvboxLight":
			return gruvboxLight;
		case "materialDark":
			return materialDark;
		case "materialLight":
			return materialLight;
		case "noctisLilac":
			return noctisLilac;
		case "nord":
			return nord;
		case "okaidia":
			return okaidia;
		case "solarizedDark":
			return solarizedDark;
		case "solarizedLight":
			return solarizedLight;
		case "sublime":
			return sublime;
		case "tokyoNight":
			return tokyoNight;
		case "tokyoNightDay":
			return tokyoNightDay;
		case "tokyoNightStorm":
			return tokyoNightStorm;
		case "xcodeDark":
			return xcodeDark;
		case "xcodeLight":
			return xcodeLight;

		default:
			break;
	}
};
