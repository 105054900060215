import { Dictionary } from "lodash";
import { v4 as uuid } from "uuid";
import { initParameters } from "../utils/queryBuilder";
import { Layout } from "react-grid-layout";
import { CustomLayout } from "../types/CustomLayout";
import { VisualType } from "../types/Visual";
import _ from "lodash";
import { getFromLS, getGridHeight, removeFromLS } from "../utils/utils";

export const ROW_HEIGHT = 80;
export const CONTAINER_PADDING = 16;
export const HEADER_HEIGHT = 56;
export const MARGIN_H = 10;

export type CustomDashboardState = {
	isResize: boolean;
	isDrag: boolean;
	tempSliceValues: any;
	sliceValues: any;
	slicerParams: Dictionary<any>;
	params: any;
	loading: boolean;
	measureModal: boolean;
	drilldownModal: boolean;
	drawer: boolean;
	drawerKey: null;
	currentMeasure: Partial<MeasureType>;
	currentVisual: Partial<VisualType> | null;
	currentDrilldown: string;
	newMeasure: boolean;
	draggedItemId: string;
	draggedVisualId: string;
	editMode: boolean;
	layout: Array<any>;
	layouts: any;
	addItem: Layout & {
		add: boolean;
	};
	slice: boolean;
	dirty: boolean;
	slicerModified: boolean;
	initial: boolean;
	breakpoint: string;
	owners: Array<any>;
	viewers: Array<any>;
	users: Array<any>;
	groups: Array<any>;
	removedTargets: Array<any>;
	description: string;
	visuals: Array<any>;
	updatedVisualIds: Array<string>;
	updatedVisualGroupIds: Array<string>;
	initialLayout: Array<string>;
	initialLayouts: any;
	layoutid: any;
	metadata: any;
};

export type CustomDashboardAction =
	| { type: "LOADING"; payload: any }
	| { type: "MEASUREMODAL"; payload: any }
	| { type: "CREATE_MEASURE"; payload: any }
	| { type: "RESTORE_MEASURE"; payload: any }
	| { type: "EDIT_MEASURE"; payload: any }
	| { type: "DRAWER"; payload: any }
	| { type: "ON_DROP"; payload: any }
	| { type: "ON_VISUAL_DRAG_START"; payload: any }
	| { type: "LAYOUT_CHANGE"; payload: any }
	| { type: "EDIT_DASHBOARD"; payload: any }
	| { type: "REMOVE_FROM_DASHBOARD"; payload: any }
	| { type: "LOAD_DASHBOARD"; payload: any }
	| { type: "DASHBOARD_SAVE_END"; payload: any }
	| { type: "EDIT_MODE"; payload: boolean }
	| {
			type: "UPDATE_SLICER_PARAMS";
			payload: { key: string; value: any; initial: boolean };
	  }
	| {
			type: "DISCARD_CHANGES";
			payload: {
				prevLayout: any;
				prevLayouts: any;
				preVisuals: any[];
				prevVisualGroups: any[];
			};
	  }
	| { type: "ON_BREAKPOINT_CHANGE"; payload: string }
	| { type: "SLICER_VAL_CHANGE"; payload: any }
	| { type: "SLICE_DATA" }
	| {
			type: "ADD_TO_GROUP";
			payload: {
				source: { id: string };
				destination: { id: string };
				group: any;
			};
	  }
	| {
			type: "REMOVE_LAYOUT";
			payload: CustomLayout;
	  }
	| {
			type: "UPDATE_LAYOUT";
			payload: CustomLayout[];
	  }
	| {
			type: "SET_VISUAL_GROUPS";
			payload: Array<any>;
	  }
	| {
			type: "UPDATE_VISUAL";
			payload: any;
	  }
	| {
			type: "SET_VISUALS";
			payload: any;
	  }
	| {
			type: "MOVE_VISUAL_TO_MAIN";
			payload: { layout: Layout; visual: VisualType };
	  }
	| {
			type: "DELETE_VISUAL_FROM_GROUP";
			payload: { layout: Layout; visual: VisualType };
	  }
	| {
			type: "RESIZE_GROUP";
			payload: { item: any; dimensions: any };
	  }
	| { type: "IS_RESIZE"; payload: boolean }
	| { type: "IS_DRAG"; payload: boolean }
	| { type: "SHOW_DRILLDOWN"; payload: any }
	| { type: "CLOSE_DRILLDOWN"; payload: string }
	| { type: "INIT_DASHBOARD"; payload: any };

const initialState: CustomDashboardState = {
	isResize: false,
	isDrag: false,
	loading: false,
	measureModal: false,
	drilldownModal: false,
	drawer: false,
	drawerKey: null,
	currentMeasure: {},
	currentVisual: null,
	currentDrilldown: "",
	newMeasure: true,
	draggedItemId: "",
	draggedVisualId: "",
	editMode: false,
	layout: [],
	addItem: {
		i: "add",
		add: true,
		h: 3,
		w: 1,
		x: 0,
		y: Infinity,
		isResizable: false,
		isDraggable: false,
	},
	slicerParams: initParameters,
	params: {},
	slice: false,
	dirty: false,
	slicerModified: false,
	initial: true,
	layouts: { lg: [] },
	breakpoint: "lg",
	owners: [],
	viewers: [],
	users: [],
	groups: [],
	removedTargets: [],
	description: "",
	tempSliceValues: {},
	sliceValues: initParameters,
	visuals: [],
	updatedVisualIds: [],
	updatedVisualGroupIds: [],
	initialLayout: [],
	initialLayouts: { lg: [] },
	layoutid: "",
	metadata: null,
};

const DRAWER_KEYS: Dictionary<string> = {
	VISUALS: "visuals",
	MEASURES: "measures",
	VISUAL_GROUPS: "visual_groups",
};

export const PERMISSION = {
	OWNER: "Owner",
	VIEWER: "Viewer",
};

const reducer = (
	state: CustomDashboardState,
	action: CustomDashboardAction
) => {
	switch (action.type) {
		case "CLOSE_DRILLDOWN":
			return {
				...state,
				drilldownModal: false,
				currentVisual: null,
				currentDrilldown: "",
			};

		case "SHOW_DRILLDOWN":
			return {
				...state,
				drilldownModal: true,
				currentVisual: action.payload,
				currentDrilldown: action.payload.drilldown,
			};

		case "IS_RESIZE":
			return { ...state, isResize: action.payload, isDrag: action.payload };

		case "IS_DRAG":
			return { ...state, isDrag: action.payload };

		case "RESIZE_GROUP":
			if (action.payload.dimensions) {
				let currentH = action.payload.item.h;
				let previousH = action.payload.item.h;

				const groupHeight = action.payload.dimensions.height;
				let gridHeight = getGridHeight(action.payload.item.h) - HEADER_HEIGHT;

				while (groupHeight > gridHeight) {
					currentH++; // Incrementing the height
					gridHeight = getGridHeight(currentH) - HEADER_HEIGHT; // Recalculate grid height based on the updated currentH
				}

				if (previousH !== currentH) {
					return {
						...state,
						layout: state.layout?.map((_layout: any) => {
							if (_layout.i === action.payload.item.i) {
								return { ..._layout, h: currentH, minH: 3 };
							}
							return { ..._layout, minH: 3 };
						}),
						layouts: {
							lg: state.layout?.map((_layout: any) => {
								if (_layout.i === action.payload.item.i) {
									return { ..._layout, h: currentH, minH: 3 };
								}
								return { ..._layout, minH: 3 };
							}),
						},
					};
				}
			}

			return {
				...state,
			};

		case "DELETE_VISUAL_FROM_GROUP":
			// * Find existing layout to be removed from
			const targetLayout = state?.layout?.find((_layout) =>
				_layout?.children?.find(
					(child: Layout) => child?.i === action.payload?.layout.i
				)
			);

			// * To indicate if the mainlayout should be removed depending on the number of visual in the group
			const shouldRemove = targetLayout?.children?.length === 1;

			const updatedMainLayout = [
				...state?.layout.map((_layout) => {
					if (_layout?.i === targetLayout?.i) {
						return {
							..._layout,
							children: _layout?.children?.filter(
								(child: Layout) => child.i !== action.payload.layout.i
							),
						};
					}
					return _layout;
				}),
			];

			// * Update visual's layoutIds to remove the child layout and add new layout's children's first child
			const updatedAllVisuals = state.visuals.map((_visual) => {
				if (_visual.id === action.payload.visual.id) {
					return {
						..._visual,
						layoutIds: [
							..._visual?.layoutIds?.filter(
								(layoutId: string) => layoutId !== action.payload.layout.i
							),
						],
					};
				}
				return _visual;
			});

			// * Update visual group's layoutIds when the last visual was removed from the group, would need to remove the layout from dashboard as well
			const targetGroup = state.groups?.find((_group) =>
				_group?.layoutIds?.find(
					(_layoutId: string) => _layoutId === targetLayout.i
				)
			);
			const updatedAllGroups = state.groups?.map((_group) => {
				if (targetGroup.id === _group.id && shouldRemove) {
					return {
						..._group,
						layoutIds: _group?.layoutIds.filter(
							(_layoutId: string) => _layoutId !== targetLayout.i
						),
					};
				}
				return { ..._group };
			});

			const __updatedLayout = shouldRemove
				? updatedMainLayout?.filter((_layout) => _layout.i !== targetLayout.i)
				: updatedMainLayout;

			return {
				...state,
				updatedVisualIds: _.uniq([
					...state.updatedVisualIds,
					action.payload.visual.id,
				]),
				visuals: updatedAllVisuals,
				layout: __updatedLayout,
				groups: updatedAllGroups,
				updatedVisualGroupIds: shouldRemove
					? _.uniq([...state.updatedVisualGroupIds, targetGroup.id])
					: state.updatedVisualGroupIds,
				layouts: { lg: __updatedLayout },
			};

		case "MOVE_VISUAL_TO_MAIN":
			// * Create new layout since moving a visual to main layout is creating a new layout
			const newLayoutId = uuid();
			let newLayout: CustomLayout = {
				i: uuid(),
				w: 1,
				h: 3,
				x: Infinity,
				y: Infinity,
				maxW: 3,
				minH: 3,
				children: [
					{
						i: newLayoutId,
						w: 1,
						h: 2,
						x: Infinity,
						y: Infinity,
						maxW: 3,
					},
				],
			};

			// * Find existing layout to be removed from
			const mainLayout = state?.layout?.find((_layout) =>
				_layout?.children?.find(
					(child: Layout) => child?.i === action.payload?.layout.i
				)
			);

			// * To indicate if the mainlayout should be removed depending on the number of visual in the group
			const willRemove = mainLayout?.children?.length === 1;

			const updatedLayout = [
				...state?.layout.map((_layout) => {
					if (_layout?.i === mainLayout?.i) {
						return {
							..._layout,
							children: _layout?.children?.filter(
								(child: Layout) => child.i !== action.payload.layout.i
							),
						};
					}
					return _layout;
				}),
				newLayout,
			];

			// * Update visual's layoutIds to remove the child layout and add new layout's children's first child
			const updatedVisuals = state.visuals.map((_visual) => {
				if (_visual.id === action.payload?.visual.id) {
					return {
						..._visual,
						layoutIds: [
							..._visual?.layoutIds?.filter(
								(layoutId: string) => layoutId !== action.payload.layout.i
							),
							newLayoutId,
						],
					};
				}
				return _visual;
			});

			// * Update visual group's layoutIds when the last visual was removed from the group, would need to remove the layout from dashboard as well
			const currGroup = state.groups?.find((_group) =>
				_group?.layoutIds?.find(
					(_layoutId: string) => _layoutId === mainLayout.i
				)
			);
			const updatedGroups = state.groups?.map((_group) => {
				if (currGroup.id === _group.id && willRemove) {
					return {
						..._group,
						layoutIds: _group?.layoutIds.filter(
							(_layoutId: string) => _layoutId !== mainLayout.i
						),
					};
				}
				return { ..._group };
			});

			return {
				...state,
				updatedVisualIds: _.uniq([
					...state.updatedVisualIds,
					action.payload.visual.id,
				]),
				visuals: updatedVisuals,
				layout: willRemove
					? updatedLayout?.filter((_layout) => _layout.i !== mainLayout.i)
					: updatedLayout,
				layouts: {
					lg: willRemove
						? updatedLayout?.filter((_layout) => _layout.i !== mainLayout.i)
						: updatedLayout,
				},
				groups: updatedGroups,
				updatedVisualGroupIds: willRemove
					? _.uniq([...state.updatedVisualGroupIds, currGroup.id])
					: state.updatedVisualGroupIds,
			};

		case "SET_VISUALS":
			return { ...state, visuals: action.payload };

		case "UPDATE_VISUAL":
			const visual = action.payload;
			return {
				...state,
				updatedVisualIds: _.uniq([...state.updatedVisualIds, visual.id]),
				visuals: state.visuals.map((_visual) =>
					_visual.id === visual.id ? visual : _visual
				),
			};

		case "SET_VISUAL_GROUPS":
			return { ...state, groups: action.payload };

		case "REMOVE_LAYOUT":
			const newUpdatedGroups: Array<string> = [];
			const newUpdatedVisuals: Array<string> = [];

			const newGroups = state.groups.map((_group) => {
				if (_group?.layoutIds?.includes(action.payload.i)) {
					newUpdatedGroups.push(_group?.id);
					return {
						..._group,
						layoutIds: _group?.layoutIds?.filter(
							(layoutId: string) => layoutId !== action.payload.i
						),
					};
				}
				return _group;
			});

			const newVisuals = state.visuals.map((_visual) => {
				const layout = state.layout.find(
					(_layout) => _layout?.i === action.payload.i
				);
				const childrenLayoutIds = layout?.children?.map(
					(child: Layout) => child?.i
				);

				if (
					_visual?.layoutIds?.find((_layoutId: string) =>
						childrenLayoutIds?.includes(_layoutId)
					)
				) {
					newUpdatedVisuals.push(_visual?.id);

					return {
						..._visual,
						layoutIds: _visual?.layoutIds?.filter(
							(_layoutId: string) => !childrenLayoutIds?.includes(_layoutId)
						),
					};
				}
				return _visual;
			});

			const _updatedLayout = state.layout.filter(
				(_layout) => _layout.i !== action.payload.i
			);

			return {
				...state,
				groups: newGroups,
				visuals: newVisuals,
				updatedVisualIds: _.uniq([
					...state.updatedVisualIds,
					...newUpdatedVisuals,
				]),
				updatedVisualGroupIds: _.uniq([
					...state.updatedVisualGroupIds,
					...newUpdatedGroups,
				]),

				layout: _updatedLayout,
				layouts: {
					lg: _updatedLayout,
				},
				dirty: true,
			};

		case "UPDATE_LAYOUT":
			return { ...state, layout: action.payload, dirty: state.editMode };

		case "ADD_TO_GROUP":
			const { source, destination, group: _group } = action.payload;

			const f = state.layout.find((_layout) => _layout.i === destination.id);

			const newItem = {
				i: source.id,
				w: 1,
				h: 2,
				x: Infinity,
				y: Infinity,
				maxW: 3,
				minH: 2,
			};

			// handling existing groups
			if (f) {
				const newGroups = state.groups?.map((__group) => {
					if (__group.id === _group.id) {
						return {
							...__group,
							layoutIds: _.uniq([...(_group?.layoutIds || []), f.i]),
						};
					}
					return __group;
				});

				const singleLayout = state.layout.find(
					(_layout) => _layout.i === source.id
				)?.children?.[0];

				const visual = state.visuals.find((_visual) =>
					_visual?.layoutIds?.find(
						(layoutId: string) => layoutId === singleLayout.i
					)
				);

				const updatedVisuals = state.visuals.map((_visual) => {
					if (_visual.id === visual.id) {
						return {
							..._visual,
							layoutIds: [
								...(_visual?.layoutIds?.filter(
									(layoutId: string) => layoutId !== singleLayout.i
								) || []),
								source.id,
							],
						};
					}
					return _visual;
				});

				if (!visual || !_group) {
					return { ...state };
				}

				return {
					...state,
					groups: newGroups,
					visuals: updatedVisuals,
					updatedVisualIds: _.uniq([...state.updatedVisualIds, visual.id]),
					updatedVisualGroupIds: _.uniq([
						...state.updatedVisualGroupIds,
						_group.id,
					]),
					layout: state.layout
						.filter((_layout) => _layout.i !== source.id)
						.map((_layout) => {
							if (_layout.i === f.i) {
								return {
									..._layout,
									children: [..._layout.children, newItem],
									layouts: {
										// ..._layout?.layouts,
										lg: [..._layout?.layouts?.lg, newItem],
									},
								};
							}
							return _layout;
						}),
					layouts: {
						lg: state.layout
							.filter((_layout) => _layout.i !== source.id)
							.map((_layout) => {
								if (_layout.i === f.i) {
									return {
										..._layout,
										children: [..._layout.children, newItem],
										layouts: {
											// ..._layout?.layouts,
											lg: [..._layout?.layouts?.lg, newItem],
										},
									};
								}
								return _layout;
							}),
					},
				};
			} else {
				// handling new groups
				const newLayoutItem: CustomLayout = {
					i: destination.id,
					w: 1,
					h: 3,
					x: Infinity,
					y: Infinity,
					maxW: 3,
					minH: 3,
					isGroup: true,
					children: [newItem],
					layouts: {
						lg: [newItem],
					},
				};

				const newGroups = state.groups?.map((__group) => {
					if (__group.id === _group.id) {
						return {
							...__group,
							layoutIds: [...(_group?.layoutIds || []), destination.id],
						};
					}
					return __group;
				});

				const newLayout: CustomLayout[] = [
					...state.layout.filter((_layout) => _layout.i !== source.id),
					newLayoutItem,
				];

				const singleLayout = state.layout.find(
					(_layout) => _layout.i === source.id
				)?.children?.[0];

				const visual = state.visuals.find((_visual) =>
					_visual?.layoutIds?.find(
						(layoutId: string) => layoutId === singleLayout.i
					)
				);

				const updatedVisuals = state.visuals.map((_visual) => {
					if (_visual.id === visual.id) {
						return {
							..._visual,
							layoutIds: [
								...(_visual?.layoutIds?.filter(
									(layoutId: string) => layoutId !== singleLayout.i
								) || []),
								source.id,
							],
						};
					}
					return _visual;
				});

				if (!visual || !_group) {
					return { ...state };
				}

				// ! Remove the source visual
				return {
					...state,
					layout: newLayout,
					layouts: { ...state.layouts, lg: newLayout },
					groups: newGroups,
					visuals: updatedVisuals,
					updatedVisualIds: _.uniq([...state.updatedVisualIds, visual.id]),
					updatedVisualGroupIds: _.uniq([
						...state.updatedVisualGroupIds,
						_group.id,
					]),
				};
			}

		case "SLICE_DATA":
			return {
				...state,
				sliceValues: state.tempSliceValues,
				tempSliceValues: {},
			};

		case "SLICER_VAL_CHANGE":
			return { ...state, tempSliceValues: action.payload, initial: false };

		case "ON_BREAKPOINT_CHANGE":
			// disable edit mode when breakpoint is not lg
			if (action.payload !== "lg") {
				return {
					...state,
					breakpoint: action.payload,
					dirty: false,
					editMode: false,
				};
			}

			const prevLayout = getFromLS("layout");
			if (prevLayout) {
				return {
					...state,
					breakpoint: action.payload,
					dirty: true,
					editMode: true,
					layout: prevLayout,
				};
			}

			return { ...state, breakpoint: action.payload };

		case "EDIT_MODE":
			if (!action.payload) {
				// removes "Add visual" tile when user exits from dashboard edit mode
				const newLayout = state.layout.filter((_layout) => !_layout?.add);

				return {
					...state,
					editMode: action.payload,
					layout: newLayout,
				};
			} else {
				return {
					...state,
					editMode: action.payload,
				};
			}

		case "DISCARD_CHANGES":
			removeFromLS("layout");
			return {
				...state,
				layout: action.payload.prevLayout,
				visuals: action.payload.preVisuals,
				groups: action.payload.prevVisualGroups,
				layouts: action.payload.prevLayouts,
				editMode: initialState.editMode,
				dirty: initialState.dirty,
			};

		case "UPDATE_SLICER_PARAMS":
			return {
				...state,
				slicerParams: {
					...state.slicerParams,
					[action.payload.key]: action.payload.value,
				},
				slicerModified: true,
				initial: false,
			};

		case "INIT_DASHBOARD":
			const {
				initialLayout,
				initialLayouts,
				layoutId,
				metadata = null,
			} = action.payload;
			return {
				...state,
				// ...initialState,
				metadata,
				initialLayout,
				initialLayouts,
				layoutId,
				layout: initialLayout,
				layouts: initialLayouts,
			};

		case "LOAD_DASHBOARD":
			const { defaultSlicerParams } = action.payload;

			return {
				...state,
				sliceValues: defaultSlicerParams,
				slicerParams: defaultSlicerParams,
			};

		case "DASHBOARD_SAVE_END":
			removeFromLS("layout");
			return {
				...state,
				layout: filterLayout(state.layout, action.payload),
				layouts: {
					...state.layouts,
					[state.breakpoint]: filterLayout(state.layout, action.payload),
				},
				drawer: initialState.drawer,
				drawerKey: initialState.drawerKey,
				editMode: initialState.editMode,
				dirty: initialState.dirty,
				loading: false,
			};

		case "REMOVE_FROM_DASHBOARD":
			const layout: Array<any> = state.layout.filter(
				(el: any) => el?.i !== action.payload
			);

			// handling this to add the add button when there layout is empty
			if (layout.length === 0 && state.editMode) {
				layout.push(state.addItem);
			}

			return {
				...state,
				dirty: true,
				layout,
				layouts: { ...state.layout, [state.breakpoint]: layout },
			};

		case "EDIT_DASHBOARD":
			if (state.layout?.length === 0) {
				return {
					...state,
					editMode: action.payload,
					layout: [state.addItem],
					layouts: {
						lg: [state.addItem],
					},
				};
			}
			return {
				...state,
				editMode: action.payload,
			};

		case "LAYOUT_CHANGE":
			const { layout: currentLayout, allLayouts } = action.payload;

			if (!state.isDrag && state.breakpoint === "lg") {
				const mappedLayout = currentLayout.map((_layout: Layout) => {
					const found = state.layout.find(
						(__layout: any) => __layout.i === _layout.i
					);

					if (found) {
						return {
							...found,
							..._layout,
							minH: 3,
						};
					}
					return { _layout, minH: 3 };
				});

				/**
				 * dashboard is dirty when "Add visual" tile is not present
				 * as "Add visual" will only be filtered out from the layout
				 * when there are visual(s) in the dashboard
				 */
				const condition =
					state.layout?.find((el: any) => el.add) && !state.dirty;

				const updatedLayout =
					mappedLayout.length > 0
						? mappedLayout
						: state.editMode
						? [state.addItem]
						: mappedLayout?.filter((_layout: any) => !_layout?.add);

				return {
					...state,
					layout: updatedLayout,
					dirty: condition ? false : state.editMode,
					layouts: {
						lg:
							allLayouts.lg.length > 0
								? allLayouts.lg
								: state.editMode
								? [state.addItem]
								: allLayouts.lg?.filter((_layout: any) => !_layout?.add),
					},
				};
			}

			return { ...state };

		case "ON_VISUAL_DRAG_START":
			return {
				...state,
				draggedItemId: uuid(),
				draggedVisualId: action.payload,
				drawer: initialState.drawer,
			};

		case "ON_DROP":
			const {
				layout: _layout,
				item,
				visualId,
				newLayoutId: childLayoutId,
			} = action.payload;
			const { x, y } = item;

			/**
			 * creating a layout item based on the item dropped onto the dashboard.
			 * layout item stores the visual_id to get the visual object upon rendering
			 */
			const newVisual: CustomLayout = {
				i: uuid(),
				w: 1,
				h: 3,
				x,
				y,
				maxW: 3,
				minH: 3,
				children: [
					{
						i: childLayoutId,
						w: 1,
						h: 2,
						x: Infinity,
						y: Infinity,
						maxW: 3,
						minH: 2,
						// isDraggable: false,
						// isResizable: false,
					},
				],
			};
			return {
				...state,
				layout: [
					...state.layout.filter((_layout: any) => !_layout?.add),
					newVisual,
				],
				layouts: {
					lg: [
						...state.layout.filter((_layout: any) => !_layout?.add),
						newVisual,
					],
				},
				draggedVisualId: initialState.draggedVisualId,
				updatedVisualIds: _.uniq([...state.updatedVisualIds, visualId]), // only getting the distinct ids as same visual could be drop on the dashboard multiple times
				isDrag: false,
			};

		case "DRAWER":
			return {
				...state,
				drawer: action.payload !== null,
				drawerKey: action.payload,
			};

		case "LOADING":
			return {
				...state,
				loading: action.payload,
			};

		case "MEASUREMODAL":
			if (!action.payload) {
				return {
					...state,
					measureModal: action.payload,
					currentMeasure: initialState.currentMeasure,
				};
			}
			return {
				...state,
				measureModal: action.payload,
			};

		case "CREATE_MEASURE":
			return {
				...state,
				currentMeasure: {
					id: uuid(),
					name: null,
					queryStatement: "",
					params: initParameters,
				},
				measureModal: true,
				newMeasure: true,
			};

		case "RESTORE_MEASURE":
			return {
				...state,
				currentMeasure: action.payload,
				measureModal: true,
				newMeasure: true,
			};

		case "EDIT_MEASURE":
			return {
				...state,
				currentMeasure: action.payload,
				measureModal: true,
				newMeasure: false,
			};

		default:
			return state;
	}
};

const filterLayout = (layout: Array<any>, editMode: boolean) => {
	if (editMode) {
		/**
		 * only brings back "Add visual" tile when dashboard is empty
		 * to prevent having to find the "Add visual" tile when dashboard
		 * is full and needing to scroll down to find the tile
		 */
		if (layout.length !== 0) return layout;
		return [...layout, initialState.addItem];
	}

	// removes "Add visual" tile when dashboard is not in edit mode
	return layout.filter((el: any) => !el.add);
};

export { initialState, DRAWER_KEYS, reducer };
