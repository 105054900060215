import { Button, Flex, Input, Layout, Typography } from "antd";
import React, {
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useState,
} from "react";
import { GetAntIcon } from "../utils/ant_icons";
import { INIITAL_STATE, reducer } from "../reducers/visualReducer";
import VisualModal from "./modal/VisualModal";
import VisualsList from "./VisualsList";
import { CustomDashboardContext, VisualContext } from "../contexts/context";
import { useComponent } from "../contexts/ComponentContext";

const { Header } = Layout;
const { Text } = Typography;

interface VisualTabProps {}

// this tab is responsible for all of its states
const VisualTab = ({}: VisualTabProps) => {
	// reducer
	const [state, dispatch] = useReducer(reducer, INIITAL_STATE);
	const [searchVal, setSearchVal] = useState<string | undefined>("");

	// Context
	const {
		isOwner,
		dispatch: customDashboardDispatch,
		state: customDashboardState,
	} = useContext(CustomDashboardContext);
	const { visuals } = useComponent();
	const actionable = !customDashboardState.editMode;

	// CALLBACKS
	const createNewVisual = useCallback(() => {
		dispatch({ type: "NEW_VISUAL" });
	}, []);

	// Render
	const renderVisualButton = () => {
		if (!isOwner) return null;
		return (
			<Button
				disabled={!actionable}
				icon={GetAntIcon("plus")}
				onClick={createNewVisual}
			>
				New visual
			</Button>
		);
	};

	const onVisualDragStart = (e: any, visualId: string) => {
		customDashboardDispatch({
			type: "ON_VISUAL_DRAG_START",
			payload: visualId,
		});
	};

	const getVisuals = () => {
		return searchVal
			? visuals?.filter((_visual) => {
					return (
						_visual?.title?.toLowerCase().includes(searchVal?.toLowerCase()) ||
						_visual?.subtitle?.toLowerCase().includes(searchVal?.toLowerCase())
					);
			  })
			: visuals;
	};

	useEffect(() => {
		console.log(state);
	}, [state]);

	return (
		<>
			<VisualContext.Provider value={{ state, dispatch }}>
				<Layout style={{ background: "transparent", height: "inherit" }}>
					<Header
						style={{
							padding: 0,
							background: "transparent",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Text style={{ fontSize: 24 }}>Visuals</Text>
						{renderVisualButton()}
					</Header>
					<Input
						placeholder="Search..."
						value={searchVal}
						onChange={(e) => setSearchVal(e.target.value)}
						allowClear
						style={{ borderRadius: 2, marginBottom: 16 }}
					/>
					<VisualsList
						draggable={customDashboardState.editMode}
						onVisualDragStart={onVisualDragStart}
						visuals={getVisuals()}
					/>
				</Layout>
				<VisualModal open={state.openModal} />
			</VisualContext.Provider>
		</>
	);
};

export default VisualTab;
