import React, { useEffect, useState, useMemo } from "react";
import { Button } from "antd";

const ScrollButton = (props: any) => {
	const scrollToTop = () => {
		//console.log("top");
		document
			.getElementById("content")
			?.scrollTo({ top: 0, behavior: "smooth" });
	};

	return (
		<Button
			style={{
				display: props.visible ? "inline" : "none",
				position: "fixed",
				zIndex: "99",
				bottom: "5%",
				right: "5%",
				fontWeight: "bold",
			}}
			onClick={scrollToTop}
		>
			Back to top
		</Button>
	);
};

export default ScrollButton;
