import {
	Dropdown,
	Input,
	MenuProps,
	SelectProps,
	Table,
	TableProps,
} from "antd";
import { useEffect, useState } from "react";
import { duckQuery } from "../services/api-server/deltashare";
import { useComponent } from "../contexts/ComponentContext";

type ParamSelectDropdownV2Props = SelectProps & {
	parameter: Parameter;
	overlayStyle?: React.CSSProperties;
	useDisplayName?: boolean;
};

const ParamSelectDropdownV2 = ({
	parameter,
	overlayStyle,
	onChange = () => {},
	value,
	useDisplayName = false,
}: ParamSelectDropdownV2Props) => {
	const [paramOptions, setParamOptions] = useState<
		{ key: React.Key; label: any; value: any }[]
	>([]);

	const { data_authorized } = useComponent();

	const tableProps: TableProps<any> = {
		rootClassName: "param-table-dropdown",
		className: "param-table-dropdown",
		dataSource: paramOptions,
		columns: [{ dataIndex: "displayName", title: "All" }],
		style: {
			overflowY: "scroll",
		},
		rowSelection: {
			selectedRowKeys: value?.map((v: any) => `k-${v}`),
			onChange,
		},
		pagination: false,
		size: "small",
		scroll: { y: 300 },
		bordered: true,
	};

	const items: MenuProps["items"] = [
		{
			className: "param-select-dropdown",
			style: { padding: 0 },
			label: <Table {...tableProps} />,
			type: "group",
		},
	];

	const getTextValue = () => {
		if (value?.length === paramOptions?.length) {
			return `All ${parameter.displayName}`;
		} else if (value?.length > 1) {
			return `Multiple ${parameter.displayName}`;
		} else if (value?.length === 1) {
			const found = paramOptions?.find((d) => value?.includes(d?.value));

			return `${found?.label}`;
		} else {
			return undefined;
		}
	};

	useEffect(() => {
		const getData = async () => {
			const data: any = await duckQuery(parameter.query, data_authorized);
			const latestValues: any[] =
				data?.response?.map((d: any) => {
					const dArr = Object.entries(d)[0];
					return dArr[1];
				}) || [];

			setParamOptions(
				latestValues?.map((value) => ({
					key: `k-${value}`,
					label: value,
					value,
				}))
			);
		};

		if (parameter.default && parameter.query) {
			getData();
		} else {
			const options = parameter.fieldValues
				?.filter((field) => field.include)
				?.map((field) => ({
					key: `k-${field.value}`,
					label: field.value,
					value: field.value,
					displayName: useDisplayName
						? field.displayName ?? field.value
						: field.value,
				}));

			setParamOptions(options);
		}
	}, [parameter]);

	return (
		<Dropdown
			menu={{
				items,
			}}
			trigger={["click"]}
			overlayStyle={{
				zIndex: 30003,
				height: "auto",
				width: 100,
				...overlayStyle,
			}}
			getPopupContainer={() => document.body}
			autoAdjustOverflow
		>
			<Input
				style={{ width: overlayStyle?.width ?? 216 }}
				value={getTextValue()}
				placeholder={
					value?.length > 0
						? undefined
						: parameter.displayName ?? parameter.name
				}
			/>
		</Dropdown>
	);
};

export default ParamSelectDropdownV2;
