import React from "react";
import * as D3 from "d3";

//To prevent re-rendering of chart
const areEqual = (prevProps: any, nextProps: any) => {
	if (prevProps.rig === nextProps.rig) {
		return true; // donot re-render
	} else {
		return false; // will re-render
	}
};

export const generateTreeData: any = (data: any, tiers: any = []) => {
	const newData: any = {
		name: "root",
		children: [],
	};
	const toTree: any = (mydata: any, n: number = 0, treedata: any) => {
		const propName: string = tiers[n];
		const labels: any = [];
		mydata?.forEach((d: any) => {
			if (labels.indexOf(d[propName]) < 0) labels.push(d[propName]);
		});
		n += 1;
		if (n <= tiers.length) {
			labels.forEach((label: string) => {
				const filteredData: any = mydata.filter((d: any) => {
					return d[propName] === label;
				});
				if (filteredData.length > 0) {
					const node: any = {
						name: label,
						propName: tiers[n - 1] ? tiers[n - 1] : undefined,
						n: n,
						children: [],
					};
					filteredData.forEach((d: any) => {
						node.value += d.value;
					});
					toTree(filteredData, n, node.children);
					if (node.children.length < 1) {
						delete node.children;
					}
					if (node.propName === undefined) {
						delete node.propName;
					}
					treedata.push(node);
				}
			});
		} else {
			// n is deeper than number of tiers
			mydata &&
				mydata.forEach((d: any) => {
					const node: any = {
						name: "leaf",
						propName: tiers[n - 1] ? tiers[n - 1] : undefined,
						n: n,
						children: [],
						...d,
					};
					if (node.children.length < 1) {
						delete node.children;
					}
					if (node.propName === undefined) {
						delete node.propName;
					}
					if (!d[tiers[n - 1]]) {
						// console.log(tiers[n - 2], tiers);
					}
					node.name = d[tiers[n - 1]] ? d[tiers[n - 1]] : "leaf-node";
					treedata.push(node);
				});
		}

		return newData;
	};

	toTree(data, 0, newData.children);
	return newData;
};

const Component = (props: any) => {
	const breadCrumb: any = [];
	const parentColor = "transparent";
	const divRef: any = React.useRef();
	const svgRef: any = React.useRef();
	const data: any = props.data ? props.data : [];
	const sumField: any = props.sumField ? props.sumField : "value";
	const categoryField: any = props.categoryField
		? props.categoryField
		: "category";

	const svg: any = D3.select(svgRef.current);
	let treemap: any = undefined;

	const getLabel: any = (data: any) => {
		if (props.formatLabel && typeof props.formatLabel === "function") {
			return props.formatLabel(data);
		} else {
			return data.data.label ? data.data.label : "";
		}
	};
	let dimension: any = {};
	let x: any = null;
	let y: any = null;
	const updateDimensions = () => {
		dimension = {
			width: () => {
				return divRef.current ? divRef.current.offsetWidth : window.innerWidth;
			},
			height: () => {
				return divRef.current
					? divRef.current.offsetHeight
					: window.innerHeight;
			},
			scaleX: () => {
				return D3.scaleLinear([-100, 100], [0, dimension.width()]);
			},
			scaleY: () => {
				return D3.scaleLinear([-100, 100], [0, dimension.height()]);
			},
			margin: () => {
				return Math.min(dimension.width(), dimension.height()) * 0.05;
			},
			center: () => {
				return [dimension.width() / 2, dimension.height() / 2];
			},
			padding: 30,
		};
		x = D3.scaleLinear().rangeRound([0, dimension.width()]);
		y = D3.scaleLinear().rangeRound([0, dimension.height()]);
	};
	updateDimensions();
	const init = (el: any) => {
		updateDimensions();
		el.selectAll("#area-chart").remove();

		el.append("g")
			.attr("id", "area-chart")
			.append("rect")
			.attr("x", 0)
			.attr("y", 2)
			.attr("width", dimension.width())
			.attr("height", dimension.height())
			.attr("fill", "none");
		let hierarchy = D3.hierarchy(data)
			.sum((d: any) => {
				return d[sumField] ? d[sumField] : 0;
			})
			.sort((a: any, b: any) => {
				return b[sumField] - a[sumField];
			});

		const tile = (
			node: any,
			x0: number,
			y0: number,
			x1: number,
			y1: number
		) => {
			D3.treemapBinary(node, 0, 0, dimension.width(), dimension.height());
			for (const child of node.children) {
				child.x0 = x0 + (child.x0 / dimension.width()) * (x1 - x0);
				child.x1 = x0 + (child.x1 / dimension.width()) * (x1 - x0);
				child.y0 = y0 + (child.y0 / dimension.height()) * (y1 - y0);
				child.y1 = y0 + (child.y1 / dimension.height()) * (y1 - y0);
			}
		};
		treemap = (_data: any) => {
			return D3.treemap().tile(tile)(
				D3.hierarchy(_data)
					.sum((d: any) => {
						return !isNaN(d[sumField]) ? d[sumField] : 0;
					})
					.sort((a: any, b: any) => {
						return b[sumField] - a[sumField];
					})
			);
		};

		const rootTree = treemap(data); //(hierarchy)
		const cats: any = rootTree.children
			? rootTree.children.map((d: any) => {
					return d.data.name;
			  })
			: [];
		const allColors: any = D3.interpolateRainbow(50);
		const colorCats: any = D3.scaleOrdinal().domain(cats).range(allColors);

		const grp = el.selectAll("#area-chart");
		const position = (group: any, root: any) => {
			group
				.selectAll("g")
				.attr("transform", (d: any) => {
					return d === root
						? `translate(0,0)`
						: `translate(${x(d.x0)},${y(d.y0) + dimension.padding})`;
				})
				.select("rect")
				.attr("width", (d: any) => {
					return d === root ? dimension.width() : x(d.x1) - x(d.x0);
				})
				.attr("height", (d: any) => {
					return d === root ? 25 : y(d.y1) - y(d.y0);
				});
		};
		const render = (group: any, root: any) => {
			const node = group
				.selectAll("g")
				.data(root.children.concat(root))
				.join("g");

			node
				.filter((d: any) => {
					return d === root ? d.parent : d.children;
				})
				.on("click", (ev: any, d: any) => {
					if (d === root) {
						zoomout(d);
					} else {
						zoomin(d);
					}
				});

			node.append("title").text((d: any) => {
				return d.value ? `Value:${Math.round(d.value)}` : "";
			});
			node
				.append("rect")
				.attr("id", (d: any) => {
					return d.data.id;
				})
				.attr("fill", (d: any, i: number) => {
					if (d.data.id === "pd") {
						return "#138585";
					} else if (d.data.id === "npd") {
						return "#791A1F";
					} else {
						const color = D3.interpolateRainbow(
							i / root.children.concat(root).length
						);
						return d === root ? parentColor : color;
					}
				})
				// .attr("fill-opacity", 1)
				// .attr("stroke", "#fff")
				.attr("width", 100)
				.attr("height", 100);

			node.append("clipPath").attr("id", (d: any) => {
				d["clipUid"] = `clip-${d.data.name}`;
				return d["clipUid"];
			});

			node
				.append("text")
				.attr("clip-path", (d: any) => {
					return d["clipUid"];
				})
				.attr("font-weight", (d: any) => {
					return d === root ? "bold" : "bold";
				})
				.attr("font-size", (d: any) => {
					return d === root ? "12px" : "10px";
				})
				.selectAll("tspan")
				.data((d: any) => {
					return (d === root ? "root" : getLabel(d)).split(/\s/g);
				})
				.join("tspan")
				.attr("background-color", "transparent")
				.attr("x", 3)
				.attr("y", (d: any, i: any, nodes: any) => {
					return `${(i === nodes.length - 1 ? 1 : -1) * 0.3 + 1.5 + i * 1.7}em`;
				})
				.attr("fill-opacity", (d: any, i: any, nodes: any) => {
					return i === nodes.length - 1 ? 0.7 : null;
				})
				.attr("fill-weight", (d: any, i: any, nodes: any) => {
					return i === nodes.length - 1 ? "normal" : null;
				})
				.text((d: any) => {
					if (d === "root") {
						return `/${breadCrumb.join("/")}`;
					}
					return d;
				})
				.attr(
					"fill",
					localStorage.getItem("theme") === "dark" ? "white" : "black"
				)
				.attr("cursor", "pointer");
			/////
			group.call(position, root);
		};

		let grpCell = grp.append("g").attr("id", "grpCell").call(render, rootTree);

		const doZoomOut = () => {
			zoomout(rootTree);
		};

		const zoomout = (node: any) => {
			const group0 = grpCell.remove(); //attr('pointer-events','none')
			// const group1 = grpCell = D3.select(svgRef.current).selectAll('#grpCell').append('g').attr('id','grpCell')
			if (breadCrumb.length > 0) breadCrumb.splice(-1);
			const areaChart = D3.select(svgRef.current)
				.selectAll("#area-chart")
				.append("g")
				.attr("id", "grpCell");
			const group1 = (grpCell = areaChart
				// .select('#area-chart')
				.insert("g", "*")
				.call((d: any) => {
					render(d, node.parent);
				}));

			x.domain([node.parent.x0, node.parent.x1]);
			y.domain([node.parent.y0, node.parent.y1]);
			D3.selectAll(svgRef.current).call((t: any) => {
				return group1.call(position, node.parent);
			});
		};
		const zoomin = (node: any) => {
			// const group0:any = group.attr('pointer-events','none')
			const group0: any = grpCell.attr("pointer-event", "none");
			if (node.data?.name) {
				breadCrumb.push(node.data.name);
			}
			// const group1:any = grpCell = D3.select(svgRef.current).selectAll('#grpCell').append('g').attr('id','grpCell').call(render,node)
			const group1 = (grpCell = D3.select(svgRef.current)
				.append("g")
				.attr("id", "grpCell")
				.call(render, node));

			// breadCrumb.push(node.)
			x.domain([node.x0, node.x1]);
			y.domain([node.y0, node.y1]);
			D3.selectAll(svgRef.current)
				.call((t: any) => {
					return group0.remove().call(position, node.parent);
				})
				.call((t: any) => {
					return group1.call(position, node);
				});
		};
	};
	React.useEffect(() => {
		init(D3.select(svgRef.current));
	});
	window.addEventListener("resize", () => {
		init(D3.select(svgRef.current));
	});
	React.useLayoutEffect(() => {
		init(D3.select(svgRef.current));
	});

	return (
		<>
			<div ref={divRef} style={{ width: "100%", height: "100%" }}>
				<svg ref={svgRef} style={{ width: "100%", height: "120%" }}></svg>
			</div>
		</>
	);
};

export default React.memo(Component, areEqual);
