import { BehaviorSubject } from 'rxjs'
import { SingletonFactory } from './SingletonFactory'
let username = 'hobo2'
interface IUser {
    email: string,
    role: string
}
export const getUserName = () => {
    return username
}

export class User extends SingletonFactory {

    public username: string | undefined
    public user: BehaviorSubject<any | undefined> = new BehaviorSubject(undefined)
    public test: any = undefined

    init() {
        if (this.user.getValue() === undefined) {
            const dummyuser: IUser = { email: 'reuben@test.com', role: 'admin' }
            this.user.next(dummyuser)
        }
    }

}

