import { useEffect, useState } from "react";
import { getWorkerUrl } from "../utils/utils";
import { Button, Drawer, Empty, Popover } from "antd";
import { GetAntIcon } from "../utils/ant_icons";

import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { printPlugin } from "@react-pdf-viewer/print";

// Your render function

// Import styles
import "../assets/css/pdf-viewer.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import { url } from "../services/api-server/_exports";
import Emitter from "../services/EventEmitter";

const PdfViewer = ({
	fileObject,
	pdfViewer,
	handleCloseDocument,
	open,
}: any) => {
	const zoomPluginInstance = zoomPlugin({ enableShortcuts: true });
	const { ZoomInButton, ZoomOutButton, ZoomPopover, CurrentScale } =
		zoomPluginInstance;

	const getFilePluginInstance = getFilePlugin();
	const { DownloadButton } = getFilePluginInstance;

	const printPluginInstance = printPlugin();
	const { PrintButton } = printPluginInstance;
	const toolbarPluginInstance = toolbarPlugin({});
	// const dPlugin = defaultLayoutPlugin({
	// 	sidebarTabs: () => {
	// 		return [];
	// 	},
	// 	toolbarPlugin: {
	// 		searchPlugin: { enableShortcuts: false },
	// 		getFilePlugin: undefined,
	// 		openPlugin: {},
	// 	},
	// 	// renderToolbar: (toolbar: any) => {
	// 	// 	console.log(toolbar);
	// 	// 	return (
	// 	// 		<div className="rpv-toolbar">
	// 	// 			<div className="rpv-toolbar__left"></div>
	// 	// 			<div className="rpv-toolbar__center"></div>
	// 	// 			<div className="rpv-toolbar__right"></div>
	// 	// 		</div>
	// 	// 	);
	// 	// },
	// });

	const handlePDFViewerClose = () => {
		handleCloseDocument({
			pdfViewer: false,
			fileObject: undefined,
			loading: false,
			open: false,
		});
	};

	return (
		<Worker workerUrl={getWorkerUrl()}>
			<Drawer
				zIndex={50000}
				destroyOnClose
				title={fileObject?.key}
				placement="bottom"
				onClose={handlePDFViewerClose}
				open={open}
				height="100%" // Adjust the height as needed
				styles={{ body: { overflowY: "hidden", padding: "0" } }} // Makes the body scrollable
				closable={false}
				extra={
					<>
						<Button
							style={{ boxShadow: "none" }}
							className="ant-drawer-close"
							onClick={handlePDFViewerClose}
							icon={GetAntIcon("close")}
						></Button>
					</>
				}
			>
				<div
					className="rpv-core__viewer"
					style={{
						border: "1px solid rgba(0, 0, 0, 0.3)",
						display: "flex",
						flexDirection: "column",
						height: "100%",
					}}
				>
					<div
						style={{
							alignItems: "center",
							backgroundColor: "#eeeeee",
							borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
							display: "flex",
							justifyContent: "center",
							padding: "4px",
						}}
					>
						<div
							className="rpv-toolbar__center"
							style={{ position: "absolute" }}
						>
							<ZoomOutButton />
							<CurrentScale />
							<ZoomInButton />
						</div>

						<div
							className="rpv-toolbar__right"
							style={{ paddingRight: "30px" }}
						>
							<Popover
								overlayClassName="pdf-viewer-popover"
								placement="bottom"
								content={"Copy link"}
								style={{ transition: "none" }}
							>
								<Button
									className="pdf-viewer-custom-button-icon"
									type="link"
									icon={GetAntIcon("link")}
									style={{ color: "black" }}
									onClick={async () => {
										try {
											let fileRecord = fileObject?.record || {};
											await navigator.clipboard.writeText(
												`${window.location.origin}/${
													window.location.pathname.split("/")[1]
												}/document?rig=${
													fileRecord.container || fileRecord.containerName
												}&cat=${fileRecord?.tags?.category}&date=${
													fileRecord?.tags?.reportDate
												}`
											);
											Emitter.emit("alert", {
												type: "success",
												message: "Link successfully copied",
												timeout: "3000",
											});
										} catch (err) {
											Emitter.emit("alert", {
												type: "error",
												message: "Link failed to be copied",
												timeout: "3000",
											});
											// setCopySuccess('Failed to copy!');
										}
									}}
								></Button>
							</Popover>

							<PrintButton />
							<DownloadButton />
						</div>
					</div>

					<div
						style={{
							flex: 1,
							overflow: "hidden",
						}}
					>
						{open && fileObject && (
							<Viewer
								plugins={[
									zoomPluginInstance,
									printPluginInstance,
									getFilePluginInstance,
								]}
								defaultScale={SpecialZoomLevel.ActualSize}
								fileUrl={fileObject?.children}
								renderError={(error: any) => {
									return <Empty description={error.message} />;
								}}
							></Viewer>
						)}
					</div>
				</div>
			</Drawer>
		</Worker>
	);
};

export default PdfViewer;
