import { Form, FormInstance, Input, Modal, ModalProps } from "antd";
import { useContext } from "react";
import { ACTIONTYPES } from "../../reducers/measureReducer";
import { addMeasure, updateMeasure } from "../../services/api-server/measures";
import Emitter from "../../services/EventEmitter";
import { CustomDashboardContext, MeasureContext } from "../../contexts/context";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useComponent } from "../../contexts/ComponentContext";
import useMenu from "../../hooks/useMenu";

interface NameDescriptionModalProps extends ModalProps {
	onChange?: Function;
	onSave?: any;
	closeModal?: any;
	form?: FormInstance;
}

const NameDescriptionModal = ({
	onChange = () => {},
	onSave,
	closeModal,
	form,
	...restProps
}: NameDescriptionModalProps) => {
	const { measures } = useComponent();
	const { measureState, measureDispatch } = useContext(CustomDashboardContext);
	const { menuItem } = useComponent();
	const { customDashboardModules } = useMenu();
	const user = useSelector((state: any) => state.user);

	const handleSaveMeasure = async () => {
		const timeCreated = dayjs().toString();
		const values = form?.getFieldsValue(true);

		measureDispatch({ type: ACTIONTYPES.SAVE_MEASURE_START });

		form?.setFieldValue("lastUpdatedAt", timeCreated);
		form?.setFieldValue("lastUpdatedBy", user?.name || "");

		// Check if measure was verified before
		const wasVerified = measures?.find(
			(measure: any) =>
				measure.id === measureState.measure.id && measure?.verified
		);

		if (!wasVerified) {
			form?.setFieldValue("verifiedAt", timeCreated);
			form?.setFieldValue("verifiedBy", user?.name || "");
		}

		if (!values?.verified) {
			form?.setFieldValue("verifiedAt", "");
			form?.setFieldValue("verifiedBy", "");
		}

		if (measureState.isNew) {
			form?.setFieldValue("createdAt", timeCreated);
			form?.setFieldValue("createdBy", user?.name || "");
		}

		const cleanedValues = Object.fromEntries(
			Object.entries(form?.getFieldsValue(true)).map(([key, value]) => [
				key,
				typeof value === "string" ? value.trim() : value,
			])
		);

		try {
			await form?.validateFields();

			// Determine either new measure or existing measure
			if (!measureState.isNew) {
				// Indication of a measure that was created prior to mastered measure logic was implemented, thus making it mastered from the where it was edited to be a mastered measure
				const oldMeasure = !Object.hasOwn(cleanedValues, "menuKey");

				// Find if measure was detached from parent in the event of deletion of custom dashboard
				const isDetached =
					!oldMeasure &&
					customDashboardModules.find(
						(module) => module.key === cleanedValues.menuKey
					) === undefined;

				// Existing measure
				updateMeasure(
					oldMeasure || isDetached
						? ({ ...cleanedValues, menuKey: menuItem.key } as MeasureType)
						: (cleanedValues as MeasureType),
					menuItem.key
				)
					.then((response: any) => {
						onSave(response.data);
						Emitter.emit("alert", {
							type: "success",
							message: "Your measure had been updated",
							description: "You have successfully updated a measure",
							timeout: 5000,
						});
						form?.resetFields();
					})
					.catch((error) => {
						Emitter.emit("alert", {
							type: "error",
							message: "Unsuccessful saving measure",
							description: "There was an error while saving measure",
							timeout: 5000,
						});
						console.error(error);
					})
					.finally(() => {
						closeModal();
					});
			} else {
				// New measure
				addMeasure(cleanedValues as MeasureType, menuItem.key)
					.then((response: any) => {
						onSave(response.data);
						Emitter.emit("alert", {
							type: "success",
							message: "Measure created successfully",
							description: "You have successfully created a measure",
							timeout: 5000,
						});
						form?.resetFields();
					})
					.catch((error) => {
						Emitter.emit("alert", {
							type: "error",
							message: "Unsuccessful saving measure",
							description: "There was an error while saving measure",
							timeout: 5000,
						});
						console.error(error);
					})
					.finally(() => {
						closeModal();
					});
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Modal
			{...restProps}
			title={`${measureState.isNew ? "Edit" : "New"} Measure`}
			className="name-description-modal"
			okText={"Save"}
			onOk={handleSaveMeasure}
			style={{ zIndex: 30005 }}
			destroyOnClose
		>
			<Form.Item
				required
				label="Name"
				name={"name"}
				layout="vertical"
				rules={[
					{ required: true, message: "Please fill in measure name" },
					{
						validator: (rule, value) => {
							const measureId = form?.getFieldValue("id");

							if (!value) {
								return Promise.resolve();
							}

							const cleanedName = value?.trim().toLowerCase();

							const found = measures?.find((_measure: any) => {
								const cleanedMeasureName = _measure?.name?.trim().toLowerCase();

								if (measureId) {
									return (
										(cleanedMeasureName === cleanedName ||
											cleanedName === "") &&
										_measure?.id !== measureId
									);
								} else {
									return (
										cleanedMeasureName === cleanedName || cleanedName === ""
									);
								}
							});

							if (!found) {
								return Promise.resolve();
							} else {
								return Promise.reject("Measure name must be unique");
							}
						},
					},
				]}
			>
				<Input
					style={measureState.errorMessage !== "" ? { marginBottom: 8 } : {}}
					placeholder="Measure 1"
				/>
			</Form.Item>
			<Form.Item layout="vertical" label="Description" name={"description"}>
				<Input placeholder="This is a description" />
			</Form.Item>
		</Modal>
	);
};

export default NameDescriptionModal;
