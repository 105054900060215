import React, {
	useRef,
	useEffect,
	useState,
	useMemo,
	useContext,
	createRef,
} from "react";
import { Engine, Scene } from "react-babylonjs";
import * as BABYLON from "@babylonjs/core";
import { api } from "../contexts/AuthContext";
import {
	getEngineeringAsset,
	getVesselModel,
} from "../services/api-server/digitaltwin";
import {
	Modal,
	Progress,
	Spin,
	Menu,
	Input,
	Space,
	Typography,
	Button,
	Popover,
} from "antd";
import Portal_Icon_Logo from "../assets/img/Portal_hexagon_RGB.png";
import Test from "./../assets/img/newnewnew3.png";
// import Video from "./../assets/video/Download.mp4";
// import Audio from "./../assets/video/Monday.mp3";
import { getMaximoData, getMaximoXMLData } from "../services/api-server/maximo";
import { GetAntIcon } from "../utils/ant_icons";
import { socket } from "../utils/socket";
import { Inspector } from "@babylonjs/inspector";
import { useLocation } from "react-router-dom";
import { HomeContext } from "./Home";
import { getVesselTag } from "../services/api-server/kognifai";
import dayjs from "dayjs";
import { duckQuery } from "../services/api-server/deltashare";
import "../assets/css/digitalTwin.css";
import Emitter from "../services/EventEmitter";
import { ComponentHook } from "../utils/components";
import { setErrorAlertMessages } from "../utils/utils";
import DigitalTwinLeftDrawer from "../components/digital-twin/DigitalTwinLeftDrawer";
import DigitalTwinRightDrawer from "../components/digital-twin/DigitalTwinRightDrawer";
import TutorialModal from "../components/digital-twin/TutorialModal";
import { escape } from "lodash";
import PieChart from "../components/charts/PieChart";
import { getRigObject, getRigObjectbyCode } from "../services/RigInfo";

const defaultPosition = new BABYLON.Vector3(106.97, 60.05, -108.46);
let cloned = defaultPosition.clone();
const defaultTarget = new BABYLON.Vector3(98.92, 46.94, 282.67);

//V1.5 PointerMove Timeout
let pointerMoveTimerID: any = null;
let popoverTimerID: any = null;

// Boolean to check if user wants to skip the model build up
let skipLoadingModel: any = false;

// Interval check for updating FPS for dynamic environment
let FPSCheckInterval: any = null;

// All current animation tags
const kognifai_data_series = [
	"StenaCarron.OPC UA.NOV.2.DataVault.PubSub.SDI-101-A00",
	"StenaCarron.OPC UA.NOV.2.DataVault.PubSub.SDI2-101-A00",
];

// Animation tags to mesh match
const kognifai_data_series_animation: any = {
	"StenaCarron.OPC UA.NOV.2.DataVault.PubSub.SDI-101-A00": [
		{
			objectName: "P.313001",
			objectType: "pickable",
			minY: 51,
			denominator: 1,
		},
		{
			objectName: "P.312026",
			objectType: "pickable",
			minY: 82,
			denominator: 2,
		},
		{
			objectName: "A.312025",
			objectType: "animation",
			minY: 74.6,
			denominator: 2,
		},
	],
	"StenaCarron.OPC UA.NOV.2.DataVault.PubSub.SDI2-101-A00": [
		{
			objectName: "P.313002",
			objectType: "pickable",
			minY: 51,
			denominator: 1,
		},
		{
			objectName: "P.312031",
			objectType: "pickable",
			minY: 82,
			denominator: 2,
		},
		{
			objectName: "A.312030",
			objectType: "animation",
			minY: 74.6,
			denominator: 2,
		},
	],
};

//Main Code
const DigitalTwin = (props: any) => {
	const ContextConsumer: any = useContext(HomeContext);

	const originalClipPlanes: any = {
		clipPlane: new BABYLON.Plane(1, 0, 0, -10000),
		clipPlane2: new BABYLON.Plane(-1, 0, 0, -10000),
		clipPlane3: new BABYLON.Plane(0, 1, 0, -100000),
		clipPlane4: new BABYLON.Plane(0, -1, 0, -10000),
		clipPlane5: new BABYLON.Plane(0, 0, 1, -10000),
		clipPlane6: new BABYLON.Plane(0, 0, -1, -100000),
	};

	const clippingPlanesRef = useRef<any>({
		...originalClipPlanes,
	});

	const mqttRef = useRef<any>([]);
	const searchInputRef = useRef<any>(null);
	const assetTreeRef = useRef<any>(null);
	const pobTreeRef = useRef<any>(null);

	//Babylon Must Have Ref
	const sceneRef = useRef<any>(null);
	const engineRef = useRef<any>(null);
	const canvasRef = useRef<any>(null);

	//Camera Ref
	const cameraRef = useRef<any>(null);
	const cameraArcRef = useRef<any>(null);
	const cameraComRef = useRef<any>(null);
	const activeCameraRef = useRef<any>(null);
	const previousPosition = useRef<any>({});

	//Babylon Common Ref
	const mouseHoldRef = useRef<any>(null);
	const movementRef = useRef<any>([]);
	const crosshairRef = useRef<any>(null);
	const boxRef = useRef<any>(null);
	const smallBoxRef = useRef<any>(null);
	const skyRef = useRef<any>(null);
	const oceanRef = useRef<any>(null);

	//Focus Mode Ref
	const isFocusedRef = useRef<any>(false);
	const rightDrawerKeyRef = useRef<any>("");

	//Level Refs
	const level1Ref = useRef<any>(null);
	const level2Ref = useRef<any>(null);
	const level3Ref = useRef<any>(null);

	//Meshes
	const downloadingRef = useRef<any>(false);
	const highPolyMeshes = useRef<any>(null);
	const AllMeshes = useRef<any>(null);
	const pickables = useRef<any>(null);
	const animateMesh = useRef<any>(null);
	const engineerMesh = useRef<any>([]);
	const pipeMesh = useRef<any>([]);
	const meshRefs = useRef<any>([]);
	const highlightedAssetRef = useRef<any>([]);
	const closeMeshRef = useRef<any>([]);
	const Pointerlock = useRef<any>(false);

	const hullRef = useRef<any>([]);
	const pointerClickRef: any = useRef<any>(null);

	//Animations
	const animationGroupRef = useRef<any>([]);
	const intervalRef = useRef<any>([]);
	const isInspectorOpenRef = useRef<any>(false);

	//Scene states
	const [options, setOptions] = useState<any>(
		JSON.parse(localStorage.getItem("DT_settings") as string) || {
			enablePipes: false,
			enableSky: true,
			enableOcean: true,
		}
	);
	const [scene, setScene] = useState<any>(null);
	const [canvas, setCanvas] = useState<any>(null);
	const [graphicalEngine, setGraphicEngine] = useState<string>(
		localStorage.getItem("graphicsEngine") || "WebGL"
	);

	//Babylon States
	const [FPS, setFPS] = useState<any>(0);
	const [cameraPosition, setCameraPosition] = useState<any>(null);
	const [cameraTarget, setCameraTarget] = useState<any>(null);

	const [lowFPSCounter, setLowFPSCounter] = useState<any>(0);
	const [tempCounter, setTempCounter] = useState<any>(0);
	const [cameraSpeed, setCameraSpeed] = useState<any>(2.55);
	const [cameraFov, setCameraFov] = useState<any>(1.5);
	const [cameraMode, setCameraMode] = useState<any>("1");
	const [highlightLayer, setHighlightLayer] = useState<any>(null);

	//Loading, Modal, Drawers
	const [isLoaded, setLoaded] = useState<any>(false);
	const [isAssetLoaded, setAssetLoaded] = useState<any>(true);
	//Maximo
	const [isMaximoView, setMaximoView] = useState<boolean>(false);
	const [isMaximoLoading, setMaximoLoading] = useState<any>(false);
	const [maximoAssetData, setMaximoAssetData] = useState<any>(null);
	const [maximoWOData, setMaximoWOData] = useState<any>(null);

	const [maximoWORecord, setMaximoWORecord] = useState<any>(null);
	const [maximoEFRRecord, setMaximoEFRRecord] = useState<any>(null);
	const [maximoModificationsRecord, setMaximoModificationsRecord] =
		useState<any>(null);
	const [maximoEFRData, setMaximoEFRData] = useState<any>(null);
	const [maximoWODataList, setMaximoWODataList] = useState<any>(null);
	const [maximoWODuckList, setMaximoWODuckList] = useState<any>([]);
	const [maximoEFRDataList, setMaximoEFRDataList] = useState<any>(null);
	const [maximoModificationDataList, setMaximoModificationDataList] =
		useState<any>(null);
	const [filteredMaximoWODataList, setFilteredMaximoWODataList] =
		useState<any>(null);
	const [filteredMaximoEFRDataList, setFilteredMaximoEFRDataList] =
		useState<any>(null);
	const [
		filteredMaximoModificationDataList,
		setFilteredMaximoModificationDataList,
	] = useState<any>(null);
	const [maximoTotalIncidentDowntime, setMaximoTotalIncidentDowntime] =
		useState<any>(null);
	const [isMaximoWOLoading, setMaximoWOLoading] = useState<any>(false);
	const [isMaximoEFRLoading, setMaximoEFRLoading] = useState<any>(false);
	const [isMaximoModificationsLoading, setMaximoModificationsLoading] =
		useState<any>(false);
	const [currentMaximoKey, setCurrentMaximoKey] = useState<any>("");
	const [currentMaximoKeyList, setCurrentMaximoKeyList] = useState<any>(null);

	//Drawer System
	const [leftDrawer, setLeftDrawer] = useState<any>(false);
	const [rightDrawer, setRightDrawer] = useState<any>(false);
	const [rightDrawerData, setRightDrawerData] = useState<any>({});
	const [rightDrawerKey, setRightDrawerKey] = useState<string>("");
	const [rightDrawerTabKey, setRightDrawerTabKey] =
		useState<string>("overview");

	//Side menus and modals
	const [menuKey, setMenuKey] = useState<any>("");
	const menuKeyRef = useRef<string>("");
	const [tutorialModal, setTutorialModal] = useState<any>(false);
	const [tutorial, setTutorial] = useState<any>(false);
	const [FSModal, setFSModal] = useState<any>(false);
	const [FSMessage, setFSMessage] = useState<any>(false);

	//MQTT data
	const [mqttList, setMqttList] = useState<any>([]);

	const location = useLocation().pathname;

	const [ModelLoadPercent, setModelLoaded] = useState<any>(0);
	const [ModelLoadDesc, setModelLoadDesc] = useState<any>("Initialise engine");
	const [noShow, setNoShow] = useState<any>(false);
	const [seaState, setSeaState] = useState<any>({});
	const [currentCamera, setCurrentCamera] = useState<any>("1");
	const [assetHierarchy, setAssetHierarchy] = useState<any>(null);
	const flatAssetHierarchy = useRef<any>(null);
	const [duckWOData, setDuckWOData] = useState<any>(null);

	const [pobHierarchy, setPobHierarchy] = useState<any>(null);
	const [pobData, setPobData] = useState<any>(null);
	const [selectedKeys, setSelectedKeys] = useState<any>([]);
	const [selectValue, setSelectValue] = useState<any>(null);
	const [searchResults, setSearchResults] = useState<Array<any>>([]);
	const [searchValue, setSearchValue] = useState<any>("");
	const [isSearch, setIsSearch] = useState(false);

	const [tempAssetKey, setTempAssetKey] = useState<any>(null);
	const [availableAssets, setAvailableAssets] = useState<any>(null);
	const [assetTreeSearchValue, setAssetTreeSearchValue] = useState("");
	const [expandedKeys, setExpandedKeys] = useState<any>([]);

	const popoverPlacementRef = useRef<any>("right");
	const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
	const [popoverContent, setPopoverContent] = useState<any>("");

	const [digitalTwinSettings, setDigitalTwinSettings] = useState<any>({});

	const dt_rig = props.params.mitem?.dt_rig;
	const dt_data = props.params.mitem?.dt_data;
	const rig_code = dt_data?.code;

	var shiftKeyDown: boolean = false;
	var spaceKeyDown: boolean = false;

	const abortControllerRef = useRef<AbortController | null>(null);

	const getAllKeys = (tree: any, assetnum: any) => {
		// Result array to store the keys
		let result: any = [];

		// Helper function to recursively search for the key and collect all keys
		const recursiveSearch = (obj: any, currentKey: any) => {
			if (currentKey === assetnum) {
				// Function to collect all keys from the object
				const collectKeys = (currentObj: any) => {
					if ("key" in currentObj) {
						result.push(currentObj.key);
					}
					if ("children" in currentObj) {
						// If the current property has children, recursively collect their keys
						if (Array.isArray(currentObj["children"])) {
							currentObj["children"].forEach((child: any) =>
								collectKeys(child)
							);
						}
					}
				};
				// Collect keys starting from the found key's object
				collectKeys(obj);
			} else {
				// Continue searching deeper in the object if the current key doesn't match
				if (obj && obj.children && Array.isArray(obj.children)) {
					obj.children.forEach((child: any) =>
						recursiveSearch(child, child.key)
					);
				}
			}
		};

		// Start the recursive search from the root object
		if (tree && tree.key) {
			recursiveSearch(tree, tree.key);
		}

		return result;
	};

	const focusMesh = (meshes: any, camera: any) => {
		let minX = Number.POSITIVE_INFINITY;
		let minY = Number.POSITIVE_INFINITY;
		let minZ = Number.POSITIVE_INFINITY;
		let maxX = Number.NEGATIVE_INFINITY;
		let maxY = Number.NEGATIVE_INFINITY;
		let maxZ = Number.NEGATIVE_INFINITY;

		meshes.forEach((mesh: any) => {
			// Get the world matrix for the mesh
			const worldMatrix = mesh.getWorldMatrix();
			const boundingInfo = mesh.getBoundingInfo();

			const minimum = boundingInfo.minimum.multiply(mesh.scaling);
			const maximum = boundingInfo.maximum.multiply(mesh.scaling);

			const worldMin = BABYLON.Vector3.TransformCoordinates(
				minimum,
				worldMatrix
			);
			const worldMax = BABYLON.Vector3.TransformCoordinates(
				maximum,
				worldMatrix
			);

			minX = Math.min(minX, worldMin.x);
			minY = Math.min(minY, worldMin.y);
			minZ = Math.min(minZ, worldMin.z);

			maxX = Math.max(maxX, worldMax.x);
			maxY = Math.max(maxY, worldMax.y);
			maxZ = Math.max(maxZ, worldMax.z);
		});

		// Create a bounding box that encapsulates all the meshes
		const boundingBox = new BABYLON.BoundingBox(
			new BABYLON.Vector3(minX, minY, minZ),
			new BABYLON.Vector3(maxX, maxY, maxZ)
		);

		// Calculate the center and size of the bounding box
		const center = boundingBox.center;
		const size = boundingBox.maximum.subtract(boundingBox.minimum);

		// Calculate the distance the camera needs to be to view the entire bounding box
		const maxDim = Math.max(size.x, size.y, size.z);
		const distance = maxDim * 1.5; // Adjust this factor as needed

		// Position the camera to view the entire bounding box
		const direction = camera.position.subtract(camera.target).normalize();
		camera.position = center.add(direction.scale(distance));
		camera.setTarget(center);
	};

	// FPS counter useEffect
	useEffect(() => {
		const checkFPS = () => {
			if (lowFPSCounter < 10) {
				FPSCheckInterval = setInterval(() => {
					let FPS = scene.getEngine().getFps().toFixed();
					if (FPS < 15) {
						setLowFPSCounter(lowFPSCounter + 1);
						clearInterval(FPSCheckInterval);
					}
				}, 1000);
			}
		};

		if (scene && isLoaded) {
			checkFPS();
		}

		Emitter.on("page-visible", () => {
			checkFPS();
		});

		Emitter.on("page-invisible", () => {
			clearInterval(FPSCheckInterval);
		});

		if (lowFPSCounter == 10) {
			Emitter.emit("alert", {
				type: "info",
				message: "Optimising Your Experience",
				description:
					"The Digital Twin environment is now disabled to improve performance",
			});
			setLowFPSCounter(lowFPSCounter + 1);
			enablePipes(false);
			skyRef.current.setEnabled(false);
			oceanRef.current.setEnabled(false);
			Emitter.remove("page-visible");
			Emitter.remove("page-invisible");
		}

		return () => {
			clearInterval(FPSCheckInterval);
			FPSCheckInterval = null;
			Emitter.remove("page-visible");
			Emitter.remove("page-invisible");
		};
	}, [scene, lowFPSCounter, isLoaded, leftDrawer, tempCounter]);

	// Just for handling closing left drawer and stopping FPS check
	const OpenCloseLeftDrawer = (boolean: any) => {
		abortControllerRef?.current?.abort();
		setLeftDrawer(boolean);
		clearInterval(FPSCheckInterval);
		setTimeout(() => {
			setTempCounter(tempCounter + 1);
		}, 3000);
	};

	// Enable/disable certain assets based on the settings options
	useEffect(() => {
		if (scene) {
			enablePipes(options.enablePipes);
			skyRef.current.setEnabled(options.enableSky);
			oceanRef.current.setEnabled(options.enableOcean);

			localStorage.setItem("DT_settings", JSON.stringify(options));
		}
	}, [options]);

	// Clean up when Digital Twin is not handled
	useEffect(() => {});

	// Enable/disable pipework from Digital Twin
	const enablePipes = (boolean: any) => {
		// console.log(pipeMesh.current);
		pipeMesh.current.forEach((mesh: any) => {
			// console.log(mesh);
			mesh.setEnabled(boolean);
		});
	};

	// Set sky visibility on load
	const setSkyInvisibility = () => {
		skyRef.current.setEnabled(options.enableSky);
	};

	// Set ocean visibility on load
	const setOceanInvisibility = () => {
		oceanRef.current.setEnabled(options.enableOcean);
	};

	// Fetch kognifai data for animation/initial state purpose
	const getKognifaiData = (
		data_series: Array<any>,
		interval: string,
		rig: string
	) => {
		const promises = data_series.map((element: any) => {
			const tag: any = {};
			const start_date = dayjs().subtract(5000, "milliseconds").toISOString();
			const end_date = dayjs().toISOString();

			return getVesselTag(rig, element, start_date, end_date, "5m")
				.then((response: any) => {
					if (response.data.error) {
						// console.error(response.data.error);
					} else {
						let data = response.data[0];
						data["series"] = element;
						return data;
					}
				})
				.catch((error) => {
					// console.error(error);
				});
		});
		return Promise.all(promises);
	};

	// Generates a data tree hierarchy in antd format depending on the the type of flat data used
	const generateTreeData = (
		type: String,
		hierarchy: Array<any>,
		availableAssets: Array<any> | null = null
	) => {
		//A method to counter the number of leaf nodes for the parent
		const countLeafNodes: any = (node: any) => {
			if (!node || node.length <= 0) {
				return 1;
			} else {
				let leafCount = 0;
				node.forEach((leaf: any) => {
					leafCount += countLeafNodes(leaf.children);
				});
				return leafCount;
			}
		};

		//Traverse through the data and create a tree
		const traverse = (
			element: any,
			level: number = 1,
			parentKey: string = ""
		) => {
			const { title = "", key = "", children = [] } = element;
			const treePos = level === 1 ? key : `${parentKey}-${key}`;

			let data: any = {
				key,
				title: "",
			};
			switch (type) {
				case "Asset":
					const titleArray: Array<string> = title
						.trim()
						.split(/[\s+-]/)
						.filter((v: any) => v);
					// titleArray.splice(0, 1);
					const cleanedTitle = titleArray.join(" ");
					data.cleanedTitle = cleanedTitle;
					data.fullName = `${cleanedTitle} (${key})`;
					data.assetExist = element.hasAsset;
					data.isEngineerAsset = element.isEngineerAsset;
					data.level = level;
					data.treePos = treePos;
					data.className = `digitaltwin-tree-level-${level}`;
					let marginLeft = 0;
					// let color = duckWOData?.some((string: any) =>
					// 	string?.startsWith(data.key)
					// )
					// 	? "#FF4D4F"
					// 	: "#434343";
					let color = "#434343";
					switch (element.status) {
						case "critical":
							color = "#FF4D4F";
							break;
					}
					marginLeft = 16 * (level - 1);

					data.style = {
						// marginLeft: `${24 * Number(level > 1)}px`,
						marginLeft: `${marginLeft}px`,
						borderLeft: `${level > 1 ? "4px" : "6px"} solid ${color}`,
						marginBottom: `4px`,
						padding: `0`,
					};
					break;

				case "Crew":
					data = {
						...element,
					};
					data.cleanedTitle = title;
					data.treePos = treePos;
					data.level = level;
					data.occupation = title;
					data.className = `digitaltwin-tree-level-${level}`;
					data.style = { marginLeft: `${24 * Number(level > 1)}px` };

					break;
			}

			if (children && children.length !== 0) {
				data.children = children.map((child: any) =>
					traverse(child, level + 1, treePos)
				);
				data.childrenCount = countLeafNodes(children);
			}
			return data;
		};

		const treeData = hierarchy.map((element) => {
			return traverse(element);
		});

		return treeData;
	};

	// Generate asset tree data based maximo tree
	const assetTreedata: any = useMemo(() => {
		if (assetHierarchy) {
			let t = generateTreeData("Asset", assetHierarchy);
			return t;
		}
	}, [assetHierarchy, pickables.current]);

	// Generate crew tree data based crew_model and crew data
	const pobTreeData: any = useMemo(() => {
		if (pobHierarchy) {
			let res = generateTreeData("Crew", pobHierarchy, null);
			return res;
		}
	}, [pobHierarchy]);

	const handleSearch = (value: string, isSpecific: boolean = false) => {
		let expandedKeys: Array<any> = [];
		const filterTreeData = (
			nodes: Array<any>,
			key: any,
			searchText: string
		) => {
			let filteredNodes: Array<any> = [];
			nodes.forEach((node) => {
				if (key == "Asset") {
					if (isSpecific) {
						if (node.key.toLowerCase() == searchText) {
							filteredNodes.push(node);
						} else if (node.children) {
							const filteredChildren = filterTreeData(
								node.children,
								key,
								searchText
							);
							if (filteredChildren.length > 0) {
								filteredNodes = filteredChildren;
							}
						}
					} else {
						if (node.fullName.toLowerCase().includes(searchText)) {
							filteredNodes.push(node);
							expandedKeys.push(node.key);
						} else if (node.children) {
							const filteredChildren = filterTreeData(
								node.children,
								key,
								searchText
							);
							if (filteredChildren.length > 0) {
								filteredNodes.push({ ...node, children: filteredChildren });
								expandedKeys.push(node.key);
							}
						}
					}
				} else if (key == "Crew") {
					if (
						node.full_name?.toLowerCase().includes(searchText) ||
						node.occupation?.toLowerCase().includes(searchText)
					) {
						filteredNodes.push(node);
						expandedKeys.push(node.key);
					} else if (node.children) {
						const filteredChildren = filterTreeData(
							node.children,
							key,
							searchText
						);
						if (filteredChildren.length > 0) {
							filteredNodes.push({ ...node, children: filteredChildren });
							expandedKeys.push(node.key);
						}
					}
				}
			});
			return filteredNodes;
		};
		const splitSearchValue = value.split("_");
		const searchKey: any = splitSearchValue.pop();
		const searchValue = splitSearchValue.join("_");

		const cleanedValue = searchValue.trim().toLowerCase();
		if (cleanedValue === "") {
			return;
		}

		let searchData: any = null;
		switch (searchKey) {
			case "Asset":
				searchData = assetTreedata;
				break;
			case "Crew":
				searchData = pobTreeData;
				break;
		}

		const filteredTreeData = filterTreeData(
			searchData,
			searchKey,
			cleanedValue
		);
		if (isSpecific) {
			return filteredTreeData;
		} else {
			setIsSearch(true);
			if (expandedKeys.length < 20) {
				setExpandedKeys(expandedKeys);
			}
			setSearchResults(filteredTreeData);
		}
	};

	const showPopover = (asset: any, event: any) => {
		clearTimeout(popoverTimerID);

		if (event === true) {
			popoverTimerID = setTimeout(() => {
				let assetnum: any = null;
				if (level2Ref.current || level3Ref.current) {
					assetnum = asset.replace("P.", "").trim().split(/[-\s]/)[0];
				} else {
					assetnum = asset.replace("P.", "").trim().split(/[-\s]/)[1];
				}

				let assetNode = flatAssetHierarchy.current.find(
					(node: any) => node.key === assetnum
				);
				if (assetNode) {
					setPopoverContent(assetNode.cleanedTitle);
					setPopoverVisible(event);
				} else {
					setPopoverContent(`Asset not found (${assetnum})`);
					setPopoverVisible(event);
				}
			}, 1);
		} else {
			setPopoverVisible(false);
		}
	};

	const startUp = () => {
		if (localStorage.getItem("skipTutorial") != "true") {
			setTutorial(1);
		}
	};

	//Engine Initialization
	useEffect(() => {
		if (
			// acceptedroles &&
			graphicalEngine === "WebGPU"
		) {
			const canvas = canvasRef.current;
			const engine = new BABYLON.WebGPUEngine(canvas, {
				setMaximumLimits: true,
				enableAllFeatures: true,
			});

			engine
				.initAsync()
				.then(() => {
					var createScene = function () {
						var scene = new BABYLON.Scene(engine);

						const camera = new BABYLON.UniversalCamera(
							"UniCamera",
							defaultPosition.clone(),
							scene
						);
						camera.target = defaultTarget;
						camera.speed = cameraSpeed;
						camera.inertia = 0;
						camera.angularSensibility = 1000;
						camera.minZ = 0.3;
						camera.fov = cameraFov;
						camera.attachControl(canvas, true);

						cameraRef.current = camera;

						const ArcCamera = new BABYLON.ArcRotateCamera(
							"RotateCamera",
							Math.PI / 4,
							Math.PI / 4,
							10,
							new BABYLON.Vector3(98.92, 46.94, 282.67),
							scene
						);
						ArcCamera.upperRadiusLimit = 300;
						ArcCamera.lowerRadiusLimit = 300;
						ArcCamera.minZ = 0.5;
						ArcCamera.fov = 0.5;
						ArcCamera.useAutoRotationBehavior = true;
						cameraArcRef.current = ArcCamera;

						const ArcComCamera = new BABYLON.ArcRotateCamera(
							"RotateComponentCamera",
							Math.PI / 4,
							Math.PI / 4,
							10,
							BABYLON.Vector3.Zero(),
							scene
						);
						ArcComCamera.upperRadiusLimit = 300;
						ArcComCamera.lowerRadiusLimit = 1;
						ArcComCamera.minZ = 0.1;
						cameraComRef.current = ArcComCamera;

						return scene;
					};

					const scene = createScene();
					sceneRef.current = scene;
					engineRef.current = engine;

					if (scene.isReady()) {
						onSceneReady(scene);
					} else {
						scene.onReadyObservable.addOnce(() => {
							onSceneReady(scene);
						});
					}

					engine.runRenderLoop(function () {
						scene.render();
					});

					window.addEventListener("resize", function () {
						engine.resize();
					});
				})
				.catch((error: any) => {
					console.log("fail to initialise WebGPU engine, changing to WebGL");
					localStorage.setItem("graphicsEngine", "WebGL");
					setGraphicEngine("WebGL");
				});
		}
		// else if (!acceptedroles) {
		// 	setacceptedroles(checkRoles(props?.role, props?.params?.roles));
		// }
	}, [location]);

	//Scene Creation
	useEffect(() => {
		startUp();
		pureReset();

		if (engineRef.current) {
			var createScene = function () {
				var scene = new BABYLON.Scene(engineRef.current);

				const camera = new BABYLON.UniversalCamera(
					"UniCamera",
					defaultPosition.clone(),
					scene
				);
				camera.target = defaultTarget;
				camera.speed = cameraSpeed;
				camera.inertia = 0;
				camera.angularSensibility = 1000;
				camera.minZ = 0.3;
				camera.fov = cameraFov;
				camera.attachControl(canvas, true);

				cameraRef.current = camera;

				const ArcCamera = new BABYLON.ArcRotateCamera(
					"RotateCamera",
					Math.PI / 4,
					Math.PI / 4,
					10,
					new BABYLON.Vector3(98.92, 46.94, 282.67),
					scene
				);
				ArcCamera.upperRadiusLimit = 300;
				ArcCamera.lowerRadiusLimit = 300;
				ArcCamera.minZ = 0.5;
				ArcCamera.fov = 0.5;
				ArcCamera.useAutoRotationBehavior = true;
				cameraArcRef.current = ArcCamera;

				const ArcComCamera = new BABYLON.ArcRotateCamera(
					"RotateComponentCamera",
					Math.PI / 4,
					Math.PI / 4,
					10,
					BABYLON.Vector3.Zero(),
					scene
				);
				ArcComCamera.upperRadiusLimit = 300;
				ArcComCamera.lowerRadiusLimit = 1;
				ArcComCamera.minZ = 0.1;
				cameraComRef.current = ArcComCamera;

				return scene;
			};

			const scene = createScene();
			sceneRef.current = scene;
			engineRef.current = engineRef.current;

			if (scene.isReady()) {
				onSceneReady(scene);
			} else {
				scene.onReadyObservable.addOnce(() => {
					onSceneReady(scene);
				});
			}
		}

		return () => {
			// console.log("Disposing scene");
			// sceneRef.current.dispose();
			// sceneRef.current = null;
		};
	}, [location, graphicalEngine]);

	// POB traversal
	const traversePobHierarchy = (data: any, pobData: any) => {
		let tree: any = {
			title: "Stena Drilling",
			key: "stenadrilling",
			selectable: false,
			children: [],
		};
		let occupationCount: any = {};
		const items: any = {
			occupation: "-",
			area: "-",
			full_name: "-",
			email: "-",
			hierarchy: "-",
			arrival_date: "-",
			departure_date: "TBC",
			days_on_rig: 0,
			cabin: "-",
			lifeboat_name: "-",
			company_contact_no: "-",
			emergency_team: "-",
		};

		const levenshteinSimilarity = (str1: any, str2: any) => {
			if (str1.length === 0 && str2.length === 0) {
				return 100.0;
			} else if (str1.length === 0 || str2.length === 0) {
				return 0.0;
			}

			const dp = new Array(str1.length + 1)
				.fill(0)
				.map(() => new Array(str2.length + 1).fill(0));

			for (let i = 0; i <= str1.length; i++) {
				dp[i][0] = i;
			}

			for (let j = 0; j <= str2.length; j++) {
				dp[0][j] = j;
			}

			for (let i = 1; i <= str1.length; i++) {
				for (let j = 1; j <= str2.length; j++) {
					const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
					dp[i][j] = Math.min(
						dp[i - 1][j] + 1,
						dp[i][j - 1] + 1,
						dp[i - 1][j - 1] + cost
					);
				}
			}

			const maxLen = Math.max(str1.length, str2.length);
			const similarity =
				((maxLen - dp[str1.length][str2.length]) / maxLen) * 100.0;

			return similarity;
		};

		const createChildNode = (
			parent: any,
			key: any,
			elementNode: any,
			isOccupation: any = false
		) => {
			let childNode = parent.children.find((node: any) => node.key === key);

			if (!isOccupation) {
				if (!childNode) {
					childNode = {
						title: key,
						key,
						selectable: isOccupation,
						children: [],
					};
					parent.children.push(childNode);
				}
			} else if (isOccupation) {
				key = elementNode.occupation.split("-").join(" ");

				if (!occupationCount[key]) {
					occupationCount[key] = 1;
				} else {
					occupationCount[key] += 1;
				}

				const customOccupation: any = {
					Medic: "Doctor",
				};

				let data: any = { ...items };
				let selectedPobData: any = {};
				let originalOccupation = key;
				let selectedOccupation =
					customOccupation[originalOccupation] || originalOccupation;
				let occupationIndex = occupationCount[key];

				let highestSimilarityString: any = "";
				let similarityIndex: any = 0;
				let pobList: any = pobData
					.filter((element: any) => {
						if (element.occupation.includes(selectedOccupation)) {
							return true;
						} else {
							let similarity = levenshteinSimilarity(
								element.occupation,
								selectedOccupation
							);

							if (similarity > 80) {
								return true;
							}
						}
					})
					.map((element: any) => {
						let similarity = levenshteinSimilarity(
							element.occupation,
							selectedOccupation
						);

						if (similarity > similarityIndex) {
							similarityIndex = similarity;
							highestSimilarityString = element.occupation;
						}
						return element;
					})
					.filter((element: any) => {
						return element.occupation === highestSimilarityString;
					});

				if (pobList.length > 0) {
					selectedPobData = pobList[occupationIndex - 1] || {};
				}

				Object.keys(items).map((element: any) => {
					data[element] = selectedPobData[element] || items[element];
				});

				let arrival_date = new Date(data.arrival_date);
				let departure_date = new Date(data.departure_date);
				let days_on_rig = data.days_on_rig;
				let today = new Date();

				if (arrival_date.toString() != "Invalid Date") {
					data.arrival_date = new Date(data.arrival_date).toLocaleDateString(
						"en-GB",
						{
							day: "2-digit",
							year: "numeric",
							month: "long",
						}
					);
				}

				if (departure_date.toString() != "Invalid Date") {
					data.departure_date = new Date(
						data.departure_date
					).toLocaleDateString("en-GB", {
						day: "2-digit",
						year: "numeric",
						month: "long",
					});
				}

				if (days_on_rig == "0" && arrival_date.toString() != "Invalid Date") {
					const time = today.getTime() - arrival_date.getTime();
					data.days_on_rig = time / (1000 * 60 * 60 * 24);
				}

				childNode = {
					...data,
					title: key,
					key: key + "_" + occupationCount[key],
					selectable: isOccupation,
					children: [],
					email: elementNode.Email,
					area: elementNode.area,
					hierarchy: elementNode.Hierarchy,
				};
				parent.children.push(childNode);
			}
			return childNode;
		};

		data.forEach((element: any) => {
			const functionNode = createChildNode(tree, element.function, element);
			createChildNode(functionNode, element.occupation, element, true);
		});

		return [tree];
	};

	// General Key functionalities
	useEffect(() => {
		// Genenal keyboard checker
		const handleKeyDown = (event: any) => {
			if (event.ctrlKey && event.key === "f") {
				event.preventDefault(); // Prevent the default browser search behavior
				// searchInputRef.current.focus(); // Focus on the custom search bar
			}
		};

		// Handle mouse leave to check
		const handleMouseLeave = (event: any) => {
			setTimeout(() => {
				showPopover(null, false);
				removeHighlightedAsset();
			}, 500);
		};

		// Handle mouse click to check if the inspector window is missing or not
		// to change the state of the menukeys
		const handleMouseClick = (event: any) => {
			if (
				!document.getElementById("embed-host") &&
				isInspectorOpenRef.current
			) {
				setMenuKey(null);
			}
		};

		if (isLoaded) {
			canvasRef.current.focus();
			let randomValue = 40;
			// animateBox("StenaCarron.OPC UA.NOV.2.DataVault.PubSub.SDI2_101_A00", 40);

			// intervalRef.current.push(
			// 	setInterval(() => {
			// 		randomValue = randomValue == 40 ? 0 : 40;
			// 		animateBox(
			// 			"StenaCarron.OPC UA.NOV.2.DataVault.PubSub.SDI2_101_A00",
			// 			randomValue
			// 		);
			// 	}, 15000)
			// );

			socket.emit("DigitalTwin");

			canvasRef.current.addEventListener("mouseleave", handleMouseLeave);

			document.addEventListener("keydown", handleKeyDown);
			document.addEventListener("click", handleMouseClick);

			return () => {
				document.removeEventListener("keydown", handleKeyDown);
				document.onmousemove = null;
				if (canvasRef.current) {
					canvasRef.current.removeEventListener("mouseleave", handleMouseLeave);
				}
			};
		}
	}, [isLoaded]);

	// Asset tree search
	useEffect(() => {
		if (selectValue && menuKey === "Asset") {
			const assetNode: any = handleSearch(selectValue + "_Asset", true);
			if (assetNode.length > 0) {
				setExpandedKeys(assetNode[0].treePos.split("-"));

				const treeInstance = assetTreeRef.current;
				if (treeInstance) {
					setTimeout(() => {
						var element: any = document.getElementById(selectValue);
						if (element)
							element.scrollIntoView({
								behavior: "smooth",
								block: "nearest",
								inline: "start",
							});
					}, 500);
				}
			}
		}
	}, [selectValue, menuKey]);

	// Setting up asset hierarchy etc
	const loadFiles = () => {
		return new Promise((resolve: any, reject: any) => {
			Promise.all([
				api
					.get(`/assets/hierarchy/maximo-list-${rig_code}.json`)
					.then((result: any) => {
						let filteredTree = result.data.filter((element: any) => {
							return !element.key.startsWith("1");
						});
						setAssetHierarchy(filteredTree || {});

						const flattenTree = (tree: any) => {
							const result: any = [];
							const recursiveFlatten = (node: any, parentKey: any = null) => {
								const titleArray: Array<string> = node.title
									.trim()
									.split(/[\s+-]/)
									.filter((v: any) => v);
								// titleArray.splice(0, 1);
								const cleanedTitle = titleArray.join(" ");
								result.push({
									...node,
									parentKey,
									cleanedTitle: `${cleanedTitle} (${node.key}) `,
								});
								if (node.children) {
									node.children.forEach((child: any) =>
										recursiveFlatten(child, node.key)
									);
								}
							};

							tree.forEach((node: any) => recursiveFlatten(node));
							return result;
						};
						let flat = flattenTree(filteredTree);
						flatAssetHierarchy.current = flat;
						// });
					})
					.catch((error: any) => {
						console.log(error);
					}),

				duckQuery(
					`SELECT * FROM pob WHERE pob_date = ((SELECT MAX(pob_date) FROM pob WHERE vessel_id = '${rig_code}')) and vessel_id = '${rig_code}' ORDER BY company DESC`,
					[],
					true
				)
					.then((data: any) => {
						const pobData = data?.response.sort((a: any, b: any) => {
							return a.occupation.localeCompare(b.occupation);
						});
						setPobData(pobData);

						duckQuery(
							`SELECT * FROM crew_model WHERE vessel_id=${rig_code}`,
							[],
							true
						)
							.then((data: any) => {
								let PobTree = traversePobHierarchy(data?.response, pobData);
								setPobHierarchy(PobTree);
								return true;
							})
							.catch((error) => {
								Emitter.emit("alert", {
									message: "Crew query error",
									description:
										"A query has failed to run for crew data, please try again later",
									type: "error",
									timeout: "5000",
								});
							});
						setPobHierarchy([]);
					})
					.catch((error) => {
						Emitter.emit("alert", {
							message: "Crew query error",
							description:
								"A query has failed to run for crew data, please try again later",
							type: "error",
							timeout: "5000",
						});
						setPobData([]);
						setPobHierarchy([]);
					}),
			])
				.then(() => {
					resolve("done");
				})
				.catch((error: any) => {
					console.log(error);
					reject("Fail");
				});
		});
	};

	// Setting up the scene and load all assets
	useEffect(() => {
		if (scene) {
			setModelLoaded(10);
			setModelLoadDesc("Downloading Asset Information");
			loadFiles().then(() => {
				document.addEventListener("pointerlockchange", (event: any) => {
					if (document.pointerLockElement === null) {
						Pointerlock.current = false;
						crosshairRef.current.isVisible = false;
						spaceKeyDown = false;
					}
				});
				sceneRef.current = scene;
				const hl = new BABYLON.HighlightLayer("hl", scene);
				setModelLoadDesc("Adding Skybox");
				setModelLoaded(20);

				Object.keys(originalClipPlanes).forEach((element: any) => {
					scene[element] = originalClipPlanes[element];
				});

				scene.registerBeforeRender(function () {
					//console.log("Total meshes:", scene.meshes.length);
					//console.log("Active meshes:", scene.getActiveMeshes().length);
					//console.log("Active Indices:", scene.getActiveIndices());
					let count = 0;
					// console.log(AllMeshes);
					AllMeshes.current?.forEach((element: any) => {
						if (element.isOccluded) {
							count++;
							// element.isVisible = false;
						} else {
							// element.isVisible = true;
						}
					});
					//console.log(count);
				});

				// Disable the default audio unlock button
				// let audioEngine: any = BABYLON.Engine.audioEngine;
				// audioEngine.useCustomUnlockedButton = true;

				addSkybox(scene).then((skybox: any) => {
					setModelLoadDesc("Adding Ocean");
					setModelLoaded(20);
					LoadOcean(scene, skybox).then((ocean: any) => {
						setModelLoadDesc("Downloading Vessel Model");
						setModelLoaded(30);
						loadModel(scene, hl).then((res: any) => {
							// console.log("ALL MESH COUNT: ", AllMeshes.current.length);
							// console.log("PICKABLE MESH COUNT: ", pickables.current.length);
							// console.log(
							// 	"HIGH POLY MESH COUNT: ",
							// 	highPolyMeshes.current.length
							// );

							// scene.setRenderingAutoClearDepthStencil(1, true, true, true);
							scene.setRenderingAutoClearDepthStencil(2, false, false, false);
							let meshes = sceneRef.current.meshes;
							let currentMeshIndex = 0;

							let loadInterval = setInterval(function () {
								if (currentMeshIndex < meshes.length && !skipLoadingModel) {
									// Show the current mesh part
									meshes[currentMeshIndex].isVisible = true;
									currentMeshIndex++;
									let loaded = (
										70 +
										(currentMeshIndex / meshes.length) * 20
									).toFixed(2);
									setModelLoaded(
										(70 + (currentMeshIndex / meshes.length) * 20).toFixed(2)
									);
									if (loaded === "90.00") {
									}
								} else {
									// All mesh parts have been loaded, clear the interval
									clearInterval(loadInterval);
									setModelLoadDesc("Finalizing Model");

									if (scene.activeCamera) {
										let crosshair = addCrosshair(scene);
										crosshair.renderingGroupId = 2;
										hl.addExcludedMesh(crosshair);
										crosshairRef.current = crosshair;
										// console.log(crosshair);
										var camera = scene.activeCamera;
										scene.activeCamera.detachControl();
										cameraRef.current.detachControl();
										cameraArcRef.current.detachControl();
										cameraComRef.current.detachControl();
										scene.activeCamera = cameraRef.current;
										scene.activeCamera.attachControl(canvas, false);
										activeCameraRef.current = scene.activeCamera;
										// Set the camera's collision group and mask
										//V1 box size 100 - follows camera
										var box = BABYLON.MeshBuilder.CreateBox(
											"Box",
											{ size: 100 },
											scene
										);

										//V2 box size 30 - depending on where the user clicks
										// var box = BABYLON.MeshBuilder.CreateBox(
										// 	"Box",
										// 	{ size: 30 },
										// 	scene
										// );
										box.position = camera.position;
										box.isVisible = false;
										box.isPickable = false;
										box.checkCollisions = false;
										box.renderingGroupId = 2;
										// box.visibility = 0.3;
										boxRef.current = box;

										var smallBox = BABYLON.MeshBuilder.CreateBox(
											"smallBox",
											{ size: 1 },
											scene
										);

										smallBox.position = camera.position;
										smallBox.isVisible = false;
										smallBox.isPickable = false;
										smallBox.checkCollisions = false;
										smallBox.renderingGroupId = 2;
										smallBoxRef.current = smallBox;

										// Unlock audio on first user interaction.
										// window.addEventListener(
										// 	"click",
										// 	() => {
										// 		if (!audioEngine.unlocked) {
										// 			audioEngine.unlock();
										// 		}
										// 	},
										// 	{ once: true }
										// );

										camera.keysUpward.push(69); //increase elevation
										camera.keysDownward.push(81); //decrease elevation
										camera.keysUp.push(87); //forwards
										camera.keysDown.push(83); //backwards
										camera.keysLeft.push(65);
										camera.keysRight.push(68);

										cameraComRef.current.inputs.attached.keyboard.keysLeft.push(
											65
										); // A key (rotate counterclockwise)
										cameraComRef.current.inputs.attached.keyboard.keysRight.push(
											68
										); // D key (rotate clockwise)

										if (tutorial) {
											setTutorialModal(true);
										}

										//Animate data
										let rig_data = getRigObjectbyCode(rig_code);

										//Statically do Carron for now
										if (rig_data?.name === "carron") {
											getKognifaiData(
												kognifai_data_series,
												"5s",
												rig_data?.name
											)
												.then((kognifaiArr: any) => {
													if (kognifaiArr.length > 0) {
														kognifaiArr?.forEach((element: any) => {
															if (element?.Value) {
																animateBox(
																	element?.series,
																	new Number(element?.Value),
																	50
																);
															}
														});
													}
												})
												.catch((err: any) => {
													console.log(err);
												});

											socket.on("Carron", (element: any) => {
												element = JSON.parse(element);
												if (
													kognifai_data_series.includes(element.Name) &&
													rig_code === "900"
												) {
													// console.log("Animating", element.Name, element.Value);
													animateBox(element.Name, element.Value, 3);
												}
												let index = mqttRef.current.findIndex(
													(_element: any) => {
														return element.Name === _element.Name;
													}
												);
												if (index == -1) {
													mqttRef.current.push(element);
												} else {
													mqttRef.current[index] = element;
												}

												if (menuKeyRef.current == "MQTT") {
													setMqttList([...mqttRef.current]);
												}
											});
										}

										// addParticles();

										// 	var result = new BABYLON.SceneOptimizerOptions(60, 2000);

										// 	var priority = 0;
										// 	result.optimizations.push(
										// 		new BABYLON.ShadowsOptimization(priority)
										// 	);
										// 	result.optimizations.push(
										// 		new BABYLON.LensFlaresOptimization(priority)
										// 	);

										// 	// Next priority
										// 	priority++;
										// 	result.optimizations.push(
										// 		new BABYLON.PostProcessesOptimization(priority)
										// 	);
										// 	result.optimizations.push(
										// 		new BABYLON.ParticlesOptimization(priority)
										// 	);
										// 	result.optimizations.push(
										// 		new BABYLON.HardwareScalingOptimization(priority, 0.75)
										// 	);
										// 	// ();

										// 	// Next priority
										// 	priority++;
										// 	// result.optimizations
										// 	// 	.push
										// 	// 	// new BABYLON.HardwareScalingOptimization(priority, 2.5)
										// 	// 	();
										// 	result.optimizations.push(
										// 		new BABYLON.TextureOptimization(priority, 32)
										// 	);

										// 	// Next priority
										// 	priority++;
										// 	// result.optimizations
										// 	// 	.push
										// 	// 	// new BABYLON.HardwareScalingOptimization(priority, 3)
										// 	// 	();
										// 	// result.optimizations.push(
										// 	// 	new BABYLON.RenderTargetsOptimization(priority)
										// 	// );

										// 	// Next priority
										// 	priority++;
										// 	result.optimizations.push(
										// 		new BABYLON.HardwareScalingOptimization(priority, 3)
										// 	);

										// 	result.addCustomOptimization(
										// 		function () {
										// 			setOptions({ ...options, enableHighPoly: true });
										// 			highPolyMeshes.current.forEach((element: any) => {
										// 				element.setEnabled(false);
										// 			});
										// 			return true;
										// 		},
										// 		function () {
										// 			return "Turning Off HighPolyMeshes";
										// 		}
										// 	);

										// 	BABYLON.SceneOptimizer.OptimizeAsync(
										// 		scene,
										// 		result,
										// 		function () {
										// 			console.log("success");
										// 		},
										// 		function () {
										// 			// FPS target not reached
										// 			console.log("fail");
										// 		}
										// 	);
										setModelLoaded(100);
										setLoaded(true);
									}
								}
							}, 1);
						});
					});
				});
				// setupPointerLock();
				scene.getEngine().resize();
				scene.setRenderingAutoClearDepthStencil(2, false, false, false);
			});
		}
	}, [scene]);

	// Clear Digital Twin
	useEffect(() => {
		return () => {
			//console.log("CLEAR");
			clearEverything();
		};
	}, [location]);

	useEffect(() => {
		if (pickables.current) {
			addPointerObservable();
			addKeyboardObservable();
			addMotionControllerObservable();
			addAfterRenderObservable();
		}
	}, [isLoaded]);

	// Clean up
	const clearEverything = () => {
		sceneRef.current?.meshes.forEach((mesh: any) => {
			mesh.dispose();
		});

		// clear all materials from the scene
		sceneRef.current?.materials.forEach((material: any) => {
			material.dispose();
		});

		// clear all textures from the scene
		sceneRef.current?.textures.forEach((texture: any) => {
			texture.dispose();
		});

		animationGroupRef?.current.forEach((animation: any) => {
			animation.dispose();
		});

		intervalRef.current?.forEach((interval: any) => {
			clearInterval(interval);
		});
	};

	// Reset states
	const pureReset = () => {
		OpenCloseLeftDrawer(false);
		setRightDrawer(false);
		setMenuKey(null);
		resetMaximoStates();
		setMaximoView(false);
		setSelectedKeys([]);
		setSelectValue(null);
		setCameraMode("1");

		isFocusedRef.current = false;

		level2Ref.current = null;
		level3Ref.current = null;
		level1Ref.current = null;

		if (sceneRef.current) {
			oceanRef.current.isVisible = true;
			skyRef.current.isVisible = true;
			cameraRef.current.position = defaultPosition.clone();
			cameraRef.current.target = defaultTarget.clone();
			activeCameraRef.current = scene.activeCamera;
		}

		if (Pointerlock.current) {
			document.exitPointerLock();
			Pointerlock.current = false;
		}

		setLoaded(false);
		setModelLoaded(0);
		setModelLoadDesc("Initialise engine");
	};

	// Reset all states
	const ResetEverything = () => {
		scene.activeCamera.detachControl();
		scene.activeCamera = cameraRef.current;
		//console.log(cameraRef.current);
		scene.activeCamera.attachControl(canvas, false);
		AllMeshes.current.map((element: any) => {
			element.setEnabled(true);
		});
		if (engineerMesh.current.length > 0) {
			engineerMesh.current.map((element: any) => {
				element.dispose();
			});
			engineerMesh.current = [];
		}

		resetMaximoStates();
		setMaximoView(false);
		isFocusedRef.current = false;
		setRightDrawer(false);

		setSelectedKeys([]);
		setSelectValue(null);
		setIsSearch(false);
		setSearchResults([]);
		setCameraMode("1");
		oceanRef.current.isVisible = true;
		skyRef.current.isVisible = true;
		level2Ref.current = null;
		level3Ref.current = null;
		level1Ref.current = null;

		cameraRef.current.position = defaultPosition.clone();
		cameraRef.current.target = defaultTarget;
		activeCameraRef.current = scene.activeCamera;
		if (Pointerlock.current) {
			document.exitPointerLock();
			Pointerlock.current = false;
		}
		canvasRef.current.focus();
	};

	// Add mouse keys
	const addPointerObservable = () => {
		let wheelTimer: any = null;
		scene.onPointerObservable.add((pointerInfo: any) => {
			const button: any = pointerInfo.event.button;
			switch (pointerInfo.type) {
				case BABYLON.PointerEventTypes.POINTERTAP:
					switch (button) {
						case 0:
							//console.log(pointerInfo);
							onPointerLeftClick(scene, pointerInfo);
							break;
						case 1:
							//console.log("MIDDLE");
							//console.log(closeMeshRef.current);
							break;
						case 2:
							if (isFocusedRef.current) {
								showPopover("", false);
								if (level3Ref.current) {
									// level3Ref.current.renderOutline = false;
									highlightLayer.addExcludedMesh(level3Ref.current);
									level3Ref.current = null;
									engineerMesh.current.forEach((element: any) => {
										element.visibility = 1;
									});
									resetMaximoStates();
									setRightDrawer(false);
									setMaximoView(false);

									if (level2Ref.current) {
										let assetnum = level2Ref.current
											.replace("P.", "")
											.trim()
											.split(/[-\s]/)[0];

										setRightDrawer(true);
										setMaximoLoading(true);
										setMaximoView(true);
										const node = flatAssetHierarchy.current.find(
											(asset: any) => asset.key === assetnum
										);
										if (node) {
											let assetnumList = getAllKeys(node, assetnum);
											getAssetData(assetnum, assetnumList);
										}

										setSelectedKeys([assetnum]);
										handleSearch(assetnum + "_Asset");
									}
								} else {
									//ComponentArcCamera
									scene.activeCamera.detachControl();

									scene.activeCamera = activeCameraRef.current;
									if (activeCameraRef.current === cameraRef.current)
										scene.activeCamera.attachControl(canvas, false);
									scene.activeCamera.position =
										activeCameraRef.current.position;
									scene.activeCamera.target = activeCameraRef.current.target;

									AllMeshes.current.map((element: any) => {
										element.setEnabled(true);
									});
									engineerMesh.current.map((element: any) => {
										highlightLayer.removeExcludedMesh(element);
										highlightLayer.removeMesh(element);
										element.dispose();
									});
									setTimeout(() => {
										resetMaximoStates();
										setMaximoView(false);
										isFocusedRef.current = false;
										setSelectedKeys([]);
										setExpandedKeys([]);
										setSelectValue(null);
										setSearchResults([]);
										setIsSearch(false);
										oceanRef.current.isVisible = true;
										skyRef.current.isVisible = true;
										level3Ref.current = null;
										level2Ref.current = null;
									}, 100);
									engineerMesh.current = [];
									setRightDrawer(false);
								}
							}

							break;
					}
					break;
				case BABYLON.PointerEventTypes.POINTERWHEEL:
					var scrollDelta = pointerInfo.event.wheelDelta;
					if (
						!level2Ref.current &&
						scene.activeCamera != cameraArcRef.current
					) {
						let fov = cameraRef.current.fov;
						if (mouseHoldRef.current == 0) {
							if (scrollDelta > 0) {
								fov += 0.05;
								if (fov > 1.5) {
									fov = 1.5;
								}
							} else {
								fov -= 0.05;
								if (fov < 0.05) {
									fov = 0.05;
								}
							}
							cameraRef.current.fov = Number(fov.toFixed(2));
							if (menuKeyRef.current === "Help") {
								setCameraFov(cameraRef.current.fov);
							}
						} else {
							var scrollDelta = pointerInfo.event.wheelDelta;
							let speed = cameraRef.current.speed;
							if (scrollDelta > 0) {
								speed += 0.5;
							} else {
								speed -= 0.5;
								if (speed < 0.05) {
									speed = 0.05;
								}
							}
							cameraRef.current.speed = Number(speed.toFixed(2));
							if (menuKeyRef.current === "Help") {
								setCameraSpeed(cameraRef.current.speed);
							}
						}
					}

					const wheelKey = document.getElementById(`1-Highlight`);

					if (wheelKey) {
						wheelKey.style.opacity = "1";
					}

					clearTimeout(wheelTimer);

					// Start a new wheel timer
					wheelTimer = setTimeout(() => {
						const wheelKey = document.getElementById(`1-Highlight`);

						if (wheelKey) {
							wheelKey.style.opacity = "0";
						}
					}, 100); // Adjust the delay as per your requirements

					break;
				case BABYLON.PointerEventTypes.POINTERMOVE:
					if (movementRef.current.length == 0 && !downloadingRef.current) {
						highlightMesh(scene);
					}

					break;
				case BABYLON.PointerEventTypes.POINTERDOWN:
					pointerClickRef.current = false;
					if ([0, 1, 2].includes(button)) {
						const wheelKey = document.getElementById(`${button}-Highlight`);

						if (wheelKey) {
							wheelKey.style.opacity = "1";
						}
					}
					switch (button) {
						case 0:
							mouseHoldRef.current = 0;
							//console.log("Holding left button");
							break;
					}
					break;
				case BABYLON.PointerEventTypes.POINTERUP:
					pointerClickRef.current = true;
					if ([0, 1, 2].includes(button)) {
						const wheelKey = document.getElementById(`${button}-Highlight`);

						if (wheelKey) {
							wheelKey.style.opacity = "0";
						}
					}
					switch (button) {
						case 0:
							mouseHoldRef.current = null;
							//	console.log("Release left button");
							break;
					}
					break;
			}
		});
	};

	// Add keyboard keys
	const addKeyboardObservable = () => {
		scene.onKeyboardObservable.add((keyboardInfo: any) => {
			const key = keyboardInfo.event.key.toLowerCase();

			const scene = sceneRef.current;
			showPopover(null, false);

			if (keyboardInfo.type === BABYLON.KeyboardEventTypes.KEYDOWN) {
				if (
					!movementRef.current.find((element: any) => element === key) &&
					!["alt", "tab", "ctrl", "meta"].includes(key)
				)
					movementRef.current.push(key);
				if (key === " " && scene.activeCamera.name == cameraRef.current.name) {
					if (spaceKeyDown === false) {
						if (Pointerlock.current) {
							document.exitPointerLock();
							Pointerlock.current = false;
							spaceKeyDown = true;
						} else {
							if (document.pointerLockElement === null) {
								canvasRef.current.requestPointerLock({
									unadjustedMovement: true,
								});
								spaceKeyDown = true;
								Pointerlock.current = true;
								crosshairRef.current.isVisible = true;
							}
						}
					}
				}

				if (key === "shift") {
					if (shiftKeyDown === false) {
						cameraRef.current.speed = Number(
							(cameraRef.current.speed += 2).toFixed(2)
						);
						shiftKeyDown = true;
					}
				}
				if (key === "escape") {
					ResetEverything();
				}

				//QE = Active - #BFBFBF #434343 #595959 | Inactive - #434343 #434343 #141414
				//WASD = Active - #3C9AE8 #1765AD #112A45 | Inactive - #1765AD #1765AD #141414
				if (["w", "a", "s", "d"].includes(key)) {
					//console.log("WASD");
					const keyboardKey = document.getElementById(`${key}Key`);
					const text = document.getElementById(`${key}Text`);

					if (keyboardKey && text) {
						keyboardKey.style.fill = "#112A45";
						keyboardKey.style.stroke = "#1765AD";
						text.style.fill = " #3C9AE8";
					}

					if (isFocusedRef.current) {
						if (key == "w") {
							cameraComRef.current.radius -= 3;
						} else if (key == "s") {
							cameraComRef.current.radius += 3;
						}
					}
				}
				if (["q", "e"].includes(key)) {
					const keyboardKey = document.getElementById(`${key}Key`);
					const text = document.getElementById(`${key}Text`);

					if (keyboardKey && text) {
						keyboardKey.style.fill = "#595959";
						keyboardKey.style.stroke = "#434343";
						text.style.fill = "#BFBFBF";
					}

					if (isFocusedRef.current) {
						if (key == "e") {
							cameraComRef.current.target.y += 1;
						} else if (key == "q") {
							cameraComRef.current.target.y -= 1;
						}
					}
				}
			}
			if (keyboardInfo.type === BABYLON.KeyboardEventTypes.KEYUP) {
				let index = movementRef.current.findIndex(
					(element: any) => element === key
				);
				if (index >= 0)
					movementRef.current = movementRef.current.slice(0, index);
				if (key === "shift") {
					if (shiftKeyDown === true) {
						cameraRef.current.speed = Number(
							(cameraRef.current.speed -= 2).toFixed(2)
						);
						shiftKeyDown = false;
					}
				}
				if (key === " ") {
					spaceKeyDown = false;
				}
				if (["w", "a", "s", "d"].includes(key)) {
					const keyboardKey = document.getElementById(`${key}Key`);
					const text = document.getElementById(`${key}Text`);

					if (keyboardKey && text) {
						keyboardKey.style.fill = "#141414";
						keyboardKey.style.stroke = "#1765AD";
						text.style.fill = "ws#1765AD";
					}
				}
				if (["q", "e"].includes(key)) {
					const keyboardKey = document.getElementById(`${key}Key`);
					const text = document.getElementById(`${key}Text`);

					if (keyboardKey && text) {
						keyboardKey.style.fill = "#141414";
						keyboardKey.style.stroke = "#434343";
						text.style.fill = "#434343";
					}
				}
			}
		});
	};

	// Add and experiment WebXR keys
	const addMotionControllerObservable = async () => {
		// // Add vr
		// var helper = scene.createDefaultVRExperience({
		// 	createDeviceOrientationCamera: false,
		// });
		// helper.enableInteractions();

		var xr = await scene.createDefaultXRExperienceAsync({
			disableTeleportation: true,
			//  xrInput: defaultXRExperience.input,
			//      floorMeshes: [environment.ground] /* Array of meshes to be used as landing points */
		});

		const featureManager = xr.baseExperience.featuresManager;

		featureManager.enableFeature(BABYLON.WebXRFeatureName.MOVEMENT, "latest", {
			xrInput: xr.input,
		});

		xr.input.onControllerAddedObservable.add((controller: any) => {
			console.log(controller);
			controller.onMotionControllerInitObservable.add(
				(motionController: any) => {
					if (motionController.handness === "left") {
						const xr_ids = motionController.getComponentIds();
						let triggerComponent = motionController.getComponent(xr_ids[0]); //xr-standard-trigger
						triggerComponent.onButtonStateChangedObservable.add(() => {
							console.log("Trigger button clicked");
						});
						let squeezeComponent = motionController.getComponent(xr_ids[1]); //xr-standard-squeeze
						squeezeComponent.onButtonStateChangedObservable.add(() => {
							console.log("Squeeze button clicked");
						});
						let thumbstickComponent = motionController.getComponent(xr_ids[2]); //xr-standard-thumbstick
						thumbstickComponent.onButtonStateChangedObservable.add(() => {
							console.log("ThumbStick button clicked");
						});
						thumbstickComponent.onAxisValueChangedObservable.add(
							(axes: any) => {
								console.log(axes);
							}
						);

						let xbuttonComponent = motionController.getComponent(xr_ids[3]); //x-button
						xbuttonComponent.onButtonStateChangedObservable.add(() => {
							console.log("X button clicked");
						});
						let ybuttonComponent = motionController.getComponent(xr_ids[4]); //y-button
						ybuttonComponent.onButtonStateChangedObservable.add(() => {
							console.log("Y button clicked");
						});
					}
					if (motionController.handness === "right") {
						const xr_ids = motionController.getComponentIds();
						let triggerComponent = motionController.getComponent(xr_ids[0]); //xr-standard-trigger
						triggerComponent.onButtonStateChangedObservable.add(() => {
							console.log("Trigger button clicked");
						});
						let squeezeComponent = motionController.getComponent(xr_ids[1]); //xr-standard-squeeze
						squeezeComponent.onButtonStateChangedObservable.add(() => {
							console.log("Squeeze button clicked");
						});
						let thumbstickComponent = motionController.getComponent(xr_ids[2]); //xr-standard-thumbstick
						thumbstickComponent.onButtonStateChangedObservable.add(() => {
							console.log("ThumbStick button clicked");
						});
						thumbstickComponent.onAxisValueChangedObservable.add(
							(axes: any) => {
								console.log(axes);
							}
						);

						let abuttonComponent = motionController.getComponent(xr_ids[3]); //a-button
						abuttonComponent.onButtonStateChangedObservable.add(() => {
							console.log("A button clicked");
						});
						let bbuttonComponent = motionController.getComponent(xr_ids[4]); //b-button
						bbuttonComponent.onButtonStateChangedObservable.add(() => {
							console.log("B button clicked");
						});
					}
				}
			);
		});
	};

	const addAfterRenderObservable = () => {
		scene.onAfterRenderObservable.add(async () => {
			let closeMeshes: any = [];

			//V1 - box following camera
			boxRef.current.position = cameraRef.current.position;
			if (pickables.current) {
				await Promise.all(
					pickables.current.map((mesh: any) => {
						if (mesh.intersectsMesh(boxRef.current, true)) {
							closeMeshes.push(mesh);
						} else {
							// highlightLayer.addExcludedMesh(mesh);
							// mesh.renderOutline = false;
						}
					})
				);
				closeMeshRef.current = closeMeshes;
			}

			smallBoxRef.current.position = cameraRef.current.position;
			const boxBounding = smallBoxRef.current.getBoundingInfo().boundingBox;
			const min = boxBounding.minimumWorld;
			const max = boxBounding.maximumWorld;

			if (hullRef.current && !isFocusedRef.current) {
				let check = hullRef.current.some((element: any) => {
					if (element.intersectsMinMax(min, max)) {
						return true;
					} else {
						return false;
					}
				});

				if (check && smallBoxRef.current.isEnabled()) {
					oceanRef.current.isVisible = false;
				} else {
					oceanRef.current.isVisible = true;
				}
			}
		});
	};

	// V2 Maximo Asset Data
	const getAssetData = (assetnum: any, assetnumList: any = null) => {
		let assetNumArrayString: any = null;
		if (assetnumList) {
			let modifiedList = assetnumList.map((s: any) => `'${s}'`);
			assetNumArrayString = modifiedList.join(", ");
		} else {
			assetNumArrayString = `'${assetnum}'`;
		}
		resetMaximoStates();
		abortControllerRef?.current?.abort();
		abortControllerRef.current = new AbortController();
		Promise.all([
			getMaximoXMLData(
				null,
				assetnum,
				assetNumArrayString,
				rig_code,
				"STN_PORTAL_MXASSET",
				null,
				"1",
				abortControllerRef?.current?.signal
			).then((element: any) => {
				setMaximoAssetData(element?.ASSET || []);
			}),
			Promise.all([
				//next due date WO
				getMaximoXMLData(
					null,
					assetnum,
					assetNumArrayString,
					rig_code,
					"STN_PORTAL_WO",
					// " and status NOT IN ('CLOSE', 'COMP', '3PCOMP', 'RPREV') and ISTASK='0' and TARGSTARTDATE!='' order by TARGSTARTDATE ASC",
					` and status NOT IN ('CLOSE', 'COMP', '3PCOMP', 'RPREV', 'CAN') and ISTASK='0' and TARGCOMPDATE < '${dayjs().format(
						"YYYY-MM-DD"
					)}' AND STN_MNT_CRITICALITY IN ('sc', 'SC') order by TARGCOMPDATE ASC`,

					"1",
					abortControllerRef?.current?.signal
				),
				//Last Maintenance WO
				getMaximoXMLData(
					null,
					assetnum,
					assetNumArrayString,
					rig_code,
					"STN_PORTAL_WO",
					" and status IN ('CLOSE', 'COMP') and ISTASK='0' order by ACTFINISH DESC",
					"1",
					abortControllerRef?.current?.signal
				),
			]).then((result: any) => {
				let NextWO = result[0] || { WORKORDER: [{}] };
				let lastWO = result[1]?.WORKORDER?.[0]?.ACTFINISH || [];
				NextWO.WORKORDER[0].ACTFINISH = lastWO;

				setMaximoWOData(NextWO?.WORKORDER || []);
			}),
			duckQuery(
				`SELECT ARRAY(SELECT DISTINCT workorder.wonum from workorder
				WHERE siteid=${rig_code} AND assetnum IN (${assetNumArrayString}) AND status NOT IN ('CLOSE', 'COMP', '3PCOMP', 'RPREV', 'CAN') AND istask='0' and targcompdate !=''
				AND targcompdate < '${dayjs().format(
					"YYYY-MM-DD"
				)}' AND stn_mnt_criticality IN ('sc', 'SC'))  as id_array`,
				[],
				true
			).then((duckData: any) => {
				const assetList = duckData.response[0].id_array;
				setMaximoWODuckList(assetList);
				let assetListString = "('')";
				if (assetList.length > 0) {
					assetListString = "('" + assetList.join("','") + "')";
				}
				getMaximoXMLData(
					null,
					assetnum,
					assetNumArrayString,
					rig_code,
					"STN_PORTAL_WO",
					` and status !='CAN' and ISTASK='0' and TARGCOMPDATE !='' order by CASE WHEN wonum IN ${assetListString} THEN 1 ELSE 2 END, ACTFINISH asc, TARGCOMPDATE asc`,
					"25",
					abortControllerRef?.current?.signal
				).then((element: any) => {
					setMaximoWODataList(element?.WORKORDER || []);
				});
			}),
			getMaximoXMLData(
				null,
				assetnum,
				assetNumArrayString,
				rig_code,
				"STN_PORTAL_EFR",
				" order by ACTUALSTART DESC",
				null,
				abortControllerRef?.current?.signal
			).then((element: any) => {
				if (element?.INCIDENT?.length > 0) {
					let totalDowntime: any = 0;
					element?.INCIDENT.forEach((incident: any) => {
						if (incident?.STN_ESTDOWNTIME?.[0] != "") {
							totalDowntime += parseFloat(incident?.STN_ESTDOWNTIME?.[0]);
						}
					});
					var hours = Math.floor(totalDowntime);

					// Get the decimal part (minutes)
					var decimalPart = totalDowntime - hours;
					var minutes = Math.round(decimalPart * 60);

					// Format the string
					var result = hours + " h " + minutes + " min";
					setMaximoTotalIncidentDowntime(result);
				}
				setMaximoEFRData(element?.INCIDENT || []);
			}),

			getMaximoXMLData(
				null,
				assetnum,
				assetNumArrayString,
				rig_code,
				"STN_PORTAL_EFR",
				null,
				"25",
				abortControllerRef?.current?.signal
			).then((element: any) => {
				setMaximoEFRDataList(element?.INCIDENT || []);
			}),
			getMaximoXMLData(
				null,
				assetnum,
				assetNumArrayString,
				rig_code,
				"STN_PORTAL_MR",
				null,
				"25",
				abortControllerRef?.current?.signal
			).then((element: any) => {
				setMaximoModificationDataList(element?.PLUSGMOC || []);
			}),
		]).catch((error: any) => {
			console.log(error);
			if (error != "Canceled") {
				if (error.response) {
					setErrorAlertMessages(error.response);
				} else {
					setErrorAlertMessages(error);
				}
			}
		});
	};

	const resetMaximoStates = () => {
		setMaximoAssetData(null);
		setMaximoWOData(null);
		setMaximoWODataList(null);
		setMaximoWORecord(null);
		setMaximoEFRData(null);
		setMaximoEFRDataList(null);
		setMaximoWODuckList([]);
		setMaximoModificationDataList(null);
		setFilteredMaximoEFRDataList(null);
		setFilteredMaximoModificationDataList(null);
		setFilteredMaximoWODataList(null);
		setMaximoTotalIncidentDowntime(null);
		abortControllerRef?.current?.abort();
	};

	const changeCamera = (CameraNumber: string) => {
		scene.activeCamera.detachControl();
		if (CameraNumber != "2") {
			scene.activeCamera = cameraRef.current;
			scene.activeCamera.attachControl(canvas, false);
			smallBoxRef.current.setEnabled(true);
			setCameraMode("1");
		} else if (CameraNumber == "2") {
			scene.activeCamera = cameraArcRef.current;
			scene.activeCamera.autoRotationBehavior.idleRotationSpinupTime = 2000;
			scene.activeCamera.autoRotationBehavior.idleRotationWaitTime = 100;
			scene.activeCamera.autoRotationBehavior.idleRotationSpeed = 0.1;
			smallBoxRef.current.setEnabled(false);
			showPopover(null, false);
			removeHighlightedAsset();
			setCameraMode("2");
			// scene.activeCamera.attachControl(canvas, false, false, true);
		}

		// scene.activeCamera.position = position;

		if (CameraNumber == "2") {
			scene.activeCamera.setTarget(new BABYLON.Vector3(100, 40, 0));
			scene.activeCamera.rebuildAnglesAndRadius();
		}
		if (CameraNumber == "3") {
			oceanRef.current.isVisible = false;
			skyRef.current.isVisible = false;
		}

		if (Pointerlock.current) {
			document.exitPointerLock();
			Pointerlock.current = false;
		}
		activeCameraRef.current = scene.activeCamera;
	};

	const addCrosshair = (scene: any) => {
		let w = 128;

		let texture = new BABYLON.DynamicTexture("reticule", w, scene, false);
		texture.hasAlpha = true;

		let ctx = texture.getContext();
		let reticule;

		const createNavigate = () => {
			ctx.fillStyle = "transparent";
			ctx.clearRect(0, 0, w, w);
			ctx.strokeStyle = "rgba(170, 255, 0, 0.9)";
			ctx.lineWidth = 3.5;
			ctx.beginPath();
			ctx.arc(w * 0.5, w * 0.5, 20, 0, 2 * Math.PI);
			ctx.fillStyle = "rgba(0, 255, 0, 0.4)";
			ctx.fill();
			ctx.stroke();

			texture.update();
		};
		createNavigate();

		let material = new BABYLON.StandardMaterial("reticule", scene);
		material.diffuseTexture = texture;
		material.opacityTexture = texture;
		material.emissiveColor.set(1, 1, 1);
		material.disableLighting = true;

		let plane = BABYLON.MeshBuilder.CreatePlane(
			"reticule",
			{ size: 0.04 },
			scene
		);
		plane.material = material;
		plane.position.set(0, 0, 1.1);
		plane.isPickable = false;
		plane.rotation.z = Math.PI / 4;

		reticule = plane;
		reticule.parent = scene.activeCamera;
		reticule.isVisible = false;
		return reticule;
	};

	const onPointerLeftClick = (scene: any, pointerInfo: any) => {
		// console.log("Clicking");
		//V1 PointerClick
		var res: any = null;
		if (scene.activeCamera !== cameraArcRef.current) {
			if (Pointerlock.current) {
				var ray = scene.activeCamera.getForwardRay(200);
				res = scene.pickWithRay(ray);
			} else {
				res = scene.pick(scene.pointerX, scene.pointerY);
			}

			if (res.hit) {
				const regex = /\([^)]*\)/;

				// Replace the matched text with an empty string
				const resultString = res.pickedMesh.name.replace(regex, "");

				let assetnum = resultString.replace("P.", "").trim().split(/[-\s]/)[0];
				setSelectedKeys([assetnum]);
				setSelectValue(assetnum);

				if (
					highlightedAssetRef.current[0]?.name.includes(
						res.pickedMesh.name.split("_primitive")[0]
					)
				) {
					showPopover(null, false);
					if (level2Ref.current) {
						if (level3Ref.current != res.pickedMesh) {
							setRightDrawer(true);
							setMaximoLoading(true);
							setMaximoView(true);
							const node = flatAssetHierarchy.current.find(
								(asset: any) => asset.key === assetnum
							);
							if (node) {
								let assetnumList = getAllKeys(node, assetnum);
								getAssetData(assetnum, assetnumList);
							}
							engineerMesh.current.forEach((mesh: any) => {
								mesh.visibility = 0.03;
							});
							if (level3Ref.current) {
								level3Ref.current.renderOutline = false;
								highlightLayer.addExcludedMesh(level3Ref.current);
							}

							highlightedAssetRef.current = [];
							highlightLayer.removeMesh(res.pickedMesh);
							highlightLayer.addMesh(
								res.pickedMesh,
								BABYLON.Color3.FromHexString("#7DF9FF")
							);
							level3Ref.current = res.pickedMesh;
							highlightLayer.removeExcludedMesh(res.pickedMesh);
							res.pickedMesh.visibility = 1;
							// handleSearch(assetnum + "_Asset");
							setRightDrawerKey("Asset");
						}
					} else {
						setRightDrawerKey("Asset");
						LoadComponent(scene, resultString);
					}
				}
			}
		}

		//V2 PointerClick (set boxMesh collider to clicked position)
		// var res: any = null;
		// if (Pointerlock.current) {
		// 	var ray = scene.activeCamera.getForwardRay(200);
		// 	res = scene.pickWithRay(ray);
		// } else {
		// 	res = scene.pick(scene.pointerX, scene.pointerY);
		// }
		// console.log(pointerInfo.pickInfo?.pickedPoint);
		// if (pointerInfo.pickInfo?.pickedPoint)
		// 	boxRef.current.position = pointerInfo.pickInfo?.pickedPoint;
	};
	const removeHighlightedAsset = () => {
		if (highlightedAssetRef?.current?.length > 0) {
			highlightedAssetRef?.current.forEach((element: any) => {
				highlightLayer.addExcludedMesh(element);
				// element.renderOutline = false;
			});
			highlightedAssetRef.current = [];
		}
	};

	const highlightMesh = (scene: any) => {
		clearTimeout(pointerMoveTimerID);
		if (pointerClickRef.current) {
			pointerMoveTimerID = setTimeout(() => {
				// V1 Highlight mesh on pointerHover
				// Very expensive if there are a lot of pickables
				if (scene.activeCamera !== cameraArcRef.current) {
					var res: any = null;
					if (Pointerlock.current) {
						var ray = scene.activeCamera.getForwardRay(200);
						res = scene.pickWithRay(ray);
					} else {
						res = scene.pick(scene.pointerX, scene.pointerY);
					}

					if (res.hit) {
						res.pickedMesh.occlusionQueryAlgorithmType = undefined;
						res.pickedMesh.isOccluded = false;

						res.pickedMesh.occlusionType =
							BABYLON.AbstractMesh.OCCLUSION_TYPE_NONE;

						const real = res.pickedMesh.name.split("_primitive")[0];

						if (isFocusedRef.current) {
							if (
								!highlightedAssetRef.current[0]?.name.includes(
									res.pickedMesh
								) &&
								// res.pickedMesh.renderOutline != true &&
								level3Ref.current !== res.pickedMesh
							) {
								showPopover(res.pickedMesh.name, true);
								if (highlightedAssetRef?.current.length > 0) {
									removeHighlightedAsset();
								}
								highlightLayer.removeExcludedMesh(res.pickedMesh);
								highlightLayer.addMesh(
									res.pickedMesh,
									BABYLON.Color3.FromHexString("#7DF9FF")
								);
								// res.pickedMesh.renderOutline = true;

								highlightedAssetRef.current = [res.pickedMesh];
							}
						} else if (closeMeshRef.current.length > 0) {
							if (
								closeMeshRef.current.includes(res.pickedMesh) &&
								// res.pickedMesh.renderOutline != true &&
								!highlightedAssetRef.current[0]?.name.includes(res.pickedMesh)
							) {
								if (res.pickedMesh.name.includes("_primitive")) {
									showPopover(real, true);
									if (highlightedAssetRef?.current.length > 0) {
										removeHighlightedAsset();
									}

									let all = pickables.current.filter((element: any) => {
										return element.name.includes(real);
									});

									all.forEach((element: any) => {
										highlightLayer.removeExcludedMesh(element);
										highlightLayer.addMesh(
											element,
											BABYLON.Color3.FromHexString("#7DF9FF")
										);
										// element.renderOutline = true;
									});

									highlightedAssetRef.current = all;
								} else {
									showPopover(res.pickedMesh.name, true);
									if (highlightedAssetRef?.current.length > 0) {
										removeHighlightedAsset();
									}
									highlightLayer.removeExcludedMesh(res.pickedMesh);
									highlightLayer.addMesh(
										res.pickedMesh,
										BABYLON.Color3.FromHexString("#7DF9FF")
									);

									highlightedAssetRef.current = [res.pickedMesh];
								}
							} else if (!closeMeshRef.current.includes(res.pickedMesh)) {
								showPopover(null, false);
								removeHighlightedAsset();
							}
						} else {
							showPopover(null, false);
							removeHighlightedAsset();
						}
					} else {
						showPopover(null, false);
						removeHighlightedAsset();
					}
				}

				document.onmousemove = (e) => {
					const popover: any = document.querySelector(".BabylonPopOver");
					if (popover && Pointerlock.current != true) {
						if (canvasRef.current.width / 2 < e.pageX) {
							popoverPlacementRef.current = "left";
							popover.style.left = `${e.pageX - 100}px`;
						} else {
							popoverPlacementRef.current = "right";
							popover.style.left = `${e.pageX - 50}px`;
						}

						if (canvasRef.current.height / 2 < e.pageY) {
							popover.style.top = `${e.pageY - 130}px`;
						} else {
							popover.style.top = `${e.pageY - 65}px`;
						}
					} else {
						popoverPlacementRef.current = "right";
						popover.style.left = `calc(50% + 10px)`;
						popover.style.top = `calc(50%)`;
					}
				};
			}, 1);
		}
	};

	const onSceneReady = (sceneComponent: any) => {
		setModelLoadDesc("Setting up Cameras");
		let scene: any = null;
		let canvas: any = null;
		let engine: any = null;
		if (sceneComponent.scene) {
			setScene(sceneComponent.scene);
			setCanvas(sceneComponent.canvas);

			scene = sceneComponent.scene;
			canvas = sceneComponent.canvas;
			engine = scene.getEngine();

			engineRef.current = engine;
			sceneRef.current = scene;
			canvasRef.current = canvas;
		} else {
			setScene(sceneRef.current);
			setCanvas(canvasRef.current);
			scene = sceneRef.current;

			scene.getEngine().resize();
		}

		scene.getEngine().resize();
	};

	var animateBox = function (series: any, value: any, speed: number) {
		let temp: any = kognifai_data_series_animation[series];

		if (temp) {
			var animationGroup: any = new BABYLON.AnimationGroup(series);
			temp.forEach((meshElement: any) => {
				let mesh: any = null;

				let YPos = meshElement.minY + value / meshElement.denominator;

				var meshAnimation: any = new BABYLON.Animation(
					`${meshElement.objectName}`,
					"position.y",
					speed,
					BABYLON.Animation.ANIMATIONTYPE_FLOAT,
					BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
				);
				var meshKeys: any = [];
				if (meshElement.objectType === "pickable") {
					mesh = pickables.current.filter((element: any) =>
						element.name.includes(meshElement.objectName)
					);
				} else if (meshElement.objectType === "animation") {
					mesh = animateMesh.current.filter((element: any) =>
						element.name.includes(meshElement.objectName)
					);
				}

				meshKeys.push({ frame: 0, value: mesh[0].position.y });
				meshKeys.push({ frame: 100, value: YPos });
				meshAnimation.setKeys(meshKeys);

				mesh.forEach((element: any) => {
					animationGroup.addTargetedAnimation(meshAnimation, element);
				});
			});
			animationGroup.start();
			animationGroupRef.current.push(animationGroup);
		}

		// let TopDrive: any = pickables.current.find(
		// 	(element: any) =>
		// 		element.name === "P.313001 TOP DRIVE FOR MAIN CENTRE (BUILD).nwd"
		// );
		// let PipesMesh: any = animateMesh.current.filter((element: any) =>
		// 	element.name.includes("A.312025")
		// );
		// let SheaveCluster: any = pickables.current.find((element: any) =>
		// 	element.name.includes("P.312026 SHEAVE CLUSTER")
		// );
		// let scene = sceneRef.current;
		// if (TopDrive && PipesMesh.length > 0 && SheaveCluster) {
		// 	let randomNumber = Math.random();

		// 	// +2 because min value is -2, so starting position is technically + 2
		// 	const randomTopY = Math.floor(49 + 2 + value);
		// 	const randomPipeY = Math.floor(73.1 + 2 / 2 + value / 2);
		// 	const randomSheaveY = Math.floor(81 + 2 / 2 + value / 2);

		// 	console.log(randomTopY, randomPipeY, randomSheaveY);

		// 	const TopY = Math.floor(49 + value);
		// 	const PipeY = Math.floor(73.1 + value / 2);
		// 	const SheaveY = Math.floor(81 + value / 2);

		// 	var animationGroup: any = new BABYLON.AnimationGroup("myAnimationGroup");

		// 	// Create animations for each mesh
		// 	var mesh1Animation: any = new BABYLON.Animation(
		// 		"mesh1Animation",
		// 		"position.y",
		// 		30,
		// 		BABYLON.Animation.ANIMATIONTYPE_FLOAT,
		// 		BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
		// 	);
		// 	var mesh2Animation: any = new BABYLON.Animation(
		// 		"mesh2Animation",
		// 		"position.y",
		// 		30,
		// 		BABYLON.Animation.ANIMATIONTYPE_FLOAT,
		// 		BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
		// 	);
		// 	var mesh3Animation: any = new BABYLON.Animation(
		// 		"mesh3Animation",
		// 		"position.y",
		// 		30,
		// 		BABYLON.Animation.ANIMATIONTYPE_FLOAT,
		// 		BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
		// 	);
		// 	var mesh1Keys: any = [];
		// 	mesh1Keys.push({ frame: 0, value: TopDrive.position.y });
		// 	mesh1Keys.push({ frame: 100, value: randomTopY });
		// 	mesh1Animation.setKeys(mesh1Keys);

		// 	var mesh2Keys: any = [];
		// 	mesh2Keys.push({ frame: 0, value: PipesMesh[0].position.y });
		// 	mesh2Keys.push({ frame: 100, value: randomPipeY });
		// 	mesh2Animation.setKeys(mesh2Keys);

		// 	var mesh3Keys: any = [];
		// 	mesh3Keys.push({ frame: 0, value: SheaveCluster.position.y });
		// 	mesh3Keys.push({ frame: 100, value: randomSheaveY });
		// 	mesh3Animation.setKeys(mesh3Keys);

		// 	// Add animations to the Animation Group
		// 	animationGroup.addTargetedAnimation(mesh1Animation, TopDrive);
		// 	PipesMesh.forEach((pipe: any) => {
		// 		animationGroup.addTargetedAnimation(mesh2Animation, pipe);
		// 	});
		// 	animationGroup.addTargetedAnimation(mesh3Animation, SheaveCluster);

		// 	animationGroup.start();
		// 	animationGroupRef.current.push(animationGroup);
		// }
	};

	// Download and render the whole Vessel Model
	const loadModel = (scene: any, hl: any) => {
		return new Promise((resolve: any, reject: any) => {
			getVesselModel(props.params.mitem?.dt_data?.value).then((glb: any) => {
				const urlObject = URL.createObjectURL(glb);

				// console.log("GLB DOWNLOADED", glb);
				if (scene) {
					BABYLON.SceneLoader.ImportMesh(
						"",
						"",
						urlObject,
						scene,
						(mesh: any) => {
							mesh[0].isVisible = skipLoadingModel;
							let meshes: any = {};
							let highPolyMesh: any = [];
							let _pickables: any = [];
							let animateMeshes: any = [];
							let AllMesh: any = [];
							let allPipeMesh: any = [];

							// Add FullScreen UI component
							// var advancedTexture =
							// 	AdvancedDynamicTexture.CreateFullscreenUI("UI");
							// UITextureRef.current = advancedTexture;

							for (var i = 1; i < mesh.length; i++) {
								mesh[i].isVisible = skipLoadingModel;
								const id = mesh[i].id;
								meshes[id] = mesh[i];

								// mesh[i].occlusionType =
								// 	BABYLON.AbstractMesh.OCCLUSION_TYPE_OPTIMISTIC;

								// Set Pickable assets
								let meshName = mesh[i].name.split(") ")[1] || "";
								if (meshName.startsWith("P.")) {
									_pickables.push(mesh[i]);
									mesh[i].setParent(null);
									mesh[i].renderingGroupId = 2;

									// mesh[i].material.backFaceCulling = true;

									// Set animated assets
								} else if (meshName.includes("A.")) {
									animateMeshes.push(mesh[i]);
									mesh[i].renderingGroupId = 2;
									mesh[i].isPickable = false;
									// mesh[i].material.backFaceCulling = true;
									// animateMeshes.push(mesh[i]);
									// mesh[i].renderingGroupId = 1;
									// mesh[i].setParent(null);
									// mesh[i].material.backFaceCulling = true;

									// Set non-pickable assets
								} else {
									mesh[i].isPickable = false;
									mesh[i].doNotSyncBoundingInfo = false;
									mesh[i].visibility = 1;
									mesh[i].renderingGroupId = 1;
								}

								// EXPERIMENTS: Widget Addition + Click
								// if (mesh[i].name.includes("(313)")) {
								// 	// var advancedTexture = AdvancedDynamicTexture.CreateForMesh(
								// 	// 	mesh[i]
								// 	// );
								// 	const button = GUIButton.CreateImageOnlyButton(
								// 		`but${i}`,
								// 		Portal_Icon_Logo
								// 	);

								// 	button.width = "30px";
								// 	button.height = "30px";
								// 	button.color = "white";
								// 	button.background = "green";
								// 	button.cornerRadius = 20;
								// 	advancedTexture.addControl(button);

								// 	const line = new Line(`line${i}`);
								// 	line.lineWidth = 4;
								// 	line.color = "Blue";
								// 	line.y2 = 13;
								// 	// line.linkOffsetY = -20;
								// 	advancedTexture.addControl(line);

								// 	// var text1 = new TextBlock();
								// 	// text1.text = "Hello world";
								// 	// text1.color = "red";
								// 	// text1.fontSize = 24;
								// 	// advancedTexture.addControl(text1);
								// 	button.onPointerClickObservable.add(
								// 		(eventData, eventState) => {
								// 			const linkedMesh: any = button._linkedMesh;

								// 			// setOpenModal(true);
								// 			// setModalData({ eventData, eventState, mesh: linkedMesh });

								// 			// EXPERIMENTS: Adding overlay cards
								// 			let index = widgetRef.current.findIndex(
								// 				(item: any) => item?.id === linkedMesh?.id
								// 			);
								// 			if (index == -1) {
								// 				line.color = "Red";
								// 				button.background = "Red";
								// 				widgetRef.current = [
								// 					...widgetRef.current,
								// 					{
								// 						id: linkedMesh?.id,
								// 						name: linkedMesh?.name,
								// 						button: button,
								// 						line: line,
								// 						position: {
								// 							x: eventState.userInfo.localPosition.x + 30,
								// 							y: eventState.userInfo.localPosition.y - 30,
								// 						},
								// 					},
								// 				];

								// 				setWidgets(widgetRef.current);
								// 			} else {
								// 				let a = [...widgetRef.current];
								// 				line.color = "Blue";
								// 				button.background = "Green";
								// 				a.splice(index, 1);
								// 				widgetRef.current = a;
								// 				setWidgets(a);
								// 			}

								// 			// EXPERIMENTS: Adding 3D cards
								// 			// const htmlCanvas = document.createElement("canvas");
								// 			// htmlCanvas.width = 512;
								// 			// htmlCanvas.height = 512;
								// 			// const ctx: any = htmlCanvas.getContext("2d");
								// 			// // Draw HTML components (for example, text)
								// 			// ctx.fillStyle = "white";
								// 			// ctx.fillRect(0, 0, htmlCanvas.width, htmlCanvas.height);
								// 			// ctx.fillStyle = "black";
								// 			// ctx.font = "30px Arial";
								// 			// ctx.fillText("Hello, Babylon.js!", 50, 100);

								// 			// // Convert the HTML canvas to a texture
								// 			// const texture = new BABYLON.DynamicTexture(
								// 			// 	"dynamicTexture",
								// 			// 	htmlCanvas,
								// 			// 	scene
								// 			// 	// true
								// 			// );

								// 			// // Create a material using the texture
								// 			// const material = new BABYLON.StandardMaterial("abc", scene);
								// 			// material.diffuseTexture = texture;

								// 			// //Create dynamic texture
								// 			// var textureResolution = 512;
								// 			// var textureGround = new BABYLON.DynamicTexture(
								// 			// 	"dynamic texture",
								// 			// 	{ width: 512, height: 256 },
								// 			// 	scene
								// 			// );
								// 			// var textureContext = textureGround.getContext();

								// 			// var materialGround = new BABYLON.StandardMaterial(
								// 			// 	"Mat",
								// 			// 	scene
								// 			// );
								// 			// materialGround.diffuseTexture = textureGround;
								// 			// materialGround.opacityTexture = textureGround;
								// 			// materialGround.emissiveColor.set(1, 1, 1);
								// 			// materialGround.disableLighting = true;

								// 			// // Create a plane and apply the material
								// 			// const plane = BABYLON.MeshBuilder.CreatePlane(
								// 			// 	"TestPlane",
								// 			// 	{ size: 10 },
								// 			// 	scene
								// 			// );
								// 			// plane.material = materialGround;
								// 			// plane.position = linkedMesh.position; // position relative to the parent mesh
								// 			// plane.renderingGroupId = 3;
								// 			// plane.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;

								// 			// // Set the plane as a child of the parent mesh
								// 			// // plane.parent = linkedMesh;
								// 			// //Add text to dynamic texture
								// 			// var font = "bold 44px monospace";
								// 			// textureGround.drawText(
								// 			// 	"BabylonJS widget test",
								// 			// 	75,
								// 			// 	135,
								// 			// 	font,
								// 			// 	"green",
								// 			// 	"white",
								// 			// 	true,
								// 			// 	true
								// 			// );
								// 		}
								// 	);

								// 	button.linkWithMesh(mesh[i]);

								// 	button.linkOffsetY = -300;
								// 	line.linkWithMesh(mesh[i]);
								// 	line.connectedControl = button;
								// }

								// Change pipe assets Level of Detail based on distance
								if (meshName.includes("-pipes")) {
									mesh[i].addLODLevel(100, null);
									mesh[i].setEnabled(options.enablePipes);
									allPipeMesh.push(mesh[i]);
								}
								if (meshName.includes("P.")) {
									// mesh[i].addLODLevel(50, null);
									// mesh[i].setEnabled(options.enablePipes);
									// allPipeMesh.push(mesh[i]);
								}

								// Caculate poly count
								var vertexCount = mesh[i].getTotalVertices();
								var indexCount = mesh[i].getTotalIndices();
								var polygonCount = indexCount / 3;

								if (polygonCount > 1000000) {
									highPolyMesh.push(mesh[i]);
									//mesh[i].setEnabled(false);
								}

								// Create bounding box for hull
								if (mesh[i].name.includes("Hull_")) {
									mesh[i].renderingGroupId = 1;
									// Assuming you have already created a Babylon.js scene and a mesh named "myMesh"

									// Calculate the current bounding box of the mesh
									var boundingBox = mesh[i].getBoundingInfo().boundingBox;

									// Define the amount by which you want to reduce the bounding box vertically
									var reductionAmount = -3; // Adjust this value as needed

									// Calculate the new dimensions of the 5bounding box
									var newMinimum = boundingBox.minimum.clone();
									var newMaximum = boundingBox.maximum.clone();
									//console.log(boundingBox);
									newMaximum.y -=
										(boundingBox.maximum.y - boundingBox.minimum.y) *
										reductionAmount;

									// Extend the maxZ and minX values
									newMaximum.z +=
										((boundingBox.maximum.z - boundingBox.minimum.z) *
											reductionAmount) /
										1.5;
									newMinimum.x -=
										((boundingBox.maximum.x - boundingBox.minimum.x) *
											reductionAmount) /
										2.5;

									// Extend the minZ and maxX values
									newMinimum.z -=
										((boundingBox.maximum.z - boundingBox.minimum.z) *
											reductionAmount) /
										1.5;
									newMaximum.x +=
										((boundingBox.maximum.x - boundingBox.minimum.x) *
											reductionAmount) /
										2.5;

									// Update the mesh's bounding box
									var customBoundingBox = new BABYLON.BoundingBox(
										newMinimum, // Minimum point
										newMaximum, // Maximum point
										boundingBox._worldMatrix
									);

									hullRef.current.push(customBoundingBox);
								} else {
									// mesh[i].renderingGroupId = 2;
									// mesh[i].occlusionQueryAlgorithmType =
									// 	BABYLON.AbstractMesh.OCCLUSION_ALGORITHM_TYPE_CONSERVATIVE;
									// mesh[i].isOccluded = true;
									// mesh[i].occlusionType =
									// 	BABYLON.AbstractMesh.OCCLUSION_TYPE_STRICT;
								}

								if (hl) hl.addExcludedMesh(mesh[i]);
								AllMesh.push(mesh[i]);

								// After finishing rendering all meshes
								if (i == mesh.length - 1) {
									// var planeOpts = {
									// 	height: 5.4762,
									// 	width: 7.3967,
									// 	sideOrientation: BABYLON.Mesh.DOUBLESIDE,
									// };
									// var ANote0Video = BABYLON.MeshBuilder.CreatePlane(
									// 	"plane",
									// 	planeOpts,
									// 	scene
									// );
									// var vidPos = new BABYLON.Vector3(0, 0, 0.1);
									// ANote0Video.position = vidPos;
									// var ANote0VideoMat = new BABYLON.StandardMaterial("m", scene);
									// var ANote0VideoVidTex = new BABYLON.VideoTexture(
									// 	"vidtex",
									// 	Video,
									// 	scene
									// );
									// ANote0VideoVidTex.video.muted = true;

									// ANote0VideoMat.diffuseTexture = ANote0VideoVidTex;
									// ANote0VideoMat.roughness = 1;
									// ANote0VideoMat.emissiveColor = BABYLON.Color3.White();
									// ANote0Video.material = ANote0VideoMat;

									// const music = new BABYLON.Sound("music", Audio, scene, null, {
									// 	loop: true,
									// 	autoplay: true,
									// 	spatialSound: true,
									// 	distanceModel: "exponential",
									// 	rolloffFactor: 2,
									// 	maxDistance: 20,
									// });
									// music.attachToMesh(ANote0Video);

									// AllMesh.push(ANote0Video);

									pickables.current = _pickables;
									animateMesh.current = animateMeshes;
									AllMeshes.current = AllMesh;
									pipeMesh.current = allPipeMesh;
									highPolyMeshes.current = highPolyMesh;
									setHighlightLayer(hl);
									resolve(true);
								}
							}
						},
						(evt: any) => {
							var loadedPercent: any = 0;
							if (evt.lengthComputable) {
								loadedPercent = (evt.loaded * 100) / evt.total;
							} else {
								var dlCount = evt.loaded / (1024 * 1024);
								loadedPercent = Math.floor(dlCount * 100.0) / 100.0;
							}
							if (loadedPercent == 100) {
								setModelLoadDesc("Setting up the Digital Twin");
							}
							setModelLoaded((30 + (loadedPercent / 100) * 40).toFixed(2));
						},
						(scene: any, message: string, exception: any) => {
							// console.log(message);
						},
						".glb"
					);
				}
			});
		});
	};

	const addSkybox = (scene: any) => {
		return new Promise((resolve, reject) => {
			//V0.01
			// var skyEnv = new BABYLON.CubeTexture(TestEnv, scene);
			// skyEnv.rotationY = 9;
			// scene.environmentTexture = skyEnv;
			// scene.environmentIntensity = 2;
			// var skybox = BABYLON.Mesh.CreateBox("skyBox", 10000.0, scene);
			// skybox.position.y -= 150;
			// skybox.isPickable = false;
			// skybox.infiniteDistance = true;
			// var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
			// skyboxMaterial.backFaceCulling = false;
			// skyboxMaterial.disableLighting = true;
			// skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture(
			// 	"http://localhost:5000/api/models/skybox/d",
			// 	scene,
			// 	["_px.png", "_py.png", "_pz.png", "_nx.png", "_ny.png", "_nz.png"]
			// );
			// skyboxMaterial.reflectionTexture.coordinatesMode =
			// 	BABYLON.Texture.SKYBOX_MODE;
			// skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
			// skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
			// skybox.material = skyboxMaterial;
			// setSkyEnv(skyEnv);
			// LoadOcean(scene, skybox);
			// resolve(skybox);

			//V1
			// scene.clearColor = new BABYLON.Color3(1, 1, 1);
			var skyEnv = new BABYLON.CubeTexture(
				"https://dl.dropbox.com/s/nea7g9dywkpzkoc/sky.env",
				scene
			);
			skyEnv.rotationY = 2;
			scene.environmentTexture = skyEnv;
			scene.environmentIntensity = 5;
			var sphere = BABYLON.MeshBuilder.CreateSphere(
				"sphere",
				{ diameter: 5000, segments: 32 },
				scene
			);
			var sphereTexture = new BABYLON.Texture(Test, scene);
			sphereTexture.vScale = -1;
			var sphereMat = new BABYLON.StandardMaterial("sphereMat", scene);
			sphereMat.emissiveTexture = sphereTexture;
			sphereMat.backFaceCulling = false;
			sphere.material = sphereMat;
			sphere.rotation = new BABYLON.Vector3(0, 0.5, 0);
			sphere.position.y -= 40;
			sphere.isPickable = false;
			skyRef.current = sphere;
			setSkyInvisibility();
			resolve(sphere);

			//V2
			// const reflectionTexture = new BABYLON.HDRCubeTexture(
			// 	"https://www.babylonjs-playground.com/textures/environment.hdr",
			// 	scene,
			// 	128,
			// 	false,
			// 	true,
			// 	false,
			// 	true
			// );
			// var hdrTexture = new BABYLON.CubeTexture(
			// 	"https://www.babylonjs-playground.com/textures/environment.hdr",
			// 	scene
			// );
			// var envTexture = new BABYLON.CubeTexture(
			// 	"https://www.babylonjs-playground.com/textures/environment.env",
			// 	scene
			// );
			// scene.environmentTexture = envTexture;
			// scene.environmentIntensity = 2;
			// var skyboxMaterial = new BABYLON.PBRMaterial("skyboxMaterial", scene);
			// skyboxMaterial.reflectionTexture = reflectionTexture;
			// skyboxMaterial.emissiveColor = new BABYLON.Color3(0, 0, 1);
			// skyboxMaterial.backFaceCulling = false;
			// skyboxMaterial.microSurface = 1.0; // Adjust as needed
			// skyboxMaterial.cameraExposure = 1.0; // Adjust as needed
			// var skybox = BABYLON.MeshBuilder.CreateSphere(
			// 	"skybox",
			// 	{ diameter: 1000 },
			// 	scene
			// );
			// skybox.material = skyboxMaterial;
			// skybox.infiniteDistance = false;
			// LoadOcean(scene, skybox);
			// resolve(skybox);
		});
	};

	const LoadOcean = (scene: any, skybox: any) => {
		return new Promise(async (resolve, reject) => {
			//V0.01
			// var waterPlane: any = null;
			// waterPlane = BABYLON.MeshBuilder.CreateGround(
			// 	"ground",
			// 	{ width: 1048, height: 1048, subdivisions: 58 },
			// 	scene.scene
			// ); // ground.scaling = new BABYLON.Vector3(100, 100, 100);
			// waterPlane.rotation.y = Math.PI;
			// waterPlane.renderingGroupId = 2;
			// waterPlane.position.y -= -4.0;
			// waterPlane.position.x += 115;
			// waterPlane.isPickable = false;
			// var water = new BABYLONMAT.WaterMaterial(
			// 	"water",
			// 	scene,
			// 	new BABYLON.Vector2(512, 512)
			// );
			// water.backFaceCulling = true;
			// water.bumpTexture = new BABYLON.Texture(
			// 	"https://www.babylonjs-playground.com/textures/rockn.png",
			// 	scene
			// );
			// water.windForce = 5;
			// water.waveHeight = 0.4;
			// water.bumpHeight = 0.1;
			// water.waveSpeed = 0; // water.waveLength = 50;
			// water.windDirection = new BABYLON.Vector2(-1, 0);
			// water.waterColor = new BABYLON.Color3(0.1, 0.09, 0.22);
			// water.colorBlendFactor = 0.5;
			// water.addToRenderList(skybox);
			// // water.infiniteDistance = 100; // Adjust as needed
			// // water.tilingY = 100; // Adjust as needed
			// waterPlane.material = water;

			//V1
			var waterPlane: any = BABYLON.MeshBuilder.CreateGround(
				"waterplane",
				{ width: 5000, height: 5000 },
				scene
			);
			waterPlane.position.y = 9;
			var waterNormalMap = new BABYLON.Texture(
				"https://dl.dropbox.com/s/76obsake8lydcap/water_N.png",
				scene
			);
			waterNormalMap.vScale = -31;
			waterNormalMap.uScale = 30;
			// var waterOpacity = new BABYLON.Texture(a, scene);
			var waterOpacity = new BABYLON.Texture(
				"https://dl.dropbox.com/s/ztuvq7fnftkh8eh/waterOpacity.jpg",
				scene
			);

			var waterMat: any = await BABYLON.NodeMaterial.ParseFromFileAsync(
				"waterMat",
				"https://dl.dropbox.com/s/kr5lnxja8p63zlf/basicWater.json",
				scene
			);
			var waterNormal = waterMat.getBlockByName("waterNormal");
			waterNormal.texture = waterNormalMap;
			var opacityMap = waterMat.getBlockByName("opacityMap");
			opacityMap.texture = waterOpacity;
			waterPlane.material = waterMat;
			var waterNormalStrength = waterMat.getBlockByName("normalStrength");
			waterNormalStrength.value = 0.03;
			var waterSpeedX = waterMat.getBlockByName("SpeedX");
			waterSpeedX.value = 0.0014;
			var waterSpeedY = waterMat.getBlockByName("SpeedY");
			waterSpeedY.value = 0.0022;
			var waterSpeed2X = waterMat.getBlockByName("Speed2X");
			waterSpeed2X.value = -0.0016;
			var waterSpeed2Y = waterMat.getBlockByName("Speed2Y");
			waterSpeed2Y.value = -0.0062;
			var waterColor = waterMat.getBlockByName("baseColor");
			waterColor.value = BABYLON.Color3.FromHexString("#000000");
			var Roughness = waterMat.getBlockByName("Roughness");
			Roughness.value = 0.069;
			var metallic = waterMat.getBlockByName("metallic");
			metallic.value = 0.074;
			var normalBlend = waterMat.getBlockByName("NormalBlend");
			normalBlend.value = 0.5;

			waterPlane.renderingGroupId = 2;
			waterPlane.isPickable = false;

			setSeaState({
				strength: waterNormalStrength,
				speedX: waterSpeedX,
				speedY: waterSpeedY,
				speed2X: waterSpeed2X,
				speed2Y: waterSpeed2Y,
				metallic: metallic,
				roughness: Roughness,
				color: waterColor,
				blend: normalBlend,
				waterNormalMap: waterNormalMap,
			});

			oceanRef.current = waterPlane;
			setOceanInvisibility();
			// engineRef.current.hideLoadingUI();
			resolve(waterPlane);
		});
	};

	const LoadComponent = (
		scene: any,
		pickable: any,
		engineerKey: any = null
	) => {
		showPopover(null, false);
		downloadingRef.current = true;
		Inspector.Hide();
		setAssetLoaded(false);
		setModelLoadDesc("Downloading Asset");
		setModelLoaded(0);
		getEngineeringAsset(pickable).then((glb: any) => {
			const urlObject = URL.createObjectURL(glb);
			// console.log(glb);
			BABYLON.SceneLoader.ImportMesh(
				"",
				"",
				urlObject,
				scene,
				(mesh: any) => {
					// console.log(mesh);
					let components: any = [];
					mesh[0].isVisible = false;

					var boundingBox: any = new BABYLON.BoundingBox(
						BABYLON.Vector3.Zero(),
						BABYLON.Vector3.Zero()
					);
					let assetnum = pickable.replace("P.", "").trim().split(/[-\s]/)[0];
					for (var i = 1; i < mesh.length; i++) {
						var meshtest: any = mesh[i];
						meshtest.computeWorldMatrix(true);
						var boundingInfo: any = meshtest.getBoundingInfo();
						//	console.log(boundingInfo);
						boundingBox.reConstruct(
							boundingInfo.boundingBox.minimumWorld,
							boundingInfo.boundingBox.maximumWorld
						);

						components.push(mesh[i]);

						OpenCloseLeftDrawer(true);
						setMenuKey("Asset");
						if (mesh[i].name.startsWith("P.")) {
							const element_assetnum = mesh[i].name
								.replace("P.", "")
								.trim()
								.split(/[-\s]/)[0];
							mesh[i].isPickable =
								(element_assetnum.length > 6 &&
									assetnum.length < 7 &&
									!isNaN(parseInt(element_assetnum))) ||
								isNaN(parseInt(element_assetnum));
							mesh[i].renderingGroupId = 1;
						} else {
							mesh[i].isPickable = false;
							mesh[i].doNotSyncBoundingInfo = true;
							mesh[i].visibility = 1;
							mesh[i].renderingGroupId = 2;
						}

						highlightLayer.addExcludedMesh(mesh[i]);

						if (i == mesh.length - 1) {
							engineerMesh.current.push(...components);

							AllMeshes.current.map((element: any) => {
								element.setEnabled(false);
							});
							let target = scene.activeCamera.target.clone();
							let position = scene.activeCamera.position.clone();

							// if (!isFocusedRef.current) {
							previousPosition.current.target = target.clone();
							previousPosition.current.position = position.clone();
							// }

							// Component Arc Camera
							scene.activeCamera.detachControl();
							scene.activeCamera = cameraComRef.current;
							scene.activeCamera.position = BABYLON.Vector3.Zero();
							scene.activeCamera.radius = 30;
							// console.log(scene.activeCamera.radius);
							scene.activeCamera.attachControl(canvas, false);

							// if (fromtree) {
							// 	const boundingInfo = pickedmesh.getBoundingInfo();
							// 	const boundingBox = boundingInfo.boundingBox;
							// 	const min = boundingBox.minimum;
							// 	const max = boundingBox.maximum;

							// 	// Calculate the center position of the bounding box
							// 	const center = BABYLON.Vector3.Center(min, max);

							// 	// Calculate the distance from the center to the farthest point
							// 	const distance = BABYLON.Vector3.Distance(center, max);

							// 	// Calculate the frustum dimensions based on the distance

							// 	// Set the camera position and adjust target and rotation as needed
							// 	let position = new BABYLON.Vector3(
							// 		meshPosition.x + (distance > 500 ? 20 : distance / 50),
							// 		meshPosition.y,
							// 		meshPosition.z + (distance > 500 ? 20 : distance / 50)
							// 	);
							// 	scene.activeCamera.position = position.clone();

							// } else {
							// 	scene.activeCamera.position = position.clone();
							// }

							// scene.activeCamera.target = meshPosition.clone();
							// Calculate the center point of the bounding box
							var center = boundingBox.center;

							// Set the camera target to the center point
							scene.activeCamera.setTarget(center);

							// Optionally, you can also position the camera to get a better view
							var distance: any = BABYLON.Vector3.Distance(
								center,
								scene.activeCamera.position
							);
							scene.activeCamera.position = center.add(
								new BABYLON.Vector3(distance / 3, 0, distance / 3)
							);

							// scene.activeCamera.setTarget(mesh[i]);
							// scene.activeCamera.radius = 30;
							// console.log(scene.activeCamera.position);
							// console.log(scene.activeCamera.target);
							// console.log(mesh[i].position);

							//ArcCamera

							// scene.activeCamera.rebuildAnglesAndRadius();
							level2Ref.current = pickable;

							//ArcCamera
							Pointerlock.current = false;
							oceanRef.current.isVisible = false;
							skyRef.current.isVisible = false;
							isFocusedRef.current = true;
							setRightDrawer(true);
							setMaximoLoading(true);
							setMaximoView(true);
							setAssetLoaded(true);
							downloadingRef.current = false;
							// handleSearch(assetnum + "_Asset");
							if (engineerKey) {
								const node = flatAssetHierarchy.current.find(
									(asset: any) => asset.key === engineerKey
								);
								if (node) {
									let assetnumList = getAllKeys(node, engineerKey);
									getAssetData(engineerKey, assetnumList);
								}
								setTempAssetKey(null);

								let mesh = components.find((element: any) => {
									return element.name.includes(engineerKey);
								});

								if (mesh) {
									engineerMesh.current.forEach((mesh: any) => {
										mesh.visibility = 0.03;
									});
									highlightedAssetRef.current = [];
									highlightLayer.removeMesh(mesh);
									highlightLayer.addMesh(
										mesh,
										BABYLON.Color3.FromHexString("#7DF9FF")
									);
									level3Ref.current = mesh;
									highlightLayer.removeExcludedMesh(mesh);
									mesh.visibility = 1;
								}
							} else {
								setSelectedKeys([assetnum]);
								const node = flatAssetHierarchy.current.find(
									(asset: any) => asset.key === assetnum
								);
								if (node) {
									let assetnumList = getAllKeys(node, assetnum);
									getAssetData(assetnum, assetnumList);
								}
							}

							//ArcCamera
							document.exitPointerLock();
						}
					}
				},
				(evt: any) => {
					var loadedPercent: any = 0;
					if (evt.lengthComputable) {
						loadedPercent = (evt.loaded * 100) / evt.total;
					} else {
						var dlCount = evt.loaded / (1024 * 1024);
						loadedPercent = Math.floor(dlCount * 100.0) / 100.0;
					}
					if (loadedPercent == 100) {
						setModelLoadDesc("Finish Download");
					}
					setModelLoaded((30 + (loadedPercent / 100) * 40).toFixed(2));
				},
				() => {},
				".glb"
			);
		});
	};

	const assetMissingModal = () => {
		return (
			<Modal
				width={425}
				wrapClassName="modal-wrap-containerless"
				maskClosable={false}
				mask={false}
				open={FSModal}
				closable={false}
				style={{
					position: "fixed",
					left: "500px",
					top: "calc(50% - 260px/2)",
				}}
				footer={null}
			>
				<Space direction="horizontal" align="start">
					<div
						style={{
							color: "#177DDC",
							fontSize: "24px",
							width: "30px",
							marginTop: "-7px",
						}}
					>
						{GetAntIcon("exclamation")}
					</div>

					<Space direction="vertical" size={8}>
						<div
							style={{
								fontWeight: "500",
								fontSize: "16px",
								lineHeight: "24px",
							}}
						>
							Asset Missing
						</div>
						<div
							style={{
								fontWeight: "400",
								fontSize: "14px",
								lineHeight: "22px",
								marginBottom: "30px",
								color: "#FFFFFFA6",
							}}
						>
							This asset is currently unavailable.
							<br></br>
							<br />
							If you'd like to add it, please submit a request to the
							development team by clicking the 'Request Asset' button below. We
							will get back to you shortly.
						</div>
						<Space
							style={{ justifyContent: "end", display: "flex" }}
							direction="horizontal"
						>
							<Button
								onClick={() => {
									setFSModal(false);
									canvasRef.current.focus();
								}}
							>
								Close
							</Button>
							<Button
								type="primary"
								onClick={() => {
									setFSModal(false);
									ContextConsumer.handleUSModal({
										component: "Digital Twin",
										message: FSMessage,
									});
								}}
							>
								Request asset
							</Button>
						</Space>
					</Space>
				</Space>
			</Modal>
		);
	};

	return (
		<div
			style={{
				position: "relative",
				width: "100%",
				height: "100%",
				overflow: "hidden",
			}}
		>
			<ComponentHook menuProps={props.params.mitem} />
			<Spin
				// style={{ color: "rgb(77, 77, 77)" }}
				size="large"
				tip={
					<div
						style={{
							color: "white",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<span style={{ fontSize: "30px" }}>{ModelLoadDesc}</span>
						<Progress style={{ width: "50%" }} percent={ModelLoadPercent} />
					</div>
				}
				indicator={<img alt="Portal logo" src={Portal_Icon_Logo} />}
				className={"antd-spin-blink"}
				wrapperClassName={"dt2-loading-wrapper"}
				spinning={!(isLoaded && isAssetLoaded)}
			>
				<Menu
					className="dt-side-menu"
					rootClassName="dt-root-side-menu"
					mode="inline"
					theme="dark"
					onClick={(e: any) => {
						if (e.key === "Camera") {
							if (cameraMode === "1") {
								changeCamera("2");
							} else {
								changeCamera("1");
							}
						} else {
							if (
								rightDrawerKeyRef.current === e.key ||
								["Help", "Settings"].includes(e.key)
							) {
							} else {
								setRightDrawer(false);
								setSelectedKeys([]);
								if (engineerMesh.current.length > 0) {
									engineerMesh.current.forEach((element: any) => {
										highlightLayer.removeExcludedMesh(element);
										highlightLayer.removeMesh(element);
										element.dispose();
									});
									engineerMesh.current = [];
								}
								resetMaximoStates();
								AllMeshes.current.forEach((element: any) => {
									element.setEnabled(true);
								});

								if (level2Ref.current || level3Ref.current) {
									//console.log(previousPosition.current);
									scene.activeCamera.detachControl();

									scene.activeCamera = activeCameraRef.current;
									scene.activeCamera.attachControl(canvas, false);

									scene.activeCamera.position =
										previousPosition?.current?.position;
									scene.activeCamera.target = previousPosition?.current?.target;
								}

								level3Ref.current = null;
								level2Ref.current = null;
								level1Ref.current = null;
								resetMaximoStates();
								isFocusedRef.current = false;
								oceanRef.current.isVisible = true;
								skyRef.current.isVisible = true;
							}
							if (e.key !== menuKey) {
								if (isSearch) {
									setTimeout(() => {
										setIsSearch(false);
										setSearchResults([]);
										setSearchValue("");
										setExpandedKeys([]);
									}, 100);
								}

								if (e.key !== "Inspector") {
									mqttRef.current.key = e.key;
									menuKeyRef.current = e.key;
									Inspector.Hide();

									isInspectorOpenRef.current = false;
									if (leftDrawer) {
										OpenCloseLeftDrawer(false);
										setTimeout(() => {
											setMenuKey(e.key);
											OpenCloseLeftDrawer(true);
										}, 100);
									} else {
										setMenuKey(e.key);
										OpenCloseLeftDrawer(true);
									}
								} else {
									OpenCloseLeftDrawer(false);
									setMenuKey(e.key);
									Inspector.Show(sceneRef.current, {
										embedMode: true,
										enableClose: true,
										enablePopup: false,
									});
									isInspectorOpenRef.current = true;
								}
							} else {
								OpenCloseLeftDrawer(false);
								Inspector.Hide();
								isInspectorOpenRef.current = false;
								setTimeout(() => {
									menuKeyRef.current = "";
									setMenuKey(null);
									setIsSearch(false);
									setSearchResults([]);
									setSearchValue("");
									setExpandedKeys([]);
								}, 100);
							}
						}

						canvasRef.current.focus();
					}}
					selectedKeys={menuKey}
					items={
						props?.role.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)
							? [
									{
										label: "Asset",
										key: "Asset",
										icon: GetAntIcon("expand"),
									},
									{ label: "Crew", key: "Crew", icon: GetAntIcon("team") },
									{
										label: "Settings",
										key: "Settings",
										icon: GetAntIcon("setting"),
										style: { marginTop: "auto" },
									},
									{
										label: "Help",
										key: "Help",
										icon: GetAntIcon("question3"),
										// style: { marginTop: "auto" },
									},
							  ]
							: [
									{
										label: "Asset",
										key: "Asset",
										icon: GetAntIcon("expand"),
									},
									{ label: "Crew", key: "Crew", icon: GetAntIcon("team") },
									{
										label: "Settings",
										key: "Settings",
										icon: GetAntIcon("setting"),
										style: { marginTop: "auto" },
									},
									{
										label: "Help",
										key: "Help",
										icon: GetAntIcon("question3"),
										// style: { marginTop: "auto" },
									},
							  ]
					}
				/>
				<div
					style={{
						width: "100%",
						height: "100%",
						position: "relative",
						overflow: "hidden",
					}}
				>
					{graphicalEngine === "WebGPU" ? (
						<canvas
							ref={canvasRef}
							style={{ width: "100%", height: "100%", display: "block" }}
						/>
					) : (
						<Engine antialias adaptToDeviceRatio canvasId="DigitalTwin">
							<Scene onSceneMount={onSceneReady}>
								<universalCamera
									name="UniCamera"
									ref={cameraRef}
									target={defaultTarget}
									position={cloned}
									//Lowering Inertia can make the camera to jump a few meters
									inertia={0}
									speed={cameraSpeed}
									angularSensibility={1000}
									minZ={0.3}
									fov={cameraFov}
								/>
								<arcRotateCamera
									name="RotateCamera"
									useAutoRotationBehavior={true}
									ref={cameraArcRef}
									target={new BABYLON.Vector3(98.92, 46.94, 282.67)}
									alpha={Math.PI / 4}
									beta={Math.PI / 4}
									radius={10}
									upperRadiusLimit={300}
									lowerRadiusLimit={300}
									minZ={0.5}
									fov={0.5}
								/>
								<arcRotateCamera
									name="RotateComponentCamera"
									ref={cameraComRef}
									target={new BABYLON.Vector3(0, 0, 0)}
									alpha={Math.PI / 4}
									beta={Math.PI / 4}
									radius={10}
									upperRadiusLimit={300}
									lowerRadiusLimit={1}
									minZ={0.1}
								/>
							</Scene>
						</Engine>
					)}
					<DigitalTwinLeftDrawer
						scene={scene}
						menuKey={menuKey}
						setFPS={setFPS}
						FPS={FPS}
						setCameraFov={setCameraFov}
						cameraFov={cameraFov}
						setCameraSpeed={setCameraSpeed}
						cameraSpeed={cameraSpeed}
						setCameraPosition={setCameraPosition}
						cameraPosition={cameraPosition}
						setCameraTarget={setCameraTarget}
						cameraTarget={cameraTarget}
						cameraRef={cameraRef}
						leftDrawer={leftDrawer}
						isSearch={isSearch}
						searchResults={searchResults}
						expandedKeys={expandedKeys}
						selectedKeys={selectedKeys}
						selectValue={selectValue}
						mqttList={mqttList}
						options={options}
						cameraMode={cameraMode}
						assetTreedata={assetTreedata}
						assetTreeRef={assetTreeRef}
						handleSearch={handleSearch}
						removeHighlightedAsset={removeHighlightedAsset}
						AllMeshes={AllMeshes}
						highlightedAssetRef={highlightedAssetRef}
						highlightLayer={highlightLayer}
						pickables={pickables}
						engineerMesh={engineerMesh}
						level1Ref={level1Ref}
						level2Ref={level2Ref}
						level3Ref={level3Ref}
						canvasRef={canvasRef}
						setExpandedKeys={setExpandedKeys}
						setSelectedKeys={setSelectedKeys}
						setSelectValue={setSelectValue}
						setRightDrawerKey={setRightDrawerKey}
						flatAssetHierarchy={flatAssetHierarchy}
						getAllKeys={getAllKeys}
						setCurrentMaximoKey={setCurrentMaximoKey}
						setCurrentMaximoKeyList={setCurrentMaximoKeyList}
						setFSModal={setFSModal}
						resetMaximoStates={resetMaximoStates}
						setRightDrawer={setRightDrawer}
						rightDrawerKeyRef={rightDrawerKeyRef}
						setFSMessage={setFSMessage}
						oceanRef={oceanRef}
						skyRef={skyRef}
						getAssetData={getAssetData}
						setMaximoLoading={setMaximoLoading}
						setMaximoView={setMaximoView}
						isFocusedRef={isFocusedRef}
						abortControllerRef={abortControllerRef}
						activeCameraRef={activeCameraRef}
						previousPosition={previousPosition}
						canvas={canvas}
						setSearchValue={setSearchValue}
						setSearchResults={setSearchResults}
						setIsSearch={setIsSearch}
						focusMesh={focusMesh}
						LoadComponent={LoadComponent}
						pobTreeData={pobTreeData}
						pobTreeRef={pobTreeRef}
						setRightDrawerData={setRightDrawerData}
						lowFPSCounter={lowFPSCounter}
						setOptions={setOptions}
						changeCamera={changeCamera}
						OpenCloseLeftDrawer={OpenCloseLeftDrawer}
						Inspector={Inspector}
						isInspectorOpenRef={isInspectorOpenRef}
						setMenuKey={setMenuKey}
						menuKeyRef={menuKeyRef}
					/>
					<DigitalTwinRightDrawer
						canvasRef={canvasRef}
						setSelectedKeys={setSelectedKeys}
						setMaximoWORecord={setMaximoWORecord}
						setMaximoEFRRecord={setMaximoEFRRecord}
						setMaximoModificationsRecord={setMaximoModificationsRecord}
						isMaximoView={isMaximoView}
						rightDrawer={rightDrawer}
						rightDrawerTabKey={rightDrawerTabKey}
						rightDrawerKey={rightDrawerKey}
						rightDrawerData={rightDrawerData}
						setRightDrawer={setRightDrawer}
						setRightDrawerData={setRightDrawerData}
						setRightDrawerTabKey={setRightDrawerTabKey}
						setRightDrawerKey={setRightDrawerKey}
						setMaximoView={setMaximoView}
						maximoAssetData={maximoAssetData}
						maximoWOData={maximoWOData}
						maximoEFRData={maximoEFRData}
						maximoTotalIncidentDowntime={maximoTotalIncidentDowntime}
						maximoWODataList={maximoWODataList}
						isMaximoWOLoading={isMaximoWOLoading}
						maximoWODuckList={maximoWODuckList}
						setFilteredMaximoWODataList={setFilteredMaximoWODataList}
						filteredMaximoWODataList={filteredMaximoWODataList}
						setMaximoWOLoading={setMaximoWOLoading}
						getMaximoXMLData={getMaximoXMLData}
						currentMaximoKeyList={currentMaximoKeyList}
						currentMaximoKey={currentMaximoKey}
						rig_code={rig_code}
						abortControllerRef={abortControllerRef}
						rightDrawerKeyRef={rightDrawerKeyRef}
						setMaximoWODataList={setMaximoWODataList}
						maximoEFRDataList={maximoEFRDataList}
						isMaximoEFRLoading={isMaximoEFRLoading}
						setFilteredMaximoEFRDataList={setFilteredMaximoEFRDataList}
						filteredMaximoEFRDataList={filteredMaximoEFRDataList}
						setMaximoEFRLoading={setMaximoEFRLoading}
						maximoModificationDataList={maximoModificationDataList}
						setMaximoModificationDataList={setMaximoModificationDataList}
						isMaximoModificationsLoading={isMaximoModificationsLoading}
						setFilteredMaximoModificationDataList={
							setFilteredMaximoModificationDataList
						}
						filteredMaximoModificationDataList={
							filteredMaximoModificationDataList
						}
						setMaximoModificationsLoading={setMaximoModificationsLoading}
						maximoWORecord={maximoWORecord}
						maximoEFRRecord={maximoEFRRecord}
						maximoModificationsRecord={maximoModificationsRecord}
					/>
					<TutorialModal
						tutorial={tutorial}
						noShow={noShow}
						setNoShow={setNoShow}
						setTutorialModal={setTutorialModal}
						menuKeyRef={menuKeyRef}
						setMenuKey={setMenuKey}
						OpenCloseLeftDrawer={OpenCloseLeftDrawer}
						canvasRef={canvasRef}
						setTutorial={setTutorial}
						tutorialModal={tutorialModal}
					/>
					{assetMissingModal()}

					<Popover
						placement={popoverPlacementRef.current}
						className="BabylonPopOver"
						open={popoverVisible && isAssetLoaded}
						arrow={false}
						content={popoverContent}
					/>
				</div>
			</Spin>
		</div>
	);
};

export default DigitalTwin;
