import { Tenant } from "./_exports";
import { VisualGroupType } from "../../types/VisualGroup";
import { api } from "../../contexts/AuthContext";
import store from "../../state/store";

export const listVisualGroups = () => {
	return new Promise((resolve, reject) => {
		api.get(`/visualgroups/listVisualGroups`, {
			params: { tenantid: Tenant },
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const saveVisualGroup = async (
	visualGroup: Partial<VisualGroupType>
) => {
	const { user } = store.getState();
	const { menuKey } = visualGroup;
	try {
		visualGroup.updatedBy = user?.name || "";

		const response = await api.post(`/visualgroups/saveVisualGroup`, {
			visualGroup,
			tenantid: Tenant,
			menuKey,
		});
		return response;
	} catch (err) {
		throw err;
	}
};

export const deleteVisualGroup = async (visualGroup: VisualGroupType) => {
	const { menuKey, id } = visualGroup;

	try {
		const response = await api.delete(`/visualgroups/deleteVisualGroup`, {
			data: {
				id,
				menuKey,
				tenantid: Tenant,
			},
		});

		return response;
	} catch (err) {
		throw err;
	}
};

export const updateVisualGroup = (visualGroup: VisualGroupType) => {
	const { menuKey } = visualGroup;
	const endpoint = `/visualgroups/updateVisualGroup`;
	return new Promise((resolve, reject) => {
		api.post(endpoint, { visualGroup, tenantid: Tenant, menuKey })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
