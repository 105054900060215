import React from "react";
import {
	MapContainer,
	LayersControl,
	TileLayer,
	Marker,
	Popup,
	useMap,
	Tooltip,
} from "react-leaflet";
import * as L from "leaflet";
import VesselStatus from "./VesselStatus";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { getRigObject } from "../services/RigInfo";
import { connect } from "react-redux";
import Emitter from "../services/EventEmitter";
import { Button, Divider, Drawer, Image, Modal, Space } from "antd";
import { GetAntIcon } from "../utils/ant_icons";
import { setDrawerlogo, setHomeLogo } from "../utils/company_logo";
import "../assets/css/Vessel.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import { SettingOutlined } from "@ant-design/icons";
import map_pin from "../assets/img/map_pin.png";
import map_pin_gray from "../assets/img/map_pin_gray.png";

const { BaseLayer } = LayersControl;

const LeafIcon: any = L.Icon.extend({
	options: {},
});

const blueIcon = new LeafIcon({
	iconUrl: map_pin,
	iconSize: [30, 30],
	iconAnchor: [16, 30],
});

const greyIcon = new LeafIcon({
	iconUrl: map_pin_gray,
	iconSize: [30, 30],
	iconAnchor: [16, 30],
});

const zoomCoords: any = {
	0: 0,
	2: 70,
	3: 35,
	4: 25,
	5: 10,
	6: 5,
	7: 3,
	8: 1,
};

// const createClusterCustomIcon = (cluster: any) => {
// 	return L.divIcon({
// 		html: `<span>${cluster.getChildCount()}</span>`,
// 		className: "custom-marker-cluster",
// 		iconSize: [20, 30],
// 		iconAnchor: [10, 30],
// 	});
// };

const customClusterIcon = (cluster: any) => {
	return L.divIcon({
		html: `<div><div class='customClusterIconBackground'><span>${cluster.getChildCount()}</span></div></div>`,
		className: "customClusterIcon",
		iconSize: [50, 30],
		iconAnchor: [10, 35],
	});
};

class VesselMap extends React.Component<any> {
	keys = "AIzaSyACV-NLNpo-vnrfo-SjBYLSXUEqfGoZhFo";
	props: any = {
		RigInfo: null,
		selectedVessel: "",
		Zoom: 10,
	};
	state: any = {
		zoom: 10,
		clean: null,
		all_markers: {},
		marker: false,
	};

	timer: any;

	markerRefs: any = {};

	constructor(props: any) {
		super(props);
		this.props = props;
		this.addMarkers = this.addMarkers.bind(this);
	}

	componentDidMount() {
		this.props.dispatch({
			type: "SELECT_VESSEL",
			payload: this.props.selectedVessel,
		});
		if (this.props.selectedVessel !== null) {
			this.setState({ vesselSelected: true });
		}
		Emitter.on("updatelocation", (payload: any) => {
			// console.log("VesselLocation With Payload", payload)
			payload.forEach((element: any) => {
				if (this.markerRefs[element.name]) {
					this.markerRefs[element.name].setLatLng([
						element.ais_data.ais_location.lat,
						element.ais_data.ais_location.lng,
					]);
				} else {
					// console.log(element.name, "is not found");
				}
			});
		});
		//console.log('selectedVessel',this.props.selectedVessel)
	}

	componentWillUnmount() {
		Emitter.off("updatelocation", null);
		// clearInterval(this.timer)
	}

	shouldComponentUpdate(nextProps: any, nextState: any) {
		if (nextProps.ismobile != this.props.ismobile) {
			return true;
		}
		if (this.state.marker != true) {
			return true;
		}
		if (this.state.clean && !this.state.clean_map) {
			this.setState({ clean_map: true });
			return true;
		} else if (this.props.selectedVessel !== nextProps.selectedVessel) {
			return true;
		} else if (this.props.selectedVessel !== null && !this.state.clean_map) {
			this.setState({ clean_map: true });
			return true;
		} else {
			return false;
		}
	}

	checkRigOnHire = (rigname: any) => {
		if (rigname) {
			let rig = getRigObject(rigname);
			return rig.on_hire ? true : false;
		}
	};

	addMarkers = (_key: string) => {
		return this.props.vessels.map((name: string) => {
			// this.setState({ marker: true });
			let rig = getRigObject(name);
			const openPopup = (marker: any) => {
				// console.log('openMarker',marker)
				if (marker !== undefined && marker !== null) marker.openPopup();
			};
			return (
				<div key={"marker" + name}>
					<Marker
						key={name}
						position={
							rig !== undefined && rig.ais_data[0]
								? [
										rig.ais_data[0].ais_location["lat"],
										rig.ais_data[0].ais_location["lng"],
								  ]
								: [50, 50]
						}
						icon={rig?.on_hire ? blueIcon : greyIcon}
						ref={(ref: any) => {
							//register marker
							if (!this.markerRefs[`${name}`]) {
								this.markerRefs[`${name}`] = ref;
							}
							if (this.props.selectedVessel === name) {
								openPopup(ref);
							}
						}}
						eventHandlers={{
							click: (event: any) => {
								this.props.dispatch({ type: "SELECT_VESSEL", payload: null });
								this.setState({ rigInfo: rig.ais_data[0] });
								this.markerRefs[name].openPopup();

								setTimeout(() => {
									this.props.dispatch({
										type: "SELECT_VESSEL",
										payload: name,
									});
								}, 500);
							},
						}}
					>
						<Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>
							{rig?.vessel_name}
						</Tooltip>

						{/* Desktop version shows a popup while mobile displays at the bottom page */}
						{this.props.level === "bronze" ? (
							!this.props.ismobile ? (
								<Popup minWidth={220} offset={[0, -15]}>
									{this.props.selectedVessel === name ? (
										<VesselStatus
											level={this.props.level}
											rig={name}
											file_tagString={this.props.vesselFiles[name]}
										/>
									) : null}
								</Popup>
							) : this.props.selectedVessel === name ? (
								<VesselStatus
									level={this.props.level}
									rig={name}
									ismobile={true}
									file_tagString={this.props.vesselFiles[name]}
									style={{
										position: "absolute",
										zIndex: "999",
										width: "100%",
									}}
								/>
							) : null
						) : (
							<></>
						)}
					</Marker>
				</div>
			);
		});
	};

	// getZoom = () => {
	// 	return this.props.Zoom;
	// };
	render = () => {
		let _myMap: any;
		// const RefreshMarkers = (myMap: any | null) => {
		// 	let mycenter: any = this.props.selectedVessel;
		// 	mycenter = [0, 0];
		// 	if (this.props.selectedVessel?._id) {
		// 		mycenter = [
		// 			this.props.selectedVessel?.ais_data[0]?.ais_location.lat,
		// 			this.props.selectedVessel?.ais_data[0]?.ais_location.lng,
		// 		];
		// 	}
		// 	Object.keys(this.markerRefs).map((vname: string) => {
		// 		// const markername = `${vname}`
		// 		// console.log(markername)
		// 		// if (this.props.selectedVessel === vname) console.log(this.props.selectedVessel,markername)
		// 		if (this.props.selectedVessel === vname) {
		// 			this.markerRefs[vname].openPopup();
		// 			mycenter = this.markerRefs[vname]._latlng;
		// 		}
		// 		return true;
		// 	});
		// 	if (_myMap !== undefined && _myMap !== null)
		// 		_myMap.setView(mycenter, this.getZoom(), { animate: true });
		// };

		const ChangeView = ({ center, zoom }: any) => {
			const myMap = useMap();
			_myMap = myMap;

			if (this.state.clean_map) {
				this.setState({ map: myMap });
			}
			if (zoom === undefined) zoom = myMap.getZoom();

			if (this.props.selectedVessel && this.state.clean != null) {
				myMap.flyTo([center[0], center[1]], zoom, {
					animate: false,
				});
			} else {
				myMap.flyTo(center, 3, { animate: false });
				if (this.state.clean !== true) this.setState({ clean: true });
			}

			return null;
		};
		const selectedVessel = getRigObject(this.props.selectedVessel);
		const centerLatLng: any = selectedVessel?.ais_data[0]?.ais_location;
		const centerView = () => {
			if (centerLatLng === undefined) {
				return [30, 0];
			} else {
				if (this.props.level !== "bronze" && this.props.ismobile !== true) {
					return [
						centerLatLng.lat,
						centerLatLng.lng +
							zoomCoords[this.props.Zoom ? this.props.Zoom : 0],

						// zoomCoords[this.state.map?._zoom ? this.state.map._zoom : 0],
					];
				} else {
					return [centerLatLng.lat, centerLatLng.lng];
				}
			}
		};

		const CheckPopup = () => {
			const myMap = useMap();
			if (!this.props.selectedVessel) {
				_myMap = myMap;
				_myMap.closePopup();
			}
			return <></>;
		};

		// mapKey: unique mapkeys would create new instances of the MapContainer
		// forcing the map to re-center. This method works as the MapContainer's properties
		// are immutable. Downside of unique mapkeys would be popups do not automatically trigger
		// this would probably be due to the new instance of the mapcotainer being created (memory leak??)
		// const mapKey= JSON.stringify((centerLatLng?centerLatLng:''))

		// Does not re-render and center
		const mapKey = "map-of-the-world";

		return (
			<div
				style={{
					filter: `grayscale(${this.props.grayscale})`,
					flex: 1,
					display: "flex",
				}}
				className="map-container"
			>
				<MapContainer
					center={[30, 0]}
					minZoom={2}
					zoom={3}
					maxZoom={8}
					scrollWheelZoom={true}
				>
					<MarkerClusterGroup
						iconCreateFunction={customClusterIcon}
						// maxClusterRadius={100}
						disableClusteringAtZoom={6}
						// spiderfyOnMaxZoom={true}
					>
						<ChangeView center={centerView()} zoom={this.props.Zoom} />
						<CheckPopup></CheckPopup>
						<LayersControl position="topright">
							<BaseLayer checked name="Google Maps - Hybrid">
								<ReactLeafletGoogleLayer
									noWrap={false}
									apiKey={this.keys}
									type={"hybrid"}
								/>
							</BaseLayer>
							<BaseLayer name="OpenStreetMap">
								<TileLayer
									noWrap={false}
									attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
							</BaseLayer>
						</LayersControl>
						{this.addMarkers(mapKey)}
					</MarkerClusterGroup>
				</MapContainer>
				{this.props.selectedVessel && (
					<Drawer
						title={
							<>
								<span
									style={{
										fontSize: "16px",
										marginRight: "30px",
										fontWeight: "bold",
									}}
								>{`STENA ${this.props.selectedVessel?.toUpperCase()}`}</span>
								{this.checkRigOnHire(this.props.selectedVessel) === true
									? setDrawerlogo(this.props.client?.logo)
									: null}
							</>
						}
						open={this.props.selectedVessel && this.props.level != "bronze"}
						mask={false}
						maskClosable={false}
						destroyOnClose={true}
						closable={false}
						getContainer={false}
						rootClassName={`vessel_drawer_wrapper`}
						rootStyle={{ position: "absolute" }}
						width={this.props.ismobile ? "100vw" : "500px"}
						extra={
							<Space>
								<Button
									style={{ border: "none", boxShadow: "none" }}
									className={"drawer-button-close"}
									icon={GetAntIcon("close")}
									onClick={() => {
										this.props.dispatch({
											type: "SELECT_VESSEL",
											payload: null,
										});
									}}
								></Button>
							</Space>
						}
					>
						<VesselStatus
							level={this.props.level}
							rig={this.props.selectedVessel}
							file_tagString={this.props.vesselFiles[this.props.selectedVessel]}
						/>
					</Drawer>
				)}
			</div>
		);
	};
}
const mapStateToProps = (state: any) => {
	return {
		vessels: state.vessels.allVessels,
		detailvessel: state.vessels.allDetailVessels,
		vesselFiles: state.vessels.vesselFiles,
		selectedVessel: state.vessels.selectedVessel,
		ismobile: state.ismobile,
		client: state.client,
	};
};

export default connect(mapStateToProps)(VesselMap);
