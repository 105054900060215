import { api } from "../../contexts/AuthContext";

const apiURL = "sensormap";

export const getSensorMap = (vessel_code: any) => {
	return new Promise((resolve, reject) => {
		api.get(`/${apiURL}`, {
			params: {
				vessel_code: vessel_code,
			},
		})
			.then((_data) => {
				resolve(_data?.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addSensorMap = (data: any) => {
	return new Promise((resolve, reject) => {
		api.post(`/${apiURL}/add`, data)
			.then((_data) => {
				resolve(_data);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};

export const updateSensorMap = (
	vessel_code: any,
	asset_number: any,
	data: any
) => {
	return new Promise((resolve, reject) => {
		api.put(`/${apiURL}/update`, {
			filter: {
				vessel_code: vessel_code,
				asset_number: asset_number,
			},
			data,
			options: {
				upsert: true,
				new: true,
			},
		})
			.then((_data) => {
				resolve(_data);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};
