import React, { useCallback, useEffect, useState } from "react";
import Visual from "./Visual";
import StaggeredGrid from "./StaggeredGrid";
import { Empty, Space, Typography } from "antd";
import { duckQuery } from "../services/api-server/deltashare";
import { replaceWithParams } from "../utils/queryBuilder";
import { getParameters } from "../services/api-server/parameters";
import { magicFunction } from "../utils/utils";
import { Responsive, WidthProvider } from "react-grid-layout";
import { VisualType } from "../types/Visual";
import useMenu from "../hooks/useMenu";
import useMeasures from "../hooks/useMeasures";
const { Text, Title } = Typography;

const ResponsiveGridLayout = WidthProvider(Responsive);

type VisualsListProps = {
	draggable?: boolean;
	onVisualDragStart?: (
		e: React.DragEvent<HTMLDivElement>,
		visualId: any
	) => void;
	visuals?: VisualType[];
};

const VisualsList = ({
	draggable = false,
	onVisualDragStart = () => {},
	visuals = [],
}: VisualsListProps) => {
	const { getMeasureById } = useMeasures();
	const { getMenuByKey } = useMenu();
	const onDragStart = useCallback(
		(e: React.DragEvent<HTMLDivElement>, visualId: string) => {
			onVisualDragStart(e, visualId);
		},
		[]
	);

	const [visualData, setVisualData] = useState<Record<string, any>>({});
	const [loading, setLoading] = useState(false);

	// data fetching for each visual
	useEffect(() => {
		const fetchDataForVisuals = async () => {
			const data: any = {};
			setLoading(true);

			await Promise.all(
				visuals?.map(async (visual) => {
					const measure = getMeasureById(visual.measure);

					// TODO: get the parameteres

					if (measure) {
						const parameters = await getParameters(measure?.menuKey || "");
						const masteredMenuItem = getMenuByKey(measure?.menuKey || "");

						const masteredParams: Record<string, any> = {};

						parameters.forEach((param) => {
							let values = param.fieldValues
								.filter((field) => field.include)
								.map((field) => field.value);

							masteredParams[param.name] = values;
						});

						const obey = magicFunction(masteredParams, visual?.parameters);

						try {
							const query = replaceWithParams(measure.queryStatement, {
								...measure?.params,
								...obey,
							});
							const response: any = await duckQuery(
								query,
								masteredMenuItem?.data_authorized || [],
								false
							);
							data[visual.id] = response?.response;
						} catch (error) {}
					} else {
						data[visual.id] = null;
					}
				})
			);
			setVisualData(data);
			setLoading(false);
		};

		fetchDataForVisuals();
	}, [visuals, getMeasureById, getMenuByKey]);

	if (visuals?.length === 0)
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<Space direction="vertical" size={16}>
						<Title
							level={2}
							style={{
								margin: "16px 0 8px",
								fontWeight: "normal",
								color: "#FFFFFF73",
							}}
						>
							No visuals here yet
						</Title>
						<Text style={{ color: "#FFFFFF73" }}>
							Create a new visual to update your dashboard.
						</Text>
					</Space>
				}
			/>
		);

	// const generateLayout = () => {
	// 	const itemsPerRow = 3;
	// 	const baseHeight = 3;
	// 	const baseWidth = 2;

	// 	return visuals?.map((visual, i) => {
	// 		return {
	// 			i: visual.id,
	// 			h: baseHeight,
	// 			w: baseWidth,
	// 			x: (i % itemsPerRow) * baseWidth,
	// 			y: Math.floor(i / itemsPerRow) * baseHeight,
	// 		};
	// 	});
	// };

	return (
		<>
			<StaggeredGrid
				items={visuals.map((visual: any) => {
					return (
						<div
							className="droppable-element"
							// draggable={false}
							draggable={draggable}
							onDragStart={(e) => {
								onDragStart(e, visual.id);
								e.dataTransfer.setData("visual_id", visual.id);
							}}
						>
							<Visual
								loading={loading}
								data={visualData[visual.id]}
								key={visual.id}
								visual={visual}
								plotStyle={{ maxHeight: 250 }}
								// params={{ ...measure?.params, ...visual?.parameters }}
								showControls
							/>
						</div>
					);
				})}
			/>
			{/* //! Commented for now, for further development */}
			{/* <ResponsiveGridLayout
				useCSSTransforms={false}
				isDraggable={false}
				isResizable={false}
				isDroppable={false}
				layouts={{ lg: generateLayout() }}
				rowHeight={80}
				breakpoints={{ lg: 1200, md: 768, sm: 576 }}
				cols={{ lg: 6, md: 3, sm: 1 }}
				containerPadding={[0, 0]}
			>
				{visuals.map((visual, i) => {
					return (
						<div
							key={visual.id}
							className="droppable-element"
							// draggable={false}
							draggable={draggable}
							onDragStart={(e) => {
								onDragStart(e, visual.id);
								e.dataTransfer.setData("visual_id", visual.id);
							}}
						>
							<Visual
								data={visualData[visual.id]}
								key={visual.id}
								visual={visual}
								plotStyle={{ maxHeight: 250 }}
								showControls
							/>
						</div>
					);
				})}
			</ResponsiveGridLayout> */}
		</>
	);
};

export default VisualsList;
