import {
	Flex,
	Input,
	InputRef,
	Modal,
	ModalProps,
	Select,
	Space,
	Table,
	Typography,
} from "antd";
import {
	useCallback,
	useState,
	useMemo,
	ChangeEvent,
	useRef,
	useEffect,
} from "react";
import {
	getAppGroupMembers,
	getAppRoles2,
	getApplicationGroups2,
	getGroupAppRoleAssignments,
} from "../../services/api-server/admin";
import { filterUsers } from "../../utils/utils";
import _ from "lodash";

const { Text } = Typography;
const { Search } = Input;

type AddGroupModalProps = ModalProps & {
	currentUsers?: any;
	onAddViewers?: (group: any) => void;
	defaultUsers?: Array<any>;
};

const AddViewerModal = ({
	currentUsers = [],
	onAddViewers = () => {},
	defaultUsers = [],
	onCancel = () => {},
	open,
	...rest
}: AddGroupModalProps) => {
	const searchRef = useRef<InputRef>(null);
	const [selected, setSelected] = useState<any>(null);
	const [loading, setLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [dataSource, setDataSource] = useState([]);
	const [searchVal, setSearchVal] = useState("");
	const [selectedUsers, setSelectedUsers] = useState([]);

	const [groupOptions, setGroupOptions] = useState([]);

	const defaultColumns = [
		{ key: "user", dataIndex: "displayName", title: "User" },
		{
			key: "group",
			dataIndex: "group",
			title: "AD Group",
			render: (text: any, record: any) => record?.groupDisplayName,
		},
		{
			key: "email",
			dataIndex: "mail",
			title: "Email",
		},
	];

	const handleAddViewers = useCallback(() => {
		onAddViewers(selectedUsers);
		resetAddGroupModal();
	}, [onAddViewers, selectedUsers]);

	const handleGroupChange = useCallback(
		(_: any, option: any) => {
			const { value: groupId = "", group } = option;
			setSelected(group);
			// setSelectedUsers([]);
			setTableLoading(true);
			if (searchRef.current && searchRef.current.input)
				searchRef.current.input.setAttribute("value", "");

			getAppGroupMembers(group?.principalId)
				.then((members: any) => {
					const membersWithGroup = members
						?.map((m: any) => ({
							...m,
							key: m.id,
							group: groupId,
							groupDisplayName: group?.principalDisplayName,
						}))
						?.filter((m: any) => {
							const found = defaultUsers?.find(
								(user: any) => user?.id === m?.id
							);
							return !found;
						});
					filterUsers(membersWithGroup)
						.then((members: any) => {
							setDataSource(members);
						})
						.catch((err) => {
							// console.error(err);
						})
						.finally(() => setTableLoading(false));
				})
				.catch((err) => {
					// console.error(err);
					setTableLoading(false);
				});
		},
		[defaultUsers, searchRef.current]
	);

	const handleCloseModal = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			resetAddGroupModal();
			onCancel(e);
		},
		[]
	);

	const resetAddGroupModal = useCallback(() => {
		setSelected(null);
		setSearchVal("");
		setDataSource([]);
	}, []);

	const rowSelection = {
		preserveSelectedRowKeys: true,
		onChange: (selectedRowKeys: any, selectedRows: any) => {
			setSelectedUsers(selectedRows);
		},
	};

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value === "") setSearchVal("");
	};

	const handleSearch = (value: string) => {
		setSearchVal(value);
	};


	const users = useMemo(() => {
		const isMatch = (value: string) => {
			return value?.includes(searchVal);
		};
		const filteredUsers = dataSource?.filter((user: any) => {
			// console.log(user);

			const userName = user?.displayName?.toLowerCase();
			const group = user?.groupDisplayName?.toLowerCase();
			const email = user?.mail?.toLowerCase();
			return isMatch(userName) || isMatch(group) || isMatch(email);
		});

		return searchVal ? filteredUsers : dataSource;
	}, [dataSource, searchVal]);

	const fetchData = async () => {
		try {
			const groups: any = await getApplicationGroups2();
			const appRoles: any = await getAppRoles2();
			const adminRole: any = appRoles?.find(
				(role: any) =>
					role.displayName === process.env.REACT_APP_SUPER_ADMIN_TAG
			);

			const uniqueGroups: any[] = _.uniqBy(groups, "principalId");

			const filterGroups: any = [];
			for (const group of uniqueGroups) {
				const assignments: any = await getGroupAppRoleAssignments(
					group.principalId
				);

				const isAdmin = assignments.find(
					(appRoleId: any) => appRoleId === adminRole.id
				);

				if (!isAdmin) {
					filterGroups.push(group);
				}
			}

			setGroupOptions(
				filterGroups.map((group: any) => ({
					label: group.principalDisplayName,
					value: group.id,
					group,
				}))
			);
		} catch (err) {
			throw err;
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Modal
			{...rest}
			title="Add Viewers"
			okText="Add Viewers"
			onOk={handleAddViewers}
			okButtonProps={{ disabled: selectedUsers?.length === 0, loading }}
			styles={{
				header: {
					padding: "16px 24px",
				},
			}}
			maskClosable={false}
			destroyOnClose
			open={open}
			width={800}
			onCancel={handleCloseModal}
		>
			<Flex vertical gap={16}>
				<Flex vertical gap={"small"}>
					<Text>Search AD Group</Text>

					<Select
						dropdownStyle={{ zIndex: 30003 }}
						style={{ width: "100%" }}
						options={groupOptions}
						showSearch
						onChange={handleGroupChange}
						value={selected?.id}
						loading={tableLoading}
						disabled={tableLoading}
						optionFilterProp="label"
					/>
				</Flex>

				{dataSource && dataSource?.length !== 0 && (
					<Flex vertical gap={"small"}>
						<Flex justify="space-between" align="center">
							<Space>
								<Text>Users</Text>
								{selectedUsers.length > 0 && (
									<Text>Selected {selectedUsers.length} users</Text>
								)}
							</Space>
							<Search
								ref={searchRef}
								placeholder="Search user name"
								style={{ width: 250 }}
								onSearch={handleSearch}
								onChange={handleSearchChange}
								allowClear
							/>
						</Flex>
						<Table
							className="preview-table"
							columns={defaultColumns}
							bordered
							size="small"
							dataSource={users}
							loading={tableLoading}
							pagination={{ pageSize: 8, showSizeChanger: false }}
							rowSelection={rowSelection}
						/>
					</Flex>
				)}
			</Flex>
		</Modal>
	);
};

export default AddViewerModal;
