import React from "react";
import PowerBI from "../services/PowerBI";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import NoAccess from "./NoAccess";
import { ComponentHook } from "../utils/components";

class Powerbi extends React.Component<any, any> {
	state: any = {
		workspaceid: null,
		reportid: null,
		dashboardid: null,
		scorecardid: null,
		vessel: null,
		history: null,
		e_identity: false,
		loading: true,
		powerbi_roles: [],
	};

	props: any = {};

	constructor(props: any) {
		super(props);
		this.props = props;
	}

	componentDidMount() {}

	componentDidUpdate() {
		if (this.state.history !== window.location.pathname && this.state.history) {
			this.setState({
				workspaceid: null,
				reportid: null,
				history: null,
			});
		}
	}

	render() {
		const WithRouter = () => {
			let Params: any = useLocation();
			let pathname: string = Params.pathname;
			const roles: any = [];
			let powerbi_roles: any = this.props.params.mitem.powerbi_roles;
			let enable_custom_roles: any =
				this.props.params.mitem.enable_custom_roles;
			if (powerbi_roles && enable_custom_roles) {
				if (powerbi_roles.length > 0) {
					powerbi_roles.forEach((element: any) => {
						if (element.users?.includes(this.props.username)) {
							roles.push(element.role_key);
						} else {
							return element.roles?.every((role: any) => {
								if (this.props.role.includes(role)) {
									roles.push(element.role_key);
									return false;
								} else {
									return true;
								}
							});
						}
					});
				} else {
					this.setState({ roleCheck: false });
				}
			} else {
				this.setState({ roleCheck: true });
			}

			// console.log(roles);
			if (roles.length === 0 && enable_custom_roles) {
				this.setState({ roleCheck: false });
			} else {
				this.setState({ roleCheck: true });
			}

			this.setState({
				workspaceid: this.props.params.mitem.workspaceid,
				reportid: this.props.params.mitem.reportid,
				datasetid: this.props.params.mitem.datasetid,
				dashboardid: this.props.params.dashboardid,
				scorecardid: this.props.params.scorecardid,
				vessel: this.props.params.vessel,
				e_identity: this.props.params.mitem.e_identity,
				paginated: this.props.params.mitem.paginated,
				available_roles: roles.length > 0 ? roles : null,
				enable_custom_roles: this.props.params.mitem.enable_custom_roles,
				history: pathname,
				loading: false,
			});
			return null;
		};

		return (
			<>
				{!this.state.history ? <WithRouter /> : null}
				{!this.state.loading && (
					<>
						{this.state.workspaceid && this.state.roleCheck ? (
							<>
								<ComponentHook menuProps={this.props.params.mitem} />
								<PowerBI
									params={{
										title: this.props.title,
										workspaceid: this.state.workspaceid,
										reportid: this.state.reportid,
										dashboardid: this.state.dashboardid,
										scorecardid: this.state.scorecardid,
										datasetid: this.state.datasetid,
										vessel: this.state.vessel || null,
										e_identity: this.state.e_identity,
										paginated: this.state.paginated,
										available_roles: this.state.available_roles,
									}}
								/>
							</>
						) : (
							<NoAccess />
						)}
					</>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		role: state.role,
		username: state.user.preferred_username,
	};
};

export default connect(mapStateToProps)(Powerbi);
