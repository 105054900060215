import { useCallback, useEffect, useState } from "react";
import { socket } from "../utils/socket";
import { duckQuery } from "../services/api-server/deltashare";
import useVisualGroups from "./useVisualGroups";
import useParameters from "./useParameters";
import useMenu from "./useMenu";
import { useComponent } from "../contexts/ComponentContext";

/**
 * handles all loading of data for custom dashboard
 */
const useCustomDashboard = (props: any) => {
	const { menuitem, metadata } = props;
	const [loading, setLoading] = useState(false);
	const { loading: visualGroupsLoading, visualGroups } = useVisualGroups(
		menuitem.key
	);
	const [progress, setProgress] = useState(0); // keep track of the progress of the tasks
	const [dataLoading, setDataLoading] = useState(true);
	const [tasks, setTasks] = useState<any>({});
	const {
		loading: paramsLoading,
		parameters,
		paramValues,
	} = useParameters(menuitem.key);
	const { loading: menuLoading, menu, getMenuByKey } = useMenu();
	const { measuresLoading, visualsLoading, fetchMeasureData } = useComponent();
	const handleLoading = useCallback((name: string, data: any) => {
		// handle data loading here for special case
		if (name === "data") {
			const { total = 0, completed = 0 } = data;

			if (total === completed) {
				setDataLoading(false);
			} else {
				setLoading(true);
				setDataLoading(true);
			}
		}
		setTasks((prev: any) => ({ ...prev, [name]: data }));
	}, []);

	useEffect(() => {
		setLoading(true);

		if (!visualGroupsLoading) {
			handleLoading("visualGroups", { total: 1, completed: 1 });
		}

		if (!paramsLoading) {
			handleLoading("parameters", { total: 1, completed: 1 });
		}

		if (!menuLoading) {
			handleLoading("menu", { total: 1, completed: 1 });
		}

		if (!measuresLoading) {
			handleLoading("measures", { total: 1, completed: 1 });
		}

		if (!visualsLoading) {
			handleLoading("visuals", { total: 1, completed: 1 });
		}

		if (
			!dataLoading &&
			!visualGroupsLoading &&
			!paramsLoading &&
			!menuLoading &&
			!measuresLoading &&
			!visualsLoading
		) {
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [
		dataLoading,
		visualGroupsLoading,
		paramsLoading,
		menuLoading,
		measuresLoading,
		visualsLoading,
	]);

	// calculation of progress when there are new tasks added
	useEffect(() => {
		const reduced: any = Object.values(tasks).reduce(
			(acc: any, curr: any, i: any) => {
				const { total = 0, completed = 0 } = curr;
				acc.total += total;
				acc.completed += completed;
				return acc;
			},
			{ total: 0, completed: 0 }
		);
		const { total = 0, completed = 0 } = reduced;

		setProgress(Math.round((completed / total) * 100));
	}, [tasks]);

	useEffect(() => {
		duckQuery("SHOW TABLES", [], true)
			.then((data: any) => {
				if (data?.response?.length !== 0) {
					setDataLoading(false);
				} else {
					setDataLoading(true);
				}
			})
			.catch((error) => {
				// Emitter.emit("alert", {
				// 	type: "error",
				// 	message: "Unsuccessful query",
				// 	description: "Please refresh the page or try again later.",
				// });
				console.error(error);
			});

		socket.on("DATA_LOADING", (data: any) => handleLoading("data", data));

		return () => {
			socket.off("DATA_LOADING", (data: any) => handleLoading("data", data));
		};
	}, []);

	return {
		loading,
		visualGroups,
		progress,
		paramValues,
		parameters,
		menu,
		fetchMeasureData,
		getMenuByKey,
	};
};

export default useCustomDashboard;
