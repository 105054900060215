import { Typography } from "antd";
import { useState } from "react";

interface CustomParagraphProps {
	maxRows?: number;
	children?: React.ReactNode;
}

const { Paragraph } = Typography;

const CustomParagraph = ({ children, maxRows = 3 }: CustomParagraphProps) => {
	const [expanded, setExpanded] = useState(false);

	const onToggle = () => {
		setExpanded((prev) => !prev);
	};

	if (children === undefined)
		return <div style={{ color: "slategray", height: "100%" }}>null</div>;

	return (
		<Paragraph
			style={{ marginBottom: 0, maxWidth: "300px", height: "100%" }}
			ellipsis={{
				expandable: "collapsible",
				rows: maxRows,
				onExpand: onToggle,
				symbol: expanded ? "Less" : "More",
			}}
		>
			{children}
		</Paragraph>
	);
};

export default CustomParagraph;
