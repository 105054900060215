import { useCallback, useEffect, useState } from "react";
import { loadMenu } from "../services/api-server/menu";
import Emitter from "../services/EventEmitter";
import { findMenuItemByKey, getNodes } from "../utils/utils";

const useMenu = () => {
	const [menu, setMenu] = useState<Record<string, any>[]>([]);
	const [loading, setLoading] = useState(false);
	const [customDashboardModules, setCustomDashboardModules] = useState<
		Record<string, any>[]
	>([]);

	const getMenuByKey = useCallback(
		(key: string) => {
			return findMenuItemByKey(menu, key);
		},
		[menu]
	);

	useEffect(() => {
		const updateMenu = () => {
			setLoading(true);
			loadMenu()
				.then((data: any) => {
					if (data !== undefined) {
						setMenu(data.menu);
					}

					const customDashboardMenu = getNodes(data.menu)
						?.map((node) => ({
							title: node?.title,
							key: node?.key,
							path: node?.path,
							component: node?.component,
						}))
						.filter((node: any) => node.component === "Custom Dashboard");

					setCustomDashboardModules(customDashboardMenu);
				})
				.catch((error: any) => {
					console.error(error.message);
				})
				.finally(() => {
					setLoading(false);
				});
		};

		Emitter.on("MenuSaved", updateMenu);

		updateMenu();

		return () => {
			Emitter.off("MenuSaved", updateMenu);
		};
	}, []);

	return {
		loading,
		menu,
		customDashboardModules,
		getMenuByKey,
	};
};

export default useMenu;
