import { Form, Input, Typography } from "antd"

const { Text } = Typography

const RealTimeAnalyticsInputs = () => {
    return (
        <>
            <Form.Item>
                <Text style={{ fontSize: 20, marginBottom: 8 }}>
                    Kognifai Dashboard Settings
                </Text>
            </Form.Item>
            <Form.Item
                label="Kognifai Shared Link"
                name={"kognifai_shared_link"}
            >
                <Input className={"input"} />
            </Form.Item>
        </>
    )
}

export default RealTimeAnalyticsInputs