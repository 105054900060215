import React from "react";
import * as D3 from "d3";
import { v4 as uuidv4 } from "uuid";
import "./PieChart.css";

//To prevent re-rendering of chart
const areEqual = (prevProps: any, nextProps: any) => {
	if (prevProps.rig === nextProps.rig) {
		return true; // donot re-render
	} else {
		return false; // will re-render
	}
};

const Component = (props: any) => {
	const divRef: any = React.useRef();
	const svgRef: any = React.useRef();
	const tooltipRef: any = React.useRef();
	let svgElement: any = undefined;

	let thickness: number = props.thickness ? props.thickness : 0.0;
	const valueField: string = props.valueField ? props.valueField : "value";
	const data: any = props.data ? props.data : [];
	let rO: number = 10;
	const degreeStart: number | undefined = props.degreeStart
		? props.degreeStart
		: undefined;

	const dimension: any = {
		width: 10,
		height: 10,
		scaleX: () => {
			D3.scaleLinear([-100, 100], [0, dimension.width]);
		},
		scaleY: () => {
			D3.scaleLinear([-100, 100], [0, dimension.height]);
		},
		margin: () => {
			return Math.min(dimension.width, dimension.height) * 0.1;
		},
		center: () => {
			return [dimension.width / 2, dimension.height / 2];
		},
	};

	const select: any = (data: any) => {
		if (props.onSelected && typeof props.onSelected === "function") {
			props.onSelected(data);
		}
	};

	const formatLabel: any = (data: any) => {
		if (props.formatLabel && typeof props.formatLabel === "function") {
			return props.formatLabel(data);
		} else {
			return data;
		}
	};

	// prepare data
	const degree = Math.PI / 180;
	const d3pie = D3.pie()
		.value((d: any) => {
			return d[valueField] ? d[valueField] : 0;
		})
		.startAngle(() => {
			if (degreeStart) {
				return degreeStart * -1 * degree;
			}
			return 0;
		})
		.endAngle(() => {
			if (degreeStart) {
				return degreeStart * degree;
			}
			return 360;
		});

	let pieData = d3pie(data);

	const plotPie = () => {
		const allValues: number[] = data.map((d: any) =>
			d[valueField] ? d[valueField] : 0
		);
		const maxData: any = D3.extent(allValues);

		pieData = d3pie(data);
		const arcBegin: any = D3.arc().innerRadius(0).outerRadius(0.1);
		const arc: any = D3.arc()
			.innerRadius(rO - rO * thickness)
			.outerRadius(rO);
		const arcExpanded: any = D3.arc()
			.innerRadius(rO - rO * thickness)
			.outerRadius(rO + dimension.margin() / 2);
		const arcExpanded2: any = D3.arc().outerRadius(rO + dimension.margin);
		const svg = D3.select(svgRef.current);
		svg.selectAll("#mycanvas").remove();
		const mycanvas = svg.append("g").attr("id", "mycanvas");
		const chararea = mycanvas.append("g").attr("id", "chartarea");
		const chartArea: any = svg.selectAll("#chartarea");

		const arcs = chartArea
			.selectAll("#chartarea")
			.data(pieData)
			.enter()
			.append("g")
			.attr("id", (d: any) => {
				return `g-${d.data.id}`;
			});

		arcs
			.append("path")
			.attr("fill", (d: any, i: number) => {
				if (d.data.id == "perfect_days") {
					return "#138585";
				} else if (d.data.id == "Programmed") {
					return "#138585";
				} else if (d.data.id == "Downtime") {
					return "#791A1F";
				} else if (d.data.id == "NPT") {
					return "#AA7714";
				} else {
					let v = d.data[valueField] ? d.data[valueField] : 0;
					return D3.interpolateRainbow(v / maxData[1]);
				}
			})
			.attr("id", (d: any) => {
				return `path-${d.data.id}`;
			})
			.attr("d", arc)
			// .attr("stroke", "white")
			.on("click", (ev: any, d: any) => {
				const _arc = D3.select(svgRef.current).selectAll(`#path-${d.data.id}`);
				_arc.transition().delay(20).attr("d", arcExpanded2);
				_arc.transition().delay(40).attr("d", arcExpanded);
				select(d.data);
			})
			.on("mousemove", (ev: any, d: any) => {
				if (svgRef.current) {
					const _arc = D3.select(svgRef.current).selectAll(
						`#path-${d.data.id}`
					);
					updateTooltip(ev, d.data);
					_arc.transition().delay(11).attr("d", arcExpanded);
				}
			})
			.on("mouseout", (ev: any, d: any) => {
				const _arc = D3.select(svgRef.current).selectAll(`#path-${d.data.id}`);
				updateTooltip(ev, "");
				_arc.transition().delay(40).attr("d", arc);
			});
		chararea.attr(
			"transform",
			`translate(${dimension.width / 2},${dimension.height / 2})`
		);

		// arcs
		// 	.append("text")
		// 	.text(function (d: any) {
		// 		return d.data.id;
		// 	})
		// 	.attr("transform", function (d: any) {
		// 		return "translate(" + arc.centroid(d) + ")";
		// 	})
		// 	.style("text-anchor", "middle");
	};

	const initChart = (svgEl: any) => {
		let bgColor = "rgba(0,0,0,1)";
		svgEl.attr(
			"transform",
			`translate(${dimension.margin() / 2},${dimension.margin() / 2})`
		);
		rO = Math.min(dimension.width, dimension.height) / 2 - dimension.margin();
	};

	const svgId: string = `piechart-${uuidv4()}`;
	const setDimension = () => {
		dimension.width = divRef.current
			? divRef.current.clientWidth
			: dimension.width;
		dimension.height = divRef.current
			? divRef.current.clientWidth
			: dimension.height;
		D3.select(svgRef.current)
			.attr("width", dimension.width)
			.attr("height", dimension.height);
	};
	window.addEventListener("resize", () => {
		drawChart();
	});

	const updateTooltip = (ev: any, data: any, _default: string = "") => {
		// the mouse event will provide the X and Y coordinates
		// Using Layer event value will use the current device viewport position
		const clientX: number = ev.layerX + 10;
		const clientY: number = ev.layerY + 10;
		const tt = D3.select(tooltipRef.current);
		let _text = formatLabel(data.id);
		let text = _text ? _text : _default;
		tt.style("left", `${clientX}px`);
		tt.style("top", `${clientY}px`);
		D3.select(tooltipRef.current).text(`${text} ${data.value}%`);
		if (!text) {
			tt.style("display", "none");
		} else if (text.length === 0) {
			// if empty text, hide the tooltip
			tt.style("display", "none");
		} else {
			tt.style("display", "block");
			tt.style("color", "white");
		}
	};
	const drawChart: any = () => {
		D3.select(svgRef.current).selectAll("#mycanvas").remove();
		svgElement = D3.select(svgRef.current).append("g").attr("id", "mycanvas");
		setDimension();
		initChart(svgElement);
		plotPie();
	};
	drawChart();
	React.useEffect(() => {
		drawChart();
	});

	return (
		<>
			<div
				key={uuidv4()}
				ref={divRef}
				style={{
					width: "100%",
					height: "100%",
					// border: "1px solid white",
				}}
			>
				<svg key={uuidv4()} ref={svgRef} id={svgId}>
					<g id="mycanvas">
						<g></g>
					</g>
				</svg>
				<div
					onMouseOverCapture={() => {
						return null;
					}}
					className="tooltip"
					ref={tooltipRef}
				></div>
			</div>
		</>
	);
};

export default React.memo(Component, areEqual);
