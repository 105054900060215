import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { useCallback, useContext } from "react";
import { CustomDashboardContext } from "../contexts/context";

const { RangePicker } = DatePicker;

type SlicerRangePickerProps = RangePickerProps & {
	paramRef: Array<string>;
	onChange?: (values: any) => void;
};

const SlicerRangePicker = ({
	paramRef,
	onChange = () => {},
	...restProps
}: SlicerRangePickerProps) => {
	const formatString = "YYYY-MM-DD";
	const { dispatch: customDashboardDispatch } = useContext(
		CustomDashboardContext
	);

	const handleChange = useCallback(
		(values: any) => {
			const formattedDates = values?.map((val: any) =>
				dayjs(val).format(formatString)
			);

			paramRef.forEach((param, i) => {
				customDashboardDispatch({
					type: "UPDATE_SLICER_PARAMS",
					payload: { key: param, value: formattedDates?.[i], initial: false },
				});
			});

			// NEW

			if (values?.length) {
				const [start_date, end_date] = values;
				const vals = {
					start_date: dayjs(start_date).format(formatString),
					end_date: dayjs(end_date).format(formatString),
				};
				onChange(vals);
			} else {
				onChange({ start_date: null, end_date: null });
			}
		},
		[paramRef]
	);

	const disableDate = useCallback((currentDate: dayjs.Dayjs) => {
		const today = dayjs();
		return currentDate.isAfter(today);
	}, []);

	return (
		<RangePicker
			{...restProps}
			onChange={handleChange}
			disabledDate={disableDate}
		/>
	);
};

export default SlicerRangePicker;
