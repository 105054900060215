import { useEffect, useState } from "react";
import { getViewableUrl } from "../services/api-server/as-teg";
import { Layout, Spin } from "antd";
import { connect } from "react-redux";

const { Content } = Layout;

const ASTEG = (props: any) => {
	const [viewableUrl, setViewableUrl] = useState<any>(null);
	const [token, setToken] = useState<any>({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const handleMessage = (e: any) => {
			let data = e.data;

			if (data.readyState === true) {
				let iframe: any = document.getElementById("iframe");
				let data = `${token.token_type} ${token.access_token}`;
				iframe.contentWindow.postMessage(data, "*");
			}
		};

		window.addEventListener("message", handleMessage);

		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, [token]);

	useEffect(() => {
		if (props?.params?.mitem?.asset) {
			setLoading(true);
			getViewableUrl(props?.params?.mitem?.asset)
				.then((data: any) => {
					setViewableUrl(data?.url);
					setToken(data?.token);
				})
				.catch((error) => {
					// console.error(error?.message);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [props?.params?.mitem?.asset]);

	return (
		<>
			<div
				style={{
					height: "100%",
					width: "100%",
					display: "flex",
				}}
			>
				<Layout>
					<Content>
						{!loading && viewableUrl ? (
							<iframe
								id="iframe"
								src={viewableUrl}
								style={{ width: "100%", height: "100%" }}
							/>
						) : (
							<div
								style={{
									height: "100%",
									width: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Spin
									wrapperClassName="as-teg-wrapper"
									spinning={loading}
									size="large"
								/>
							</div>
						)}
					</Content>
				</Layout>
			</div>
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		role: state.role,
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(ASTEG);
