import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { findActiveMenuItems, getNodes } from "../utils/utils";
import { Tenant } from "../services/api-server/_exports";

type Toggle = () => void;
type SetSubmenuKey = (key: string) => void;

type MenuContextProps = {
	toggle: Toggle;
	selectedKey: string;
	setOpenKeys: Dispatch<SetStateAction<string[]>>;
	openKeys: string[];
	setSubmenuKey?: SetSubmenuKey;
	currentSubKey?: string;
};

const MenuContext = createContext<MenuContextProps | undefined>(undefined);

type MenuContextProviderProps = {
	toggle: Toggle;
	setSubmenuKey?: SetSubmenuKey;
	currentSubKey?: string;
	children: React.ReactNode;
	open?: boolean;
};

const MenuContextProvider = ({
	children,
	toggle,
	setSubmenuKey = () => {},
	currentSubKey,
	open,
}: MenuContextProviderProps) => {
	const [selectedKey, setSelectedKey] = useState("");
	const [openKeys, setOpenKeys] = useState<Array<string>>([]);
	const menuItems = useSelector((state: any) => state?.allModules?.mainMenu);
	const location = useLocation();
	const endNodes = useMemo(() => {
		return getNodes(menuItems);
	}, [menuItems]);

	const currentPath = location.pathname
		.split("/")
		.filter((path) => path && path !== Tenant)
		.join("/");

	useEffect(() => {
		const found = endNodes?.find(
			(node: any) => node?.path?.join("/") === currentPath
		);
		setSelectedKey(found?.key || "");

		setSubmenuKey("");

		if (found) {
			// do backwards
			const paths = location.pathname
				.split("/")
				.filter((path) => path && path !== Tenant);

			// getting all active menu items l1 > l2 > l3 ...
			const activeMenuItems = findActiveMenuItems(menuItems, paths);

			if (open) setOpenKeys(activeMenuItems.map((item) => item.key));

			setSubmenuKey(
				activeMenuItems[1]?.children &&
					activeMenuItems[1]?.children?.length !== 0
					? activeMenuItems[1]?.key
					: ""
			);
		}
	}, [currentPath, endNodes, location, open]);

	useEffect(() => {
		setSubmenuKey(currentSubKey || "");
	}, [currentSubKey]);

	return (
		<MenuContext.Provider
			value={{
				toggle,
				selectedKey,
				setOpenKeys,
				openKeys,
				setSubmenuKey,
				currentSubKey,
			}}
		>
			{children}
		</MenuContext.Provider>
	);
};

const useMenuContext = () => {
	const context = useContext(MenuContext);
	if (!context) {
		throw "useMenuContext must be used within a MenuContextProvider";
	}
	return context;
};

export { MenuContextProvider, useMenuContext };
