import { Table, TableProps } from "antd";
import { capitalize } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { duckQuery } from "../services/api-server/deltashare";
import { useDataExplorer } from "../contexts/DataExplorerContext";
import { transformRecord } from "../utils/utils";
import { useComponent } from "../contexts/ComponentContext";

interface FieldSelectionTableProps extends TableProps<any> {
	onField?: (values: any[], query: string) => void;
}

const FieldSelectionTable = ({
	onField = () => {},
	...restProps
}: FieldSelectionTableProps) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<Array<any>>([]);
	const { state } = useDataExplorer();
	const { data_authorized } = useComponent();
	const [currentPage, setCurrentPage] = useState(1);

	const dataSource = useMemo(() => {
		if (data?.length) {
			return generateDataSource(data);
		}
		return [];
	}, [data]);

	const columns = useMemo(() => {
		if (data?.length) {
			return generateColumns(dataSource);
		}
		return;
	}, [data, dataSource]);

	const scrollToTop = () => {
		const table = document.querySelector(
			".field-selection-table div.ant-table-body"
		);
		if (table) table.scrollTop = 0;
	};

	useEffect(() => {
		if (data?.length) {
			scrollToTop();
		}
	}, [data]);

	useEffect(() => {
		if (state.field) {
			setLoading(true);
			const queryStatement = `SELECT DISTINCT ${state.field} FROM '${state.dataset.name}'`;
			duckQuery(queryStatement, data_authorized)
				.then((data: any) => {
					setData(data?.response);
					onField(transformRecord(data?.response), queryStatement);
					setCurrentPage(1);
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [state.dataset, state.field]);

	if (!state.field) return null;

	return (
		<Table
			loading={loading}
			dataSource={dataSource}
			columns={columns}
			pagination={{
				pageSize: 8,
				showSizeChanger: false,
				style: { paddingRight: 16 },
				current: currentPage,
				onChange: (page) => {
					setCurrentPage(page);
				},
			}}
			rowClassName={"field-selection-table-row"}
			rootClassName="field-selection-table"
			size="small"
			bordered
			style={{ maxHeight: 300 }}
			scroll={{ y: 162 }}
			title={() => "Values"}
			{...restProps}
		/>
	);
};

const generateDataSource = (data: Array<any>): Array<any> => {
	return data.map((d, i) => {
		for (const field in d) {
			if (typeof d[field] === "boolean") {
				return { key: i, [field]: d[field] ? "true" : "false" };
			} else if (d[field]) return { key: i, [field]: d[field] };
			return { key: i, [field]: "null" };
		}
	});
};

const generateColumns = (data: Array<any>) => {
	const columns: any = [];
	const sampleData = data[0];
	const { key, ...restData } = sampleData;
	for (const field in restData) {
		columns.push({ dataIndex: field, key: field, title: capitalize(field) });
	}
	return columns;
};

export default FieldSelectionTable;
