import { Menu } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Tenant } from "../services/api-server/_exports";

class NotificationCenter extends React.Component<any, any> {
	state: any = {
		content: "features",
	};

	props: any = {};

	constructor(props: any) {
		super(props);
		this.props = props;
		// this.setState({
		//     allNotifications:[]
		// })
	}
	componentDidMount() {
		//this.addRandomNotifications(5000)
	}

	componentWillUnmount() {}

	sortAllNotifications() {
		return this.props?.notifications?.allNotifications?.sort(
			(d1: any, d2: any) => {
				return new Date(d2.dt).getTime() - new Date(d1.dt).getTime();
			}
		);
	}
	shortDate(d: Date) {
		const dt: Date = new Date(d);
		let result: string = "ok";
		let date: number = dt.getDate();
		let mnth: number = dt.getMonth();
		let year: number = dt.getFullYear();
		let hrs: number = dt.getHours();
		let min: number = dt.getMinutes();
		result = `${year}-${mnth}-${date} ${hrs}:${min}`;
		return result;
	}

	setContent(menutab: String) {
		this.setState({ content: menutab });
		//Set notifications content here using states
	}

	setReleaseNotes() {
		// const
	}

	render() {
		const renderReleaseNotes = () => {
			const parseDate = (date: any) => {
				let d: any = new Date(date).toString();
				d = d.split(" ");
				const parsedDate: any = d[1] + " " + d[2] + ", " + d[3];
				return parsedDate;
			};
			return this.props.releasenotes?.map((element: any) => {
				return (
					<>
						{!this.props.ismobile ? (
							<div className="notification-items">
								<div className="notification-icon">
									<div className="notification-new-release">
										<FontAwesomeIcon
											className="notification-image-icon"
											style={{ zIndex: 1, color: "white" }}
											icon={faStar}
										/>
									</div>
								</div>
								<div className="notification-content-container">
									<div style={{ display: "flex" }}>
										<span className="notification-title">{element.title}</span>{" "}
										<span className="notification-date">
											{parseDate(element.date)}
										</span>
									</div>
									<div className="notification-content">
										{element.simple_notes
											? element.simple_notes.map((notes: any) => {
													return (
														<span>
															{notes}
															<br />
														</span>
													);
											  })
											: element.notes.map((notes: any) => {
													return (
														<span>
															{notes}
															<br />
														</span>
													);
											  })}
									</div>
									<Link
										style={{ textDecoration: "underline" }}
										to={{
											pathname: `release`,
											hash: `${element.version}`,
										}}
									>
										Read More
									</Link>
								</div>
							</div>
						) : (
							<div
								style={{
									margin: "5px",
									borderBottom: "solid 1px #f4f4f4",
									padding: "5px 0px",
								}}
							>
								<div className="notification-content-container">
									<div style={{ lineHeight: "1" }}>
										<div style={{ display: "flex" }}>
											<span>{element.title}</span>{" "}
											<div className="notification-date">
												{parseDate(element.date)}
											</div>
										</div>

										<Link
											onClick={() => this.props.closeDrawer()}
											style={{ textDecoration: "underline" }}
											to={{
												pathname: `release`,
												hash: `${element.version}`,
											}}
										>
											Read More
										</Link>
									</div>
								</div>
							</div>
						)}
					</>
				);
			});
		};
		return (
			<div className="notification-container">
				<Menu
					className="notification-nav-bar"
					defaultSelectedKeys={["features"]}
					mode="horizontal"
				>
					<Menu.Item
						className="notification-menu-item"
						key="features"
						onClick={() => this.setContent("features")}
					>
						New features {this.state?.NumFeatures}
					</Menu.Item>
					<Menu.Item
						className="notification-menu-item"
						key="updates"
						onClick={() => this.setContent("updates")}
					>
						Updates {this.state?.NumUpdates}
					</Menu.Item>
				</Menu>
				<div className={"notification-window"}>
					{/* Map For Each Notification items. based on the menu.item selected */}
					{this.state.content === "features" ? (
						this.props.releasenotes?.length > 0 ? (
							<>{renderReleaseNotes()}</>
						) : (
							<div
								style={
									!this.props.ismobile
										? {
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												height: "350px",
												fontSize: "20px",
										  }
										: {
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												height: "350px",
												fontSize: "14px",
										  }
								}
							>
								There are no new features
							</div>
						)
					) : null}
					{this.state.content === "updates" ? (
						<div
							className="notification-updates"
							style={
								!this.props.ismobile
									? {
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											height: "300px",
											fontSize: "20px",
									  }
									: {
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											height: "300px",
											fontSize: "14px",
									  }
							}
						>
							There are no updates yet
						</div>
					) : null}
				</div>
			</div>
		);
	}
	addRandomNotifications(n: number = 100) {
		const notificationTypes: string[] = ["a", "b", "c"];
		let newNotification: any = [];
		for (let i = 0; i < n; i++) {
			//const notification:any = (this.state?.allNotifications)? this.state.allNotifications:[]
			//console.log(notification.length)
			let x: any = {
				_id: `${new Date().getTime()}${Math.random()}`,
				dt: new Date().getTime(),
				type: notificationTypes[
					Math.floor(Math.random() * notificationTypes.length)
				],
				description: "Lorem Ipsum",
			};
			newNotification.push(x);
			//this.setState({allNotifications:[...notification,x]})
		}
		this.setState({ allNotifications: newNotification });
	}
}

const mapStateToProps = (state: any) => {
	return {
		notifications: state.notifications,
		releasenotes: state.releasenotes,
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(NotificationCenter);
