import React from "react";
import "../assets/css/Powerbi.css";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Dashboard, Embed } from "powerbi-client";
import { getEmbedToken, getEmbedURL } from "./api-server/powerbi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowRightRotate,
	faPrint,
	faMaximize,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { Space } from "antd";
import NoAccess from "../containers/NoAccess";
import { Tenant } from "./api-server/_exports";

class PowerBI extends React.Component<any, any> {
	state: any = {};
	constructor(props: any) {
		super(props);
		this.state = {
			clean: true,
			workspaceid: this.props.params?.workspaceid,
			reportid: this.props.params?.reportid,
			dashboardid: this.props.params?.dashboardid,
			scorecardid: this.props.params?.scorecardid,
			datasetid: this.props.params?.datasetid,
			vessel: this.props.params?.vessel,
			e_identity: this.props.params?.e_identity,
			paginated: this.props.params?.paginated,
			available_roles: this.props.params?.available_roles,
			enable_custom_roles: this.props.params?.enable_custom_roles,
			tokenType: null,
			embedURL: null,
			embedToken: null,
			type: null,
			contentid: null,
			pageview: "1",
		};
	}

	componentDidMount() {
		// if (
		// 	!localStorage.getItem(`${Tenant}:pbiToken`) &&
		// 	localStorage.getItem(`${Tenant}:idToken`) !== "1"
		// ) {
		// 	getPbiToken().then((res: any) => {
		// 		window.location.reload();
		// 	});
		// } else if (localStorage.getItem(`${Tenant}:pbiToken`)) {
		if (this.state.reportid) {
			this.setState({ type: "report", contentid: this.state.reportid });
			getEmbedURL(this.state.workspaceid, this.state.reportid, "reports")
				.then((res: any) => {
					this.setState({ embedURL: res.embedUrl, report_title: res.name });
				})
				.catch((err) => {
					this.setState({ error: true });
				});
			getEmbedToken(
				this.state.user,
				this.state.workspaceid,
				this.state.reportid,
				"reports",
				this.state.datasetid,
				this.state.e_identity,
				this.state.paginated,
				this.state.available_roles
			)
				.then((res: any) => {
					this.setState({
						embedToken: res,
						tokenType: models.TokenType.Embed,
					});
				})
				.catch((err) => {
					this.setState({ error: true });
				});
		} else if (this.state.dashboardid) {
			this.setState({ type: "dashboard", contentid: this.state.dashboardid });
			getEmbedURL(this.state.workspaceid, this.state.dashboardid, "dashboards")
				.then((res: any) => {
					this.setState({ embedURL: res });
				})
				.catch((err) => {
					this.setState({ error: true });
				});
			getEmbedToken(
				this.state.user,
				this.state.workspaceid,
				this.state.dashboardid,
				"dashboards",
				this.state.datasetid,
			)
				.then((res: any) => {
					this.setState({
						embedToken: res,
						tokenType: models.TokenType.Embed,
					});
				})
				.catch((err) => {
					this.setState({ error: true });
				});
		} else if (this.state.scorecardid) {
			this.setState({ type: "report", contentid: this.state.scorecardid });
			let accessToken = localStorage.getItem(`${Tenant}:pbiToken`);
			let embedurl = `https://app.powerbi.com/scorecardEmbed?scorecardId=${this.state.scorecardid}`;
			this.setState({
				embedURL: embedurl,
				embedToken: accessToken,
				tokenType: models.TokenType.Aad,
			});
		}
		// }
	}

	changeLayout() {
		if (this.state.report) {
			if (this.state.ismobile) {
				if (window.innerWidth <= 800 && window.innerHeight <= 1200) {
					this.state.report.switchLayout(models.LayoutType.MobilePortrait);
				} else if (window.innerWidth <= 1200 && window.innerHeight <= 800) {
					this.state.report.switchLayout(models.LayoutType.MobileLandscape);
				}
			}
		}
	}

	render() {
		// const NavigationToggle = () => {
		//     let Nav: any = this.state.Nav
		//     if (Nav) {
		//         let settings = {
		//             panes: {
		//                 pageNavigation: {
		//                     visible: false
		//                 }
		//             }
		//         }
		//         this.setState({ Nav: false })
		//         this.state.report.updateSettings(settings);
		//     } else {
		//         let settings = {
		//             panes: {
		//                 pageNavigation: {
		//                     visible: true
		//                 }
		//             }
		//         }
		//         this.setState({ Nav: true })
		//         this.state.report.updateSettings(settings);
		//     }
		// }
		// const ExportData = async () => {
		//     try {
		//         const pages = await this.state.report.getPages();
		//         console.log(pages)

		//         // Retrieve the page that contain the visual. For the sample report it will be the active page
		//         let page = pages.filter(function (page: any) {
		//             return page.isActive
		//         })[0];

		//         console.log(page)

		//         const visuals = await page.getVisuals();

		//         console.log(visuals)
		//     }
		//     catch (errors) {
		//         console.log(errors);
		//     }
		// }
		// const updatelayout = (ev: any) => {
		// 	let settings: any = {};
		// 	let view = ev != null ? ev : this.state.pageview;
		// 	if (view == "1") {
		// 		settings = {
		// 			layoutType: models.LayoutType.Custom,
		// 			customLayout: {
		// 				displayOption: models.DisplayOption.ActualSize,
		// 			},
		// 		};
		// 	} else if (view == "2") {
		// 		settings = {
		// 			layoutType: models.LayoutType.Custom,
		// 			customLayout: {
		// 				pageSize: {
		// 					type: models.PageSizeType.Custom,
		// 					width: document.getElementById("PowerBI-container")?.clientWidth,
		// 					height:
		// 						document.getElementById("PowerBI-container")?.clientHeight,
		// 				},
		// 			},
		// 		};
		// 	} else if (view == "3") {
		// 		settings = {
		// 			layoutType: models.LayoutType.Custom,
		// 			customLayout: {
		// 				displayOption: models.DisplayOption.FitToWidth,
		// 			},
		// 		};
		// 	} else if (view == "4") {
		// 		settings = {
		// 			layoutType: models.LayoutType.Custom,
		// 			customLayout: {
		// 				displayOption: models.DisplayOption.FitToPage,
		// 			},
		// 		};
		// 	}
		// 	this.setState({ pageview: view });
		// 	this.state.report.updateSettings(settings);
		// 	// this.state.report.updateSettings("dasa");
		// };
		const setLayout = () => {
			if (this.state.clean) {
				if (this.props.ismobile || this.state.ismobile) {
					this.setState({ ismobile: true, clean: false });
					if (window.innerWidth <= 800 && window.innerHeight <= 1200) {
						return models.LayoutType.MobilePortrait;
					} else if (window.innerWidth <= 1200 && window.innerHeight <= 800) {
						return models.LayoutType.MobileLandscape;
					}
				} else {
					this.setState({ ismobile: false, clean: false });
					return models.LayoutType.Custom;
				}
			}
		};
		return (
			<div
				style={{
					display: "flex",
					height: "100%",
					overflow: "hidden",
					flexDirection: "column",
				}}
			>
				{/* Double conditions because for some reason <PowerBIEmbed> cannot be re-rendered, thus, it will only render when there is embedURL and embedToken */}
				{this.state.embedURL && this.state.embedToken ? (
					<>
						<div id={"PowerBI-container"} style={{ flex: "auto" }}>
							<PowerBIEmbed
								embedConfig={{
									type: `${this.state.type}`, // Supported types: report, dashboard, tile, visual and qna
									id: `${this.state.contentid}`,
									embedUrl: `${this.state.embedURL}`,
									accessToken: `${this.state.embedToken}`,
									tokenType: this.state.tokenType,
									settings: {
										layoutType: setLayout(),
										customLayout: {
											displayOption: models.DisplayOption.FitToPage,
										},
										panes: {
											filters: {
												expanded: false,
												visible: false,
											},
											pageNavigation: {
												visible: false,
											},
										},
									},
								}}
								eventHandlers={
									new Map([
										[
											"loaded",
											function () {
												// console.log("Report loaded");
											},
										],
										[
											"rendered",
											function () {
												// console.log("Report rendered");
												// updatelayout(null);
											},
										],
										[
											"error",
											function (event) {
												// console.log(event?.detail);
											},
										],
									])
								}
								cssClassName={"report-style-class"}
								getEmbeddedComponent={(embeddedReport: Embed) => {
									if (this.state.type === "report") {
										this.setState({ report: embeddedReport as Report });
									} else if (this.state.type === "dashboard") {
										this.setState({ report: embeddedReport as Dashboard });
									}
								}}
							/>
						</div>
						<div className="content-header">
							{/* <span className="content-header-title">
								
									{this.state.report_title ? this.state.report_title : null}
								</span> */}
							<Space size={24}>
								{/* <Select
										defaultValue={"1"}
										style={{ marginRight: "30px", width: "250px" }}
										onChange={(ev: any) => {
											updatelayout(ev);
										}}
									>
										<Option value="1">Actual Size</Option>
										<Option value="2">
											Scale to container (will break scaling)
										</Option>
										<Option value="3">Fit to Width</Option>
										<Option value="4">Fit to Page</Option>
									</Select> */}
								{this.state.type === "report" ? (
									<FontAwesomeIcon
										className="pbi-button-icon"
										icon={faPrint}
										onClick={() => {
											this.state.report.print();
										}}
									/>
								) : null}
								<FontAwesomeIcon
									className="pbi-button-icon"
									icon={faArrowRightRotate}
									onClick={() => {
										this.state.report.reload();
									}}
								/>
								<FontAwesomeIcon
									style={{ marginRight: "55px" }}
									className="pbi-button-icon"
									icon={faMaximize}
									onClick={() => {
										this.state.report.fullscreen();
									}}
								/>
							</Space>

							{/* <Button onClick={() => { this.state.report.print() }}>Print Report</Button>
                                <Button onClick={() => { this.state.report.reload() }}>Reload Report</Button>
                                <Button onClick={() => { this.state.report.fullscreen() }}>Full Screen</Button>
                                <Button onClick={() => { this.state.report.switchMode('view') }}>View Mode</Button>
                                <Button onClick={() => { this.state.report.switchMode('edit') }}>Edit Mode</Button>
                                <Button onClick={() => { NavigationToggle() }}>Navigation Toggle</Button>
                                <Button onClick={() => { ExportData() }}>get Visuals</Button>
                                <Button onClick={() => { MobileLandscape() }}>Mobile Landscape mode</Button>
                                <Button onClick={() => { MobilePotrait() }}>Mobile Potrait mode</Button> */}
						</div>
					</>
				) : (
					<>
						{this.state.error === true ? (
							<NoAccess
								text={"An error has occurred, PowerBI report will not load"}
								subtext={"Contact support: portal-support@stena-evolve.com"}
							/>
						) : null}
					</>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		ismobile: state.ismobile,
		user: state.user
	};
};

export default connect(mapStateToProps)(PowerBI);
