import { Badge, Space } from "antd";
import dayjs from "dayjs";

type NewMenuItemHandler = {
	title?: string;
	newDuration?: Array<any>;
};

const NewMenuItemHandler = ({
	title = "",
	newDuration,
}: NewMenuItemHandler) => {
	return (
		<Space
			align="center"
			styles={{ item: { alignItems: "center", display: "flex" } }}
		>
			{title}
			{newDuration &&
				dayjs().isAfter(dayjs(newDuration?.[0])) &&
				dayjs().isBefore(dayjs(newDuration?.[1])) && (
					<Badge
						count={"New"}
						style={{
							backgroundColor: "#112A45",
							color: "#3C9AE8",
							border: 0,
							boxShadow: "none",
						}}
						color="blue"
					/>
				)}
		</Space>
	);
};

export default NewMenuItemHandler;
