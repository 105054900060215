import { v4 as uuid } from "uuid";
import {
	checkTable,
	initParameters,
	replaceTable,
} from "../utils/queryBuilder";

const ACTIONTYPES = {
	LOADING: "LOADING",
	TABLES: "TABLES",
	METADATAS: "METADATAS",
	QUERY: "QUERY",
	ERROR: "ERROR",
	PREVIEWDATA: "PREVIEWDATA",
	QUERYLOADING: "QUERYLOADING",
	UPDATE_MEASURE: "UPDATE_MEASURE",
	SAVE_MEASURE_START: "SAVE_MEASURE_START",
	SAVE_MEASURE_END: "SAVE_MEASURE_END",
	RESET: "RESET",
	CREATE_MEASURE: "CREATE_MEASURE",
	RESTORE_MEASURE: "RESTORE_MEASURE",
	EDIT_MEASURE: "EDIT_MEASURE",
	VIEW_MEASURE: "VIEW_MEASURE",
	SET_MEASURE: "SET_MEASURE",
	ADD_CLAUSE: "ADD_CLAUSE",
	DELETE_CLAUSE: "DELETE_CLAUSE",
	UPDATE_QUERY_OPTIONS: "UPDATE_QUERY_OPTIONS",
	INSERT_CLAUSE: "INSERT_CLAUSE",
	SAVABLE: "SAVABLE",
	DIRTY: "DIRTY",
	NAME_ERR: "NAME_ERR",
	GO_NEXT: "GO_NEXT",
	GO_PREV: "GO_PREV",
	MEASURE_MODAL: "MEASURE_MODAL",
};

const MODE_TYPE = {
	FIELD: "FIELD",
	DATASET: "DATASET",
	SCHEMA: "SCHEMA",
};

const PAGE_TYPE = {
	QUERY: "QUERY",
	NAMEDESC: "NAMEDESC",
};

const tableId = uuid();

const INITIALSTATE = {
	loading: false,
	tables: [],
	metadatas: [],
	query: "",
	errorMsg: "",
	previewdata: [],
	queryloading: false,
	querydirty: false,
	table: "",
	measure: {
		id: "",
		name: null,
		description: null,
		queryStatement: "",
		params: initParameters,
		verified: false,
	},
	savemeasureloading: false,
	editMode: false,
	savable: false,
	dirty: false,
	errorMessage: "",
	page: PAGE_TYPE.QUERY,
	measureModal: false,
	isNew: true,
};

const reducer = (state: any, action: any) => {
	switch (action.type) {
		case ACTIONTYPES.GO_NEXT:
			return { ...state, page: PAGE_TYPE.NAMEDESC };

		case ACTIONTYPES.GO_PREV:
			return { ...state, page: PAGE_TYPE.QUERY };

		case ACTIONTYPES.INSERT_CLAUSE:
			const { clause, value } = action.payload;

			let singleTable = true;

			if (clause !== "from") {
				singleTable = state.measure?.queryOptions["from"]?.tables?.length === 1;
			}

			switch (clause) {
				case "select":
				case "groupBy":
					let newClause: any = { id: uuid(), name: null };
					if (singleTable)
						newClause = {
							...newClause,
							table: state?.tables?.find((tbl: any) => {
								return (
									tbl?.name ===
									state?.measure?.queryOptions["from"]?.tables[0]?.name
								);
							}),
						};

					return {
						...state,
						measure: {
							...state.measure,
							queryOptions: {
								...state.measure.queryOptions,
								[clause]: {
									...state.measure.queryOptions[clause],
									columns: [
										...state.measure.queryOptions[clause].columns,
										newClause,
									],
								},
							},
						},
					};

				case "from":
					return {
						...state,
						measure: {
							...state.measure,
							queryOptions: {
								...state.measure.queryOptions,
								[clause]: {
									...state.measure.queryOptions[clause],
									tables: [
										...state.measure.queryOptions[clause].tables,
										{ id: uuid(), name: null, type: value },
									],
								},
							},
						},
					};

				case "where":
					let newWhere: any = {
						id: uuid(),
						name: null,
						op: null,
						value: null,
					};
					if (value) newWhere = { ...newWhere, bitOp: value };
					if (singleTable)
						newWhere = {
							...newWhere,
							table: state?.tables?.find((tbl: any) => {
								return (
									tbl?.name ===
									state.measure?.queryOptions["from"]?.tables[0]?.name
								);
							}),
						};

					return {
						...state,
						measure: {
							...state.measure,
							queryOptions: {
								...state.measure.queryOptions,
								[clause]: {
									...state.measure.queryOptions[clause],
									columns: [
										...state.measure.queryOptions[clause].columns,
										newWhere,
									],
								},
							},
						},
					};

				case "orderBy":
					return {
						...state,
						measure: {
							...state.measure,
							queryOptions: {
								...state.measure.queryOptions,
								[clause]: {
									...state.measure.queryOptions[clause],
									columns: [
										...state.measure.queryOptions[clause].columns,
										singleTable
											? {
													id: uuid(),
													name: null,
													order: "ASC",
													table: state?.tables?.find((tbl: any) => {
														return (
															tbl?.name ===
															state.measure?.queryOptions["from"]?.tables[0]
																?.name
														);
													}),
											  }
											: {
													id: uuid(),
													name: null,
													order: "ASC",
											  },
									],
								},
							},
						},
					};

				default:
					return state;
			}

		case ACTIONTYPES.UPDATE_QUERY_OPTIONS:
			let updatedOptions: any = {
				...state.measure.queryOptions,
				[action.payload.key]: action.payload.value,
			};

			// handling from clause separately here
			if (action.payload.key === "from") {
				const entries = action?.payload?.value?.tables;
				const updatedEntries = entries?.map((entry: any) => {
					let updatedEntry = { ...entry };
					if (!entries?.find((e: any) => e?.name === entry?.left?.name)) {
						updatedEntry = {
							...updatedEntry,
							left: { name: null },
							leftKey: null,
						};
					}
					if (!entries?.find((e: any) => e?.name === entry?.right?.name)) {
						updatedEntry = {
							...updatedEntry,
							right: { name: null },
							rightKey: null,
						};
					}
					return updatedEntry;
				});
				updatedOptions = {
					...updatedOptions,
					[action.payload.key]: { tables: updatedEntries },
				};
			}

			// special case for from clause as other clauses depends on the from clauses
			updatedOptions = checkTable(updatedOptions);
			updatedOptions = replaceTable(updatedOptions, state?.tables);

			return {
				...state,
				measure: { ...state.measure, queryOptions: updatedOptions },
				dirty: true,
			};

		case ACTIONTYPES.DELETE_CLAUSE:
			let newQueryOptions = {};
			for (const field in state.measure.queryOptions) {
				if (field !== action.payload) {
					newQueryOptions = {
						...newQueryOptions,
						[field]: state.measure.queryOptions[field],
					};
				}
			}

			return {
				...state,
				measure: { ...state.measure, queryOptions: newQueryOptions },
				dirty: true,
			};

		case ACTIONTYPES.ADD_CLAUSE:
			let updated = { ...state.measure.queryOptions, ...action.payload };

			updated = replaceTable(updated, state?.tables);

			return {
				...state,
				measure: { ...state.measure, queryOptions: updated },
			};

		case ACTIONTYPES.LOADING:
			return { ...state, loading: action.payload };

		case ACTIONTYPES.TABLES:
			return { ...state, tables: action.payload };

		case ACTIONTYPES.METADATAS:
			return { ...state, metadatas: action.payload };

		case ACTIONTYPES.QUERY:
			return {
				...state,
				query: action.payload,
			};

		case ACTIONTYPES.ERROR:
			return {
				...state,
				errorMsg: action.payload,
				previewdata: [],
				querydirty: true,
				queryloading: false,
			};

		case ACTIONTYPES.PREVIEWDATA:
			return {
				...state,
				errorMsg: "",
				previewdata: action.payload.response,
				query: action.payload.query,
				querydirty: true,
				queryloading: false,
			};

		case ACTIONTYPES.QUERYLOADING:
			return {
				...state,
				queryloading: action.payload,
				dirty: true,
			};

		case ACTIONTYPES.UPDATE_MEASURE:
			const updatedMeasure = {
				...state.measure,
				[action.payload.key]: action.payload.value,
			};
			return {
				...state,
				measure: updatedMeasure,
			};

		case ACTIONTYPES.SAVE_MEASURE_START:
			return { ...state, savemeasureloading: true };

		case ACTIONTYPES.SAVE_MEASURE_END:
			return { ...state, savemeasureloading: false };

		case ACTIONTYPES.CREATE_MEASURE:
			const newMeasure = INITIALSTATE.measure;
			return {
				...state,
				measure: {
					...newMeasure,
					id: uuid(),
				},
				measureModal: true,
				isNew: true,
				editMode: true,
			};

		case ACTIONTYPES.EDIT_MEASURE:
			return {
				...state,
				measure: action.payload,
				measureModal: true,
				isNew: false,
				editMode: true,
			};

		case ACTIONTYPES.VIEW_MEASURE:
			console.log("herer");

			return {
				...state,
				measure: action.payload,
				measureModal: true,
				isNew: false,
				editMode: false,
			};

		case ACTIONTYPES.RESTORE_MEASURE:
			// const newMeasure = INITIALSTATE.measure;
			return {
				...state,
				measure: action.payload,
				dirty: true,
				isNew: false,
				measureModal: true,
				editMode: true,
			};

		case ACTIONTYPES.SET_MEASURE:
			return { ...state, measure: action.payload, measureModal: true };

		case ACTIONTYPES.MEASURE_MODAL:
			return { ...state, measureModal: action.payload };

		case ACTIONTYPES.SAVABLE:
			if (action.payload) {
				return {
					...state,
					savable: action.payload,
					errorMessage: INITIALSTATE.errorMessage,
				};
			}
			return { ...state, savable: action.payload };

		case ACTIONTYPES.NAME_ERR:
			return { ...state, errorMessage: action.payload, savable: false };

		case ACTIONTYPES.DIRTY:
			return { ...state, dirty: action.payload };

		case ACTIONTYPES.RESET:
			const { metadatas, tables, ...restState } = INITIALSTATE;
			return { ...state, ...restState };

		default:
			return state;
	}
};

export { ACTIONTYPES, INITIALSTATE, reducer, MODE_TYPE, PAGE_TYPE };
