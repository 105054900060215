import React from "react";
import VesselButton from "../components/VesselButton";
import VesselMap from "../components/VesselMap";
import { currentRig, getRigObject } from "../services/RigInfo";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import Portal_Icon_Logo from "../assets/img/Portal_hexagon_RGB.png";
import { ComponentHook } from "../utils/components";

//class Dashboard extends React.Component<any> {
class Map extends React.Component<any> {
	state: any = {
		rig: null,
		currentRigIndex: 0,
		vessels: [],
		loadedVessels: [],
		zoom: 8,
		acceptedroles: null,
		history: null,
		grayscale: 0,
	};

	props: any = {};

	_currentRig: any;
	_currentRigIndex: number = 0;

	reduxSubscriber: any;

	constructor(props: any) {
		super(props);
		this.props = props;
	}

	componentDidMount() {
		// check localstorage for selectedRig
		// if localstorage selectedRig
		const LSselectedVessel: any = localStorage.getItem("selectedVessel");
		if (LSselectedVessel !== undefined || LSselectedVessel !== null) {
			this.setState({ rig: getRigObject(LSselectedVessel) });
		}
		const rigIndex = this.props.vessels.indexOf(this.state.rig?.name);
		this.setState({ currentRigIndex: rigIndex });
	}

	componentWillUnmount() {}

	componentDidUpdate() {
		if (this.state.history !== window.location.pathname && this.state.history) {
			this.setState({ history: null, acceptedroles: null });
		}
	}

	setloading(index: any) {
		this.state.loadedVessels.push(index);
		if (this.state.loadedVessels.length === this.props.vessels.length) {
			this.setState({ vessel_image_loaded: true });
		}
	}

	listVessels() {
		return this.props.vessels.map((name: string, index: any) => (
			<VesselButton
				key={name}
				vesselname={name}
				onhire={true}
				onLoad={() => {
					this.setloading(index);
				}}
			/>
		));
	}

	setNextRig(rigname: string) {
		const rigIndex = this.props.vessels.indexOf(rigname);
		// console.log("rigindex", rigIndex);
		this.setState({ currentRigIndex: rigIndex });
		this.props.dispatch({ type: "SELECT_VESSEL", payload: rigname });
		localStorage.setItem("selectedVessel", rigname);
		currentRig.next(getRigObject(rigname));
	}

	render() {
		const WithRouter = () => {
			let Params: any = useLocation();
			let pathname: string = Params.pathname;
			this.setState({ history: pathname });
			return null;
		};

		const renderVesselModal = () => {};

		return (
			<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
				<ComponentHook menuProps={this.props.params.mitem} />
				{!this.state.history ? (
					<>
						<WithRouter />
					</>
				) : null}

				<>
					{" "}
					{/* <Spin
						// style={{ color: "rgb(77, 77, 77)" }}
						size="large"
						tip={"Loading Vessels..."}
						indicator={<img alt="Portal logo" src={Portal_Icon_Logo} />}
						className={"antd-spin-blink vessel-spin"}
						spinning={!this.state.vessel_image_loaded}
					> */}
					<div
						className="vessel-list-container noselect nodrag"
						draggable={false}
					>
						{this.listVessels()}
					</div>
					{/* </Spin> */}
					<div
						style={{
							position: "relative",
							display: "flex",
							flex: 1,
							flexDirection: "column",
						}}
					>
						<VesselMap
							level={
								this.props.params.mitem?.level
									? this.props.params.mitem.level
									: "bronze"
							}
							grayscale={this.state.grayscale}
							Zoom={this.state.zoom}
							RigInfo={this.state.rig}
							// selectedVessel={this.props.selectedVessel}
							selectedVessel={this.props?.selectedVessel}
							{...this.props}
						></VesselMap>
						{/* {!this.props.ismobile ? (
								<Slider
									max={new Date().getTime()}
									tipFormatter={(value: any) =>
										new Date(value).toLocaleDateString()
									}
									trackStyle={{ background: "none" }}
									style={{
										bottom: "5%",
										position: "absolute",
										width: "80%",
										left: "0",
										right: "0",
										marginLeft: "auto",
										marginRight: "auto",
										zIndex: "10",
									}}
									onAfterChange={(value: any) =>
										this.setState({ grayscale: 0 })
									}
									onChange={() => this.setState({ grayscale: 1 })}
								/>
							) : null} */}
					</div>
					{this.props.params.mitem.level !== "bronze" &&
					this.props.params.mitem.level
						? renderVesselModal()
						: null}
				</>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		vessels: state.vessels.allVessels,
		selectedVessel: state.vessels.selectedVessel,
		role: state.role,
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(Map);
