import { api } from "../../contexts/AuthContext";
import { group_uid } from "./_exports";

export const getAllClients = () => {
	return new Promise((resolve, reject) => {
		api.get(`/clients`)
			.then((_data: any) => {
				resolve(_data);
			})
			.catch((e: any) => {
				// console.error("error", e);
				reject(e);
			});
	});
};

export const getClient = () => {
	return new Promise((resolve, reject) => {
		api.get(`/clients`, {
			params: {
				group_uid: group_uid,
			},
		})
			.then((_data: any) => {
				resolve(_data);
			})
			.catch((e: any) => {
				// console.error("error", e);
				reject(e);
			});
	});
};

export const getClientLogo = () => {
	return new Promise((resolve, reject) => {
		api.get(`/clients/clientlogo`, {
			params: {
				group_uid: group_uid,
			},
		})
			.then((_data: any) => {
				resolve(_data);
			})
			.catch((e: any) => {
				// console.error("error", e);
				reject(e);
			});
	});
};

export const deleteClient = () => {};

export const updateClient = () => {};
