import { useCallback, useState, useMemo } from "react";
import {
	Button,
	Space,
	Switch,
	Table,
	TableColumnsType,
	Typography,
} from "antd";
import { useMainContext } from "../../contexts/MainContext";

type DataAccessProps = {
	onChange?: (
		authorized_dataset: string[],
		authorized_schema: string[]
	) => void;
	authorized_schema?: string[];
	authorized_dataset?: string[];
};

interface DataType {
	key: React.Key;
	schema: string;
	description: string;
	access: boolean;
}

interface ExpandedDataType {
	key: React.Key;
	name: string;
	schema: string;
	description?: string;
	access: boolean;
}

const { Text } = Typography;

const DataAccess = ({
	onChange = () => {},
	authorized_dataset = [],
	authorized_schema = [],
}: DataAccessProps) => {
	const { metadatas, metadatasLoading } = useMainContext();

	const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

	const schemaToDatasets = useMemo(() => {
		return metadatas.reduce((acc: Record<string, ExpandedDataType[]>, curr) => {
			if (!acc[curr.schema]) {
				acc[curr.schema] = [];
			}
			acc[curr.schema].push({
				key: curr.id,
				name: curr.name,
				schema: curr.schema,
				description: curr.metadata?.description || "",
				access: authorized_dataset.includes(curr.name),
			});
			return acc;
		}, {});
	}, [metadatas, authorized_dataset]);

	const handleSchemaAccess = useCallback(
		(checked: boolean, schema: string) => {
			if (checked) {
				const newAuthorizedDatasets = schemaToDatasets[schema].map(
					(ds) => ds.name
				);

				onChange(
					[...authorized_dataset, ...newAuthorizedDatasets],
					[...authorized_schema, schema]
				);
			} else {
				const newAuthorizedDatasets = authorized_dataset.filter(
					(ds) => !schemaToDatasets[schema].some((sds) => sds.name === ds)
				);

				onChange(
					newAuthorizedDatasets,
					authorized_schema.filter((s) => s !== schema)
				);
			}
		},
		[schemaToDatasets, authorized_dataset, authorized_schema, onChange]
	);

	const handleDatasetAccess = useCallback(
		(checked: boolean, dataset: string, schema: string) => {
			// Update the authorized datasets based on whether it is being checked or unchecked
			const updatedAuthorizedDatasets = checked
				? [...authorized_dataset, dataset]
				: authorized_dataset?.filter((d) => d !== dataset);

			// Get all datasets that belong to the current schema
			const schemaDatasets = metadatas
				.filter((m) => m?.schema === schema)
				.map((m) => m.name);

			// Check how many datasets remain authorized for this schema
			const remainingAuthorizedDatasetsForSchema = schemaDatasets.filter((d) =>
				updatedAuthorizedDatasets.includes(d)
			);

			// Trigger onChange with updated datasets and schemas
			onChange(
				updatedAuthorizedDatasets,
				remainingAuthorizedDatasetsForSchema.length > 0
					? // If any dataset in this schema is authorized, keep the schema as authorized
					  authorized_schema.includes(schema)
						? authorized_schema
						: [...authorized_schema, schema]
					: // If all datasets are unchecked, remove the schema from authorized_schema
					  authorized_schema.filter((s) => s !== schema)
			);
		},
		[metadatas, authorized_dataset, authorized_schema]
	);

	const getSchemaChecked = useCallback(
		(schema: string) => {
			// All datasets that belong to this schema
			const datasets = metadatas
				?.filter((m) => m?.schema === schema)
				?.map((m) => m?.name);

			// Check if at least one dataset in this schema is authorized
			return datasets?.some((d) => authorized_dataset?.includes(d));
		},
		[metadatas, authorized_dataset]
	);

	const expandedRowRender = (record: DataType) => {
		const columns: TableColumnsType<ExpandedDataType> = [
			{
				title: "Dataset",
				dataIndex: "name",
				key: "name",
				width: "300px",
			},
			{
				title: "Description",
				dataIndex: "description",
				key: "description",
			},
			{
				title: "Access",
				key: "access",
				width: "80px",
				render: (_value, _record) => (
					<Switch
						onChange={(checked) =>
							handleDatasetAccess(checked, _record.name, record.schema)
						}
						checked={authorized_dataset.includes(_record.name)}
						disabled={!authorized_schema.includes(record.schema)} // Only disable if the schema is unauthorized
					/>
				),
			},
		];

		return (
			<Table
				columns={columns}
				dataSource={schemaToDatasets[record.schema]}
				pagination={false}
			/>
		);
	};

	const handleExpandAll = useCallback(() => {
		const allSchemas = Object.keys(schemaToDatasets);
		setExpandedRowKeys(allSchemas);
	}, [schemaToDatasets]);

	const handleCollapseAll = useCallback(() => {
		setExpandedRowKeys([]);
	}, []);

	const columns: TableColumnsType<DataType> = [
		{
			title: "Schema",
			dataIndex: "schema",
			key: "schema",
			width: "300px",
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
		},
		{
			title: "Access",
			key: "access",
			width: "80px",
			render: (value, record) => (
				<Switch
					onChange={(checked) => handleSchemaAccess(checked, record.schema)}
					checked={getSchemaChecked(record.schema)}
				/>
			),
		},
	];

	const data: DataType[] = Object.keys(schemaToDatasets).map((schema) => ({
		key: schema,
		schema,
		description: schemaToDatasets[schema][0]?.description || "",
		access: getSchemaChecked(schema),
	}));

	return (
		<>
			<div
				style={{
					padding: "16px 8px",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<Text style={{ fontSize: 16 }}>Data access</Text>
				<Space>
					<Button disabled={metadatasLoading} onClick={handleCollapseAll}>
						Collapse All
					</Button>
					<Button disabled={metadatasLoading} onClick={handleExpandAll}>
						Expand All
					</Button>
				</Space>
			</div>
			<Table
				loading={metadatasLoading}
				columns={columns}
				dataSource={data}
				expandable={{
					expandedRowRender,
					expandedRowKeys,
					onExpand: (_, record) =>
						setExpandedRowKeys((prev) =>
							prev.includes(String(record.key))
								? prev.filter((key) => key !== String(record.key))
								: [...prev, String(record.key)]
						),
				}}
				pagination={false}
			/>
		</>
	);
};

export default DataAccess;
