import { useEffect, useState } from "react";
import {
	Button,
	ConfigProvider,
	Drawer,
	Select,
	Spin,
	Table,
	theme,
} from "antd";
import {
	getAllVesselsKognifai,
	listVesselTags,
} from "../services/api-server/kognifai";

import { isNumeric } from "../utils/utils";
import { getMaximoList } from "../services/api-server/maximo";
import { getVesselCode } from "../services/api-server/vessels";
import { connect } from "react-redux";
import { GetAntIcon } from "../utils/ant_icons";
import Search from "antd/es/input/Search";
import {
	addSensorMap,
	getSensorMap,
	updateSensorMap,
} from "../services/api-server/sensorMap";
import Emitter from "../services/EventEmitter";
import { ComponentHook } from "../utils/components";

const SensorMapping = (props: any) => {
	const [loading, setLoading]: any = useState<any>(true);
	// const location = useLocation().pathname;
	// const [acceptedroles, setacceptedroles] = useState<any>(
	// 	checkRoles(props?.role, props?.params?.roles)
	// );
	const [vesselList, setVesselList] = useState<any>([]);
	const [currentVessel, setCurrentVessel] = useState<any>(null);
	const [currentVesselCode, setCurrentVesselCode] = useState<any>(null);
	const [kognifaiData, setKognifaiData] = useState<any>([]);
	const [maximoData, setMaximoData] = useState<any>([]);
	const [mappedData, setMappedData] = useState<any>([]);
	const [maximoSearchValue, setMaximoSearchValue] = useState<any>(null);
	const [kognifaiSearchValue, setKognifaiSearchValue] = useState<any>(null);
	const [filteredMaximoData, setFilteredMaximoData] = useState<any>(null);
	const [filteredKognifaiData, setFilteredKognifaiData] = useState<any>(null);
	const [tableHeight, setTableHeight] = useState<any>(null);

	const [kognifaiRowKeys, setKognifaiRowKeys] = useState<any>([]);
	const [maximoRowKey, setMaximoRowKey] = useState<any>(null);
	const [totalSensorCount, setTotalSensorCount] = useState<number>(0);
	const [totalMappedCount, setTotalMappedCount] = useState<number>(0);

	const [saveDrawer, setSaveDrawer] = useState<boolean>(false);
	const [prevData, setPrevData] = useState<any>(null);

	const calculateTableHeight = () => {
		const table: any = document.getElementById("table-container");

		if (table) {
			setTableHeight(table?.clientHeight - 55);
		}
	};

	useEffect(() => {
		getAllVesselsKognifai()
			.then((data: any) => {
				let options: any = [];
				data.forEach((vessel: any) => {
					if (vessel.Name != "Don" && vessel.Name != "Spey")
						options.push({ label: "Stena " + vessel.Name, value: vessel.Name });
				});
				setVesselList(options);
			})
			.finally(() => {
				setCurrentVessel("Carron");
				setLoading(false);
			});
	}, []);

	// useEffect(() => {
	// 	setacceptedroles(checkRoles(props?.role, props?.params?.roles));
	// }, [props?.role, props?.params?.roles, location]);

	useEffect(() => {
		if (currentVessel) {
			setLoading(true);
			getVesselCode(currentVessel).then((code: any) => {
				const vesselCode = code[0];
				setCurrentVesselCode(vesselCode);
				Promise.all([
					getMaximoList(vesselCode),
					listVesselTags(currentVessel),
					getSensorMap(vesselCode),
				])
					.then((response: any) => {
						const maximoList = response[0];
						const kognifaiList = response[1];
						const mapList = response[2];
						let sensorCount = 0;
						if (maximoList) {
							let filteredTree = maximoList.filter((element: any) => {
								return !element.key.startsWith("1");
							});
							const flattenTree = (tree: any) => {
								const result: any = [];
								const recursiveFlatten = (node: any) => {
									const titleArray: Array<string> = node.title
										.trim()
										.split(/[\s+-]/)
										.filter((v: any) => v);
									// titleArray.splice(0, 1);
									const cleanedTitle = titleArray.join(" ");
									let found = mapList.find(
										(element: any) => element.asset_number === node.key
									);
									if (found?.tags) {
										sensorCount += found?.tags.length;
									}

									result.push({
										...node,
										sensor_count: found?.tags.length || 0,
										children: null,
										subTitle: `${cleanedTitle} (${node.key}) `,
									});
									if (node.children) {
										node.children.forEach((child: any) =>
											recursiveFlatten(child)
										);
									}
								};
								tree.forEach((node: any) => recursiveFlatten(node));
								return result;
							};
							let flat = flattenTree(filteredTree);
							setMaximoData(flat);
							setTotalMappedCount(sensorCount);
						}
						if (kognifaiList.data) {
							setTotalSensorCount(kognifaiList.data.length);
							let kognifaiData = kognifaiList.data.map((element: any) => {
								let found = mapList?.find((item: any) =>
									item.tags.find((item: any) =>
										item.name?.includes(element.Name)
									)
								);
								let array = element.Name.split(".");
								array.shift();
								return {
									...element,
									asset_mapped: found?.asset_name || null,
									asset_mapped_number: found?.asset_number || null,
									subName: array.join(),
								};
							});
							setKognifaiData(kognifaiData);
						}
						setMappedData(mapList);
					})
					.catch((error: any) => {
						// console.log(error);
					})
					.finally(() => {
						setLoading(false);
						calculateTableHeight();
					});
			});
		}
	}, [currentVessel]);

	const handleRefresh = (data: any | null = null) => {
		if (currentVesselCode) {
			getSensorMap(currentVesselCode)
				.then((SensorMap: any) => {
					let newKognifaiData = kognifaiData
						.map((kognifaiItem: any) => {
							let found = SensorMap.find((item: any) =>
								item.tags.find((item: any) =>
									item.name?.includes(kognifaiItem.Name)
								)
							);

							return {
								...kognifaiItem,
								asset_mapped: found?.asset_name || null,
								asset_mapped_number: found?.asset_number || null,
							};
						})
						.sort((a: any, b: any) => {
							if (
								a.asset_mapped === data?.asset_name &&
								b.asset_mapped === data?.asset_name
							) {
								return 0; // Leave DBC items in their current order
							} else if (a.asset_mapped === data?.asset_name) {
								return -1; // Place DBC items above others
							} else if (b.asset_mapped === data?.asset_name) {
								return 1; // Place non-DBC items below DBC items
							} else {
								const result = a.asset_mapped > b.asset_mapped ? -1 : 1;
								// return order === 'ascend' ? result : -result;
								return result;
							}
						});

					let count = 0;

					let newMaximoData = maximoData.map((maximoItem: any) => {
						let found = SensorMap.find(
							(element: any) => element.asset_number === maximoItem.key
						);
						if (found?.tags.length) {
							count += found?.tags.length;
						}
						return { ...maximoItem, sensor_count: found?.tags.length || 0 };
					});

					setTotalMappedCount(count);
					setMaximoData(newMaximoData);
					setKognifaiData(newKognifaiData);
					setMappedData(SensorMap);

					if (maximoSearchValue)
						filterMaximoData(maximoSearchValue, newMaximoData);
					if (kognifaiSearchValue)
						filterKognifaiData(kognifaiSearchValue, newKognifaiData);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const renderTop10 = () => {
		let mappingCounts: any = {};
		mappedData.forEach((element: any) => {
			let tags: any = element.tags;
			tags.forEach((element: any) => {
				let user = element.by;
				if (mappingCounts[user] === undefined) {
					mappingCounts[user] = 1;
				} else {
					mappingCounts[user]++;
				}
			});
		});

		const sortedMappingCounts: any = Object.entries(mappingCounts)
			.sort((a: any, b: any) => b[1] - a[1])
			.slice(0, 10);

		//console.log(sortedMappingCounts);

		return sortedMappingCounts.map((array: any) => (
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<span>{array[0]}</span>
				<span>{array[1]}</span>
			</div>
		));
	};

	const filterMaximoData = (value: any, data: any = null) => {
		if (maximoData?.length > 0) {
			let currentMaximoData = data || [...maximoData];
			if (value === "") {
				setFilteredMaximoData(null);
			} else {
				let filteredData: any = [];
				if (isNumeric(value.charAt(0))) {
					filteredData = currentMaximoData.filter(
						(element: any) =>
							element.key.startsWith(value) &&
							element !== null &&
							element !== undefined
					);
				} else {
					filteredData = currentMaximoData.filter(
						(element: any) =>
							element.title.toLowerCase().includes(value.toLowerCase()) &&
							element !== null &&
							element !== undefined
					);
				}
				setFilteredMaximoData(filteredData);
			}
		} else {
			setFilteredMaximoData(null);
		}
	};

	const filterKognifaiData = (value: any, data: any = null) => {
		if (kognifaiData?.length > 0) {
			let currentKognifaiData = data || [...kognifaiData];
			if (value === "") {
				setFilteredKognifaiData(null);
			} else {
				let filteredData = currentKognifaiData.filter(
					(element: any) =>
						(element.Name.toLowerCase().includes(value.toLowerCase()) ||
							element.Description.toLowerCase().includes(
								value.toLowerCase()
							)) &&
						element !== null &&
						element !== undefined
				);

				setFilteredKognifaiData(filteredData);
			}
		} else {
			setFilteredKognifaiData(null);
		}
	};

	return (
		<ConfigProvider
			theme={{
				algorithm: theme.defaultAlgorithm,
				inherit: false,
			}}
		>
			{/* {acceptedroles ?
			 (
				
			) : (
				<NoAccess />
			)} */}
			<ComponentHook menuProps={props.params.mitem} />
			<Spin
				wrapperClassName="full-page-spin"
				tip="Refreshing Data..."
				size="large"
				spinning={loading}
			>
				<div
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
						padding: "32px 40px",
						background: "#FAFAFB",
						gap: "20px",
					}}
					className="admin-div"
				>
					<div
						className="admin-title-container"
						style={{
							flexDirection: "row",
							display: "flex",
							width: "100%",
							justifyContent: "space-between",
							padding: 0,
						}}
					>
						<div
							style={{
								flex: 1,
								display: "flex",
								alignItems: "flex-start",
								justifyContent: "flex-end",
							}}
						>
							<Select
								className="sensor-mapping-select"
								popupClassName="sensor-mapping-select-popup"
								disabled={saveDrawer}
								placeholder="Select vessel..."
								style={{ minWidth: "200px" }}
								options={vesselList}
								value={currentVessel}
								onChange={(value: any) => {
									setKognifaiData([]);
									setKognifaiRowKeys([]);
									setMaximoData([]);
									setMaximoRowKey(null);
									setCurrentVessel(value);
								}}
							></Select>
						</div>
					</div>
					<div
						className="admin-content-container"
						style={{
							//TEMP SOLUTION
							color: "black",
							display: "flex",
							flexDirection: "row",
							width: "100%",
							flex: "1",
							gap: "24px",
						}}
					>
						{currentVessel ? (
							<>
								{" "}
								<div
									style={{
										width: "90%",
										minWidth: "300px",
										display: "flex",
										flexDirection: "row",
										gap: "16px",
									}}
								>
									<div
										className="maximo-assets-container"
										style={{
											display: "flex",
											flexDirection: "column",
											width: "50%",
											maxWidth: "50%",
											background: "#FFF",
											boxShadow:
												"0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(20, 62, 98, 0.15)",
										}}
									>
										<div
											className="maximo-assets-header"
											style={{
												display: "grid",
												gridTemplateColumns: "0.2fr 1fr 1fr",
												padding: "0px 16px",
												justifyContent: "space-between",
												alignItems: "center",
												alignSelf: "stretch",
												height: "56px",
												gap: "28px",
											}}
										>
											<div>Assets</div>
											<Select disabled placeholder="Show all">
												Filter
											</Select>
											<Search
												allowClear
												onSearch={(value: any) => {
													setMaximoSearchValue(value);
													filterMaximoData(value);
												}}
												placeholder="Search assets..."
											></Search>
										</div>
										<div
											className="maximo-asset-table"
											style={{ flex: 1, maxHeight: "100%" }}
										>
											<Table
												key={"maximo-table"}
												rowClassName={(record: any) => {
													return record.key === maximoRowKey?.key
														? "ant-table-row-selected  table-row-clickable"
														: " table-row-clickable";
												}}
												onRow={(record: any, index: any) => {
													return {
														onClick: () => {
															if (!saveDrawer) {
																if (maximoRowKey?.name === record.title) {
																	setMaximoRowKey(null);
																	setKognifaiRowKeys([]);
																	setPrevData(false);
																} else {
																	setKognifaiRowKeys([]);
																	setMaximoRowKey({
																		name: record.title,
																		key: record.key,
																	});

																	if (mappedData) {
																		let found = mappedData.find(
																			(element: any) =>
																				record.key === element.asset_number
																		);
																		//set previous data and sort it the mapped data
																		if (found) {
																			let tagList = found.tags.map(
																				(element: any) => element.name
																			);
																			setKognifaiRowKeys(tagList);
																			setPrevData(found);

																			let sortedKognifai: any =
																				kognifaiData.sort((a: any, b: any) => {
																					if (
																						a.asset_mapped ===
																							found.asset_name &&
																						b.asset_mapped === found.asset_name
																					) {
																						return 0; // Leave DBC items in their current order
																					} else if (
																						a.asset_mapped === found.asset_name
																					) {
																						return -1; // Place DBC items above others
																					} else if (
																						b.asset_mapped === found.asset_name
																					) {
																						return 1; // Place non-DBC items below DBC items
																					} else {
																						const result =
																							a.asset_mapped > b.asset_mapped
																								? -1
																								: 1;
																						// return order === 'ascend' ? result : -result;
																						return result;
																					}
																				});

																			setKognifaiData([...sortedKognifai]);
																		} else {
																			setPrevData(false);
																		}
																	}
																}
															}
														},
													};
												}}
												rowKey={"key"}
												virtual
												className="white-table"
												columns={[
													{ title: "Name", dataIndex: "title", key: "title" },
													{ title: "ID", dataIndex: "key", key: "key" },
													{
														title: "Sensors",
														dataIndex: "sensor_count",
														key: "sensor",
														width: 80,
														render: (text: any, record: any) => {
															return text ? text : "0";
														},
													},
												]}
												dataSource={
													filteredMaximoData ? filteredMaximoData : maximoData
												}
												pagination={false}
												scroll={tableHeight && { y: tableHeight, x: "0px" }}
											></Table>
										</div>
									</div>
									<div
										className="kognifai-sensors-container"
										style={{
											display: "flex",
											flexDirection: "column",
											width: "50%",
											maxWidth: "50%",
											background: "#FFF",
											boxShadow:
												"0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(20, 62, 98, 0.15)",
										}}
									>
										<div
											className="kognifai-sensors-header"
											style={{
												display: "grid",
												gridTemplateColumns: "0.2fr 1fr 1fr",
												padding: "0px 16px",
												justifyContent: "space-between",
												alignItems: "center",
												alignSelf: "stretch",
												height: "56px",
												columnGap: "28px",
											}}
										>
											<div>Sensors</div>
											<Select disabled placeholder="Show all">
												Filter
											</Select>
											<Search
												allowClear
												onSearch={(value: any) => {
													setKognifaiSearchValue(value);
													filterKognifaiData(value);
												}}
												placeholder="Search sensors..."
											></Search>
										</div>
										<div
											id="table-container"
											className="kognifai-asset-table"
											style={{
												flex: 1,
												maxHeight: "100%",
												overflow: "hidden",
											}}
										>
											<Table
												key={"kognifai-table"}
												onRow={(record: any, rowIndex: any) => {
													return {
														onClick: (event: any) => {
															if (maximoRowKey) {
																let selectedKognifaiKeys = [...kognifaiRowKeys];
																let foundIndex = selectedKognifaiKeys.findIndex(
																	(key: any) => record.Name === key
																);
																if (foundIndex !== -1) {
																	selectedKognifaiKeys.splice(foundIndex, 1);
																} else {
																	selectedKognifaiKeys.push(record.Name);
																}
																setKognifaiRowKeys(selectedKognifaiKeys);
																if (prevData) {
																	const array2Sorted = selectedKognifaiKeys
																		.slice()
																		.sort();

																	let check =
																		prevData.tags.length ===
																			selectedKognifaiKeys.length &&
																		prevData.tags
																			.slice()
																			.sort()
																			.every((value: any, index: any) => {
																				return value === array2Sorted[index];
																			});
																	setSaveDrawer(!check);
																} else if (
																	selectedKognifaiKeys.length === 0 &&
																	!prevData
																) {
																	setSaveDrawer(false);
																} else {
																	setSaveDrawer(true);
																}
															}
														},
													};
												}}
												rowSelection={{
													hideSelectAll: true,
													columnWidth: 50,
													type: undefined,

													onChange: (
														selectedRowKeys: React.Key[],
														selectedRows: any[]
													) => {
														setKognifaiRowKeys(selectedRowKeys);
														if (prevData) {
															const array2Sorted = selectedRowKeys
																.slice()
																.sort();

															let check =
																prevData.tags.length ===
																	selectedRowKeys.length &&
																prevData.tags
																	.slice()
																	.sort()
																	.every((value: any, index: any) => {
																		return value === array2Sorted[index];
																	});

															setSaveDrawer(!check);
														} else if (
															selectedRowKeys.length === 0 &&
															!prevData
														) {
															setSaveDrawer(false);
														} else {
															setSaveDrawer(true);
														}
													},
													getCheckboxProps: (record: any) => ({
														disabled: !maximoRowKey, // Column configuration not to be checked
														name: record.name,
													}),
													selectedRowKeys: kognifaiRowKeys,
												}}
												rowKey={"Name"}
												virtual
												className="white-table"
												rowClassName={"table-row-clickable"}
												columns={[
													{
														title: "Name",
														dataIndex: "subName",
														key: "name",
													},
													{
														title: "Description",
														dataIndex: "Description",
														key: "description",
													},
													{
														title: "Mapped",
														dataIndex: "asset_mapped",
														key: "asset_mapped",
														render: (text: any, record: any) => {
															return text
																? `${text} (${record?.asset_mapped_number})`
																: "-";
														},
													},
												]}
												pagination={false}
												dataSource={
													filteredKognifaiData
														? filteredKognifaiData
														: kognifaiData
												}
												scroll={tableHeight && { y: tableHeight }}
											></Table>
										</div>
									</div>
								</div>
								<div
									className="stats-container"
									style={{
										width: "10%",
										minWidth: "200px",
										gap: "16px",
										display: "flex",
										flexDirection: "column",
									}}
								>
									<div
										className="total-sensors"
										style={{
											padding: "16px",
											background: "#FFF",
											boxShadow:
												"0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(20, 62, 98, 0.15)",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												gap: "4px",
											}}
										>
											<span
												style={{
													fontSize: "14px",
													fontWeight: "600",
													lineHeight: "22px",
												}}
											>
												Total sensors
											</span>
											<span
												style={{
													padding: "2.5px 0px",
													display: "flex",
													justifyContent: "center",
													alignItems: "flex-start",
													fontSize: "38px",
													lineHeight: "46px",
												}}
											>
												{totalSensorCount.toString()}
											</span>
										</div>
									</div>
									<div
										className="mapped-sensors"
										style={{
											padding: "16px",
											background: "#FFF",
											boxShadow:
												"0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(20, 62, 98, 0.15)",
										}}
									>
										<span
											style={{
												fontSize: "14px",
												fontWeight: "600",
												lineHeight: "22px",
											}}
										>
											Mapped sensors
										</span>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												gap: "8px",
											}}
										>
											<span
												style={{
													display: "flex",
													padding: "2.5px 0px",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "flex-start",
													fontSize: "38px",
													lineHeight: "46px",
												}}
											>
												{((totalMappedCount / totalSensorCount) * 100)
													.toFixed(2)
													.toString() || "0"}
												%
											</span>
											<span style={{ fontSize: "14px", lineHeight: "22px" }}>
												{totalMappedCount.toString()}
											</span>
										</div>
									</div>
									<div
										className="leaderboard-container"
										style={{
											padding: "16px",
											background: "#FFF",
											flex: 1,
											boxShadow:
												"0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(20, 62, 98, 0.15)",
										}}
									>
										<div
											style={{
												display: "flex",

												flexDirection: "column",
												alignItems: "flex-start",
												gap: "16px",
											}}
										>
											<span
												style={{
													fontSize: "14px",
													fontWeight: "600",
													lineHeight: "22px",
												}}
											>
												Top 10
											</span>
											<div style={{ width: "100%" }}>
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}
												>
													<span>Name</span>
													<span>Mappings</span>
												</div>
												{renderTop10()}
											</div>
										</div>
									</div>
								</div>
							</>
						) : (
							<></>
							// <div
							// 	style={{
							// 		display: "flex",
							// 		alignItems: "center",
							// 		width: "100%",
							// 		flexDirection: "column",
							// 		justifyContent: "center",
							// 		paddingBottom: "60px",
							// 		gap: "24px",
							// 	}}
							// >
							// 	<div
							// 		style={{
							// 			fontSize: "86px",
							// 			color: "rgba(0,0,0,0.45)",
							// 			marginBottom: "-30px",
							// 		}}
							// 	>
							// 		{GetAntIcon("exclamation")}
							// 	</div>
							// 	<div
							// 		style={{
							// 			display: "flex",
							// 			flexDirection: "column",
							// 			justifyContent: "center",
							// 			alignItems: "center",
							// 			gap: "8px",
							// 			alignSelf: "stretch",
							// 		}}
							// 	>
							// 		<span
							// 			style={{
							// 				fontSize: "38px",
							// 				lineHeight: "40px",
							// 				color: "rgba(0,0,0,0.45)",
							// 			}}
							// 		>
							// 			Select vessel
							// 		</span>
							// 		<span
							// 			style={{
							// 				fontSize: "14px",
							// 				lineHeight: "22px",
							// 				color: "rgba(0,0,0,0.45)",
							// 			}}
							// 		>
							// 			Choose a vessel to start mapping the sensors
							// 		</span>
							// 	</div>
							// 	<Select
							// 		className="sensor-mapping-select"
							// 		popupClassName="sensor-mapping-select-popup"
							// 		placeholder="Select vessel..."
							// 		style={{ minWidth: "200px" }}
							// 		options={vesselList}
							// 		onChange={(value: any) => {
							// 			setCurrentVessel(value);
							// 		}}
							// 	></Select>
							// </div>
						)}
					</div>
				</div>
				<Drawer
					height={"64px"}
					mask={false}
					maskClosable={false}
					closable={false}
					placement={"bottom"}
					open={saveDrawer}
					styles={{
						body: {
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							alignItems: "center",
							padding: "16px 24px",
							gap: "16px",
							alignSelf: "stretch",
						},
					}}
				>
					<Button
						type={"primary"}
						onClick={() => {
							setLoading(true);
							if (!prevData) {
								let data: any = {};
								data.asset_name = maximoRowKey.name;
								data.asset_number = maximoRowKey.key;
								data.vessel_name = currentVessel;
								data.vessel_code = currentVesselCode;
								// data.tags = [...kognifaiRowKeys];
								data.tags = kognifaiRowKeys.map((element: any) => {
									return {
										name: element,
										by: props.user.name,
										email: props.user.preferred_username,
										last_update: Date.now(),
									};
								});
								data.last_update = Date.now();
								data.last_updated_by_email = props.user.preferred_username;
								data.last_updated_by = props.user.name;
								data.created_on = Date.now();
								data.author_email = props.user.preferred_username;
								data.author = props.user.name;
								addSensorMap(data)
									.then((res: any) => {
										Emitter.emit("alert", {
											type: "success",
											message: `Sensor mapping successfully saved`,
											description: "",
											top: false,
											closeable: false,
											timeout: 5000,
										});
									})
									.catch((error: any) => {
										Emitter.emit("alert", {
											type: "error",
											message: `Sensor mapping failed to save`,
											description: "",
											top: false,
											closeable: false,
											timeout: 5000,
										});
									})
									.finally(() => {
										setPrevData(data);
										handleRefresh(data);
										setSaveDrawer(false);
									});
							} else {
								let temp_data = kognifaiRowKeys.map((element: any) => {
									let found = prevData.tags.find((tag: any) => {
										return tag.name === element;
									});
									if (found) {
										return found;
									} else {
										return {
											name: element,
											by: props.user.name,
											email: props.user.preferred_username,
											last_update: Date.now(),
										};
									}
								});
								prevData.tags = temp_data;
								prevData.last_update = Date.now();
								prevData.last_updated_by_email = props.user.preferred_username;
								prevData.last_updated_by = props.user.name;
								updateSensorMap(currentVesselCode, maximoRowKey.key, prevData)
									.then((res: any) => {
										Emitter.emit("alert", {
											type: "success",
											message: `Sensor mapping successfully updated`,
											description: "",
											top: false,
											closeable: false,
											timeout: 5000,
										});
									})
									.catch((error: any) => {
										Emitter.emit("alert", {
											type: "error",
											message: `Sensor mapping failed to update`,
											description: "",
											top: false,
											closeable: false,
											timeout: 5000,
										});
									})
									.finally(() => {
										setSaveDrawer(false);
										handleRefresh(prevData);
									});
							}
						}}
					>
						Save
					</Button>
					<Button
						type={"default"}
						onClick={() => {
							if (prevData) {
								let tags = prevData.tags.map((element: any) => element.name);
								setKognifaiRowKeys(tags);
								setSaveDrawer(false);
							} else {
								setMaximoRowKey(null);
								setKognifaiRowKeys([]);
								setPrevData(null);
								setSaveDrawer(false);
							}
						}}
					>
						Cancel
					</Button>
				</Drawer>
			</Spin>
		</ConfigProvider>
	);
};

const mapStateToProps = (state: any) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(SensorMapping);
