import { Table, TableProps } from "antd";
import { useMemo } from "react";
import { useMainContext } from "../contexts/MainContext";
import { useDataExplorer } from "../contexts/DataExplorerContext";
import { useComponent } from "../contexts/ComponentContext";

interface SchemaTableProps extends TableProps<any> {}

const SchemaTable = ({ ...restProps }: SchemaTableProps) => {
	const { dispatch, state } = useDataExplorer();
	const { metadatas: tables } = useMainContext();
	const { schema_authorized } = useComponent();

	const onRow = (record: any) => {
		return {
			onClick: () => {
				dispatch({ type: "SCHEMA", payload: record.key });
			},
			style: { cursor: "pointer" },
		};
	};

	const dataSource: Array<any> = useMemo(() => {
		if (tables?.length === 0) return [];

		const schemas = tables
			?.map((tbl) => tbl?.schema)
			?.reduce((acc, curr) => {
				if (!acc.includes(curr)) acc.push(curr);
				return acc;
			}, [])
			?.filter((schema: string) => schema_authorized?.includes(schema))
			?.map((schema: any) => {
				return { schema, key: schema };
			});

		if (state.searchVal && state.searchVal !== "")
			return schemas?.filter((el: any) => el.schema.includes(state.searchVal));

		return schemas;
	}, [tables, state.searchVal, schema_authorized]);

	const columns = useMemo(() => {
		if (dataSource.length === 0) return [];
		return [{ dataIndex: "schema", title: "Schema", key: "schema" }];
	}, [dataSource]);

	return (
		<Table
			{...restProps}
			bordered
			rootClassName="schema-table"
			size="small"
			dataSource={dataSource}
			columns={columns}
			onRow={onRow}
		/>
	);
};

export default SchemaTable;
