import { Tenant } from "./_exports";
import Emitter from "../EventEmitter";
import { api } from "../../contexts/AuthContext";

//currently based on menuKey only, in the future will require component metadata
export const getVisuals = (menuKey: string) => {
	return new Promise((resolve, reject) => {
		// console.log(menuKey);
		api
			.get(`/visuals_v2/getVisuals`, { params: { menuKey } })
			.then((response) => {
				// console.log(response);
				resolve(response.data);
			})
			.catch((e: any) => {
				console.log(e);
				reject(e);
			});
	});
};

export const addVisual = (visual: any, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api
			.post(`/visuals_v2/addVisual`, { ...visual, menuKey })
			.then((response) => {
				Emitter.emit("VISUALS_UPDATED", response);
				resolve(response);
			})
			.catch((e: any) => {
				console.log("err", e);
				reject(e);
			});
	});
};

export const updateVisual = (visual: any, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api
			.put(`/visuals_v2/updateVisual`, visual)
			.then((response) => {
				Emitter.emit("VISUALS_UPDATED", response);
				resolve(response);
			})
			.catch((e: any) => {
				console.log("err", e);
				reject(e);
			});
	});
};

export const deleteVisual = (id: string, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api
			.delete(`/visuals_v2/deleteVisual`, {
				data: { id, tenantid: Tenant, menuKey },
			})
			.then((_data) => {
				Emitter.emit("VISUALS_UPDATED", _data);
				resolve(_data);
			})
			.catch((e: any) => {
				console.log("err", e);
				reject(e);
			});
	});
};
