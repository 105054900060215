import React from "react";
import { logout } from "../utils/utils";
import { connect } from "react-redux";

class Logout extends React.Component<any> {
	componentDidMount() {
		// if (
		// 	localStorage.getItem(`${Tenant}:idToken`) &&
		// 	localStorage.getItem(`${Tenant}:idToken`) !== undefined &&
		// 	localStorage.getItem(`${Tenant}:idToken`) !== "undefined"
		// ) {
		// if (localStorage.getItem(`${Tenant}:idToken`) === "1") {
		// 	localStorage.removeItem(`${Tenant}:idToken`);
		// 	window.location.href = `/${Tenant}`;
		// } else {
		this.props.dispatch({ type: "LOGOUT", payload: true });
		logout();
		// localStorage.removeItem(`${Tenant}:idToken`);
		// localStorage.removeItem(`${Tenant}:accessToken`);
		// localStorage.removeItem(`${Tenant}:pbiToken`);
		// }
		// } else {
		// this.props.dispatch({ type: "LOGOUT", payload: true });
		// logout();
		// localStorage.removeItem(`${Tenant}:idToken`);
		// localStorage.removeItem(`${Tenant}:accessToken`);
		// localStorage.removeItem(`${Tenant}:pbiToken`);
		// }
	}

	render() {
		return <></>;
	}
}
const mapStateToProps = (state: any) => {
	return {
		isLoggedIn: state.isLoggedIn,
		userToken: state.userToken,
		loginRegister: state.loginRegister,
	};
};

export default connect(mapStateToProps)(Logout);
