import React from "react";
import "../../assets/css/Admin.css";
import { connect } from "react-redux";
import {
	Select,
	Button,
	Input,
	Tag,
	Popconfirm,
	Typography,
	Space,
	Form,
	FormInstance,
} from "antd";
import { Tree } from "antd";
import { loadAdminmenu, saveAdminMenu } from "../../services/api-server/menu";
import { v4 as uuidv4 } from "uuid";
import { AdminComponentList } from "../../utils/components";
import { PlusOutlined } from "@ant-design/icons";
import { getAppData, getAppRoles } from "../../services/api-server/admin";
import {
	getMasterdataKeys,
	savePbiData,
} from "../../services/api-server/powerbi";
import Emitter from "../../services/EventEmitter";
import TextArea from "antd/lib/input/TextArea";
import NoAccess from "../NoAccess";
import { allowed } from "./Admin";
import TenantSettings from "./TenantSettings";
import { Tenant } from "../../services/api-server/_exports";

const { Option } = Select;
const { Text } = Typography;

const allowDuplicate: boolean = true;

const getAllRoutes: any = (menu: any) => {
	let routes: Array<any> = [];
	menu.forEach((menuItem: any) => {
		if (menuItem?.route?.link)
			routes.push({ key: menuItem.key, route: menuItem?.route?.link });

		if (menuItem?.children?.length) {
			let childRoutes: Array<any> = getAllRoutes(menuItem.children);
			routes.push(...childRoutes);
		}
	});

	return routes;
};

const getAllTitles: any = (menu: any) => {
	let titles: Array<any> = [];
	menu.forEach((menuItem: any) => {
		if (menuItem?.title)
			titles.push({ key: menuItem.key, title: menuItem.title });

		if (menuItem?.children?.length) {
			let childTitles: Array<string> = getAllTitles(menuItem.children);
			titles.push(...childTitles);
		}
	});

	return titles;
};

const validateRoute: any = (key: any, menu: any, route: string) => {
	let routeValid: boolean;
	let userInput: string = route.toLowerCase();

	let allRoutes: Array<any> = getAllRoutes(menu);
	let filteredRoutes: Array<any> = allRoutes.filter(
		(routeObject: any) => routeObject.key !== key
	);

	let found: number = filteredRoutes.findIndex((routeObject: any) => {
		let _route: string = routeObject.route.toLowerCase();
		return _route === userInput;
	});

	if (found !== -1) {
		routeValid = false;
	} else {
		routeValid = true;
	}

	return routeValid;
};

const validateTitle: any = (key: any, menu: any, title: string) => {
	let nameValid: boolean;
	let userInput: string = title.toLowerCase().trim();

	let allTitles: Array<any> = getAllTitles(menu);
	let filteredTitles: Array<any> = allTitles.filter(
		(titleObject: any) => titleObject.key !== key
	);

	let found: number = filteredTitles.findIndex((titleObject: any) => {
		let _title: string = titleObject.title.toLowerCase();
		// console.log({ userInput, _title });
		return _title === userInput;
	});

	// console.log(found);

	if (found !== -1 && !allowDuplicate) {
		nameValid = false;
	} else {
		nameValid = true;
	}

	return nameValid;
};

export const getNonDuplicateLabel = (key: string, menu: any) => {
	let menuTitle: string = "New Item";
	let result = menuTitle;
	let count: number = 1;

	let menuTitles: Array<string> = getAllTitles(menu);
	let filteredMenuTitles: any = menuTitles.filter(
		(titleObject: any) => titleObject.key !== key
	);

	while (
		filteredMenuTitles.find((menuItem: any) => menuItem.title === result)
	) {
		result = `${menuTitle}-${count}`;
		count++;
	}

	return result;
};

class AdminMenuManager extends React.Component<any> {
	state: any = {
		clean: true,
		allMenu: [],
		adminmenu: [],
		roles: [],
		savedMenu: {},
		selectedMenu: null,
		mainMenu: {},
		visibleModal: false,
		inputVisible: false,
		inputValue: "",
		editInputIndex: -1,
		editInputValue: "",
		dataKeys: [],
		appData: {},
	};

	constructor(props: any) {
		super(props);
		this.state.adminmenu = this.props.adminModules.adminmenu;
	}

	formRef = React.createRef<FormInstance>();

	componentDidMount() {
		this.loadData();
		this.getRoles();
		getAppData().then((res: any) => {
			this.setState({ appData: res.data });
		});
	}
	loadData = () => {
		loadAdminmenu().then((data: any) => {
			if (data === undefined) {
				data = { adminmenu: this.props.allModules.adminModules };
			}

			this.setState({ adminmenu: data.adminmenu });
		});
	};

	getRoles = () => {
		getAppRoles().then((data: any) => {
			if (data) {
				let adminarray: any = [];

				data.data.map((keyword: any) => {
					if (keyword.toLowerCase().includes("admin")) {
						adminarray.push(keyword);
					}
					return true;
				});
				this.setState({ roles: adminarray });
			}
		});
	};

	getDatafieldKeys = () => {
		const datasetid = this.state.selectedMenu?.datasetid;
		const tablename = this.state.selectedMenu?.tablename;

		if (datasetid && tablename) {
			this.setState({ loading: true });
			getMasterdataKeys(datasetid, tablename)
				.then((_data: any) => {
					this.setState({ dataKeys: _data.data });
					this.setState({ loading: false });
					alert("Datafield Keys loaded");
				})
				.catch((error) => {
					this.setState({ loading: false });
					alert(error.response.data.message.code);
				});
		} else {
			alert("Dataset ID and Dataset Table name must not be empty");
		}
	};

	cancelChanges = () => {
		const id = this.state.selectedMenu?._id;
		this.setState({ selectedMenu: null, new: false, clean: true });
		this.loadData();
		this.setRouteError(false);
		this.setTitleError(false);
	};

	saveChanges = () => {
		let payload: any = {};
		const _menu = this.state.selectedMenu;
		let wholemenu: any[] | null = this.state.adminmenu;
		const find_replace: any = (arr: any[] | null, key: string, obj: any) => {
			if (arr?.length) {
				return arr.map((o: any, index: number) => {
					if (o.children && o.children.length && o.children.length > 0) {
						//o.children = find_replace(o.children,key,obj)
						if (o.key === key) {
							return {
								...o,
								title: obj.title,
								description: obj.description,
								component: obj.component,
								datasetid: obj.datasetid,
								tablename: obj.tablename,
								table: obj.table,
								roles: obj.roles,
								key: obj.key,
								route: obj.route,
							};
						} else {
							let newmenuitem: any = {
								title: o.title,
								key: o.key,
								component: o.component,
								description: o.description,
								datasetid: o.datasetid,
								tablename: o.tablename,
								table: o.table,
								roles: o.roles,
								route: o.route,
								children: find_replace(o.children, key, obj),
							};
							return newmenuitem;
						}

						// return find_replace(o.children,key,obj)
					} else {
						if (o.key === key) {
							return {
								title: obj.title,
								description: obj.description,
								datasetid: obj.datasetid,
								table: obj.table,
								tablename: obj.tablename,
								key: obj.key,
								component: obj.component,
								roles: obj.roles,
								route: obj.route,
							};
						} else {
							return {
								title: o.title,
								description: o.description,
								datasetid: o.datasetid,
								table: o.table,
								key: o.key,
								tablename: o.tablename,
								component: o.component,
								roles: o.roles,
								route: o.route,
							};
						}
					}
				});
			} else {
				return null;
			}
		};
		if (_menu !== undefined && _menu !== null) {
			if (
				this.state.new === true &&
				wholemenu?.findIndex((element: any) => element.key === _menu.key) === -1
			) {
				wholemenu.push(_menu);
			} else {
				wholemenu = find_replace(wholemenu, _menu.key, _menu);
			}
		}

		if (_menu.component === "Admin Tables") {
			savePbiData(_menu.datasetid, _menu.tablename)
				.then((data: any) => {
					// console.log(data);
				})
				.catch((error) => {
					// console.error(error);
				});
		}

		saveAdminMenu(wholemenu).then((d: any) => {
			this.setState({ clean: true });
			this.setState({ selectedMenu: null });
			this.loadData();

			payload.type = "success";
			payload.message = "Admin Menu saved successfully";
			payload.description =
				"The menu has been saved, please refresh to view your changes";
			payload.timeout = 5000;
			Emitter.emit("Admin:MenuSaved", true);
			Emitter.emit("alert", payload);
		});
	};

	setSelectedModule = (key: any, info: any) => {
		if (info === "AdminConsole") {
			this.cancelChanges();
		}

		if (this.formRef?.current) {
			this.formRef?.current.resetFields();
			this.formRef?.current.setFieldsValue(info);
		}
		this.setState({ selectedMenu: info });
	};

	addNewMenuItem = () => {
		const uuid: string = uuidv4();
		const adminmenu = [...this.state.adminmenu];
		const menuTitle = getNonDuplicateLabel(uuid, adminmenu);

		const newItem = {
			title: menuTitle,
			key: uuid,
			role: "all",
			route: { link: "" },
		};
		adminmenu.push(newItem);
		this.formRef.current?.resetFields();
		this.formRef.current?.setFieldValue("label", newItem.title);

		this.setState({
			adminmenu: adminmenu,
			clean: false,
			selectedMenu: newItem,
			selectedMenuPath: "/",
			new: true,
		});
	};

	deleteMenuItem = () => {
		let menu = this.state.adminmenu;
		let selected_menuitem = this.state.selectedMenu;

		//console.log(menu)
		//console.log(selected_menuitem)
		const remove_menu = (menuitems: any, key: string) => {
			if (!menuitems) return;

			return menuitems.reduce((acc: any, child: any) => {
				if (child?.key === key) {
					const removedChild = remove_menu(child.children, key);
					acc.children = removedChild;
				} else {
					child.children = remove_menu(child.children, key);
					acc.push(child);
				}
				return acc;
			}, []);
		};

		menu = remove_menu(menu, selected_menuitem.key);
		this.setState({ adminmenu: menu });
		saveAdminMenu(menu).then((d: any) => {
			this.setState({ clean: true, selectedMenu: null, new: false });
			Emitter.emit("Admin:MenuSaved", true);
			this.loadData();
		});
	};
	onItemDrop = (info: any) => {
		const dropKey: any = info.node.key;
		const dragKey: any = info.dragNode.key;
		const dropPos: any = info.node.pos.split("-");
		const dropPosition =
			info.dropPosition - Number(dropPos[dropPos.length - 1]);
		// console.log(info);

		const loop = (data: any, key: any, callback: any) => {
			for (let i: number = 0; i < data.length; i++) {
				if (data[i].key === key) {
					return callback(data[i], i, data);
				}
			}
		};
		const data = [...this.state.adminmenu];

		let dragObj: any;
		if (info.dropToGap) {
			loop(data, dragKey, (item: any, index: any, arr: any) => {
				arr.splice(index, 1);
				dragObj = item;
			});
		}

		if (!info.dropToGap) {
			// console.log("Do not set children in Admin menu");
		} else if (
			(info.node.props.children || []).length > 0 &&
			info.node.props.expanded &&
			dropPosition === 1
		) {
			loop(data, dropKey, (item: any) => {
				item.children = item.children || [];
				item.children.unshift(dragObj);
			});
		} else {
			let ar: any;
			let i: any;
			loop(data, dropKey, (item: any, index: any, arr: any) => {
				ar = arr;
				i = index;
			});
			if (dropPosition === -1) {
				ar.splice(i, 0, dragObj);
			} else {
				ar.splice(i + 1, 0, dragObj);
			}
		}
		this.setState({ adminmenu: data });
		saveAdminMenu(data).then((_d: any) => {
			//console.log('//saved')
			loadAdminmenu().then((_d: any) => {
				Emitter.emit("Admin:MenuSaved", true);
				//console.log('reloaded')
			});
		});
	};

	setTitleError = (value: boolean) => {
		this.setState({ titleError: value });
	};

	setRouteError = (value: boolean) => {
		this.setState({ routeError: value });
	};

	render = () => {
		const data: any = this.state.selectedMenu;
		const adminmenu: any = this.state.adminmenu;

		const addNewTableParameters = () => {
			const add = () => {
				let data = this.state.selectedMenu;
				let table = data.table;
				if (table === undefined) {
					table = [];
				}
				table.push({ datafield_name: "", key: "", filter: "" });
				data.table = table;
				this.formRef?.current?.setFieldValue("tableparams", table);
				this.setState({ selectedMenu: data, clean: false });
			};
			return (
				<Button key={"addnewtable"} onClick={add}>
					Add New Table Parameters
				</Button>
			);
		};

		const deleteTableParams = (index: number) => {
			let data: any = this.state.selectedMenu;
			let tableparams: any[] = data?.table;
			//console.log(routeparams[index])
			tableparams.splice(index, 1);
			data.table = tableparams;
			this.setState({ selectedMenu: data, clean: false });
		};

		const updateTableParams = (index: number, key: string, value: string) => {
			let data: any = this.state.selectedMenu;
			let tableparams: any[] = data?.table;
			tableparams[index][`${key}`] = value;
			data.table = tableparams;
			this.setState({ selectedMenu: data, clean: false });
		};

		const getTableParameters = (s: any) => {
			const tableParams = this.state.selectedMenu?.table;

			if (
				tableParams !== undefined &&
				tableParams !== null &&
				tableParams.length > 0
			) {
				return (
					<Form.Item name={"table"} label="Table Parameters">
						<div className={"admin-layout-properties"}>
							<div>Datafield Name</div>
							<div>Datafield Key</div>
							<div>Filter Value</div>
							<div>#</div>
							{tableParams?.map((obj: any, index: number) => {
								return (
									<>
										<Form.Item
											name={["table", index, "datafield_name"]}
											style={{ marginBottom: 0 }}
										>
											<Input
												type="text"
												className={"input"}
												key={index}
												// value={obj.datafield_name}
												onChange={(v: any) =>
													updateTableParams(
														index,
														"datafield_name",
														v.target.value
													)
												}
											/>
										</Form.Item>
										<Form.Item
											name={["table", index, "key"]}
											style={{ marginBottom: 0 }}
										>
											<Select
												getPopupContainer={(trigger) => trigger.parentNode}
												className={"para-select"}
												// value={obj.key}
												onChange={(v: any) =>
													updateTableParams(index, "key", v)
												}
												showSearch
												optionFilterProp="children"
												filterOption={(input, option) => {
													return (option!.children as unknown as string)
														.toLowerCase()
														.includes(input.toLowerCase());
												}}
											>
												{this.state.dataKeys?.map((element: any) => {
													return (
														<Option key={element} value={element}>
															{element}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
										<Form.Item
											name={["table", index, "filter"]}
											style={{ marginBottom: 0 }}
										>
											<Input
												type="text"
												className={"input"}
												key={index}
												// value={obj.filter}
												onChange={(v: any) =>
													updateTableParams(index, "filter", v.target.value)
												}
											/>
										</Form.Item>
										<Form.Item>
											<Button onClick={() => deleteTableParams(index)}>
												Delete
											</Button>
										</Form.Item>
									</>
								);
							})}
						</div>
						<div>{addNewTableParameters()}</div>
					</Form.Item>
				);
			} else {
				return (
					<Form.Item name={"table"} label="Table Parameters">
						{addNewTableParameters()}
					</Form.Item>
				);
			}
		};

		const dataChanged = (l: string, v: any) => {
			this.setState({ clean: false });
			let data = this.state.selectedMenu;

			if (l === "title") {
				v = v.replace(/\s+/, " ");
				if (validateTitle(data.key, this.state.adminmenu, v)) {
					this.setTitleError(false);
				} else {
					this.setTitleError(true);
				}
			}

			data[`${l}`] = v;
			this.setState({ selectedMenu: data });
		};

		const addRole = (value: any) => {
			if (value !== "") {
				this.setState({ clean: false });
				let data = this.state.selectedMenu;
				if (data[`roles`]) {
					if (!data[`roles`].includes(value)) data[`roles`].push(value);
				} else {
					data.roles = [value];
				}
				this.setState({ selectedMenu: data, inputVisible: false });
			} else {
				this.setState({ inputVisible: false });
			}
		};

		const deleteRole = (index: any) => {
			this.setState({ clean: false });
			let data = this.state.selectedMenu;
			data[`roles`].splice(index, 1);
			this.setState({ selectedMenu: data });
		};

		const dataRouteChanged = (l: string, v: any) => {
			this.setState({ clean: false });
			let data = this.state.selectedMenu;
			if (data.route === undefined || data.route === null) {
				data.route = { link: "" };
			}

			let cleanedRoute: string = v
				.replace(" ", "-")
				.replace(/\-+/g, "-")
				.toLowerCase();
			if (cleanedRoute.length === 1) {
				cleanedRoute = cleanedRoute.replace(
					/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
					""
				);
			}

			if (validateRoute(data.key, this.state.adminmenu, cleanedRoute)) {
				this.setRouteError(false);
			} else {
				this.setRouteError(true);
			}

			data.route[`${l}`] = cleanedRoute;
			this.setState({ selectedMenu: data });
		};
		const confirmcancel = () => {
			return (
				<>
					{this.state.clean === false && (
						<Form.Item>
							<Space>
								<Button
									key={"confirmbutton"}
									className="confirm"
									disabled={this.state.routeError || this.state.titleError}
									htmlType="submit"
								>
									Confirm
								</Button>
								<Button
									key={"cancelbutton"}
									className="cancel"
									onClick={this.cancelChanges}
								>
									Cancel
								</Button>
							</Space>
						</Form.Item>
					)}
				</>
			);
		};

		const renderSelectedMenu = () => {
			return (
				<>
					{this.state.selectedMenu === null && (
						<p
							style={{
								textAlign: "center",
								fontSize: "50px",
								paddingTop: "20%",
							}}
						>
							Select the menu tabs to start editing
						</p>
					)}

					{this.state.selectedMenu === "AdminConsole" && (
						<div style={{ marginRight: "5%", display: "grid", gap: 20 }}>
							{Object.keys(this.state.appData).map((element, i) => {
								return (
									<Space direction="vertical">
										<div>{element}</div>
										<div>
											<Input
												key={i}
												type={"text"}
												value={this.state.appData[element]}
												className={"admin-console"}
												readOnly
											></Input>
										</div>
									</Space>
								);
							})}

							{allowed.includes(Tenant) && <TenantSettings />}
						</div>
					)}

					{this.state.selectedMenu !== "AdminConsole" && (
						<Form
							ref={this.formRef}
							style={{
								marginRight: "5%",
								display:
									this.state.selectedMenu !== "AdminConsole" &&
									this.state.selectedMenu === null
										? "none"
										: "inherit",
							}}
							layout="vertical"
							requiredMark={"optional"}
							onFinish={this.saveChanges}
						>
							<Form.Item
								name={"title"}
								label="Label"
								rules={[
									{ required: true, message: "Please enter a label" },
									{
										validator: (rule, value, callback) => {
											if (value && this.state.titleError)
												callback("Label must be unique");
											else callback();
										},
									},
								]}
							>
								<Input
									key={"title"}
									type={"text"}
									className={"input"}
									status={this.state.titleError ? "error" : ""}
									onChange={(ev: any) => {
										dataChanged("title", ev.target.value);
									}}
								/>
							</Form.Item>
							{this.state.selectedMenu?.component !== "MenuManager" ? (
								<Form.Item name={"description"} label="Description">
									<TextArea
										key={"description"}
										className={"para-input"}
										onChange={(ev: any) => {
											dataChanged("description", ev.target.value);
										}}
									/>
								</Form.Item>
							) : null}
							<Form.Item name={"roles"} label="Roles">
								<div className="input">
									{this.state.selectedMenu?.roles ? (
										this.state.selectedMenu?.roles.map(
											(tag: any, index: any) => {
												return (
													<Tag
														key={tag}
														closable={true}
														onClose={() => deleteRole(index)}
														style={{ fontSize: "14px" }}
													>
														{tag}
													</Tag>
												);
											}
										)
									) : (
										<></>
									)}
									{!this.state.inputVisible && (
										<Tag
											className="site-tag-plus"
											onClick={() => {
												this.setState({ inputVisible: "true" });
											}}
										>
											<PlusOutlined /> New Role
										</Tag>
									)}
									{this.state.inputVisible && (
										<Select
											getPopupContainer={(trigger) => trigger.parentNode}
											className="tag-select"
											value=""
											onChange={(ev: any) => addRole(ev)}
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) => {
												return (option!.children as unknown as string)
													.toLowerCase()
													.includes(input.toLowerCase());
											}}
										>
											{this.state.roles?.map((element: any) => {
												return (
													<Option key={element} value={element}>
														{element}
													</Option>
												);
											})}
										</Select>
									)}
								</div>
							</Form.Item>
							<Form.Item
								name={"component"}
								label="Component"
								rules={[
									{ required: true, message: "Please select a component" },
								]}
							>
								<Select
									className={"input"}
									showSearch
									optionFilterProp="children"
									getPopupContainer={(trigger) => trigger.parentNode}
									key={"component"}
									onChange={(ev: any) => dataChanged("component", ev)}
									filterOption={(input, option) => {
										return (option!.children as unknown as string)
											.toLowerCase()
											.includes(input.toLowerCase());
									}}
								>
									<Option key="None" value="None">
										None
									</Option>
									{AdminComponentList.map((element: any) => {
										return (
											<Option key={element} value={element}>
												{element}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							{this.state.selectedMenu?.component === "Admin Tables" ? (
								<>
									<Form.Item
										name={"datasetid"}
										label="Dataset ID (The dataset ID from PowerBI)"
										rules={[
											{
												required: true,
												message: "Please enter a dataset ID from PowerBI",
											},
										]}
									>
										<Input
											className={"input"}
											key={"datasetid"}
											type={"text"}
											onChange={(ev: any) => {
												dataChanged("datasetid", ev.target.value);
											}}
										/>
									</Form.Item>
									<Form.Item
										name={"tablename"}
										label="Dataset Table name (The table name based on the Dataset ID)"
										rules={[
											{
												required: true,
												message:
													"Please enter a table name based on the dataset id",
											},
										]}
									>
										<Input
											className={"input"}
											key={"tablename"}
											type={"text"}
											onChange={(ev: any) => {
												dataChanged("tablename", ev.target.value);
											}}
										/>
									</Form.Item>
									<Form.Item>
										<Button
											onClick={this.getDatafieldKeys}
											loading={this.state.loading}
										>
											Get Datafield Keys
										</Button>
									</Form.Item>
									{getTableParameters(data)}
								</>
							) : null}
							<Form.Item
								name={["route", "link"]}
								label="Route"
								rules={[
									{ required: true, message: "Please enter a route" },
									{
										validator: (rule, value, callback) => {
											if (value && this.state.routeError)
												callback("Route must be unique");
											else callback();
										},
									},
								]}
								normalize={(value: string) => {
									let cleanedRoute: string = value
										.replace(" ", "-")
										.replace(/\-+/g, "-");
									// checking for the first character of the route
									if (cleanedRoute.length === 1) {
										cleanedRoute = cleanedRoute.replace(
											/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
											""
										);
									}

									return cleanedRoute;
								}}
							>
								<Input
									className={"input"}
									key={"route.link"}
									type={"text"}
									status={this.state.routeError ? "error" : ""}
									onChange={(ev: any) => {
										dataRouteChanged("link", ev.target.value);
									}}
								/>
							</Form.Item>
							{confirmcancel()}
						</Form>
					)}
				</>
			);
		};

		return (
			<>
				{this.props.role?.includes(process.env.REACT_APP_SUPER_ADMIN_TAG) ? (
					<div className="admin-div">
						<div className="layout-side-list">
							<div className="menu-tree">
								<Tree
									style={{ background: "#fafafb" }}
									disabled={this.state.clean === false}
									draggable={true}
									showLine
									treeData={adminmenu}
									onDrop={this.onItemDrop}
									onClick={(ev: any) => {
										this.setState({ inputVisible: false });
									}}
									onSelect={(selected: any, info: any) => {
										if (info.selected) {
											this.setSelectedModule(selected[0], info.node);
										} else {
											this.setState({ selectedMenu: null });
										}
									}}
									selectedKeys={[this.state.selectedMenu?.key]}
								/>
								<Button
									style={{ width: "200px", marginTop: "5%" }}
									onClick={() => {
										this.addNewMenuItem();
									}}
									disabled={!this.state.clean}
								>
									Add New Menu Item
								</Button>
								<Popconfirm
									title={"Confirm deletion?"}
									placement={"bottom"}
									okText="Yes"
									cancelText="No"
									disabled={
										!this.state.selectedMenu
											? true
											: this.state.selectedMenu === "AdminConsole"
											? true
											: false
									}
									onConfirm={() => this.deleteMenuItem()}
								>
									<Button
										style={{ width: "200px" }}
										disabled={
											!this.state.selectedMenu
												? true
												: this.state.selectedMenu === "AdminConsole"
												? true
												: false
										}
									>
										Delete Menu Item
									</Button>
								</Popconfirm>
								<Button
									style={{ width: "200px", marginTop: "10%" }}
									onClick={() => {
										this.setSelectedModule("Admin", "AdminConsole");
									}}
									disabled={!this.state.clean}
								>
									Admin Console
								</Button>
							</div>
							<div className="admin-menu-configuration">
								{renderSelectedMenu()}
							</div>
						</div>
					</div>
				) : (
					<NoAccess />
				)}
			</>
		);
	};
}

const mapStateToProps = (state: any) => {
	return {
		role: state.role,
		allUsers: state.allUsers,
		allModules: state.allModules,
		adminModules: state.adminModules,
	};
};

export default connect(mapStateToProps)(AdminMenuManager);
