import { SelectProps } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import { Dictionary } from "lodash";

export const clauses = [
	"select",
	"where",
	"orderBy",
	"groupBy",
	"from",
	"limitHint",
];

export const operators = [
	{ label: "Equal to", value: "=" },
	{ label: "Greater than", value: ">" },
	{ label: "Less than", value: "<" },
	{ label: "Greater than or equal to", value: ">=" },
	{ label: "Less than or equal to", value: "<=" },
	{ label: "Not equal to", value: "<>" },
	{ label: "Is not null", value: "IS NOT NULL" },
	{ label: "Is null", value: "IS NULL" },
	{ label: "In", value: "IN" },
];

export const orders = [
	{ label: "Ascending", value: "ASC" },
	{ label: "Descending", value: "DESC" },
];

export const bitOperators = ["and", "or"];

export const joins = ["join", "left join", "right join", "full join"];

export const functions = [
	{ label: "Sum", value: "SUM" },
	{ label: "Count", value: "COUNT" },
	{ label: "Average", value: "AVG" },
	{ label: "Min", value: "MIN" },
	{ label: "Max", value: "MAX" },
];

export const commonSelectProps: SelectProps = {
	dropdownStyle: { zIndex: 30003 },
	popupMatchSelectWidth: false,
	popupClassName: "common-select-dropdown",
	showSearch: true,
	allowClear: true,
	suffixIcon: null,
	style: {
		width: "100%",
		overflow: "auto",
	},
};

// Adding a type would need to handle the type of component to render in QueryParameters.tsx
export enum ParameterType {
	DATE = "date",
	INPUT = "input",
}

// Add parameters here
export const parameters: { name: string; type: ParameterType }[] = [
	// { name: "vessel_id", type: ParameterType.INPUT },
	{ name: "start_date", type: ParameterType.DATE },
	{ name: "end_date", type: ParameterType.DATE },
];

export const initParameters: Dictionary<any> = {
	// vessel_id: [100],

	// ! previous day params
	// start_date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
	// end_date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
	// mode: "previous",
	// unit: "day",

	// ? month to date
	// start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
	start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
	end_date: dayjs().format("YYYY-MM-DD"),
	mode: "current",
	unit: "month",

	occurrences: 1,
	useTarget: true,
};

// Replaces params in the string query provided
export const replaceWithParams = (query: any, params: any) => {
	const replaceParams = (strQuery: string) => {
		let updatedQuery: string = strQuery;

		for (const field in params) {
			let cleandedParams = "";

			if (Array.isArray(params[field])) {
				cleandedParams = params[field]
					?.map((value: any) => `'${value}'`)
					?.join(", ");
			} else {
				cleandedParams = `${params[field]}`;
			}

			updatedQuery = updatedQuery.replaceAll(`@${field}`, cleandedParams);
		}

		return updatedQuery;
	};

	if (query) return JSON.parse(replaceParams(JSON.stringify(query)));

	return "";
};

// Generate completions to be used in codemirror editor
export const generateCompletions = (
	tables: Array<any>,
	metadatas: Array<any>
) => {
	const completions: Array<any> = [];

	metadatas?.forEach((table) => {
		completions.push(table?.name);
		table?.fields?.forEach((field: any) => {
			completions.push(field?.name);
		});
	});

	return _.uniq(completions)?.map((label) => ({ label, type: "constant" }));
};

export const checkTable = (updatedOptions: any) => {
	let queryOptions = {};
	const { from, limitHint, ...restProps } = updatedOptions;

	// storing the selected table names into an array for further usage
	const selectedTables: Array<any> = from?.tables
		?.filter((table: any) => table?.name)
		?.map((table: any) => table?.name);

	/**
	 * filtering column values based on the selected tables
	 * validating the left and right tables
	 * if the left/right table is not included in the selected tables
	 * left/right table and column will be reset
	 */
	const filteredColumns = Object.entries(restProps)?.map((entry) => {
		const [key, value]: any = entry;
		const { columns = [] } = value;

		const newColumns = columns?.map((col: any) => {
			const { id } = col;

			if (!selectedTables?.includes(col?.table?.name))
				if (col?.bitOp) {
					// handle where clause (custom)
					return { id, bitOp: col?.bitOp };
				} else {
					return { id };
				}

			return col;
		});

		return [key, { ...value, columns: newColumns }];
	});

	const columnsFromEntries = Object.fromEntries(filteredColumns);
	queryOptions = { ...updatedOptions, ...columnsFromEntries };

	return queryOptions;
};

// Logic to replace columns's table in all clauses when there there is only a single table
export const replaceTable = (options: any, allTables: Array<any>) => {
	const { from, limitHint, ...restProps } = options;
	let latest = {};
	if (from?.tables?.length === 1) {
		const tbl = allTables?.find(
			(tbl: any) => tbl?.name === from?.tables?.[0]?.name
		);

		for (const clause in restProps) {
			if (clause === "limitHint") return;
			latest = {
				...latest,
				[clause]: {
					...restProps?.[clause],
					columns: restProps?.[clause]?.columns?.map((col: any) => {
						return { ...col, table: tbl };
					}),
				},
			};
		}
		options = {
			...options,
			from,
			...latest,
		};
	}
	return options;
};

export const getErrorMessage = (required: Array<any>) => {
	let msg =
		"Please ensure that mandatory parameters are provided in the query:\n\n";

	msg = msg + required?.map((param: string) => `(${param})`)?.join("\n");

	msg =
		msg +
		"\n\n Make sure you have filled in all the required fields before proceeding.";

	return msg;
};

export const compareSelectOptions = (a: any, b: any) => {
	if (a.label < b.label) {
		return -1;
	} else if (a.label > b.label) {
		return 1;
	}
	// a must be equal to b
	return 0;
};
