import React from "react";
import { connect } from "react-redux";
import { Outlet, Link } from "react-router-dom";
import { loadUsers } from "../../services/api-server/admin";
import Emitter from "../../services/EventEmitter";
import { ConfigProvider, Menu, theme } from "antd";
import NoAccess from "../NoAccess";
import { loadAdminmenu } from "../../services/api-server/menu";

export const allowed: string[] = ["stenadrilling", "staging"];

class Admin extends React.Component<any, any> {
	props: any = {};

	constructor(props: any) {
		super(props);
		this.props = props;
		this.state = {
			href: window.location.href.split("/").pop(),
			adminmenu: this.props.adminModules.adminmenu,
		};
	}

	handleClick = (event: any) => {
		this.setState({ current: event.key });
	};

	windowpop = () => {
		this.setState({ href: window.location.href.split("/").pop() });
	};

	componentDidMount = () => {
		window.addEventListener("popstate", this.windowpop);
		Emitter.on("Admin:MenuSaved", () => {
			loadAdminmenu().then((adminmenu: any) => {
				this.setState({ adminmenu: adminmenu.adminmenu });
			});
		});
		loadUsers();
	};

	componentWillUnmount = () => {
		window.removeEventListener("popstate", this.windowpop);
		Emitter.emit("onpage", window.location.href.split("/").pop());
	};

	renderAdminMenu = () => {
		let adminmenu: any = this.props.adminModules?.adminmenu;

		if (adminmenu) {
			const _adminmenu = adminmenu;
			const pattern = /^[a-zA-Z0-9_-]+$/;
			let access: any = null;
			return _adminmenu.map((element: any) => {
				if (this.props.role.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
					access = true;
				} else if (element.roles?.length === 0 || !element.roles) {
					access = true;
				} else {
					element.roles?.map((element: any) => {
						if (this.props.role.includes(element)) {
							access = true;
							return true;
						}
						return null;
					});
				}
				return (
					<>
						{access &&
						element.route.link != "" &&
						pattern.test(element.route.link) ? (
							<Menu.Item
								className="admin-nav-item"
								key={element.route.link}
								onClick={this.sethref}
							>
								<Link
									to={element.route.link}
									state={{ params: { component: element.component } }}
								/>
								{element.title}
							</Menu.Item>
						) : null}
					</>
				);
			});
		}
	};

	sethref = () => {
		this.setState({ href: window.location.href.split("/").pop() });
	};

	render() {
		const checkRoles = () => {
			const roles = this.props.role;
			for (var i = 0; i < roles.length; i++) {
				if (roles[i].toLowerCase().includes("admin")) {
					return true;
				}
			}
		};

		return (
			<div style={{ height: "100%", overflow: "auto" }}>
				{checkRoles() ? (
					<div
						style={{ height: "100%", display: "flex", flexDirection: "column" }}
					>
						<div className="admin-nav-bar-container">
							<Menu
								className="admin-nav-bar"
								onClick={this.handleClick}
								selectedKeys={
									this.state.href
									// ["menu-manager"]
								}
								mode="horizontal"
							>
								{/* {this.props.role?.includes(
									process.env.REACT_APP_SUPER_ADMIN_TAG
								) ? (
									<Menu.Item
										className="admin-nav-item"
										key="admin-menu-manager"
										onClick={this.sethref}
									>
										<Link
											to="admin-menu-manager"
											state={{ params: { component: "Admin Menu Manager" } }}
										/>
										Admin Menu manager
									</Menu.Item>
								) : null} */}
								{this.props.adminModules?.adminmenu
									? this.renderAdminMenu()
									: null}
							</Menu>
						</div>
						<div
							className="admin-container"
							style={{
								backgroundColor: "#fafafb",
								flex: "1",
								overflow: "auto",
							}}
						>
							<ConfigProvider
								theme={{
									inherit: false,
									algorithm: theme.defaultAlgorithm,
								}}
							>
								<Outlet />
							</ConfigProvider>
						</div>
					</div>
				) : (
					<NoAccess />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		role: state.role,
		adminModules: state.adminModules,
	};
};

export default connect(mapStateToProps)(Admin);
