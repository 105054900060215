import { createContext, Dispatch, useContext } from "react";
import { DataExplorerAction, DataExplorerState } from "../reducers/dataExplorerReducer";

type DataExplorerContextProps = {
    state: DataExplorerState,
    dispatch: Dispatch<DataExplorerAction>
}

const DataExplorerContext = createContext<DataExplorerContextProps | undefined>(undefined)

type DataExplorerProviderProps = {
    children?: React.ReactNode,
    state: DataExplorerState,
    dispatch: Dispatch<DataExplorerAction>

}

const DataExplorerProvider = ({ children, state, dispatch }: DataExplorerProviderProps) => {
    return <DataExplorerContext.Provider value={{ state, dispatch }}>
        {children}
    </DataExplorerContext.Provider>
}

const useDataExplorer = () => {
    const context = useContext(DataExplorerContext)
    if (!context)
        throw new Error('useDataExplorer must be used within a DataExplorerProvider')

    return context
}


export { DataExplorerProvider, useDataExplorer }