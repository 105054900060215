export const initialState: any = {
	mainMenu: [
		{
			sequence: 0,
			key: "main_dashboard",
			component: "Map",
			requireAdmin: false,
			title: "Dashboard",
			icon: "dashboard",
			route: { link: "dashboard" },
			children: [],
		},
		{
			sequence: 2,
			key: "care",
			requireAdmin: false,
			title: "Care",
			icon: "user",
			route: { link: "care" },
			children: [
				{
					sequence: 0,
					key: "obs-daily-report",
					component: "PowerBI",
					requireAdmin: false,
					title: "Obs Daily Report",
					route: {
						link: "obs-daily-report",
						params: [
							{
								key: "workspaceid",
								value: "4f68105c-c34a-4ed8-a472-8c81397ad894",
							},
							{
								key: "reportid",
								value: "9e62b368-310a-4e09-908e-9b861a57581a",
							},
							// {workspaceid:'4f68105c-c34a-4ed8-a472-8c81397ad894', reportid:'9e62b368-310a-4e09-908e-9b861a57581a'}
						],
					},
				},
				{
					sequence: 1,
					key: "obs-analysis",
					component: "PowerBI",
					requireAdmin: false,
					title: "Obs Daily Analysis",
					route: {
						link: "obs-analysis",
						params: [
							{
								key: "workspaceid",
								value: "4f68105c-c34a-4ed8-a472-8c81397ad894",
							},
							{
								key: "reportid",
								value: "30d937c1-03d3-4a0e-b83c-82ed8f954950",
							},
							// {workspaceid:'4f68105c-c34a-4ed8-a472-8c81397ad894', reportid:'30d937c1-03d3-4a0e-b83c-82ed8f954950'}
						],
					},
				},
				{
					sequence: 3,
					key: "obs-archive",
					component: "PowerBI",
					requireAdmin: false,
					title: "Obs Archive",
					route: {
						link: "obs-archive",
						params: [
							{
								key: "workspaceid",
								value: "4f68105c-c34a-4ed8-a472-8c81397ad894",
							},
							{
								key: "reportid",
								value: "de14bfce-8d2e-4aee-a28c-25776d311e45",
							},
						],
					},
				},
			],
		},
		{
			sequence: 3,
			key: "innovation",
			component: "Coming_Soon",
			requireAdmin: false,
			title: "Innovation",
			icon: "team",
			children: [],
			route: { link: "innovation" },
		},
		{
			sequence: 4,
			key: "perform",
			component: "Coming_Soon",
			requireAdmin: false,
			title: "Performance",
			icon: "rocket",
			children: [],
			route: { link: "dpm-overview" },
		},
		{
			sequence: 5,
			key: "documents",
			component: "Documents",
			requireAdmin: false,
			icon: "file",
			title: "Documents",
			route: { link: "documents" },
		},
	],

	adminMenu: [
		{
			sequence: 0,
			menuid: "adminmenu00",
			clickable: false,
			requireAdmin: false,
			icon: "user",
			label: "",
			state: "Email",
		},
		// {
		// 	sequence: 1,
		// 	menuid: "darklight-switch",
		// 	requireAdmin: false,
		// 	icon: null,
		// 	label: "",
		// 	state: "theme-switch",
		// },
		{
			sequence: 2,
			menuid: "adminmenu01",
			requireAdmin: false,
			icon: "question",
			label: "Help",
			route: { link: "help" },
		},
		{
			sequence: 3,
			menuid: "adminmenu02",
			requireAdmin: false,
			icon: "file",
			label: "Release Notes",
			route: { link: "release" },
		},
		{
			sequence: 4,
			menuid: "adminmenu03",
			requireAdmin: true,
			icon: "settings",
			label: "Site Admin",
			route: { link: "admin" },
		},
		{
			sequence: 6,
			is_divider: true,
		},
		{
			sequence: 7,
			menuid: "adminmenu04",
			requireAdmin: false,
			icon: "logout",
			label: "Sign out",
			route: { link: "logout" },
		},
	],

	adminModules: [
		{
			sequence: 0,
			key: "menu manager",
			title: "Menu Manager",
			component: "Menu Manager",
			route: { link: "menu-manager" },
		},
		{
			sequence: 1,
			key: "uptime kuma",
			title: "Portal Systems Monitor",
			component: "Uptime Kuma",
			route: { link: "uptime-kuma" },
		},
	],
};
export const AllModules = (state: any = initialState, action: any) => {
	switch (action.type) {
		case "SET_ALL_MODULES":
			return action.payload;
		default:
			return state;
	}
};

export const AllComponents = (state: any = null, action: any) => {
	switch (action.type) {
		case "SET_AVAILABLE_COMPONENTS":
			return action.payload;
		default:
			return state;
	}
};

export const AdminModules = (
	state: any = initialState.adminModules,
	action: any
) => {
	switch (action.type) {
		case "SET_ADMIN_MODULE":
			return action.payload;
		default:
			return state;
	}
};

export const CurrentModule = (state: any = null, action: any) => {
	switch (action.type) {
		case "SET_MODULE":
			return action.payload;
		default:
			return state;
	}
};

export const CurrentComponent = (state: any = null, action: any) => {
	switch (action.type) {
		case "SET_COMPONENT":
			return action.payload;
		default:
			return state;
	}
};

export const SiderCollapse = (state: any = null, action: any) => {
	switch (action.type) {
		case "SET_COLLAPSE":
			return action.payload;
		default:
			return state;
	}
};

export const Documents = (state: any = null, action: any) => {
	switch (action.type) {
		case "DOCUMENTS":
			return action.payload;
		default:
			return state;
	}
};
