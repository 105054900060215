import { api } from "../../contexts/AuthContext";

export const getShares = () => {
	return new Promise((resolve, reject) => {
		api
			.get(`/deltashare/shares/`, {})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getSchemas = (share: string) => {
	return new Promise((resolve, reject) => {
		api
			.get(`/deltashare/schemas`, {
				params: {
					share,
				},
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getTables = (data: any) => {
	return new Promise((resolve, reject) => {
		api
			.get(`/deltashare/tables`, {
				params: {
					...data,
				},
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getAllTables = (share: string) => {
	return new Promise((resolve, reject) => {
		api
			.get(`/deltashare/alltables`, {
				params: {
					share,
					key: share,
				},
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getMetaData = (share: string, schema: string, table: string) => {
	return new Promise((resolve, reject) => {
		api
			.get(`/deltashare/metadata/`, {
				params: {
					share,
					schema,
					table,
					key: table,
				},
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getData = (table: string) => {
	return new Promise((resolve, reject) => {
		api
			.get(`/${table}/`, {})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getData2 = (
	share: string,
	schema: string,
	table: string,
	options: any
) => {
	return new Promise((resolve, reject) => {
		api
			.post(
				`/deltashare/query2?share=${share}&schema=${schema}&table=${table}`,
				options
			)
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

// For bypassing authorized checks, provide empty array for authorized datasets and true for bypass
export const duckQuery = (
	queryString?: string,
	authorized_datasets: Array<any> = [], // authorized datasets
	bypass: boolean = false // bypass option for non user query, e.g. hooks
) => {
	return new Promise((resolve, reject) => {
		api
			.post(`/deltashare/duckq`, {
				key: queryString,
				queryString,
				authorized_datasets,
				bypass,
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error?.response?.data || "There is an error running your query");
				console.log(
					error?.response?.data || "There is an error running your query"
				);
			});
	});
};
