import React, { useEffect, useState } from "react";

const useResizeObserver = (ref: React.MutableRefObject<null>) => {
	const [dimensions, setDimensions] = useState(null);

	useEffect(() => {
		const observeTarget: any = ref.current;

		if (!observeTarget) return;
		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				setDimensions(entry.contentRect as any);
			});
		});

		resizeObserver.observe(observeTarget);

		return () => {
			resizeObserver.unobserve(observeTarget);
		};
	}, [ref]);

	return dimensions;
};

export default useResizeObserver;
