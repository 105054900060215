import React, { useMemo } from "react";
import PropTypes from "prop-types";
import * as BABYLON from "@babylonjs/core";
import { GetAntIcon } from "../../utils/ant_icons";
import {
	Drawer,
	Input,
	Descriptions,
	Tree,
	Space,
	Typography,
	Switch,
	Divider,
	Empty,
} from "antd";

import { KeyboardSectionSVG, MouseSectionSVG } from "../../components/Svg";

const { Text } = Typography;
const { Search } = Input;
let defaultInterval: any = null;

const DigitalTwinLeftDrawer = ({
	scene,
	menuKey,
	setFPS,
	FPS,
	setCameraFov,
	cameraFov,
	setCameraSpeed,
	cameraSpeed,
	setCameraPosition,
	cameraPosition,
	setCameraTarget,
	cameraTarget,
	cameraRef,
	leftDrawer,
	isSearch,
	searchResults,
	expandedKeys,
	selectedKeys,
	selectValue,
	mqttList,
	options,
	cameraMode,
	assetTreedata,
	assetTreeRef,
	handleSearch,
	removeHighlightedAsset,
	AllMeshes,
	highlightedAssetRef,
	highlightLayer,
	pickables,
	engineerMesh,
	level1Ref,
	level2Ref,
	level3Ref,
	canvasRef,
	setExpandedKeys,
	setSelectedKeys,
	setSelectValue,
	setRightDrawerKey,
	flatAssetHierarchy,
	getAllKeys,
	setCurrentMaximoKey,
	setCurrentMaximoKeyList,
	setFSModal,
	resetMaximoStates,
	setRightDrawer,
	rightDrawerKeyRef,
	setFSMessage,
	oceanRef,
	skyRef,
	getAssetData,
	setMaximoLoading,
	setMaximoView,
	isFocusedRef,
	abortControllerRef,
	activeCameraRef,
	previousPosition,
	canvas,
	setSearchValue,
	setSearchResults,
	setIsSearch,
	focusMesh,
	LoadComponent,
	pobTreeData,
	pobTreeRef,
	setRightDrawerData,
	lowFPSCounter,
	setOptions,
	changeCamera,
	OpenCloseLeftDrawer,
	Inspector,
	isInspectorOpenRef,
	setMenuKey,
	menuKeyRef,
}: any) => {
	const handleSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
		const value = ev.target.value;
		setSearchValue(ev.target.value);
		if (value === "" && isSearch) {
			setSearchResults([]);
			setIsSearch(false);
			setExpandedKeys([]);
		}
	};

	// Handling asset tree select for maximo assets
	const handleAssetTreeSelect = (keys: any, node: any) => {
		const regex = /P\.(\d+)/;
		const regex2 = /\((\d+)\)/;
		canvasRef.current.focus();
		if (keys.length > 0) {
			let key = keys[0];

			setSelectedKeys(keys);
			setSelectValue(null);
			setRightDrawerKey("Asset");
			const flat = flatAssetHierarchy.current.find(
				(asset: any) => asset.key === key
			);
			const allKeys = getAllKeys(flat, key);
			setCurrentMaximoKey(key);
			setCurrentMaximoKeyList(allKeys);

			if (node.assetExist) {
				setFSModal(false);
				if (key) {
					const check = isNaN(parseInt(key.charAt(key.length - 1)));
					if (check) {
						key = key.slice(0, -1);
					}
					const treePos = node.treePos.split("-");
					const level: any = node.level;

					if (level == 1 || !node.isEngineerAsset) {
						if (engineerMesh.current.length > 0) {
							engineerMesh.current.map((element: any) => {
								highlightLayer.removeExcludedMesh(element);
								highlightLayer.removeMesh(element);
								element.dispose();
							});
							engineerMesh.current = [];
							resetMaximoStates();

							scene.activeCamera.detachControl();
							scene.activeCamera = activeCameraRef.current;
							scene.activeCamera.attachControl(canvas, false);

							scene.activeCamera.position = previousPosition.current.position;
							scene.activeCamera.target = previousPosition.current.target;
							level3Ref.current = null;
							level2Ref.current = null;
						}

						AllMeshes.current.map((element: any) => {
							element.setEnabled(false);
						});

						resetMaximoStates();
						isFocusedRef.current = true;
						oceanRef.current.isVisible = false;
						skyRef.current.isVisible = false;

						let meshes = AllMeshes.current.filter((element: any) => {
							const match = element?.name?.match(regex);
							const match2 = element?.name?.match(regex2);
							let assetnum = match?.[1] || match2?.[1] || "";
							return allKeys.includes(assetnum);
							// return element.name.includes(`(${node.key})`);
						});
						if (meshes.length > 0) {
							meshes.forEach((element: any) => {
								element.setEnabled(true);
							});
							focusMesh(meshes, scene.activeCamera);
						}
						setRightDrawer(true);
						rightDrawerKeyRef.current = "Asset";
						setMaximoLoading(true);
						setMaximoView(true);
						getAssetData(key, allKeys);
					}
					// Check if pickables or not
					else if (level > 1) {
						let isEngineer: boolean = false;
						let pickablesFound = pickables.current?.filter((element: any) => {
							const match = element?.name?.match(regex);
							let assetnum = match?.[1] || "";
							return allKeys.includes(assetnum);
							// return element.name.includes(key);
						});
						AllMeshes.current.map((element: any) => {
							element.setEnabled(false);
						});
						let meshes = AllMeshes.current.filter((element: any) => {
							const match = element?.name?.match(regex);
							let assetnum = match?.[1] || "";
							return allKeys.includes(assetnum);
						});
						let parentAssetKey: any = null;

						if (engineerMesh.current.length > 0 && pickablesFound.length == 0) {
							pickablesFound = engineerMesh.current.filter((element: any) => {
								return element.name.includes(key);
							});
							if (pickablesFound.length > 0) isEngineer = true;
						}
						if (pickablesFound.length == 0 && isEngineer === false) {
							parentAssetKey = treePos[treePos.length - 2];
							pickablesFound = pickables.current?.filter((element: any) => {
								return element.name.includes(parentAssetKey);
							});
						}

						if (pickablesFound.length > 0) {
							if (!isEngineer) {
								if (pickablesFound[0].name === level2Ref.current) {
									// console.log("This is true)");
									setRightDrawer(true);
									rightDrawerKeyRef.current = "Asset";
									setMaximoLoading(true);
									setMaximoView(true);
									getAssetData(key, allKeys);

									if (level3Ref.current) {
										// level3Ref.current.renderOutline = false;
										highlightLayer.addExcludedMesh(level3Ref.current);
										level3Ref.current = null;
									}
									highlightedAssetRef.current = [];

									engineerMesh.current.map((element: any) => {
										element.visibility = 1;
									});
								} else {
									engineerMesh.current.map((element: any) => {
										highlightLayer.removeExcludedMesh(element);
										highlightLayer.removeMesh(element);
										element.dispose();
									});
									engineerMesh.current = [];
									resetMaximoStates();
									level3Ref.current = null;
									level2Ref.current = null;
									const regex = /\([^)]*\)/;

									// Replace the matched text with an empty string
									const resultString = pickablesFound[0].name.replace(
										regex,
										""
									);

									if (parentAssetKey) {
										LoadComponent(scene, resultString, key);
									} else {
										LoadComponent(scene, resultString);
										setRightDrawer(true);
										rightDrawerKeyRef.current = "Asset";
										setMaximoLoading(true);
										setMaximoView(true);
										// getAssetData(key);
									}
								}
							} else {
								if (level3Ref.current != pickablesFound[0]) {
									isFocusedRef.current = true;
									setRightDrawer(true);
									rightDrawerKeyRef.current = "Asset";
									setMaximoLoading(true);
									setMaximoView(true);
									getAssetData(key, allKeys);
									engineerMesh.current.forEach((mesh: any) => {
										mesh.visibility = 0.03;
									});
									if (level3Ref.current) {
										// level3Ref.current.renderOutline = false;
										highlightLayer.addExcludedMesh(level3Ref.current);
									}

									highlightedAssetRef.current = [];
									highlightLayer.removeMesh(pickablesFound[0]);
									highlightLayer.addMesh(
										pickablesFound[0],
										BABYLON.Color3.FromHexString("#7DF9FF")
									);
									level3Ref.current = pickablesFound[0];
									highlightLayer.removeExcludedMesh(pickablesFound[0]);
									pickablesFound[0].visibility = 1;
								}
							}
						}
					}
				} else {
					scene.activeCamera.detachControl();
					scene.activeCamera = cameraRef.current;
					//console.log(cameraRef.current);
					scene.activeCamera.attachControl(canvas, false);
					AllMeshes.current.map((element: any) => {
						element.setEnabled(true);
					});
					if (engineerMesh.current.length > 0) {
						engineerMesh.current.map((element: any) => {
							element.dispose();
						});
						engineerMesh.current = [];
					}

					resetMaximoStates();
					setMaximoView(false);
					isFocusedRef.current = false;
					setRightDrawer(false);

					setSelectedKeys([]);
					setSelectValue(null);
					oceanRef.current.isVisible = true;
					skyRef.current.isVisible = true;
					level2Ref.current = null;
					level3Ref.current = null;
					level1Ref.current = null;
				}
			} else {
				resetMaximoStates();
				setRightDrawer(false);
				rightDrawerKeyRef.current = "";
				const message = `${node.fullName}`;
				setFSMessage(message);
				setFSModal(true);

				//V2
				oceanRef.current.isVisible = false;
				skyRef.current.isVisible = false;
				AllMeshes.current.forEach((element: any) => {
					element.setEnabled(false);
				});

				if (engineerMesh.current?.length > 0) {
					engineerMesh.current.forEach((element: any) => {
						highlightLayer.removeExcludedMesh(element);
						highlightLayer.removeMesh(element);
						element.dispose();
					});
					engineerMesh.current = [];
					level2Ref.current = null;
				}

				getAssetData(key, allKeys);
				setRightDrawer(true);
				setMaximoLoading(true);
				setMaximoView(true);

				//Remove references
				level3Ref.current = null;
				level1Ref.current = null;
				isFocusedRef.current = true;
			}
		} else {
			abortControllerRef?.current?.abort();
			setSelectedKeys([]);
			setRightDrawer(false);
			setRightDrawerKey("");
		}
	};

	const getFormattedTitle = (
		type: string,
		level: number,
		node: any,
		exist: boolean,
		ellipsis: boolean = true
	) => {
		const setIcon = (key: any, level: any) => {
			switch (key) {
				case "Asset":
					switch (level) {
						case 1:
							return (
								<span style={{ fontSize: "12px" }}>{GetAntIcon("expand")}</span>
							);

						case 2:
							return (
								<span style={{ fontSize: "10px" }}>
									{GetAntIcon("compress")}
								</span>
							);

						case 3:
							return (
								<span style={{ fontSize: "10px" }}>{GetAntIcon("dash")}</span>
							);
						case 4:
							return (
								<span style={{ fontSize: "10px" }}>
									{GetAntIcon("small-dash")}
								</span>
							);
					}
					break;
				case "Crew":
					switch (level) {
						case 1:
							return (
								<span style={{ fontSize: "12px" }}>{GetAntIcon("group")}</span>
							);

						case 2:
							return (
								<span style={{ fontSize: "10px" }}>
									{GetAntIcon("ungroup")}
								</span>
							);

						case 3:
							return (
								<span style={{ fontSize: "10px" }}>{GetAntIcon("user")}</span>
							);
					}
					break;
			}
		};
		const { cleanedTitle = "", key = "" } = node;
		return (
			<Space id={key} direction="horizontal">
				<div>{setIcon(type, level)}</div>
				<Space
					direction="vertical"
					title=""
					style={exist ? {} : { opacity: "0.3" }}
					styles={{ item: { marginBottom: "-3px" } }}
					size={0}
				>
					{type === "Asset" && (
						<Text
							className="digitaltwin-asset-tree-number"
							style={{ fontSize: 12, color: "#FFFFFF73" }}
						>
							{key}
						</Text>
					)}
					<Text
						title=""
						ellipsis={{
							onEllipsis: () => {},
							tooltip: <span>{cleanedTitle}</span>,
						}}
						style={
							type === "Crew" && node.full_name === "-"
								? ellipsis
									? {
											color: "#FF2D2D",
											width: `calc(280px - ${Number(level - 1) * 10}px)`,
											whiteSpace: "nowrap",
											textOverflow: "ellipsis",
											overflow: "hidden",
											display: "inherit",
									  }
									: {
											color: "#FF2D2D",
											display: "inherit",
											width: `calc(280px - ${Number(level - 1) * 10}px)`,
											maxWidth: "280px",
									  }
								: ellipsis
								? {
										color: "#FFFFFFA6",
										width: `calc(280px - ${Number(level - 1) * 12}px)`,
										whiteSpace: "nowrap",
										textOverflow: "ellipsis",
										overflow: "hidden",
										display: "inherit",
								  }
								: {
										color: "#FFFFFFA6",
										display: "inherit",
										width: `calc(280px - ${Number(level - 1) * 12}px)`,
										maxWidth: "280px",
								  }
						}
					>
						{cleanedTitle}
						<span>
							{node.childrenCount > 0 && type === "Crew"
								? `(${node.childrenCount})`
								: null}
						</span>
					</Text>
				</Space>
			</Space>
		);
	};

	const DrawerMemo: any = useMemo(() => {
		const setTitle = () => {
			switch (menuKey) {
				case "Asset":
					return "Asset";
				case "Crew":
					return "Crew";
				case "Help":
					return "Navigate the 3D Model";
				default:
					return menuKey;
			}
		};

		const setContent = () => {
			clearInterval(defaultInterval);
			switch (menuKey) {
				case "Help":
					defaultInterval = setInterval(() => {
						setFPS(scene.getEngine().getFps().toFixed());
						if (cameraRef.current) {
							setCameraFov(cameraRef.current.fov);
							setCameraSpeed(cameraRef.current.speed);
							setCameraPosition(
								new BABYLON.Vector3(
									Math.round(cameraRef.current.position._x * 100) / 100,
									Math.round(cameraRef.current.position._y * 100) / 100,
									Math.round(cameraRef.current.position._z * 100) / 100
								)
							);
							setCameraTarget(
								new BABYLON.Vector3(
									Math.round(cameraRef.current.target._x * 100) / 100,
									Math.round(cameraRef.current.target._y * 100) / 100,
									Math.round(cameraRef.current.target._z * 100) / 100
								)
							);
						}
					}, 1000);
					return (
						<div style={{ padding: "15px" }}>
							<MouseSectionSVG />
							<Divider />
							<KeyboardSectionSVG />
							<Divider />
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									gap: "10px",
									color: "rgba(255, 255, 255, 0.85)",
								}}
							>
								<span style={{ fontSize: "15px" }}>Statistics</span>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "10px",
										fontSize: "14px",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<div>FPS :</div>
										<div>{FPS}</div>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<div>Camera Speed :</div>
										<div>{cameraSpeed}</div>
									</div>{" "}
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<div>Camera FOV :</div>
										<div> {cameraFov}</div>
									</div>{" "}
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<div>Camera Position :</div>
										<div>
											{" "}
											{cameraPosition?._x}, {cameraPosition?._y},{" "}
											{cameraPosition?._z}
										</div>
									</div>{" "}
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<div>Camera Target :</div>
										<div>
											{" "}
											{cameraTarget?._x}, {cameraTarget?._y}, {cameraTarget?._z}
										</div>
									</div>
								</div>
							</div>
						</div>
					);

				case "Asset":
					return (
						<Space
							direction="vertical"
							style={{ height: "100%", width: "100%", overflow: "hidden" }}
							className="babylon-asset-body"
							styles={{
								item: {
									height: "fit-content",
									maxHeight: "calc(100% - 32px)",
								},
							}}
							size={0}
						>
							<Search
								className="grey-search-bar"
								placeholder="Search for asset key or name..."
								key={"asset-searchbar"}
								onSearch={(value: any) => handleSearch(value + "_Asset")}
								onChange={handleSearchChange}
								allowClear
							/>

							{!isSearch || (isSearch && searchResults.length !== 0) ? (
								<Tree
									treeData={
										isSearch && searchResults.length > 0
											? searchResults
											: assetTreedata
									}
									titleRender={(node: any) => {
										const level: any = node.level;
										return getFormattedTitle(
											"Asset",
											level,
											node,
											node.assetExist
										);
									}}
									className="babylon-asset-tree"
									virtual={true}
									motion={false}
									ref={assetTreeRef}
									selectedKeys={selectedKeys}
									expandedKeys={expandedKeys}
									onMouseEnter={(info: any) => {
										let key = info.node.key;
										const check = isNaN(parseInt(key.charAt(key.length - 1)));
										if (check) {
											key = key.slice(0, -1);
										}
										const level = info.node.pos.split("-").length - 1;
										if (level == 1 && info.node.assetExist) {
											let meshes = AllMeshes.current.filter((element: any) => {
												return element.name.includes(`(${info.node.key})`);
											});

											if (meshes.length > 0) {
												if (highlightedAssetRef?.current.length > 0) {
													removeHighlightedAsset();
												}
												meshes.forEach((element: any) => {
													element.removeLODLevel(null);
													highlightLayer.removeExcludedMesh(element);
													highlightLayer.addMesh(
														element,
														BABYLON.Color3.FromHexString("#7DF9FF")
													);
												});

												// meshes[0].renderOutline = true;
												highlightedAssetRef.current = meshes;
											}
										}
										if (level == 2 || level == 3) {
											let isEngineer = false;
											let meshes = pickables.current?.filter((element: any) => {
												return element.name.includes(key);
											});

											if (meshes.length === 0) {
												meshes = engineerMesh.current.filter((element: any) => {
													return element.name.includes(key);
												});
												if (meshes.length > 0) isEngineer = true;
											}

											if (meshes.length > 0) {
												if (
													!highlightedAssetRef.current[0]?.name.includes(
														meshes[0]
													) &&
													meshes[0] != level3Ref.current
												) {
													if (highlightedAssetRef?.current.length > 0) {
														removeHighlightedAsset();
													}
													meshes.forEach((element: any) => {
														highlightLayer.removeExcludedMesh(element);
														highlightLayer.addMesh(
															element,
															BABYLON.Color3.FromHexString("#7DF9FF")
														);
													});

													// meshes[0].renderOutline = true;
													highlightedAssetRef.current = meshes;
												}
											}
										}
									}}
									onMouseLeave={() => {
										removeHighlightedAsset();
									}}
									onSelect={(keys: any, info: any) => {
										handleAssetTreeSelect(keys, info.node);
									}}
									onExpand={(keys: any, info: any) => {
										canvasRef.current.focus();
										if (info.expanded === false) {
											setExpandedKeys(
												keys.filter(
													(item: any) => !item.includes(info.node.key)
												)
											);
										} else {
											setExpandedKeys(keys);
										}
									}}
									rootClassName="digital-twin-asset-tree"
									style={{
										color: "rgba(255, 255, 255, 0.85)",
										marginTop: "8px",
									}}
									rootStyle={{ background: "none" }}
								/>
							) : (
								<Empty />
							)}
						</Space>
					);
				case "Crew":
					return (
						<Space
							direction="vertical"
							style={{ height: "100%", width: "100%", overflow: "hidden" }}
							className="babylon-asset-body"
							styles={{
								item: {
									height: "fit-content",
									maxHeight: "calc(100% - 32px)",
								},
							}}
							size={0}
						>
							<Search
								className="grey-search-bar"
								placeholder="Search for name or role..."
								key={"crew-searchBar"}
								onSearch={(value: any) => handleSearch(value + "_Crew")}
								onChange={handleSearchChange}
								allowClear
							/>

							{pobTreeData &&
							(!isSearch || (isSearch && searchResults.length !== 0)) ? (
								<Tree
									treeData={
										isSearch && searchResults.length > 0
											? searchResults
											: pobTreeData
									}
									titleRender={(node: any) => {
										const level: any = node.level;
										return getFormattedTitle("Crew", level, node, true, false);
									}}
									className="babylon-asset-tree"
									virtual={true}
									motion={false}
									ref={pobTreeRef}
									selectedKeys={selectedKeys}
									expandedKeys={expandedKeys}
									onExpand={(keys: any) => {
										setExpandedKeys(keys);
										canvasRef.current.focus();
									}}
									onSelect={(keys: any, info: any) => {
										canvasRef.current.focus();
										setRightDrawer(info.selected);
										rightDrawerKeyRef.current = "Crew";
										setSelectedKeys(keys);
										setRightDrawerKey("Crew");
										setRightDrawerData(info.node);
										resetMaximoStates();
										setMaximoView(false);
									}}
									rootClassName="digital-twin-asset-tree"
									style={{
										color: "rgba(255, 255, 255, 0.85)",
										marginTop: "8px",
									}}
									rootStyle={{ background: "none" }}
								/>
							) : (
								<Empty style={{ marginTop: "20px" }} />
							)}
						</Space>
					);

				case "MQTT":
					return (
						<div>
							<Descriptions layout="vertical" bordered>
								{mqttList.map((element: any) => {
									return (
										<Descriptions.Item span={40} label={element.Name}>
											{element.Value}
										</Descriptions.Item>
									);
								})}
							</Descriptions>
						</div>
					);
				case "Settings":
					const disable =
						level1Ref.current || level2Ref.current || level3Ref.current;
					return (
						<>
							<div style={{ padding: "15px" }}>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "10px",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "3px",
										}}
									>
										<Text style={{ alignSelf: "start", fontSize: 15 }}>
											Scene elements
										</Text>
										<Text
											style={{
												alignSelf: "start",
												fontSize: 13,
												color: "grey",
											}}
										>
											Customise your scene with a simple toggle
										</Text>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "10px",
										}}
									>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<Text
												style={{
													alignSelf: "start",
													fontSize: 13,
													color: "grey",
												}}
											>
												Elements
											</Text>
											<Text
												style={{
													alignSelf: "start",
													fontSize: 13,
													color: "grey",
												}}
											>
												On/Off
											</Text>
										</div>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											Pipes mesh :
											<Switch
												disabled={lowFPSCounter >= 10}
												defaultChecked={false}
												checked={options.enablePipes}
												onChange={(boolean: any) => {
													// console.log(boolean);
													setOptions({ ...options, enablePipes: boolean });
												}}
											/>
										</div>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											Sky :
											<Switch
												disabled={lowFPSCounter >= 10}
												checked={options.enableSky}
												defaultChecked={true}
												onChange={(boolean: any) => {
													// console.log(boolean);
													setOptions({ ...options, enableSky: boolean });
												}}
											/>
										</div>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											Sea :
											<Switch
												disabled={lowFPSCounter >= 10}
												checked={options.enableOcean}
												defaultChecked={true}
												onChange={(boolean: any) => {
													setOptions({ ...options, enableOcean: boolean });
												}}
											/>
										</div>
									</div>
								</div>

								<Divider />
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "10px",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "3px",
										}}
									>
										<Text style={{ alignSelf: "start", fontSize: 15 }}>
											Display
										</Text>

										<Text
											style={{
												alignSelf: "start",
												fontSize: 13,
												color: "grey",
											}}
										>
											Choose how you interact with the model
										</Text>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											gap: "10px",
										}}
									>
										<div
											className={`digital-twin-camera-button ${
												cameraMode === "1" &&
												"digital-twin-camera-button-active"
											} ${
												isFocusedRef.current &&
												"digital-twin-camera-button-disabled"
											}`}
											onClick={() => {
												if (!disable && !isFocusedRef.current)
													changeCamera("1");
											}}
										>
											<div style={{ fontSize: "25px", margin: "-5px" }}>
												{GetAntIcon("eye")}
											</div>
											<span>POV</span>
										</div>
										<div
											className={`digital-twin-camera-button ${
												cameraMode === "2" &&
												"digital-twin-camera-button-active"
											} ${
												isFocusedRef.current &&
												"digital-twin-camera-button-disabled"
											}`}
											onClick={() => {
												if (!disable && !isFocusedRef.current)
													changeCamera("2");
											}}
										>
											<div style={{ fontSize: "25px", margin: "-5px" }}>
												{GetAntIcon("desktop")}
											</div>
											<span>Presentation</span>
										</div>
									</div>
								</div>
							</div>
						</>
					);
				default:
					return <div>{menuKey} Content</div>;
			}
		};
		return (
			<Drawer
				styles={{
					header: {
						padding: "8px 16px",
						fontSize: "14px",
						background: "#141414",
					},
					body: { padding: "0px 0px 12px 0px" },
				}}
				open={leftDrawer}
				closeIcon={GetAntIcon("leftarrow")}
				width={350}
				placement="left"
				className={`digitaltwin-drawer-wrapper`}
				onClose={() => {
					canvasRef.current.focus();
					if (
						rightDrawerKeyRef.current === menuKey ||
						["Help", "Settings"].includes(menuKey)
					) {
					} else {
						setRightDrawer(false);
						setSelectedKeys([]);
						if (engineerMesh.current.length > 0) {
							engineerMesh.current.map((element: any) => {
								highlightLayer.removeExcludedMesh(element);
								highlightLayer.removeMesh(element);
								element.dispose();
							});
							engineerMesh.current = [];
							resetMaximoStates();
							AllMeshes.current.map((element: any) => {
								element.setEnabled(true);
							});
							scene.activeCamera.detachControl();

							scene.activeCamera = activeCameraRef.current;
							scene.activeCamera.attachControl(canvas, false);

							scene.activeCamera.position = previousPosition.current.position;
							scene.activeCamera.target = previousPosition.current.target;
							level3Ref.current = null;
							level2Ref.current = null;

							resetMaximoStates();
							isFocusedRef.current = false;
							oceanRef.current.isVisible = true;
							skyRef.current.isVisible = true;
						}
					}

					OpenCloseLeftDrawer(false);
					Inspector.Hide();
					isInspectorOpenRef.current = false;
					setTimeout(() => {
						setIsSearch(false);
						setSearchResults([]);
						setSearchValue("");
						setMenuKey(null);
						menuKeyRef.current = "";
						setExpandedKeys([]);
					}, 100);
				}}
				getContainer={false}
				mask={false}
				maskClosable={false}
				destroyOnClose={false}
				closable={true}
				title={setTitle()}
				style={{ position: "absolute" }}
				afterOpenChange={() => canvasRef.current.focus()}
			>
				<div style={{ color: "white", height: "100%" }}>{setContent()}</div>
			</Drawer>
		);
	}, [
		leftDrawer,
		isSearch,
		searchResults,
		expandedKeys,
		cameraSpeed,
		cameraFov,
		cameraTarget,
		selectedKeys,
		selectValue,
		mqttList,
		options,
		cameraMode,
		highlightLayer,
	]);

	return <>{DrawerMemo}</>;
};

export default DigitalTwinLeftDrawer;
