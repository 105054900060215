import React from "react";
import { Tenant } from "../services/api-server/_exports";
import { Link } from "react-router-dom";
import useMenu from "../hooks/useMenu";
import { Breadcrumb, BreadcrumbProps } from "antd";
import useAdminMenu from "../hooks/useAdminMenu";
import { capitalize } from "../utils/dataTools";
import {
	BreadcrumbItemType,
	BreadcrumbSeparatorType,
} from "antd/es/breadcrumb/Breadcrumb";
import { useMainContext } from "../contexts/MainContext";

type BreadcrumbState2Props = {
	show: boolean;
	defaultKey: any;
	onClick: (breadcrumb: any) => void;
};

const BreadcrumbState2 = ({
	show,
	defaultKey,
	onClick,
}: BreadcrumbState2Props) => {
	const { location } = useMainContext();
	const { pathname }: any = location;
	const locations: string[] = pathname.split("/");
	const cleanedLocations: string[] = locations.filter(
		(loc) => loc && loc !== Tenant
	);

	const { menu } = useMenu();
	const adminMenu = useAdminMenu();

	const isAdmin = isAdminPage(cleanedLocations);

	let breadcrumbItems: any = getBreadcrumbs(
		cleanedLocations,
		isAdmin ? adminMenu : menu,
		isAdmin
	);

	const home = getHomePage(defaultKey);
	// insert a home path at the start of the breadcrumb
	breadcrumbItems.unshift(home);

	const handleClick = (breadcrumb: any) => {
		onClick(breadcrumb);
	};

	const itemRender = (
		element: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>
	) => {
		return (
			<Link
				style={{
					pointerEvents: typeof element?.href === "string" ? "auto" : "none",
				}}
				to={element.href || ""}
				onClick={() => handleClick(element)}
			>
				{element.title}
			</Link>
		);
	};

	return (
		<div
			style={{
				padding: "8px 16px",
				background: "rgba(9, 17, 25, 0.7)",
				display: show ? "initial" : "none",
			}}
		>
			<Breadcrumb
				separator=">"
				items={breadcrumbItems}
				itemRender={itemRender}
			/>
		</div>
	);
};

const isAdminPage = (locations: string[]) => {
	const filters: string[] = ["admin"];
	return locations.some((value) => filters.includes(value));
};

const getHomePage = (defaultKey: any) => {
	let homePage: {
		key: React.Key;
		title: string;
		href: string;
		clickable: boolean;
	} = {
		key: defaultKey,
		title: "Home",
		href: "",
		clickable: true,
	};

	return homePage;
};

const getBreadcrumbs = (
	routes: string[],
	menu: any,
	isAdmin: boolean
): BreadcrumbProps["items"] => {
	if (menu && menu.length !== 0) {
		let breadcrumbs: BreadcrumbProps["items"] = [];

		if (isAdmin) {
			breadcrumbs = generateAdminBreadcrumbs(routes, menu);
		} else {
			const usedItems = getUsedItems(routes, menu);
			breadcrumbs = generateBreadcrumbs(usedItems);
			if (breadcrumbs?.length === 0) {
				breadcrumbs = routes.map((route) => {
					if (route === "release") {
						return { key: route, title: `${capitalize(route)} Notes` };
					}
					return { key: route, title: capitalize(route) };
				});
			}
		}
		return breadcrumbs;
	} else {
		return [];
	}
};

const generateAdminBreadcrumbs = (routes: string[], menu: Array<any>) => {
	let breadcrumbs: BreadcrumbProps["items"] = [];

	breadcrumbs = routes.map((route: any) => {
		if (route === "admin") {
			return { key: route, title: "Admin" };
		} else if (route === "admin-menu-manager") {
			return { key: route, title: route.split("-").map(capitalize).join(" ") };
		} else {
			const foundMenu = menu.find((item: any) => {
				const { route: menuRoute = {} } = item;
				const { link = "" } = menuRoute;
				return link === route;
			});
			const { key = "", title = "" } = foundMenu;
			return { key, title };
		}
	});

	return breadcrumbs;
};

const getUsedItems = (routes: Array<string>, menu: Array<any>) => {
	const usedItems: Array<any> = [];

	const traverse = (menuItem: any, level: number = 0) => {
		if (menuItem?.route?.link === routes[level]) {
			// console.log(menuItem?.route?.link);
			// console.log(routes[level]);

			usedItems?.push(menuItem);
			menuItem?.children?.forEach((child: any) => traverse(child, level + 1));
		}
	};

	menu.forEach((menuItem: any) => traverse(menuItem));

	return usedItems;
};

const generateBreadcrumbs = (usedItems: Array<any>) => {
	const breadcrumms: BreadcrumbProps["items"] = usedItems?.map(
		(item, index) => {
			const { key, title } = item;

			// check for the last breadcrumb, specifically targetting Document component
			if (index === usedItems?.length - 1 && item?.component === "Documents") {
				const path = item?.path?.join("/");
				return { key, title, href: path };
			}
			return { key, title };
		}
	);
	return breadcrumms;
};

export default BreadcrumbState2;
