import {
	Badge,
	Breadcrumb,
	BreadcrumbProps,
	Button,
	Descriptions,
	Modal,
	ModalProps,
	Space,
	Table,
	Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
	capitalize,
	formatDate,
	formatDateTime,
	isDate,
	isDateTime,
	processValueByKey,
} from "../utils/dataTools";

interface CustomModalProps extends ModalProps {
	data: any;
}

const { Link, Text, Paragraph } = Typography;

const CustomModal = ({ data = {}, ...restProps }: CustomModalProps) => {
	const [rawData, setRawData] = useState<any>(null);
	const [history, setHistory] = useState<any[]>([]);

	const { label = "", children = [], excludedKeys = [] } = rawData ?? data;

	const columns = generateColumns(children, excludedKeys);
	const dataSource = generateDataSource(children, excludedKeys);

	const onRow = (record: any, rowIndex: any) => {
		return {
			style: { cursor: "pointer" },
			onClick: () => {
				setRawData(children[record?.key]);
				setHistory((prevHistory) => [...prevHistory, children[record?.key]]);
			},
		};
	};

	const handleAfterClose = () => {
		setRawData(null);
		setHistory([]);
	};

	const handleAfterOpen = (open: boolean) => {
		if (open) {
			setHistory([data]);
		}
	};

	const goTo = (index: number) => {
		setHistory((prevHistory) => prevHistory.slice(0, index + 1));
		setRawData(history[index]);
	};

	const breadcrumbItems: BreadcrumbProps["items"] = history?.map(
		(h: any, index: any) => {
			return {
				key: index,
				title:
					history?.length - 1 === index ? (
						<Text>{h?.label}</Text>
					) : (
						<Link>{h?.label}</Link>
					),
				onClick: () => goTo(index),
			};
		}
	);

	return (
		<Modal
			{...restProps}
			destroyOnClose
			width={"90%"}
			style={{ width: "80%", paddingBottom: "0" }}
			afterOpenChange={handleAfterOpen}
			afterClose={handleAfterClose}
			footer={false}
			title={
				<Space>
					{label}
					{children?.length && children?.length > 0 ? (
						<Badge
							color="#112a45"
							style={{ color: "rgba(255, 255, 255, 0.85)" }}
							count={children?.length}
						/>
					) : null}
				</Space>
			}
		>
			<Space direction="vertical" style={{ width: "100%" }}>
				<Breadcrumb separator={">"} items={breadcrumbItems} />
				<ModalContent
					children={children}
					dataSource={dataSource}
					columns={columns}
					onRow={onRow}
					data={rawData ?? data}
					excludedKeys={excludedKeys}
				/>
			</Space>
		</Modal>
	);
};

const ModalContent = ({
	children,
	dataSource,
	columns,
	onRow,
	data,
	excludedKeys,
}: any) => {
	const [currentPage, setCurrentPage] = useState(1);
	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	return (
		<>
			{children?.length && children?.length > 0 ? (
				<Table
					dataSource={dataSource}
					columns={columns}
					onRow={onRow}
					pagination={{
						style: { paddingRight: 24 },
						current: currentPage,
						onChange: handlePageChange,
					}}
				/>
			) : (
				<Descriptions className="vessel-details" bordered>
					{Object?.entries(data)?.map(([key, value]: any) => {
						if (!excludedKeys.includes(key)) {
							return (
								<Descriptions.Item label={cleanLabel(key)}>
									{cleanValue(key, value)}
								</Descriptions.Item>
							);
						}
						return null;
					})}
				</Descriptions>
			)}
		</>
	);
};

const generateDataSource = (data: any[], excludedKeys: string[]) => {
	return data?.map((d: any, index: number) => {
		const filteredData = Object.keys(d)
			.filter((key) => !excludedKeys.includes(key))
			.reduce((obj: any, key) => {
				const value = processValueByKey(key, d[key]);
				obj[key] = value;
				return obj;
			}, {});
		return { ...filteredData, key: index };
	});
};

const generateColumns = (data: any[], excludedKeys: string[]) => {
	if (data?.length > 0) {
		return Object.keys(data[0])
			?.filter((key) => !excludedKeys.includes(key))
			.map((key: string) => {
				return {
					title: capitalize(key),
					dataIndex: key,
					key,
				};
			});
	}
	return [];
};

const cleanLabel = (key: string): string => {
	let cleanedLabel = key;

	// special cases for some keys
	switch (key) {
		case "activity_desc":
			cleanedLabel = "activity_description";
			break;
		case "responsible_party_dept":
			cleanedLabel = "responsible_party_department";
			break;

		case "responsible_party_co":
			cleanedLabel = "responsible_party_company";
			break;

		default:
			break;
	}

	return capitalize(cleanedLabel);
};

const cleanValue = (key: string, value: any) => {
	let cleanedValue = value;
	if (isDate(key)) {
		cleanedValue = formatDate(cleanedValue);
	} else if (isDateTime(key)) {
		cleanedValue = formatDateTime(cleanedValue);
	} else if (key === "activity_duration") {
		cleanedValue = `${cleanedValue} hour${value > 1 ? "s" : ""}`;
	} else if (value === null) {
		cleanedValue = (
			<Text disabled italic>
				null
			</Text>
		);
	} else if (key === "activity_desc") {
		return (
			<>
				<Paragraph
					ellipsis={{ expandable: true, symbol: "more", rows: 3 }}
					style={{ marginBottom: 0 }}
				>
					{value}
				</Paragraph>
			</>
		);
	}
	return cleanedValue;
};

export default CustomModal;
