import { createContext, useContext } from "react";
import useMeasures from "../hooks/useMeasures";
import useVisuals from "../hooks/useVisuals";
import { VisualType } from "../types/Visual";

type ComponentContextProps = {
	measures: Array<MeasureType>;
	visuals: Array<VisualType>;
	data_authorized: Array<string>;
	schema_authorized: Array<string>;
	component: string;
	menuItem: any;
	measuresLoading: boolean;
	visualsLoading: boolean;
	fetchMeasureData: (
		measure: MeasureType,
		currentUser: Record<string, any>,
		currentMenuItem: Record<string, any>,
		isOwner: boolean,
		slicerParams?: Record<string, any>,
		params?: Record<string, any>
	) => Promise<any>;
};

type ComponentProviderProps = { children?: React.ReactNode; menuItem?: any };

// A context that wraps all Portal components providing the necessary data like MVP (Measures, Visuals and Parameters)
const ComponentContext = createContext<ComponentContextProps | undefined>(
	undefined
);

const ComponentProvider = ({ children, menuItem }: ComponentProviderProps) => {
	const { data_authorized = [], schema_authorized = [], component } = menuItem;

	const {
		loading: measuresLoading,
		measures,
		fetchMeasureData,
	} = useMeasures();
	const { loading: visualsLoading, visuals } = useVisuals(menuItem.key);

	return (
		<ComponentContext.Provider
			value={{
				measures,
				visuals,
				data_authorized,
				schema_authorized,
				component,
				menuItem,
				measuresLoading,
				visualsLoading,
				fetchMeasureData,
			}}
		>
			{children}
		</ComponentContext.Provider>
	);
};

const useComponent = () => {
	const context = useContext(ComponentContext);

	if (!context) {
		throw new Error("useComponent must be used within a ComponentProvider");
	}

	return context;
};

export { ComponentProvider, useComponent };
