export const isLoggedIn = (state: boolean | any = false, action: any) => {
	switch (action.type) {
		case "LOGIN":
			return true;
		case "LOGOUT":
			return false;
		default:
			return state;
	}
};

export const User = (state: any = null, action: any) => {
	switch (action.type) {
		case "SET_USER":
			// console.log("user is", action.payload.email);
			return action.payload;
		default:
			return state;
	}
};

export const Token = (state: any = null, action: any) => {
	switch (action.type) {
		case "SET_SSO_TOKEN":
			if (action.payload === null) {
				return state;
			}
			if (action.payload?.idToken && action.payload?.accessToken) {
				const result = {
					...state,
					dummy: "data",
					idToken: action.payload.idToken,
					accessToken: action.payload.accessToken,
				};
				return result;
			} else {
				return state;
			}
		default:
			return state;
	}
};

export const loginRegister = (state: any = null, action: any) => {
	switch (action.type) {
		case "LOGIN_REGISTER":
			const token = action.payload?.idToken;
			if (token) {
				try {
					// const decoded = jwtDecode(action.payload.idToken)
					//alert('>>>>>!!!!!'+JSON.stringify(decoded))
					return token;
				} catch (e: any) {
					// console.log("err", action.payload);
					alert(JSON.stringify(action.payload));
				}
				return PopStateEvent;
			} else {
				return state;
			}
		default:
			return state;
	}
};
