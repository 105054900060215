import { Breadcrumb, Col, Input, Row, Tabs, Typography } from "antd";
import SchemaTable from "./SchemaTable";
import DataSourceTable from "./DataSourceTable";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from "react";
import FieldTable from "./FieldTable";
import FieldSelectionTable from "./FieldSelectionTable";
import PreviewTable from "./PreviewTable";
import {
	INIITAL_STATE,
	MODE_TYPE,
	reducer,
} from "../reducers/dataExplorerReducer";
import { DataExplorerProvider } from "../contexts/DataExplorerContext";

const { Title, Link } = Typography;
const { Search } = Input;

type DataExplorerProps = {
	onDataset?: (dataset: any) => void;
	onField?: (
		values: any[],
		dataset?: any,
		fieldName?: string,
		query?: string
	) => void;
};

const DataExplorer = ({
	onDataset = () => {},
	onField = () => {},
}: DataExplorerProps) => {
	const [state, dispatch] = useReducer(reducer, INIITAL_STATE);

	const [searchVal, setSearchVal] = useState("");
	const getPlaceholder = useMemo(() => {
		switch (state.mode) {
			case MODE_TYPE.SCHEMA:
				return "schema";
			case MODE_TYPE.DATASET:
				return "dataset";
			case MODE_TYPE.FIELD:
				return "field";
			default:
				return "";
		}
	}, [state.mode]);

	const handleBreadcrumbClick = useCallback((mode: string) => {
		switch (mode) {
			case MODE_TYPE.SCHEMA:
				dispatch({ type: "VIEW_SCHEMAS" });
				break;
			case MODE_TYPE.DATASET:
				dispatch({ type: "VIEW_DATASETS" });
				break;
			case MODE_TYPE.FIELD:
				dispatch({ type: "VIEW_FIELDS" });
				break;

			default:
				break;
		}
	}, []);

	const handleSearchChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.value === "") {
				handleSearch(e.target.value);
			}
			setSearchVal(e.target.value);
		},
		[]
	);

	const handleSearch = (searchVal: string) => {
		dispatch({ type: "ONSEARCH", payload: searchVal });
	};

	const handleTabChange = (activeKey: string) => {
		dispatch({ type: "TAB", payload: activeKey });
	};

	const renderTable = (mode: string) => {
		switch (mode) {
			case MODE_TYPE.SCHEMA:
				return <SchemaTable />;
			case MODE_TYPE.DATASET:
				return <DataSourceTable onDataset={onDataset} />;
			case MODE_TYPE.FIELD:
				return (
					<Row gutter={[8, 8]}>
						<Col span={24}>
							<Tabs
								onChange={handleTabChange}
								items={[
									{ key: "fields", label: "Fields", children: <FieldTable /> },
									{
										key: "top_hundred",
										label: "Top 100",
										children: (
											<PreviewTable
												title={() => "Top 100"}
												data={state.topHundredData}
											/>
										),
									},
								]}
							/>
						</Col>
						<Col>
							<FieldSelectionTable
								onField={(values, query) =>
									onField(values, state.dataset, state.field, query)
								}
							/>
						</Col>
					</Row>
				);
			default:
				return null;
		}
	};

	// Resets search value when displaying different table
	useEffect(() => {
		setSearchVal("");
	}, [state.mode]);

	return (
		<DataExplorerProvider dispatch={dispatch} state={state}>
			<Row gutter={[8, 8]}>
				<Col span={24}>
					<Title style={{ marginTop: "0.5em" }} level={5}>
						Data Explorer
					</Title>
				</Col>
				<Col span={24}>
					<Search
						placeholder={`Search ${getPlaceholder}...`}
						allowClear
						onChange={handleSearchChange}
						onSearch={handleSearch}
						value={searchVal}
						style={{ marginBottom: 8 }}
						styles={{ affixWrapper: { height: 32 } }}
						disabled={state.activeTab === "top_hundred"}
					/>
				</Col>
				<Col span={24}>
					<Breadcrumb
						items={state.history.map((h: any) => ({
							key: h.mode,
							title: (
								<Link onClick={() => handleBreadcrumbClick(h.mode)}>
									{h.title}
								</Link>
							),
						}))}
					/>
				</Col>
				<Col span={24}>{renderTable(state.mode)}</Col>
			</Row>
		</DataExplorerProvider>
	);
};

export default DataExplorer;
