import { Button, Col, Flex, Input, Popconfirm, Row, Table, Typography } from 'antd'
import React, { useMemo, useState } from 'react'
import { GetAntIcon } from '../utils/ant_icons'
import dayjs from 'dayjs'

const { Text, Link } = Typography
const { Search } = Input

type UserPermissionsProps = {
    openAddViewersModal?: () => void,
    readonly?: boolean,
    menuItem?: any
    users?: any[]
    onRemoveViewer?: (id: string) => void
}

const defaultColumns = [
    { key: "user", dataIndex: "displayName", title: "User" },
    { key: "email", dataIndex: "mail", title: "Email" },
    { key: "approvedBy", dataIndex: "approvedBy", title: "Approved By" },
    {
        key: "approvedDate",
        dataIndex: "approvedDate",
        title: "Approved Date",
        render: (text: any) =>
            text ? dayjs(text).format("DD-MMM-YYYY hh:mm:ss A") : null,
    },
    { key: "team", dataIndex: "team", title: "Team", width: 80 },
    {
        key: "permissions",
        dataIndex: "permissions",
        title: "Role",
        width: 60,
    },
];

const UserPermissions = ({ openAddViewersModal, readonly, menuItem, users, onRemoveViewer = () => { } }: UserPermissionsProps) => {
    const [searchVal, setSearchVal] = useState("");
    const handleSearch = (value: string) => {
        setSearchVal(value);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") setSearchVal("");
    };

    const columns = !readonly
        ? [
            ...defaultColumns,
            {
                key: "actions",
                dataIndex: "actions",
                title: "Actions",
                width: 70,
                render: (text: string, record: any) => {

                    const {
                        id = "",
                        userPrincipalName = "",
                        removable = true,
                    } = record;

                    return !removable ? null : (
                        <Popconfirm
                            title="Are you sure?"
                            description={`Are you sure you want to remove user ${userPrincipalName}?`}
                            overlayStyle={{ zIndex: 30003 }}
                            onConfirm={() => onRemoveViewer(id)}
                        >
                            <Link>Remove</Link>
                        </Popconfirm>
                    );
                },
            },
        ]
        : defaultColumns;

    const filteredUsers = useMemo(() => {
        const filteredUsers = users?.filter((user: any) => {
            const userName = user?.displayName?.toLowerCase();
            const groupName = user?.team?.toLowerCase();
            const userMail = user?.mail?.toLowerCase();
            return (
                userName?.includes(searchVal.toLowerCase()) ||
                groupName?.includes(searchVal.toLowerCase()) ||
                userMail?.includes(searchVal.toLowerCase())
            );
        });

        return searchVal ? filteredUsers : users;
    }, [searchVal, users]);


    return (
        <Row
            className="permissions-section"
            gutter={[12, 12]}
        >
            <Col span={24} >
                <Flex justify="space-between" wrap="wrap">
                    <Text style={{ fontSize: 16 }}>User Permissions</Text>
                    {!readonly && (
                        <Button
                            icon={GetAntIcon("plus")}
                            onClick={openAddViewersModal}
                        >
                            Add viewers
                        </Button>
                    )}
                </Flex>
            </Col>
            <Col xs={24} sm={6}>
                <Search
                    placeholder="Search user name"
                    onSearch={handleSearch}
                    allowClear
                    onChange={handleSearchChange}
                />
            </Col>
            <Col span={24}>
                <Table
                    className="preview-table"
                    columns={columns}
                    dataSource={filteredUsers}
                    bordered
                    size="small"
                    tableLayout="fixed"
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                />
            </Col>
        </Row>
    )
}

export default UserPermissions