import React from "react";
import "./App.css";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import Logout from "./services/Logout";
import { setAdminComponent, setComponent } from "./utils/components";
import { getFolders } from "./services/api-server/documents";
import { getClient } from "./services/api-server/clients";
import { connect } from "react-redux";
import { loadVessels } from "./services/RigInfo";
import Emitter from "./services/EventEmitter";
import {
	loadAdminmenu,
	loadMenu,
	saveAdminMenu,
	saveMenu,
} from "./services/api-server/menu";
import { initialState } from "./state/reducers/module";
import { Alert, ConfigProvider, theme } from "antd";

//Desktop Containers
import LoginPage from "./containers/LoginPage";
import Release from "./containers/Release";
import Home from "./containers/Home";
import Admin from "./containers/admin/Admin";
import Help from "./containers/Help";
import DocumentTable from "./containers/DocumentTable";
import { checkMobile, filterMenu, getNodes, logout } from "./utils/utils";
import NoAccess from "./containers/NoAccess";
import { socket } from "./utils/socket";
import Location from "./utils/location";
import withComponentManager from "./hoc/withComponentManager";
import { Tenant } from "./services/api-server/_exports";
import en_GB from "antd/lib/locale/en_GB";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { MainProvider } from "./contexts/MainContext";
dayjs.locale("en-gb");
export const Mode = `${process.env.REACT_APP_MODE}`;
var detect = require("detect.js");
let ping: any = null;
let inactivityTimer: any = null;
let inactivityTimeout: any = 60000;

const { defaultAlgorithm, darkAlgorithm } = theme;
const EnhancedDocumentTable = withComponentManager(DocumentTable);
//Toggle darkMode true every time
document.body.classList.toggle("dark-mode", true);
localStorage.removeItem(`${Tenant}:pbiToken`);

const EndPoint = `${Tenant}`;

class App extends React.Component<any> {
	state: any = {
		isAdmin: false,
		email: "abc@xyz.com",
		isLoggedIn: false,
		roles: null,
		menu: null,
		adminmenu: null,
		clean: true,
		alert: {},
		pbi_decoded: null,
		defaultmenu: null,
		defaultAdminmenu: null,
		mode: localStorage.getItem("theme") || "dark",
	};

	Restricted = [
		`/${EndPoint}/login`,
		`/${EndPoint}/logout`,
		`/${EndPoint}/login/`,
		`/${EndPoint}/logout/`,
	];

	RedirectRestriced = [`/`, `/${EndPoint}`, `/${EndPoint}/`];

	currentwindow = window.location.pathname;
	timeout: any = null;

	constructor(props: any) {
		super(props);
		this.resetPing = this.resetPing.bind(this);
		this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.sendInactiveSocket = this.sendInactiveSocket.bind(this);
		// this.ReduxMainMenu();
		// this.ReduxAdminMenu();
	}

	ReduxMainMenu = (reload: boolean = false) => {
		loadMenu().then((menu: any) => {
			if (!menu) {
				saveMenu(initialState.mainMenu).then((initial_menu: any) => {
					menu = initial_menu.data;
					this.setState({ menu: initial_menu.data });
				});
			} else {
				const isAdmin = this.props.user?.roles?.includes(
					process.env.REACT_APP_SUPER_ADMIN_TAG
				);

				this.setState({
					menu: isAdmin
						? menu
						: {
								menu: filterMenu(
									menu.menu,
									this.props.user?.roles,
									this.props.user
								),
						  },
				});
				if (reload) {
					// Emitter.emit("MenuReload", menu);
					// console.log("not not have menu");
				}
			}

			const { defaultMenu } = this.getDefaultPage(menu.menu);

			this.setState({
				defaultKey: defaultMenu ? defaultMenu.key : null,
			});
		});
	};

	ReduxAdminMenu = () => {
		loadAdminmenu().then((adminmenu: any) => {
			if (!adminmenu) {
				saveAdminMenu(initialState.adminModules).then((initial_menu: any) => {
					this.setState({ adminmenu: initial_menu.data });
					this.props.dispatch({
						type: "SET_ADMIN_MODULE",
						payload: initial_menu.data,
					});
				});
			} else {
				// this.setState({ adminmenu: adminmenu });
				// this.props.dispatch({ type: "SET_ADMIN_MODULE", payload: adminmenu });
				this.setState({ adminmenu: { adminmenu: initialState.adminModules } });
				this.props.dispatch({
					type: "SET_ADMIN_MODULE",
					payload: { adminmenu: initialState.adminModules },
				});
			}
		});
	};

	logout() {
		this.props.dispatch({ type: "LOGOUT", payload: true });
		logout(true);
	}

	CheckUser(id_decoded: any) {
		if (id_decoded.aud !== process.env.REACT_APP_CLIENT_ID) {
			alert(
				"Token Client is not the same as the Client of the application. Logging you out"
			);
			this.logout();
		}
	}

	CheckRole(user_roles: any, menu_roles: any) {
		return true;
		if (user_roles.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
			return true;
		} else if (!menu_roles) {
			return true;
		} else if (menu_roles?.length === 0) {
			return true;
		} else {
			menu_roles?.map((element: any) => {
				if (user_roles.includes(element)) {
					return true;
				}
			});
		}
	}

	renderParams = (mitem: any) => {
		let paramObj: any = {};
		let route_params: any = mitem.route?.params;

		if (mitem.roles) {
			paramObj["roles"] = mitem.roles;
		}

		paramObj["mitem"] = mitem;

		if (route_params !== undefined && route_params !== null) {
			route_params?.map((p: any) => {
				//console.log('p',p)
				paramObj[`${p.key}`] = p.value;
				return null;
			});
			// console.log('paramObj',paramObj)
		}

		if (paramObj) {
			return paramObj;
		} else {
			return null;
		}
	};

	DynamicRouting = (element: any) => {
		const SComponent = setComponent(
			element.component,
			element.title,
			element.key,
			this.renderParams(element),
			this.props.user?.roles
		);

		const pattern = /^[a-zA-Z0-9_-]+$/;
		let link = element?.route?.link || "";
		if (link != "" && pattern.test(link)) {
			if (element.children && element.children.length > 0) {
				return (
					<Route key={element.key} path={element.route.link}>
						{element.children.map((child: any) => {
							return this.DynamicRouting(child);
						})}

						{element.sidebarmenu?.map((sidebarItem: any) => {
							return sidebarItem?.children?.map((child: any) => {
								return this.DynamicRouting(child);
							});
						})}

						<Route
							key={"not-found"}
							path=":pagename"
							element={<NoAccess text={"Page does not exist"} />}
						/>
					</Route>
				);
			} else {
				if (element.component === "Documents") {
					const documents = this.state.documents;
					return (
						<>
							<Route
								key={element.title}
								path={element.route.link}
								element={SComponent}
							/>
							{documents
								? documents.map((folder: any, index: any) => {
										return (
											<Route
												key={folder.name}
												path={`${element.route.link}/${folder.name
													.split(" ")
													.join("_")}`}
												element={
													<EnhancedDocumentTable
														key={`${folder}-${index}`}
														folder={[folder, index]}
														params={this.renderParams(element)}
													/>
												}
											></Route>
										);
								  })
								: null}
						</>
					);
				} else {
					return (
						<Route
							key={element.title}
							path={element.route.link}
							element={SComponent}
						/>
					);
				}
			}
		} else {
			return null;
		}
	};

	DynamicAdminRouting = (element: any) => {
		const pattern = /^[a-zA-Z0-9_-]+$/;
		let link = element.route.link || "";
		if (link != "" && pattern.test(link)) {
			return (
				<Route
					key={element.title}
					path={element.route.link}
					element={setAdminComponent(
						element.component,
						this.renderParams(element),
						this.props.user?.roles
					)}
				/>
			);
		} else {
			return <></>;
		}
	};

	findRoute = (parent: any, targetRoute: string) => {
		let item = null;

		const searchInChildren = (children: any[]) => {
			children.forEach((mItem: any) => {
				if (parent?.route?.link === targetRoute) {
					item = parent;
				}

				if (mItem?.children && mItem?.children?.length !== 0) {
					searchInChildren(mItem?.children);
				}
			});
		};

		searchInChildren(parent?.children);

		return item;
	};

	getDefaultMenu = () => {
		return new Promise((resolve, reject) => {
			const locations = window.location.href.split("/");

			// this is to filter out the values after split until we are left with the parent route and child if there is
			const cleanedLocations = locations.splice(
				locations.findIndex((value) => value === Tenant) + 1
			);

			// the first element in the cleanedLocations will always be the parent route which is what we are looking for
			const parentRoute: any = cleanedLocations[0];

			loadMenu().then((data: any) => {
				data?.menu?.forEach((mItem: any) => {
					if (mItem?.children && mItem?.children?.length !== 0) {
						const found = this.findRoute(mItem, parentRoute);
						if (found) resolve(mItem);
					} else if (mItem?.route?.link === parentRoute) {
						resolve(mItem);
					}
				});
			});
		});
	};

	setDefaultMenu = (parent: any, element: any, routes: any = []) => {
		if (this.CheckRole(this.props.user?.roles, element.roles) === true) {
			routes.push(element.route.link);
			if (element.children) {
				let menu: any = null;
				element.children.every((child: any) => {
					if (this.CheckRole(this.props.user?.roles, child.roles) === true) {
						let temp: any = this.setDefaultMenu(parent, child, routes);
						menu = temp;
						return false;
					} else {
						return true;
					}
				});
				return menu;
			} else {
				let route: any = "";
				if (routes.length > 0) {
					route = routes.join("/");
				} else {
					route = element.route.link;
				}

				this.getDefaultMenu().then((defaultMenu: any) => {
					let parentRoute: string = "";
					if (routes?.length > 0) {
						parentRoute = routes[0];
					} else {
						parentRoute = route;
					}

					// check to see if the default menu item is the current menu
					if (defaultMenu?.route?.link === parentRoute) {
						if (!this.state.defaultKey)
							this.setState({
								defaultKey: parent.key,
								homeKey: parent.key,
							});
					} else {
						if (!this.state.defaultKey)
							this.setState({
								defaultKey: defaultMenu.key,
								homeKey: parent.key,
							});
					}
				});
				return (
					<>
						<Route
							key={"DefaultPath"}
							path=""
							element={<Navigate to={route} />}
						/>
						{this.DynamicRouting(parent)}
					</>
				);
			}
		}
	};

	setDefaultAdminMenu = (element: any) => {
		return (
			<>
				<Route
					key={"DefaultAdminPath"}
					path={""}
					element={<Navigate to={element.route.link} />}
				/>
				{this.DynamicAdminRouting(element)}
			</>
		);
	};

	resize = () => {
		// normal tablet screen size = 1280px
		// const isMobile = window.innerWidth <= 1280;

		this.setState({ Mobileview: checkMobile() });
		this.props.dispatch({ type: "IS_MOBILE", payload: checkMobile() });

		// console.log({isMobile});
	};

	sendPing = () => {
		var ua = detect.parse(navigator.userAgent);
		const start = Date.now();

		socket.emit("ping", ua?.browser?.name, () => {
			const duration = Date.now() - start;
			// console.log("Latency:", duration + "ms");
		});
	};

	resetPing = () => {
		clearInterval(ping);
		ping = setInterval(() => {
			this.sendPing();
		}, 10000);
	};

	handleVisibilityChange() {
		if (document.hidden) {
			// The page is not visible (e.g., tab is suspended)
			// Perform actions when the tab becomes inactive
			this.sendInactiveSocket();
			Emitter.emit("page-invisible", null);
			clearInterval(ping);
			clearTimeout(inactivityTimer);
		} else {
			// The page is visible (e.g., tab regains focus)
			// Perform actions when the tab becomes active
			Emitter.emit("page-visible", null);
			this.sendPing();
			this.resetPing();
		}
	}

	handleMouseMove() {
		this.resetPing();

		// Reset the inactivity timer
		clearTimeout(inactivityTimer);
		inactivityTimer = setTimeout(this.sendInactiveSocket, inactivityTimeout);
	}

	handleKeyDown() {
		this.resetPing();

		// Reset the inactivity timer
		clearTimeout(inactivityTimer);
		inactivityTimer = setTimeout(this.sendInactiveSocket, inactivityTimeout);
	}

	sendInactiveSocket() {
		// Send an "inactive" socket message when the inactivity timeout is reached
		clearInterval(ping);
		socket.emit("inactive");
	}

	componentDidMount() {
		window.addEventListener("resize", this.resize);
		this.resize();

		if (this.currentwindow === "/") {
			window.location.href = `/${EndPoint}`;
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
	}

	componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<{}>,
		snapshot?: any
	): void {
		//IMPORTANT: It should only run once
		if (prevProps.user !== this.props.user) {
			// console.log("run once");
			if (!this.Restricted.includes(this.currentwindow) && this.props.user) {
				try {
					getFolders()
						.then((res: any) => {
							this.setState({ documents: res.data[0].folders });
							this.props.dispatch({
								type: "DOCUMENTS",
								payload: res.data[0],
							});
						})
						.catch((error: any) => {
							console.log("Error has occured when fetching Documents");
						});
					getClient()
						.then((res: any) => {
							this.props.dispatch({ type: "CLIENT", payload: res.data[0] });
						})
						.catch((error: any) => {
							console.log("Error has occured when getting Client Information");
						});
					this.CheckUser(this.props.user);
					loadVessels();
				} catch (error) {
					this.logout();
				}

				const socketServer: string = process.env
					.REACT_APP_SOCKET_SERVER as string;

				socket.on("connect", () => {
					inactivityTimer = setTimeout(
						this.sendInactiveSocket,
						inactivityTimeout
					);
					if (!document.hidden) {
						const start = Date.now();
						this.sendPing();
					} else {
						this.sendInactiveSocket();
					}
					document.addEventListener(
						"visibilitychange",
						this.handleVisibilityChange
					);
					document.addEventListener("mousemove", this.handleMouseMove);
					document.addEventListener("keydown", this.handleKeyDown);
				});
				socket.on("connect_error", (err) => {
					console.log(`${socketServer}::connect_error due to ${err.message}`);
					Emitter.emit("alert", {
						type: "error",
						message: "Connection to Server has failed",
						description:
							"The Server may be down or is under maintanence, please come back at a later time.",
					});
				});

				socket.on("AIS:NEWPOSITIONDATA", (data: any) => {
					// console.log('AISDATA', data.payload.data)
					Emitter.emit("updatelocation", data.data);
					// localStorage.setItem("VesselLocation", JSON.stringify(data.payload.data));
				});
				socket.on("NOTIFICATION:EMIT", (payload) => {
					this.props.dispatch({ type: "ADD_NOTIFICATION", payload: payload });
				});
				socket.on("SERVERRESTART", (data: any) => {
					Emitter.emit("alert", data.payload);
				});
				socket.on("MENURESTART", (data: any) => {
					Emitter.emit("alert", data.payload);
					this.ReduxMainMenu(true);
				});
				socket.on("DATA_UPDATED", (data: any) => {
					Emitter.emit("alert", data.payload);
				});

				socket.on("UPDATE_CLIENTS", (data) => {
					const menuKey = data;
					// check if the client is on the same page as the targetted page
					const allEndNodes = getNodes(this.state.menu?.menu);
					const excludeTenantPath = window.location.pathname
						?.split("/")
						.filter((path) => path && path !== Tenant)
						.join("/");
					const found = allEndNodes?.find(
						(node) =>
							node.path?.join("/") === excludeTenantPath && node.key === menuKey
					);

					// refreshes the clients that are on the same page as the recent saved/updated menu
					if (found) {
						Emitter.emit("alert", {
							type: "info",
							message: "Menu Updated",
							description:
								"Menu has been updated, please refresh the page to receive the latest updates",
						});
						this.ReduxMainMenu(true);
					}
				});

				//Event Emitters
				Emitter.on("newdocs", () => {
					getFolders()
						.then((res: any) => {
							this.setState({ documents: res.data[0].folders });
							this.props.dispatch({ type: "DOCUMENTS", payload: res.data[0] });
						})
						.catch((error: any) => {
							console.log("Error has occured when fetching Documents");
						});
				});
				Emitter.on("alert", (payload: any) => {
					if (payload) {
						if (payload.timeout) {
							if (this.timeout) {
								clearTimeout(this.timeout);
								this.timeout = null;
							}
							this.timeout = setTimeout(() => {
								this.setState({ alert: {} });
							}, payload.timeout);
						}
						this.setState({
							alert: {
								type: payload.type,
								message: payload.message,
								description: payload.description,
								top: payload.top,
							},
						});
					} else {
						this.setState({ alert: {} });
					}
				});
				Emitter.on("MenuSaved", () => {
					socket.emit("MENUSAVED");
					this.ReduxMainMenu(true);
				});
				Emitter.on("Admin:MenuSaved", () => {
					this.ReduxAdminMenu();
				});
				Emitter.on("mode-toggle", (ev: any) => {
					this.setState({ mode: ev ? "dark" : "light" });
				});
			}
			this.ReduxMainMenu(true);
			this.ReduxAdminMenu();
		}
	}

	getDefaultPage = (menu: Array<any>) => {
		let defaultMenu: any = null;

		const traverse = (parent: any, item: any) => {
			// logic: if first level parent is not accesible, everything under it won't be accessible
			const hasAccess = this.CheckRole(this.props.user?.roles, item.roles);

			if (hasAccess) {
				// if have children, check the children
				// if not, set this menu to be the default
				const { children = [], keyPath = [item.route.link] } = item;

				if (children !== null && children.length !== 0) {
					children.forEach((child: any) => {
						const { route = {} } = child;
						const { link = "" } = route;
						traverse(parent, {
							...child,
							route: { link: `${item?.route?.link}/${link}` },
							keyPath: [...keyPath, link],
						});
					});
				}

				if (defaultMenu === null) {
					defaultMenu = { parent, defaultMenu: { ...item, keyPath } };
				}
			}
		};

		if (menu !== null && menu.length !== 0) {
			menu.forEach((mItem: any) => {
				if (defaultMenu === null) {
					traverse(mItem, mItem);
				}
			});
		}
		return defaultMenu;
	};

	getMenuKey = () => {
		const cleanedPaths = this.currentwindow
			.split("/")
			.filter((path) => path && path !== Tenant);

		let foundParent: any = null; // only required to find first level menu items
		if (this.state.menu !== null) {
			const { menu = [] } = this.state.menu;
			if (menu !== null && menu.length !== 0) {
				foundParent = menu.find((mItem: any) => {
					const { route = {} } = mItem;
					const { link = "" } = route;
					return link === cleanedPaths[0];
				});
			}
		}

		return foundParent?.key ?? null;
	};

	render() {
		const onLocationChange = (location: Location) => {
			this.setState({ location });
		};

		let defaultmenu: any = null;
		let defaultAdminmenu: any = null;

		return (
			<ConfigProvider
				locale={en_GB}
				theme={{
					inherit: false,
					algorithm:
						this.state.mode === "light" ? defaultAlgorithm : darkAlgorithm,
				}}
			>
				<div className="main">
					{Object.keys(this.state.alert).length > 0 ? (
						<>
							{window.innerWidth > 640 ? (
								<Alert
									className={
										this.state.alert?.top
											? "alert-message-box-top"
											: "alert-message-box"
									}
									type={this.state.alert?.type}
									message={this.state.alert?.message}
									description={this.state.alert?.description}
									showIcon
									closable
									afterClose={() => this.setState({ alert: {} })}
									style={{ zIndex: 99999 }}
								/>
							) : (
								<Alert
									className="alert-message-box-mobile"
									type={this.state.alert?.type}
									message={this.state.alert?.message}
									description={this.state.alert?.description}
									showIcon
									closable
									afterClose={() => this.setState({ alert: {} })}
									style={{ zIndex: 99999 }}
								/>
							)}
						</>
					) : null}
					<Router>
						<Location onChange={onLocationChange} />
						<Routes>
							<Route path={`${Tenant}`}>
								<Route path="login" element={<LoginPage />} />
								{this.props.user && this.state.menu ? (
									<Route
										path=""
										element={
											<MainProvider location={this.state.location}>
												<Home
													defaultKey={
														this.getMenuKey() || this.state.defaultKey
													}
													homeKey={this.state.defaultKey || null}
												/>
											</MainProvider>
										}
									>
										{this.state.menu
											? this.state.menu?.menu?.map(
													(element: any, index: any) => {
														if (!defaultmenu) {
															let { parent, defaultMenu } = this.getDefaultPage(
																this.state.menu.menu
															);

															if (defaultMenu !== null) {
																defaultmenu = true;
																return (
																	<>
																		<Route
																			key={"DefaultPath"}
																			path=""
																			element={
																				<Navigate
																					to={defaultMenu.keyPath.join("/")}
																				/>
																			}
																		/>

																		{this.DynamicRouting(parent)}
																	</>
																);
															} else {
																return this.DynamicRouting(element);
															}
														} else {
															return this.DynamicRouting(element);
														}
													}
											  )
											: null}
										<Route path="help" element={<Help />} />
										<Route path="release" element={<Release />} />
										<Route path="logout" element={<Logout />} />
										<Route
											path=":pagename"
											element={<NoAccess text={"Page does not exist"} />}
										/>
										<Route path="admin" element={<Admin />}>
											{this.props.user?.roles?.includes(
												process.env.REACT_APP_SUPER_ADMIN_TAG
											) ? (
												<>
													{(defaultAdminmenu = "menu-manager")}
													<Route
														path=""
														element={<Navigate to={"menu-manager"} />}
													/>
												</>
											) : null}
											{this.state.adminmenu
												? this.state.adminmenu?.adminmenu?.map(
														(element: any) => {
															if (!defaultAdminmenu) {
																if (
																	this.CheckRole(
																		this.props.user?.roles,
																		element.roles
																	) === true
																) {
																	defaultAdminmenu = element.route.link;
																	return this.setDefaultAdminMenu(element);
																}
															} else {
																// console.log(this.state.adminmenu);
																return this.DynamicAdminRouting(element);
															}
														}
												  )
												: null}
										</Route>
										<Route
											path="*"
											element={<NoAccess text={"Page does not exist"} />}
										/>
									</Route>
								) : null}
							</Route>
							<Route
								path=":pagename"
								element={
									this.state.isLoggedIn === true ? (
										<>
											<NoAccess text={"Page does not exist"} />
										</>
									) : (
										<NoAccess text={"Page does not exist"} />
									)
								}
							/>
						</Routes>
					</Router>
				</div>
			</ConfigProvider>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		users: state,
		notifications: state.notifications,
		vessels: state.vessels.allVessels,
		user: state.user,
	};
};

export default connect(mapStateToProps)(App);
