import { DeleteOutlined } from '@ant-design/icons'
import { Button, Checkbox, Flex, Form, Input, Select, Space, Typography } from 'antd'
import { Suspense, useEffect, useState } from 'react'
import { getAppRoles, getGeneralUsers } from '../../services/api-server/admin'

const { Text } = Typography

type PowerBiInputProps = {
    e_identity?: boolean, paginated?: boolean, enable_custom_roles?: boolean
}

const PowerBiInputs = ({ e_identity, paginated, enable_custom_roles }: PowerBiInputProps) => {
    const [users, setUsers] = useState<any[]>([])
    const [appRoles, setAppRoles] = useState<any[]>([])


    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const responses = await Promise.all([getGeneralUsers(), getAppRoles()])
                setUsers(responses[0])
                setAppRoles(responses[1])


            } catch (error) {
                console.log('Unable to fetch options');

            }
        }

        fetchOptions()

    }, [])


    return (
        <>
            <Form.Item>
                <Text style={{ fontSize: 20 }}>Power BI Settings</Text>
            </Form.Item>
            <Form.Item
                name={"workspaceid"}
                label="Workspace ID"
                rules={[
                    {
                        required: true,
                        message: "Please provide a Workspace ID",
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"reportid"}
                label="Report ID"
                rules={[
                    {
                        required: true,
                        message: "Please provide a Report ID",
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item>
                <Form.Item name={'e_identity'} valuePropName="checked" noStyle>
                    <Checkbox
                        style={{
                            color: "rgba(0,0,0,0.85)",
                        }}
                    >
                        Row Level Security
                    </Checkbox>
                </Form.Item>
                <Form.Item name={'paginated'} valuePropName="checked" noStyle>
                    <Checkbox
                        style={{
                            color: "rgba(0,0,0,0.85)",
                        }}
                    >
                        Paginated Report
                    </Checkbox>
                </Form.Item>
                <Form.Item name={'enable_custom_roles'} valuePropName="checked" noStyle>
                    <Checkbox
                        disabled={
                            e_identity &&
                            paginated}
                        style={{
                            color: "rgba(0,0,0,0.85)",
                        }}
                    >
                        Custom Roles
                    </Checkbox>
                </Form.Item>
            </Form.Item>
            {(e_identity ||
                paginated) ? (
                <Form.Item
                    label="Dataset ID"
                    name={"datasetid"}
                    rules={[
                        {
                            required: true,
                            message: "Please enter a dataset id",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            ) : null}
            {(paginated ||
                e_identity) &&
                enable_custom_roles ? (
                <Form.List name={"powerbi_roles"} >
                    {(fields, { add, remove }) => <Space direction="vertical">
                        {fields.map(({ key, name }) => (
                            <Flex
                                key={key}
                                gap={8}
                                align="baseline"
                            >
                                <Form.Item
                                    name={[name, "role_key"]}
                                    noStyle
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please provide a PowerBI role key",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Role Key"
                                        style={{ width: 200 }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name={[name, "users"]}
                                    noStyle
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select users",
                                        },
                                    ]}
                                >
                                    <Select
                                        options={users?.map(
                                            (user: any) => ({
                                                label: user?.displayName,
                                                value: user?.id,
                                            })
                                        )}
                                        placeholder="Users"
                                        showSearch
                                        optionFilterProp="label"
                                        style={{ width: 200 }}
                                        mode="multiple"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={[name, "roles"]}
                                    noStyle
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select user roles",
                                        },
                                    ]}
                                >
                                    <Select
                                        options={appRoles?.map(
                                            (role: any) => ({
                                                label: role,
                                                value: role,
                                            })
                                        )}
                                        placeholder="Roles"
                                        style={{ width: 200 }}
                                        showSearch
                                        optionFilterProp="label"
                                        mode="multiple"
                                    />
                                </Form.Item>
                                <Button
                                    danger
                                    onClick={() => remove(name)}
                                    icon={<DeleteOutlined />}
                                />
                            </Flex>
                        ))}
                        <Button
                            onClick={() => add()}
                        >
                            Add Role
                        </Button>
                    </Space>}
                </Form.List>
            ) : null}
        </>
    )
}

export default PowerBiInputs