import { useEffect, useState } from "react";
import { Layout, List } from "antd";
import TenantDetails from "../../components/admin/TenantDetails";
import { getAllTenantModules } from "../../services/api-server/menu";

const { Content, Sider } = Layout;
const TenantSettings = (props: any) => {
	const [tenants, setTenants] = useState<any[]>([]);
	const [selectedTenant, setSelectedTenant] = useState<any>(null);

	const getLatestTenantModules = async () => {
		await getAllTenantModules()
			.then((res: any) => {
				setTenants(res.data);
			})
			.catch((err) => {
				// console.error(err?.message);
			});
	};

	const onUpdate = (updatedTenant: any) => {
		setSelectedTenant(updatedTenant);
		getLatestTenantModules();
	};

	useEffect(() => {
		getLatestTenantModules();
	}, []);

	return (
		<Layout style={{ maxHeight: "100vh" }}>
			<Sider style={{ background: "none" }}>
				<List>
					{tenants?.map((_tenant: any) => {
						return (
							<List.Item
								key={_tenant._id}
								onClick={() => {
									setSelectedTenant(_tenant);
								}}
								style={{
									padding: 16,
									cursor: "pointer",
									background:
										selectedTenant?._id === _tenant?._id
											? "#d9d9d9"
											: "#f5f5f5",
								}}
							>
								{_tenant.tenantid}
							</List.Item>
						);
					})}
				</List>
			</Sider>

			<Content
				style={{ height: "100%", background: "#f5f5f5", margin: "24px" }}
			>
				{selectedTenant === null ? (
					<p style={{ textAlign: "center", fontSize: "50px" }}>
						Select tenant to configure
					</p>
				) : (
					<TenantDetails tenant={selectedTenant} onUpdate={onUpdate} />
				)}
			</Content>
		</Layout>
	);
};

export default TenantSettings;
